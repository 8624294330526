
import { authAxios, withoutAuthAxios } from "../../Config/axios"

export const updateBuyerDetail = data => _dispatch => {
    return (
        _dispatch({ type: "UPDATE_BUYER_DETAILS", payload: data })
    )
}
export const updateRelatedOrderId = data => _dispatch => {
    return (
        _dispatch({ type: "UPDATE_RELATED_ORDER_ID", payload: data })
    )
}
export const setDriverDetails = data => _dispatch => {
    return (
        _dispatch({ type: "SET_INVOICE_DRIVER", payload: data })
    )
}

export const setParentShopID = data => _dispatch => {
    return (
        _dispatch({ type: "SET_PARENT_SHOP_ID", payload: data })
    )
}

export const saveCurrentLatLng = data => _dispatch => {
    return (
        _dispatch({ type: "SAVE_CURRENT_LAT_LNG", payload: data })
    )
}

export const saveAddress = data => _dispatch => {
    return (
        _dispatch({ type: "SAVE_ADDRESS", payload: data })
    )
}

export const saveSelectedUsers = data => _dispatch => {
    return (
        _dispatch({ type: "SAVE_SELECTED_USERS", payload: data })
    )
}

export const saveDeliveryZoneTitle = data => _dispatch => {
    return (
        _dispatch({ type: "SAVE_TITLE_NAME", payload: data })
    )
}

export const setNotifyOrder = data => _dispatch => {
    return (
        _dispatch({ type: "SET_NOTIFY_ORDER", payload: data })
    )
}

export const removeOpenOrder = data => _dispatch => {
    return (
        _dispatch({ type: "REMOVE_OPEN_ORDER", payload: data })
    )
}

export const setOrderETA = data => _dispatch => {
    return (
        _dispatch({ type: "SET_ORDER_ETA", payload: data })
    )
}

export const setDspDriversId = data => _dispatch => {
    return (
        _dispatch({ type: "SET_DSP_DRIVERS", payload: data })
    )
}

export const saveCityData = data => _dispatch => {
    return (
        _dispatch({ type: "SAVE_CITY_DATA", payload: data })
    )
}

export const saveAggregatorName = data => _dispatch => {
    return (
        _dispatch({ type: "SET_AGGREGATOR_NAME", payload: data })
    )
}

export const addNotification = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/push/send_push_message", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const sendWhatsAppMessage = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/send_whatsapp_message", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const sendTextMessage = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/send_text_message", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getGlobalApi = () => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().get("/app/driver/get_global_config")
            .then(
                response => {
                    resolve(response.data)
                    if (response.data.status == '1') {
                        const resData = response.data.data?.config?.params
                        _dispatch({ type: "SAVE_GLOBAL_DATA", payload: resData });
                    }
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getUserGlobalApi = () => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().get("/users/get_global_web_config")
            .then(
                response => {
                    resolve(response.data)
                    if (response.data.status == '1') {
                        const resData = response.data.data?.config?.params
                        _dispatch({ type: "SAVE_USER_GLOBAL_DATA", payload: resData });
                    }
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getAddressFromItsLocation = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/other/get_address_from_geo_location", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const shareLocation = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/other/share_location", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const addCommentApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/order_comment/add_comment", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getCommentsApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/order_comment/get_comment", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const updateCommentApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/order_comment/update_comment", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const removeCommentApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/order_comment/remove_comment", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getWebhooklog = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/order/get_webhook", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}









