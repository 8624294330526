import React, { useEffect, useState } from 'react';
import { sendTextMessage} from '../../Redux/action/App';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { selectOptionStyle } from '../../Helper';


function SendTextMessageModal(props) {
    const { orderDetails, modal, closeModal } = props
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const { _id, driverphone, drivername, driverid, receiver_name, buyerphone } = orderDetails ? orderDetails : {}
    const [userOptions, setUserOptions] = useState([])

    useEffect(() => {
        let user_options = [...userOptions]
        if (driverid) {
            const driverOption = {
                label: `${drivername}(${driverphone})(DRIVER)`,
                value: driverphone,
                user_type: 'Driver',
                user_name: drivername
            }
            user_options.push(driverOption)
            setUserOptions(user_options)
        }
        if (buyerphone && receiver_name) {
            const buyerOption = {
                label: `${receiver_name}(${buyerphone})(BUYER)`,
                value: buyerphone,
                user_type: 'Buyer',
                user_name: receiver_name
            }
            user_options.push(buyerOption)
            setUserOptions(user_options)
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            phone: "",
            message: '',
            order_id: _id,
            receiver_type: "",
            receiver_name: ""
        },
        validationSchema: Yup.object({
            message: Yup.string()
                .required("This field is required")
                .min(5, 'Message must be at least 5 characters'),

            phone: Yup.string()
                .required("Please select user")
        }),

        onSubmit: async values => {
            const payload = values
            setLoader(true)
            await dispatch(sendTextMessage(payload))
                .then(
                    response => {
                        setLoader(false)
                        if (response.status == '1') {
                            toast.success(response.data.message)
                            formik.resetForm();
                            closeModal();
                        } else {
                            toast.error(response.data?.message)
                        }
                    },
                    error => {
                        setLoader(false)
                        const errorMessage = error.response?.data?.data?.message || error.response?.data?.message || error?.message
                        toast.error(errorMessage)
                    }
                )
        }
    });

    const selectUser = (option) => {
        formik.setFieldValue('phone', option ? option.value : "")
        formik.setFieldValue('receiver_type', option ? option.user_type : "")
        formik.setFieldValue('receiver_name', option ? option.user_name : "")
    }

    return (
        <div id="myModal" className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}
            role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit} >
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Send Text Message</h5>
                            <button onClick={() => closeModal()} type="button"
                                className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">User</label>
                                        <Select
                                            value={userOptions.find(item => item.value === formik.values.phone)}
                                            onChange={selectUser}
                                            options={userOptions}
                                            placeholder="Select user"
                                            isClearable={true}
                                            styles={selectOptionStyle}
                                        />
                                        {formik.touched.phone && formik.errors.phone ? (<span className="text-danger mb-1">{formik.errors.phone}</span>) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">Message</label>
                                        <textarea
                                            className="form-control"
                                            type="text"
                                            name="message"
                                            required
                                            rows={3}
                                            placeholder='Enter the message'
                                            onChange={formik.handleChange}
                                            value={formik.values.message}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.message && formik.errors.message ? (<span className="text-danger">{formik.errors.message}</span>) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button disabled={loader} type="submit" className="btn btn-success">{loader ? "Processing" : "Send"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default SendTextMessageModal