import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { addDeliveryZone, validateDeliveryZone } from '../../Redux/action/DeliveryZone';
import { getUserForSearchBar } from '../../Redux/action/User';
import { Link, useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { default as ReactSelect } from "react-select";
import { selectOptionStyle } from '../../Helper';

function AddDeliveryZone(props) {
  const { setLoading } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedShops, setSelectedShops] = useState("");
  const [shopFetching, setShopFetching] = useState(false)
  const [shops, setShops] = useState([])



  // loaded options using API call with searched Shops
  const getSearchedShops = async (searchvalue) => {
    const payload = {
      search: { type: "Shop,Integrator", nickname: searchvalue, username: "" },
      shorting: { order_in: "asc", order_by: "nickname" }
    }
    setShopFetching(true)
    await dispatch(getUserForSearchBar(payload))
      .then(
        response => {
          setShopFetching(false)
          if (response.status === "1") {
            const resData = response.data.users
            const allShops = []
            resData.forEach(item => {
              const { _id, nickname } = item
              allShops.push({ label: nickname, value: _id })
            })
            setShops(allShops)
          } else {
            setShops([])
          }
        },
        error => {
          setShops([])
          setShopFetching(false)
          toast.error(error.response.data?.message)
        }
      )
  };

  const getShopsData = useCallback(debounce((searchvalue) => getSearchedShops(searchvalue), 500), []);
  const handleSearchShop = (inputValue) => {
    const value = inputValue.trim()
    if (value && value.length > 0) {
      getShopsData(value);
    }
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      selected_users: [],
      delivery_fee: '',
      utrac_fee: '',
      km_min: '',
      km_max: '',
      tax_fee: ''
    },

    validationSchema: Yup.object({
      title: Yup.string()
        .required("This fiels is required")
        .min(3, 'This field must be at least 3 characters'),

      delivery_fee: Yup.number()
        .typeError('You must specify a number')
        .min(0, "Please enter a positive number")
        .required("This field is required"),

      utrac_fee: Yup.number()
        .typeError('You must specify a number')
        .min(0, "Please enter a positive number")
        .required("This field is required"),

      km_min: Yup.number()
        .typeError('You must specify a number')
        .min(0, "Please enter a positive number")
        .required("This field is required"),

      km_max: Yup.number()
        .typeError('You must specify a number')
        .min(0, "Please enter a positive number")
        .required("This field is required")
        .test(
          'km_max',
          'Max KM. must be greater than Min KM',
          function (km_max) {
            const { km_min } = this.parent; // Access min_geofence value
            return km_max > km_min;
          }
        ),
      tax_fee: Yup.number()
        .typeError('You must specify a number')
        .min(0, "Please enter a positive number")
        .required("This field is required"),
    }),

    onSubmit: async values => {
      if (parseInt(values.km_max) <= parseInt(values.km_min)) {
        toast.error('Max km must be greater than Min km')
      }
      else {
        const fieldValues = values
        setLoading(true)
        await dispatch(addDeliveryZone(fieldValues))
          .then(
            response => {
              setLoading(false)
              if (response.status === '1') {
                toast.success(response.data.message)
                formik.resetForm();
                history.push('/delivery-zones')
              } else {
                const errMsg = response.data.message || response.data.error[0]
                toast.error(errMsg)
              }
            },
            (error) => {
              setLoading(false);
              toast.error(error.response.data?.message)
            }
          )
          .catch(error => console.log(error)
          )
      }
    }
  });

  const handleSelectShops = async (options) => {
    const dummyArr = []
    options.map(item => dummyArr.push(item.value))
    if (options.length > selectedShops.length > 0) {
      let fieldValues = formik.values
      fieldValues['selected_users'] = dummyArr
      setLoading(true)
      await dispatch(validateDeliveryZone(fieldValues))
        .then(
          response => {
            setLoading(false)
            if (response.status === '1') {
              setSelectedShops(options)
              formik.setFieldValue('selected_users', dummyArr);
            } else {
              const errMsg = response.data.message || response.data.error[0]
              toast.error(errMsg)
            }
          },
          (error) => {
            setLoading(false);
            const errMsg = error.response.data?.message || error.response.data?.data?.message
            toast.error(errMsg)
          }
        )
        .catch(error => console.log(error)
        )
    }
    else {
      setSelectedShops(options)
      formik.setFieldValue('selected_users', dummyArr);
    }
  }

  const checkValidateRequiredFields = () => {
    const { title, delivery_fee, utrac_fee, km_min, km_max, tax_fee } = formik.values
    if (!title.trim() || delivery_fee === '' || utrac_fee === '' || km_min === '' || km_max === '' || tax_fee === '') {
      return true;
    }
    else if (delivery_fee < 0 || utrac_fee < 0 || km_min < 0 || km_max < 0 || tax_fee < 0) {
      return true
    }
    else {
      return false
    }
  }

  return (
    <div className="content-wrapper">
      <div className="content-header main_breadcrumb_top">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Create Delivery Zone</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <form onSubmit={formik.handleSubmit} >

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Title</label>
                          <input
                            required
                            name="title"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                            placeholder='Title name'
                            type="text" className="form-control" />
                          {formik.touched.title && formik.errors.title ? (<span className="text-danger">{formik.errors.title}</span>) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Delivery Fee</label>
                          <input
                            required
                            name="delivery_fee"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Delivery fee'
                            value={formik.values.delivery_fee}
                            type="number" className="form-control" />
                          {formik.touched.delivery_fee && formik.errors.delivery_fee ? (<span className="text-danger">{formik.errors.delivery_fee}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Utrac Fee</label>
                          <input
                            required
                            name="utrac_fee"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Utrac fee'
                            value={formik.values.utrac_fee}
                            type="number" className="form-control" />
                          {formik.touched.utrac_fee && formik.errors.utrac_fee ? (<span className="text-danger">{formik.errors.utrac_fee}</span>) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Tax Fee</label>
                          <input
                            required
                            name="tax_fee"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Tax fee'
                            value={formik.values.tax_fee}
                            type="number" className="form-control" />
                          {formik.touched.tax_fee && formik.errors.tax_fee ? (<span className="text-danger">{formik.errors.tax_fee}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Minimum KM</label>
                          <input
                            required
                            name="km_min"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.km_min}
                            placeholder='Min KM'
                            type="number" className="form-control" />
                          {formik.touched.km_min && formik.errors.km_min ? (<span className="text-danger">{formik.errors.km_min}</span>) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label" >Maximum KM</label>
                          <input
                            required
                            name="km_max"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.km_max}
                            placeholder='Max KM'
                            type="number" className="form-control" />
                          {formik.touched.km_max && formik.errors.km_max ? (<span className="text-danger">{formik.errors.km_max}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="control-label">Shop Name</label>
                          <ReactSelect placeholder="Search & Select"
                            isLoading={shopFetching}
                            isClearable={true}
                            value={selectedShops}
                            options={shops}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isMulti={true}
                            isDisabled={checkValidateRequiredFields()}
                            onInputChange={handleSearchShop}
                            onChange={handleSelectShops}
                            styles={selectOptionStyle}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-foot-btn mt-2 d-flex justify-content-center">
                      <button type="button" className="btn btn-primary mr-4" onClick={() => history.push('/delivery-zones')}>Back</button>
                      <button type="submit" className="btn btn-success ">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default IsLoadingHOC(IsLoggedinHOC(AddDeliveryZone))