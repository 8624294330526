import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { addAdminAction } from '../../Redux/action/User';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { phoneRegExp, countryCode } from '../../Config/constants';


function CreateAdmin(props) {
  const { setLoading } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)


  const formik = useFormik({
    initialValues: {
      username: '',
      nickname: '',
      email: '',
      phone: '',
      password: '',
      confirm_password: '',
      assign_roles: []
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required("This fiels is required")
        .min(3, 'This field must be at least 3 characters'),

      nickname: Yup.string()
        .required("This field is required")
        .min(3, 'This field must be at least 3 characters'),

      email: Yup.string()
        .required("This field is required"),

      phone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required("This field is required"),

      password: Yup.string()
        .required("This field is required")
        .min(5, 'Password must be at least 5 characters'),

      confirm_password: Yup.string()
        .required("This field is required")
        .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),

    }),
    onSubmit: async values => {
      let fieldValues = { ...values }
      let phoneNumber = fieldValues.phone.toString();
      fieldValues.phone = countryCode + phoneNumber;
      setLoading(true)
      await dispatch(addAdminAction(fieldValues))
        .then(
          response => {
            setLoading(false)
            if (response.status === '1') {
              toast.success(response.data.message)
              formik.resetForm();
              history.push('/admins')
            } else {
              toast.error(response.data.error[0])
            }
          },
          (error) => {
            setLoading(false);
            toast.error(error.response.data?.message)
          }
        ).catch(error => console.log(error))
    },
  });
  
  return (
    <div className="content-wrapper">
      <div className="content-header main_breadcrumb_top">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Create Admin</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Name</label>
                          <input
                            required
                            name="username"
                            onChange={formik.handleChange}
                            value={formik.values.username}
                            onBlur={formik.handleBlur}
                            type="text" className="form-control" placeholder="User name" />
                          {formik.touched.username && formik.errors.username ? (<span className="text-danger">{formik.errors.username}</span>) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Nick name</label>
                          <input
                            required
                            name="nickname"
                            onChange={formik.handleChange}
                            value={formik.values.nickname}
                            onBlur={formik.handleBlur}
                            type="text" className="form-control" placeholder="Nick name" />
                          {formik.touched.nickname && formik.errors.nickname ? (<span className="text-danger " >{formik.errors.nickname}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Email address</label>
                          <input
                            required
                            name="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            type="email" className="form-control" placeholder="Email ID" />
                          {formik.touched.email && formik.errors.email ? (<span className="text-danger">{formik.errors.email}</span>) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required ">
                          <label className="control-label">Phone</label>
                          <div className="input-group">
                            <div className="input-group-append">
                              <span className="input-group-text">{countryCode}</span>
                            </div>
                            <input
                              required
                              name="phone"
                              onChange={formik.handleChange}
                              value={formik.values.phone}
                              onBlur={formik.handleBlur}
                              type="text" className="form-control" placeholder="Phone" />
                          </div>
                          {formik.touched.phone && formik.errors.phone ? (<span className="text-danger">{formik.errors.phone}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Password</label>
                          <input
                            required
                            name="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            onBlur={formik.handleBlur}
                            type={showPassword ? 'text' : 'password'}
                            className="form-control" placeholder="Password" />
                          <i className={showPassword ? "fas fa-eye" : " fas fa-eye-slash"} onClick={() => setShowPassword(!showPassword)} id="eye"></i>
                          {formik.touched.password && formik.errors.password ? (<span className="text-danger " >{formik.errors.password}</span>) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Confirm password</label>
                          <input
                            required
                            name="confirm_password"
                            onChange={formik.handleChange}
                            value={formik.values.confirm_password}
                            onBlur={formik.handleBlur}
                            type={showConfirmPassword ? 'text' : 'password'}
                            className="form-control" placeholder="Confirm password" />
                          <i className={showConfirmPassword ? "fas fa-eye" : " fas fa-eye-slash"} id="eye" onClick={() => setShowConfirmPassword(!showConfirmPassword)} ></i>
                          {formik.touched.confirm_password && formik.errors.confirm_password ? (<span className="text-danger">{formik.errors.confirm_password}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="pb-5 form-foot-btn">
                      <button type="button" className="btn btn-primary mr-2" onClick={() => history.push('/admins')}>Back</button>
                      <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default IsLoadingHOC(IsLoggedinHOC(CreateAdmin))