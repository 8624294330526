import React, { useEffect, useState } from "react";
import { useJsApiLoader, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import shopMarker from '../../assets/dist/img/active_shopmarker.png';
import driverMarker from '../../assets/dist/img/active_drivermarker.png';
import customerMarker from '../../assets/dist/img/active_customermarker.png';

const libraries = ['places']
const mapContainerStyle = { width: "100%", height: "100%" };


export default function SimpleMap({ orderDetail, driverLocation }) {
  const { accept_order_location, buyercurrlocation, senderlocation, shopname, sender_full_address, senderphone, drivername, driverphone, receiver_address, receiver_name, buyerphone } = orderDetail
  const [map, setMap] = useState(null)
  const [shopLocation, setShopLocation] = useState(null)
  const [buyerLocation, setBuyerLocation] = useState(null)
  const [center, setCenter] = useState(null)
  const [isMouseOver, setMouseOver] = useState(false);
  const [shopMarkerInfo, setShopMarkerInfo] = useState(null);
  const [driverMarkerInfo, setDriverMarkerInfo] = useState(null);
  const [buyerMarkerInfo, setBuyerMarkerInfo] = useState(null);


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  }, [libraries])


  useEffect(() => {
    if (senderlocation) {
      if (senderlocation[0] && senderlocation[1]) {
        const latitude = parseFloat(senderlocation[0])
        const longitude = parseFloat(senderlocation[1])
        let shop_location = { lat: latitude, lng: longitude }
        setShopLocation(shop_location)
      }
    }
    if (buyercurrlocation) {
      if (buyercurrlocation[0] && buyercurrlocation[1]) {
        const latitude = parseFloat(buyercurrlocation[0])
        const longitude = parseFloat(buyercurrlocation[1])
        let buyer_location = { lat: latitude, lng: longitude }
        setBuyerLocation(buyer_location)
      }
    }
  }, [accept_order_location, senderlocation, buyercurrlocation])

  useEffect(() => {
      if(driverLocation){
        setCenter(driverLocation)
      }
  }, [driverLocation])


  const handleMouseOver = (type) => {
    setMouseOver(true);
    if (type === 'Shop') {
      setDriverMarkerInfo(null)
      setBuyerMarkerInfo(null)
      const position = shopLocation
      setShopMarkerInfo({ shopname, senderphone, sender_full_address, position })
    }
    else if (type === 'Driver') {
      setShopMarkerInfo(null)
      setBuyerMarkerInfo(null)
      const position = driverLocation
      setDriverMarkerInfo({ drivername, driverphone, position })
    }

    else if (type === 'Buyer') {
      setShopMarkerInfo(null)
      setDriverMarkerInfo(null)
      const position = buyerLocation
      setBuyerMarkerInfo({ receiver_name, buyerphone, position, receiver_address })
    }
  };

  const handleInfoWindowClose = () => {
    setMouseOver(false);
    setShopMarkerInfo(null);
    setDriverMarkerInfo(null);
    setBuyerMarkerInfo(null);
  };

  return (
    <>
      <div className="track-maps-content">
        {isLoaded && center &&
          <GoogleMap
            center={center}
            zoom={12}
            mapContainerStyle={mapContainerStyle}
            onLoad={map => setMap(map)}
          // options={{
          //   zoomControl: true,
          //   streetViewControl: true,
          //   mapTypeControl: true,
          //   fullscreenControl: true,
          // }}
          >
            {shopLocation && <Marker
              position={shopLocation}
              icon={shopMarker}
              onMouseOver={() => handleMouseOver('Shop')}
            />}
            {buyerLocation && <Marker
              position={buyerLocation}
              icon={customerMarker}
              onMouseOver={() => handleMouseOver('Buyer')}
            />}
            {driverLocation && <Marker
              position={driverLocation}
              icon={driverMarker}
              onMouseOver={() => handleMouseOver('Driver')}
            />}
            {isMouseOver && shopMarkerInfo && (
              <InfoWindow
                position={shopMarkerInfo.position}
                onCloseClick={handleInfoWindowClose}
              >
                <div>
                  <h6>{shopMarkerInfo.shopname}</h6>
                  {/* <h6>{shopMarkerInfo.senderphone}</h6> */}
                  {/* <p>{shopMarkerInfo.sender_full_address}</p> */}
                </div>
              </InfoWindow>
            )}
            {isMouseOver && driverMarkerInfo && (
              <InfoWindow
                position={driverMarkerInfo.position}
                onCloseClick={handleInfoWindowClose}
              >
                <div>
                  <h6>{driverMarkerInfo.drivername}</h6>
                  {/* <h6>{driverMarkerInfo.driverphone}</h6> */}
                </div>
              </InfoWindow>
            )}
            {isMouseOver && buyerMarkerInfo && (
              <InfoWindow
                position={buyerMarkerInfo.position}
                onCloseClick={handleInfoWindowClose}
              >
                <div>
                  <h6>{buyerMarkerInfo.receiver_name}</h6>
                  {/* <h6>{buyerMarkerInfo.buyerphone}</h6> */}
                  {/* <p>{buyerMarkerInfo.receiver_address}</p> */}
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        }
      </div>
    </>
  );
}
