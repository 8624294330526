import React, { useState, useEffect } from 'react';
import { dateFormat } from '../../Helper';
import { getScheduleOrderDetail } from '../../Redux/action/Orders';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

function ScheduledOrderDetail(props) {
    const dispatch = useDispatch()
    const { setLoading, order_id, modal, closeModal, isLoading } = props
    const [orderDetail, setOrderDetail] = useState({
        sender_full_address: '',
        receiver_full_address: '',
        buyername: '',
        receiver_name: '',
        buyerphone: '',
        senderphone: '',
        schedule_time: '',
        shopname: '',
        orderprice: '0',
        utrackfee: '0',
        deliveryfee: '0',
        distance: '0',
        taxfee: '0',
        paymentmethod: '',
        _created_at: '',
        _updated_at: ''
    })

    useEffect(() => {
        getScheduleOrderDetailsData();
    }, [])

    const getScheduleOrderDetailsData = async () => {
        const payload = { order_id }
        setLoading(true)
        await dispatch(getScheduleOrderDetail(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const resData = (response.data && response.data.order) || {}
                        for (let key in resData) {
                            if (resData[key]) {
                                setOrderDetail(prevState => ({
                                    ...prevState,
                                    [key]: resData[key]
                                }));
                            }
                        }
                    } else {
                        toast.error(response.data?.error[0])
                    }
                },
                error => {
                    toast.error(error.response.data?.message)
                    setLoading(false)
                    console.log(error)
                }
            )
    }

    const getNumberValue = (value) => {
        if (value == 0) {
            return value
        } else if (!value) {
            return 'NA'
        }
        else {
            return value
        }
    }

    const getBuyerName = (order) => {
        if (isLoading) {
            return 'Buyer name'
        }
        else if (order.buyername) {
            return order.buyername;
        }
        else if (order.receiver_name) {
            return order.receiver_name;
        }
        else {
            return 'Not available'
        }
    }

    const getBuyerFullAddress = (order) => {
        if (isLoading) {
            return 'Buyer address';
        }
        else if (order.receiver_full_address) {
            return order.receiver_full_address
        }
        else if (order.receiver_address) {
            return order.receiver_address
        }
        else {
            return 'Not available'
        }
    }

    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`}
            style={{ display: modal ? "block" : "none" }} tabIndex="-1"
            role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog  modal-lg " role="document">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Scheduled Order</h5>
                        <button onClick={closeModal} type="button"
                            className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body overflow-auto detail--modal--scroll">
                        <form >
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Buyer Address</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Receiver address'
                                            disabled
                                            value={getBuyerFullAddress(orderDetail)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Shop Address</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Receiver address'
                                            disabled
                                            value={orderDetail?.sender_full_address} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Shop Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            placeholder='Shop name'
                                            value={orderDetail.shopname} />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Shop Phome</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            placeholder='Shop phone'
                                            value={orderDetail.senderphone} />
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Buyer Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Buyer name'
                                            disabled
                                            value={getBuyerName(orderDetail)} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Buyer Phone</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Buyer phone'
                                            disabled
                                            value={orderDetail.buyerphone} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>Order Price</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Order price'
                                            disabled
                                            value={getNumberValue(orderDetail.orderprice)} />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>Delivery Fee</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Delivery fee'
                                            disabled
                                            value={getNumberValue(orderDetail.deliveryfee)} />
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>Utrac Fee</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Utrac fee'
                                            disabled
                                            value={getNumberValue(orderDetail.utrackfee)} />
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>Tax Fee</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Tax fee'
                                            disabled
                                            value={getNumberValue(orderDetail.taxfee)} />
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Payment Method</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Payment method'
                                            disabled
                                            value={orderDetail.paymentmethod} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Distance</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Distance'
                                            disabled
                                            value={orderDetail.distance} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Schedule Time</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Scheduled time'
                                            disabled
                                            value={dateFormat(orderDetail.schedule_time)} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Created At</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder='Created at'
                                            disabled
                                            value={dateFormat(orderDetail._created_at)} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={closeModal} className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ScheduledOrderDetail

