import React from "react";
import PolarAreaChart from '../../Components/Chart/PolarAreaChart';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import RadialBarChart from "../../Components/Chart/RadialBarChart";
import DonutChart from "../../Components/Chart/DonutChart";



function AverageTimeApexChart() {
    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Dashboard</h1>
                        </div>
                        <div className="col-sm-6">
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-4">
                            <RadialBarChart />
                        </div>
                        <div className="col-sm-4">
                            <DonutChart />
                        </div>
                        <div className="col-sm-4">
                            <PolarAreaChart />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default  IsLoggedinHOC(AverageTimeApexChart)