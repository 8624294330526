import { authAxios } from "../../Config/axios"
import { trimObjValues } from "../../Helper"


export const getOrdersList = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/get_orders", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getOrdersCount = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/dashboard/get_orders_count", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getScheduleOrdersCount = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/dashboard/get_scheduled_orders_count", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getOrderDetails = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/get_order_detail", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const createNewOrder = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/create_order", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const assignOrderToDriver = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/assign_driver", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const cancelOrderForVender = (data , deliveryProvider) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/delivery_vendor/${deliveryProvider}_cancelOrder`, data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const assignOrderToVendor = (data , deliveryProvider) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/delivery_vendor/${deliveryProvider}_createOrder`, data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const pickOrderApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/pick_order", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const arrivedToShopApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/arrive_to_shop", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const cancelOrderApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/cancel_order", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const cancelBulkOrdersApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/cancel_bulk_orders", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const keepOrderOnHoldApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/hold_order", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const resumeOrderApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/resume_order", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const completeOrderApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/complete_order", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const updateBuyerOrder = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/update_order", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const createScheduleOrder = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/create_schedule_order", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const updateScheduleOrder = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/update_schedule_order", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const reScheduleOrder = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/reschedule_from_create_order", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getScheduleOrderDetail = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/get_schedule_order_detail", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}
export const getScheduleOrderList = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/schedule_order_list", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const deleteScheduleOrder = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/delete_schedule_order", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const moveSheduleToOpenOrders = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/schedule_to_create_order", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const bulkScheduleToCreateOrder = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/bulk_schedule_to_create_order", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const reorderCreated = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/reorder_created", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const reorderPendingPick = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/reorder", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const reNotifyOrder = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/renotify", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getDriversWithinShopRange = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/get_drivers_within_shop_range", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getDriverLastLocation = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_driver_last_location", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getOrderLastLocation = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/get_order_last_location", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getPerDayOrdersCount = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/graph/get_per_day_order_counts", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getPerMonthOrdersCount = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/graph/get_per_month_order_counts", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getPerMonthAggregatorOrdersCount = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/graph/get_per_month_aggregator_order_counts", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getOrderAverageTime = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/graph/get_average_order_timelines", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getDeliveryFeeAverage = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/graph/get_delivery_fee_order_counts", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getAreasOrderCount = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/graph/get_area_order_counts", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getOrderLiveETA = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/get_order_live_eta", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getOrderEtaApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/get_order_static_eta", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const createToScheduleOrderApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/create_order_to_schedule", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getOrderPushNotifications = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/get_order_push_notifications", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const importOrdersApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/import_orders", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const uploadOrderDocumentsApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/orders/upload_document", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}













