import React from 'react';

const SwitchToggle = ({ isChecked, handleSwitch }) => {
    return (
        <React.Fragment>
            <div className="form-group">
                <div className="checkbox checbox-switch switch-success">
                    <label>
                        <input onChange={handleSwitch} type="checkbox" name="" checked={isChecked} />
                        <span></span>
                    </label>
                </div>
            </div>
            <span>
            </span>
        </React.Fragment>
    )
}

export default SwitchToggle
