/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts";
import SelectDate from "../Form/Select/SelectDate";
import { useDispatch, useSelector } from "react-redux";
import { getDeliveryFeeAverage } from '../../Redux/action/Orders';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import moment from "moment";



function DonutChart({ setLoading }) {
    const dispatch = useDispatch();
    var today = new Date();
  const [selectedDate, setSelectedDate] = useState(new Date().setDate(today.getDate() -1))
    const [series, setSeries] = useState([0.1, 0.1, 0.1, 0.1])
    const { user } = useSelector(state => state.auth)
    const { _id, type } = user
    const { drivers } = user ? user : {}
    const logisticsDrivers = drivers ? drivers : []


    const [options, setOptions] = useState({
        chart: {
            height: 300,
            type: 'donut',
            background: '#fcfcfc'
        },
        labels: [],
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return parseInt(val) + '%'
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    show: true,
                    floating: true,
                    position: 'Left',
                    // offsetY: 0,
                    // height: 230,
                }
            }
        }],
        title: {
            text: `Per day orders within delivery fee`,
            align: 'left'
        },
        labels: ['Label-1', 'Label-2', 'label-3', 'label-4'],
    }
    )


    useEffect(() => {
        getDeliveryFeeAverageData();
    }, [selectedDate])

    const getDeliveryFeeAverageData = async () => {
        const formatedDate = moment(selectedDate).format('yyyy-MM-DD');
        let payload = {
            user_id: type === "Admin" ? "" : _id,
            date: formatedDate
        }
        if (["Logistics"].includes(user.type) && logisticsDrivers?.length > 0) {
            Object.assign(payload, { drivers: logisticsDrivers })
        }
        setLoading(true)
        await dispatch(getDeliveryFeeAverage(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        const resData = response.data?.orders
                        let orderCount = []
                        let orderLabels = []
                        if (resData && resData.length > 0) {
                            resData.forEach((item) => {
                                orderCount.push(Math.round(item.count))
                                const strgValue = `${item._id}.00 SAR`
                                orderLabels.push(strgValue)
                            })
                            setSeries(orderCount.sort((a, b) => b - a))
                            setOptions((prevState) => ({
                                ...prevState,
                                labels: orderLabels,
                            }));
                        }
                        else {
                            setSeries([0, 0, 0, 0])
                            setOptions((prevState) => ({
                                ...prevState,
                                labels: ['Label-1', 'Label-2', 'label-3', 'label-4'],
                            }));
                        }
                    }
                }).catch(error => console.log(error))
    }

    const handleChangeDate = (date) => {
        setSelectedDate(date);
    }

    return (
        <>
            <div className="card chart-avgtime">
                <div className="card-body chart_card_body">
                    <div className="row justify-content-end">
                        <div className="col-lg-6 col-6">
                            <form>
                                <div className="form-group">
                                    <SelectDate selectedDate={selectedDate} handleChangeDate={handleChangeDate} />
                                </div>
                            </form>
                        </div>
                    </div>
                    {series && series.length > 0 && <ReactApexChart options={options} series={series} type="donut" height={300} />}
                </div>
            </div>
        </>
    )
}

export default IsLoadingHOC(IsLoggedinHOC(DonutChart))
