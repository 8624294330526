import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';


const AppRoute = ({
    component: Component,
    layout: Layout,
    ...rest }) => {
    const token = useSelector(state => state.auth.token)
    return (
        <Route
            {...rest}
            render={(props) => (
                token ? <Redirect to="/" />
                    :
                    <Layout>
                        <Component {...props} />
                    </Layout>
            )}
        />
    )
}

export default AppRoute ;