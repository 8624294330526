import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { getShopDetails, removeShopSubUser } from '../../Redux/action/Shop';
import Pagination from '../../Components/Pagination';
import { useLocation, useParams } from 'react-router-dom';
import { getFormatedDate, setCharacterLimit } from '../../Helper';
import AddShopAdmin from './AddShopAdmin';
import EditShopAdmin from './EditShopAdmin';
import TableFooter from '../../Components/TableFooter/index';
import { getUsersList } from '../../Redux/action/User';
import DeleteConfirmation from '../../Components/ConfirmModel/Delete';
import { Link } from 'react-router-dom';
import { SidebarContext } from '../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';

function ShopSubusers(props) {
    const { isLoading, setLoading } = props
    const location = useLocation()
    const params = useParams()
    const [addModal, setAddModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [shops, setShops] = useState([])
    const [userData, setUserData] = useState("")
    const [shopDetails, setShopDetails] = useState({});
    const [userId, setUserId] = useState("")
    const {
        dispatch,
        totalCount,
        toast,
        setTotalCount,
        sorting,
        deleteModal,
        setDeleteModal,
        handleSorting,
        currentPage,
        setcurrentPage,
        itemsPerPage,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
    } = useContext(SidebarContext)

    const [searchParams] = useState({
        parent_id: params.id,
        _id: "",
        type: "Shop_Sub_Admin",
        nickname: "",
        username: "",
        phone: "",
        is_vip: "",
        from_date: "",
        to_date: "",
        active: "",
        verified: "",
        available: "",
        online: "",
        states_driver: ""
    })

    useLayoutEffect(() => {
        if (location.state && location.state.currentPage) {
            setcurrentPage(location.state.currentPage)
        }
    }, [])

    useEffect(() => {
        getUsersListData();
    }, [currentPage, sorting])


    useEffect(() => {
        getShopDetailsData()
    }, [])

    const getShopDetailsData = async () => {
        const payload = { user_id: params.id }
        setLoading(true)
        await dispatch(getShopDetails(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const result = response.data.user[0]
                        setShopDetails(result)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    }

    const getUsersListData = async () => {
        const payload = {
            search: searchParams,
            shorting: sorting,
            pagination: { start: currentPage, length: itemsPerPage }
        }
        setLoading(true)
        await dispatch(getUsersList(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setShops(response.data.users)
                        setTotalCount(response.data.total_count)
                    } else {
                        toast.error(response.data.error[0])
                        setShops([])
                        setTotalCount(null)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    }

    const handleDelete = async () => {
        const payload = { user_id: userId }
        setLoading(true)
        await dispatch(removeShopSubUser(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        toast.success(response.data?.message)
                        setDeleteModal(false)
                        getUsersListData();
                    } else {
                        toast.error(response.data?.message)
                    }
                },
                (error) => {
                    toast.error(error.response.data?.data?.message)
                    setLoading(false);
                }
            )
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header main_breadcrumb_top">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0"> Sub users of {shopDetails?.nickname}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="all_drivers">
                                    <div className="card card-dark">
                                        <div className="card-header">
                                            <div className="container-fluid">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pagination_new">
                                        <div className="col-lg-6 col-12">
                                            <Link to='/shops'><button disabled={isLoading} type="button" className="btn btn-primary"><i className="fa-solid fa-circle-arrow-left"></i>Back</button></Link>
                                            <button type="add" disabled={isLoading} onClick={() => setAddModal(true)} className="btn btn-info ml-2"><i className="fa-regular fa-circle-plus"></i>Create</button>
                                        </div>
                                    </div>
                                    {shops?.length > 0 &&
                                        <React.Fragment>
                                            <div className="card table_card completed_1">
                                                <div className="card-body all_drive_table">
                                                    <table id="example2" className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr. No</th>
                                                                <th>User Name
                                                                    {sorting.order_by === "username" ?
                                                                        <i onClick={() => handleSorting("username")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("username")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>User Email</th>
                                                                <th>User Phone</th>
                                                                <th>Created At
                                                                    {sorting.order_by === "_created_at" ?
                                                                        <i onClick={() => handleSorting("_created_at")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i> :
                                                                        <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {shops.map((item, i) => (
                                                                <tr key={item._id} className="accordion-toggle">
                                                                    <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                                                                    <td><span title={item.username}>{setCharacterLimit(item.username)}</span></td>
                                                                    <td><span title={item.email}>{setCharacterLimit(item.email)}</span></td>
                                                                    <td>{item.phone ? item.phone : "Not Available"}</td>
                                                                    <td>{getFormatedDate(item._created_at)}</td>
                                                                    <td>
                                                                        <div className="action_button">
                                                                            <div className="btn-group">
                                                                                <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    <img src={require("../../assets/dist/img/action.png")} alt='action-png' />
                                                                                </button>
                                                                                <div className="dropdown-menu">
                                                                                    <span className="dropdown-item"
                                                                                        onClick={() => {
                                                                                            setUserData(item)
                                                                                            setEditModal(true)
                                                                                        }}
                                                                                    >
                                                                                        <span className="blue_1">
                                                                                            <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span></span>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <span className="dropdown-item"
                                                                                        onClick={() => {
                                                                                            setUserId(item._id)
                                                                                            setDeleteModal(true)
                                                                                        }} >
                                                                                        <span className="red_1">
                                                                                            <i className="fa fa-trash" aria-hidden="true"></i>Delete</span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>)
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row pagination_new">
                                                <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={shops} totalCount={totalCount} />
                                                <div className="col-lg-4">
                                                    <Pagination
                                                        totalCount={totalCount}
                                                        currentPage={currentPage}
                                                        itemsPerPage={itemsPerPage}
                                                        maxPageNumberLimit={maxPageNumberLimit}
                                                        minPageNumberLimit={minPageNumberLimit}
                                                        setcurrentPage={setcurrentPage}
                                                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                        setminPageNumberLimit={setminPageNumberLimit}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {shops?.length === 0 && !isLoading && <NotFoundData />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {addModal &&
                <AddShopAdmin
                    modal={addModal}
                    closeModal={() => setAddModal(false)}
                    getUserList={getUsersListData}
                    setLoading={setLoading}
                    isLoading={isLoading}
                />
            }
            {editModal &&
                <EditShopAdmin
                    modal={editModal}
                    closeModal={() => setEditModal(false)}
                    getUserList={getUsersListData}
                    setLoading={setLoading}
                    isLoading={isLoading}
                    userData={userData}
                />
            }
            {deleteModal &&
                <DeleteConfirmation
                    modal={deleteModal}
                    loader={isLoading}
                    title="Do you want to remove sub user"
                    closeModal={() => setDeleteModal(false)}
                    handleDelete={handleDelete} />}
        </>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(ShopSubusers))