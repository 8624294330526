import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { getUserForSearchBar } from '../../Redux/action/User';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { default as ReactSelect } from "react-select";
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { selectOptionStyle, trimFileName } from '../../Helper';
import { importOrdersApi } from '../../Redux/action/Orders';



function UploadFileModal(props) {
    const dispatch = useDispatch()
    const { setLoading, loader, modal, closeModal } = props
    const [shopFetching, setShopFetching] = useState(false)
    const [searchedShops, setSearchedShops] = useState([])
    const [selectedSearchedShop, setSelectedSearchedShop] = useState("");
    const [selectedFile, setSelectedFile] = useState(null)
    const { user } = useSelector(state => state.auth)

    
    const formik = useFormik({
        initialValues: {
            user_id: user.type === "Admin" ? "" : user._id,
            file_name: ''
        },
        validationSchema: Yup.object({
            user_id: Yup.string()
                .required("This field is required"),
            file_name: Yup.string()
                .required("This field is required"),
        }),
        onSubmit: async values => {
            let fieldValues = values
            var formData = new FormData();
            formData.append('file', fieldValues.file_name);
            formData.append('user_id', fieldValues.user_id);

            setLoading(true)
            await dispatch(importOrdersApi(formData))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == '1') {
                            toast.success(response.data.message)
                            formik.resetForm();
                            setSelectedSearchedShop("")
                            closeModal();
                        } else {
                            toast.error(response.data.error[0])
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                    }
                ).catch(error => console.log(error))
        },
    });

    const handleChangeFile = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            formik.setFieldValue("file_name", file)
            setSelectedFile(URL.createObjectURL(event.target.files[0]));
        }
    }

    // load options using API call with searched Drivers
    const getSearchShopUsers = async (searchDriver) => {
        const digitNumbers = /^-?[\+|\d.]+(?:e-?\d+)?$/
        let payload = {
            search: { id: '', type: "Shop", nickname: '', username: "", phone: '' },
            shorting: { order_in: "asc", order_by: "nickname" }
        }
        if (digitNumbers.test(searchDriver)) {
            let phoneNumber = searchDriver.toString();
            if (phoneNumber[0] === "+") {
                phoneNumber = phoneNumber.substring(1)
            }
            payload.search.phone = phoneNumber
        } else {
            payload.search.nickname = searchDriver
        }
        setShopFetching(true)
        await dispatch(getUserForSearchBar(payload))
            .then(
                response => {
                    setShopFetching(false)
                    if (response.status === "1") {
                        const resData = response.data.users
                        const allShops = []
                        resData.forEach(item => {
                            const { _id, nickname } = item
                            allShops.push({ label: nickname, value: _id })
                        })
                        setSearchedShops(allShops)
                    } else {
                        setSearchedShops([])
                    }
                },
                error => {
                    setSearchedShops([])
                    setShopFetching(false)
                    toast.error(error.response.data?.message)
                }
            )
    };


    const getShopUsersData = useCallback(debounce((searchvalue) => getSearchShopUsers(searchvalue), 500), []);
    const handleSearchShop = (inputValue) => {
        const value = inputValue?.trim()
        if (value && value.length > 0) {
            getShopUsersData(value);
        }
    }

    const handleSelectSearchedShop = (option) => {
        if (option) {
            let { value } = option
            formik.setFieldValue('user_id', value)
            setSelectedSearchedShop(option);
        } else {
            formik.setFieldValue('user_id', "")
            setSelectedSearchedShop("");
        }
    }

    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Import bulk orders</h5>
                            <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">Upload file</label>
                                        <div className="custom-file">
                                            <input
                                                className="custom-file-input"
                                                type="file"
                                                placeholder='Choose file'
                                                onChange={handleChangeFile}
                                                accept=".xls, .xlsx, .csv"
                                            />
                                            <label className="custom-file-label" htmlFor="exampleInputFile">{selectedFile ? trimFileName(selectedFile) : 'Choose file'}</label>
                                        </div>
                                        {formik.touched.file_name && formik.errors.file_name ? (<span className="text-danger">{formik.errors.file_name}</span>) : null}

                                    </div>
                                </div>
                            </div>
                            {user.type === "Admin" &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group required">
                                            <label className="control-label">Shop</label>
                                            <ReactSelect placeholder="Search Shop"
                                                isClearable={true}
                                                isLoading={shopFetching}
                                                options={searchedShops}
                                                value={selectedSearchedShop}
                                                onInputChange={handleSearchShop}
                                                onChange={handleSelectSearchedShop}
                                                styles={selectOptionStyle}
                                            />
                                            {formik.touched.user_id && formik.errors.user_id ? (<span className="text-danger">{formik.errors.user_id}</span>) : null}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button disabled={loader} type="submit" className="btn btn-success"> {loader ? "Processing" : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(UploadFileModal))