import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { addShopOtherInfoMation, setFormCurrentStep, updateShop } from '../../Redux/action/Shop';
import { getUserForSearchBar } from '../../Redux/action/User';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { default as ReactSelect } from "react-select";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify'
import { selectOptionStyle } from '../../Helper';



function ShopOtherInfomation(props) {
    let formRef = useRef(null)
    const { setLoading } = props
    const dispatch = useDispatch()
    const [dirvers, setDirvers] = useState([])
    const [selectedDrivers, setSelectedDrivers] = useState(null)
    const [selectedOwnDrivers, setSelectedOwnDrivers] = useState(null)
    const [driversParams] = useState({ type: "Driver", nickname: "", username: "", is_vip: "", active: "true" })
    const { userInfo, shopInfo, shopOtherInfo, socialurl, openingHours, shopImage, shopOtherImages, p_shopid } = useSelector(state => state.shop)
    const params = useParams();

    useEffect(() => {
        getDriversData();
    }, [])

    useEffect(() => {
        if (shopOtherInfo) {
            if (shopOtherInfo.description || shopOtherInfo.latitude) {
                const fields = ["vip_drivers", "own_drivers", "description", "latitude", "longitude", "fix_delivery_fee", "fix_utrac_fee", "fix_tax_fee"];
                fields.forEach(field => formik.setFieldValue(field, shopOtherInfo[field], false));
                if (shopOtherInfo.hasOwnProperty('is_vip')) {
                    formik.setFieldValue('is_vip', shopOtherInfo.is_vip)
                }
                if (shopOtherInfo.hasOwnProperty('has_own_drivers')) {
                    formik.setFieldValue('has_own_drivers', shopOtherInfo.has_own_drivers)
                }
                if (shopOtherInfo.hasOwnProperty('alternative_name')) {
                    formik.setFieldValue("alternative_name", shopOtherInfo.alternative_name)
                }
                if (shopOtherInfo.hasOwnProperty('use_alternative_name')) {
                    formik.setFieldValue("use_alternative_name", shopOtherInfo.use_alternative_name)
                }
                if (shopOtherInfo.hasOwnProperty('publish')) {
                    formik.setFieldValue("publish", shopOtherInfo.publish)
                }
                if (shopOtherInfo.hasOwnProperty('manual_renotify')) {
                    formik.setFieldValue("manual_renotify", shopOtherInfo.manual_renotify)
                }
            }
        }
    }, [shopOtherInfo])

    const formik = useFormik({
        initialValues: {
            use_alternative_name: false,
            alternative_name: "",
            publish: true,
            is_vip: false,
            has_own_drivers: false,
            manual_renotify: false,
            vip_drivers: [],
            own_drivers: [],
            description: '',
            latitude: '',
            longitude: '',
            fix_delivery_fee: '',
            fix_utrac_fee: '',
            fix_tax_fee: ''
        },

        validationSchema: Yup.object({
            latitude: Yup.number()
                .typeError('You must specify a number')
                .required("This field is required"),
            longitude: Yup.number()
                .typeError('You must specify a number')
                .required("This field is required"),
            description: Yup.string()
                .required("This field is required")
                .min(5, 'Description must be at least 5 characters'),
            fix_delivery_fee: Yup.number()
                .typeError('You must specify a number')
                .min(0, "Please enter a positive number"),
            fix_utrac_fee: Yup.number()
                .typeError('You must specify a number')
                .min(0, "Please enter a positive number"),
            fix_tax_fee: Yup.number()
                .typeError('You must specify a number')
                .min(0, "Please enter a positive number"),
        }),
        onSubmit: async values => {
            const fieldValues = values
            let locationMap = [fieldValues.latitude, fieldValues.longitude]
            Object.assign(fieldValues, {
                "locationmap": locationMap
            });
            if (formRef.current.name === 'next') {
                dispatch(addShopOtherInfoMation(fieldValues))
                dispatch(setFormCurrentStep("SOCIAL_LINK"))
            } else {
                handleSubmitData(fieldValues)
            }
        },
    });

    const getDriversData = async () => {
        const payload = {
            search: { ...driversParams },
            shorting: { order_in: "desc", order_by: "nickname" },
        }
        setLoading(true);
        payload.search.is_vip = payload.search.is_vip.toString()
        await dispatch(getUserForSearchBar(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const result = (response.data && response.data.users) || []
                        const all_drivers = []
                        result.forEach(item => {
                            all_drivers.push({ label: item.nickname, value: item._id, is_vip: item.is_vip })
                        })
                        setDirvers(all_drivers)
                        if (shopOtherInfo && shopOtherInfo.vip_drivers) {
                            const selected_Drivers = shopOtherInfo.vip_drivers
                            const results = all_drivers.filter(({ value: id1 }) => selected_Drivers.some((id2) => id2 === id1))
                            setSelectedDrivers(results)
                        }
                        if (shopOtherInfo && shopOtherInfo.own_drivers) {
                            const selected_Drivers = shopOtherInfo.own_drivers
                            const own_results = all_drivers.filter(({ value: id1 }) => selected_Drivers.some((id2) => id2 === id1))
                            setSelectedOwnDrivers(own_results)
                        }
                    } else {
                        setDirvers([])
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
    }

    const selectedHandler = (options, name) => {
        const optionsData = []
        options.forEach(item => {
            optionsData.push(item.value)
        })
        if (name === 'vip_drivers') {
            setSelectedDrivers(options)
            formik.setFieldValue('vip_drivers', optionsData)
        }
        else {
            setSelectedOwnDrivers(options)
            formik.setFieldValue('own_drivers', optionsData)
        }
    }

    const handleVIPDriver = (event) => {
        let { name, value } = event.target
        value = JSON.parse(value)
        formik.setFieldValue(name, value)
        if (name === 'is_vip' && value === true) {
            formik.setFieldValue('has_own_drivers', false)
        }
        else if (name === 'has_own_drivers' && value === true) {
            formik.setFieldValue('is_vip', false)
        }
    }

    const handleUseAlterNative = (event) => {
        let { name, value } = event.target
        value = JSON.parse(value)
        formik.setFieldValue(name, value)
        if (value === false) {
            formik.setFieldValue('alternative_name', "")
        }
    }

    const handlePreviousStep = () => {
        dispatch(setFormCurrentStep("SHOP_INFO"))
    }

    const handleChangeBooleanValue = (e) => {
        let { name, value } = e.target
        value = JSON.parse(value)
        formik.setFieldValue(name, value)
    }

    const handleSubmitData = async (fieldValues) => {
        const payload = {
            user_id: params.id,
            shop_id: p_shopid,
            ...userInfo,
            ...shopInfo,
            ...fieldValues,
            ...shopImage,
            hours: { ...openingHours },
            otherimages: [...shopOtherImages],
            sociallinks: { ...socialurl },
        }
        setLoading(true)
        await dispatch(updateShop(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status === '1') {
                        toast.success(response.data.message)
                    } else {
                        toast.error(response.data?.error[0])
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                    console.log(error)
                }
            )
    }

    return (
        <div className="card-body">
            <div className="tab-content" id="custom-tabs-one-tabContent">
                <div className="tab-pane fade active show " id="custom-shop-info-other" role="tabpanel" aria-labelledby="shop-info-other-tab">
                    <form>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Use Alternate Name ?</label>
                                    <select className="custom-select" name='use_alternative_name'
                                        onChange={(e) => handleUseAlterNative(e)}
                                        value={formik.values.use_alternative_name}>
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Shop Alternate Name</label>
                                    <input className="form-control" placeholder='Alternate name'
                                        required={formik.values.use_alternative_name}
                                        name='alternative_name'
                                        onChange={formik.handleChange}
                                        value={formik.values.alternative_name}
                                        disabled={!formik.values.use_alternative_name}
                                        type="text" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Publish</label>
                                    <select name='publish' className="custom-select"
                                        value={formik.values.publish}
                                        onChange={handleChangeBooleanValue} >
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Manual Renotify</label>
                                    <select name='manual_renotify' className="custom-select"
                                        value={formik.values.manual_renotify}
                                        onChange={handleChangeBooleanValue}>
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Is VIP</label>
                                    <select className="custom-select"
                                        name='is_vip'
                                        value={formik.values.is_vip}
                                        onChange={(e) => handleVIPDriver(e)}>
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>VIP Driver</label>
                                    <ReactSelect placeholder="Select driver"
                                        options={dirvers?.filter(item => item.is_vip === true)}
                                        isMulti={true}
                                        hideSelectedOptions={false}
                                        value={selectedDrivers}
                                        styles={selectOptionStyle}
                                        onChange={(options) => selectedHandler(options, 'vip_drivers')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Shop Has Own Drivers</label>
                                    <select className="custom-select"
                                        name='has_own_drivers'
                                        value={formik.values.has_own_drivers}
                                        onChange={(e) => handleVIPDriver(e)}>
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Assign Own Drivers</label>
                                    <ReactSelect placeholder="Select own driver"
                                        options={dirvers?.filter(item => item.is_vip === false)}
                                        isMulti={true}
                                        hideSelectedOptions={false}
                                        value={selectedOwnDrivers}
                                        styles={selectOptionStyle}
                                        onChange={(options) => selectedHandler(options, 'own_drivers')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Map Location: Latitude</label>
                                    <input className="form-control" placeholder='31.988485'
                                        required
                                        name='latitude'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.latitude}
                                        type="number" />
                                    {formik.touched.latitude && formik.errors.latitude ? (<span className="text-danger">{formik.errors.latitude}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Map Location : Longitude</label>
                                    <input className="form-control" placeholder='36.951485'
                                        required
                                        name='longitude'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.longitude}
                                        type="number" />
                                    {formik.touched.longitude && formik.errors.longitude ? (<span className="text-danger">{formik.errors.longitude}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Delivery Fee</label>
                                    <input
                                        name="fix_delivery_fee"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.fix_delivery_fee}
                                        type="number" className="form-control" />
                                    {formik.touched.fix_delivery_fee && formik.errors.fix_delivery_fee ? (<span className="text-danger">{formik.errors.fix_delivery_fee}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Utrac Fee</label>
                                    <input
                                        required
                                        name="fix_utrac_fee"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.fix_utrac_fee}
                                        type="number" className="form-control" />
                                    {formik.touched.fix_utrac_fee && formik.errors.fix_utrac_fee ? (<span className="text-danger">{formik.errors.fix_utrac_fee}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Tax Fee</label>
                                    <input
                                        required
                                        name="fix_tax_fee"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.fix_tax_fee}
                                        type="number" className="form-control" />
                                    {formik.touched.fix_tax_fee && formik.errors.fix_tax_fee ? (<span className="text-danger">{formik.errors.fix_tax_fee}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Description</label>
                                    <textarea className="form-control" placeholder='Enter description'
                                        required
                                        name='description'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.description}
                                        rows="1">
                                    </textarea>
                                    {formik.touched.description && formik.errors.description ? (<span className="text-danger">{formik.errors.description}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="pb-5 footer-step">
                            <button type='button' className="btn btn-primary mr-2" onClick={handlePreviousStep} >Back</button>
                            <button type='button' ref={formRef} className="btn btn-success mr-2"
                                onClick={() => { formRef.current.name = 'update'; formik.handleSubmit() }} >Update</button>
                            <button type='button' ref={formRef} className="btn btn-info"
                                onClick={() => { formRef.current.name = 'next'; formik.handleSubmit() }} >Next</button>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}
export default IsLoadingHOC(IsLoggedinHOC(ShopOtherInfomation))