import React from 'react'

function TableFooter(props) {
    const {currentPage ,itemsPerPage ,totalCount , resData } = props
  return (
    <div className="col-lg-8">
    <div className="dataTables_info">
       Showing {(currentPage - 1) * itemsPerPage + 1} to {(currentPage - 1) * itemsPerPage + resData.length} of {totalCount} {totalCount > 1 ? 'Entries' : 'Entry' }
    </div>
 </div>
  )
}

export default TableFooter
