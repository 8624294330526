import React, { useState, useEffect } from 'react';
import { getFormatedDate, handleSelectListItem, isAllItemSelected, trimObjValues, customStyles, exportToExcel } from '../../Helper';
import { getUserForSearchBar } from '../../Redux/action/User';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import ExportModel from '../../Components/ConfirmModel/ExportData';
import { Link } from 'react-router-dom';
import { getWalletAmountHistory } from '../../Redux/action/User';
import Pagination from '../../Components/Pagination';
import DateInputs from '../../Components/DatePicker';
import TableFooter from '../../Components/TableFooter';
import { SidebarContext } from '../../Context/SidebarContext';
import { useContext } from 'react';
import NotFoundData from '../../Components/NotFoundData';
import ReactSelect from 'react-select';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import AddMoneyByPaymentMethod from '../../Components/UserModal/PaymentMethods';


// Export CSV File Headers
const headers = [
    { label: "User Id", key: "_id" },
    { label: "User Name", key: "user[0].username" },
    { label: "Nick Name", key: "user[0].nickname" },
    { label: "Amount", key: "amount" },
    { label: "Balance", key: "balance" },
    { label: "Action", key: "action" },
    { label: "Created At", key: "_created_at" },
    { label: "Updated At", key: "_updated_at" },
];

function WalletHistory(props) {
    const { setLoading, isLoading } = props
    const [users, setUsers] = useState([])
    const { user } = useSelector(state => state.auth)
    const [selectedValue, setSelectedValue] = useState("");
    const [shopFetching, setShopFetching] = useState(false)
    const [shops, setShops] = useState([])

    const {
        csvLink,
        dispatch,
        fileLoading,
        setFileLoading,
        totalCount,
        toast,
        handleSorting,
        selectedDate,
        setSelectedDate,
        setTotalCount,
        sorting,
        resetForm,
        currentPage,
        setcurrentPage,
        itemsPerPage,
        handleSubmit,
        exportsData,
        setExportsData,
        toggleExportModal,
        selectedItem,
        setSelectedItem,
        handleSelectItem,
        exportModal,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
        walletModal,
        setWalletModal
    } = useContext(SidebarContext)

    const [searchParams, setSearchParams] = useState({ action: "", article: "", user_id: "" })

    useEffect(() => {
        getUsersData();
    }, [currentPage, sorting])

    const getUsersData = async () => {
        const search_params = trimObjValues(searchParams)
        const payload = {
            search: search_params,
            shorting: sorting,
            pagination: { start: currentPage, length: itemsPerPage }
        }
        setLoading(true)
        await dispatch(getWalletAmountHistory(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setUsers(response.data.items)
                        setTotalCount(response.data.total_count)
                    } else {
                        setUsers([])
                        setTotalCount(null)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
    }

    const getExportingData = async () => {
        const search_params = trimObjValues(searchParams);
        const totalItem = [];

        const fetchInvoicesList = async (pagination) => {
            const payload = {
                search: search_params,
                selected_items: pagination.start === 1 ? [...selectedItem] : [],
                shorting: sorting,
                pagination: pagination
            };
            try {
                setFileLoading(true);
                const response = await dispatch(getWalletAmountHistory(payload));
                setFileLoading(false);

                if (response.status === "1") {
                    totalItem.push(...response.data.items);
                }
            } catch (error) {
                setFileLoading(false);
                toast.error(error.response?.data?.message);
            }
        };

        if (selectedItem && selectedItem.length > 0) {
            await fetchInvoicesList({ start: 1, length: 1000 });
        } else {
            const pages = Math.ceil(totalCount / 50000);
            for (let i = 1; i <= pages; i++) {
                await fetchInvoicesList({ start: i, length: 50000 });
            }
        }

        const exportData = totalItem?.map((item) => {
            const exportItem = {};
            headers.forEach(header => {
                exportItem[header.label] = item[header.key];
            });
            return exportItem;
        });
        const fileName = `wallet_history-${moment(new Date()).format("hh-mm-ss")}.xlsx`
        exportToExcel(exportData, fileName);

        //setExportsData(totalItem);
        // csvLink.current.link.click();
        toast.success("Data exported successfully");
        toggleExportModal();
    };

    const handleChangeDate = (date, name) => {
        setSearchParams((prevState) => ({
            ...prevState,
            [name]: date ? moment(date).format("YYYY-MM-DD") : '',
        }));
        setSelectedDate((prevState) => ({
            ...prevState,
            [name]: date,
        }));
    }

    const handleSearch = (e) => {
        const { name, value } = e.target
        setSearchParams((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleReset = async () => {
        const resetFormData = { action: "", article: "", user_id: "" }
        setSearchParams(resetFormData)
        resetForm();
    }

    // loaded options using API call with searched Shops
    const getSearchedShops = async (searchvalue) => {
        const payload = {
            search: { type: "Shop,Integrator", nickname: searchvalue, username: "" },
            shorting: sorting
        }
        setShopFetching(true)
        await dispatch(getUserForSearchBar(payload))
            .then(
                response => {
                    setShopFetching(false)
                    if (response.status === "1") {
                        const resData = response.data.users
                        const allShops = []
                        resData.forEach(item => {
                            const { _id, nickname } = item
                            allShops.push({ label: nickname, value: _id })
                        })
                        setShops(allShops)
                    } else {
                        setShops([])
                    }
                },
                error => {
                    setShops([])
                    setShopFetching(false)
                    toast.error(error.response.data?.message)
                }
            )
    };

    const getShopsData = useCallback(debounce((searchvalue) => getSearchedShops(searchvalue), 500), []);
    const handleSearchShop = (inputValue) => {
        const value = inputValue.trim()
        if (value && value.length > 0) {
            getShopsData(value);
        }
    }

    const handlSelectShop = (option) => {
        const { value } = option ? option : {}
        setSelectedValue(option)
        setSearchParams((prevState) => ({
            ...prevState,
            user_id: value ? value : '',
        }));
        if (!value) {
            setShops([])
        }
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header main_breadcrumb_top">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Wallet history list</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Wallet history</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="all_drivers">
                                    <div className="card card-dark">
                                        <div className="card-header">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-6 col-lg-11">
                                                        <h3 className="pl-5">Search</h3>
                                                    </div>
                                                    <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                        <i className="fas fa-angle-up click_search"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form className="form_search" action="">
                                            <div className="card-body">
                                                {user.type === 'Admin' &&
                                                    <div className="form-group">
                                                        <label>User Name</label>
                                                        <ReactSelect placeholder="Search"
                                                            isLoading={shopFetching}
                                                            styles={customStyles}
                                                            isClearable={true}
                                                            value={selectedValue}
                                                            options={shops}
                                                            onInputChange={handleSearchShop}
                                                            onChange={(option) => handlSelectShop(option)}
                                                        />
                                                    </div>
                                                }
                                                <div className="form-group">
                                                    <label>Action</label>
                                                    <select className="form-control" name="action" value={searchParams.action} onChange={handleSearch}>
                                                        <option value="">All</option>
                                                        <option value="CREDIT">Credit</option>
                                                        <option value="DEBIT">Debit</option>
                                                    </select>
                                                </div>
                                                <DateInputs selectedDate={selectedDate} handleChangeDate={handleChangeDate} />
                                            </div>
                                            <div className="card-footer text-center">
                                                <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                                <button type="button" onClick={() => handleSubmit(getUsersData)} className="btn btn-success">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="row pagination_new">
                                        <div className="col-lg-6 col-12">
                                            <button disabled={isLoading} type="button" onClick={() => handleSubmit(getUsersData)} className="btn btn-secondary">
                                                <i className={`fa-regular fa-rotate-right ${isLoading && 'fa_spinner'}`}></i>
                                                Refresh
                                            </button>

                                            {/* {['Shop'].includes(user.type) &&
                                                <button disabled={isLoading} type="button" onClick={() => setWalletModal(true)} className="btn btn-primary ml-2">
                                                    <i className='fa-regular fa-circle-plus'></i>
                                                    Recharge To Wallet
                                                </button>
                                            } */}

                                            {/* <button type="button" disabled={fileLoading || !totalCount || isLoading} onClick={toggleExportModal} className="btn btn-info ml-2"><i className="fa-regular fa-circle-down"></i>{fileLoading ? "Loading.." : "Export"}</button>
                                            <CSVLink type='button' className='csv-link' target='_blank' ref={csvLink}
                                                headers={headers}
                                                data={exportsData}
                                                filename={`wallet_history_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss-A")}`}
                                            /> */}
                                        </div>
                                        {users?.length > 0 &&
                                            <div className="col-lg-6 col-12">
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    maxPageNumberLimit={maxPageNumberLimit}
                                                    minPageNumberLimit={minPageNumberLimit}
                                                    setcurrentPage={setcurrentPage}
                                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                    setminPageNumberLimit={setminPageNumberLimit}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {users?.length > 0 &&
                                        <React.Fragment>
                                            <div className="card table_card completed_1">
                                                <div className="card-body all_drive_table">
                                                    <table id="example2" className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                {/* <th><input type="checkbox" id="check" checked={isAllItemSelected(users, selectedItem)} onChange={(event) => handleSelectListItem(event, users, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th> */}
                                                                <th>SN.</th>
                                                                <th>User Name</th>
                                                                <th>Amount</th>
                                                                <th>Balance</th>
                                                                {/* <th>Action</th> */}
                                                                <th>Description</th>
                                                                <th>Created At
                                                                    {sorting.order_by === "_created_at" ?
                                                                        <i onClick={() => handleSorting("_created_at")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {users.map((item, index) =>
                                                            (<tr key={item._id} >
                                                                {/* <td>
                                                                    <input type="checkbox" className="user_table_checkbox"
                                                                        onChange={(event) => handleSelectItem(event, item._id)}
                                                                        checked={selectedItem.includes(item._id)} />
                                                                </td> */}
                                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                <td>{item?.user && item?.user[0]?.username}</td>
                                                                <td>
                                                                    <span className={item.action === "CREDIT" ? 'yes_1' : 'no_1'}>
                                                                        {Math.abs(item?.amount)?.toFixed(2)}
                                                                    </span>
                                                                </td>
                                                                <td>{item?.balance?.toFixed(2)}</td>
                                                                {/* <td>
                                                                    <span className={item.action === "CREDIT" ? 'yes_1' : 'no_1'}>
                                                                        {item.action}
                                                                    </span>
                                                                </td> */}
                                                                <td>{item.description}</td>
                                                                <td>{getFormatedDate(item._created_at)}</td>
                                                            </tr>)
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row pagination_new">
                                                <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={users} totalCount={totalCount} />
                                                <div className="col-lg-4">
                                                    <Pagination
                                                        totalCount={totalCount}
                                                        currentPage={currentPage}
                                                        itemsPerPage={itemsPerPage}
                                                        maxPageNumberLimit={maxPageNumberLimit}
                                                        minPageNumberLimit={minPageNumberLimit}
                                                        setcurrentPage={setcurrentPage}
                                                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                        setminPageNumberLimit={setminPageNumberLimit}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {users?.length === 0 && !isLoading && <NotFoundData />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
            {exportModal &&
                <ExportModel
                    loader={fileLoading}
                    toggleExportModal={toggleExportModal}
                    exportModal={exportModal}
                    getExportingData={getExportingData}
                    totalCount={totalCount}
                    selectedItem={selectedItem}
                />
            }
            {walletModal &&
                <AddMoneyByPaymentMethod
                    loader={fileLoading}
                    closeModal={() => setWalletModal(false)}
                    modal={walletModal}
                    getExportingData={getExportingData}
                    totalCount={totalCount}
                    selectedItem={selectedItem}
                />
            }
        </>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(WalletHistory))