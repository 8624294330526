import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { getAreas, updateOrderAreaSheetApi } from '../../Redux/action/Area';
import { getCities } from '../../Redux/action/City';
import { saveCityData } from '../../Redux/action/App';
import Pagination from '../../Components/Pagination';
import { Link, useLocation } from 'react-router-dom';
import { getFormatedDate, setCharacterLimit, trimObjValues } from '../../Helper';
import TableFooter from '../../Components/TableFooter';
import { SidebarContext } from "../../Context/SidebarContext";
import NotFoundData from '../../Components/NotFoundData';


function Areas(props) {
    const { isLoading, setLoading } = props
    const location = useLocation()
    const [areas, setAreas] = useState([])
    const [searchParams, setSearchParams] = useState({ name: "", from_date: "", to_date: "" })
    const {
        dispatch,
        totalCount,
        toast,
        setTotalCount,
        currentPage,
        resetForm,
        handleSubmit,
        setcurrentPage,
        itemsPerPage,
        handleSorting,
        sorting,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
    } = useContext(SidebarContext)

    useLayoutEffect(() => {
        if (location.state && location.state.currentPage) {
            setcurrentPage(location.state.currentPage)
        }
    }, [])

    useEffect(() => {
        getAreasData();
    }, [currentPage, sorting])

    useEffect(() => {
        getCitiesData();
    }, [])

    const getAreasData = async () => {
        try {
            setLoading(true);
            const search_params = trimObjValues(searchParams);
            const payload = {
                search: search_params,
                shorting: sorting,
                pagination: { start: currentPage, length: itemsPerPage }
            };
            const response = await dispatch(getAreas(payload));
            if (response.status === "1") {
                const { total_count, areas } = response.data;
                setTotalCount(total_count);
                setAreas(areas);
            } else {
                setAreas([]);
                setTotalCount(null);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const getCitiesData = async () => {
        try {
            setLoading(true);
            const payload = {
                search: searchParams,
                shorting: sorting,
            };
            const response = await dispatch(getCities(payload));
            setLoading(false);
            if (response.status === "1") {
                const cityData = response.data.cities.map(item => ({
                    value: item._id,
                    label: item.name
                }));
                dispatch(saveCityData(cityData));
            } else {
                toast.error(response.data.error[0]);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const updateOrderAreasSheet = async () => {
        try {
            setLoading(true);
            const payload = { column_name: "" };
            const response = await dispatch(updateOrderAreaSheetApi(payload));
            setLoading(false);
            if (response.status === "1") {
                toast.success(response.data.message)
            } else {
                const toastMsg = response?.message || response?.data?.message
                toast.error(toastMsg);
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        const { name, value } = e.target
        setSearchParams((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleReset = async () => {
        const resetFormData = { _id: "", name: "", from_date: "", to_date: "" }
        setSearchParams(resetFormData)
        resetForm();
    }

    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Areas List</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Areas</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="all_drivers">
                                <div className="card card-dark">
                                    <div className="card-header">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-6 col-lg-11">
                                                    <h3 className="pl-5">Search</h3>
                                                </div>
                                                <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                    <i className="fas fa-angle-up click_search"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form className="form_search" action="">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label>English Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    onChange={handleSearch}
                                                    value={searchParams.name}
                                                    className="form-control"
                                                    placeholder="Enter name" />
                                            </div>
                                        </div>
                                        <div className="card-footer text-center">
                                            <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                            <button onClick={() => handleSubmit(getAreasData)} type="button" className="btn btn-success">Submit</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="row pagination_new">
                                    <div className="col-lg-6">
                                        <Link to="/create-area"><button disabled={isLoading} type="add" className="btn btn-primary"><i className="fa-regular fa-circle-plus"></i>Create</button></Link>
                                        <button disabled={isLoading} type="button" onClick={updateOrderAreasSheet} className="btn btn-info ml-2">{isLoading ? "Processing" : "Update Order Sheet"}</button>
                                    </div>
                                    {areas?.length > 0 &&
                                        <div className="col-lg-6 col-12">
                                            <Pagination
                                                totalCount={totalCount}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                maxPageNumberLimit={maxPageNumberLimit}
                                                minPageNumberLimit={minPageNumberLimit}
                                                setcurrentPage={setcurrentPage}
                                                setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                setminPageNumberLimit={setminPageNumberLimit}
                                            />
                                        </div>
                                    }
                                </div>
                                {areas?.length > 0 &&
                                    <React.Fragment>
                                        <div className="card table_card completed_1">
                                            <div className="card-body all_drive_table">
                                                <table id="example2" className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No</th>
                                                            <th>English Name
                                                                {sorting.order_by === "name" ?
                                                                    <i onClick={() => handleSorting("name")}
                                                                        className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                    </i>
                                                                    : <i onClick={() => handleSorting("name")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                }
                                                            </th>
                                                            <th>Arabic Name
                                                                {sorting.order_by === "name_ar" ?
                                                                    <i onClick={() => handleSorting("name_ar")}
                                                                        className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                    </i>
                                                                    : <i onClick={() => handleSorting("name_ar")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                }
                                                            </th>
                                                            <th>Direction</th>
                                                            <th>Block
                                                                {sorting.order_by === "num" ?
                                                                    <i onClick={() => handleSorting("num")}
                                                                        className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                    </i>
                                                                    : <i onClick={() => handleSorting("num")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                }
                                                            </th>
                                                            <th>Created At
                                                                {sorting.order_by === "_created_at" ?
                                                                    <i onClick={() => handleSorting("_created_at")}
                                                                        className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                    </i>
                                                                    : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                }
                                                            </th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {areas.map((item, index) =>
                                                        (<tr key={item._id} >
                                                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                            <td><span title={item?.name}>{setCharacterLimit(item?.name)}</span></td>
                                                            <td>{item.name_ar ? item.name_ar : "Not Available"}</td>
                                                            <td>{item.direction ? item.direction : "Not Available"}</td>
                                                            <td>{item.num ? item.num : "Not Available"}</td>
                                                            <td>{getFormatedDate(item._created_at)} </td>
                                                            <td>
                                                                <div className="action_button">
                                                                    <div className="btn-group">
                                                                        <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <img src={require("../../assets/dist/img/action.png")} alt='actionicon' />
                                                                        </button>
                                                                        <div className="dropdown-menu">
                                                                            <Link to={{
                                                                                pathname: `/edit-area/${item._id}`,
                                                                                state: { currentPage: currentPage }
                                                                            }}
                                                                                className="dropdown-item" ><span className="blue_1">
                                                                                    <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>)
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row pagination_new">
                                            <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={areas} totalCount={totalCount} />
                                            <div className="col-lg-4">
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    maxPageNumberLimit={maxPageNumberLimit}
                                                    minPageNumberLimit={minPageNumberLimit}
                                                    setcurrentPage={setcurrentPage}
                                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                    setminPageNumberLimit={setminPageNumberLimit}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                {areas?.length === 0 && !isLoading && <NotFoundData />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(Areas))