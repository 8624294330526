import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import ShopDetailsModel from '../../Components/DetailsModel/ShopDetails';
import { isAllItemSelected, handleSelectListItem, getFormatedDate, setCharacterLimit, handleCopyClipboard, exportToExcel } from '../../Helper';
import { approveRegisteredShop, cleanShopFormData, getRegisteredShops, removeRegisteredShop, setFormCurrentStep } from '../../Redux/action/Shop';
import ActionPermissionModal from '../../Components/PermissionModal/ActionPermissionModal';
import ExportPermissionModal from '../../Components/PermissionModal/ExportPermissionModal';
import DeleteConfirmation from '../../Components/ConfirmModel/Delete';
import ExportModel from '../../Components/ConfirmModel/ExportData';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import Pagination from '../../Components/Pagination';
import { Link, useLocation } from 'react-router-dom';
import DateInputs from '../../Components/DatePicker';
import { trimNestedObjValues } from '../../Helper';
import TableFooter from '../../Components/TableFooter';
import SwitchToggle from '../../Components/Form/Switch/SwitchToggle';
import { SidebarContext } from '../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';
import { CSVLink } from 'react-csv';
import moment from 'moment';


// Export CSV File Headers
const headers = [
    { label: "Shop", key: "shop_information.shop" },
    { label: "Abbreviation", key: "shop_information.abbreviation" },
    { label: "User", key: "username" },
    { label: "published", key: "shop_information.publish" },
    { label: "Active", key: "shop_information.active" },
    { label: "Verified", key: "verified" },
    { label: "VIP", key: "shop_information.is_vip" },
    { label: "Shopmainsort", key: "shopmainsort" },
    { label: "Shopsubsort", key: "shopsubsort" },
    { label: "Buyerratings", key: "buyerratings" },
    { label: "Shopratings", key: "shopratings" },
    { label: "Numofbuyerratings", key: "numofbuyerratings" },
    { label: "Earnigs", key: "earnigs" },
    { label: "Created At", key: "_created_at" },
];

function RegisteredShop(props) {
    const { isLoading, setLoading } = props
    const location = useLocation()
    const [shopDetailModel, setShopDetailModel] = useState(false)
    const [shopData, setShopData] = useState(null)
    const [shopDetails, setShopDetail] = useState({})
    const [shops, setShops] = useState([])
    const [userId, setUserId] = useState("")

    const {
        csvLink,
        dispatch,
        fileLoading,
        setFileLoading,
        totalCount,
        toast,
        deleteModal,
        setDeleteModal,
        handleSubmit,
        selectedDate,
        setSelectedDate,
        setTotalCount,
        sorting,
        resetForm,
        currentPage,
        setcurrentPage,
        itemsPerPage,
        exportsData,
        setExportsData,
        actionPermissionModal,
        setActionPermissionModal,
        exportPermissionModal,
        setExportPermissionModal,
        toggleExportModal,
        handleSorting,
        selectedItem,
        setSelectedItem,
        handleSelectItem,
        exportModal,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
    } = useContext(SidebarContext)

    const [searchParams, setSearchParams] = useState({
        user_search: { _id: "", username: "", from_date: "", to_date: "" },
        shop_search: {
            shop: "", abbreviation: "", active: "", publish: "", is_vip: ""
        }
    })

    useLayoutEffect(() => {
        if (location.state && location.state.currentPage) {
            setcurrentPage(location.state.currentPage)
        }
    }, [])

    useEffect(() => {
        getUsersListData();
    }, [currentPage, sorting])

    useEffect(() => {
        dispatch(cleanShopFormData());
        dispatch(setFormCurrentStep("USER_INFO"))
    }, [])

    const handleSearch = (e) => {
        const { name, value } = e.target
        setSearchParams((prevState) => ({
            ...prevState,
            shop_search: {
                ...prevState.shop_search,
                [name]: value,
            }
        }));
    }

    const handleUserSearch = (e) => {
        const { name, value } = e.target
        setSearchParams((prevState) => ({
            ...prevState,
            user_search: {
                ...prevState.user_search,
                [name]: value,
            }
        }));
    }

    const handleChangeDate = (date, name) => {
        setSearchParams((prevState) => ({
            ...prevState,
            user_search: {
                ...prevState.user_search,
                [name]: date ? moment(date).format("YYYY-MM-DD") : '',
            }
        }));
        setSelectedDate((prevState) => ({
            ...prevState,
            [name]: date,
        }));
    }

    const getUsersListData = async () => {
        const search_params = trimNestedObjValues(searchParams)
        const payload = {
            search: search_params,
            shorting: sorting,
            pagination: { start: currentPage, length: itemsPerPage }
        }
        setLoading(true)
        await dispatch(getRegisteredShops(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setShops(response.data.users)
                        setTotalCount(response.data.total_count)
                    } else {
                        setShops([])
                        setTotalCount(null)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    }

    const getExportingData = async () => {
        const search_params = trimNestedObjValues(searchParams);
        const totalItem = [];
        const fetchShopList = async (pagination) => {
            const payload = {
                search: search_params,
                selected_items: pagination.start === 1 ? selectedItem : [],
                shorting: sorting,
                pagination: pagination
            };
            try {
                setFileLoading(true);
                const response = await dispatch(getRegisteredShops(payload));
                setFileLoading(false);

                if (response.status === "1") {
                    totalItem.push(...response.data.users);
                }
            } catch (error) {
                setFileLoading(false);
                toast.error(error.response?.data?.message);
            }
        };
        if (selectedItem && selectedItem.length > 0) {
            await fetchShopList({ start: 1, length: 1000 });
        } else {
            const pages = Math.ceil(totalCount / 50000);
            for (let i = 1; i <= pages; i++) {
                await fetchShopList({ start: i, length: 50000 });
            }
        }
        const exportData = totalItem?.map((item) => {
            const exportItem = {};
            headers.forEach(header => {
                exportItem[header.label] = item[header.key];
            });
            return exportItem;
        });
        const fileName = `Registered_Shops-${moment(new Date()).format("hh-mm-ss")}.xlsx`
        exportToExcel(exportData, fileName)
        // setExportsData(totalItem);
        //csvLink.current.link.click();
        toast.success("Data exported successfully");
        toggleExportModal();
    };

    const handleReset = async () => {
        const resetFormData = {
            user_search: { _id: "", username: "", from_date: "", to_date: "" },
            shop_search: {
                shop: "", abbreviation: "", active: "", publish: "", is_vip: ""
            }
        }
        setSearchParams(resetFormData)
        resetForm();
    }

    const handleApproveShop = async (event, data) => {
        const { checked } = event.target
        if (checked) {
            const { _p_shopid } = data
            const payload = { shop_id: _p_shopid }
            setLoading(true)
            await dispatch(approveRegisteredShop(payload))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == 1) {
                            toast.success(response.data.message)
                            const result = shops.filter(shop => shop._p_shopid !== _p_shopid)
                            setShops(result)
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                    }
                )
                .catch((error) => console.log("Catch Error", error))
        }
    }

    const handleDelete = async () => {
        let payload = { shop_id: userId }
        setLoading(true)
        await dispatch(removeRegisteredShop(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status == '1') {
                        toast.success(response.data.message)
                        getUsersListData()
                        setDeleteModal(false)
                    }
                    else {
                        const errMsg = response.data.message || response.data.error[0]
                        toast.error(errMsg)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data.message)
                }
            )
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header main_breadcrumb_top">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Registered Shop List</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Shops</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="all_drivers">
                                    <div className="card card-dark">
                                        <div className="card-header">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-6 col-lg-11">
                                                        <h3 className="pl-5">Search</h3>
                                                    </div>
                                                    <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                        <i className="fas fa-angle-up click_search"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form className="form_search" action="">
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label>User name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        onChange={handleUserSearch}
                                                        value={searchParams.user_search.username}
                                                        name="username" placeholder="User name" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Shop name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        onChange={handleSearch}
                                                        value={searchParams.shop_search['shop']}
                                                        name="shop" placeholder="Shop name" />
                                                </div>
                                                <DateInputs selectedDate={selectedDate} handleChangeDate={handleChangeDate} />
                                                <div className="form-group">
                                                    <label>Abbreviation</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        onChange={handleSearch}
                                                        value={searchParams.shop_search['abbreviation']}
                                                        name="abbreviation" placeholder="Abbreviation" />
                                                </div>
                                            </div>
                                            <div className="card-footer text-center">
                                                <button type="reset" className="btn btn-primary mr-2" onClick={handleReset} >Reset</button>
                                                <button type="button" onClick={() => handleSubmit(getUsersListData)} className="btn btn-success">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="row pagination_new">
                                        <div className="col-lg-6 col-12">
                                            <Link to="/user/create-shop"><button type="add" disabled={isLoading} className="btn btn-primary"><i className="fa-regular fa-circle-plus"></i>Create</button></Link>
                                            <button disabled={isLoading} type="button" onClick={() => handleSubmit(getUsersListData)} className="btn btn-secondary ml-2"><i className={`fa-regular fa-rotate-right ${isLoading && 'fa_spinner'}`}></i>Refresh</button>
                                            <button type='button' disabled={fileLoading || !totalCount || isLoading} onClick={toggleExportModal} className="btn btn-info ml-2"><i className="fa-regular fa-circle-down"></i>{fileLoading ? "Loading.." : "Export"}</button>
                                            {/* <CSVLink className='csv-link' target='_blank'
                                                headers={headers}
                                                data={exportsData}
                                                filename={`Shops_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss-A")}`}
                                                ref={csvLink}
                                            /> */}
                                        </div>
                                        {shops?.length > 0 &&
                                            <div className="col-lg-6 col-12">
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    maxPageNumberLimit={maxPageNumberLimit}
                                                    minPageNumberLimit={minPageNumberLimit}
                                                    setcurrentPage={setcurrentPage}
                                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                    setminPageNumberLimit={setminPageNumberLimit}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {shops?.length > 0 &&
                                        <React.Fragment>
                                            <div className="card table_card completed_1">
                                                <div className="card-body all_drive_table">
                                                    <table id="example2" className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th><input type="checkbox" id="check" checked={isAllItemSelected(shops, selectedItem)} onChange={(event) => handleSelectListItem(event, shops, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th>
                                                                <th>More info</th>
                                                                <th>Shop Name
                                                                    {sorting.order_by === "shop" ?
                                                                        <i onClick={() => handleSorting("shop")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("shop")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>Abbreviation</th>
                                                                <th>User Name
                                                                    {sorting.order_by === "username" ?
                                                                        <i onClick={() => handleSorting("username")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("username")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>Active</th>
                                                                <th>Created At
                                                                    {sorting.order_by === "_created_at" ?
                                                                        <i onClick={() => handleSorting("_created_at")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i> :
                                                                        <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>Approve</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {shops.map((item, i) => (
                                                                <tr key={item._id} className="accordion-toggle">
                                                                    <td>
                                                                        <input type="checkbox" className="user_table_checkbox"
                                                                            onChange={(event) => handleSelectItem(event, item._id)}
                                                                            checked={selectedItem.includes(item._id)} />
                                                                    </td>
                                                                    <td>
                                                                        <div className="view-details-btn">
                                                                            <span onClick={() => {
                                                                                setShopDetail(item)
                                                                                setShopDetailModel(!shopDetailModel)
                                                                            }} title='Show details'><i className="fa-regular fa-circle-info fa-lg"></i>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td><span title={item.shop_information.shop}>{setCharacterLimit(item.shop_information.shop)}</span>
                                                                    </td>
                                                                    <td>{item.shop_information.abbreviation ? item.shop_information.abbreviation : "Not Available"}</td>
                                                                    <td className='cursor-pointer' title={item.username}>{setCharacterLimit(item.username)}</td>
                                                                    <td><span className={item.shop_information.active ? 'yes_1' : item.shop_information.active === false ? 'no_1' : 'not_avl'} >
                                                                        {item.shop_information.active ? 'Yes' : item.shop_information.active === false ? "No" : 'NA'}</span>
                                                                    </td>
                                                                    <td>{getFormatedDate(item._created_at)}</td>
                                                                    <td><SwitchToggle isChecked={item.shop_information.publise} handleSwitch={(event) => handleApproveShop(event, item)} /></td>
                                                                    <td>
                                                                        <div className="action_button">
                                                                            <div className="btn-group">
                                                                                <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                                                </button>
                                                                                <div className="dropdown-menu">
                                                                                    <span onClick={() => handleCopyClipboard(item._id)} className="dropdown-item"><span>
                                                                                        <i className="fa-regular fa-copy"></i>Copy User Id</span>
                                                                                    </span>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <Link to={{ pathname: `/edit-shop/${item._id}`, state: { currentPage: currentPage } }} className="dropdown-item"><span className="blue_1">
                                                                                        <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span>
                                                                                    </Link>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <span className="dropdown-item"
                                                                                        onClick={() => {
                                                                                            setUserId(item._p_shopid)
                                                                                            setDeleteModal(true)
                                                                                        }}
                                                                                    >
                                                                                        <span className="red_1">
                                                                                            <i className="fa fa-trash" aria-hidden="true"></i>Delete</span>
                                                                                    </span>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <span className="dropdown-item"
                                                                                        onClick={() => {
                                                                                            setShopData(item)
                                                                                            setActionPermissionModal(true)
                                                                                        }}>
                                                                                        <i className="fa-sharp fa-regular fa-gear">
                                                                                        </i>Action Permission</span>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <span className="dropdown-item"
                                                                                        onClick={() => {
                                                                                            setShopData(item)
                                                                                            setExportPermissionModal(true)
                                                                                        }}>
                                                                                        <i className="fa-regular fa-file-chart-column">
                                                                                        </i>Export Permission</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>)
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row pagination_new">
                                                <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={shops} totalCount={totalCount} />
                                                <div className="col-lg-4">
                                                    <Pagination
                                                        totalCount={totalCount}
                                                        currentPage={currentPage}
                                                        itemsPerPage={itemsPerPage}
                                                        maxPageNumberLimit={maxPageNumberLimit}
                                                        minPageNumberLimit={minPageNumberLimit}
                                                        setcurrentPage={setcurrentPage}
                                                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                        setminPageNumberLimit={setminPageNumberLimit}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {shops?.length === 0 && !isLoading && <NotFoundData />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {exportModal &&
                < ExportModel
                    loader={fileLoading}
                    toggleExportModal={toggleExportModal}
                    exportModal={exportModal}
                    getExportingData={getExportingData}
                    selectedItem={selectedItem}
                    totalCount={totalCount} />
            }
            {shopDetailModel &&
                <ShopDetailsModel
                    shopDetails={shopDetails}
                    modal={shopDetailModel}
                    closeModal={() => setShopDetailModel(false)} />
            }
            {actionPermissionModal &&
                <ActionPermissionModal
                    modal={actionPermissionModal}
                    closeModal={() => setActionPermissionModal(false)}
                    user={shopData}
                    getUserList={setShopData}
                    setLoading={setLoading}
                    isLoading={isLoading}
                />
            }
            {exportPermissionModal &&
                <ExportPermissionModal
                    modal={exportPermissionModal}
                    closeModal={() => setExportPermissionModal(false)}
                    user={shopData}
                    getUserList={setShopData}
                    setLoading={setLoading}
                    isLoading={isLoading}
                />
            }
            {deleteModal && <DeleteConfirmation
                loader={isLoading}
                modal={deleteModal}
                title="Do you want to remove registered shop ?"
                closeModal={() => setDeleteModal(false)}
                handleDelete={handleDelete} />}
        </>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(RegisteredShop))