import { authAxios } from "../../Config/axios"
import { trimObjValues } from "../../Helper"


export const getCities = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/city/get_cities", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const addCity = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/city/add_city", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getCityDetails = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/city/get_cities", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const updateCity = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/city/update_city", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}



