import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDetails } from '../../Redux/action/User';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { weekDays } from '../../utils/selectOptions';
import Select from 'react-select';
import { selectOptionStyle } from '../../Helper';


function ManageShiftTimeModal(props) {
    const dispatch = useDispatch()
    const { setLoading, loader, modal, closeModal, userData, getListData } = props
    const { _id, username, nickname, email, phone, shift_starts_at, shift_ends_at, job_directions, days_off, is_permanent, break_time } = userData ? userData : {}
    const [shiftTime, setShiftTime] = useState({ shift_starts_at: "", shift_ends_at: "" })
    const [breakTime, setBreakTime] = useState({ break_start_time: "", break_end_time: "" })
    const { userGlobalData } = useSelector(state => state.app)
    const { area_direction } = userGlobalData ? userGlobalData : []
    const [selectedDirection, setSelectedDirection] = useState(null)
    const [selectedItems, setSelectedItems] = useState([])


    const formik = useFormik({
        initialValues: {
            user_id: _id,
            is_permanent: '',
            username: username,
            nickname: nickname,
            email: email,
            phone: phone,
            job_directions: [],
            days_off: [],
            shift_starts_at: "",
            shift_ends_at: "",
            break_time: ""
        },
        validationSchema: Yup.object({
            // shift_starts_at: Yup.string()
            //     .required("This field is required"),

            // shift_ends_at: Yup.string()
            //     .required("This field is required"),

            // job_directions: Yup.array()
            //     .required('This field is required')
            //     .min(1, 'This field is required')
        }),
        onSubmit: async values => {
            let fieldValues = { ...values }
            if (formValidate(fieldValues)) {
                if (breakTime.break_start_time && breakTime.break_end_time) {
                    let breakStartTime = moment(breakTime.break_start_time).format("HH:mm")
                    let breakEndTime = moment(breakTime.break_end_time).format("HH:mm")
                    let break_time = `${breakStartTime}-${breakEndTime}`
                    fieldValues['break_time'] = break_time
                }
                setLoading(true)
                await dispatch(updateUserDetails(fieldValues))
                    .then(
                        response => {
                            setLoading(false)
                            if (response.status === '1') {
                                toast.success(response.data.message)
                                getListData()
                                formik.resetForm();
                                closeModal();
                            } else {
                                toast.error(response.data.error[0])
                            }
                        },
                        error => {
                            setLoading(false)
                            toast.error(error.response.data?.message)
                        }
                    ).catch(error => console.log(error))
            }
        }
    });

    const formValidate = (fieldValues) => {
        if (fieldValues.shift_starts_at && !fieldValues.shift_ends_at) {
            toast.error("Please select shift end time")
            return false
        }
        else if (fieldValues.shift_ends_at && !fieldValues.shift_starts_at) {
            toast.error("Please select shift start time")
            return false
        }
        else if (breakTime.break_start_time && !breakTime.break_end_time) {
            toast.error("Please select break end time")
            return false
        }
        else if (breakTime.break_end_time && !breakTime.break_start_time) {
            toast.error("Please select break start time")
            return false
        }
        else {
            return true
        }
    }

    const setTimeField = (fieldName, timeValue) => {
        if (timeValue && timeValue !== "Not Available") {
            formik.setFieldValue(fieldName, timeValue);
            const [hours, minutes] = timeValue.split(':');
            const initialTime = new Date();
            initialTime.setHours(parseInt(hours, 10));
            initialTime.setMinutes(parseInt(minutes, 10));
            return initialTime;
        }
        return null;
    };

    const setBreakTimeField = () => {
        if (break_time && break_time !== "Not Available") {
            let [start_time, end_time] = break_time.split('-');
            if (start_time) {
                const [startHours, startMinutes] = start_time.split(':');
                const startTime = new Date();
                startTime.setHours(parseInt(startHours, 10));
                startTime.setMinutes(parseInt(startMinutes, 10));
                setBreakTime((prevState) => ({
                    ...prevState,
                    break_start_time: startTime,
                }));
            }
            if (end_time) {
                const [endHours, endMinutes] = end_time.split(':');
                const endTime = new Date();
                endTime.setHours(parseInt(endHours, 10));
                endTime.setMinutes(parseInt(endMinutes, 10));
                setBreakTime((prevState) => ({
                    ...prevState,
                    break_end_time: endTime,
                }));
            }
        }
    }

    useEffect(() => {
        const selectedStartTime = setTimeField('shift_starts_at', shift_starts_at);
        const selectedEndTime = setTimeField('shift_ends_at', shift_ends_at);
        setShiftTime((prevState) => ({
            ...prevState,
            shift_starts_at: selectedStartTime,
            shift_ends_at: selectedEndTime,
        }));
        setBreakTimeField()
        const selectedValue = job_directions ? job_directions : []
        const selected_off_days = days_off ? days_off : []
        const selectedOptions = area_direction.filter(({ value: id1 }) => selectedValue.some((id2) => id2 === id1))
        setSelectedDirection(selectedOptions)
        setSelectedItems(selected_off_days)
        formik.setFieldValue('job_directions', selectedValue)
        formik.setFieldValue('days_off', selected_off_days)
        formik.setFieldValue('is_permanent', is_permanent)
    }, [shift_starts_at, shift_ends_at, break_time]);


    const handleChangeTime = (selectedTime, fieldName) => {
        setShiftTime((prevState) => ({
            ...prevState,
            [fieldName]: selectedTime,
        }));
        if (selectedTime) {
            const selected_time = moment(selectedTime).format("HH:mm")
            formik.setFieldValue(fieldName, selected_time)
        }
        else {
            formik.setFieldValue(fieldName, "")
        }
    }

    const handleChangeBreakTime = (selectedTime, fieldName) => {
        setBreakTime((prevState) => ({
            ...prevState,
            [fieldName]: selectedTime,
        }));
    }

    const handleSelect = (options) => {
        setSelectedDirection(options)
        const dummyArr = []
        options.map(item => dummyArr.push(item.value))
        formik.setFieldValue('job_directions', dummyArr);
    }

    const handleSelectItem = (event, order_id) => {
        const is_checked = event.target.checked
        const selected_item = [...selectedItems]
        if (is_checked) {
            selected_item.push(order_id)
        }
        else {
            const index = selected_item.indexOf(order_id);
            if (index > -1) {
                selected_item.splice(index, 1);
            }
        }
        setSelectedItems(selected_item)
        formik.setFieldValue('days_off', selected_item)
    }

    const handleChange = (e) => {
        let { name, value } = e.target
        value = JSON.parse(value)
        formik.setFieldValue(name, value)
    }

    return (
        <div className={`modal fade cs--modal ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-info" id="exampleModalLongTitle">Manage Driver Schedule</h5>
                            <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body overflow-auto modal--body--scroll">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            defaultValue={username}
                                            placeholder='Driver Name'
                                            disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label">Direction</label>
                                        <Select
                                            value={selectedDirection}
                                            onChange={handleSelect}
                                            options={area_direction}
                                            placeholder="Select Direction"
                                            isClearable={true}
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            styles={selectOptionStyle}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label">Permanent</label>
                                        <select className="form-control" name='is_permanent' onChange={handleChange} value={formik.values.is_permanent}>
                                            <option disabled value="">Select an option</option>
                                            <option value={true}>Permanent</option>
                                            <option value={false}>Temporary</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label">Shift Start Time</label>
                                        <DatePicker
                                            className="form-control date-field ub-l cursor-pointer" placeholderText="Start Time"
                                            selected={shiftTime.shift_starts_at}
                                            showIcon={shiftTime.shift_starts_at ? false : true}
                                            onChange={(time) => handleChangeTime(time, 'shift_starts_at')}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Start Time"
                                            dateFormat="HH:mm"
                                            timeFormat="HH:mm"
                                            isClearable={shiftTime.shift_starts_at ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label">Shift End Time</label>
                                        <DatePicker
                                            className="form-control date-field ub-l cursor-pointer" placeholderText="End Time"
                                            selected={shiftTime.shift_ends_at}
                                            showIcon={shiftTime.shift_ends_at ? false : true}
                                            onChange={(time) => handleChangeTime(time, 'shift_ends_at')}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="End Time"
                                            dateFormat="HH:mm"
                                            timeFormat="HH:mm"
                                            isClearable={shiftTime.shift_ends_at ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                            {shiftTime.shift_starts_at && shiftTime.shift_ends_at &&
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="control-label">Break Start Time</label>
                                            <DatePicker
                                                className="form-control date-field ub-l cursor-pointer" placeholderText="Start Time"
                                                selected={breakTime.break_start_time}
                                                showIcon={breakTime.break_start_time ? false : true}
                                                onChange={(time) => handleChangeBreakTime(time, 'break_start_time')}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Start Time"
                                                dateFormat="HH:mm"
                                                timeFormat="HH:mm"
                                                isClearable={breakTime.break_start_time ? true : false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="control-label">Break End Time</label>
                                            <DatePicker
                                                className="form-control date-field ub-l cursor-pointer" placeholderText="End Time"
                                                selected={breakTime.break_end_time}
                                                showIcon={breakTime.break_end_time ? false : true}
                                                onChange={(time) => handleChangeBreakTime(time, 'break_end_time')}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="End Time"
                                                dateFormat="HH:mm"
                                                timeFormat="HH:mm"
                                                isClearable={breakTime.break_end_time ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="form-group">
                                        <label className="control-label">Week Off</label>
                                        <div className="card bg-light">
                                            <div className="card-body">
                                                <div className='row'>
                                                    {weekDays.map((item, index) => (
                                                        <div key={index + 1} className="col-sm-3">
                                                            <div className="form-check select-user">
                                                                <input className="form-check-input" type="checkbox" onChange={(event) => handleSelectItem(event, item.value)}
                                                                    checked={selectedItems.includes(item.value)} id={item.value} />
                                                                <label className="form-check-label" htmlFor={item.value}>
                                                                    {item.label}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button disabled={loader} type="submit" className="btn btn-success"> {loader ? "Processing.." : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default ManageShiftTimeModal
