import React, { useState, useEffect } from 'react';
import { removeOpenOrder, setNotifyOrder, setOrderETA } from '../../../Redux/action/App';
import { useSelector } from 'react-redux';
import Sound from 'react-sound';
import { toast } from 'react-toastify';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { logOut } from '../../../Redux/action/Auth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { shopAppUrl } from '../../../Config/constants';
import { io } from "socket.io-client";
import { getAdminUserDetails } from '../../../Redux/action/User';
import { getGlobalApi, getUserGlobalApi } from '../../../Redux/action/App';


dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault(process.env.REACT_APP_TIMEZONE);

function TopNavBar() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { user, sub_user } = useSelector(state => state.auth)
    const { drivers, allowed_permission, assigned_clients } = user
    const logisticsPermissions = allowed_permission ? allowed_permission : []
    const logisticsDrivers = drivers ? drivers : []
    const [orderNotifications, setOrderNotifications] = useState([])
    const [arrivalMessage, setArrivalMessage] = useState(null);
    const [playSound, setPlaySound] = useState(false);
    const { profile_image } = useSelector(state => state.auth)
    const { branchesArr } = useSelector(state => state.shop)

    const allUsers = ["Admin", "Shop", "Integrator", "ShopVendor"]
    let socketEvent = "notification"
    let userTypes = ["Shop", "Integrator", "ShopVendor"]
    if (userTypes.includes(user.type)) {
        socketEvent = "notification-" + user._id
    }

    useEffect(() => {
        getAdminUserDetail();
        fetchGlobalData();
        fetchUserGlobalData();
    }, [])

    const getAdminUserDetail = async () => {
        await dispatch(getAdminUserDetails({ user_id: user._id }))
            .then(
                response => {
                    if (response.status === "1") {
                        const user = response.data?.user
                        if (user && user._id) {
                            dispatch({ type: "USER_DATA_UPDATE", payload: user });
                        }
                    }
                },
                (error) => {
                    toast.error(error.response.data?.message)
                }
            )
            .catch(err => {
                console.log(err.response.data.message)
            })
    }


    const fetchGlobalData = () => {
        dispatch(getGlobalApi())
        .then(
            response => {
            },
            (error) => {
                console.log(error.response.data?.message)
            }
        )
        .catch(err => {
            console.log(err.response.data.message)
        })
    }

    const fetchUserGlobalData = async () => {
       await dispatch(getUserGlobalApi())
        .then(
            response => {
            },
            (error) => {
                console.log(error.response.data?.message)
            }
        )
        .catch(err => {
            console.log(err.response.data.message)
        })
    }

    const handleSoundFinishedPlaying = () => {
        setPlaySound(false);
    };

    const handleSocketEvent = (data) => {
        if (!data) return;

        const { order, messageType } = data;
        if (!order) return;

        const { shopid, driverid } = order;

        const notifyAndSave = () => {
            dispatch(setNotifyOrder(order));
            saveMessageWithToaster(data);
        };

        const isClientAssigned = assigned_clients && assigned_clients.includes(shopid);
        const isDriverAssigned = logisticsDrivers?.includes(driverid);
        const canViewCreatedOrders = logisticsPermissions.includes("View-Created-Orders");

        if (allUsers.includes(user.type)) {
            if (assigned_clients?.length > 0 && user.type === "Admin") {
                if (isClientAssigned) {
                    notifyAndSave();
                }
            } else {
                notifyAndSave();
            }
        } else if (user.type === "Logistics") {
            if (logisticsDrivers?.length > 0 && isDriverAssigned) {
                notifyAndSave();
            } else if (canViewCreatedOrders && messageType === "CreateOrder") {
                notifyAndSave();
            } else {
                dispatch(removeOpenOrder(order));
            }
        }
    };

    const handleOrderEtaUpdate = (data) => {
        dispatch(setOrderETA(data));
    };

    const showConfirmation = (data) => {
        console.log("Confirm__Data", data)
        if (!data || data.user_id !== user._id) return;

        const { status, message } = data;
        const toastOptions = { position: toast.POSITION.TOP_CENTER, autoClose: false };

        if (status === 'success') {
            toast.success(message, toastOptions);
        } else {
            toast.error(message, toastOptions);
        }
    }


    useEffect(() => {
        const socket = io(process.env.REACT_APP_BASEURL, { transports: ['polling'] });
        const shopSocket = shopAppUrl ? io(shopAppUrl, { transports: ['polling'] }) : null;

        socket.on("confirm_notification", data => showConfirmation(data));

        socket.on(socketEvent, data => handleSocketEvent(data));
        socket.on("order_eta_update", data => handleOrderEtaUpdate(data));

        if (shopSocket) {
            shopSocket.on(socketEvent, data => handleSocketEvent(data));
        }

        // if (user.type === 'Logistics') {
        //     connectSocketForDSP(socket);
        // }

        if (branchesArr && branchesArr.length > 1) {
            branchesArr.forEach((branchId) => {
                socket.on(`notification-${branchId}`, data => {
                    handleSocketEvent(data)
                })
            })
        }

        return () => {
            socket.disconnect();
            if (shopSocket) {
                shopSocket.disconnect();
            }
        };
    }, [socketEvent, shopAppUrl, user, branchesArr]);


    useEffect(() => {
        if (arrivalMessage) {
            let orderExists = orderNotifications.some(item => item.ordernumber === arrivalMessage.ordernumber);
            if (!orderExists) {
                setOrderNotifications((prev) => [...prev, arrivalMessage]);
                setPlaySound(true)
            }
        }
    }, [arrivalMessage]);

    const saveMessageWithToaster = (data) => {
        if (data.messageType === "CreateOrder") {
            toast.info(`#${data?.order?.ordernumber} has been created`)
            setArrivalMessage(data.order);
        }
        else if (data.messageType === 'AssignDriver') {
            toast.info(`#${data?.order?.ordernumber} has been assigned`)
        }
        else if (data.messageType === 'HoldOrder') {
            toast.info(`#${data?.order?.ordernumber} has been placed on hold`)
        }
        else if (data.messageType === 'ResumeOrder') {
            toast.info(`#${data?.order?.ordernumber} has been resumed`)
        }
        else if (data.messageType === 'AcceptOrder') {
            toast.info(`#${data?.order?.ordernumber} has been accepted`)
        }
        else if (data.messageType === 'ArriveToShop') {
            toast.info(`#${data?.order?.ordernumber}, driver has beeen arrived`)
        }
        else if (data.messageType === 'PickOrder') {
            toast.info(`#${data?.order?.ordernumber} has been picked`)
        }
        else if (data.messageType === 'CompleteOrder') {
            toast.info(`#${data?.order?.ordernumber} has been delivered`)
        }
        else if (data.messageType === 'CancelOrder') {
            toast.info(`#${data?.order?.ordernumber} has been canceled`)
        }
        else if (data.messageType === 'UpdateOrder') {
            toast.info(`#${data?.order?.ordernumber} has been updated`)
        }
    }

    // const connectSocketForDSP = (socket) => {
    //     if (logisticsDrivers.length > 0) {
    //         drivers.forEach((driverId) => {
    //             socket.on(`notification-${driverId}`, data => {
    //                 if (data.messageType === 'AssignDriver') {
    //                     toast.info(`#${data?.order?.ordernumber} has been assigned`)
    //                     setArrivalMessage(data.order);
    //                 }
    //                 else if (data.messageType === 'AcceptOrder') {
    //                     toast.info(`#${data?.order?.ordernumber} has been accepted`)
    //                     setArrivalMessage(data.order);
    //                 }
    //                 else if (data.messageType === 'ArriveToShop') {
    //                     toast.info(`#${data?.order?.ordernumber}, driver has beeen arrived`)
    //                 }
    //                 else if (data.messageType === 'PickOrder') {
    //                     toast.info(`#${data?.order?.ordernumber} has been picked`)
    //                 }
    //                 else if (data.messageType === 'CompleteOrder') {
    //                     toast.info(`#${data?.order?.ordernumber} has been delivered`)
    //                 }
    //                 else if (data.messageType === 'CancelOrder') {
    //                     toast.info(`#${data?.order?.ordernumber} has been canceled`)
    //                 }
    //             })
    //         })
    //     }
    // }

    const handleLogOut = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Utrac</h1>
                        <p>Do you want to exit from this App?</p>
                        <button onClick={onClose}>No</button>
                        <button
                            onClick={() => {
                                dispatch(logOut())
                                history.push('/login')
                                onClose()
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            }
        });
    }

    const clearAllNotification = () => {
        setOrderNotifications([])
    }

    const removeSpecificNotification = (index) => {
        let allNotifications = [...orderNotifications]
        if (index > -1) {
            allNotifications.splice(index, 1);
            setOrderNotifications(allNotifications)
        }
    }
    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light nav_bar_top">
                <ul className="navbar-nav">
                </ul>
                <ul className="navbar-nav ml-auto">

                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="/#">
                            <img src={require("../../../assets/dist/img/dashboard/bell.png")} alt="bell-btn" />
                            {orderNotifications && orderNotifications.length > 0 && <span className="badge badge-warning navbar-badge">
                                {orderNotifications.length}</span>
                            }
                        </a>
                        <div className="dropdown-menu notify--drop  dropdown-menu-lg dropdown-menu-right">
                            <span className="dropdown-item dropdown-header">{orderNotifications.length} Notifications</span>
                            <div className="dropdown-divider"></div>
                            {orderNotifications && orderNotifications.length > 0 &&
                                <div className='notifications'>
                                    {orderNotifications.slice(0).reverse().map((item, index) =>
                                        <a key={index + 1} className="dropdown-item border border-2">
                                            <i className="fa-sharp fa-solid fa-xmark" onClick={() => removeSpecificNotification(index)}></i>
                                            <span>New order <span className='pl-3 text-capitalize'> {`${item.ordernumber}`}</span></span>
                                            <span className="float-right text-muted text-sm">{dayjs(item._created_at).fromNow()}</span>
                                        </a>
                                    )}
                                    <div className="dropdown-divider"></div>
                                </div>}
                            {orderNotifications && orderNotifications.length > 0 &&
                                <div className="dropdown-item dropdown-footer clear-notifications">
                                    <span onClick={clearAllNotification}>Clear All Notifications</span>
                                </div>
                            }
                        </div>
                    </li>
                </ul>
                <div className="login_details">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="/#" role="button">
                                <img style={{ width: '38px', height: '38px', padding: '1px', borderRadius: "50%" }} src={profile_image} alt="user-logo" />
                                <span className='text-capitalize font-weight-bold ml-2'>{sub_user?.username || user?.username}</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <span onClick={handleLogOut} className="dropdown-item ">
                                    <i className="fas fa-sign-out-alt mr-2"></i>
                                    <span>Log out</span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* <audio autoPlay ref={audioPlayer} src={NotificationSound} /> */}
            <Sound
                url="https://utracmenu.s3.amazonaws.com/order/1701418632660-alert.mpeg"
                playStatus={playSound ? Sound?.status?.PLAYING : Sound?.status?.STOPPED}
                onFinishedPlaying={handleSoundFinishedPlaying}
            />
        </>
    )
}

export default TopNavBar