import React from 'react';
import { useDispatch } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { addPrice } from '../../Redux/action/price';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

function AddPrice(props) {
  const { setLoading } = props
  const dispatch = useDispatch()
  const history = useHistory()


  const formik = useFormik({
    initialValues: {
      title: '',
      delivery_fee: '',
      utrac_fee: '',
      tax_fee: ''
    },

    validationSchema: Yup.object({
      title: Yup.string()
        .required("This fiels is required")
        .min(3, 'This field must be at least 3 characters'),

      delivery_fee: Yup.number()
        .typeError('You must specify a number')
        .min(0, "Please enter a positive number")
        .required("This field is required"),

      utrac_fee: Yup.number()
        .typeError('You must specify a number')
        .min(0, "Please enter a positive number")
        .required("This field is required"),

      tax_fee: Yup.number()
        .typeError('You must specify a number')
        .min(0, "Please enter a positive number")
        .required("This field is required"),
    }),

    onSubmit: async values => {
      const fieldValues = values
      setLoading(true)
      await dispatch(addPrice(fieldValues))
        .then(
          response => {
            setLoading(false)
            if (response.status === '1') {
              toast.success(response.data.message)
              formik.resetForm();
              history.push('/prices')
            } else {
              toast.error(response.data.error[0])
            }
          },
          error => {
            setLoading(false)
            toast.error(error.response.data?.message)
          }
        )
        .catch(error => console.log(error)
        )
    }
  });


  return (
    <div className="content-wrapper">
      <div className="content-header main_breadcrumb_top">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Create new price</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <form onSubmit={formik.handleSubmit} >
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Title</label>
                          <input
                            required
                            name="title"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                            placeholder='Title name'
                            type="text" className="form-control" />
                          {formik.touched.title && formik.errors.title ? (<span className="text-danger">{formik.errors.title}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Delivery Fee</label>
                          <input
                            required
                            name="delivery_fee"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Delivery fee'
                            value={formik.values.delivery_fee}
                            type="number" className="form-control"
                          />
                          {formik.touched.delivery_fee && formik.errors.delivery_fee ? (<span className="text-danger">{formik.errors.delivery_fee}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Utrac Fee</label>
                          <input
                            required
                            name="utrac_fee"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Utrac fee'
                            value={formik.values.utrac_fee}
                            type="number" className="form-control" />
                          {formik.touched.utrac_fee && formik.errors.utrac_fee ? (<span className="text-danger">{formik.errors.utrac_fee}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Tax Fee</label>
                          <input
                            required
                            name="tax_fee"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Tax fee'
                            value={formik.values.tax_fee}
                            type="number" className="form-control" />
                          {formik.touched.tax_fee && formik.errors.tax_fee ? (<span className="text-danger">{formik.errors.tax_fee}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 form-foot-btn">
                      <button type="button" className="btn btn-primary mr-2" onClick={() => history.push('/prices')}>Back</button>
                      <button type="submit" className="btn btn-success ">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default IsLoadingHOC(IsLoggedinHOC(AddPrice))