import axios from "axios";
import { store } from '../Redux/store';

const apiUrl = `${process.env.REACT_APP_BASEURL}/api`;

export const authAxios = () => {
    let token = store.getState('state').auth.token;
    return axios.create({
        baseURL: apiUrl,
        headers: {
            'x-access-token' : `${token ? `${token}` : null}`,
        },
    });
};

export const globalAxios = (accessToken) => {
    let token = accessToken;
    return axios.create({
        baseURL: apiUrl,
        headers: {
            'x-access-token' : `${token ? `${token}` : null}`,
        },
    });
};

export const withoutAuthAxios = () => {
    return axios.create({
        baseURL: apiUrl,
    });
};

export const axiosWithHashKey = (hash_key) => {
    return axios.create({
        baseURL: apiUrl,
        headers: {
            'hash-signature' : `${hash_key ? `${hash_key}` : null}`,
        },
    });
};