import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { isAllItemSelected, handleSelectListItem, getFormatedDate } from '../../Helper';
import { getDeliveryZoneDetails, updateDeliveryZone } from './../../Redux/action/DeliveryZone';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import Pagination from '../../Components/Pagination';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import TableFooter from '../../Components/TableFooter';
import { getUsersList } from '../../Redux/action/User';
import DeleteConfirmation from '../../Components/ConfirmModel/Delete';
import { saveSelectedUsers } from '../../Redux/action/App';
import { SidebarContext } from '../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';
import { useSelector } from 'react-redux';



function DeliveryZoneShopList(props) {
    const { isLoading, setLoading } = props
    const location = useLocation()
    const history = useHistory()
    const params = useParams()
    const [shops, setShops] = useState([])
    const { selectedUsers, deliveryZoneTitle } = useSelector(state => state.app)
    const [deliveryZone, setDeliveryZone] = useState({})

    const {
        dispatch,
        totalCount,
        toast,
        setTotalCount,
        currentPage,
        setcurrentPage,
        itemsPerPage,
        sorting,
        selectedItem,
        setSelectedItem,
        deleteModal,
        setDeleteModal,
        handleSelectItem,
        handleSorting,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
    } = useContext(SidebarContext)

    const [searchParams] = useState({
        type: "",
        nickname: "",
        username: "",
        phone: "",
        from_date: "",
        to_date: "",
        verified: "",
        available: "",
        online: "",
    })

    useLayoutEffect(() => {
        if (location.state && location.state.currentPage) {
            setcurrentPage(location.state.currentPage)
        }
    }, [])

    useEffect(() => {
        getDeliveryZoneDetailsData()
    }, [])

    const getDeliveryZoneDetailsData = async () => {
        const payload = {
            is_detail: "true",
            search: { removed: "", _id: params.id },
            shorting: { order_in: -1, order_by: "_created_at" }
        }
        setLoading(true)
        await dispatch(getDeliveryZoneDetails(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const resData = response.data.delivery_zone
                        setDeliveryZone(resData)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                })
            .catch(error => {
                console.log(error)
            }
            )
    }

    useEffect(() => {
        if (selectedUsers && selectedUsers.length > 0) {
            getShopListData();
        }
        else {
            setShops([])
            setTotalCount(null)
        }
    }, [currentPage, sorting, selectedUsers])

    const getShopListData = async () => {
        const payload = {
            search: { ...searchParams, _id: selectedUsers.join(',') },
            shorting: { order_in: sorting.order_in, order_by: sorting.order_by },
            pagination: { start: currentPage, length: itemsPerPage }
        }
        setLoading(true)
        await dispatch(getUsersList(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        setShops(response.data.users)
                        setTotalCount(response.data.total_count)
                    } else {
                        toast.error(response.data.error[0])
                        setShops([])
                        setTotalCount(null)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    }

    const removeShopFromDeliveryZone = async () => {
        let fieldValues = { ...deliveryZone, delivery_zone_id: params.id }
        if (fieldValues?._id && fieldValues?.selected_users) {
            const selected_users = fieldValues?.selected_users
            const filteredSelectedUsers = selected_users.filter(item => !selectedItem.includes(item));
            fieldValues['selected_users'] = filteredSelectedUsers
            setLoading(true)
            await dispatch(updateDeliveryZone(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == '1') {
                            toast.success(response.data.message)
                            setDeleteModal(false)
                            setSelectedItem([])
                            dispatch(saveSelectedUsers(filteredSelectedUsers))
                        } else {
                            const errMsg = response.data.message || response.data.error[0]
                            toast.error(errMsg)
                        }
                    },
                    (error) => {
                        setLoading(false);
                        toast.error(error.response.data?.message)
                    })
                .catch(error => console.log(error))
        }
    }


    return (
        <>
            <div className="content-wrapper">
                <div className="content-header main_breadcrumb_top">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Delivery Zone Shops ({deliveryZoneTitle})</h1>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="all_drivers">
                                    <div className="card card-dark">
                                        <div className="card-header">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-6 col-lg-11">
                                                    </div>
                                                    <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pagination_new">
                                        <div className="col-lg-6 col-12">
                                            <button disabled={isLoading} type="button" onClick={() => history.goBack()} className="btn btn-primary"><i className="fa-solid fa-circle-arrow-left"></i>Back</button>
                                            {selectedItem && selectedItem.length > 0 && <button disabled={isLoading} onClick={() => setDeleteModal(true)} type="button" className="btn btn-danger ml-2"><i className="fa-sharp fa-solid fa-trash"></i>Delete</button>}
                                        </div>
                                        {shops?.length > 0 &&
                                            <div className="col-lg-6 col-12">
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    maxPageNumberLimit={maxPageNumberLimit}
                                                    minPageNumberLimit={minPageNumberLimit}
                                                    setcurrentPage={setcurrentPage}
                                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                    setminPageNumberLimit={setminPageNumberLimit}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {shops?.length > 0 &&
                                        <React.Fragment>
                                            <div className="card table_card completed_1">
                                                <div className="card-body all_drive_table">
                                                    <table id="example2" className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th><input type="checkbox" id="check" checked={isAllItemSelected(shops, selectedItem)} onChange={(event) => handleSelectListItem(event, shops, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th>
                                                                <th>Nick Name
                                                                    {sorting.order_by === "nickname" ?
                                                                        <i onClick={() => handleSorting("nickname")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("nickname")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>User Name
                                                                    {sorting.order_by === "username" ?
                                                                        <i onClick={() => handleSorting("username")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("username")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>Published</th>
                                                                <th>Active</th>
                                                                <th>Created At
                                                                    {sorting.order_by === "_created_at" ?
                                                                        <i onClick={() => handleSorting("_created_at")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i> :
                                                                        <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {shops.map((item) => (
                                                                <tr key={item._id} className="accordion-toggle">
                                                                    <td>
                                                                        <input type="checkbox" className="user_table_checkbox"
                                                                            onChange={(event) => handleSelectItem(event, item._id)}
                                                                            checked={selectedItem.includes(item._id)} />
                                                                    </td>
                                                                    <td>{item.nickname ? item.nickname : "Not available"}</td>
                                                                    <td>{item.username ? item.username : "Not available"}</td>
                                                                    <td><span className={item.shop_information.publish ? 'yes_1' : item.shop_information.publish === false ? 'no_1' : 'not_avl'} >
                                                                        {item.shop_information.publish ? 'Yes' : item.shop_information.publish === false ? "No" : 'NA'}</span>
                                                                    </td>
                                                                    <td><span className={item.shop_information.active ? 'yes_1' : item.shop_information.active === false ? 'no_1' : 'not_avl'} >
                                                                        {item.shop_information.active ? 'Yes' : item.shop_information.active === false ? "No" : 'NA'}</span>
                                                                    </td>
                                                                    <td>{getFormatedDate(item._created_at)}
                                                                    </td>
                                                                </tr>)
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row pagination_new">
                                                <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={shops} totalCount={totalCount} />
                                                <div className="col-lg-4">
                                                    <Pagination
                                                        totalCount={totalCount}
                                                        currentPage={currentPage}
                                                        itemsPerPage={itemsPerPage}
                                                        maxPageNumberLimit={maxPageNumberLimit}
                                                        minPageNumberLimit={minPageNumberLimit}
                                                        setcurrentPage={setcurrentPage}
                                                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                        setminPageNumberLimit={setminPageNumberLimit}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {shops?.length === 0 && !isLoading && <NotFoundData />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {deleteModal && <DeleteConfirmation
                loader={isLoading}
                modal={deleteModal}
                title="Do you want to remove from delivery zone"
                closeModal={() => setDeleteModal(false)}
                handleDelete={removeShopFromDeliveryZone} />}
        </>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(DeliveryZoneShopList))