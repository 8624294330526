import React, { useState } from 'react';
import { getFormatedDate } from '../../Helper';
import { useEffect } from 'react';
import { acceptedFileTypes } from '../../Config/constants';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { uploadDriverDocument } from '../../Redux/action/Media';


function DriverDocuments(props) {
    const dispatch = useDispatch()
    const { modal, closeModal, driverDetails, setLoading, getListData } = props
    const { _id, user_documents, username } = driverDetails ? driverDetails : {}
    const [isShowList, setShowList] = useState(false)
    const [userDocuments, setUserDocuments] = useState([
        { title: "driving_license" },
        { title: "driver_iqama" },
        { title: "vehicle_registration" },
        { title: "vehicle_photo" },
        { title: "driver_photo" }
    ])

    useEffect(() => {
        if (user_documents && user_documents.length > 0) {
            const uploadedDocs = userDocuments.map(doc => {
                const result = user_documents.find(upload => upload.title === doc.title);
                if (result) {
                    return {
                        ...doc,
                        media: result.media,
                        date: result.date
                    };
                } else {
                    return doc;
                }
            });
            setUserDocuments(uploadedDocs)
        }
        setShowList(true)
    }, [])


    const replaceUnderScoreToSpace = (text) => {
        if (text) {
            let new_text = text.replace('_', ' ');
            return new_text
        }
    }

    const uploadDocFile = async (event, index) => {
        setLoading(true)
        const doc_file = event.target.files[0]
        const { name } = event.target
        var formData = new FormData();
        formData.append('user_id', _id);
        formData.append('title', name);
        formData.append('media', doc_file);
        dispatch(uploadDriverDocument(formData))
            .then(
                response => {
                    setLoading(false)
                    if (response.status == '1') {
                        toast.success('Document uploaded successfully')
                        getListData()
                        const arrayOfObjects = [...userDocuments]
                        const uploadedDoc = response.data
                        arrayOfObjects.splice(index, 1, uploadedDoc);
                        setUserDocuments(arrayOfObjects)

                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                error => {
                    const message = (error.response && error.response.statusText) || "something went wrong"
                    toast.error(message)
                    setLoading(false)
                }
            )
            .catch(error => {
                console.log(error)
                toast.error(error.message)
                setLoading(false)
            }
            )
    }

    return (
        <div id="myModal" className={`modal fade cs--modal ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary">{username} 's  Documents</h5>
                        <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div className="modal-body overflow-auto modal--body--scroll">
                        <div className="card-model table-responsive">
                            <table className="table table-striped table-valign-middle">
                                <thead>
                                    <tr>
                                        <th>SN.</th>
                                        <th>Document name</th>
                                        <th>Uploaded At</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isShowList &&
                                        userDocuments.map((item, index) => (
                                            <tr key={index + 1}>
                                                <td>{index + 1.}</td>
                                                <td>{replaceUnderScoreToSpace(item.title)}</td>
                                                <td>{item?.date ? getFormatedDate(item.date) : "Not available"}</td>
                                                {item.media ?
                                                    <td>
                                                        <span className="btn btn-primary btn-sm btn-file">
                                                            <i className="fa-regular fa-circle-up mr-1"></i>Update<input accept={acceptedFileTypes} onChange={(event) => uploadDocFile(event, index)} name={item.title} type="file" />
                                                        </span>
                                                        <a href={item?.media}>
                                                            <button className="btn btn-success btn-sm ml-2">
                                                                <i className="fa-regular fa-circle-down mr-1"></i>Download</button>
                                                        </a>
                                                    </td>
                                                    :
                                                    <td>
                                                        <span className="btn btn-primary btn-sm btn-file">
                                                            <i className="fa-regular fa-circle-up mr-1"></i>Upload<input accept={acceptedFileTypes} onChange={(event) => uploadDocFile(event, index)} name={item.title} type="file" />
                                                        </span>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DriverDocuments;

