let initialState = {
    user: "",
    token: "",
    sub_user: "",
    profile_image: "",
    foodics_user : "",
    foodics_user_token : ""
}


export default function auth(state = initialState, action) {
    switch (action.type) {
        case "USER_DATA_UPDATE":
            return { ...state, user: action.payload }
        case "SAVE_TOKEN":
            return { ...state, token: action.payload }
        case "SAVE_PROFILE_IMAGE":
            return { ...state, profile_image: action.payload }
        case "SAVE_SUB_USER":
            return { ...state, sub_user: action.payload }
        case "LOGOUT":
            return { ...state, user: "", token: "", sub_user: "" }
        case "SAVE_FOODICS_USER":
            return { ...state, foodics_user: action.payload }
        case "SAVE_FOODICS_USER_TOKEN":
            return { ...state, foodics_user_token: action.payload }
        default:
            return state
    }
}