import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { getSubCategories, getMainCategories } from '../../Redux/action/Category';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import Pagination from '../../Components/Pagination';
import { default as ReactSelect } from "react-select";
import { Link, useParams, useLocation } from 'react-router-dom';
import { trimObjValues, customStyles, getFormatedDate } from '../../Helper';
import TableFooter from '../../Components/TableFooter';
import { SidebarContext } from '../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';


function SubCategories(props) {
   const { isLoading, setLoading } = props
   const params = useParams()
   const location = useLocation()
   const [subCategories, setSubCategories] = useState([])
   const [categories, setcategories] = useState([])
   const [selectedvalue, setSelectedvalue] = useState(null)
   const [sorting, setSorting] = useState({ order_in: 1, order_by: "subcategory" })
   const {
      dispatch,
      totalCount,
      setTotalCount,
      toast,
      resetForm,
      handleSubmit,
      currentPage,
      setcurrentPage,
      itemsPerPage,
      maxPageNumberLimit,
      setmaxPageNumberLimit,
      minPageNumberLimit,
      setminPageNumberLimit,
   } = useContext(SidebarContext)

   const [searchParams, setSearchParams] = useState({
      _p_maincategory: params.id ? params.id : "",
      _id: "",
      subcategory: "",
      num: "",
   })

   useLayoutEffect(() => {
      if (location.state && location.state.currentPage) {
         setcurrentPage(location.state.currentPage)
      }
   }, [])

   useEffect(() => {
      getMainCategoriesData();
   }, [])

   const getMainCategoriesData = async () => {
      const payload = {
         search: { _id: "", category: "", num: "", active: "" },
         shorting: { order_in: 1, order_by: "category" },
         pagination: { start: 1, length: 10000 }
      }
      await dispatch(getMainCategories(payload))
         .then(
            response => {
               if (response.status === "1") {
                  const result = (response.data && response.data.categories) || []
                  const mainCategories = []
                  result.forEach(item => {
                     mainCategories.push({ label: item.category, value: item._id })
                  })
                  setcategories(mainCategories)
               } else {
                  toast.error(response.data?.error[0])
                  setcategories([])
               }
            },
            (error) => {
               setLoading(false);
               toast.error(error.response.data?.message)
            }
         )
   }

   useEffect(() => {
      getSubCategoriesData();
   }, [currentPage, sorting])

   const getSubCategoriesData = async () => {
      const search_params = trimObjValues(searchParams)
      const payload = {
         search: search_params,
         shorting: sorting,
         pagination: { start: currentPage, length: itemsPerPage }
      }
      setLoading(true)
      await dispatch(getSubCategories(payload))
         .then(
            response => {
               setLoading(false);
               if (response.status === "1") {
                  setSubCategories(response.data.categories)
                  setTotalCount(response.data.total_count)
               } else {
                  toast.error(response.data.error[0])
                  setSubCategories([])
                  setTotalCount(null)
               }
            },
            (error) => {
               setLoading(false);
               toast.error(error.response.data?.message)
            }
         )
   }

   const handleSearch = (e) => {
      const { name, value } = e.target
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value,
      }));
   }

   const handleSelect = (option, name) => {
      const { value } = option ? option : {}
      setSelectedvalue(option)
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value || "",
      }));
   }

   const handleReset = async () => {
      const resetFormData = { _p_maincategory: params.id ? params.id : "", _id: "", subcategory: "", num: "" }
      setSearchParams(resetFormData)
      resetForm();
      setSelectedvalue(null)
      setSorting({ order_in: 1, order_by: "subcategory" })
   }

   const handleSorting = (orderby) => {
      setSorting((prevState) => ({
         ...prevState,
         order_by: orderby,
         order_in: prevState.order_in === -1 ? 1 : -1
      }));
   }

   return (
      <div className="content-wrapper">
         <div className="content-header main_breadcrumb_top">
            <div className="container-fluid">
               <div className="row mb-2">
                  <div className="col-sm-6">
                     <h1 className="m-0">Sub Categroies</h1>
                  </div>
                  <div className="col-sm-6">
                     <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active">Sub Categroies</li>
                     </ol>
                  </div>
               </div>
            </div>
         </div>
         <section className="content">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="all_drivers">
                        <div className="card card-dark">
                           <div className="card-header">
                              <div className="container-fluid">
                                 <div className="row">
                                    <div className="col-6 col-lg-11">
                                       <h3 className="pl-5">Search</h3>
                                    </div>
                                    <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                       <i className="fas fa-angle-up click_search"></i>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <form className="form_search" action="">
                              <div className="card-body">
                                 <div className="form-group">
                                    <label>Sub Category</label>
                                    <input
                                       type="text"
                                       name="subcategory"
                                       onChange={handleSearch}
                                       value={searchParams.subcategory}
                                       className="form-control"
                                       placeholder="Enter subcategory" />
                                 </div>
                                 {!params.id ?
                                    <div className="form-group ">
                                       <label>Main Category</label>
                                       <ReactSelect
                                          isClearable={true}
                                          name="_p_maincategory"
                                          options={categories}
                                          hideSelectedOptions={false}
                                          value={selectedvalue}
                                          onChange={(option) => handleSelect(option, "_p_maincategory")}
                                          styles={customStyles}
                                          placeholder="Select category"
                                       />
                                    </div>
                                    : null}
                              </div>
                              <div className="card-footer text-center">
                                 <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                 <button type="button" onClick={() => handleSubmit(getSubCategoriesData)} className="btn btn-success">Submit</button>
                              </div>
                           </form>
                        </div>
                        <div className="row pagination_new">
                           <div className="col-lg-6">
                              <Link to="/create-sub-category"><button type="add" className="btn btn-primary"><i className="fa-regular fa-circle-plus"></i>Create</button></Link>
                           </div>
                           {subCategories?.length > 0 &&
                              <div className="col-lg-6 col-12">
                                 <Pagination
                                    totalCount={totalCount}
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    maxPageNumberLimit={maxPageNumberLimit}
                                    minPageNumberLimit={minPageNumberLimit}
                                    setcurrentPage={setcurrentPage}
                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                    setminPageNumberLimit={setminPageNumberLimit}
                                 />
                              </div>
                           }
                        </div>
                        {subCategories?.length > 0 &&
                           <React.Fragment>
                              <div className="card table_card completed_1">
                                 <div className="card-body all_drive_table">
                                    <table id="example2" className="table table-hover">
                                       <thead>
                                          <tr>
                                             <th>Sr. No</th>
                                             <th>Sub Category
                                                {sorting.order_by === "subcategory" ?
                                                   <i onClick={() => handleSorting("subcategory")}
                                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                   </i>
                                                   : <i onClick={() => handleSorting("subcategory")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                }
                                             </th>
                                             <th>Main Category
                                                {sorting.order_by === "main_category_name" ?
                                                   <i onClick={() => handleSorting("main_category_name")}
                                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                   </i>
                                                   : <i onClick={() => handleSorting("main_category_name")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                }
                                             </th>
                                             <th>Block
                                                {sorting.order_by === "num" ?
                                                   <i onClick={() => handleSorting("num")}
                                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                   </i>
                                                   : <i onClick={() => handleSorting("num")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                }
                                             </th>
                                             <th>Created At</th>
                                             <th>Action</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          {subCategories.map((item, index) => (
                                             <tr key={item._id} >
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>{item.subcategory ? item.subcategory : "Not Available"}</td>
                                                <td>{item.main_category_name ? item.main_category_name : "Not Available"}</td>
                                                <td>{item.num ? item.num : "NA"}</td>
                                                <td>{getFormatedDate(item._created_at)}</td>
                                                <td>
                                                   <div className="action_button">
                                                      <div className="btn-group">
                                                         <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                         </button>
                                                         <div className="dropdown-menu">
                                                            <Link className="dropdown-item"
                                                               to={{ pathname: `/edit-sub-category/${item._id}`, state: { currentPage: currentPage } }}>
                                                               <span className="blue_1">
                                                                  <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span>
                                                            </Link>
                                                            <div className="dropdown-divider"></div>
                                                            <span className="dropdown-item" ><span className="red_1">
                                                               <i className="fa fa-trash" aria-hidden="true"></i>Delete</span></span>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </td>
                                             </tr>)
                                          )}
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                              <div className="row pagination_new">
                                 <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={subCategories} totalCount={totalCount} />
                                 <div className="col-lg-4">
                                    <Pagination
                                       totalCount={totalCount}
                                       currentPage={currentPage}
                                       itemsPerPage={itemsPerPage}
                                       maxPageNumberLimit={maxPageNumberLimit}
                                       minPageNumberLimit={minPageNumberLimit}
                                       setcurrentPage={setcurrentPage}
                                       setmaxPageNumberLimit={setmaxPageNumberLimit}
                                       setminPageNumberLimit={setminPageNumberLimit}
                                    />
                                 </div>
                              </div>
                           </React.Fragment>
                        }
                        {subCategories?.length === 0 && !isLoading && <NotFoundData />}
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}
export default IsLoadingHOC(IsLoggedinHOC(SubCategories))