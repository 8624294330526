import { authAxios } from "../../Config/axios"
import { trimObjValues } from "../../Helper"


export const getDeliveryZones = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/delivery_zone/get_delivery_zones", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const addDeliveryZone = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/delivery_zone/add_delivery_zone", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getDeliveryZoneDetails = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/delivery_zone/get_delivery_zones", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const updateDeliveryZone = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/delivery_zone/update_delivery_zone", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getUtracDeliveryZoneFee = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/delivery_zone/get_utrac_pickup_delivery_zone", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getDeliveryZoneFee = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/delivery_zone/get_pickup_delivery_zone", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const importDeliveryZones = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/delivery_zone/import_delivery_zones" ,data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const deleteDeliveryZone = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/delivery_zone/delete_delivery_zone" ,data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const validateDeliveryZone = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/delivery_zone/validate_delivery_zone", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const addUsersToDeliveryZone = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/delivery_zone/add_bulk_users_to_delivery_zone", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}
// 

export const removeUsersToDeliveryZone = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/delivery_zone/remove_bulk_users_from_delivery_zone", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}






