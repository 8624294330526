import React, { createContext, useState } from "react";
import { currentDate } from '../utils/dateFormat';
import moment from "moment";


export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
    const [orderDetailModal, setOrderDetailModal] = useState(false);
    const [orderNotesModal, setOrderNotesModal] = useState(false);
    const [viewCommentModal, setViewCommentModal] = useState(false);
    const [whatsAppMsgesModal, setWhatsAppMsgesModal] = useState(false);
    const [addFavLocModal, setAddFavLocModal] = useState(false);
    const [editFavLocModal, setEditFavLocModal] = useState(false);
    const [createBuyerModal, setCreateBuyerModal] = useState(false);
    const [shopFetching, setShopFetching] = useState(false);
    const [driverSearchable, setDriverSearchable] = useState(false);
    const [driverFetching, setDriverFetching] = useState(false)
    const [selectedDate, setSelectedDate] = useState({ from_date: null, to_date: null })
    const [ordersParams, setOrdersParams] = useState({ from_date: currentDate, to_date: currentDate })
    const [scheduledOrderParams, setScheduledOrderParams] = useState({ created: "false", active: "true", from_date: currentDate, to_date: "" })
    const [usersParams, setUsersParams] = useState({ from_date: currentDate, to_date: currentDate })
    const [balanceParams, setBalanceParams] = useState({ from_date: currentDate, to_date: currentDate })
    const [activeStep, setActiveStep] = useState("toDay")
    const [isFetchAreasChart, setFetchAreasChart] = useState(false)


    const handleChangeDate = (date, name) => {
        setActiveStep('')

        setOrdersParams((prevState) => ({
            ...prevState,
            [name]: date ? moment(date).format("YYYY-MM-DD") : '',
        }));

        setScheduledOrderParams((prevState) => ({
            ...prevState,
            [name]: date ? moment(date).format("YYYY-MM-DD") : ''
        }));

        setUsersParams((prevState) => ({
            ...prevState,
            [name]: date ? moment(date).format("YYYY-MM-DD") : ''
        }));

        setBalanceParams((prevState) => ({
            ...prevState,
            [name]: date ? moment(date).format("YYYY-MM-DD") : ''
        }));

        setSelectedDate((prevState) => ({
            ...prevState,
            [name]: date
        }));



    }

    const handleChangeStep = (fromDate, activeStep) => {

        setActiveStep(activeStep)

        setSelectedDate((prevState) => ({
            ...prevState,
            from_date: null,
            to_date: null
        }))

        setOrdersParams((prevState) => ({
            ...prevState,
            from_date: fromDate,
        }));
        setScheduledOrderParams((prevState) => ({
            ...prevState,
            from_date: fromDate,
        }));
        setUsersParams((prevState) => ({
            ...prevState,
            from_date: fromDate,
        }));

        setBalanceParams((prevState) => ({
            ...prevState,
            from_date: fromDate,
        }));

    }


    return (
        <OrderContext.Provider
            value={{
                addFavLocModal,
                setAddFavLocModal,
                editFavLocModal,
                setEditFavLocModal,
                createBuyerModal,
                setCreateBuyerModal,
                orderDetailModal,
                setOrderDetailModal,
                viewCommentModal,
                setViewCommentModal,
                orderNotesModal,
                setOrderNotesModal,
                whatsAppMsgesModal,
                setWhatsAppMsgesModal,
                shopFetching,
                setShopFetching,
                driverSearchable,
                setDriverSearchable,
                driverFetching,
                setDriverFetching,
                ordersParams,
                setOrdersParams,
                scheduledOrderParams,
                setScheduledOrderParams,
                usersParams,
                setUsersParams,
                activeStep,
                setActiveStep,
                balanceParams,
                setBalanceParams,

                handleChangeStep,
                handleChangeDate,

                selectedDate,
                setSelectedDate,
                isFetchAreasChart,
                setFetchAreasChart

            }}
        >
            {children}
        </OrderContext.Provider>
    );
};
