import React, { useState, useEffect, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import SelectYear from "../Form/Select/SelectYear";
import { getPerMonthOrdersCount } from '../../Redux/action/Orders';
import IsLoggedinHOC from '../IsLoggedinHOC';
import IsLoadingHOC from '../IsLoadingHOC';
import { useDispatch, useSelector } from "react-redux";
import { OrderContext } from "../../Context/OrderContext";


function BarChart({ setLoading }) {
  const dispatch = useDispatch()
  const { isFetchAreasChart } = useContext(OrderContext)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [series, setSeries] = useState([])
  const { user } = useSelector(state => state.auth)
  const { drivers } = user ? user : {}
  const logisticsDrivers = drivers ? drivers : []


  const [options, setOptions] = useState({
    chart: {
      height: 400,
      type: 'bar',
      zoom: {
        enabled: true
      },
      toolbar: {
        show: false,
        tools: {
          download: false
        }
      },
      background: '#fcfcfc'
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    title: {
      text: `Per month orders for ${selectedYear}`,
      align: 'left'
    },
    xaxis: {
      categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    },

    fill: {
      opacity: 1
    },
    // ##-- For change Formate value
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return "$ " + val + " thousands"
    //     }
    //   }
    // }
  })

  useEffect(() => {
    if (isFetchAreasChart) {
      getPerMonthOrders();
    }
  }, [selectedYear, isFetchAreasChart])


  const getPerMonthOrders = async () => {
    let payload = {
      user_id: user.type === 'Admin' ? "" : user._id,
      status: ["created", "pending pickup", "completed", "canceled"],
      year: selectedYear.toString(),
    }
    if (["Logistics"].includes(user.type) && logisticsDrivers?.length > 0) {
      Object.assign(payload, { drivers: logisticsDrivers })
    }
    setLoading(true)
    await dispatch(getPerMonthOrdersCount(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status == "1") {
            const resData = response.data
            let completed_orders = { name: 'Completed', data: [] }
            let canceled_orders = { name: 'Canceled', data: [] }
            let created_orders = { name: 'Created', data: [] }
            let pendingPickup_orders = { name: 'Pending pickup', data: [] }
            resData.completed.forEach((item) => completed_orders.data.push(item.order_count))
            resData.canceled.forEach((item) => canceled_orders.data.push(item.order_count))
            resData.created.forEach((item) => created_orders.data.push(item.order_count))
            resData.pending_pickup.forEach((item) => pendingPickup_orders.data.push(item.order_count))
            setSeries([created_orders, completed_orders, pendingPickup_orders, canceled_orders])
          }
        }).catch(error => console.log(error))
  }

  const handleChangeYear = (year) => {
    setSelectedYear(year)
    setOptions((prevState) => ({
      ...prevState,
      title: {
        ...prevState.text,
        text: `Per Month orders for ${year}`
      }
    }));
  }

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div className="row justify-content-end">
            <div className="col-lg-3 col-3">
              <div className="form-group">
                <SelectYear selectedYear={selectedYear} handleChangeYear={handleChangeYear} />
              </div>
            </div>
          </div>
          <ReactApexChart options={options} series={series} type="bar" height={400} />
        </div>
      </div>
    </React.Fragment >
  );
}
export default IsLoadingHOC(IsLoggedinHOC(BarChart))