import React, { useState, useEffect, useLayoutEffect } from 'react';
import { exportToExcel, getFormatedDate, handleSelectListItem, isAllItemSelected, setCharacterLimit, trimObjValues } from '../../Helper';
import { updateRelatedOrderId } from '../../Redux/action/App';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { getInvoicesList } from '../../Redux/action/Report';
import { setDriverDetails } from '../../Redux/action/App';
import ExportModel from '../../Components/ConfirmModel/ExportData';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Pagination from '../../Components/Pagination';
import DateInputs from '../../Components/DatePicker';
import TableFooter from '../../Components/TableFooter';
import { SidebarContext } from '../../Context/SidebarContext';
import { useContext } from 'react';
import NotFoundData from '../../Components/NotFoundData';
import moment from 'moment';
import { CSVLink } from 'react-csv';


// Export CSV File Headers
const headers = [
    { label: "Driver Id", key: "_id" },
    { label: "User Name", key: "username" },
    { label: "Nick Name", key: "nickname" },
    { label: "Phone", key: "phone" },
    { label: "Active", key: "active" },
    { label: "Utrac Fee Balance", key: "balance" },
    { label: "Cash Balance", key: "cash_balance" },
    { label: "Created At", key: "_created_at" },
    { label: "Updated At", key: "_updated_at" },
];

function Invoices(props) {
    const { setLoading, isLoading } = props
    const history = useHistory()
    const location = useLocation()
    const [users, setUsers] = useState([])
    const {
        csvLink,
        dispatch,
        fileLoading,
        setFileLoading,
        totalCount,
        toast,
        handleSubmit,
        handleSorting,
        selectedDate,
        setSelectedDate,
        setTotalCount,
        sorting,
        currentPage,
        setcurrentPage,
        resetForm,
        itemsPerPage,
        exportsData,
        setExportsData,
        toggleExportModal,
        selectedItem,
        setSelectedItem,
        handleSelectItem,
        exportModal,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
    } = useContext(SidebarContext)

    const [searchParams, setSearchParams] = useState({
        nickname: "",
        username: "",
        phone: "",
        from_date: "",
        to_date: "",
        active: "",
        balance: "",
        cash_balance: ""
    })

    useLayoutEffect(() => {
        if (location.state && location.state.currentPage) {
            setcurrentPage(location.state.currentPage)
        }
    }, [])

    useEffect(() => {
        getUsersData();
    }, [currentPage, sorting])

    useEffect(() => {
        const payload = { current_balance: '', driver_name: '' }
        dispatch(setDriverDetails(payload));
    }, [])

    const getUsersData = async () => {
        const search_params = trimObjValues(searchParams)
        const payload = {
            search: search_params,
            shorting: sorting,
            pagination: { start: currentPage, length: itemsPerPage }
        }
        setLoading(true)
        await dispatch(getInvoicesList(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setUsers(response.data.orders)
                        setTotalCount(response.data.total_count)
                    } else {
                        setUsers([])
                        setTotalCount(null)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
    }

    const getExportingData = async () => {
        const search_params = trimObjValues(searchParams);
        const totalItem = [];

        const fetchInvoicesList = async (pagination) => {
            const payload = {
                search: search_params,
                selected_items: pagination.start === 1 ? [...selectedItem] : [],
                shorting: sorting,
                pagination: pagination
            };

            try {
                setFileLoading(true);
                const response = await dispatch(getInvoicesList(payload));
                setFileLoading(false);

                if (response.status === "1") {
                    totalItem.push(...response.data.orders);
                }
            } catch (error) {
                setFileLoading(false);
                toast.error(error.response?.data?.message);
            }
        };

        if (selectedItem && selectedItem.length > 0) {
            await fetchInvoicesList({ start: 1, length: 1000 });
        } else {
            const pages = Math.ceil(totalCount / 50000);
            for (let i = 1; i <= pages; i++) {
                await fetchInvoicesList({ start: i, length: 50000 });
            }
        }
        const exportData = totalItem?.map((item) => {
            const exportItem = {};
            headers.forEach(header => {
                exportItem[header.label] = item[header.key];
            });
            return exportItem;
        });
        const fileName = `Invoices-${moment(new Date()).format("hh-mm-ss")}.xlsx`
        exportToExcel(exportData, fileName);
        //setExportsData(totalItem);
        // csvLink.current.link.click();
        toast.success("Data exported successfully");
        toggleExportModal();
    };

    const handleSearch = (e) => {
        const { name, value } = e.target
        setSearchParams((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleChangeDate = (date, name) => {
        setSearchParams((prevState) => ({
            ...prevState,
            [name]: date ? moment(date).format("YYYY-MM-DD") : '',
        }));
        setSelectedDate((prevState) => ({
            ...prevState,
            [name]: date,
        }));
    }

    const handleReset = async () => {
        const resetFormData = {
            nickname: "",
            username: "",
            phone: "",
            from_date: "",
            to_date: "",
            active: "",
            balance: "",
            cash_balance: ""
        }
        setSearchParams(resetFormData)
        resetForm();
    }

    const handleRelatedOrders = async (item) => {
        const payload = { driverid: item._id, relatedBy: 'Driver', user_name: item.username }
        dispatch(updateRelatedOrderId(payload))
        history.push(`/completed-orders/${item._id}`)
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header main_breadcrumb_top">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Invoices</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Invoices</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="all_drivers">
                                    <div className="card card-dark">
                                        <div className="card-header">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-6 col-lg-11">
                                                        <h3 className="pl-5">Search</h3>
                                                    </div>
                                                    <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                        <i className="fas fa-angle-up click_search"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form className="form_search" action="">
                                            <div className="card-body">
                                                <div className="form-group ">
                                                    <label>Name</label>
                                                    <input
                                                        type="text"
                                                        name="nickname"
                                                        onChange={handleSearch}
                                                        value={searchParams.nickname}
                                                        className="form-control"
                                                        placeholder="Enter the name" />
                                                </div>
                                                <div className="form-group ">
                                                    <label>Phone</label>
                                                    <input
                                                        type="number"
                                                        name="phone"
                                                        onChange={handleSearch}
                                                        value={searchParams.phone}
                                                        className="form-control"
                                                        placeholder="Phone" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Active</label>
                                                    <select
                                                        className="form-control"
                                                        value={searchParams.active}
                                                        onChange={handleSearch}
                                                        name="active" >
                                                        <option value="" >All</option>
                                                        <option value="true">Yes</option>
                                                        <option value="false">No</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Utrac Fee Balance</label>
                                                    <select
                                                        className="form-control"
                                                        value={searchParams.balance}
                                                        onChange={handleSearch}
                                                        name="balance" >
                                                        <option value="" >All</option>
                                                        <option value="true">Yes</option>
                                                        <option value="false">No</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Cash Balance</label>
                                                    <select
                                                        className="form-control"
                                                        value={searchParams.cash_balance}
                                                        onChange={handleSearch}
                                                        name="cash_balance" >
                                                        <option value="" >All</option>
                                                        <option value="true">Yes</option>
                                                        <option value="false">No</option>
                                                    </select>
                                                </div>
                                                <DateInputs selectedDate={selectedDate} handleChangeDate={handleChangeDate} />
                                            </div>
                                            <div className="card-footer text-center">
                                                <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                                <button type="button" onClick={() => handleSubmit(getUsersData)} className="btn btn-success">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="row pagination_new">
                                        <div className="col-lg-6 col-12">
                                            <button disabled={isLoading} type="button" onClick={() => handleSubmit(getUsersData)} className="btn btn-secondary"><i className={`fa-regular fa-rotate-right ${isLoading && 'fa_spinner'}`}></i>Refresh</button>
                                            <button type="button" disabled={fileLoading || !totalCount || isLoading} onClick={toggleExportModal} className="btn btn-info ml-2"><i className="fa-regular fa-circle-down"></i>{fileLoading ? "Loading.." : "Export"}</button>
                                            {/* <CSVLink type='button' className='csv-link' target='_blank' ref={csvLink}
                                                headers={headers}
                                                data={exportsData}
                                                filename={`Invoices_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss-A")}`}
                                            /> */}
                                        </div>
                                        {users?.length > 0 &&
                                            <div className="col-lg-6 col-12">
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    maxPageNumberLimit={maxPageNumberLimit}
                                                    minPageNumberLimit={minPageNumberLimit}
                                                    setcurrentPage={setcurrentPage}
                                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                    setminPageNumberLimit={setminPageNumberLimit}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {users?.length > 0 &&
                                        <React.Fragment>
                                            <div className="card table_card completed_1">
                                                <div className="card-body all_drive_table">
                                                    <table id="example2" className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th><input type="checkbox" id="check" checked={isAllItemSelected(users, selectedItem)} onChange={(event) => handleSelectListItem(event, users, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th>
                                                                <th>User Name
                                                                    {sorting.order_by === "username" ?
                                                                        <i onClick={() => handleSorting("username")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("username")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>Phone</th>
                                                                <th>Active</th>
                                                                <th>Utrac Fee Balance
                                                                    {sorting.order_by === "balance" ?
                                                                        <i onClick={() => handleSorting("balance")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("balance")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>Cash balance
                                                                    {sorting.order_by === "cash_balance" ?
                                                                        <i onClick={() => handleSorting("cash_balance")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("cash_balance")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>Created At
                                                                    {sorting.order_by === "_created_at" ?
                                                                        <i onClick={() => handleSorting("_created_at")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th> Updated At
                                                                    {sorting.order_by === "_updated_at" ?
                                                                        <i onClick={() => handleSorting("_updated_at")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("_updated_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {users.map((item, index) =>
                                                            (<tr key={item._id} >
                                                                <td>
                                                                    <input type="checkbox" className="user_table_checkbox"
                                                                        onChange={(event) => handleSelectItem(event, item._id)}
                                                                        checked={selectedItem.includes(item._id)} />
                                                                </td>
                                                                <td><span title={item?.username}>{setCharacterLimit(item?.username)}</span></td>
                                                                <td>{item.phone ? item.phone : "Not Available"}</td>
                                                                <td><span className={item.active ? 'yes_1' : item.active === false ? 'no_1' : "not_avl"} >{item.active ? 'Yes' : item.active === false ? "No" : 'NA'}</span></td>
                                                                <td><span className={`${item?.balance === 0 ? 'not_avl' :  item?.balance > 0 ? 'yes_1' : 'no_1'}`} > {Math.abs(item?.balance)?.toFixed(2)}</span></td>
                                                                <td>{Math.abs(item?.cash_balance)?.toFixed(2)}</td>
                                                                <td>{getFormatedDate(item._created_at)}</td>
                                                                <td>{getFormatedDate(item._updated_at)}</td>
                                                                <td>
                                                                    <div className="action_button">
                                                                        <div className="btn-group">
                                                                            <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                                            </button>
                                                                            <div className="dropdown-menu">
                                                                                <span className="dropdown-item" onClick={() => handleRelatedOrders(item)} >
                                                                                    <i className="fa fa-list" aria-hidden="true"></i>Related Orders</span>
                                                                                <div className="dropdown-divider"></div>
                                                                                <Link onClick={() => { dispatch(setDriverDetails(item)) }} to={{ pathname: `/balance-sheets/${item._id}`, state: { currentPage: currentPage } }} className="dropdown-item" >
                                                                                    <i className="fa fa-list" aria-hidden="true"></i>Balance Sheet</Link>
                                                                                <div className="dropdown-divider"></div>
                                                                                <Link onClick={() => { dispatch(setDriverDetails(item)) }} to={{ pathname: `/addjust-Txn/${item._id}`, state: { currentPage: currentPage } }} className="dropdown-item">
                                                                                    <i className="fa fa-pencil" aria-hidden="true"></i> Adjust TXN</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>)
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row pagination_new">
                                                <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={users} totalCount={totalCount} />
                                                <div className="col-lg-4">
                                                    <Pagination
                                                        totalCount={totalCount}
                                                        currentPage={currentPage}
                                                        itemsPerPage={itemsPerPage}
                                                        maxPageNumberLimit={maxPageNumberLimit}
                                                        minPageNumberLimit={minPageNumberLimit}
                                                        setcurrentPage={setcurrentPage}
                                                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                        setminPageNumberLimit={setminPageNumberLimit}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {users?.length === 0 && !isLoading && <NotFoundData />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {exportModal &&
                <ExportModel
                    loader={fileLoading}
                    toggleExportModal={toggleExportModal}
                    exportModal={exportModal}
                    getExportingData={getExportingData}
                    totalCount={totalCount}
                    selectedItem={selectedItem}
                />
            }
        </>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(Invoices))