import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { createTxnByDriverId } from '../../Redux/action/Report';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';


function AddjustTXN(props) {
  const { setLoading } = props
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const { invoiceDriver } = useSelector(state => state.app)
  const { balance, cash_balance, username } = invoiceDriver ? invoiceDriver : {}
  const location = useLocation()
  const { currentPage } = location.state ? location.state : {}


  const formik = useFormik({
    initialValues: {
      driverId: params.id,
      type: '',
      notes: '',
      amount: '',
      action: 'utrac_fee_balance'
    },

    validationSchema: Yup.object({

      type: Yup.string()
        .required("This fiels is required"),

      notes: Yup.string()
        .required("This fiels is required"),

      amount: Yup.number()
        .typeError('You must specify a number')
        .required("This field is required"),

      action: Yup.string()
        .required("This fiels is required"),
    }),

    onSubmit: async values => {
      const fieldValues = values
      setLoading(true)
      await dispatch(createTxnByDriverId(fieldValues))
        .then(
          response => {
            setLoading(false)
            if (response.status === '1') {
              toast.success(response.data.message)
              formik.resetForm();
              history.push({ pathname: '/invoices', state: { currentPage: currentPage } })
            } else {
              toast.error(response.data.error[0])
            }
          },
          error => {
            setLoading(false)
            toast.error(error.response.data?.message)
          }
        )
    },
  });

  const handleAdjustmentType = (e) => {
    const { value, name } = e.target
    formik.setFieldValue(name, value)
    formik.setFieldValue('type', '')
  }

  return (
    <div className="content-wrapper">
      <div className="content-header main_breadcrumb_top">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Edit Driver Balance {`(${username})`}</h1>
            </div>
          </div>  
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Adjustment Type</label>
                          <select
                            className="form-control"
                            value={formik.values.action}
                            onChange={handleAdjustmentType}
                            name="action">
                            <option value="">Select</option>
                            <option value="utrac_fee_balance">Utrac Fee Balance</option>
                            <option value="cash_balance">Cash balance</option>
                          </select>
                          {formik.touched.action && formik.errors.action ? (<span className="text-danger">{formik.errors.action}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="control-label">Available Balance</label>
                          <input
                            className="form-control"
                            disabled
                            name="balance"
                            value={formik.values.action === "utrac_fee_balance" ? parseFloat(balance)?.toFixed(2) : parseFloat(cash_balance)?.toFixed(2)}
                            type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Transaction type</label>
                          <select
                            className="form-control"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="type"
                          >
                            <option value="">Select</option>
                            {formik.values.action === "utrac_fee_balance" && <option value="deposit">Deposit Amount</option>}
                            <option value="credit">Credit</option>
                            <option value="debit">Debit</option>
                          </select>
                          {formik.touched.type && formik.errors.type ? (<span className="text-danger">{formik.errors.type}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Note</label>
                          <input
                            name="notes"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.notes}
                            type="text" className="form-control" />
                          {formik.touched.notes && formik.errors.notes ? (<span className="text-danger">{formik.errors.notes}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Amount</label>
                          <input
                            name="amount"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.amount}
                            type="number" className="form-control" />
                          {formik.touched.amount && formik.errors.amount ? (<span className="text-danger">{formik.errors.amount}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="pb-5 form-foot-btn">
                      <button type="button" className="btn btn-primary mr-2" onClick={() => history.push({ pathname: '/invoices', state: { currentPage: currentPage } })}>Back</button>
                      <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default IsLoadingHOC(IsLoggedinHOC(AddjustTXN))