import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { addAmountToWallet } from '../../Redux/action/User';
import { currencyCode } from '../../Config/constants';


function AddMoneyToWallet(props) {
    const dispatch = useDispatch()
    const { modal, closeModal, getListData, isLoading, setLoading, user } = props
    const { _id, wallet_amount, username } = user
    const walletAmount = wallet_amount ? wallet_amount : 0

    const formik = useFormik({
        initialValues: {
            user_id: _id,
            amount: ''
        },

        validationSchema: Yup.object({
            amount: Yup.number()
                .typeError('You must specify a number')
                .min(1, "Amount must be greater than zero")
                .required("This field is required"),
        }),
        onSubmit: async values => {
            setLoading(true)
            await dispatch(addAmountToWallet(values))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == '1') {
                            toast.success(response.data.message)
                            closeModal();
                            getListData();
                        } else {
                            toast.error(response.data?.error[0])
                        }
                    })
                .catch(err => setLoading(false))
        },
    });

    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? 'show' : ''}`} tabIndex="-1" style={{ display: modal ? 'block' : 'none' }}>
            <div className="modal-dialog wallet--modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center">Add Money to Wallet</h5>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-4">
                                    <img src={require("../../assets/logo/e-wallet.png")} alt="Wallet" className="img-fluid" style={{ maxWidth: '220px', marginBottom: '10px' }} />
                                </div>
                                <div className="col-8">
                                    <div className="wallet-form-group">
                                        <label htmlFor="balance" className="form-label">Wallet Balance :  {walletAmount + ' ' + currencyCode}</label>
                                        <p className="user-name">{username}</p>
                                        <input className="form-control"
                                            required
                                            name='amount'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.amount}
                                            placeholder='Enter amount'
                                            type="number"
                                        />
                                        {formik.touched.amount && formik.errors.amount ? (<span className="text-danger">{formik.errors.amount}</span>) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                            <button type="submit" className="btn btn-primary">{isLoading ? 'Processing' : 'Save'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default AddMoneyToWallet
