import React, { useState, useEffect, useLayoutEffect } from 'react';
import { getUsersList, updateDriverAvailability, updateUserDetails } from '../../Redux/action/User';
import { getFormatedDate, setCharacterLimit } from '../../Helper';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import Pagination from '../../Components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import TableFooter from '../../Components/TableFooter';
import NotFoundData from '../../Components/NotFoundData';


function LogisticDrivers(props) {
    const { isLoading, setLoading } = props
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const [drivers, setDrivers] = useState([])
    const [totalCount, setTotalCount] = useState(null)
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage] = useState(50);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [sorting] = useState({ order_in: -1, order_by: "_created_at" })
    const { dsp_drivers } = useSelector(state => state.app)

    const [searchParams] = useState({
        type: "Driver",
        nickname: "",
        username: "",
        phone: "",
        from_date: "",
        to_date: "",
        verified: "",
        available: "",
        online: "",
    })

    useLayoutEffect(() => {
        if (location.state && location.state.currentPage) {
            setcurrentPage(location.state.currentPage)
        }
    }, [])


    useEffect(() => {
        getLogisticdrivers();
    }, [currentPage, sorting])

    const getLogisticdrivers = async () => {
        const payload = {
            search: { ...searchParams, _id: dsp_drivers.join(',') },
            shorting: { order_in: sorting.order_in, order_by: sorting.order_by },
            pagination: { start: currentPage, length: itemsPerPage }
        }
        setLoading(true)
        await dispatch(getUsersList(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setDrivers(response.data.users)
                        setTotalCount(response.data.total_count)
                    } else {
                        toast.error(response.data.error[0])
                        setDrivers([])
                        setTotalCount(null)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    }

    const handleToggleAvailability = async (event, data) => {
        const { checked } = event.target
        const { _id } = data
        const payload = { user_id: _id, availability: checked }
        setLoading(true)
        await dispatch(updateDriverAvailability(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status == 1) {
                        toast.success(response.data.message)
                        const result = drivers.map(driver => {
                            if (driver._id === _id) {
                                return { ...driver, online: checked };
                            }
                            return driver
                        })
                        setDrivers(result)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
            .catch((error) => console.log("Catch Error", error))
    }

    const handleToggleActivate = async (event, data) => {
        const { checked } = event.target
        const { _id, username, nickname, email, phone } = data
        const payload = { user_id: _id, username, nickname, email, phone, is_active: checked, is_available: !checked && false }
        setLoading(true)
        await dispatch(updateUserDetails(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status == 1) {
                        toast.success(response.data.message)
                        const result = drivers.map(driver => {
                            if (driver._id === _id) {
                                return { ...driver, active: checked };
                            }
                            return driver
                        })
                        setDrivers(result)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
            .catch((error) => console.log("Catch Error", error))
    }


    return (
        <>
            <div className="content-wrapper">
                <div className="content-header main_breadcrumb_top">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Logistic driver list</h1>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="all_drivers">
                                    <div className="card card-dark">
                                        <div className="card-header">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-6 col-lg-11">
                                                    </div>
                                                    <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pagination_new">
                                        <div className="col-lg-6 col-12">
                                            <button disabled={isLoading} type="button" onClick={() => history.goBack()} className="btn btn-primary"><i className="fa-solid fa-circle-arrow-left"></i>Back</button>
                                        </div>
                                        {drivers?.length > 0 &&
                                            <div className="col-lg-6 col-12">
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    maxPageNumberLimit={maxPageNumberLimit}
                                                    minPageNumberLimit={minPageNumberLimit}
                                                    setcurrentPage={setcurrentPage}
                                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                    setminPageNumberLimit={setminPageNumberLimit}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {drivers?.length > 0 &&
                                        <React.Fragment>
                                            <div className="card table_card">
                                                <div className="card-body all_drive_table">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th className='serial--number'>SN.</th>
                                                                <th className='user--name'>Name</th>
                                                                <th className='phone--number'>Phone</th>
                                                                <th className='availability'>VIP</th>
                                                                <th className='availability'>Available</th>
                                                                <th className='driver--balance'>Balance</th>
                                                                <th className='created--date'>Created At</th>
                                                                <th className='online-driver'>Online</th>
                                                                <th className='active-driver' >Active</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {drivers.map((item, index) => (
                                                                <tr key={item._id} >
                                                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                    <td className='cursor-pointer' title={item.username}><i className={`${item.is_permanent ? 'fa-solid' : 'fa-regular'} fa-user fa-lg`}></i>{setCharacterLimit(item.username)}</td>
                                                                    <td>{item.phone ? item.phone : "Not Available"}</td>
                                                                    <td><span className={item.is_vip ? 'yes_1' : item.is_vip === false ? 'no_1' : "not_avl"} >{item.is_vip ? 'Yes' : item.is_vip === false ? "No" : 'NA'}</span></td>
                                                                    <td><span className={item.available ? 'yes_1' : item.available === false ? 'no_1' : "not_avl"}>{item.available ? 'Yes' : item.available === false ? "No" : 'NA'}</span></td>
                                                                    <td>{item.balance === 0 ? "0" : item.balance ? item.balance?.toFixed(2) : "NA"}</td>
                                                                    <td>{getFormatedDate(item._created_at)}</td>
                                                                    <td>
                                                                        <div className="form-group">
                                                                            <div className="checkbox checbox-switch switch-success">
                                                                                <label>
                                                                                    <input onChange={(e) => handleToggleAvailability(e, item)} type="checkbox" name="" checked={item.online} />
                                                                                    <span> </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group">
                                                                            <div className="checkbox checbox-switch switch-success">
                                                                                <label>
                                                                                    <input onChange={(e) => handleToggleActivate(e, item)} type="checkbox" name="" checked={item.active} />
                                                                                    <span> </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <span>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row pagination_new">
                                                <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={drivers} totalCount={totalCount} />
                                                <div className="col-lg-4">
                                                    <Pagination
                                                        totalCount={totalCount}
                                                        currentPage={currentPage}
                                                        itemsPerPage={itemsPerPage}
                                                        maxPageNumberLimit={maxPageNumberLimit}
                                                        minPageNumberLimit={minPageNumberLimit}
                                                        setcurrentPage={setcurrentPage}
                                                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                        setminPageNumberLimit={setminPageNumberLimit}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {drivers?.length === 0 && !isLoading && <NotFoundData />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(LogisticDrivers))