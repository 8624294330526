import React, { useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { addMaqsam, updateShop, setFormCurrentStep } from '../../Redux/action/Shop';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';


const webUrl = /^(?=.{4,2048}$)((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]{1,63}(\.[a-zA-Z]{1,63}){1,5}(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

function SocialLink(props) {
    const { setLoading } = props
    const { userInfo, shopInfo, shopOtherInfo, socialurl, openingHours, shopImage, shopOtherImages, maqsam, p_shopid } = useSelector(state => state.shop)
    const { parentShopID } = useSelector(state => state.app)
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const { currentPage } = location.state ? location.state : {}


    useEffect(() => {
        if (maqsam) {
            if (maqsam.share_location_customer_url) {
                formik.setFieldValue('share_location_customer_url', maqsam.share_location_customer_url, false)
            }
            if (maqsam.share_location_driver_url) {
                formik.setFieldValue('share_location_customer_url', maqsam.share_location_driver_url, false)
            }
            if (maqsam.accept_order_driver_url) {
                formik.setFieldValue('share_location_customer_url', maqsam.accept_order_driver_url, false)
            }
        }
    }, [maqsam])


    const formik = useFormik({
        initialValues: {
            share_location_customer_url: '',
            share_location_driver_url: '',
            accept_order_driver_url: ''
        },

        validationSchema: Yup.object({
            share_location_customer_url: Yup.string()
                .matches(webUrl, 'Website should be a valid URL!'),

            share_location_driver_url: Yup.string()
                .matches(webUrl, 'Website should be a valid URL!'),

            accept_order_driver_url: Yup.string()
                .matches(webUrl, 'Website should be a valid URL!'),
        }),

        onSubmit: async values => {
            let fieldValues = values
            dispatch(addMaqsam(fieldValues));
            const payload = {
                user_id: params.id,
                shop_id: p_shopid,
                ...userInfo,
                ...shopInfo,
                ...shopOtherInfo,
                ...shopImage,
                hours: openingHours,
                otherimages: [...shopOtherImages],
                sociallinks: { ...socialurl },
                ...fieldValues
            }
            setLoading(true)
            await dispatch(updateShop(payload))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status === '1') {
                            toast.success(response.data.message)
                            const pathName = location.pathname.split('/')
                            if (pathName && pathName.length > 0 && pathName[1] === 'edit-shop-branch') {
                                history.push({ pathname: `/shop-branches/${parentShopID}`, state: { currentPage: currentPage } })
                            } else {
                                history.push({ pathname: '/shops', state: { currentPage: currentPage } })
                            }
                        } else {
                            toast.error(response.data?.error[0])
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                        console.log(error)
                    }
                )
        },
    });

    const handlePrevious = () => {
        dispatch(setFormCurrentStep("SHOP_OTHER_IMAGE"))
    }

    return (
        <div className="card-body">
            <div className="tab-content" id="custom-tabs-one-tabContent">
                <div className="tab-pane fade active show " id="custom-maqsam" role="tabpanel" aria-labelledby="maqsam-tab">
                    <form onSubmit={formik.handleSubmit}  >
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Maqsam share location customer URL</label>
                                    <input type="text" className="form-control"
                                        name='share_location_customer_url'
                                        placeholder='Enter URL'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.share_location_customer_url}
                                    />
                                    {formik.touched.share_location_customer_url && formik.errors.share_location_customer_url ? (<span className="text-danger">{formik.errors.share_location_customer_url}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Maqsam share location driver URL</label>
                                    <input type="text" className="form-control"
                                        name='share_location_driver_url'
                                        placeholder='Enter URL'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.share_location_driver_url}
                                    />
                                    {formik.touched.share_location_driver_url && formik.errors.share_location_driver_url ? (<span className="text-danger">{formik.errors.share_location_driver_url}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Maqsam accept order driver URL</label>
                                    <input type="text" className="form-control"
                                        name='accept_order_driver_url'
                                        placeholder='Enter URL'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.accept_order_driver_url}
                                    />
                                    {formik.touched.accept_order_driver_url && formik.errors.accept_order_driver_url ? (<span className="text-danger">{formik.errors.accept_order_driver_url}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="pb-5 footer-step">
                            <button type='button' className="btn btn-primary mr-2" onClick={handlePrevious}>Back</button>
                            <button type='submit' className="btn btn-success">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default IsLoadingHOC(IsLoggedinHOC(SocialLink))