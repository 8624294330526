let initialState = {
    roles: [],
    form_step: 'USER_INFO',
    userInfo: {},
    shopInfo: {},
    shopOtherInfo: {},
    socialurl: {},
    openingHours: {},
    shopImage: {},
    shopOtherImages: [],
    maqsam: {},
    p_shopid: '',
    headBranchName: '',
    branches_id: '',
    branchesArr: []
}

export default function shop(state = initialState, action) {
    switch (action.type) {
        case "SET_ROLES":
            return { ...state, roles: action.payload }
        case "SET_FORM_CURRENT_STEP":
            return { ...state, form_step: action.payload }
        case "SET_USER_INFO":
            return { ...state, userInfo: action.payload }
        case "SET_SHOP_INFO":
            return { ...state, shopInfo: action.payload }
        case "SET_SHOP_OTHER_INFO":
            return { ...state, shopOtherInfo: action.payload }
        case "SET_SOCIAL_LINKS":
            return { ...state, socialurl: action.payload }
        case "SET_OPENING_HOURS":
            return { ...state, openingHours: action.payload }
        case "SET_SHOP_IMAGE":
            return { ...state, shopImage: action.payload }
        case "SET_SHOP_OTHER_IMAGE":
            return { ...state, shopOtherImages: action.payload }
        case "SET_MAQSAM":
            return { ...state, maqsam: action.payload }
        case "SET_P_SHOP_ID":
            return { ...state, p_shopid: action.payload }
        case "SAVE_HEAD_BRANCH_NAME":
            return { ...state, headBranchName: action.payload }
        case "SAVE_BRANCHES_ID":
            return { ...state, branches_id: action.payload }
        case "SAVE_BRANCHES_ARRAY":
            return { ...state, branchesArr: action.payload }
        case "CLEAN_SHOP_FORM_DATA":
            return {
                ...state,
                userInfo: {},
                shopInfo: {},
                shopOtherInfo: {},
                socialurl: {},
                openingHours: {},
                shopImage: {},
                shopOtherImages: [],
                maqsam: {},
                p_shopid: ""
            }
        default:
            return state
    }
}









