import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { getFormatedDate, trimObjValues } from '../../Helper';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { getPrices } from '../../Redux/action/price';
import Pagination from '../../Components/Pagination';
import { Link, useLocation } from 'react-router-dom';
import TableFooter from '../../Components/TableFooter';
import { SidebarContext } from '../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';

function Prices(props) {
   const { isLoading, setLoading } = props
   const location = useLocation()
   const [pricesdata, setPricesdata] = useState([])
   const [searchParams, setSearchParams] = useState({ title: "", delivery_fee: "", utrac_fee: "", tax_fee: "" })
   const [sorting, setSorting] = useState({ order_in: 1, order_by: 'delivery_fee' })

   const {
      dispatch,
      totalCount,
      toast,
      setTotalCount,
      currentPage,
      resetForm,
      handleSubmit,
      setcurrentPage,
      itemsPerPage,
      maxPageNumberLimit,
      setmaxPageNumberLimit,
      minPageNumberLimit,
      setminPageNumberLimit,
   } = useContext(SidebarContext)

   useLayoutEffect(() => {
      if (location.state && location.state.currentPage) {
         setcurrentPage(location.state.currentPage)
      }
   }, [])

   useEffect(() => {
      getPricesData();
   }, [currentPage, sorting])

   const getPricesData = async () => {
      let trimmedObj = trimObjValues(searchParams)
      if (trimmedObj.delivery_fee) {
         trimmedObj.delivery_fee = parseInt(trimmedObj.delivery_fee)
      }
      if (trimmedObj.utrac_fee) {
         trimmedObj.utrac_fee = parseInt(trimmedObj.utrac_fee)
      }
      const payload = {
         search: { ...trimmedObj },
         shorting: sorting,
         pagination: { start: currentPage, length: itemsPerPage }
      }
      setLoading(true)
      await dispatch(getPrices(payload))
         .then(
            response => {
               setLoading(false);
               if (response.status === "1") {
                  setPricesdata(response.data.prices)
                  setTotalCount(response.data.total_count)
               } else {
                  setPricesdata([])
                  setTotalCount(null)
               }
            },
            error => {
               setLoading(false)
               toast.error(error.response.data?.message)
            }
         )
   }

   const handleSearch = (e) => {
      const { name, value } = e.target
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value
      }));
   }

   const handleReset = async () => {
      const resetFormData = { title: "", delivery_fee: "", utrac_fee: "", tax_fee: "" }
      setSearchParams(resetFormData)
      resetForm()
      setSorting({ order_in: 1, order_by: "delivery_fee" })
   }

   const handleSorting = (orderby) => {
      setSorting((prevState) => ({
         ...prevState,
         order_by: orderby,
         order_in: prevState.order_in === -1 ? 1 : -1
      }));
   }

   return (
      <div className="content-wrapper">
         <div className="content-header main_breadcrumb_top">
            <div className="container-fluid">
               <div className="row mb-2">
                  <div className="col-sm-6">
                     <h1 className="m-0">Prices List</h1>
                  </div>
                  <div className="col-sm-6">
                     <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active">Prices</li>
                     </ol>
                  </div>
               </div>
            </div>
         </div>
         <section className="content">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="all_drivers">
                        <div className="card card-dark">
                           <div className="card-header">
                              <div className="container-fluid">
                                 <div className="row">
                                    <div className="col-6 col-lg-11">
                                       <h3 className="pl-5">Search</h3>
                                    </div>
                                    <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                       <i className="fas fa-angle-up click_search"></i>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <form className="form_search" action="">
                              <div className="card-body">
                                 <div className="form-group">
                                    <label>Title</label>
                                    <input
                                       type="text"
                                       name="title"
                                       onChange={handleSearch}
                                       value={searchParams.title}
                                       className="form-control"
                                       placeholder="Enter title" />
                                 </div>
                                 <div className="form-group">
                                    <label>Utrac fee</label>
                                    <input
                                       type="number"
                                       name="utrac_fee"
                                       onChange={handleSearch}
                                       value={searchParams.utrac_fee}
                                       className="form-control"
                                       placeholder="Enter utrac fee" />
                                 </div>
                                 <div className="form-group">
                                    <label>Delivery fee</label>
                                    <input
                                       type="number"
                                       name="delivery_fee"
                                       onChange={handleSearch}
                                       value={searchParams.delivery_fee}
                                       className="form-control"
                                       placeholder="Enter delivery fee" />
                                 </div>
                                 <div className="form-group">
                                    <label>Tax fee</label>
                                    <input
                                       type="number"
                                       name="tax_fee"
                                       onChange={handleSearch}
                                       value={searchParams.tax_fee}
                                       className="form-control"
                                       placeholder="Enter tax fee" />
                                 </div>
                              </div>
                              <div className="card-footer text-center">
                                 <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                 <button type="button" onClick={() => handleSubmit(getPricesData)} className="btn btn-success">Submit</button>
                              </div>
                           </form>
                        </div>
                        <div className="row pagination_new">
                           <div className="col-lg-6 col-12">
                              <Link to="/create-price" ><button type="create" className="btn btn-primary"><i className="fa-regular fa-circle-plus"></i>Create</button></Link>
                           </div>
                           {pricesdata?.length > 0 &&
                              <div className="col-lg-6 col-12">
                                 <Pagination
                                    totalCount={totalCount}
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    maxPageNumberLimit={maxPageNumberLimit}
                                    minPageNumberLimit={minPageNumberLimit}
                                    setcurrentPage={setcurrentPage}
                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                    setminPageNumberLimit={setminPageNumberLimit}
                                 />
                              </div>
                           }
                        </div>
                        {pricesdata?.length > 0 &&
                           <React.Fragment>
                              <div className="card table_card completed_1">
                                 <div className="card-body all_drive_table">
                                    <table id="example2" className="table table-hover">
                                       <thead>
                                          <tr>
                                             <th>Sr. No</th>
                                             <th>Title
                                                {sorting.order_by === "title" ?
                                                   <i onClick={() => handleSorting("title")}
                                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                   </i>
                                                   : <i onClick={() => handleSorting("title")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                }
                                             </th>
                                             <th>Delivery Fee
                                                {sorting.order_by === "delivery_fee" ?
                                                   <i onClick={() => handleSorting("delivery_fee")}
                                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                   </i>
                                                   : <i onClick={() => handleSorting("delivery_fee")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                }
                                             </th>
                                             <th>Utrac Fee
                                                {sorting.order_by === "utrac_fee" ?
                                                   <i onClick={() => handleSorting("utrac_fee")}
                                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                   </i>
                                                   : <i onClick={() => handleSorting("utrac_fee")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                }
                                             </th>
                                             <th>Tax Fee
                                                {sorting.order_by === "tax_fee" ?
                                                   <i onClick={() => handleSorting("tax_fee")}
                                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                   </i>
                                                   : <i onClick={() => handleSorting("tax_fee")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                }
                                             </th>
                                             <th>Created At
                                                {sorting.order_by === "_created_at" ?
                                                   <i onClick={() => handleSorting("_created_at")}
                                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                   </i>
                                                   : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                }
                                             </th>
                                             <th>Action</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          {pricesdata.map((item, index) =>
                                          (<tr key={item._id} >
                                             <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                             <td>{item.title ? item.title : "Not Available"}</td>
                                             <td>{item.delivery_fee === 0 ? "0" : item.delivery_fee ? item.delivery_fee : "NA"}</td>
                                             <td>{item.utrac_fee === 0 ? "0" : item.utrac_fee ? item.utrac_fee : "NA"}</td>
                                             <td>{item.tax_fee === 0 ? "0" : item.tax_fee ? item.tax_fee : "NA"}</td>
                                             <td>{getFormatedDate(item._created_at)}
                                             </td>
                                             <td>
                                                <div className="action_button">
                                                   <div className="btn-group">
                                                      <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                      </button>
                                                      <div className="dropdown-menu">
                                                         <Link
                                                            to={{ pathname: `/edit-price/${item._id}`, state: { currentPage: currentPage } }}
                                                            className="dropdown-item" ><span className="blue_1">
                                                               <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span></Link>
                                                      </div>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>)
                                          )}
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                              <div className="row pagination_new">
                                 <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={pricesdata} totalCount={totalCount} />
                                 <div className="col-lg-4">
                                    <Pagination
                                       totalCount={totalCount}
                                       currentPage={currentPage}
                                       itemsPerPage={itemsPerPage}
                                       maxPageNumberLimit={maxPageNumberLimit}
                                       minPageNumberLimit={minPageNumberLimit}
                                       setcurrentPage={setcurrentPage}
                                       setmaxPageNumberLimit={setmaxPageNumberLimit}
                                       setminPageNumberLimit={setminPageNumberLimit}
                                    />
                                 </div>
                              </div>
                           </React.Fragment>
                        }
                        {pricesdata?.length === 0 && !isLoading && <NotFoundData />}
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}
export default IsLoadingHOC(IsLoggedinHOC(Prices))