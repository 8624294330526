import React, { useState, useEffect } from 'react';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { useDispatch, useSelector } from "react-redux";
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { toast } from 'react-toastify';
import { getDriversRoster } from '../../Redux/action/User';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment-timezone';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import $ from 'jquery';


const time_zone = process.env.REACT_APP_TIMEZONE || 'Asia/Kolkata'

function DriversRosterList(props) {
    const dispatch = useDispatch();
    const { isLoading, setLoading, rightSlider, closeSlider } = props
    const { userGlobalData } = useSelector(state => state.app)
    const { area_direction } = userGlobalData ? userGlobalData : []
    const [driverList, setDriveList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(50);
    const [hasMore, setHasMore] = useState(true);
    const [selectedDirection, setSelectedDirection] = useState(null)
    const [jobDirection, setJobDirection] = useState("")
    const [selectedTime, setSelectedTime] = useState("")
    const [shiftTime, setShiftTime] = useState("")
    const [isPermanent, setPermanent] = useState('true')


    const loadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    useEffect(() => {
        getDriversWithShiftTime();
    }, [currentPage, selectedDirection, selectedTime, isPermanent])

    useEffect(() => {
        $(".click_search").on('click', function () {
            $(".search_form").slideToggle();
        });
        $(".click_search").on('click', function () {
            $(this).toggleClass('is-active').next(".option-content").stop().slideToggle(500);
        });
    }, [])

    const getDriversWithShiftTime = async () => {
        var date = moment(new Date());
        let current_time = date.tz(time_zone).format('HH:mm');
        if (shiftTime) {
            current_time = shiftTime
        }
        const payload = {
            search: {
                type: "Driver",
                shift_starts_at: current_time,
                shift_ends_at: current_time,
                job_direction: jobDirection,
                is_permanent : isPermanent
            },
            shorting: {
                order_in: 1,
                order_by: "today_complete_orders"
            },
            pagination: { start: currentPage, length: itemsPerPage }
        }
        setLoading(true)
        await dispatch(getDriversRoster(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const resData = response.data.users
                        if (
                            resData &&
                            resData.length > 0
                        ) {
                            let uniqueId = []
                            let allDrivers = [...driverList, ...resData]
                            let uniqueDrivers = allDrivers.filter(element => {
                                const isDuplicate = uniqueId.includes(element._id);
                                if (!isDuplicate) {
                                    uniqueId.push(element._id);
                                    return true
                                }
                                else {
                                    return false
                                }
                            });
                            setDriveList(uniqueDrivers);
                        }
                        else {
                            setHasMore(false);
                        }
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    const getBgColor = (user) => {
        if (!user.available) {
            return "bg-warning"
        }
        else if (user.online && user.available) {
            return "bg-success"
        }
        else if (!user.online && user.available) {
            return "bg-danger"
        }
    }

    const getDriverStatusTitle = (user) => {
        if (user.online && user.available) {
            return "Available"
        }
        else if (!user.available) {
            return "Unavailable"
        }
        else if (!user.online && user.available) {
            return "Offline & Available"
        }
    }

    const handleDragStart = (event, item) => {
        document.getElementById(event.target.id)?.classList.add("on-drag");
    };

    const dragEndTodo = (event) => {
        document.getElementById(event.target.id)?.classList.remove("on-drag");
    }

    const onDragTodo = (event, driver) => {
        event.preventDefault();
        localStorage.setItem("driver_id", driver._id)
    };

    const selectDirection = (option) => {
        setSelectedDirection(option)
        setDriveList([])
        setCurrentPage(1)
        if (option) {
            setJobDirection(option.value)
        }
        else {
            setJobDirection("")
        }
    }

    const handleChangeTime = (selectedTime) => {
        setSelectedTime(selectedTime);
        setDriveList([])
        setCurrentPage(1)
        if (selectedTime) {
            const selected_time = moment(selectedTime).format("HH:mm")
            setShiftTime(selected_time)
        }
        else {
            setShiftTime("")
        }
    }

    const handleChangePermanently = (e) => {
        setDriveList([])
        setCurrentPage(1)
        const { value } = e.target
        setPermanent(value)
    }

    const handleRefresh = () => {
        setDriveList([])
        if (currentPage === 1) {
            getDriversWithShiftTime()
        }
        else {
            setCurrentPage(1)
        }
    }
    return (
        <aside className="control-sidebar control-sidebar-light" style={{ display: rightSlider ? "block" : "none" }}>
            <div id="scrollableDiv" className="slider-content">
                <div className="modal-header">
                    <span title='Refresh' className="cursor-pointer text-info pt-1" onClick={handleRefresh}><i className={`fa-solid fa-rotate-right  ${isLoading && 'fa-spin'}`}></i></span>
                    <h5 className="modal-title w-100 text-center text-secondary" id="myModalLabel2">Driver's Roster</h5>
                    <button onClick={closeSlider} type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="card card-dark search--driver">
                    <div className="card-header slider--header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6 col-lg-11">
                                    <h5 className="">Search</h5>
                                </div>
                                <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                    <i className="fas fa-angle-up click_search"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form className="search_form" action="">
                        <div className='row p-1 pt-2'>
                            <div className='col-sm-12'>
                                <div className="form-group">
                                    <Select
                                        value={selectedDirection}
                                        onChange={selectDirection}
                                        options={[{ value: '', label: 'All' }, ...area_direction]}
                                        placeholder="Direction"
                                        isClearable={true}
                                        isOptionDisabled={(option) =>
                                            selectedDirection && option.value === selectedDirection.value
                                        }
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                cursor: 'pointer'
                                            }),
                                            clearIndicator: (provided) => ({
                                                ...provided,
                                                cursor: 'pointer',
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row p-1'>
                            <div className='col-sm-6'>
                                <div className="form-group">
                                    <select value={isPermanent} onChange={handleChangePermanently} className='form-control'>
                                        <option value=''>Show All</option>
                                        <option value='true'>Permanent</option>
                                        <option value='false'>Temporary</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="form-group">
                                    <DatePicker
                                        className="form-control date-field ub-l cursor-pointer" placeholderText="Time"
                                        selected={selectedTime}
                                        showIcon={selectedTime ? false : true}
                                        onChange={(time) => handleChangeTime(time)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        //filterTime={filterPassedTime}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        isClearable={selectedTime ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <InfiniteScroll
                    dataLength={driverList.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={null}
                    scrollableTarget="scrollableDiv"
                >
                    <div className="content-body">
                        <div className="navbar-nav ml-auto driver--lists">
                            {driverList && driverList.length > 0 &&
                                driverList.map((item, index) => (
                                    <div className='item' key={item._id}
                                        id={item._id}
                                        draggable={true}
                                        onDragEnd={(e) => dragEndTodo(e)}
                                        onDragStart={(e) => handleDragStart(e)}
                                        onDrag={event => onDragTodo(event, item)}>
                                        <div className="menu-info">
                                            <i title={getDriverStatusTitle(item)} className={`fa-solid fa-user inline-icon ${getBgColor(item)}`}></i>
                                            <div className='w-100'>
                                                <h5 title='Driver-Name' className="control-sidebar-subheading inline-text">{item.username}</h5>
                                                <p><small className='text-success' title='Shift-Time'>{item?.shift_starts_at} - {item?.shift_ends_at}</small>  {item.break_time && <> | <small className='text-danger' title='Break-Time'>{item.break_time}</small> </>} </p>
                                                {jobDirection === "" && <p title='Direction' className='text-primary text-capitalize'><small>{item?.job_directions?.length > 0 ? item.job_directions[0]?.replace('_', ' ') : ''}</small></p>}
                                            </div>
                                            <p title='Completed-Orders' className='complete-order--driver'><small>{item?.today_complete_orders ? item?.today_complete_orders : 0}</small></p>
                                        </div>
                                    </div>
                                ))}
                            {isLoading &&
                                <>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => (
                                        <div key={i + 1} className='item pb-3'>
                                            <div className="menu-info">
                                                <i className={`fa-solid fa-user inline-icon bg-light`}></i>
                                                <div className='w-100'>
                                                    <h5 title='Driver-Name' className="control-sidebar-subheading inline-text">{""}</h5>
                                                </div>
                                            </div>
                                        </div>)
                                    )}
                                </>
                            }
                        </div>
                    </div>
                </InfiniteScroll>
                <div className="container text-center pt-5">
                    <div className="row">
                        <div className="col">
                            {driverList.length === 0 && !isLoading && (
                                <h5>No records Available</h5>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default IsLoadingHOC(IsLoggedinHOC(DriversRosterList))
