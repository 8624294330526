import React, { useState, useEffect, useContext } from 'react';
import { getAggregatorsCount, getAggregatorsTotalOrdersCount, getCodBalanceCount, getUsersCount, getWalletBalanceCount } from '../../Redux/action/User';
import { getOrdersCount, getScheduleOrdersCount } from '../../Redux/action/Orders';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { Link } from 'react-router-dom';
import { SidebarContext } from '../../Context/SidebarContext';
import { OrderContext } from '../../Context/OrderContext';
import DatePicker from 'react-datepicker';

import {
    currentDate,
    yesterday,
    fiveDaysAgo,
    fifteenDaysAgo,
    thirtyDaysAgo,
    tenDaysAgo
} from '../../utils/dateFormat';

function AdminDashboard() {
    const {
        ordersParams,
        scheduledOrderParams,
        usersParams,
        activeStep,
        balanceParams,
        handleChangeStep,
        handleChangeDate,
        selectedDate,
    } = useContext(OrderContext)

    const { dispatch, toast, } = useContext(SidebarContext)

    const [allOpenOrders, setAllOpenOrders] = useState({ loading: false, totalCount: 0 })
    const [arrivedOrders, setArrivedOrders] = useState({ loading: false, totalCount: 0 })
    const [pendingPickupOrders, setPendingPickupOrders] = useState({ loading: false, totalCount: 0 })
    const [pickedOrders, setPickedOrders] = useState({ loading: false, totalCount: 0 })
    const [onHoldOrders, setOnHoldOrders] = useState({ loading: false, totalCount: 0 })
    const [completedOrders, setCompletedOrders] = useState({ loading: false, totalCount: 0 })
    const [canceledOrders, setCanceledOrders] = useState({ loading: false, totalCount: 0 })
    const [activeDrivers, setActiveDrivers] = useState({ loading: false, totalCount: 0 })
    const [inactiveDrivers, setInactiveDrivers] = useState({ loading: false, totalCount: 0 })
    const [scheduleOrders, setScheduleOrders] = useState({ loading: false, totalCount: 0 })
    const [buyers, setBuyers] = useState({ loading: false, totalCount: 0 })
    const [shops, setShops] = useState({ loading: false, totalCount: 0 })
    const [integrators, setIntegrators] = useState({ loading: false, totalCount: 0 })
    const [aggregators, setAggregators] = useState({ loading: false, totalCount: 0 })
    const [aggregatorsOrders, setAggregatorOrders] = useState({ loading: false, totalCount: 0 })
    const [logistics, setLogistics] = useState({ loading: false, totalCount: 0 })
    const [totalCashBalance, setTotalCashBalance] = useState({ loading: false, totalCount: 0 })
    const [walletBalance, setWalletBalance] = useState({ loading: false, totalCount: 0 })
    const [refresh, setRefresh] = useState(false)



    useEffect(() => {
        getOrdersCountData();
        getUsersCountData();
        getBalanceCountData();
    }, [ordersParams, refresh])


    const getOrdersCountData = () => {
        getAllOpenOrdersCount();
        getarrivedOrdersCount();
        getpendingPickUpOrdersCount();
        getpickedOrdersCount();
        getonHoldOrdersCount();
        getCanceledOrdersCount();
        getCompleteOrdersCount();
        scheduleOrdersCount();
        getAggregatorsOrdersCount();
    }

    const getUsersCountData = () => {
        getShopsUserCount();
        getBuyerCount();
        getInactiveDriversCount();
        getActiveDriversCount();
        getIntegratorsCount();
        getLogisticsCount();
        getAggregatorUserCount();
    }

    const getBalanceCountData = () => {
        getTotalCashbalance();
        getWalletBalance();
    }

    const getAllOpenOrdersCount = async () => {
        const searchParams = { ...ordersParams }
        Object.assign(searchParams, { status: "created,arrived,pending pickup,picked" });
        setAllOpenOrders((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getOrdersCount(payload))
            .then(response => {
                const total = response.data.count ? response.data.count : 0
                setAllOpenOrders((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getarrivedOrdersCount = async () => {
        const searchParams = { ...ordersParams }
        Object.assign(searchParams, { status: "arrived" });
        setArrivedOrders((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getOrdersCount(payload))
            .then(response => {
                const total = response.data.count ? response.data.count : 0
                setArrivedOrders((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getpendingPickUpOrdersCount = async () => {
        const searchParams = { ...ordersParams }
        Object.assign(searchParams, { status: "pending pickup" });
        setPendingPickupOrders((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getOrdersCount(payload))
            .then(response => {
                const total = response.data.count ? response.data.count : 0
                setPendingPickupOrders((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getpickedOrdersCount = async () => {
        const searchParams = { ...ordersParams }
        Object.assign(searchParams, { status: "picked" });
        setPickedOrders((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getOrdersCount(payload))
            .then(response => {
                const total = response.data?.count ? response.data.count : 0
                setPickedOrders((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getonHoldOrdersCount = async () => {
        const searchParams = { ...ordersParams }
        Object.assign(searchParams, { status: "hold" });
        setOnHoldOrders((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getOrdersCount(payload))
            .then(response => {
                const total = response.data.count ? response.data.count : 0
                setOnHoldOrders((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getCompleteOrdersCount = async () => {
        const searchParams = { ...ordersParams }
        Object.assign(searchParams, { status: "completed" });
        setCompletedOrders((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getOrdersCount(payload))
            .then(response => {
                const total = response.data?.count ? response.data.count : 0
                setCompletedOrders((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getCanceledOrdersCount = async () => {
        const searchParams = { ...ordersParams }
        Object.assign(searchParams, { status: "canceled" });
        setCanceledOrders((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getOrdersCount(payload))
            .then(response => {
                const total = response.data?.count ? response.data.count : 0
                setCanceledOrders((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getShopsUserCount = async () => {
        const searchParams = { ...usersParams }
        Object.assign(searchParams, { type: "Shop" });
        setShops((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getUsersCount(payload))
            .then(response => {
                const total = response.data?.count ? response.data.count : 0
                setShops((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getBuyerCount = async () => {
        const searchParams = { ...usersParams }
        Object.assign(searchParams, { type: "Buyer" });
        setBuyers((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getUsersCount(payload))
            .then(response => {
                const total = response.data?.count ? response.data.count : 0
                setBuyers((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getIntegratorsCount = async () => {
        const searchParams = { ...usersParams }
        Object.assign(searchParams, { type: "Integrator" });
        setIntegrators((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getUsersCount(payload))
            .then(response => {
                const total = response.data?.count ? response.data.count : 0
                setIntegrators((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getLogisticsCount = async () => {
        const searchParams = { ...usersParams }
        Object.assign(searchParams, { type: "Logistics", });
        setLogistics((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getUsersCount(payload))
            .then(response => {
                const total = response.data?.count ? response.data.count : 0
                setLogistics((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getActiveDriversCount = async () => {
        const searchParams = { ...usersParams }
        Object.assign(searchParams, { type: "Driver", active: "true" });
        setActiveDrivers((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getUsersCount(payload))
            .then(response => {
                const total = response.data?.count ? response.data.count : 0
                setActiveDrivers((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getInactiveDriversCount = async () => {
        const searchParams = { ...usersParams }
        Object.assign(searchParams, { type: "Driver", active: "false" });
        setInactiveDrivers((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getUsersCount(payload))
            .then(response => {
                const total = response.data?.count ? response.data.count : 0
                setInactiveDrivers((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const scheduleOrdersCount = async () => {
        const payload = { search: scheduledOrderParams }
        setScheduleOrders((prevState) => ({ ...prevState, loading: true }));
        await dispatch(getScheduleOrdersCount(payload))
            .then(
                response => {
                    const total = response.data?.count ? response.data.count : 0
                    setScheduleOrders((prevState) => ({
                        ...prevState,
                        totalCount: total,
                        loading: false
                    }));
                },
                error => {
                    toast.error(error.response.data?.message)
                }
            )
    }

    const getTotalCashbalance = async () => {
        setTotalCashBalance((prevState) => ({ ...prevState, loading: true }));
        const payload = balanceParams
        await dispatch(getCodBalanceCount(payload))
            .then(response => {
                const totalAmount = response.data?.total_balance
                setTotalCashBalance((prevState) => ({
                    ...prevState,
                    totalCount: totalAmount,
                    loading: false
                }));
            })
    }

    const getWalletBalance = async () => {
        setWalletBalance((prevState) => ({ ...prevState, loading: true }));
        const payload = balanceParams
        await dispatch(getWalletBalanceCount(payload))
            .then(response => {
                const totalAmount = response.data?.total_balance
                setWalletBalance((prevState) => ({
                    ...prevState,
                    totalCount: totalAmount,
                    loading: false
                }));
            })
    }

    const getAggregatorUserCount = async () => {
        const searchParams = { ...usersParams }
        setAggregators((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getAggregatorsCount(payload))
            .then(response => {
                const total = response.data?.count ? response.data.count : 0
                setAggregators((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    const getAggregatorsOrdersCount = async () => {
        const searchParams = { ...ordersParams, user_id: "" }
        setAggregatorOrders((prevState) => ({ ...prevState, loading: true }));
        const payload = { search: searchParams }
        await dispatch(getAggregatorsTotalOrdersCount(payload))
            .then(response => {
                const total = response.data?.count ? response.data.count : 0
                setAggregatorOrders((prevState) => ({
                    ...prevState,
                    totalCount: total,
                    loading: false
                }));
            })
    }

    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Dashboard</h1>
                        </div>
                        <div className="col-sm-6">
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mb-4 hour--details">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                        <a onClick={() => handleChangeStep("", 'all')} className={`nav-link ${activeStep === 'all' && 'active'}`} data-toggle="tab" href="#all">All</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={() => handleChangeStep(currentDate, 'toDay')} className={`nav-link ${activeStep === 'toDay' && 'active'}`} data-toggle="tab" href="#menu1">Today</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={() => handleChangeStep(yesterday, 'yesterday')} className={`nav-link ${activeStep === 'yesterday' && 'active'}`} data-toggle="tab" href="#menu1">1 Day</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={() => handleChangeStep(fiveDaysAgo, 'fiveDaysAgo')} className={`nav-link ${activeStep === 'fiveDaysAgo' && 'active'}`} data-toggle="tab" href="#menu2">5 Days</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={() => handleChangeStep(tenDaysAgo, 'tenDaysAgo')} className={`nav-link ${activeStep === 'tenDaysAgo' && 'active'}`} data-toggle="tab" href="#menu3">10 Days</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={() => handleChangeStep(fifteenDaysAgo, 'fifteenDaysAgo')} className={`nav-link ${activeStep === 'fifteenDaysAgo' && 'active'}`} data-toggle="tab" href="#menu4">15 Days</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={() => handleChangeStep(thirtyDaysAgo, 'thirtyDaysAgo')} className={`nav-link ${activeStep === 'thirtyDaysAgo' && 'active'}`} data-toggle="tab" href="#menu5">30 Days</a>
                    </li>
                    <li className='nav-item ml-3'>
                        <DatePicker className="form-control cursor-pointer" placeholderText="From Date"
                            showIcon={selectedDate?.from_date ? false : true}
                            dateFormat='dd/MM/yyyy'
                            dropdownMode="select"
                            isClearable={true}
                            selected={selectedDate?.from_date}
                            onChange={date => handleChangeDate(date, "from_date")}
                            showYearDropdown
                            showMonthDropdown
                            adjustDateOnChange
                            autocomplete={false}
                        />
                    </li>
                    <li className='nav-item ml-3'>
                        <DatePicker className="form-control cursor-pointer" placeholderText="To Date"
                            showIcon={selectedDate?.to_date ? false : true}
                            dateFormat='dd/MM/yyyy'
                            dropdownMode="select"
                            isClearable={true}
                            selected={selectedDate?.to_date}
                            onChange={date => handleChangeDate(date, "to_date")}
                            showYearDropdown
                            showMonthDropdown
                            adjustDateOnChange
                            autocomplete={false}
                        />
                    </li>
                    <li className="nav-item ml-5">
                        <button disabled={allOpenOrders.loading} type="button" onClick={() => setRefresh(!refresh)} className="btn btn-secondary"><i className={`fa-regular fa-rotate-right mr-2 ${allOpenOrders.loading && 'fa-spin'}`}></i>Refresh</button>
                    </li>
                </ul>
            </div>
            <div className="tab-content mb-5">
                <div id="home" className="container-fluid tab-pane active">
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className='item--wrapper'>
                                    <div className="item--boxes">
                                        <div style={{ background: '#00a8e8' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={allOpenOrders.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {allOpenOrders.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> : allOpenOrders.totalCount}
                                                </h3>
                                                <p>Open Orders</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/new-order.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/open-orders" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#ff595e' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={pendingPickupOrders.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {pendingPickupOrders.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> : pendingPickupOrders.totalCount}
                                                </h3>
                                                <p>Pending Pickup</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/Pending-Pickup.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/pending-pickup" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#E7E247' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={arrivedOrders.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {arrivedOrders.loading ?
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div> :
                                                        arrivedOrders.totalCount
                                                    }
                                                </h3>
                                                <p>Arrived</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/Arrived.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/arrived-orders" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#662e9b' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={pickedOrders.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {pickedOrders.loading ?
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                        :
                                                        pickedOrders.totalCount
                                                    }
                                                </h3>
                                                <p>Picked</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/Picked.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/picked-orders" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#f79824' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={onHoldOrders.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {onHoldOrders.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        onHoldOrders.totalCount
                                                    }
                                                </h3>
                                                <p>On Hold</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/On-Hold.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/on-hold-orders" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#3bb273' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={completedOrders.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {completedOrders.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        completedOrders.totalCount
                                                    }
                                                </h3>
                                                <p>Completed</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/completed-order.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/completed-orders" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#e15554' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={canceledOrders.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }}>
                                                    {canceledOrders.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        canceledOrders.totalCount
                                                    }
                                                </h3>
                                                <p>Cancelled</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/canceled-order.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/canceled-orders" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#E7E247' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={scheduleOrders.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {scheduleOrders.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        scheduleOrders.totalCount
                                                    }
                                                </h3>
                                                <p>Scheduled Orders</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/new-order.png')} alt="scheduled-orders" />
                                            </div>
                                            <Link to="/scheduled-orders" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#3bb273' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={activeDrivers.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {activeDrivers.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        activeDrivers.totalCount
                                                    }
                                                </h3>
                                                <p>Active Driver</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/active-driver.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/active-drivers" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#e15554' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={inactiveDrivers.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {inactiveDrivers.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        inactiveDrivers.totalCount
                                                    }
                                                </h3>
                                                <p>Inactive Driver</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/inactive-driver.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/inactive-drivers" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#4cc9f0' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={buyers.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }}>
                                                    {buyers.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        buyers.totalCount
                                                    }
                                                </h3>
                                                <p>Buyers</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/users.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/buyers" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#4cc9f0' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={shops.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {shops.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                        : shops.totalCount
                                                    }
                                                </h3>
                                                <p>Shops</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/shops.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/shops" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#4cc9f0' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={integrators.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {integrators.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        integrators?.totalCount
                                                    }
                                                </h3>
                                                <p>Integrators</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/Integrators.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/integrators" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#4cc9f0' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={logistics.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {logistics.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        logistics?.totalCount
                                                    }
                                                </h3>
                                                <p>DSP</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/DSP.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/logistics" className="small-box-footer new-box-footer">More info<i className="fas fa-arrow-circle-right ml-1"></i></Link>
                                        </div>
                                    </div>

                                    <div className="item--boxes">
                                        <div style={{ background: '#4cc9f0' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={aggregators.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {aggregators.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        aggregators?.totalCount
                                                    }
                                                </h3>
                                                <p>Aggregators</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/DSP.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/aggregators" className="small-box-footer new-box-footer">More info<i className="fas fa-arrow-circle-right ml-1"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#3bb273' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={aggregatorsOrders.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {aggregatorsOrders.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        aggregatorsOrders.totalCount
                                                    }
                                                </h3>
                                                <p>Aggregator Complete Orders</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/completed-order.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/aggregators" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>

                                    <div className="item--boxes">
                                        <div style={{ background: '#3bb273' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={aggregatorsOrders.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {aggregatorsOrders.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        aggregatorsOrders.totalCount + completedOrders.totalCount
                                                    }
                                                </h3>
                                                <p>Total Complete Orders</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/completed-order.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/aggregators" className="small-box-footer new-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>

                                    <div className="item--boxes">
                                        <div style={{ background: '#f79824' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={totalCashBalance.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {totalCashBalance.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        Math.abs(totalCashBalance?.totalCount)
                                                    }
                                                </h3>
                                                <p>COD Balance</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/Reports.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/invoices" className="small-box-footer new-box-footer">More info<i className="fas fa-arrow-circle-right ml-1"></i></Link>
                                        </div>
                                    </div>
                                    <div className="item--boxes">
                                        <div style={{ background: '#f79824' }} className="small-box new-samll-box">
                                            <div className="inner box-inner">
                                                <h3 style={walletBalance.loading ? { fontSize: '1.2rem' } : { fontSize: '1.6rem' }} >
                                                    {walletBalance.loading ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        walletBalance?.totalCount
                                                    }
                                                </h3>
                                                <p>Wallet Balance</p>
                                            </div>
                                            <div className="icon box-icon">
                                                <img className='box-image' src={require('../../assets/dashImg/Reports.png')} alt="Completed-Orders" />
                                            </div>
                                            <Link to="/wallet-history" className="small-box-footer new-box-footer">More info<i className="fas fa-arrow-circle-right ml-1"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(AdminDashboard))