// ----  Export CSV File Headers ----- \\
export const CanceledHeader = [
    { label: "Order ID", key: "_id" },
    { label: "Order Number", key: "ordernumber" },
    { label: "Integrator Number", key: "integrator_number" },
    { label: "Shop Order Number", key: "shop_ordernumber" },
    { label: "Shop ID", key: "shopid" },
    { label: "Shop Name", key: "shopname" },
    { label: "Driver Name", key: "drivername" },
    { label: "Driver Phone", key: "driverphone" },
    { label: "Buyer Name", key: "receiver_name" },
    { label: "Buyer Phone", key: "buyerphone" },
    { label: "KM", key: "distance" },
    { label: "Delivery Fee", key: "deliveryfee" },
    { label: "Price", key: "orderprice" },
    { label: "Utrac Fee", key: "utrackfee" },
    { label: "Tax Fee", key: "taxfee" },
    { label: "Destination", key: "receiver_address" },
    { label: "Payment Method", key: "paymentmethod" },
    { label: "Status", key: "status" },
    { label: "Reason", key: "reason" },
    { label: "Created At", key: "_created_at" },
    { label: "Canceled At", key: "canceldate" },
];



export const openHeader = [
    { label: "Order ID", key: "_id" },
    { label: "Order Number", key: "ordernumber" },
    { label: "Integrator Number", key: "integrator_number" },
    { label: "Shop Order Number", key: "shop_ordernumber" },
    { label: "Shop ID", key: "shopid" },
    { label: "Shop Name", key: "shopname" },
    { label: "Driver Name", key: "drivername" },
    { label: "Driver Phone", key: "driverphone" },
    { label: "Buyer Name", key: "receiver_name" },
    { label: "Buyer Phone", key: "buyerphone" },
    { label: "KM", key: "distance" },
    { label: "Delivery Fee", key: "deliveryfee" },
    { label: "Price", key: "orderprice" },
    { label: "Utrac Fee", key: "utrackfee" },
    { label: "Tax Fee", key: "taxfee" },
    { label: "Destination", key: "receiver_address" },
    { label: "Payment Method", key: "paymentmethod" },
    { label: "Created At", key: "_created_at" },
    { label: "Accepted At", key: "acceptdate" },
    { label: "Arrived to shop", key: "arrived_to_shop_date" },
    { label: "Picked At", key: "pickupdate" },
    { label: "Status", key: "status" },
    { label: "Reason", key: "reason" },
    { label: "Creation To Accept", key: "duration_creation_to_accept" },
    { label: "Accept To Arrive", key: "duration_accept_to_arrive" },
    { label: "Arrive To Pickup", key: "duration_arrive_to_pickup" },
    { label: "Creation To Deliver", key: "duration_creation_to_deliver" },
];

export const completedHeader = [
    { label: "Order ID", key: "_id" },
    { label: "Order Number", key: "ordernumber" },
    { label: "Integrator Number", key: "integrator_number" },
    { label: "Shop Order Number", key: "shop_ordernumber" },
    { label: "Shop ID", key: "shopid" },
    { label: "Shop Name", key: "shopname" },
    { label: "Driver Name", key: "drivername" },
    { label: "Driver Phone", key: "driverphone" },
    { label: "Buyer Name", key: "receiver_name" },
    { label: "Buyer Phone", key: "buyerphone" },
    { label: "Destination", key: "receiver_address" },
    { label: "KM", key: "distance" },
    { label: "Delivery Fee", key: "deliveryfee" },
    { label: "Price", key: "orderprice" },
    { label: "Utrac Fee", key: "utrackfee" },
    { label: "Tax Fee", key: "taxfee" },
    { label: "Payment Method", key: "paymentmethod" },
    { label: "Created At", key: "_created_at" },
    { label: "Accepted At", key: "acceptdate" },
    { label: "Arrived to shop", key: "arrived_to_shop_date" },
    { label: "Picked At", key: "pickupdate" },
    { label: "Delivered At", key: "deliverydate" },
    { label: "Status", key: "status" },
    { label: "Reason", key: "reason" },
    { label: "Creation To Accept", key: "duration_creation_to_accept" },
    { label: "Accept To Arrive", key: "duration_accept_to_arrive" },
    { label: "Arrive To Pickup", key: "duration_arrive_to_pickup" },
    { label: "Creation To Deliver", key: "duration_creation_to_deliver" },
];










