import { authAxios } from "../../Config/axios";
import { trimObjValues } from "../../Helper";


export const getFavoriteLocation = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/favorite_location/get_favorite_locations", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const addFavoriteLocation = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/favorite_location/add_favorite_location", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getFavoriteLocationDetail = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/favorite_location/get_favorite_locations", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const updateFavoriteLocation = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/favorite_location/update_favorite_location", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}


export const getLocationByGoogleShareURL = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/area/get_address_with_google_share_url", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}



