import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { getDriversWithinShopRange } from '../../Redux/action/Orders';
import { getUserForSearchBar } from '../../Redux/action/User';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { default as ReactSelect } from "react-select";
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { selectOptionStyle } from '../../Helper';
import { SidebarContext } from '../../Context/SidebarContext';



function AssignOrderModal(props) {
    const dispatch = useDispatch()
    const { loader, modal, closeModal, handleAssignOrder, orderDetails } = props
    const { _id, ordernumber, shopid, paymentmethod, integrator_number } = orderDetails ? orderDetails : {}
    const [selectedDriver, setSelectedDriver] = useState("");
    const [drivers, setDrivers] = useState([])
    const [driverSearchable, setDriverSearchable] = useState(false)
    const [driverFetching, setDriverFetching] = useState(false)
    const [searchedDrivers, setSearchedDrivers] = useState([])
    const [selectedSearchedDriver, setSelectedSearchedDriver] = useState("");
    const [driverType, setDriverType] = useState('within_shop_range')
    const { selectedItem , setSelectedItem } = useContext(SidebarContext)

    useEffect(() => {
        if (shopid) {
            getDriversDataWithShopRange();
            setSelectedItem([])
        }
        else{
            setDriverSearchable(true)
        }
    }, [orderDetails])

    const formik = useFormik({
        initialValues: {
            driver_id: ''
        },
        validationSchema: Yup.object({
            driver_id: Yup.string()
                .required("This field is required"),
        }),
        onSubmit: async values => {
            let fieldValues = values
            let driver_id = fieldValues.driver_id
            await handleAssignOrder(driver_id)
            formik.resetForm()
            setSelectedDriver("")
            setSelectedSearchedDriver("")
        },
    });

    // load options using API call with searched Drivers
    const getSearchedDrivers = async (searchDriver) => {
        const digitNumbers = /^-?[\+|\d.]+(?:e-?\d+)?$/
        let payload = {
            search: { id: '', type: "Driver", nickname: '', username: "", phone: '' },
            shorting: { order_in: "asc", order_by: "nickname" }
        }
        if (digitNumbers.test(searchDriver)) {
            let phoneNumber = searchDriver.toString();
            if (phoneNumber[0] === "+") {
                phoneNumber = phoneNumber.substring(1)
            }
            payload.search.phone = phoneNumber
        } else {
            payload.search.nickname = searchDriver
        }
        setDriverFetching(true)
        await dispatch(getUserForSearchBar(payload))
            .then(
                response => {
                    setDriverFetching(false)
                    if (response.status == "1") {
                        const resData = response.data.users
                        const alldrivers = []
                        resData.forEach(item => {
                            const { _id, nickname, username, phone, available, online } = item
                            let is_available = available ? 'Available' : 'Unavailable'
                            let is_online = online ? 'Online' : 'Offline'
                            const labelwithPhone = `${nickname ? nickname : username} (${phone}) , ${is_online} , ${is_available}`
                            alldrivers.push({ label: labelwithPhone, value: _id })
                        })
                        setSearchedDrivers(alldrivers)
                    } else {
                        setSearchedDrivers([])
                    }
                },
                error => {
                    setSearchedDrivers([])
                    setDriverFetching(false)
                    toast.error(error.response.data?.message)
                }
            )
    };

    const getDriversData = useCallback(debounce((searchvalue) => getSearchedDrivers(searchvalue), 500), []);
    const handleSearchDriver = (inputValue) => {
        const value = inputValue.trim()
        if (value && value.length > 0) {
            getDriversData(value);
        }
    }

    const handleChangeDriverType = (event) => {
        const { value } = event.target
        setDriverType(value)
        formik.setFieldValue('driver_id', "")
        if (value === 'manually_search') {
            setDriverSearchable(true)
            setSelectedDriver("")
        }
        else {
            setDriverSearchable(false)
            setSelectedSearchedDriver("")
        }
    }

    const handleSelectDriver = (option) => {
        if (option) {
            let { value } = option
            formik.setFieldValue('driver_id', value)
            setSelectedDriver(option);
        } else {
            formik.setFieldValue('driver_id', "")
            setSelectedDriver("");
        }
    }

    const handleSelectSearchedDriver = (option) => {
        if (option) {
            let { value } = option
            formik.setFieldValue('driver_id', value)
            setSelectedSearchedDriver(option);
        } else {
            formik.setFieldValue('driver_id', "")
            setSelectedSearchedDriver("");
        }
    }

    const getDriversDataWithShopRange = async () => {
        const payload = {
            shop_id: shopid,
            payment_method: paymentmethod,
        }

        await dispatch(getDriversWithinShopRange(!integrator_number || integrator_number === "Not Available" ? payload : { order_id: _id }))
            .then(
                response => {
                    const result = response.data && response.data.drivers
                    if (result && result.available_drivers && result.available_drivers.length > 0) {
                        const availableDrivers = result.available_drivers
                        const driversData = []
                        availableDrivers.forEach(item => {
                            const { _id, nickname, username, phone, available, online, distance } = item
                            let is_available = available ? 'Available' : 'Unavailable';
                            let is_online = online ? 'Online' : 'Offline'
                            const labelwithPhone = `${nickname ? nickname : username} (${phone})   ${is_online} , ${is_available}, ${distance} KM`
                            driversData.push({ label: labelwithPhone, value: _id })
                        })
                        setDrivers(driversData)
                    }
                }
            )
    };


    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Assign order to driver</h5>
                            <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {selectedItem?.length === 0 &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="control-label">Order Number</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                defaultValue={ordernumber}
                                                placeholder='Order number'
                                                disabled />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <div className="d-flex justify-content-between">
                                            <label className="control-label">Driver</label>
                                            {selectedItem?.length === 0 &&
                                                <>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input radio_input"
                                                            name='drivertype'
                                                            type='radio'
                                                            value='within_shop_range'
                                                            onChange={handleChangeDriverType}
                                                            checked={driverType === 'within_shop_range'}
                                                        />
                                                        <label className="form-check-label">Shop range</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input radio_input"
                                                            name='drivertype'
                                                            type='radio'
                                                            value='manually_search'
                                                            checked={driverType === 'manually_search'}
                                                            onChange={handleChangeDriverType}
                                                        />
                                                        <label className="form-check-label">Search</label>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        {driverSearchable ?
                                            <ReactSelect placeholder="Search driver"
                                                isClearable={true}
                                                isLoading={driverFetching}
                                                options={searchedDrivers}
                                                value={selectedSearchedDriver}
                                                onInputChange={handleSearchDriver}
                                                onChange={handleSelectSearchedDriver}
                                                styles={selectOptionStyle}
                                            />
                                            :
                                            <ReactSelect placeholder="Select driver"
                                                isClearable={true}
                                                options={drivers}
                                                hideSelectedOptions={false}
                                                value={selectedDriver}
                                                styles={selectOptionStyle}
                                                onChange={option => handleSelectDriver(option)}
                                            />
                                        }
                                        {formik.touched.driver_id && formik.errors.driver_id ? (<span className="text-danger">{formik.errors.driver_id}</span>) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button disabled={loader} type="submit" className="btn btn-success"> {loader ? "Processing.." : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default AssignOrderModal