import React, { useEffect } from 'react';
import { updateDriverDetails, getUserDetails } from '../../Redux/action/User';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';



function EditDriver(props) {
    const { setLoading } = props
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const location = useLocation()
    const { currentPage } = location.state ? location.state : {}


    useEffect(() => {
        getUserDetailsData()
    }, [])

    const getUserDetailsData = async () => {
        setLoading(true)
        await dispatch(getUserDetails({ user_id: params.id }))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const userdetails = response.data?.user
                        const fields = ['username', 'nickname', 'email', 'phone', 'active', 'is_vip', 'visa_pos', 'balance', 'car_type', 'car_plates', 'car_model', 'car_color' , 'vehicle_type'];
                        fields.forEach(field => formik.setFieldValue(field, userdetails[field], false));
                    } else {
                        toast.error('Data is not present')
                    }
                },
                error => {
                    toast.error(error.response.data?.message)
                    setLoading(false);
                }
            )
    }

    const formik = useFormik({
        initialValues: {
            username: '',
            nickname: '',
            email: '',
            phone: '',
            active: '',
            is_vip: '',
            visa_pos: '',
            balance: '',
            car_type: '',
            car_plates: "",
            car_model: "",
            car_color: "",
            vehicle_type : ""
        },

        validationSchema: Yup.object({

            username: Yup.string()
                .required("This fiels is required")
                .min(3, 'This field must be at least 3 characters'),

            nickname: Yup.string()
                .required("This fiels is required")
                .min(3, 'This field must be at least 3 characters'),

            email: Yup.string()
                .required("This fiels is required"),

            car_type: Yup.string()
                .required("This fiels is required"),

            car_plates: Yup.string()
                .required("This fiels is required"),

            car_model: Yup.string()
                .required("This fiels is required"),

            car_color: Yup.string()
                .required("This fiels is required")
        }),
        onSubmit: async values => {
            const fieldValues = values
            const payload = { user_id: params.id, update: { ...fieldValues } }
            setLoading(true)
            await dispatch(updateDriverDetails(payload))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == 1) {
                            toast.success(response.data.message)
                            history.push({
                                pathname: '/drivers',
                                state: { currentPage: currentPage }
                            })
                        }
                    },
                    error => {
                        setLoading(false)
                        const obj = (error && error.data && error.data.errors) || { message: 'Somthing went wrong' }
                        const errors = Object.values(obj)[0];
                        toast.error(errors)
                    }
                ).catch((error) => console.log("Catch Error", error))
        },
    });

    const handleChange = (e) => {
        let { name, value } = e.target
        value = JSON.parse(value)
        formik.setFieldValue(name, value)
    }

    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Edit Driver</h1>
                        </div>
                        {/* <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item "><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item">Drivers</li>
                                <li className="breadcrumb-item active">Edit</li>
                            </ol>
                        </div> */}
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={formik.handleSubmit} >
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">User name</label>
                                                    <input
                                                        required="required"
                                                        name="username"
                                                        placeholder='User name'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.username}
                                                        type="text" className="form-control" />
                                                    {formik.touched.username && formik.errors.username ? (<span className="text-danger">{formik.errors.username}</span>) : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Nick name</label>
                                                    <input
                                                        required
                                                        name="nickname"
                                                        placeholder='Nick name'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.nickname}
                                                        type="text" className="form-control" />
                                                    {formik.touched.nickname && formik.errors.nickname ? (<span className="text-danger">{formik.errors.nickname}</span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label" >Email address</label>
                                                    <input
                                                        required
                                                        name="email"
                                                        placeholder='Email address'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.email}
                                                        type="email" className="form-control" />
                                                    {formik.touched.email && formik.errors.email ? (<span className="text-danger">{formik.errors.email}</span>) : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label className="control-label">Phone</label>
                                                    <input
                                                        disabled
                                                        name="phone"
                                                        placeholder='Phone number'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.phone}
                                                        type="text" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label className="control-label" >Active</label>
                                                    <select className="custom-select" name='active' onChange={handleChange} value={formik.values.active} >
                                                        <option value=''>Select</option>
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label className="control-label" >VIP</label>
                                                    <select className="custom-select" name='is_vip' onChange={handleChange} value={formik.values.is_vip} >
                                                        <option value=''>Select</option>
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label className="control-label">Vehicle Type</label>
                                                    <select
                                                        className="form-control"
                                                        name='vehicle_type'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.vehicle_type}
                                                    >
                                                        <option value=''>Select..</option>
                                                        <option value="bike">Bike</option>
                                                        <option value="car">Car</option>
                                                        <option value="van">Van</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Vichel Plate No.</label>
                                                    <input
                                                        required
                                                        name="car_plates"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.car_plates}
                                                        type="text" className="form-control" />
                                                    {formik.touched.car_plates && formik.errors.car_plates ? (<span className="text-danger">{formik.errors.car_plates}</span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Vichel Brand</label>
                                                    <input
                                                        required
                                                        name="car_type"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.car_type}
                                                        type="text" className="form-control" />
                                                    {formik.touched.car_type && formik.errors.car_type ? (<span className="text-danger">{formik.errors.car_type}</span>) : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Vichel Color</label>
                                                    <input
                                                        required
                                                        name="car_color"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.car_color}
                                                        type="text" className="form-control" />
                                                    {formik.touched.car_color && formik.errors.car_color ? (<span className="text-danger">{formik.errors.car_color}</span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required ">
                                                    <label className="control-label">Vichel Model</label>
                                                    <input
                                                        required
                                                        name="car_model"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.car_model}
                                                        type="text" className="form-control" />
                                                    {formik.touched.car_model && formik.errors.car_model ? (<span className="text-danger">{formik.errors.car_model}</span>) : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label className="control-label" >Is visa pos</label>
                                                    <select className="custom-select" name='visa_pos' onChange={handleChange} value={formik.values.visa_pos} >
                                                        <option value=''>Select</option>
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Balance</label>
                                                    <input type="text" className="form-control" placeholder='Balance'
                                                        disabled
                                                        name="balance"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.balance}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pb-5 form-foot-btn">
                                            <button type="button" className="btn btn-primary mr-2" onClick={() => history.push({ pathname: '/drivers', state: { currentPage: currentPage } })}>Back</button>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(EditDriver))