import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { getGlobalWebConfig, login, saveFoodicsUser, saveFoodicsUserToken } from '../../Redux/action/Auth';
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { trimObjValues } from '../../Helper';
import { logOut } from '../../Redux/action/Auth';
import { countryCode } from '../../Config/constants';


function Login(props) {
  const { setLoading } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [authorization, setAuthorization] = useState(false)
  const userRoles = ['Admin', 'Shop', 'Integrator', 'ShopVendor', 'Logistics']
  const [businessAuthUrl, setBusinessAuthUrl] = useState("")


  const getGlobalWebConfigUrl = async (accessToken) => {
    setLoading(true)
    await dispatch(getGlobalWebConfig(accessToken))
      .then(
        response => {
          setLoading(false);
          if (response.status == "1") {
            const resData = response.data
            const business_auth_url = resData?.config?.params?.foodics?.business_auth_url
            setBusinessAuthUrl(business_auth_url)
          }
        },
        (error) => {
          setLoading(false);
          toast.error(error.response.data?.message)
        })
      .catch(error => console.log(error))
  }

  return (
    <>
      <div className="container-fluid login_box_container public-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 my-5">
              <div className="login_box">
                <div className="login_box_header">
                  <img
                    style={{ maxWidth: '40%' }}
                    src={
                      countryCode === '+965'
                        ? require('../../assets/logo/kuwait_logo.png')
                        : countryCode === '+974'
                          ? require('../../assets/logo/qatar_logo.png')
                          : require('../../assets/dist/img/login-logo.png')
                    }
                    alt="login-logo"
                  />
                </div>
                {!authorization &&
                  <div className="content_form_login">
                    <h3>Admin Login</h3>
                    <Formik
                      initialValues={{ username: '', password: '' }}
                      onSubmit={async (values, { setSubmitting }) => {
                        let fieldValues = trimObjValues(values)
                        if (!fieldValues.username) {
                          toast.error("User name is required!")
                        }
                        else if (!fieldValues.password) {
                          toast.error("Password is required!")
                        }
                        else {
                          setLoading(true)
                          await dispatch(login(fieldValues))
                            .then(
                              response => {
                                setLoading(false)
                                if (response.status === '1') {
                                  const user = response.data.user
                                  const created_by = response.data.user?.created_by || ""
                                  const accessToken = response.data.accessToken
                                  if (userRoles.includes(user.type)) {
                                    if (user.type === "ShopVendor" && created_by === "foodics" && user.foodics_access_token === 'Not Available') {
                                      dispatch(saveFoodicsUser(user))
                                      dispatch(saveFoodicsUserToken(accessToken))
                                      dispatch({ type: "SAVE_PROFILE_IMAGE", payload: response.data?.profile_image });
                                      dispatch({ type: "SAVE_SUB_USER", payload: response.data.sub_user });
                                      setAuthorization(true)
                                      getGlobalWebConfigUrl(accessToken)
                                    }
                                    else {
                                      const resData = response.data
                                      dispatch({ type: "SAVE_PROFILE_IMAGE", payload: resData?.profile_image });
                                      dispatch({ type: "USER_DATA_UPDATE", payload: resData.user });
                                      dispatch({ type: "SAVE_SUB_USER", payload: resData.sub_user });
                                      dispatch({ type: "SAVE_TOKEN", payload: resData.accessToken });
                                      toast.success("Logged in successfully")
                                      history.push('/')
                                      setTimeout(()=>{
                                        window.location?.reload(false);
                                      },1000)
                                    }
                                  } else {
                                    dispatch(logOut())
                                    toast.error("You don't have access to login")
                                  }
                                } else {
                                  toast.error(response.data.error[0])
                                }
                              },
                              error => {
                                console.log(error)
                                setLoading(false)
                                toast.error('some error occurred')
                              }
                            )
                        }
                      }}>
                      {({
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values
                      }) => (
                        <form onSubmit={handleSubmit} >
                          <div className="form-group">
                            <input
                              name="username"
                              onChange={handleChange}
                              value={values.username}
                              onBlur={handleBlur}
                              type="text" className="form-control" placeholder="Name" />
                          </div>
                          <div className="form-group">
                            <input
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              type={showPassword ? 'text' : 'password'}
                              className="form-control" placeholder="Password" id="password" />
                            <i style={{ top: "18px" }} className={showPassword ? "fas fa-eye" : " fas fa-eye-slash"} onClick={() => setShowPassword(!showPassword)} id="eye"></i>
                          </div>
                          <div className="form-group text-center pt-3">
                            <button type="submit" className="btn btn-primary">Sign In</button>
                          </div>
                        </form>
                      )}
                    </Formik>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className='d-flex justify-content-around'>
                          <Link to='/about-app'><span >About App</span></Link>
                          <Link to='/privacy-policy'><span >Privacy Policy</span></Link>
                          <Link to='/terms-of-service'><span >Terms</span></Link>
                        </div>
                      </div>
                    </div>
                    {countryCode !== '+966' &&
                      <div className='row pt-4'>
                        <div className='col-lg-12 d-flex justify-content-center'>
                          <h5 className='pr-1 text-gray'>Powered by</h5>
                          <img style={{ width: '90px', height: '32px', marginTop: '-3px' }} alt="utrac_logo" src={require('../../assets/logo/utrac_logo.png')} />
                        </div>
                      </div>
                    }
                    <div id="emailHelp" className="form-text text-center mt-5 text-dark">Not Registered?
                      <Link to="/register" className="fw-bold"> Create an Account</Link>
                    </div>
                  </div>
                }
                {authorization &&
                  <div className="content_form_login">
                    <h3>One time authorization to avail the delivery services</h3>
                    <form>
                      <div className="form-group text-right text-right pb-3"></div>
                      <div className="form-group text-center pb-3 pt-4">
                        <button disabled={!businessAuthUrl} onClick={() => window.location.href = businessAuthUrl} type="button" className="btn btn-success authenticate-btn">Authenticate</button>
                      </div>
                    </form>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default IsLoadingHOC(Login)