import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC'
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { updateArea, getAreaDetails } from '../../Redux/action/Area';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { default as ReactSelect } from "react-select";
import { selectOptionStyle } from '../../Helper';


function EditArea(props) {
    const { setLoading } = props
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams()
    const { userGlobalData, cityData } = useSelector(state => state.app)
    const { area_direction } = userGlobalData ? userGlobalData : []
    const [selectedCity, setSelectedCity] = useState(null)
    const location = useLocation()
    const { currentPage } = location.state ? location.state : {}


    useEffect(() => {
        getAreaDetailsData();
    }, [])

    const getAreaDetailsData = async () => {
        const payload = {
            is_detail: "true",
            search: { _id: params.id },
            shorting: { order_in: -1, order_by: "_created_at" }
        }
        setLoading(true)
        await dispatch(getAreaDetails(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const result = response.data.area
                        const fields = ["name", "name_ar", "num", 'direction', 'city_id'];
                        fields.forEach(field => formik.setFieldValue(field, result[field], false));
                        formik.setFieldValue("latitude", result.location[0])
                        formik.setFieldValue("longitude", result.location[1])
                        if (result.city_id) {
                            const selected_City = cityData.find(item => item.value === result?.city_id)
                            setSelectedCity(selected_City)
                        }
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                })
            .catch(error => console.log(error))
    }

    const formik = useFormik({
        initialValues: {
            area_id: params.id,
            name: '',
            name_ar: '',
            direction: '',
            city_id: '',
            num: '',
            latitude: '',
            longitude: ''
        },

        validationSchema: Yup.object({
            name: Yup.string()
                .required("This fiels is required")
                .min(3, 'This field must be at least 3 characters'),

            name_ar: Yup.string()
                .min(3, 'This field must be at least 3 characters')
                .required("This field is required"),

            direction: Yup.string()
                .required("This field is required"),

            city_id: Yup.string()
                .required("This field is required"),

            num: Yup.number()
                .required("This field is required")
                .typeError('You must specify a number')
                .min(0, "Please enter a positive number"),

            latitude: Yup.number()
                .required("This field is required")
                .typeError('You must specify a number'),

            longitude: Yup.number()
                .required("This field is required")
                .typeError('You must specify a number'),
        }),
        onSubmit: async values => {
            let fieldValues = values
            let locationMap = [fieldValues.latitude, fieldValues.longitude]
            // Add array in objects
            Object.assign(fieldValues, { "location": locationMap });
            setLoading(true)
            await dispatch(updateArea(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status === '1') {
                            toast.success(response.data.message)
                            history.push({ pathname: '/areas', state: { currentPage: currentPage } })
                        } else {
                            toast.error(response.data.error[0])
                        }
                    },
                    (error) => {
                        setLoading(false);
                        toast.error(error.response.data?.message)
                    }
                ).catch(error => console.log(error))
        }
    });

    const handleCitySelect = option => {
        if (option && option.value) {
            setSelectedCity(option)
            formik.setFieldValue('city_id', option.value);
        } else {
            setSelectedCity(null)
            formik.setFieldValue('city_id', "");
        }
    }

    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Edit Area</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={formik.handleSubmit} >
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">City</label>
                                                    <ReactSelect placeholder="Select City"
                                                        isClearable={true}
                                                        options={cityData}
                                                        hideSelectedOptions={false}
                                                        value={selectedCity}
                                                        onBlur={formik.handleBlur}
                                                        onChange={(option) => handleCitySelect(option)}
                                                        styles={selectOptionStyle}
                                                    />
                                                    {formik.touched.city_id && formik.errors.city_id ? (<span className="text-danger">{formik.errors.city_id}</span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Direction</label>
                                                    <select name='direction' className="form-control"
                                                        value={formik.values.direction}
                                                        onChange={formik.handleChange}>
                                                        <option value=''>Select Direction</option>
                                                        {area_direction && area_direction.length > 0 &&
                                                            area_direction.map((item, index) =>
                                                                <option key={index + 1} value={item.label}>{item.label}</option>)}
                                                    </select>
                                                    {formik.touched.direction && formik.errors.direction ? (<span className="text-danger">{formik.errors.direction}</span>) : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">English Name</label>
                                                    <input
                                                        required
                                                        name="name"
                                                        placeholder='English name'
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.name}
                                                        type="text" className="form-control" />
                                                    {formik.touched.name && formik.errors.name ? (<span className="text-danger">{formik.errors.name}</span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Arabic Name</label>
                                                    <input
                                                        required
                                                        name="name_ar"
                                                        placeholder='Arabic name'
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.name_ar}
                                                        type="text" className="form-control" />
                                                    {formik.touched.name_ar && formik.errors.name_ar ? (<span className="text-danger">{formik.errors.name_ar}</span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Number</label>
                                                    <input
                                                        required
                                                        name='num'
                                                        placeholder='Number'
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.num}
                                                        type="number" className="form-control" id="exampleInputEmail1" />
                                                    {formik.touched.num && formik.errors.num ? (<span className="text-danger">{formik.errors.num}</span>) : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Map Location: Latitude</label>
                                                    <input
                                                        required
                                                        name='latitude'
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.latitude}
                                                        placeholder='31.988485'
                                                        type="number" className="form-control" id="exampleInputEmail1" />
                                                    {formik.touched.latitude && formik.errors.latitude ? (<span className="text-danger">{formik.errors.latitude}</span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Map Location : Longitude</label>
                                                    <input
                                                        required
                                                        name='longitude'
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.longitude}
                                                        placeholder='36.951485'
                                                        type="number" className="form-control" id="exampleInputEmail1" />
                                                    {formik.touched.longitude && formik.errors.longitude ? (<span className="text-danger">{formik.errors.longitude}</span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pb-5 form-foot-btn">
                                            <Link to={{ pathname: '/areas', state: { currentPage: currentPage } }} ><button type="button" className="btn btn-primary mr-2">Back</button></Link>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(EditArea))