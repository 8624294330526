import React, { useEffect, useState } from 'react';
import { addNotification } from '../../Redux/action/App';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

function PushMessageModal(props) {
    const { orderDetails, pushMsgModel, handlePushmsgModel } = props
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const { _id, driverphone, drivername, driverid } = orderDetails ? orderDetails : {}
    const [driver, setDriver] = useState('')


    useEffect(() => {
        formik.setFieldValue('users', [driverid])
        formik.setFieldValue('order_id', _id)
        if (driverphone || drivername) {
            const driverWithPhone = `${drivername || ''} (${driverphone || ''})`
            setDriver(driverWithPhone)
        }
    }, [_id])

    const formik = useFormik({
        initialValues: {
            user_type: "Driver",
            message: '',
            users: [],
            order_id: ''
        },
        validationSchema: Yup.object({
            message: Yup.string()
                .required("This field is required")
                .min(5, 'Message must be at least 5 characters long')
        }),

        onSubmit: async values => {
            const fieldValues = values
            if (!fieldValues.users[0]) {
                toast.error('Driver is not found')
            }
            else {
                setLoader(true)
                await dispatch(addNotification(fieldValues))
                    .then(
                        response => {
                            setLoader(false)
                            if (response.status == '1') {
                                toast.success(response.data.message)
                                formik.resetForm();
                                handlePushmsgModel();
                            } else {
                                toast.error(response.data?.message)
                            }
                        },
                        error => {
                            setLoader(false)
                            toast.error(error.response.data?.message)
                        }
                    )
            }
        }
    });
    return (
        <div id="myModal" className={`modal fade cs--modal scroler-fixed ${pushMsgModel ? "show" : "hide"}`} style={{ display: pushMsgModel ? "block" : "none" }}
            role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit} >
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Push the notification to driver</h5>
                            <button onClick={() => handlePushmsgModel()} type="button"
                                className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Driver</label>
                                        <input className="form-control" placeholder='Driver phone'
                                            type="text"
                                            value={driver}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">Message</label>
                                        <textarea
                                            className="form-control"
                                            type="text"
                                            name="message"
                                            required
                                            rows={3}
                                            placeholder='Enter the message'
                                            onChange={formik.handleChange}
                                            value={formik.values.message}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.message && formik.errors.message ? (<span className="text-danger">{formik.errors.message}</span>) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={() => handlePushmsgModel()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-success">{loader ? "Processing.." : "Send"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default PushMessageModal