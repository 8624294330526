import React, { useState, useContext } from 'react';
import { moveSheduleToOpenOrders, bulkScheduleToCreateOrder } from '../../Redux/action/Orders';
import { SidebarContext } from '../../Context/SidebarContext';



function MoveOrderModel(props) {
    const { orderId, modal, closeModal, getListingData } = props
    const [loader, setLoader] = useState(false)

    const {
        dispatch,
        toast,
        selectedItem,
        setSelectedItem,
    } = useContext(SidebarContext)

    const handleConfirmed = async () => {
        const payload = { order_id: orderId }
        const bulkPayload = { orders: [...selectedItem] }
        setLoader(true)
        await dispatch(selectedItem && selectedItem.length > 0 ? bulkScheduleToCreateOrder(bulkPayload) : moveSheduleToOpenOrders(payload))
            .then(
                response => {
                    setLoader(false)
                    if (response.status === '1') {
                        toast.success("Order has been created successfully")
                        setSelectedItem([]);
                        getListingData();
                        closeModal();
                    } else {
                        toast.error(response.data.error[0])
                    }
                }, error => {
                    setLoader(false)
                    console.log(error)
                }
            )
    }

    return (
        <div id="myModal" className={`modal cs--modal fade ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}>
            <div className="modal-dialog modal-confirm">
                <div className="modal-content">
                    <div className="modal-header flex-column">
                        <h4 className="modal-title w-100 text-center text-secondary">Please Confirm</h4>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div className="modal-body">
                        <h5 className='text-dark' style={{ fontSize: '20px' }} >Do you want to move into open order?</h5>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" onClick={closeModal} className="btn btn-secondary" data-dismiss="modal">No</button>
                        <button type="button" onClick={handleConfirmed} className="btn btn-primary">{loader ? "Processing..." : "YES"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoveOrderModel