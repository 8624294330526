import React, { useState, useEffect } from 'react';
import IsLoadingHOC from '../../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../../Components/IsLoggedinHOC';
import { useParams} from 'react-router-dom';
import UserInfomation from './UserInfomation';
import ShopInfomation from './ShopInfomation';
import { useDispatch, useSelector } from 'react-redux';
import { getCities } from '../../../Redux/action/City';
import { saveCityData } from '../../../Redux/action/App';
import { toast } from 'react-toastify';
import { getShopVendorDetails } from '../../../Redux/action/User';
import { addUserInfoMation, addShopInfoMation, } from '../../../Redux/action/Shop';



function EditVendor(props) {
    const { setLoading } = props
    const params = useParams()
    const dispatch = useDispatch()
    const { form_step } = useSelector(state => state.shop)
    const [searchParams] = useState({ id: "", name: "" })
    const [sorting] = useState({ order_in: -1, order_by: "_created_at" })


    useEffect(() => {
        getCitiesData();
        if (params.id) {
            getShopVendorDetailsData();
        }
    }, [])

  
    const getShopVendorDetailsData = async () => {
        setLoading(true)
        const payload = {
            search: {
                user_search: { _id: params.id }
            },
            is_detail: true,
        }
        await dispatch(getShopVendorDetails(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const resData = response.data.users[0]
                        setMultiStepsFormData(resData);
                    }
                    else {
                        toast.error("Shop vendor details not found")
                    }
                },
                error => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }


    const setMultiStepsFormData = async (data) => {
        const { username, email, phone, shop_information } = data ? data : {}
        const {
            _id,
            shop,
            mainsort,
            subsort,
            abbreviation,
            city,
            locationarea,
            locationaddress,
            active,
            locationmap,
            description,
        } = shop_information ? shop_information : {}

        dispatch(addUserInfoMation({ username, phone, email, active }))
        dispatch(addShopInfoMation({
            shop_id: _id,
            shop,
            mainsort,
            subsort,
            abbreviation,
            city,
            locationarea,
            locationaddress,
            latitude: locationmap[0],
            longitude: locationmap[1],
            description,
        }))
    }

    const getCitiesData = async () => {
        const payload = {
            search: { ...searchParams },
            shorting: { order_in: sorting.order_in, order_by: sorting.order_by },
        }
        setLoading(true)
        await dispatch(getCities(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const cityData = []
                        const resData = response.data.cities
                        resData.map(item =>
                            cityData.push({ value: item._id, label: item.name })
                        )
                        dispatch(saveCityData(cityData))
                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Edit Shop Vendor</h1>
                        </div>
                        {/* <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to ="/" >Home</Link></li>
                                <li className="breadcrumb-item">Integrators</li>
                                <li className="breadcrumb-item active">Edit</li>
                            </ol>
                        </div> */}
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary card-tabs">
                                <div className="card-header p-0 pt-1">
                                    <ul className="nav nav-tabs" >
                                        <li className="nav-item">
                                            <span className={`nav-link ${form_step === "USER_INFO" && "active"}`} >User information</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link ${form_step === "SHOP_INFO" && "active"}`}>Shop Vendor Information</span>
                                        </li>
                                    </ul>
                                </div>
                                {form_step === "USER_INFO" && <UserInfomation />}
                                {form_step === "SHOP_INFO" && <ShopInfomation  />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(EditVendor))
