import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../../Components/IsLoggedinHOC';
import { setFormCurrentStep } from '../../../Redux/action/Shop';
import { updateIntegratorUser } from '../../../Redux/action/User';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { default as ReactSelect } from "react-select";
import { getAreas } from '../../../Redux/action/Area';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { selectOptionStyle } from '../../../Helper';



function ShopInfoMation(props) {
    const { setLoading, prices } = props
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams()
    const [selectedCity, setSelectedCity] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null)
    const [areas, setAreas] = useState([])
    const { cityData } = useSelector(state => state.app)
    const { userInfo, shopInfo } = useSelector(state => state.shop)
    const { shop_id } = shopInfo ? shopInfo : {}
    const [selectedPrice, setSelectedPrice] = useState(null)



    useEffect(() => {
        if (shopInfo && shopInfo.shop) {
            const fields = ["shop", "latitude", "longitude", "abbreviation", "city", "locationarea", "locationaddress", "description" , "share_location_service_type"];
            fields.forEach(field => formik.setFieldValue(field, shopInfo[field], false));

            let city_id = ''
            if (shopInfo.city) {
                const selected_City = cityData.find(item => item.label === shopInfo?.city)
                if (selected_City) {
                    city_id = selected_City.value
                    setSelectedCity(selected_City)
                }
            }
            if (city_id) {
                getAreasData(city_id)
            }
            if (shopInfo && shopInfo.prices && shopInfo.prices.length > 0) {
                let existPrices = [...shopInfo.prices]
                const results = prices.filter(({ value: id1 }) => existPrices.some((id2) => id2 === id1))
                setSelectedPrice(results)
                formik.setFieldValue('prices', existPrices)
            }
        }
    }, [])

    // Get Areas Drop down list selected by City
    const getAreasData = async (city_id) => {
        const payload = {
            search: { name: "", city_id: city_id },
            shorting: { order_in: -1, order_by: "_created_at" },
        }
        setLoading(true)
        await dispatch(getAreas(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const areaDropList = []
                        const resData = response.data.areas || []
                        resData.forEach(item => {
                            areaDropList.push({ value: item._id, label: item.name })
                        })
                        setAreas(areaDropList)
                        if (shopInfo.locationarea) {
                            const selected_Area = areaDropList.find(item => item.label === shopInfo?.locationarea)
                            if (selected_Area) {
                                setSelectedArea(selected_Area)
                            }
                        }
                    } else {
                        toast.error('Location area not found selected by city')
                        setAreas([])
                        setSelectedArea(null)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data.message)
                }
            )
    }

    const formik = useFormik({
        initialValues: {
            shop: "",
            mainsort: "",
            subsort: "",
            abbreviation: "",
            city: "",
            locationarea: "",
            locationaddress: "",
            description: "",
            latitude: '',
            longitude: '',
            prices: [],
            share_location_service_type: ""
        },

        validationSchema: Yup.object({
            shop: Yup.string()
                .required("This field is required")
                .min(3, 'Shop name must be at least 3 characters'),

            abbreviation: Yup.string()
                .required("This field is required")
                .min(2, 'Abbreviation must be at least 2 characters'),

            city: Yup.string()
                .required("This field is required"),

            locationarea: Yup.string()
                .required("This field is required")
                .min(3, 'Locationarea must be at least 3 characters'),

            locationaddress: Yup.string()
                .required("This field is required")
                .min(5, 'Location address must be at least 5 characters'),

            latitude: Yup.number()
                .typeError('You must specify a number')
                .required("This field is required"),

            longitude: Yup.number()
                .typeError('You must specify a number')
                .required("This field is required"),

            description: Yup.string()
                .required("This field is required")
                .min(5, 'Description must be at least 5 characters'),
        }),
        onSubmit: async values => {
            let fieldValues = values
            let locationMap = [fieldValues.latitude, fieldValues.longitude]
            Object.assign(fieldValues, { "locationmap": locationMap, user_id: params.id, shop_id: shop_id, });
            const payload = { ...userInfo, ...fieldValues }
            setLoading(true)
            await dispatch(updateIntegratorUser(payload))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status === '1') {
                            toast.success(response.data.message)
                            formik.resetForm();
                            history.push('/integrators')
                        } else {
                            toast.error(response.data?.error[0])
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                    }
                )
        },
    });

    const handleCitySelect = option => {
        const { value, label } = option || {}
        if (value || label) {
            setSelectedCity(option)
            formik.setFieldValue('city', label);
            getAreasData(value)
        } else {
            setSelectedCity(null)
            formik.setFieldValue('city', "");
            setAreas([])
            setSelectedArea(null)
            formik.setFieldValue('locationarea', "");
        }
    }

    const handleAreaSelect = option => {
        const { value, label } = option || {}
        if (value || label) {
            setSelectedArea(option)
            formik.setFieldValue('locationarea', label);
        } else {
            setSelectedArea(null)
            formik.setFieldValue('locationarea', "");
        }
    }

    const handlePrevious = () => {
        dispatch(setFormCurrentStep("USER_INFO"))
    }

    const handlePriceSelect = (options) => {
        setSelectedPrice(options)
        const optionsData = []
        options.forEach(item => {
            optionsData.push(item.value)
        })
        formik.setFieldValue('prices', optionsData);
    }

    return (
        <div className="card-body">
            <div className="tab-content" id="custom-tabs-one-tabContent">
                <div className="tab-pane fade active show " id="custom-shop-info-tab" role="tabpanel" aria-labelledby="shop-info-tab">
                    <form onSubmit={formik.handleSubmit}  >
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Integrator Name</label>
                                    <input className="form-control" placeholder='Shop'
                                        required
                                        name='shop'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.shop}
                                        type="text" />
                                    {formik.touched.shop && formik.errors.shop ? (<span className="text-danger">{formik.errors.shop}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Abbreviation</label>
                                    <input className="form-control" placeholder='Abbreviation'
                                        required={true}
                                        maxLength={4}
                                        name='abbreviation'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.abbreviation}
                                        type="text" />
                                    {formik.touched.abbreviation && formik.errors.abbreviation ? (<span className="text-danger">{formik.errors.abbreviation}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">City</label>
                                    <ReactSelect placeholder="Select City"
                                        isClearable={true}
                                        options={cityData}
                                        hideSelectedOptions={false}
                                        value={selectedCity}
                                        onBlur={formik.handleBlur}
                                        onChange={(option) => handleCitySelect(option)}
                                        styles={selectOptionStyle}
                                    />
                                    {formik.touched.city && formik.errors.city ? (<span className="text-danger">{formik.errors.city}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Location Area</label>
                                    <ReactSelect placeholder="Select Area"
                                        isClearable={true}
                                        options={areas}
                                        hideSelectedOptions={false}
                                        value={selectedArea}
                                        onBlur={formik.handleBlur}
                                        onChange={(option) => handleAreaSelect(option)}
                                    />
                                    {formik.touched.locationarea && formik.errors.locationarea ? (<span className="text-danger">{formik.errors.locationarea}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Full Address</label>
                                    <input className="form-control" placeholder='Full address'
                                        required
                                        name='locationaddress'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.locationaddress}
                                        type="text" />
                                    {formik.touched.locationaddress && formik.errors.locationaddress ? (<span className="text-danger ">{formik.errors.locationaddress}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className="control-label">Price</label>
                                    <ReactSelect
                                        isRequired={true}
                                        options={prices}
                                        isMulti={true}
                                        hideSelectedOptions={false}
                                        value={selectedPrice}
                                        onChange={handlePriceSelect}
                                        placeholder="Select Prices"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Map Location: Latitude</label>
                                    <input className="form-control" placeholder='31.988485'
                                        required
                                        name='latitude'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.latitude}
                                        type="number" />
                                    {formik.touched.latitude && formik.errors.latitude ? (<span className="text-danger">{formik.errors.latitude}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Map Location : Longitude</label>
                                    <input className="form-control" placeholder='36.951485'
                                        required
                                        name='longitude'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.longitude}
                                        type="number" />
                                    {formik.touched.longitude && formik.errors.longitude ? (<span className="text-danger">{formik.errors.longitude}</span>) : null}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Description</label>
                                    <textarea className="form-control" placeholder='Enter description'
                                        required
                                        name='description'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.description}
                                        rows="1">
                                    </textarea>
                                    {formik.touched.description && formik.errors.description ? (<span className="text-danger">{formik.errors.description}</span>) : null}
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="form-group required">
                                    <label className="control-label">Share location service type</label>
                                    <select className="form-control" name='share_location_service_type'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.share_location_service_type}
                                    >
                                        <option value='whatsapp'>WhatsApp</option>
                                        <option value='sms'>SMS</option>
                                        <option value='none'>None</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="pb-5 footer-step">
                            <button type='button' onClick={handlePrevious} className="btn btn-primary mr-2">Back</button>
                            <button type='submit' className="btn btn-success">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default IsLoadingHOC(IsLoggedinHOC(ShopInfoMation))