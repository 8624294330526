export const sidebarMenu = [
    {
        icon: "fa-regular fa-house fa-lg",
        name: "Dashboard",
        submenus: [
            { path: "/", name: "Summary" },
            { path: "/orders-charts", name: "Orders Chart" },
            { path: "/aggregator-orders-charts", name: "Aggregators Chart" },
            { path: "/averages-charts", name: "Average Chart" }
        ]
    },
    {
        icon: "fa-regular fa-user-chef fa-lg",
        name: "Admin",
        submenus: [
            { path: "/create-admin", name: "Create" },
            { path: "/admins", name: "Manage" },
        ]
    },
    {
        icon: "fa-regular fa-user-group fa-lg",
        name: "Users",
        submenus: [
            { path: "/aggregators", name: "Aggregators" },
            { path: "/buyers", name: "Buyers" },
            { path: "/drivers", name: "Drivers" },
            { path: "/logistics", name: "DSP" },
            { path: "/shops", name: "Shops" },
            { path: "/shop-vendors", name: "Shop Vendors" },
            { path: "/integrators", name: "Integrators" },
        ]
    },
    {
        icon: "fa-regular fa-user fa-lg",
        name: "Registration",
        submenus: [
            { path: "/shop-registered", name: "Shop" },
        ]
    },
    {
        icon: "fa-regular fa-bags-shopping fa-lg",
        name: "Orders",
        submenus: [
            { path: "/create-order", name: "Create" },
            { path: "/all-orders", name: "All" },
            { path: "/open-orders", name: "Open" },
            { path: "/pending-pickup", name: "Pending Pickup" },
            { path: "/arrived-orders", name: "Arrived" },
            { path: "/picked-orders", name: "Picked" },
            { path: "/on-hold-orders", name: "On Hold" },
            { path: "/completed-orders", name: "Completed" },
            { path: "/canceled-orders", name: "Canceled" }
        ]
    },
    {
        icon: "fa-regular fa-calendar-week fa-lg",
        name: "Schedule Order",
        submenus: [
            { path: "/create-schedule-order", name: "Create" },
            { path: "/scheduled-orders", name: "Manage" }
        ]
    },
    {
        icon: "fa-regular fa-truck fa-lg",
        name: "Delivery Zone",
        submenus: [
            { path: "/create-delivery-zone", name: "Create" },
            { path: "/delivery-zones", name: "Manage" }
        ]
    },
    {
        icon: "fa-regular fa-money-bills-simple fa-lg",
        name: "Price",
        submenus: [
            { path: "/create-price", name: "Create" },
            { path: "/prices", name: "Manage" }
        ]
    },
    {
        icon: "fa-regular fa-chart-area fa-lg",
        name: "Area",
        submenus: [
            { path: "/create-area", name: "Create" },
            { path: "/areas", name: "Manage" }
        ]
    },
    {
        icon: "fa-regular fa-house-building fa-lg",
        name: "City",
        submenus: [
            { path: "/create-city", name: "Create" },
            { path: "/cities", name: "Manage" }
        ]
    },
    {
        icon: "fa-regular fa-table-columns fa-lg",
        name: "Category",
        submenus: [
            { path: "/create-main-category", name: "Create" },
            { path: "/main-categories", name: "Manage" }
        ]
    },
    {
        icon: "fa-regular fa-table fa-lg",
        name: "Sub Category",
        submenus: [
            { path: "/create-sub-category", name: "Create" },
            { path: "/sub-categories", name: "Manage" }
        ]
    },
    {
        icon: "fa-regular fa-layer-group fa-lg",
        name: "Others",
        submenus: [
            { path: "/create-message", name: "Create Message" },
            { path: "/users-location", name: "Users location" }
        ]
    },
    {
        icon: "fa-regular fa-file-lines fa-lg",
        name: "Reports",
        submenus: [
            { path: "/invoices", name: "Invoices" },
            { path: "/wallet-history", name: "Wallet History" }
        ]
    },
];

