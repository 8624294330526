import React, { useState, useEffect } from 'react';
import { dateFormat, getFormatedDate, setStatusColor } from '../../Helper';
import { useDispatch } from "react-redux";
import { getOrderDetails } from '../../Redux/action/Orders';
import { toast } from 'react-toastify';
import { getDiffTime } from '../../Helper';

function OrderDetailsModel(props) {
    const { setLoading, isLoading, order_id, modal, closeModal } = props
    const dispatch = useDispatch();
    const [showTooltip, setShowTooltip] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);

    const handleMouseEnter = (item) => {
        setShowTooltip(true);
        setHoveredItem(item);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
        setHoveredItem(null);
    };

    const [orderDetail, setOrderDetail] = useState({
        ordernumber: '',
        integrator_number: '',
        shopname: '',
        drivername: '',
        driverphone: '',
        receiver_name: '',
        buyerphone: '',
        orderprice: '0',
        created_by: '',
        shop_address: '',
        receiver_address: '',
        sender_full_address: '',
        receiver_full_address: '',
        status: '',
        utrackfee: '0',
        deliveryfee: '0',
        taxfee: '0',
        _created_at: '',
        acceptdate: '',
        pickupdate: '',
        arrived_to_shop_date: '',
        deliverydate: '',
        distance: '0',
        paymentmethod: '',
        order_history: '',
        order_notes: ''

    })


    useEffect(() => {
        orderDetailData();
    }, [order_id])

    const orderDetailData = async () => {
        const payload = { order_id }
        setLoading(true)
        await dispatch(getOrderDetails(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const resData = (response.data && response.data.order) || {}
                        for (let key in resData) {
                            if (resData[key]) {
                                setOrderDetail(prevState => ({
                                    ...prevState,
                                    [key]: resData[key]
                                }));
                            }
                        }
                    } else {
                        toast.error('Data is not found')
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    const getNumberValue = (value) => {
        if (value == 0) {
            return value
        }
        else if (!value) {
            return 'NA'
        }
        else {
            return value
        }
    }

    const handleChange = (event) => {
        return;
    }

    const getShopFullAddress = (order) => {
        if (isLoading) {
            return 'Shop address';
        }
        else if (order.sender_full_address) {
            return order.sender_full_address
        }
        else if (order.shop_address) {
            return order.shop_address
        }
        else {
            return 'Not available'
        }
    }

    const getBuyerFullAddress = (order) => {
        if (isLoading) {
            return 'Buyer address';
        }
        else if (order.receiver_full_address) {
            return order.receiver_full_address
        }
        else if (order.receiver_address) {
            return order.receiver_address
        }
        else {
            return 'Not available'
        }
    }

    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog  modal-lg" role="document">
                <div className="modal-content">
                    <div className='d-flex gap-2 justify-content-between p-3'>
                        <h5 className="modal-title text-secondary" id="exampleModalLongTitle">{orderDetail.ordernumber}</h5>
                        {orderDetail.status !== "completed" && (
                            <h5 style={setStatusColor(orderDetail.status)} className="modal-title">{orderDetail.status}</h5>
                        )}
                        {orderDetail.status === "completed" && (
                            <div>
                                <h5 style={setStatusColor(orderDetail.status)} className="modal-title">
                                    {`${orderDetail.status} in(${getDiffTime(orderDetail.deliverydate, orderDetail._created_at)})`}
                                </h5>
                            </div>
                        )}
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="row p-2">
                        <div className="col-12">
                            <div className="card card-secondary card-tabs">
                                <div className="card-header p-0">
                                    <ul className="nav nav-tabs" id="myTabs" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Order Detail</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Log History</a>
                                        </li>
                                        {/* <li className="nav-item">
                                                <a className="nav-link" id="tab3-tab" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">Notes</a>
                                            </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body overflow-auto detail--modal--scroll">
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content mt-2">
                                    <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                        <form>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Shop Full Address</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Shop address'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={getShopFullAddress(orderDetail)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Buyer Full Address</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Buyer address'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={getBuyerFullAddress(orderDetail)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Shop Name</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Shop name'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={orderDetail.shopname} />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Shop Phone</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Buyer name'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={orderDetail.senderphone} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Driver Name</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Driver name'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={orderDetail.drivername} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Driver Phone</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Driver phone'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={orderDetail.driverphone} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Buyer Name</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Buyer name'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={orderDetail.receiver_name} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Buyer Phone</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Buyer Phone'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={orderDetail.buyerphone} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row' >
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Created By</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Created by'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={orderDetail.created_by} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Payment Method</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Payment method'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={orderDetail.paymentmethod} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Utrack Fee</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Utrack fee'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={getNumberValue(orderDetail.utrackfee)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Delivery Fee</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Delivery fee'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={getNumberValue(orderDetail.deliveryfee)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Tax fee</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Order price'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={getNumberValue(orderDetail.taxfee)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Order Price</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Order price'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={getNumberValue(orderDetail.orderprice)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Distance(km)</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Distance'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={getNumberValue(orderDetail.distance)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Created At</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Created at'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={dateFormat(orderDetail._created_at)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Accepted At</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Accepted at'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={dateFormat(orderDetail.acceptdate)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Arrived to shop At</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Arrived to shop at'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={dateFormat(orderDetail.arrived_to_shop_date)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Picked At</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Picked at'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={dateFormat(orderDetail.pickupdate)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Delivered At</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder='Delivered at'
                                                            disabled
                                                            onChange={(e) => handleChange(e)}
                                                            value={dateFormat(orderDetail.deliverydate)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                        {orderDetail.order_history && orderDetail.order_history?.length > 0 ?
                                            (
                                                <table className="table table-hover">
                                                    <thead className="thead-light ">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">User</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderDetail.order_history.map((item, index) => (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>
                                                                        <div>{item?.user || "Not available"}</div>
                                                                        <div><small className='text-secondary'>{item.role && `(${item.role})`}</small></div>
                                                                    </td>
                                                                    <td>
                                                                        <div style={setStatusColor(item.status)} className='text-capitalize'>
                                                                            <span>
                                                                                {item.status}
                                                                            </span>
                                                                        </div>
                                                                        {item.status === "pending pickup" && item?.driver?.name &&
                                                                            <div title='Driver Name'>
                                                                                <small className='text-secondary'>
                                                                                    {`(${item?.driver?.name})`}
                                                                                </small>
                                                                            </div>
                                                                        }
                                                                        {item.message &&
                                                                            <>
                                                                                <span
                                                                                    className="position-relative"
                                                                                    onMouseEnter={() => handleMouseEnter(item)}
                                                                                    onMouseLeave={handleMouseLeave}
                                                                                >
                                                                                    <i className="fa-regular text-success fa-circle-info mr-2"></i>
                                                                                    {showTooltip && hoveredItem === item && (
                                                                                        <span className="tooltip-text translate-middle-x">
                                                                                            <p>{item.message}</p>
                                                                                        </span>
                                                                                    )}
                                                                                </span>
                                                                                <small className='text-secondary'>
                                                                                    {`(${item?.receiver.type} : ${item?.receiver.name})`}
                                                                                </small>
                                                                            </>
                                                                        }
                                                                    </td>
                                                                    <td>{getFormatedDate(item.time)}</td>

                                                                </tr>

                                                            </React.Fragment>
                                                        ))}
                                                    </tbody>
                                                </table>

                                            )
                                            :
                                            <div className='not-found-data p-5'>
                                                <h2>Log history not available..</h2>
                                            </div>
                                        }
                                    </div>
                                    <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                                        {orderDetail.order_notes && orderDetail.order_notes.length > 0 ?
                                            (<table className="table">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Message</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orderDetail.order_notes.map((item, index) =>
                                                        <tr>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{item?.message || item.message_description || "Not available"}</td>
                                                            <td style={setStatusColor(item.status)} className='text-capitalize' >{item.status}</td>
                                                            <td>{getFormatedDate(item.date)}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            ) :
                                            <div className='not-found-data p-5'>
                                                <h2>No records available..</h2>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetailsModel

