import React, { useState, useEffect } from 'react';
import { uploadShopImgFile, removeFileImgAWS } from '../../Redux/action/Media';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { addShopOtherImage, setFormCurrentStep } from '../../Redux/action/Shop';
import { trimFileName } from '../../Helper';
import { toast } from 'react-toastify';




function ShopOtherImage(props) {
    const { setLoading } = props
    const dispatch = useDispatch()
    const [formValues, setFormValues] = useState([{ shopimage: "", }])
    const { shopOtherImages } = useSelector(state => state.shop)

    useEffect(() => {
        if (shopOtherImages && shopOtherImages.length > 0) {
            const uploadedOtherImages = []
            shopOtherImages.map(item => {
                uploadedOtherImages.push({ shopimage: item })
            })
            setFormValues(uploadedOtherImages)
        }
    }, [])


    const removeFileFromAWS = async (id) => {
        const payload = { type: "shop", file_name: id }
        await dispatch(removeFileImgAWS(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status === '1') {
                        toast.success(response.data.message)
                    }
                    else {
                        toast.error(response.data.error[0])
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                 }
            )
            .catch(
                error => console.log(error)
            )
    }

    let handleChange = (i, e) => {
        setLoading(true)
        let newFormValues = [...formValues];
        const uploadedImage = newFormValues[i]['shopimage']
        if (uploadedImage) {
            const endPoint = uploadedImage.split('/')
            removeFileFromAWS(endPoint[endPoint.length - 1])
        }
        const file = e.target.files[0]
        var formData = new FormData();
        formData.append(`type`, "shop");
        formData.append(`title`, "shop");
        formData.append('media', file);
        dispatch(uploadShopImgFile(formData))
            .then(
                response => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 2000)
                    if (response.status === '1') {
                        toast.success(response.data.message)
                        const file_url = response.data.file_url
                        let newFormValues = [...formValues];
                        newFormValues[i][e.target.name] = file_url;
                        setFormValues(newFormValues);
                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                error => {
                    const message = (error.response && error.response.statusText) || "something went wrong"
                    toast.error(message)
                    setLoading(false)
                }
            )
            .catch(error => {
                console.log(error)
                toast.error(error.message)
                setLoading(false)
            })
    }

    let addFormFields = () => {
        if (formValues && formValues.length < 5) {
            setFormValues([...formValues, { shopimage: "" }])
        }
    }

    let removeFormFields = () => {
        let newFormValues = [...formValues];
        const result = newFormValues.pop();
        if (result.shopimage) {
            var initial_url = result.shopimage
            const endPoint = initial_url.split('/');
            removeFileFromAWS(endPoint[endPoint.length - 1]);
        }
        setFormValues(newFormValues)
    }

    let handleSubmit = (event) => {
        event.preventDefault();
        let newFormValues = [...formValues];
        const uploadedOtherImages = []
        newFormValues.forEach(item => {
            if (item.shopimage) {
                uploadedOtherImages.push(item.shopimage)
            }
        })
        dispatch(addShopOtherImage(uploadedOtherImages))
        dispatch(setFormCurrentStep("MAQSAM"))
    }

    const handlePrevious = () => {
        let newFormValues = [...formValues];
        const uploadedOtherImages = []
        newFormValues.forEach(item => {
            if (item.shopimage) {
                uploadedOtherImages.push(item.shopimage)
            }
        })
        dispatch(addShopOtherImage(uploadedOtherImages))
        dispatch(setFormCurrentStep("SHOP_IMAGE"))
    }

    return (
        <div className="card-body">
            <div className="tab-content" id="custom-tabs-one-tabContent">
                <div className="tab-pane fade active show " id="custom-shop-images" role="tabpanel" aria-labelledby="shop-images-tab">
                    <form onSubmit={handleSubmit}>
                        {formValues.map((element, index) => (
                            <div className="row" key={index}>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Image {index + 1}</label>
                                        <div className="custom-file">
                                            <input
                                                name='shopimage'
                                                onChange={e => handleChange(index, e)}
                                                type="file" className="custom-file-input" id="exampleInputFile" />
                                            <label className="custom-file-label" htmlFor="exampleInputFile">{element.shopimage ? trimFileName(element.shopimage) : 'Choose file'}</label>
                                        </div>
                                        <img src={element.shopimage} className="file-upload-image pt-3" id="output" alt="" />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="pb-5">
                            <button type='button' id="click" onClick={() => addFormFields()} className="btn btn-primary mr-2">Add More Images</button>
                            {formValues && formValues.length > 1 ? <button type='button' style={{ marginLeft: "5px" }} onClick={() => removeFormFields()} className="btn btn-danger" id="remove-btn">Remove Images</button> : null}
                        </div>
                        <div className="pb-5 footer-step">
                            <button type='button' className="btn btn-primary mr-2" onClick={handlePrevious} >Back</button>
                            <button type='submit' className="btn btn-success">Next</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default IsLoadingHOC(IsLoggedinHOC(ShopOtherImage))