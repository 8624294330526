import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import SelectYear from "../Form/Select/SelectYear";
import { getPerMonthAggregatorOrdersCount } from '../../Redux/action/Orders';
import IsLoggedinHOC from '../IsLoggedinHOC';
import IsLoadingHOC from '../IsLoadingHOC';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


function AggregatorsBar({ setLoading }) {
  const dispatch = useDispatch()
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [series, setSeries] = useState([])


  const [options, setOptions] = useState({
    chart: {
      height: 400,
      type: 'bar',
      zoom: {
        enabled: true
      },
      toolbar: {
        show: false,
        tools: {
          download: false
        }
      },
      background: '#fcfcfc'
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    title: {
      text: `Per month Aggregator orders for ${selectedYear}`,
      align: 'left'
    },
    xaxis: {
      categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    },

    fill: {
      opacity: 1
    },
    // colors: ['#00bbf9'],
    // ##-- For change Formate value
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return "$ " + val + " thousands"
    //     }
    //   }
    // }
  })

  useEffect(() => {
    getPerMonthOrdersCount();
  }, [selectedYear])


  const getPerMonthOrdersCount = async () => {
    let payload = {
      year: selectedYear.toString(),
    };

    setLoading(true);
    await dispatch(getPerMonthAggregatorOrdersCount(payload))
      .then(response => {
        setLoading(false);
        if (response.status === "1") {
          const resData = response.data;
          let series = [];
          Object.keys(resData).forEach(itemName => {
            let orderData = {
              name: itemName,
              data: []
            };
            resData[itemName].forEach(item => {
              orderData.data.push(item.order_count);
            });

            series.push(orderData);
          });
          setSeries(series);
        }
        else{
          setSeries([]);
        }
      })
      .catch(error => {
        const errMsg = error.response?.data?.data?.message ||error.response?.data?.message  || "No records found"
        toast.error(errMsg)
        setSeries([]);
        setLoading(false);
        console.log(error);
      });
  };


  const handleChangeYear = (year) => {
    setSelectedYear(year)
    setOptions((prevState) => ({
      ...prevState,
      title: {
        ...prevState.text,
        text: `Per Month Aggregator orders for ${year}`
      }
    }));
  }

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div className="row justify-content-end">
            <div className="col-lg-3 col-3">
              <div className="form-group">
                <SelectYear selectedYear={selectedYear} handleChangeYear={handleChangeYear} />
              </div>
            </div>
          </div>
          <ReactApexChart options={options} series={series} type="bar" height={450} />
        </div>
      </div>
    </React.Fragment >
  );
}
export default IsLoadingHOC(IsLoggedinHOC(AggregatorsBar))