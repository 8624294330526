
let initialState = {
    buyer: "",
    relatedOrder: {},
    invoiceDriver: {},
    parentShopID: '',
    globalData: {},
    userGlobalData: {},
    cityData: [],
    latLng: { lat: 0, lng: 0 },
    address: "",
    selectedUsers: [],
    deliveryZoneTitle: '',
    notifyOrder: "",
    orderETA: "",
    removeOrder: "",
    dsp_drivers: [],
    aggregatorName: ""
}
export default function app(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_BUYER_DETAILS":
            return { ...state, buyer: action.payload }
        case "UPDATE_RELATED_ORDER_ID":
            return { ...state, relatedOrder: action.payload }
        case "SET_INVOICE_DRIVER":
            return { ...state, invoiceDriver: action.payload }
        case "SET_PARENT_SHOP_ID":
            return { ...state, parentShopID: action.payload }
        case "SAVE_GLOBAL_DATA":
            return { ...state, globalData: action.payload }
        case "SAVE_CITY_DATA":
            return { ...state, cityData: action.payload }
        case "SAVE_CURRENT_LAT_LNG":
            return { ...state, latLng: action.payload }
        case "SAVE_ADDRESS":
            return { ...state, address: action.payload }
        case "SAVE_SELECTED_USERS":
            return { ...state, selectedUsers: action.payload }
        case "SAVE_TITLE_NAME":
            return { ...state, deliveryZoneTitle: action.payload }
        case "SAVE_USER_GLOBAL_DATA":
            return { ...state, userGlobalData: action.payload }
        case "SET_NOTIFY_ORDER":
            return { ...state, notifyOrder: action.payload }
        case "REMOVE_OPEN_ORDER":
            return { ...state, removeOrder: action.payload }
        case "SET_ORDER_ETA":
            return { ...state, orderETA: action.payload }
        case "SET_DSP_DRIVERS":
            return { ...state, dsp_drivers: action.payload }
        case "SET_AGGREGATOR_NAME":
            return { ...state, aggregatorName: action.payload }
        default:
            return state
    }
}
