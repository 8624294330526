import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getdriverActivityLog } from '../../Redux/action/User';
import { addSpaceBeforeCapital, getOnlyTime, getTimestamp, setStatusColor } from '../../Helper';


function DriverActivityModal(props) {
    const { modal, closeModal, driverDetails, setLoading, isLoading } = props
    const { _id, username } = driverDetails
    const [listData, setListData] = useState([])
    const dispatch = useDispatch()


    useEffect(() => {
        getActivityLogList();
    }, [])

    console.log("listData", listData)

    const getActivityLogList = async () => {
        const payload = {
            search: {
                user_id: _id,
                from_date: "",
                to_date: ""
            }
        }
        setLoading(true)
        await dispatch(getdriverActivityLog(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == 1) {
                        const resData = response.data.logs
                        setListData(resData)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                })
            .catch(error => console.log(error))
    }


    return (
        <div id="myModal" className={`modal fade cs--modal ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-info">{username}'s Activity</h5>
                        <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div className="modal-body overflow-auto modal--body--scroll">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-12">
                                <div className="main-card card time--line--card">
                                    {listData?.length > 0 &&
                                        <div className="card-body">
                                            <h5 className="">Driver Timeline</h5>
                                            <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                                {listData.map((item, i) =>
                                                    <div key={i + 1} className="vertical-timeline-item vertical-timeline-element">
                                                        <div>
                                                            <span className="vertical-timeline-element-icon bounce-in">
                                                                <i className="badge badge-dot badge-dot-xl badge-secondary"> </i>
                                                            </span>
                                                            <div className="vertical-timeline-element-content bounce-in">
                                                                <div className='d-flex'>
                                                                    <h4 style={setStatusColor(item.action)} className="timeline-title">{addSpaceBeforeCapital(item.action)}</h4>
                                                                    <span className='timeline--stamp' href='#'>{getTimestamp(item._created_at)}</span>
                                                                </div>
                                                                <p style={{ fontSize: '14px' }} >{item?.summary}</p>
                                                                <span className="vertical-timeline-element-date">{getOnlyTime(item._created_at)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                </div>
                                {listData?.length < 1 && !isLoading &&
                                    <div className='d-flex justify-content-center align-items-center text-center pt-3 pb-3'>
                                        <h5>No records available..</h5>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DriverActivityModal
