import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { default as ReactSelect } from "react-select";
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { phoneRegExp, countryCode } from '../../Config/constants';
import { getAreas } from '../../Redux/action/Area';
import { getHashKey, selectOptionStyle } from '../../Helper';
import { getCities } from '../../Redux/action/City';
import { foodicsSignup } from '../../Redux/action/Auth';


function FoodicsSignup(props) { 
    const { setLoading } = props
    const dispatch = useDispatch()
    const history = useHistory()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [selectedCity, setSelectedCity] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null)
    const [areas, setAreas] = useState([])
    const [cities, setCities] = useState([])

    const formik = useFormik({
        initialValues: {
            username: '',
            shop: '',
            email: '',
            phone: '',
            password: '',
            confirm_password: '',
            description: '',
            latitude: '',
            longitude: '',
            city: "",
            locationarea: "",
            locationaddress: "",
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required("This fiels is required")
                .min(3, 'This field must be at least 3 characters'),

            shop: Yup.string()
                .required("This field is required")
                .min(3, 'This field must be at least 3 characters'),

            email: Yup.string()
                .required("This field is required"),

            phone: Yup.string()
                .matches(phoneRegExp, 'Phone number is not valid')
                .required("This field is required"),

            // latitude: Yup.number()
            //     .typeError('You must specify a number')
            //     .required("This field is required"),

            // longitude: Yup.number()
            //     .typeError('You must specify a number')
            //     .required("This field is required"),

            description: Yup.string()
                .required("This field is required")
                .min(5, 'Description must be at least 5 characters'),

            password: Yup.string()
                .required("This field is required")
                .min(5, 'Password must be at least 5 characters'),

            confirm_password: Yup.string()
                .required("This field is required")
                .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),

            city: Yup.string()
                .required("This field is required"),

            locationarea: Yup.string()
                .required("This field is required")
                .min(3, 'Locationarea must be at least 3 characters'),

            locationaddress: Yup.string()
                .required("This field is required")
                .min(5, 'Location address must be at least 5 characters'),

        }),
        onSubmit: async values => {
            let fieldValues = { ...values }
            let locationMap = [fieldValues.latitude, fieldValues.longitude]
            Object.assign(fieldValues, {
                "location": locationMap
            });
            let phoneNumber = fieldValues.phone.toString();
            if (phoneNumber[0] === "+") {
                phoneNumber = phoneNumber.substring(1)
            }
            let phoneWithCountryCode = `${countryCode}${phoneNumber}`;
            fieldValues.phone = phoneWithCountryCode;
            setLoading(true)
            await dispatch(foodicsSignup(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status === '1') {
                            toast.success(response.data.message)
                            formik.resetForm();
                            history.push('/login')
                        } else {
                            toast.error(response.data.error[0])
                        }
                    },
                    (error) => {
                        setLoading(false);
                        toast.error(error.response.data?.message)
                    }
                ).catch(error => console.log(error))
        },
    });

    useEffect(() => {
        getCitiesData()
    }, [])

    const getCitiesData = async () => {
        const payload = {
            search: { id: "", name: "" },
            shorting: { order_in: -1, order_by: "_created_at" },
        }
        let hash_key = getHashKey(payload);
        payload['hash'] = hash_key;
        setLoading(true)
        await dispatch(getCities(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const cityData = []
                        const resData = response.data.cities
                        resData.map(item =>
                            cityData.push({ value: item._id, label: item.name })
                        )
                        setCities(cityData)
                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    const getAreasData = async (cityid) => {
        const payload = {
            search: { name: "", city_id: cityid },
            shorting: { order_in: -1, order_by: "_created_at" },
        }
        let hash_key = getHashKey(payload);
        payload['hash'] = hash_key;
        setLoading(true)
        await dispatch(getAreas(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const areaDropList = []
                        const resData = response.data.areas || []
                        resData.forEach(item => {
                            areaDropList.push({ value: item._id, label: item.name })
                        })
                        setAreas(areaDropList)
                    } else {
                        toast.error('Location area not found selected by city')
                        setAreas([])
                        setSelectedArea(null)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data.message)
                }
            )
    }

    const handleCitySelect = option => {
        const { value, label } = option || {}
        if (value || label) {
            setSelectedCity(option)
            formik.setFieldValue('city', label);
            getAreasData(value)
        } else {
            formik.setFieldValue('city', "");
            setSelectedArea(null)
            setSelectedCity(null)
            setAreas([])
            formik.setFieldValue('locationarea', "");
        }
    }

    const handleAreaSelect = option => {
        const { value, label } = option || {}
        if (value || label) {
            setSelectedArea(option)
            formik.setFieldValue('locationarea', label);
        } else {
            setSelectedArea(null)
            formik.setFieldValue('locationarea', "");
        }
    }

    return (
        <div className="container-fluid register_box_container public-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 my-5">
                        <div className="register_box">
                            <div className="register_box_header">
                                {countryCode === '+965' ?
                                    <img style={{ maxWidth: '40%' }} src={require('../../assets/logo/kuwait_logo.png')} alt="login-logo" />
                                    :
                                    <img src={require('../../assets/dist/img/login-logo.png')} alt="login-logo" />
                                }
                            </div>
                            <div className="content_form_register">
                                <h3>Sign up</h3>
                                <div className="row">
                                    <div className="col-12">
                                        <form onSubmit={formik.handleSubmit} >
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="User Name*"
                                                            required
                                                            id='username'
                                                            name="username"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.username}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.username && formik.errors.username ? (<span className="text-danger">{formik.errors.username}</span>) : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Shop name*"
                                                            required
                                                            name="shop"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.shop}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.shop && formik.errors.shop ? (<span className="text-danger">{formik.errors.shop}</span>) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Email ID*"
                                                            required
                                                            name="email"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.email}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.email && formik.errors.email ? (<span className="text-danger">{formik.errors.email}</span>) : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group required ">
                                                        <div className="input-group">
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">{countryCode}</span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Phone*"
                                                                required
                                                                name="phone"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.phone}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        </div>
                                                        {formik.touched.phone && formik.errors.phone ? (<span className="text-danger">{formik.errors.phone}</span>) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Password*"
                                                            required
                                                            name="password"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.password}
                                                            onBlur={formik.handleBlur}
                                                            type={showPassword ? 'text' : 'password'}
                                                        />
                                                        <i style={{ top: "13px" }} className={showPassword ? "fas fa-eye" : " fas fa-eye-slash"} onClick={() => setShowPassword(!showPassword)} id="eye"></i>
                                                        {formik.touched.password && formik.errors.password ? (<span className="text-danger " >{formik.errors.password}</span>) : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Confirm password*"
                                                            required
                                                            name="confirm_password"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.confirm_password}
                                                            onBlur={formik.handleBlur}
                                                            type={showConfirmPassword ? 'text' : 'password'}
                                                        />
                                                        <i style={{ top: "13px" }} className={showConfirmPassword ? "fas fa-eye" : " fas fa-eye-slash"} id="eye" onClick={() => setShowConfirmPassword(!showConfirmPassword)} ></i>
                                                        {formik.touched.confirm_password && formik.errors.confirm_password ? (<span className="text-danger">{formik.errors.confirm_password}</span>) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <ReactSelect
                                                            placeholder="Select City*"
                                                            isClearable={true}
                                                            options={cities}
                                                            hideSelectedOptions={false}
                                                            value={selectedCity}
                                                            onBlur={formik.handleBlur}
                                                            onChange={(option) => handleCitySelect(option)}
                                                            styles={selectOptionStyle}
                                                        />
                                                        {formik.touched.city && formik.errors.city ? (<span className="text-danger">{formik.errors.city}</span>) : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <ReactSelect
                                                            placeholder="Select Area*"
                                                            isClearable={true}
                                                            options={areas}
                                                            hideSelectedOptions={false}
                                                            value={selectedArea}
                                                            onBlur={formik.handleBlur}
                                                            onChange={(option) => handleAreaSelect(option)}
                                                            styles={selectOptionStyle}
                                                        />
                                                        {formik.touched.locationarea && formik.errors.locationarea ? (<span className="text-danger">{formik.errors.locationarea}</span>) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            placeholder='Latitude'
                                                            name='latitude'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.latitude}
                                                            type="number"
                                                        />
                                                        {formik.touched.latitude && formik.errors.latitude ? (<span className="text-danger">{formik.errors.latitude}</span>) : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            placeholder='Longitude'
                                                            name='longitude'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.longitude}
                                                            type="number"
                                                        />
                                                        {formik.touched.longitude && formik.errors.longitude ? (<span className="text-danger">{formik.errors.longitude}</span>) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group required">
                                                        <input
                                                            className="form-control"
                                                            placeholder='Full address*'
                                                            required
                                                            name='locationaddress'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.locationaddress}
                                                            type="text" />
                                                        {formik.touched.locationaddress && formik.errors.locationaddress ? (<span className="text-danger ">{formik.errors.locationaddress}</span>) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-sm-12">
                                                    <div className="form-group required">
                                                        <input
                                                            className="form-control"
                                                            placeholder='Description*'
                                                            required
                                                            name='description'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.description}
                                                        >
                                                        </input>
                                                        {formik.touched.description && formik.errors.description ? (<span className="text-danger">{formik.errors.description}</span>) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group text-center pt-3">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default IsLoadingHOC(FoodicsSignup)




