import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../../Components/IsLoggedinHOC';
import { addUserInfoMation, setFormCurrentStep } from '../../../Redux/action/Shop';
import { checkExistUser } from '../../../Redux/action/User';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { countryCode, phoneRegExp } from '../../../Config/constants';


function UserInformation(props) {
    const { setLoading } = props
    const dispatch = useDispatch()
    const { userInfo } = useSelector(state => state.shop)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    useEffect(() => {
        if (userInfo && userInfo.username) {
            const fields = ["username", "email", "phone", "password", "confirm_password"];
            fields.forEach(field => formik.setFieldValue(field, userInfo[field], false));
        }
    }, [userInfo])

    const formik = useFormik({
        initialValues: {
            username: '',
            email: "",
            phone: "",
            password: '',
            confirm_password: ""
        },

        validationSchema: Yup.object({
            username: Yup.string()
                .required("This field is required")
                .min(3, 'User name must be at least 3 characters'),

            email: Yup.string()
                .required("This field is required"),

            phone: Yup.string()
                .required("This field is required")
                .matches(phoneRegExp, 'Phone number is not valid'),

            password: Yup.string()
                .required("This field is required")
                .min(5, "Password must be at least 5 characters "),

            confirm_password: Yup.string()
                .required("This field is required")
                .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
        }),
        onSubmit: async values => {
            let fieldValues = values
            checkExistUserDetail(fieldValues)
        },
    });

    // To Check User Exist details //  
    const checkUserNameExist = (username) => {
        const payload = { search: { username } }
        return dispatch(checkExistUser(payload))
    }
    const checkUserEmailExist = (email) => {
        const payload = { search: { email } }
        return dispatch(checkExistUser(payload))
    }

    const checkExistUserDetail = async (fieldValues) => {
        const { username, email } = fieldValues
        setLoading(true)
        const [nameResponse, emailResponse] = await Promise.all([checkUserNameExist(username), checkUserEmailExist(email)])
        setLoading(false)
        if (nameResponse.data.is_exist) {
            toast.error(nameResponse.data.message)
        }
        else if (emailResponse.data.is_exist) {
            toast.error(emailResponse.data.message)
        }
        else {
            dispatch(addUserInfoMation(fieldValues));
            dispatch(setFormCurrentStep("SHOP_INFO"))
        }
    }

    return (
        <div className="card-body">
            <div className="tab-content" id="custom-tabs-one-tabContent">
                <div className="tab-pane fade active show" id="custom-tabs-shop-info" role="tabpanel" aria-labelledby="custom-tabs-shop-info">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">User name</label>
                                    <input className={`form-control`} placeholder="Name"
                                        required
                                        type="text"
                                        name='username'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.username}
                                    />
                                    {formik.touched.username && formik.errors.username ? (<span className="text-danger">{formik.errors.username}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Email</label>
                                    <input className={`form-control`} placeholder="Email address"
                                        required
                                        type="email"
                                        name='email'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email} />
                                    {formik.touched.email && formik.errors.email ? (<span className="text-danger">{formik.errors.email}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required ">
                                    <label className="control-label">Phone</label>
                                    <div className="input-group">
                                        <div className="input-group-append">
                                            <span className="input-group-text">{countryCode}</span>
                                        </div>
                                        <input
                                            required
                                            name="phone"
                                            onChange={formik.handleChange}
                                            value={formik.values.phone}
                                            onBlur={formik.handleBlur}
                                            type="text" className="form-control" placeholder="Phone" />
                                    </div>
                                    {formik.touched.phone && formik.errors.phone ? (<span className="text-danger">{formik.errors.phone}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Password</label>
                                    <input className="form-control" placeholder="Password"
                                        required
                                        name='password'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type={showPassword ? 'text' : 'password'}
                                        value={formik.values.password} />
                                    <i className={showPassword ? "fas fa-eye" : " fas fa-eye-slash"} onClick={() => setShowPassword(!showPassword)} id="eye"></i>
                                    {formik.touched.password && formik.errors.password ? (<span className="text-danger">{formik.errors.password}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Confirm Password</label>
                                    <input className="form-control" placeholder="Confirm password"
                                        required
                                        name='confirm_password'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        value={formik.values.confirm_password} />
                                    <i className={showConfirmPassword ? "fas fa-eye" : " fas fa-eye-slash"} id="eye" onClick={() => setShowConfirmPassword(!showConfirmPassword)} ></i>
                                    {formik.touched.confirm_password && formik.errors.confirm_password ? (<span className="text-danger">{formik.errors.confirm_password}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <button type='submit' className="btn btn-info btn-size">Next</button>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default IsLoadingHOC(IsLoggedinHOC(UserInformation))