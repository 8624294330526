import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { assignClientApi } from '../../Redux/action/Shop';
import { getUserForSearchBar, getUsersList } from '../../Redux/action/User';
import { getUserDetails } from '../../Redux/action/User';
import { selectOptionStyle } from '../../Helper';
import { default as ReactSelect } from "react-select";
import { debounce } from 'lodash';


function AssignClientsModal(props) {
    const { setLoading, isLoading, modal, closeModal, user, getUserList } = props
    const { _id, nickname, username } = user
    const dispatch = useDispatch();
    const [fetchingUsers, setFetchingUsers] = useState(false)
    const [shopUsers, setShopUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([]);


    useEffect(() => {
        getUserAdminDetail();
    }, [])


    const getClientsUsersByIds = async (usersId) => {
        let str_ids = usersId?.join(',');
        const payload = {
            search: { _id: str_ids, type: "Shop,Integrator,ShopVendor", nickname: "", username: "" },
            shorting: { order_in: 1, order_by: "username" },
        }
        setLoading(true);
        await dispatch(getUsersList(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        const result = (response.data && response.data.users) || []
                        const all_users = []
                        result.forEach(item => {
                            let userName = item.nickname || item.username
                            all_users.push({ label: userName, value: item._id })
                        })
                        setShopUsers(all_users)
                        const results = all_users.filter(({ value: id1 }) => usersId.some((id2) => id2 === id1))
                        setSelectedUsers(results)
                    } else {
                        setShopUsers([])
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
    }

    const getUserAdminDetail = async () => {
        const payload = {
            user_id: _id
        }
        setLoading(true)
        await dispatch(getUserDetails(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        const resData = response.data.user
                        const { assigned_clients } = resData
                        getClientsUsersByIds(assigned_clients)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                })
            .catch(error => console.log(error))
    }

    const handleSubmit = async () => {
        const assignClients = selectedUsers?.map(item => item.value)
        const payLoad = { assigned_clients: assignClients, user_id: _id }
        setLoading(true)
        await dispatch(assignClientApi(payLoad))
            .then(
                response => {
                    setLoading(false)
                    if (response.status == '1') {
                        toast.success(response.data.message)
                        setSelectedUsers([])
                        closeModal()
                        getUserList()
                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
            .catch(
                error => console.log(error)
            )
    }

    // load options using API call with searched Drivers
    const getSearchedUsers = async (searchValue) => {
        let payload = {
            search: { id: '', type: "Shop,Integrator,ShopVendor", nickname: '', username: searchValue, phone: '' },
            shorting: { order_in: "asc", order_by: "nickname" }
        }
        setFetchingUsers(true)
        await dispatch(getUserForSearchBar(payload))
            .then(
                response => {
                    setFetchingUsers(false)
                    if (response.status == "1") {
                        const resData = response.data.users
                        const all_users = []
                        resData.forEach(item => {
                            const { _id, nickname, username } = item
                            const user_name = `${nickname ? nickname : username}`
                            all_users.push({ label: user_name, value: _id })
                        })
                        setShopUsers(all_users)
                    } else {
                        setShopUsers([])
                    }
                },
                error => {
                    setShopUsers([])
                    setFetchingUsers(false)
                    toast.error(error.response.data?.message)
                }
            )
    };

    const getDriversData = useCallback(debounce((searchvalue) => getSearchedUsers(searchvalue), 500), []);
    const handleSearchUser = (inputValue) => {
        const value = inputValue.trim()
        if (value && value.length > 0) {
            getDriversData(value);
        }
    }

    const handleSelectUser = (options) => {
        setSelectedUsers(options);
        // formik.setFieldValue('drivers', optionsData);
    }

    return (
        <div id="myModal" className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Assign Clients</h5>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form>
                        <div className="modal-body p-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Sub Admin</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={nickname || username}
                                            placeholder='Shop Name'
                                            disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Shop</label>
                                        <ReactSelect placeholder="Search user"
                                            isClearable={true}
                                            isLoading={fetchingUsers}
                                            isMulti={true}
                                            options={shopUsers}
                                            value={selectedUsers}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            onInputChange={handleSearchUser}
                                            onChange={handleSelectUser}
                                            styles={selectOptionStyle}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-savbtn">
                            <button type="button" onClick={handleSubmit} className="btn btn-success">{isLoading ? "Processing" : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default AssignClientsModal