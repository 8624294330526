import React, { useState, useEffect, useCallback } from 'react';
import { getScheduleOrderList, deleteScheduleOrder } from '../../Redux/action/Orders';
import ScheduledOrderDetail from '../../Components/DetailsModel/ScheduledOrderDetail';
import { isAllItemSelected, handleSelectListItem, showShopDirection } from '../../Helper';
import DeleteConfirmation from '../../Components/ConfirmModel/Delete';
import { getUserForSearchBar } from '../../Redux/action/User';
import { updateBuyerDetail } from '../../Redux/action/App';
import { getShopBranches } from '../../Redux/action/Shop';
import DatePicker from '../../Components/DatePicker';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import Pagination from '../../Components/Pagination';
import { useSelector } from 'react-redux';
import { trimObjValues, customStyles } from '../../Helper';
import { Link } from 'react-router-dom';
import { default as ReactSelect } from "react-select";
import MoveOrderModel from './MoveOrder';
import moment from 'moment';
import TableFooter from '../../Components/TableFooter';
import UploadFileModal from './UploadFileModal';
import { debounce } from 'lodash';
import { SidebarContext } from '../../Context/SidebarContext';
import { useContext } from 'react';
import NotFoundData from '../../Components/NotFoundData';


function ScheduledOrders(props) {
   const { isLoading, setLoading } = props
   const { user } = useSelector(state => state.auth)
   const { _id } = user ? user : {}
   const [moveOrderModal, setMoveOrderModal] = useState(false)
   const [loader, setLoader] = useState(false)
   const [scheduleOrders, setScheduleOrders] = useState([])
   const [detailModal, setDetailModal] = useState(false)
   const [uploadFileModal, setUploadFileModal] = useState(false)
   const [selectedValue, setSelectedValue] = useState({ shopid: null, drivername: null });
   const [sorting, setSorting] = useState({ order_in: 1, order_by: "schedule_time" })
   const [shopSearch, setShopSearch] = useState({ nickname: '' })
   const [shopFetching, setShopFetching] = useState(false)
   const [shops, setShops] = useState([])
   const [shopBranches, setShopBranches] = useState([])
   const {
      dispatch,
      totalCount,
      toast,
      setTotalCount,
      currentPage,
      resetForm,
      handleSubmit,
      setcurrentPage,
      itemsPerPage,
      selectedDate,
      setSelectedDate,
      selectedItem,
      setSelectedItem,
      handleSelectItem,
      orderId,
      deleteModal,
      setDeleteModal,
      setOrderId,
      maxPageNumberLimit,
      setmaxPageNumberLimit,
      minPageNumberLimit,
      setminPageNumberLimit,
   } = useContext(SidebarContext)

   const [searchParams, setSearchParams] = useState({
      shopid: user.type === "Admin" ? "" : user._id,
      receiver_full_address: "",
      buyerphone: "",
      from_date: "",
      to_date: ""
   })

   useEffect(() => {
      getScheduledOrdersData();
   }, [currentPage, sorting])

   useEffect(() => {
      dispatch(updateBuyerDetail({ buyer_id: '' }))
   }, [])

   useEffect(() => {
      if (user.type === "Shop") {
         getShopBranchesData()
      }
   }, [])

   const getShopBranchesData = async () => {
      const payload = {
         search: { user_id: _id ? _id : '' },
         shorting: { order_in: -1, order_by: "_created_at" }
      }
      setLoading(true)
      await dispatch(getShopBranches(payload))
         .then(
            response => {
               setLoading(false);
               if (response.status === "1") {
                  const result = (response.data && response.data.branches) || []
                  const branches = []
                  result.map(item => branches.push({ value: item.branch_shop_id, label: item.nickname }))
                  setShopBranches(branches)
               } else {
                  setShopBranches([])
               }
            },
            error => {
               setLoading(false)
               toast.error(error.response.data?.message)
            }
         )
   }

   const handleSelectShop = (option, name) => {
      let { value } = option ? option : {}
      if (value) {
         setSearchParams((prevState) => ({
            ...prevState,
            [name]: value,
         }));
      }
      else {
         if (user.type === "Admin") {
            setSearchParams((prevState) => ({
               ...prevState,
               [name]: "",
            }));
         }
         else {
            setSearchParams((prevState) => ({
               ...prevState,
               [name]: _id,
            }));
         }
      }

      setSelectedValue((prevState) => ({
         ...prevState,
         [name]: option,
      }));
      if (!value) {
         setShops([])
      }
   }

   const handleSearch = (e) => {
      const { name, value } = e.target
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value,
      }));
   }

   const handleChangeDate = (date, name) => {
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: date ? moment(date).format("YYYY-MM-DD") : '',
      }));
      setSelectedDate((prevState) => ({
         ...prevState,
         [name]: date,
      }));
   }

   // loaded options using API call with searched Shops
   const getSearchedShops = async (searchvalue) => {
      const payload = {
         search: { type: "Shop", nickname: searchvalue, username: "" },
         shorting: { order_in: "asc", order_by: "nickname" }
      }
      setShopFetching(true)
      await dispatch(getUserForSearchBar(payload))
         .then(
            response => {
               setShopFetching(false)
               if (response.status == "1") {
                  const resData = response.data.users
                  const allShops = []
                  resData.forEach(item => {
                     const { _id, nickname } = item
                     allShops.push({ label: nickname, value: _id })
                  })
                  setShops(allShops)
               } else {
                  setShops([])
               }
            },
            error => {
               setShops([])
               setShopFetching(false)
               toast.error(error.response.data?.message)
            }
         )
   };

   const getShopsData = useCallback(debounce((searchvalue) => getSearchedShops(searchvalue), 500), []);
   const handleSearchShop = (inputValue) => {
      const value = inputValue.trim()
      if (value && value.length > 0) {
         getShopsData(value);
      }
   }

   const getScheduledOrdersData = async () => {
      const trimmedSearchParams = trimObjValues(searchParams)
      const trimmedshopSearch = trimObjValues(shopSearch)
      // if (user.type === "Shop") {
      //    const obj = { shopid: _id ? _id : "" }
      //    Object.assign(trimmedSearchParams, obj)
      // }
      const payload = {
         search: { ...trimmedSearchParams },
         shop_search: { ...trimmedshopSearch },
         shorting: sorting,
         pagination: { start: currentPage, length: itemsPerPage }
      }
      setLoading(true)
      await dispatch(getScheduleOrderList(payload))
         .then(
            response => {
               setLoading(false);
               if (response.status === "1") {
                  setScheduleOrders(response.data.orders)
                  setTotalCount(response.data.total_count)
               }
               else {
                  toast.error(response.data.error[0])
                  setScheduleOrders([])
                  setTotalCount(null)
               }
            },
            error => {
               setLoading(false)
               toast.error(error.response.data?.message)
            }
         )
   }

   const handleReset = async () => {
      const resetFormData = {
         shopid: user.type === "Admin" ? "" : user._id,
         buyerphone: "",
         receiver_full_address: "",
         from_date: "",
         to_date: ""
      }
      setSearchParams(resetFormData)
      resetForm();
      setShopSearch({ nickname: '' })
      setShops([])
      setSelectedValue({ shopid: null, drivername: null })
   }

   const handleDelete = async () => {
      let payload = { orderId }
      if (selectedItem && selectedItem.length > 0) {
         Object.assign(payload, { orders: selectedItem })
      }
      setLoader(true)
      await dispatch(deleteScheduleOrder(payload))
         .then(
            response => {
               setLoader(false)
               if (response.status == '1') {
                  toast.success(response.data.message)
                  setOrderId("")
                  setSelectedItem([])
                  getScheduledOrdersData()
                  setDeleteModal(false)
               }
            },
            error => {
               setLoader(false)
               toast.error(error.response.data.message)
            }
         )
   }

   const handleSorting = (orderby) => {
      setSorting((prevState) => ({
         ...prevState,
         order_by: orderby,
         order_in: prevState.order_in === -1 ? 1 : -1
      }));
   }

   return (
      <>
         <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
               <div className="container-fluid">
                  <div className="row mb-2">
                     <div className="col-sm-6">
                        <h1 className="m-0">Scheduled Orders</h1>
                     </div>
                     <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item active">Scheduled Orders</li>
                        </ol>
                     </div>
                  </div>
               </div>
            </div>
            <section className="content">
               <div className="container-fluid">
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="all_drivers">
                           <div className="card card-dark">
                              <div className="card-header">
                                 <div className="container-fluid">
                                    <div className="row">
                                       <div className="col-6 col-lg-11">
                                          <h3 className="pl-5">Search</h3>
                                       </div>
                                       <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                          <i className="fas fa-angle-up click_search"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <form className="form_search" action="">
                                 <div className="card-body">
                                    {user.type === "Admin" &&
                                       <div className="form-group">
                                          <label>Shop</label>
                                          <ReactSelect placeholder="Search the shop"
                                             isLoading={shopFetching}
                                             styles={customStyles}
                                             isClearable={true}
                                             value={selectedValue.shopid}
                                             options={shops}
                                             onInputChange={handleSearchShop}
                                             onChange={(option) => handleSelectShop(option, "shopid")}
                                          />
                                       </div>
                                    }
                                    {user.type === "Shop" && shopBranches && shopBranches.length > 0 &&
                                       <div className="form-group">
                                          <label>Branches</label>
                                          <ReactSelect placeholder="Select branch"
                                             styles={customStyles}
                                             isClearable={true}
                                             value={selectedValue.shopid}
                                             options={shopBranches}
                                             onChange={(option) => handleSelectShop(option, "shopid")}
                                          />
                                       </div>}
                                    <div className="form-group">
                                       <label>Buyer Phone</label>
                                       <input type="number"
                                          className="form-control"
                                          onChange={handleSearch}
                                          value={searchParams.buyerphone}
                                          name="buyerphone" placeholder="Buyer phone" />
                                    </div>
                                    <div className="form-group">
                                       <label>Address</label>
                                       <input type="text"
                                          className="form-control"
                                          onChange={handleSearch}
                                          value={searchParams.receiver_full_address}
                                          name="receiver_full_address" placeholder="Receiver Address" />
                                    </div>
                                    <DatePicker selectedDate={selectedDate} handleChangeDate={handleChangeDate} maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))} />
                                 </div>
                                 <div className="card-footer text-center">
                                    <button type="reset" className="btn btn-primary mr-2" onClick={handleReset} >Reset</button>
                                    <button type="button" onClick={() => handleSubmit(getScheduledOrdersData)} className="btn btn-success">Submit</button>
                                 </div>
                              </form>
                           </div>
                           <div className="row pagination_new">
                              <div className="col-lg-6 col-12">
                                 <div className="btn-group" role="group">
                                    {['Admin', 'Shop'].includes(user?.type) &&
                                       <Link to="/create-schedule-order">
                                          <button disabled={isLoading} type="create" className="btn btn-primary"><i className="fa-regular fa-circle-plus"></i>Create</button>
                                       </Link>
                                    }
                                    <button disabled={isLoading} type="button" onClick={() => handleSubmit(getScheduledOrdersData)} className="btn btn-secondary ml-2 mr-2">
                                       <i className={`fa-regular fa-rotate-right ${isLoading && 'fa_spinner'}`}></i>Refresh
                                    </button>
                                    {['Admin', 'Shop'].includes(user?.type) && selectedItem.length > 0 && <>
                                       <button type="button" className="btn btn-outline-info">Action</button>
                                       <button type="button" className="btn btn-outline-info dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                          <span className="caret"></span>
                                          <span className="sr-only">Toggle Dropdown</span>
                                       </button>
                                       <div className="dropdown-menu">
                                          <span style={{ cursor: 'pointer' }} className="dropdown-item text-info" onClick={() => setMoveOrderModal(true)}><i className="fa-regular fa-bags-shopping mr-2"></i>Move to open</span>
                                          <div className="dropdown-divider"></div>
                                          <span style={{ cursor: 'pointer' }} className="dropdown-item text-danger" onClick={() => setDeleteModal(true)}><span className="red_1">
                                             <i className="fa fa-trash mr-2" aria-hidden="true"></i>Delete</span></span>
                                       </div>
                                    </>}
                                 </div>
                              </div>
                              {['Admin', 'Shop'].includes(user?.type) &&
                                 <div className="col-lg-6 col-12 text-lg-right text-center">
                                    <a href={`${process.env.REACT_APP_BASEURL}/import_bulk_orders.xlsx`} download ><button disabled={isLoading} type="button" className="btn btn-success  ml-2"><i className="fa-regular fa-circle-down"></i>Download Template</button></a>
                                    <button disabled={isLoading} type="button" onClick={() => setUploadFileModal(true)} className="btn btn-info  ml-2"><i className="fa-regular fa-circle-up"></i>Import</button>
                                 </div>
                              }
                           </div>
                           {scheduleOrders?.length > 0 &&
                              <React.Fragment>
                                 <div className="card table_card completed_1">
                                    <div className="card-body all_drive_table">
                                       <table id="example2" className="table table-hover">
                                          <thead>
                                             <tr>
                                                <th><input type="checkbox" id="check" checked={isAllItemSelected(scheduleOrders, selectedItem)} onChange={(event) => handleSelectListItem(event, scheduleOrders, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th>
                                                <th>More info</th>
                                                <th>Order</th>
                                                <th>Shop</th>
                                                <th>Buyer</th>
                                                <th>Price</th>
                                                <th>Type</th>
                                                <th>Schedule Time
                                                   {sorting.order_by === "schedule_time" ?
                                                      <i onClick={() => handleSorting("schedule_time")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("schedule_time")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Created At
                                                   {sorting.order_by === "_created_at" ?
                                                      <i onClick={() => handleSorting("_created_at")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                {['Admin', 'Shop'].includes(user?.type) && <th>Action</th>}
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {scheduleOrders.map((item) => (
                                                <tr key={item._id} className="accordion-toggle" style={{ backgroundColor: item?.created_by === "bulk_order" && 'rgb(233 196 106 / 76%)' }} >
                                                   <td> <input type="checkbox" className="table_checkbox"
                                                      onChange={(event) => handleSelectItem(event, item._id)}
                                                      checked={selectedItem.includes(item._id)} />
                                                   </td>
                                                   <td>
                                                      <div className="view-details-btn">
                                                         <span title='Show details'
                                                            onClick={() => {
                                                               setOrderId(item._id)
                                                               setDetailModal(true)
                                                            }} ><i className="fa-solid fa-circle-info fa-lg"></i></span>
                                                      </div>
                                                   </td>
                                                   <td>{item?.shop_ordernumber || item.ordernumber || "Not Available"}</td>
                                                   <td>
                                                      <div>
                                                         <span title={item.shopname}><i className="fa-solid fa-store"></i>{item.shopname}</span>
                                                      </div>
                                                      <div>
                                                         <span className='text-capitalize'>
                                                            <i className="fa-solid fa-diamond-turn-right"></i>
                                                            {showShopDirection(item)}</span>
                                                      </div>
                                                   </td>
                                                   <td>
                                                      <div>
                                                         <span title={item.receiver_name}><i className="fa fa-user" aria-hidden="true"></i>{item.receiver_name}</span>
                                                      </div>
                                                      <div>
                                                         <i className="fa fa-phone" aria-hidden="true"></i>{item.buyerphone ? item.buyerphone : "Not Available"}
                                                      </div>
                                                      <div><i className="fa-regular fa-location-dot"></i> {(item.favlocation && item.favlocation[0]?.locationarea) ? item.favlocation[0]?.locationarea : (item.area && item.area[0]?.name) ? item.area[0]?.name : "Not Available"}</div>
                                                   </td>
                                                   <td>
                                                      <div className='text-capitalize'>
                                                         <span role="button" title='Order-Price'><i className="fa-solid fa-coins"></i>{item?.orderprice} </span>
                                                      </div>
                                                      <div>
                                                         <span role="button" title='Payment-Method'><i className="fa-duotone fa-credit-card"></i>{item?.paymentmethod}</span>
                                                      </div>
                                                   </td>
                                                   <td>{item?.shop[0] && item.shop[0].type ? item.shop[0].type : "Not Available"}</td>
                                                   <td>{item.schedule_time}</td>
                                                   <td>{item._created_at}</td>
                                                   {['Admin', 'Shop'].includes(user?.type) &&
                                                      <td>
                                                         <div className="action_button">
                                                            <div className="btn-group">
                                                               <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                  <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                               </button>
                                                               <div className="dropdown-menu">
                                                                  <span className="dropdown-item"
                                                                     onClick={() => {
                                                                        setOrderId(item._id)
                                                                        setMoveOrderModal(true)
                                                                     }}>
                                                                     <i className="fa-regular fa-bags-shopping"></i>Move to open</span>
                                                                  <div className="dropdown-divider"></div>
                                                                  <Link to={`/edit-schedule-order/${item._id}`}
                                                                     className="dropdown-item">
                                                                     <span className="blue_1">
                                                                        <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span>
                                                                  </Link>
                                                                  <div className="dropdown-divider"></div>
                                                                  <span className="dropdown-item"
                                                                     onClick={() => {
                                                                        setOrderId(item._id)
                                                                        setDeleteModal(true)
                                                                     }}>
                                                                     <span className="red_1">
                                                                        <i className="fa fa-trash" aria-hidden="true"></i>Delete</span></span>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </td>
                                                   }
                                                </tr>
                                             )
                                             )}
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                                 <div className="row pagination_new">
                                    <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={scheduleOrders} totalCount={totalCount} />
                                    <div className="col-lg-4">
                                       <Pagination
                                          totalCount={totalCount}
                                          currentPage={currentPage}
                                          itemsPerPage={itemsPerPage}
                                          maxPageNumberLimit={maxPageNumberLimit}
                                          minPageNumberLimit={minPageNumberLimit}
                                          setcurrentPage={setcurrentPage}
                                          setmaxPageNumberLimit={setmaxPageNumberLimit}
                                          setminPageNumberLimit={setminPageNumberLimit}
                                       />
                                    </div>
                                 </div>
                              </React.Fragment>
                           }
                           {scheduleOrders?.length === 0 && !isLoading && <NotFoundData />}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>

         {deleteModal && <DeleteConfirmation
            loader={loader}
            modal={deleteModal}
            title="Do you want to remove scheduled order?"
            closeModal={() => setDeleteModal()}
            handleDelete={handleDelete} />}

         {moveOrderModal && <MoveOrderModel
            orderId={orderId}
            modal={moveOrderModal}
            closeModal={() => setMoveOrderModal(false)}
            getListingData={getScheduledOrdersData} />}

         {detailModal && <ScheduledOrderDetail
            order_id={orderId}
            modal={detailModal}
            setLoading={setLoading}
            isLoading={isLoading}
            closeModal={() => setDetailModal(false)} />
         }
         {uploadFileModal && <UploadFileModal
            modal={uploadFileModal}
            closeModal={() => setUploadFileModal(false)}
         />
         }
      </>
   )
}
export default IsLoadingHOC(IsLoggedinHOC(ScheduledOrders))