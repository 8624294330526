import React, { useState, useEffect } from 'react';
import { getFormatedDate } from '../../Helper';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { removeFileImgAWS, uploadFileApi } from '../../Redux/action/Media';
import { acceptedOrderDocFileType } from '../../Config/constants';
import { getOrderDetails, uploadOrderDocumentsApi } from '../../Redux/action/Orders';


function OrderDocuments(props) {
    const dispatch = useDispatch()
    const { modal, closeModal, isLoading, setLoading, orderDetails } = props
    const [orderDocuments, setOrderDocuments] = useState([])


    useEffect(() => {
        getOrderDetailData();
    }, [orderDetails])

    const removeFileFromAWS = async (fileEndPoint) => {
        const payload = { type: "order", file_name: fileEndPoint }
        await dispatch(removeFileImgAWS(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status == '1') {
                        toast.success(response?.data?.message)
                    } else {
                        toast.error(response.data.error[0])
                    }
                }).catch(error => setLoading(false))
    }

    const getOrderDetailData = async () => {
        const payload = { order_id: orderDetails._id }
        setLoading(true)
        await dispatch(getOrderDetails(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        const resData = (response.data && response?.data?.order) || {}
                        setOrderDocuments(resData?.order_documents)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    const updateOrderDocument = async (mediaFile = "") => {
        const payload = {
            order_id: orderDetails._id,
            title: 'signed_contract',
            media: mediaFile
        }
        setLoading(true)
        await dispatch(uploadOrderDocumentsApi(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        getOrderDetailData();
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    const uploadDocFile = async (event) => {
        setLoading(true)
        const doc_file = event.target.files[0]
        var formData = new FormData();
        formData.append(`type`, 'order');
        formData.append(`title`, orderDetails.ordernumber);
        formData.append('media', doc_file);
        dispatch(uploadFileApi(formData))
            .then(
                response => {
                    setLoading(false)
                    if (response.status == '1') {
                        // toast.success('Document uploaded successfully')
                        const resData = response.data
                        updateOrderDocument(resData?.file_url);
                    } else {
                        toast.error(response.data?.error[0])
                    }
                },
                error => {
                    const message = (error.response && error.response.statusText) || "something went wrong"
                    toast.error(message)
                    setLoading(false)
                }
            )
            .catch(error => {
                console.log(error)
                toast.error(error.message)
                setLoading(false)
            }
            )
    }

    const handleRemoveDoc = async (mediaFile = "") => {
        const splitArr = mediaFile?.split('/')
        await updateOrderDocument();
        await removeFileFromAWS(splitArr[splitArr.length - 1])
    }

    const handleUpdateDoc = async (event, mediaFile = "") => {
        const splitArr = mediaFile?.split('/')
        await uploadDocFile(event)
        await removeFileFromAWS(splitArr[splitArr.length - 1])
    }

    return (
        <div id="myModal" className={`modal fade cs--modal ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary">Order Document {`(${orderDetails.ordernumber})`} </h5>
                        <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div className="modal-body overflow-auto modal--body--scroll">
                        {orderDocuments?.length === 0 &&
                            <div className='d-flex justify-content-end mb-2'>
                                <span className="btn btn-primary btn-sm btn-file py-2 px-2">
                                    <i className="fa-regular fa-circle-up mr-1"></i>{isLoading ? 'Processing' : 'Upload'}<input disabled={isLoading} accept={acceptedOrderDocFileType} onChange={(event) => uploadDocFile(event)} type="file" />
                                </span>
                            </div>
                        }

                        <div className="card-model table-responsive mt-3">
                            {orderDocuments?.length > 0 && (
                                <table className="table table-striped table-valign-middle">
                                    <thead>
                                        <tr>
                                            <th>SN.</th>
                                            <th>Document title</th>
                                            <th>Uploaded At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderDocuments?.map((item, index) => (
                                            <tr key={index + 1}>
                                                <td>{index + 1.}</td>
                                                <td className='text-capitalize'>{item.title}</td>
                                                <td>{item?.date ? getFormatedDate(item.date) : "Not available"}</td>
                                                <td>
                                                    <span type="button" className="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                    </span>
                                                    <div className="dropdown-menu">
                                                        <span role="button" className="dropdown-item text-sm text-success cursor-pointer">
                                                            <a href={item?.media}>
                                                                <i className="fa-regular fa-circle-down mr-1"></i>Download
                                                            </a>
                                                        </span>
                                                        <div className="dropdown-divider"></div>
                                                        <span className="dropdown-item text-sm cursor-pointer btn btn-sm text-blue btn-file">
                                                            {/* <span className='btn btn-sm text-blue btn-file'> */}
                                                            <i className="fa-regular fa-circle-up mr-1"></i>Update<input accept={acceptedOrderDocFileType} onChange={(event) => handleUpdateDoc(event, item.media)} type="file" />
                                                            {/* </span> */}
                                                        </span>
                                                        <div className="dropdown-divider"></div>
                                                        <span
                                                            role="button"
                                                            className="dropdown-item text-danger text-sm cursor-pointer"
                                                            onClick={() => handleRemoveDoc(item.media)}
                                                        >
                                                            <i className="fa-regular fa-trash-can-xmark mr-2"></i><span>Remove</span>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>)
                                        )}
                                    </tbody>
                                </table>
                            )}
                            {(orderDocuments?.length === 0 && !isLoading) &&
                                <div className='d-flex justify-content-center text-center p-4'>
                                    <h5 className=''>Documents not available...</h5>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => closeModal()} className="btn btn-secondary py-1.5 px-4" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDocuments

