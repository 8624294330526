import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createBuyer } from '../../Redux/action/User';
import { updateBuyerDetail } from '../../Redux/action/App';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { phoneRegExp, countryCode } from '../../Config/constants';


function CreateBuyer(props) {
  const dispatch = useDispatch()
  const { buyer_phone, buyer_name, modal, closeModal, updateBuyer, isLoading, setLoading } = props

  useEffect(() => {
    formik.setFieldValue('buyer_name', buyer_name)
    formik.setFieldValue('buyer_phone', buyer_phone)
  }, [buyer_name, buyer_phone])


  const formik = useFormik({
    initialValues: {
      buyer_phone: '',
      buyer_name: '',
      buyer_email: '',
      buyer_password: '',
    },

    validationSchema: Yup.object({
      buyer_name: Yup.string()
        .required("This fiels is required")
        .min(3, 'This field must be at least 3 characters'),

      buyer_phone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required("This field is required"),
    }),
    onSubmit: async values => {
      let fieldValues = { ...values }
      let phoneNumber = fieldValues.buyer_phone?.toString();
      fieldValues.buyer_phone = countryCode + phoneNumber;
      setLoading(true)
      await dispatch(createBuyer(fieldValues))
        .then(
          response => {
            setLoading(false)
            if (response.status === '1') {
              toast.success(response.data.message)
              closeModal();
              const _id = response.data.buyer_id
              dispatch(updateBuyerDetail({ buyer_id: _id }))
              updateBuyer(formik.values.buyer_name, formik.values.buyer_phone)
              formik.resetForm();
            } else {
              toast.error(response.data.error[0])
            }
          }
        ).catch(err => setLoading(false))
    },
  });

  return (
    <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Create a new buyer</h5>
            <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className=''>
            <form onSubmit={formik.handleSubmit} >
              <div className="modal-body">
                <div className='row'>
                  <div className="col-sm-12">
                    <div className="form-group required">
                      <label className="control-label">Buyer Phone</label>
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text">{countryCode}</span>
                        </div>
                        <input type="text" className="form-control"
                          placeholder="Phone"
                          name="buyer_phone"
                          required
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.buyer_phone}
                        />
                      </div>
                      {formik.touched.buyer_phone && formik.errors.buyer_phone ? (<span className="text-danger">{formik.errors.buyer_phone}</span>) : ""}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group required">
                      <label className="control-label">Name</label>
                      <input
                        required
                        name='buyer_name'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.buyer_name}
                        placeholder="Enter name"
                        type="text" className="form-control" />
                      {formik.touched.buyer_name && formik.errors.buyer_name ? (<span className="text-danger">{formik.errors.buyer_name}</span>) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="control-label">Email<small></small></label>
                      <input
                        name='buyer_email'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.buyer_email}
                        placeholder="Enter email"
                        type="email" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="control-label">Password<small></small></label>
                      <input
                        name='buyer_password'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.buyer_password}
                        placeholder="Enter password"
                        type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex">
                <button type="button" onClick={closeModal} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button disabled={isLoading} type="submit" className="btn btn-success">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateBuyer;