import moment from 'moment';
let currentDate = new Date();

// Current Date
currentDate = moment(currentDate).format('YYYY-MM-DD');

// Yesterday's date
const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');

// Date 5 days ago
const fiveDaysAgo = moment().subtract(5, 'days').format('YYYY-MM-DD');

// Date 10 days ago
const tenDaysAgo = moment().subtract(10, 'days').format('YYYY-MM-DD');

// Date 15 days ago
const fifteenDaysAgo = moment().subtract(15, 'days').format('YYYY-MM-DD');

// Date 30 days ago
const thirtyDaysAgo = moment().subtract(30, 'days').format('YYYY-MM-DD');


export {
    currentDate,
    yesterday,
    fiveDaysAgo,
    tenDaysAgo,
    fifteenDaysAgo,
    thirtyDaysAgo
};
