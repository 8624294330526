import React from 'react';

function ExportModel(props) {
    const {
        loader,
        exportModal,
        toggleExportModal,
        getExportingData,
    } = props

    return (
        <div id="myModal" className={`modal fade cs--modal ${exportModal ? "show" : "hide"}`} style={{ display: exportModal ? "block" : "none" }}>
            <div className="modal-dialog modal-confirm">
                <div className="modal-content">
                    <div className="modal-header flex-column">
                        <h4 className="modal-title w-100 text-center">Export confirmation</h4>
                        <button disabled={loader} onClick={toggleExportModal} type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div className="modal-body">
                        <h5 className='text-secondary' style={{ fontSize: '21px' }} >Do you want to download records ?</h5>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" disabled={loader} onClick={toggleExportModal} className="btn btn-secondary" data-dismiss="modal">NO</button>
                        <button type="button" disabled={loader} onClick={() => getExportingData()} className="btn btn-info">{loader ? "Exporting..." : "YES"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ExportModel