import React, { useContext, useCallback, useState } from 'react';
import { addDriverCompleteOrder, getAggregators, getUserForSearchBar } from '../../Redux/action/User';
import { SidebarContext } from '../../Context/SidebarContext';
import { selectOptionStyle } from '../../Helper';
import { default as ReactSelect } from "react-select";
import { currentDate } from '../../utils/dateFormat';
import DatePicker from 'react-datepicker';
import { debounce } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';


function ManageOrder(props) {
  const { modal, closeModal, isLoading, setLoading } = props
  const { dispatch, toast } = useContext(SidebarContext);
  const [driverFetching, setDriverFetching] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [aggregatorFetching, setAggregatorFetching] = useState(false);
  const [aggregators, setAggregators] = useState([]);
  const [selectedAggregator, setSelectedAggregator] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date())


  const formik = useFormik({
    initialValues: {
      driver_id: '',
      aggregator_id: '',
      complete_orders: '',
      log_date: currentDate
    },

    validationSchema: Yup.object({
      complete_orders: Yup.number()
        .typeError('You must specify a number')
        .min(0, "Please enter a positive number")
        .required("This field is required"),

      driver_id: Yup.string()
        .required("This fiels is required"),
      aggregator_id: Yup.string()
        .required("This fiels is required"),
    }),
    onSubmit: async values => {
      let payload = values
      setLoading(true)
      await dispatch(addDriverCompleteOrder(payload))
        .then(
          response => {
            setLoading(false)
            if (response.status === '1') {
              toast.success(response.data.message)
              formik.resetForm();
              setSelectedDriver("")
              setSelectedAggregator("")
              formik.setFieldValue('log_date', moment(selectedDate).format('YYYY-MM-DD'))
            } else {
              toast.error(response.data.error[0])
            }
          },
          error => {
            toast.error(error.response.data.data.message)
            setLoading(false)
          }
        )
    },
  });


  // load options using API call with searched Drivers
  const getSearchedDrivers = async (searchDriver) => {
    const digitNumbers = /^-?[\+|\d.]+(?:e-?\d+)?$/
    let payload = {
      search: { id: '', type: "Driver", active: "true", nickname: '', username: "", phone: '' },
      shorting: { order_in: "asc", order_by: "nickname" }
    }
    if (digitNumbers.test(searchDriver)) {
      let phoneNumber = searchDriver.toString();
      if (phoneNumber[0] === "+") {
        phoneNumber = phoneNumber.substring(1)
      }
      payload.search.phone = phoneNumber
    } else {
      payload.search.nickname = searchDriver
    }
    setDriverFetching(true)
    await dispatch(getUserForSearchBar(payload))
      .then(
        response => {
          setDriverFetching(false)
          if (response.status == "1") {
            const resData = response.data.users
            const alldrivers = []
            resData.forEach(item => {
              const { _id, nickname, username, phone, available, online } = item
              let is_available = available ? 'Available' : 'Unavailable'
              let is_online = online ? 'Online' : 'Offline'
              const labelwithPhone = `${nickname ? nickname : username} (${phone}) , ${is_online} , ${is_available}`
              alldrivers.push({ label: labelwithPhone, value: _id })
            })
            setDrivers(alldrivers)
          } else {
            setDrivers([])
          }
        },
        error => {
          setDrivers([])
          setDriverFetching(false)
          toast.error(error.response.data?.message)
        }
      )
  };

  const getDriversData = useCallback(debounce((searchvalue) => getSearchedDrivers(searchvalue), 500), []);
  const handleSearchDriver = (inputValue) => {
    const value = inputValue.trim()
    if (value && value.length > 0) {
      getDriversData(value);
    }
  }

  const handleSelectDriver = (option) => {
    if (option) {
      let { value } = option
      formik.setFieldValue('driver_id', value)
      setSelectedDriver(option);
    } else {
      formik.setFieldValue('driver_id', "")
      setSelectedDriver("");
    }
  }


  // load options using API call with searched Aggregators
  const getSearchedAggregators = async (searchParams) => {
    let payload = {
      search: { name: searchParams, status: "" },
      shorting: { order_in: 1, order_by: "name" }
    }
    setAggregatorFetching(true)
    await dispatch(getAggregators(payload))
      .then(
        response => {
          setAggregatorFetching(false)
          if (response.status == "1") {
            const resData = response.data.aggregators
            const allAggregators = []
            resData.forEach(item => {
              const { _id, name } = item
              allAggregators.push({ label: name, value: _id })
            })
            setAggregators(allAggregators)
          } else {
            setAggregators([])
          }
        },
        error => {
          setAggregators([])
          setAggregatorFetching(false)
          toast.error(error.response.data?.message)
        }
      )
  };

  const getAggregatorsData = useCallback(debounce((searchvalue) => getSearchedAggregators(searchvalue), 500), []);
  const handleSearchAggregator = (inputValue) => {
    const value = inputValue.trim()
    if (value && value.length > 0) {
      getAggregatorsData(value);
    }
  }

  const handleSelectAggregator = (option) => {
    if (option) {
      let { value } = option
      formik.setFieldValue('aggregator_id', value)
      setSelectedAggregator(option);
    } else {
      formik.setFieldValue('aggregator_id', "")
      setSelectedAggregator("");
    }
  }

  const handleChangeDate = (date) => {
    setSelectedDate(date)
    formik.setFieldValue('log_date', moment(date).format('YYYY-MM-DD'))
  }

  return (
    <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Manager Order</h5>
            <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className=''>
            <form onSubmit={formik.handleSubmit} >
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group required">
                      <label className="control-label">Complete Orders Count</label>
                      <input
                        style={{ minHeight: "40px" }}
                        required
                        name='complete_orders'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.complete_orders}
                        placeholder="Complete orders"
                        type="number"
                        className="form-control"
                      />
                      {formik.touched.complete_orders && formik.errors.complete_orders ? (<span className="text-danger">{formik.errors.complete_orders}</span>) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="control-label">Log Date</label>
                      <DatePicker
                        className="form-control date-field ub-l cursor-pointer"
                        placeholderText="Select Date"
                        showIcon={selectedDate ? false : true}
                        dateFormat='dd/MM/yyyy'
                        dropdownMode="select"
                        isClearable={selectedDate ? true : false}
                        selected={selectedDate}
                        onChange={date => handleChangeDate(date)}
                        showYearDropdown
                        showMonthDropdown
                        adjustDateOnChange
                        autocomplete={false}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group required">
                      <label className="control-label">Driver</label>
                      <ReactSelect placeholder="Search"
                        isClearable={true}
                        isLoading={driverFetching}
                        options={drivers}
                        value={selectedDriver}
                        onInputChange={handleSearchDriver}
                        onChange={handleSelectDriver}
                        styles={selectOptionStyle}
                      />
                      {formik.touched.driver_id && formik.errors.driver_id ? (<span className="text-danger">{formik.errors.driver_id}</span>) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group required">
                      <label className="control-label">Aggregator</label>
                      <ReactSelect placeholder="Search"
                        isClearable={true}
                        isLoading={aggregatorFetching}
                        options={aggregators}
                        value={selectedAggregator}
                        onInputChange={handleSearchAggregator}
                        onChange={handleSelectAggregator}
                        styles={selectOptionStyle}
                      />
                      {formik.touched.aggregator_id && formik.errors.aggregator_id ? (<span className="text-danger">{formik.errors.aggregator_id}</span>) : null}
                    </div>
                  </div>
                </div>

              </div>
              <div className="modal-footer d-flex">
                <button type="button" onClick={closeModal} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button disabled={isLoading} type="submit" className="btn btn-success">{isLoading ? "Processing" : "Submit"}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageOrder;