import React, { useEffect, useState } from 'react';
import { getCommentsApi } from '../../Redux/action/App';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { formatChatTimestamp } from '../../Helper';


function ViewComments(props) {
  const { modal, closeModal, setLoading, orderId, isLoading, orderDetails } = props
  const [allComments, setAllComments] = useState([])
  const dispatch = useDispatch()


  useEffect(() => {
    getCommentsList();
  }, [])


  const getCommentsList = async () => {
    const payload = {
      search: {
        order_id: orderId
      },
      shorting: {
        order_in: 1,
        order_by: "_created_at"
      },
      pagination: {
        start: 1,
        length: 100
      }
    }
    setLoading(true)
    await dispatch(getCommentsApi(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status === "1") {
            const resData = response.data?.webhooks
            setAllComments(resData)
          }
        },
        (error) => {
          setLoading(false);
          toast.error(error.response.data?.message)
        })
      .catch(error => console.log(error))
  }


  return (
    <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} id="myModal"
      style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title w-100 text-center text-secondary">Comments List {`(${orderDetails?.ordernumber})`}</h5>
            <button type="button" onClick={() => closeModal()} className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
          </div>
          <div style={{ maxHeight: '400px', overflowY: 'auto' }} className="modal-body">
            <div className="comment-widgets">
              {allComments && allComments.length > 0 &&
                allComments.map(item =>
                  <div key={item._id} className="d-flex flex-row comment-row">
                    <div className="comment-text w-100">
                      <div className='d-flex justify-content-between'>
                        <div title='User Name' className='d-flex justify-content-start'>
                          <i className="fa-solid fa-user pr-2"></i>
                          <h6 className="text-capitalize text-info">{item.user && item?.user[0].username}</h6>
                        </div>
                        <small title='Time' className="text-muted float-right">{formatChatTimestamp(item._created_at)}</small>
                      </div>
                      <div title='Comment Message' className='d-flex justify-content-start'>
                      <i className="fa-solid fa-message pt-1 pr-2"></i>
                        <p>{item.message}</p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
            {allComments && allComments.length === 0 && !isLoading &&
              <div className='text-center'>
                <h5>No comment available...</h5>
              </div>
            }
          </div>
          <div className="modal-footer">
            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ViewComments