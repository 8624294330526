import React, { useState, useEffect, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import SelectYear from "../Form/Select/SelectYear";
import SelectMonth from "../Form/Select/SelectMonth";
import { useDispatch, useSelector } from "react-redux";
import { getPerDayOrdersCount } from '../../Redux/action/Orders';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { getMonthName } from "../../Helper";
import { OrderContext } from "../../Context/OrderContext";


function AreasChart({ setLoading }) {
    const { setFetchAreasChart } = useContext(OrderContext)
    const dispatch = useDispatch()
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
    const [series, setSeries] = useState([])
    const { user } = useSelector(state => state.auth)
    const { drivers } = user ? user : {}
    const logisticsDrivers = drivers ? drivers : []


    const [options, setOptions] = useState(
        {
            chart: {
                height: 400,
                type: "area",
                toolbar: {
                    show: false,
                    tools: {
                        download: false
                    }
                },
                background: '#fcfcfc'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            title: {
                text: `Per day orders for ${getMonthName(selectedMonth)} , ${selectedYear}`,
                align: 'left'
            },
            colors: ["#22bb33", "#FF1654"],
            xaxis: {
                categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
            }
        }
    )


    useEffect(() => {
        getPerdayOrders()
    }, [selectedMonth, selectedYear])

    const getFormatNumber = (num) => {
        return (num < 10) ? '0' + num.toString() : num.toString();
    }

    const getPerdayOrders = async () => {
        let payload = {
            user_id: user.type === 'Admin' ? "" : user._id,
            status: ["completed", "canceled"],
            year: selectedYear.toString(),
            month: getFormatNumber(selectedMonth)
        }
        if (["Logistics"].includes(user.type) && logisticsDrivers?.length > 0) {
            Object.assign(payload, { drivers: logisticsDrivers })
        }
        setLoading(true)
        await dispatch(getPerDayOrdersCount(payload))
            .then(
                response => {
                    setLoading(false);
                    setFetchAreasChart(true)
                    if (response.status == "1") {
                        const resData = response.data
                        let completed_orders = { name: 'Completed', data: [] }
                        let canceled_orders = { name: 'Canceled', data: [] }
                        let monthly_dates = []
                        resData.completed.forEach((item) => completed_orders.data.push(item.order_count))
                        resData.canceled.forEach((item) => canceled_orders.data.push(item.order_count))
                        resData.completed?.forEach((item) => monthly_dates.push(item.date.toString()))
                        setSeries([completed_orders, canceled_orders])
                        setOptions((prevState) => ({
                            ...prevState,
                            xaxis: {
                                ...prevState.xaxis,
                                categories: monthly_dates,
                            }
                        }));
                    }
                }).catch(error => console.log(error))
    }

    const handleChangeYear = (year) => {
        setSelectedYear(year)
        setOptions((prevState) => ({
            ...prevState,
            title: {
                ...prevState.text,
                text: `Per day orders for ${getMonthName(selectedMonth)} , ${year}`,
            }
        }));
    }
    const handleChangeMonth = (month) => {
        setSelectedMonth(month)
        setOptions((prevState) => ({
            ...prevState,
            title: {
                ...prevState.text,
                text: `Per day orders for ${getMonthName(month)} , ${selectedYear}`,
            }
        }));
    }

    return (
        <React.Fragment>
            <div className="card">
                <div className="card-body">
                    <div className="row justify-content-end">
                        <div className="col-lg-3 col-3">
                            <div className="form-group">
                                <SelectMonth selectedMonth={selectedMonth} handleChangeMonth={handleChangeMonth} />
                            </div>
                        </div>
                        <div className="col-lg-3 col-3">
                            <div className="form-group">
                                <SelectYear selectedYear={selectedYear} handleChangeYear={handleChangeYear} />
                            </div>
                        </div>
                    </div>
                    <ReactApexChart options={options} series={series} type="area" height={400} />
                </div>
            </div>
        </React.Fragment>
    );
}
export default IsLoadingHOC(IsLoggedinHOC(AreasChart))