import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { quickShopUpdate } from '../../Redux/action/Shop';


function QuickEditModal(props) {
  const { modal, closeModal, getListData, isLoading, setLoading, user } = props
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      username: '',
      shop_id: '',
      active: true,
      order_otp_enable: false,
      latitude: '',
      longitude: '',
    },

    validationSchema: Yup.object({
      latitude: Yup.number()
        .typeError('You must specify a number')
        .required("This field is required"),

      longitude: Yup.number()
        .typeError('You must specify a number')
        .required("This field is required"),
    }),
    onSubmit: async values => {
      let fieldValues = values
      let MapLocation = [fieldValues.latitude, fieldValues.longitude]
      Object.assign(fieldValues, { location: MapLocation });
      setLoading(true)
      await dispatch(quickShopUpdate(fieldValues))
        .then(
          response => {
            setLoading(false)
            if (response.status == '1') {
              toast.success(response.data.message)
              closeModal();
              getListData();
            } else {
              toast.error(response.data.error[0])
            }
          })
        .catch(err => setLoading(false))
    },
  });

  useEffect(() => {
    if (user) {
      const locationArr = user.shop_information?.locationmap
      formik.setFieldValue('shop_id', user._p_shopid)
      formik.setFieldValue('username', user.username)
      formik.setFieldValue('active', user.active)
      formik.setFieldValue('order_otp_enable', user.is_order_otp_enable)
      if (locationArr[0] && locationArr[1]) {
        formik.setFieldValue('latitude', locationArr[0])
        formik.setFieldValue('longitude', locationArr[1])
      }
    }
  }, [])

  const handleChangeBooleanValue = (e) => {
    let { name, value } = e.target
    value = JSON.parse(value)
    formik.setFieldValue(name, value)
  }

  return (
    <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Quick Edit</h5>
            <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={formik.handleSubmit} >
            <div className="modal-body p-3">
              <div className='row'>
                <div className='col-sm-12'>
                  <div className="form-group required">
                    <label className="control-label">Name</label>
                    <input className="form-control"
                      name='username'
                      disabled
                      onChange={formik.handleChange}
                      value={formik.values.username}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Active</label>
                    <select name='active' className="form-control"
                      value={formik.values.active}
                      onChange={handleChangeBooleanValue}>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Order OTP</label>
                    <select name='order_otp_enable' className="form-control"
                      value={formik.values.order_otp_enable}
                      onChange={handleChangeBooleanValue}>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group required">
                    <label className="control-label">Latitude</label>
                    <input className="form-control"
                      name='latitude'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.latitude}
                      type="text"
                    />
                    {formik.touched.latitude && formik.errors.latitude ? (<span className="text-danger">{formik.errors.latitude}</span>) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group required">
                    <label className="control-label">Longitude</label>
                    <input className="form-control"
                      name='longitude'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.longitude}
                      type="text"
                    />
                    {formik.touched.longitude && formik.errors.longitude ? (<span className="text-danger">{formik.errors.longitude}</span>) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer model-form-savbtn">
              <button type="button" onClick={closeModal} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button disabled={isLoading} type="submit" className="btn btn-success">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default QuickEditModal
