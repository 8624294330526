import React, { useState, useEffect } from 'react'

function SelectYear(props) {
    const { selectedYear, handleChangeYear } = props
    const [years, setYears] = useState([])

    useEffect(() => {
        const latestFiveYears = []
        const current_year = new Date().getFullYear()
        for (let i = 0; i <= 5; i++) {
            const prvYear = current_year - i
            latestFiveYears.push(prvYear)
        }
        setYears(latestFiveYears)
    }, [])

    return (
        <React.Fragment>
            <select name='active' className="custom-select"
                value={selectedYear}
                onChange={(e) => handleChangeYear(e.target.value)}
            >
                <option value=''>Select Year</option>
                {years && years.length > 0 &&
                    years.map((year, index) =>
                        <option key={index + 1} value={year}>{year}</option>
                    )}
            </select>
        </React.Fragment>
    )
}
export default SelectYear
