import React, { useState, useEffect } from 'react'
import { addSubCategory, getMainCategories } from '../../Redux/action/Category';
import IsLoadingHOC from '../../Components/IsLoadingHOC'
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { Link, useHistory } from 'react-router-dom';
import { default as ReactSelect } from "react-select";
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify'
import * as Yup from 'yup';
import { selectOptionStyle } from '../../Helper';


function AddSubCategory(props) {
  const { setLoading } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const [categories, setcategories] = useState([])
  const [selectedvalue, setSelectedvalue] = useState(null)
  const [searchParams] = useState({ _id: "", category: "", num: "", active: "true" })

  useEffect(() => {
    getMainCategoriesData();
  }, [])

  const getMainCategoriesData = async () => {
    const payload = {
      search: { ...searchParams },
      shorting: { order_in: "desc", order_by: "category" },
    }
    setLoading(true)
    await dispatch(getMainCategories(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status === "1") {
            const result = (response.data && response.data.categories) || []
            const mainCategories = []
            result.forEach(item => {
              mainCategories.push({ label: item.category, value: item._id })
            })
            setcategories(mainCategories)
          } else {
            toast.error(response.data.error[0])
            setcategories([])
          }
        },
        (error) => {
          setLoading(false);
          toast.error(error.response.data?.message)
        })
      .catch(error => console.log(error))
  }

  const handleSelect = option => {
    const { value } = option ? option : {}
    let mainCatgId = value ? value : ''
    formik.setFieldValue('main_category_id', mainCatgId);
    setSelectedvalue(option)
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      number: '',
      main_category_id: ''
    },

    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .min(3, 'This field must be at least 3 characters'),

      number: Yup.number()
        .typeError('You must specify a number')
        .required("This field is required")
        .min(0, "Please enter a positive number"),

      main_category_id: Yup.string()
        .required("This field is required")
    }),

    onSubmit: async values => {
      const fieldValues = values
      setLoading(true)
      await dispatch(addSubCategory(fieldValues))
        .then(
          response => {
            setLoading(false)
            if (response.status === '1') {
              toast.success(response.data.message)
              formik.resetForm();
              setSelectedvalue(null)
              history.push('/sub-categories')
            } else {
              toast.error(response.data.error[0])
            }
          },
          (error) => {
            setLoading(false);
            toast.error(error.response.data?.message)
          }
          )
        .catch(error => console.log(error))
    },
  });

  console.log("document.referrer" , document.referrer)




  return (
    <div className="content-wrapper">
      <div className="content-header main_breadcrumb_top">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Create a new Sub Category to Main Category</h1>
            </div>
            {/* <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Sub Category</li>
                <li className="breadcrumb-item active">Create</li>
              </ol>
            </div> */}
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Sub Category</label>
                          <input
                            required
                            name="title"
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            onBlur={formik.handleBlur}
                            type="text" className="form-control" placeholder="Enter sub category" />
                          {formik.touched.title && formik.errors.title ? (<span className="text-danger ">{formik.errors.title}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Sort Order</label>
                          <input
                            required
                            name="number"
                            onChange={formik.handleChange}
                            value={formik.values.number}
                            onBlur={formik.handleBlur}
                            type="number" className="form-control" placeholder="Enter sort order " />
                          {formik.touched.number && formik.errors.number ? (<span className="text-danger " >{formik.errors.number}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Main Category</label>
                          <ReactSelect
                            isClearable={true}
                            options={categories}
                            value={selectedvalue}
                            onChange={handleSelect}
                            placeholder="Select"
                            styles={selectOptionStyle}
                          />
                          {formik.touched.main_category_id && formik.errors.main_category_id ? (<span className="text-danger" >{formik.errors.main_category_id}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="pb-5 form-foot-btn">
                      <button type="button" className="btn btn-primary mr-2" onClick={() => history.push('/sub-categories')}>Back</button>
                      <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default IsLoadingHOC(IsLoggedinHOC(AddSubCategory))