import React, { useContext, useEffect, useState } from 'react';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { getUsersList } from '../../Redux/action/User';
import { getDeliveryZones, removeUsersToDeliveryZone } from '../../Redux/action/DeliveryZone';
import { SidebarContext } from '../../Context/SidebarContext';


function RemoveUserFromDeliveryZone(props) {
    const { isLoading, setLoading, modal, closeModal, clearSelectedItem, deliveryZones } = props
    const [searchParams] = useState({ title: "", removed: "", user_id: "", type: "" })
    const [selectedUsers, setSelectedUsers] = useState([])

    const {
        dispatch,
        toast,
        selectedItem,
        handleSelectItem,
    } = useContext(SidebarContext)

    const handleSubmit = async () => {
        try {
            if (selectedItem.length === 0) {
                toast.error("At least one user must be selected");
                return;
            }
            setLoading(true);
            const payload = {
                users: selectedItem,
                delivery_zones: deliveryZones
            };
            const response = await dispatch(removeUsersToDeliveryZone(payload));
            setLoading(false);
            if (response.status === '1') {
                toast.success(response.data.message);
                clearSelectedItem();
                closeModal();
            } else {
                toast.error(response.data.error[0]);
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.response?.data?.message || "An error occurred");
            console.error(error);
        }
    };
    

    useEffect(() => {
        if (deliveryZones) {
            getDeliveryZonesData();
        }
    }, [deliveryZones])

    const getDeliveryZonesData = async () => {
        const payload = {
            search: { ...searchParams, _id: deliveryZones.join(',') },
            shorting: { order_in: -1, order_by: 'km_max' },
            pagination: { start: 1, length: 10000 }
        }
        setLoading(true)
        await dispatch(getDeliveryZones(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const deliveryZones = response.data.delivery_zones
                        const uniqueUserIds = [];
                        for (const zone of deliveryZones) {
                            for (const userId of zone.selected_users) {
                                if (!uniqueUserIds.includes(userId)) {
                                    uniqueUserIds.push(userId);
                                }
                            }
                        }
                        if (uniqueUserIds && uniqueUserIds.length > 0) {
                            getShopListData(uniqueUserIds)
                        }
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    const getShopListData = async (selectedUsers) => {
        const payload = {
            search: {
                _id: selectedUsers.join(','),
                type: "",
                nickname: "",
                username: "",
                phone: "",
                from_date: "",
                to_date: "",
                verified: "",
                available: "",
                online: ""
            },
            shorting: { order_in: -1, order_by: '_created_at' },
        }
        setLoading(true)
        await dispatch(getUsersList(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setSelectedUsers(response.data.users)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    }

    return (
        <div className={`modal fade checkbox-modal cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form>
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-warning" id="exampleModalLongTitle">Remove users from selected delivery zones</h5>
                            <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        {selectedUsers && selectedUsers.length > 0 &&
                                            selectedUsers.map(item => (
                                                <div className="col-sm-6">
                                                    <div key={item._id} className="form-check select-user">
                                                        <input className="form-check-input" type="checkbox" onChange={(event) => handleSelectItem(event, item._id)}
                                                            checked={selectedItem.includes(item._id)} id="defaultCheck1" />
                                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                                            {item.nickname}
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button disabled={selectedItem.length === 0 || isLoading} onClick={handleSubmit} type="button" className="btn btn-danger"> {isLoading ? "Processing.." : "Remove"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(RemoveUserFromDeliveryZone))
