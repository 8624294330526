import { axiosWithHashKey, globalAxios, withoutAuthAxios } from "../../Config/axios"


export const login = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/users/login", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorr", error);
                }
            )
    })
}

export const foodicsSignup = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/shop_vendor/register_guest_shop_vendor", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorr", error);
                }
            )
    })
}

export const getGlobalWebConfig = (accessToken) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await globalAxios(accessToken).get("/users/get_global_web_config")
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorr", error);
                }
            )
    })
}

export const getAccessTokenApi = (data, accessToken) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await globalAxios(accessToken).post("/users/foodics/access_token", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorr", error);
                }
            )
    })
}

export const forgotPassword = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/users/forgot_password", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorr", error);
                }
            )
    })
}

export const sendOtpApi = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/users/send_otp", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorr", error);
                }
            )
    })
}

export const verifyOtpApi = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/users/verify_otp", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("error", error);
                }
            )
    })
}

export const registerShopUser = (data, hash_key) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await axiosWithHashKey(hash_key).post("/shops/register_shop_user", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("error", error);
                }
            )
    })
}

export const saveFoodicsUser = data => _dispatch => {
    return (
        _dispatch({ type: "SAVE_FOODICS_USER", payload: data })
    )
}

export const saveFoodicsUserToken = data => _dispatch => {
    return (
        _dispatch({ type: "SAVE_FOODICS_USER_TOKEN", payload: data })
    )
}

export const logOut = () => _dispatch => {
    return (
        _dispatch({ type: "LOGOUT" })
    )
}

