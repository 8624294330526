
import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../../Redux/action/Auth';
import { useHistory } from 'react-router-dom';
import { countryCode } from '../../../Config/constants';
import { sidebarMenu } from '../../../utils/sidebarMenu';


const AdminSidebar = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    const [time, setTime] = useState('AdminLTE 3');
    const { user } = useSelector(state => state.auth)
    const { allowed_menu_permission } = user ? user : {}
    const time_zone = process.env.REACT_APP_TIMEZONE || 'Asia/Kolkata'


    useEffect(() => {
        const timer = setInterval(() => {
            var date = moment(new Date());
            let current_time = date.tz(time_zone).format('hh:mm:ss A');
            setTime(current_time);
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const handleLogOut = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Utrac</h1>
                        <p>Do you want to exit from this App?</p>
                        <button onClick={onClose}>No</button>
                        <button
                            onClick={() => {
                                dispatch(logOut())
                                history.push('/login')
                                onClose()
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            }
        });
    }

    return (
        <>
            <aside className="main-sidebar new_main_sidebar sidebar-dark-primary elevation-4">
                <span className="nav_button" data-widget="pushmenu" role="button"><i className="fas fa-angle-left"></i></span>
                <div className="logo">
                    <span className="brand-link" href="#">
                        {/* {countryCode === '+965' ?
                            <img style={{ borderRadius: '50%', width: "45px", height: "50px", margin: '10px', padding: '1px' }} id="logo" className="brand-image  elevation-3" alt="Logo" src={require("../../../assets/logo/kuwait_logo.png")} />
                            :
                            <img id="logo" className="brand-image  elevation-3" alt="Logo" src={require("../../../assets/dist/img/logo.png")} />
                        } */}
                        <img
                            style={{ borderRadius: '50%', width: "50px", height: "45px", margin: '6px', padding: '1px' }}
                            src={
                                countryCode === '+965'
                                    ? require('../../../assets/logo/kuwait_logo.png')
                                    : countryCode === '+974'
                                        ? require('../../../assets/logo/qatar_logo.png')
                                        : require('../../../assets/dist/img/login-logo.png')
                            }
                            alt="login-logo"
                        />
                        <span className="brand-text font-weight-light">{time}</span>
                        {countryCode !== '+966' &&
                            <div className='pl-3'>
                                <span style={{ fontSize: "1rem" }}>Powered by Utrac</span>
                            </div>
                        }
                    </span>
                </div>
                <div className="sidebar">
                    <nav className="mt-2 main_nav">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            {(allowed_menu_permission?.length > 0 ? allowed_menu_permission : sidebarMenu)?.map((menuItem, index) => (
                                <li key={index + 11} className="nav-item">
                                    <a className="nav-link">
                                        <i className={`${menuItem.icon} mr-3`}></i>
                                        <p>
                                            {menuItem.name}<span></span>
                                            {menuItem?.submenus?.length > 0 && <i className="right fas fa-angle-left"></i>}
                                        </p>
                                    </a>
                                    {menuItem?.submenus?.length > 0 &&
                                        <ul className="nav nav-treeview">
                                            {menuItem.submenus.map((submenuItem, index2) => (
                                                <li key={index2 + 22} className="nav-item">
                                                    <Link to={submenuItem.path} className={`nav-link ${location.pathname === submenuItem.path ? "active" : ""}`} >
                                                        {submenuItem.name === 'Create' ? <i className="fa-regular fa-circle-plus nav-icon"></i> : <i className="far fa-circle nav-icon"></i>}
                                                        <p>{submenuItem.name}</p>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </li>
                            ))}
                            <li className="nav-item">
                                <a role='button' onClick={handleLogOut} className="nav-link">
                                    <i className="fas fa-sign-out-alt fa-lg mr-3 "></i>
                                    Log out
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    )
}
export default AdminSidebar