/* eslint-disable no-undef */
import React, { useState, useRef } from "react";
import { useJsApiLoader, GoogleMap, Marker, Autocomplete } from '@react-google-maps/api';
import simpleMarker from '../../assets/dist/img/marker.png';
import { useDispatch, useSelector } from 'react-redux';
import { saveAddress, saveCurrentLatLng } from '../../Redux/action/App';

const libraries = ['places'];

function SimpleMap({ callBackChildFunc }) {
    const dispatch = useDispatch()
    const { latLng } = useSelector(state => state.app)
    const [map, setMap] = useState(null)
    const autocompleteRef = useRef();

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    }, [libraries])

    function handleLoad(maps) {
        setMap(maps)
    }

    const handleBoundsChanged = () => {
        var lat = map.getCenter().lat()
        var lng = map.getCenter().lng()
        if (latLng.lat !== lat && latLng.lng !== lng) {
            setTimeout(() => {
                const latLng = { lat: lat, lng: lng }
                dispatch(saveCurrentLatLng(latLng))
            }, 500)
        }
    };

    async function onPlaceChanged() {
        if (autocompleteRef.current.value === '') {
            return
        }
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': autocompleteRef.current.value }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                let latitude = results[0].geometry.location.lat()
                let longitude = results[0].geometry.location.lng()
                const location = { lat: latitude, lng: longitude }
                dispatch(saveCurrentLatLng(location))
                dispatch(saveAddress(autocompleteRef.current.value))
                callBackChildFunc(autocompleteRef.current.value)
            }
            else {
                alert("Geocode was not successful for the following reason: " + status);
            }
        });
    }

    const mapContainerStyle = { width: "100%", height: "100%", };

    return (
        <div className="track-maps-content">
            {isLoaded && latLng && latLng.lat > 0 && latLng.lng > 0 &&
                <GoogleMap
                    center={latLng}
                    // onBoundsChanged={handleBoundsChanged}
                    zoom={15}
                    mapContainerStyle={mapContainerStyle}
                    onLoad={handleLoad}
                    options={{
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                    }}
                >
                    <Marker
                        position={latLng}
                        icon={simpleMarker}
                    />
                    <Autocomplete onPlaceChanged={(place) => onPlaceChanged(place)}>
                        <section className="content mt-3 p-1">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group has-search">
                                        <span className="fa fa-search form-control-feedback"></span>
                                        <input style={{ borderRadius: "2.25rem", height: '2.5rem' }}
                                            className="form-control"
                                            ref={autocompleteRef}
                                            type="search"
                                            placeholder="Search" />
                                        <div className="col-auto">
                                            <button className="btn btn-lg" type="submit"><i className="bi-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Autocomplete>
                </GoogleMap>
            }
        </div>
    );
}
export default SimpleMap