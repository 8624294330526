import React from 'react'
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import AreaChart from '../../Components/Chart/AreaChart';
import BarChart from '../../Components/Chart/BarChart';

function OrdersCharts() {
    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Orders Count</h1>
                        </div>
                        <div className="col-sm-6">
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <AreaChart />
                        </div>
                        <div className="col-sm-6">
                            <BarChart />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default IsLoggedinHOC(OrdersCharts)
