import React, { useState, useEffect, useContext } from "react";
import IsLoadingHOC from "../../Components/IsLoadingHOC";
import IsLoggedinHOC from "../../Components/IsLoggedinHOC";
import { getAggregators, removeAggregator, updateAggregator } from "../../Redux/action/User";
import { useHistory } from "react-router-dom";
import { SidebarContext } from "../../Context/SidebarContext";
import NotFoundData from "../../Components/NotFoundData";
import DeleteConfirmation from "../../Components/ConfirmModel/Delete";
import AddAggregator from "./AddAggregator";
import EditAggregator from "./EditAggregator";
import ManageOrder from "./ManageOrder";
import { saveAggregatorName } from "../../Redux/action/App";

function Aggregators(props) {
    const { isLoading, setLoading } = props;
    const [aggregators, setAggregators] = useState([]);
    const [searchParams, setSearchParams] = useState({ name: "", status: "active" });
    const [sorting, setSorting] = useState({ order_in: 1, order_by: "name" });
    const [userId, setUserId] = useState("");
    const [userInfo, setUserInfo] = useState("");
    const [manageOrder, setManageOrder] = useState(false);
    const history = useHistory();

    const {
        dispatch,
        toast,
        setTotalCount,
        deleteModal,
        setDeleteModal,
        modalOpen,
        setModalOpen,
        editModal,
        setEditModal,
    } = useContext(SidebarContext);

    useEffect(() => {
        getAggregatorsData();
    }, [searchParams]);

    const getAggregatorsData = async () => {
        const Search_params = searchParams;
        const payload = {
            search: Search_params,
            shorting: sorting,
        };
        setLoading(true);
        await dispatch(getAggregators(payload)).then(
            (response) => {
                setLoading(false);
                if (response.status === "1") {
                    setAggregators(response.data.aggregators);
                    setTotalCount(response.data.total_count);
                } else {
                    setAggregators([]);
                    setTotalCount(null);
                }
            },
            (error) => {
                console.log(error.response.data?.message);
                setLoading(false);
                setAggregators([]);
                setTotalCount(null);
            }
        );
    };

    const filterListDataByStatus = (activeStatus) => {
        setSearchParams((prevState) => ({
            ...prevState,
            status: activeStatus,
        }));
    };

    const handleRemoveAccount = async () => {
        const payload = { user_id: userId };
        setLoading(true);
        await dispatch(removeAggregator(payload)).then(
            (response) => {
                setLoading(false);
                if (response.status == "1") {
                    toast.success(response.data?.message);
                    setDeleteModal(false);
                    getAggregatorsData();
                } else {
                    toast.error(response.data?.message);
                }
            },
            (error) => {
                toast.error(error.response.data?.data?.message);
                setLoading(false);
            }
        );
    };

    const handleMoveToTrash = async (id) => {
        const payload = { user_id: id, status: "inactive" };
        setLoading(true);
        await dispatch(updateAggregator(payload)).then(
            (response) => {
                setLoading(false);
                if (response.status == "1") {
                    toast.success(response.data?.message);
                    setDeleteModal(false);
                    getAggregatorsData();
                } else {
                    toast.error(response.data?.message);
                }
            },
            (error) => {
                toast.error(error.response.data?.data?.message);
                setLoading(false);
            }
        );
    };

    const handleRestore = async (id) => {
        const payload = { user_id: id, status: "active" };
        setLoading(true);
        await dispatch(updateAggregator(payload)).then(
            (response) => {
                setLoading(false);
                if (response.status == "1") {
                    toast.success(response.data?.message);
                    setDeleteModal(false);
                    getAggregatorsData();
                } else {
                    toast.error(response.data?.message);
                }
            },
            (error) => {
                console.log(error)

            }
        );
    };

    const navigateToAggregatorLogList = (item) => {
        dispatch(saveAggregatorName(item.name))
        history.push(`/aggregator-logs/${item._id}`)
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header main_breadcrumb_top">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Aggregators</h1>
                            </div>
                            <div className="col-sm-6"></div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid hour--details">
                    <ul className="nav nav-tabs tab--container" role="tablist">
                        <li className="nav-item">
                            <a
                                onClick={() => filterListDataByStatus("")}
                                className={`nav-link cursor-pointer ${searchParams.status === "" && "active"}`}
                                data-toggle="tab"
                            >
                                All
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                onClick={() => filterListDataByStatus("active")}
                                className={`nav-link cursor-pointer ${searchParams.status === "active" && "active"
                                    }`}
                                data-toggle="tab"
                            >
                                Active
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                onClick={() => filterListDataByStatus("inactive")}
                                className={`nav-link cursor-pointer ${searchParams.status === "inactive" && "active"
                                    }`}
                                data-toggle="tab"
                            >
                                Trash
                            </a>
                        </li>
                    </ul>
                    <div className="action--btn">
                        <button onClick={() => setModalOpen(true)} disabled={isLoading} type="add" className="btn btn-primary mr-2"><i className="fa-regular fa-circle-plus mr-2"></i>Add Aggregator</button>
                        <button onClick={() => setManageOrder(true)} disabled={isLoading} type="add" className="btn btn-secondary">Manage Order</button>
                    </div>
                    <div className="tab-content">
                        <div id="home" className="container-fluid tab-pane active">
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row my--row">
                                        <div className={`${aggregators?.length > 1 && 'item--wrapper'}`}>
                                            {aggregators &&
                                                aggregators?.length > 0 &&
                                                aggregators.map((item) => (
                                                    <div key={item._id} className="item--boxes">
                                                        <div
                                                            style={{ background: "#a2d2ff" }}
                                                            className="small-box new-samll-box"
                                                        >
                                                            <div className="inner box-inner">
                                                                <h4>{item.complete_orders}</h4>
                                                                <p>{item.name}</p>
                                                            </div>
                                                            <div className="icon box-icon">
                                                                <img
                                                                    className="box-image p-2 img-size"
                                                                    src={item.logo}
                                                                    alt="aggregators"
                                                                />
                                                            </div>
                                                            <div className="button_and_dot_container">
                                                                <div className="inner-wrapper">
                                                                    <span
                                                                        onClick={() => navigateToAggregatorLogList(item)}
                                                                        className="text-white cursor-pointer"
                                                                    >
                                                                        More info{" "}
                                                                        <i className="fas fa-arrow-circle-right"></i>
                                                                    </span>
                                                                    <div className="action_button">
                                                                        <div className="btn-group">
                                                                            <button
                                                                                type="button"
                                                                                className="btn dropdown"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                            >
                                                                                <img
                                                                                    src={require("../../assets/dist/img/action.png")}
                                                                                    alt="actionicon"
                                                                                />
                                                                            </button>
                                                                            <div className="dropdown-menu">
                                                                                <span
                                                                                    className="dropdown-item"
                                                                                    onClick={() => {
                                                                                        setUserInfo(item);
                                                                                        setEditModal(true);
                                                                                    }}
                                                                                >
                                                                                    <span className="blue_1">
                                                                                        <i
                                                                                            className="fa fa-pencil pr-2"
                                                                                            aria-hidden="true"
                                                                                        ></i>
                                                                                        Edit
                                                                                    </span>
                                                                                </span>
                                                                                {item.status === "inactive" &&
                                                                                    <>
                                                                                        <div className="dropdown-divider"></div>
                                                                                        <span
                                                                                            className="dropdown-item"
                                                                                            onClick={() => {
                                                                                                setUserId(item._id);
                                                                                                setDeleteModal(true);
                                                                                            }}
                                                                                        >
                                                                                            <span className="red_1">
                                                                                                <i
                                                                                                    className="fa fa-trash pr-2"
                                                                                                    aria-hidden="true"
                                                                                                ></i>
                                                                                                Remove
                                                                                            </span>
                                                                                        </span>
                                                                                    </>
                                                                                }
                                                                                {item.status === "active" &&
                                                                                    <>
                                                                                        <div className="dropdown-divider"></div>
                                                                                        <span
                                                                                            className="dropdown-item"
                                                                                            onClick={() => handleMoveToTrash(item._id)}
                                                                                        >
                                                                                            <i className="fa-solid fa-compress pr-2"></i>
                                                                                            Move To Trash

                                                                                        </span>
                                                                                    </>
                                                                                }
                                                                                {item.status === "inactive" &&
                                                                                    <>
                                                                                        <div className="dropdown-divider"></div>
                                                                                        <span
                                                                                            className="dropdown-item"
                                                                                            onClick={() => handleRestore(item._id)}
                                                                                        >
                                                                                            <i className="fa-solid fa-compress pr-2"></i>
                                                                                            Restore
                                                                                        </span>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    {aggregators?.length === 0 && !isLoading && <NotFoundData />}
                </div>
            </div>
            {deleteModal && (
                <DeleteConfirmation
                    modal={deleteModal}
                    loader={isLoading}
                    title="Do you want to remove aggregator?"
                    closeModal={() => setDeleteModal(false)}
                    handleDelete={handleRemoveAccount}
                />
            )}
            {modalOpen && (
                <AddAggregator
                    modal={modalOpen}
                    isLoading={isLoading}
                    setLoading={setLoading}
                    closeModal={() => setModalOpen(false)}
                    getUpdatedList={getAggregatorsData}
                />
            )}
            {editModal && (
                <EditAggregator
                    modal={editModal}
                    isLoading={isLoading}
                    setLoading={setLoading}
                    userInfo={userInfo}
                    closeModal={() => setEditModal(false)}
                    getUpdatedList={getAggregatorsData}
                />
            )}
            {manageOrder && (
                <ManageOrder
                    modal={manageOrder}
                    isLoading={isLoading}
                    setLoading={setLoading}
                    closeModal={() => setManageOrder(false)}
                />
            )}
        </>
    );
}
export default IsLoadingHOC(IsLoggedinHOC(Aggregators));
