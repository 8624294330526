import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useJsApiLoader, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import shopMarker from '../../assets/img/shop.png';
import availableDriver from "../../assets/img/available_driver.png";
import unAvailableDriver from "../../assets/img/unavailable_driver.png";
import offlineDriver from "../../assets/img/offline_driver.png";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../Components/IsLoadingHOC";
import IsLoggedinHOC from "../../Components/IsLoggedinHOC";
import { getUsersList } from "../../Redux/action/User";
import { mapCenter } from "../../Config/constants";


const libraries = ['places'];
const mapContainerStyle = { width: "100%", height: "100%" };

function UsersLocation(props) {
    const dispatch = useDispatch();
    const { setLoading } = props;
    const [center, setCenter] = useState(mapCenter);
    const [shopsLocations, setShopsLocations] = useState([]);
    const [driversData, setDriversData] = useState([]);
    const [map, setMap] = useState(null);
    const [isMouseOver, setMouseOver] = useState(false);
    const [shopMarkerInfo, setShopMarkerInfo] = useState(null);
    const [driverMarkerInfo, setDriverMarkerInfo] = useState(null);
    const [userType, setUserType] = useState("all")
    const [searchParams, setSearchParams] = useState({ _id: "", type: "Driver,Shop", online: "", nickname: "", username: "", phone: "", active: "true", verified: "", available: "" })
    const [finalSearchParams, setFinalSearchParams] = useState({ _id: "", type: "Driver,Shop", online: "", nickname: "", username: "", phone: "", active: "true", verified: "", available: "" })


    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    }, [libraries])

    useEffect(() => {
        getAllUsersLocationData();
    }, [finalSearchParams])

    const getAllUsersLocationData = async () => {
        const payLoad = {
            search: finalSearchParams,
            shorting: { order_in: 1, order_by: "username" },
        }
        setLoading(true)
        await dispatch(getUsersList(payLoad))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const resData = response.data.users
                        let alldrivers = []
                        resData.forEach(item => {
                            if (item.type === 'Driver') {
                                const driver = {
                                    location: item?.location,
                                    phone: item.phone,
                                    nickname: item.nickname || item.username,
                                    available: item.available,
                                    online: item.online
                                }
                                alldrivers.push(driver)
                            }
                        })
                        if (alldrivers && (alldrivers.length === 1 || alldrivers.length === 2)) {
                            const latitude = alldrivers[0]?.location[0]
                            const longitude = alldrivers[0]?.location[1]
                            setCenter({ lat: latitude, lng: longitude })
                        }
                        setDriversData(alldrivers)
                        // Filter User Data For Active Shop
                        let activeShops = []
                        resData.forEach(item => {
                            if (item.type === 'Shop') {
                                let shops = {
                                    location: item?.shop_information[0]?.locationmap,
                                    phone: item?.phone,
                                    nickname: item?.nickname || item?.username
                                }
                                activeShops.push(shops);
                            }
                        })
                        if (activeShops && (activeShops.length === 1 || activeShops.length === 2)) {
                            const latitude = activeShops[0]?.location[0]
                            const longitude = activeShops[0]?.location[1]
                            setCenter({ lat: latitude, lng: longitude })
                        }
                        setShopsLocations(activeShops)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    const handleMouseOver = (data, type) => {
        setMouseOver(true);
        if (type === 'Shop') {
            setDriverMarkerInfo(null)
            const { nickname, location } = data
            const position = { lat: location[0], lng: location[1] }
            setShopMarkerInfo({ nickname, position })
        }
        else {
            setShopMarkerInfo(null)
            const { location, nickname, phone, online, available } = data
            const position = { lat: location[0], lng: location[1] }
            setDriverMarkerInfo({ nickname, phone, position, online, available })
        }
    };

    const handleInfoWindowClose = () => {
        setMouseOver(false);
        setShopMarkerInfo(null)
        setDriverMarkerInfo(null)
    };

    const handleSelectUserType = (e) => {
        setUserType(e.target.value)
        const { name, value } = e.target
        if (value === 'all') {
            setSearchParams((prevState) => ({
                ...prevState,
                [name]: 'Driver,Shop',
            }));
        }
        else {
            setSearchParams((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setSearchParams((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        setFinalSearchParams(searchParams);
    }
    const handleReset = () => {
        if (searchParams.nickname || searchParams.phone) {
            setFinalSearchParams({
                _id: "", type: "Driver,Shop", online: "", nickname: "", username: "", phone: "", active: "true", verified: "", available: ""
            });
            setSearchParams({
                _id: "", type: "Driver,Shop", online: "", nickname: "", username: "", phone: "", active: "true", verified: "", available: ""
            });
            setUserType("all")
        }
    }

    const getDriverInfo = (user, type) => {
        const { online, available } = user;
        let status, icon, textColor;
        if (online) {
            status = available ? "AVAILABLE" : "UNAVAILABLE";
            icon = available ? availableDriver : unAvailableDriver;
            textColor = available ? "text-success" : "text-warning";
        } else {
            status = "OFFLINE";
            icon = offlineDriver;
            textColor = "text-danger";
        }
        return type === "marker" ? icon : type === "text-color" ? textColor : status;
    };
    
    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="all_drivers">
                                <div className="card card-dark">
                                    <div className="card-header">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-6 col-lg-11">
                                                    <h3 className="pl-5">Search</h3>
                                                </div>
                                                <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                    <i className="fas fa-angle-up click_search"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit} className="form_search" action="">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label>User Type</label>
                                                <select className="form-control" name="type" value={userType} onChange={handleSelectUserType}>
                                                    <option value="all">All</option>
                                                    <option value="Driver">Drivers</option>
                                                    <option value="Shop">Shops</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Name</label>
                                                <input className="form-control" type="text" placeholder="Enter Name" name="nickname" value={searchParams.nickname} onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Phone</label>
                                                <input className="form-control" min={0} type="number" placeholder="Enter Phone" name="phone" value={searchParams.phone} onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Activate</label>
                                                <select className="form-control" name="active" value={searchParams.active} onChange={handleChange}>
                                                    <option value="">All</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="card-footer text-center">
                                            <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="all_drivers_map">
                                    {isLoaded && center &&
                                        <GoogleMap
                                            center={center}
                                            zoom={12}
                                            mapContainerStyle={mapContainerStyle}
                                            onLoad={map => setMap(map)}
                                        >
                                            {((shopsLocations && shopsLocations.length > 0) &&
                                                (userType === 'all' || userType === 'Shop')) &&
                                                shopsLocations.map((item, i) =>
                                                    item.location && item.location[0] && item.location[1] &&
                                                    <Marker
                                                        key={i + 1}
                                                        position={{ lat: item?.location[0], lng: item?.location[1] }}
                                                        icon={shopMarker}
                                                        onMouseOver={() => handleMouseOver(item, 'Shop')}
                                                    />
                                                )}
                                            {((driversData && driversData.length > 0) &&
                                                (userType === 'all' || userType === 'Driver')) &&
                                                driversData.map((item, i) =>
                                                    item.location && item.location[0] && item.location[1] &&
                                                    <Marker
                                                        key={i + 1}
                                                        position={{ lat: item?.location[0], lng: item?.location[1] }}
                                                        icon={getDriverInfo(item , "marker")}
                                                        onMouseOver={() => handleMouseOver(item, 'Driver')}
                                                    />
                                                )}
                                            {isMouseOver && shopMarkerInfo && (
                                                <InfoWindow
                                                    position={shopMarkerInfo.position}
                                                    onCloseClick={handleInfoWindowClose}
                                                    className="info_window"
                                                >
                                                    <div>
                                                        <h6 style={{fontSize :"16px"}} className="text-secondary">{shopMarkerInfo.nickname}</h6>
                                                    </div>
                                                </InfoWindow>
                                            )}
                                            {isMouseOver && driverMarkerInfo && (
                                                <InfoWindow
                                                    position={driverMarkerInfo.position}
                                                    onCloseClick={handleInfoWindowClose}
                                                    className="info_window"
                                                >
                                                    <div>
                                                        <h5 style={{fontSize :"16px"}} className={`${getDriverInfo(driverMarkerInfo , "text-color")}`}>{getDriverInfo(driverMarkerInfo , "status")}</h5>
                                                        <h6 style={{fontSize :"16px"}} className="text-secondary">{driverMarkerInfo.nickname}</h6>
                                                    </div>
                                                </InfoWindow>
                                            )}
                                        </GoogleMap>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default IsLoadingHOC(IsLoggedinHOC(UsersLocation))
