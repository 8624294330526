import React from "react";

const Footer = () => {
    return (
        <div className="main-footer d-flex justify-content-between">
            <span className="footer_text text-center text-gray pr-2"><strong>Powered by Utrac</strong></span>
            <img style={{ width: '90px', height: '30px' }} alt="utrac_logo" src={require('../../../assets/logo/utrac_logo.png')} />
        </div>
    )
}

export default Footer