import React, { useEffect, createContext, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
import $ from 'jquery';


export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const location = useLocation();
    const searchRef = useRef("");
    const csvLink = useRef()
    const dispatch = useDispatch();
    const [itemsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(null);
    const [currentPage, setcurrentPage] = useState(1);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [isUpdate, setIsUpdate] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [branchId, setBranchId] = useState("");
    const [actionPermissionModal, setActionPermissionModal] = useState(false)
    const [exportPermissionModal, setExportPermissionModal] = useState(false)
    const [walletModal, setWalletModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [exportModal, setExportModal] = useState(false)
    const [serviceId, setServiceId] = useState("");
    const [serviceData, setServiceData] = useState("");
    const [sorting, setSorting] = useState({ order_in: -1, order_by: "_created_at" })
    const [selectedDate, setSelectedDate] = useState({ from_date: null, to_date: null })
    const [exportsData, setExportsData] = useState([])
    const [selectedItem, setSelectedItem] = useState([])
    const [fileLoading, setFileLoading] = useState(false)
    const [showOTPModal, setShowOTPModal] = useState(false)
    const [orderDetails, setOrderDetail] = useState("")
    const [orderId, setOrderId] = useState('')


    useEffect(() => {
        $(".click_search").on('click', function () {
            $(".form_search").slideToggle();
        });

        $(".click_search").on('click', function () {
            $(this).toggleClass('is-active').next(".option-content").stop().slideToggle(500);
        });
        setInitialState();
    }, [location.pathname])


    const toggleExportModal = () => setExportModal(!exportModal)

    const handleSelectItem = (event, order_id) => {
        const is_checked = event.target.checked
        const selected_item = [...selectedItem]
        if (is_checked) {
            selected_item.push(order_id)
            setSelectedItem(selected_item)
        }
        else {
            const index = selected_item.indexOf(order_id);
            if (index > -1) {
                selected_item.splice(index, 1);
                setSelectedItem(selected_item)
            }
        }
    }

    const handleChangePage = (p) => {
        setcurrentPage(p);
    };

    const handleSorting = (orderby) => {
        setSorting((prevState) => ({
            ...prevState,
            order_by: orderby,
            order_in: prevState.order_in === -1 ? 1 : -1
        }));
    }

    const resetForm = () => {
        setcurrentPage(1)
        setmaxPageNumberLimit(5);
        setminPageNumberLimit(0);
        setSelectedDate({ from_date: null, to_date: null })
        setSorting({ order_in: -1, order_by: "_created_at" })
        setSelectedItem([])
    }

    const handleSubmit = (getFilterList) => {
        setSelectedItem([])
        if (currentPage === 1) {
            getFilterList();
        } else {
            setcurrentPage(1)
            setmaxPageNumberLimit(5);
            setminPageNumberLimit(0);
        }
    }

    const setInitialState = () => {
        setcurrentPage(1)
        setminPageNumberLimit(0)
        setmaxPageNumberLimit(5)
        setIsUpdate(false)
        setLoading(false)
        setBranchId("")
        setServiceId("")
        setServiceData("")
        setIsSubmitting(false)
        setServiceId("")
        setServiceData("")
        setSorting({ order_in: -1, order_by: "_created_at" })
        setSelectedDate({ from_date: null, to_date: null })
        setExportsData([])
        setSelectedItem([])
        setFileLoading(false)
        setOrderDetail("")
        setOrderId("")
    }

    return (
        <SidebarContext.Provider
            value={{
                editModal,
                setEditModal,
                isUpdate,
                setIsUpdate,
                currentPage,
                setcurrentPage,
                handleChangePage,
                searchRef,
                modalOpen,
                setModalOpen,
                loading,
                setLoading,
                branchId,
                setBranchId,
                deleteModal,
                setDeleteModal,
                isSubmitting,
                setIsSubmitting,
                serviceId,
                setServiceId,
                serviceData,
                setServiceData,
                totalCount,
                setTotalCount,
                itemsPerPage,
                maxPageNumberLimit,
                setmaxPageNumberLimit,
                minPageNumberLimit,
                setminPageNumberLimit,
                sorting,
                setSorting,
                handleSorting,
                exportsData,
                setExportsData,
                toggleExportModal,
                selectedDate,
                setSelectedDate,
                selectedItem,
                setSelectedItem,
                handleSelectItem,
                fileLoading,
                setFileLoading,
                exportModal,
                setExportModal,
                actionPermissionModal,
                setActionPermissionModal,
                exportPermissionModal,
                setExportPermissionModal,
                walletModal,
                setWalletModal,
                orderDetails,
                setOrderDetail,
                orderId,
                setOrderId,
                dispatch,
                toast,
                csvLink,
                resetForm,
                handleSubmit,
                showOTPModal,
                setShowOTPModal
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};
