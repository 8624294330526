import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { addNotification } from '../../Redux/action/App';
import { getUserForSearchBar } from '../../Redux/action/User';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { default as ReactSelect } from "react-select";
import { selectOptionStyle } from '../../Helper';


function PushNotification(props) {
    const { setLoading } = props
    const dispatch = useDispatch()
    const [inputValue, setValue] = useState('');
    const [selectedDriver, setSelectedDriver] = useState('');
    const [drivers, setDrivers] = useState([])

    useEffect(() => {
        getSearchedDrivers();
    }, [inputValue])

    const formik = useFormik({
        initialValues: {
            user_type: "Driver",
            is_active: true,
            is_online: true,
            is_available: true,
            message: "",
            users: []
        },
        validationSchema: Yup.object({
            message: Yup.string()
                .required("This field is required")
                .min(5, 'Message must be at least 5 characters long'),
        }),
        onSubmit: async values => {
            const fieldValues = values
            setLoading(true)
            await dispatch(addNotification(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == '1') {
                            toast.success(response.data?.message)
                            formik.resetForm();
                            setSelectedDriver('')
                        } else {
                            toast.error(response.data?.message)
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                    }
                )
        },
    });

    const handleChange = (event) => {
        let { name, value } = event.target
        value = JSON.parse(value)
        formik.setFieldValue(name, value)
    }

    const handleSelectDriver = (options) => {
        setSelectedDriver(options);
        const selectedUsers = []
        options.map(item => selectedUsers.push(item.value))
        formik.setFieldValue('users', selectedUsers)
    }

    // load options using API call to get Drivers
    const getSearchedDrivers = async () => {
        if (!inputValue) {
            setDrivers([])
            return;
        }
        const digitNumbers = /^-?[\+|\d.]+(?:e-?\d+)?$/
        const payload = {
            search: {
                id: '',
                type: formik.values.user_type,
                nickname: '',
                username: "",
                phone: ''
            },
            shorting: { order_in: "asc", order_by: "nickname" }
        }
        if (digitNumbers.test(inputValue)) {
            let phoneNumber = inputValue.toString();
            if (phoneNumber[0] === "+") {
                phoneNumber = phoneNumber.substring(1)
            }
            payload.search.phone = phoneNumber
        }
        else {
            payload.search.nickname = inputValue
        }
        if (payload.search.nickname || payload.search.phone) {
            await dispatch(getUserForSearchBar(payload))
                .then(
                    response => {
                        if (response.status === "1") {
                            const allUsers = response.data.users
                            const alldrivers = []
                            allUsers.forEach(item => {
                                const { nickname, username, phone } = item
                                const labelwithPhone = `${nickname ? nickname : username} (${phone})`
                                alldrivers.push({ label: labelwithPhone, value: item._id })
                            })
                            setDrivers(alldrivers)
                        } else {
                            setDrivers([])
                        }
                    },
                    error => {
                        setDrivers([])
                        toast.error(error.response.data?.message)
                    }
                )
        }
    };


    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Create Message</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={formik.handleSubmit} >
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>User Type</label>
                                                    <select name='user_type' className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.user_type}>
                                                        <option value="Buyer">Buyer</option>
                                                        <option value="Driver">Driver</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label className="control-label">User Name</label>
                                                    <ReactSelect
                                                        isClearable={true}
                                                        isMulti={true}
                                                        value={selectedDriver}
                                                        options={drivers}
                                                        onInputChange={(value) => setValue(value?.trim())}
                                                        onChange={(option) => handleSelectDriver(option)}
                                                        placeholder="Search"
                                                        styles={selectOptionStyle}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Active</label>
                                                    <select name='is_active' className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.is_active}>
                                                        <option value={true}>Yes</option>
                                                        <option value={false} >No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Online</label>
                                                    <select name='is_online' className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.is_online}>
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Available</label>
                                                    <select name='is_available' className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.is_available}>
                                                        <option value={true} >Yes</option>
                                                        <option value={false}>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Message</label>
                                                    <textarea
                                                        className="form-control"
                                                        type="text"
                                                        name='message'
                                                        placeholder='Enter the message'
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.message}
                                                        rows={4} />
                                                    {formik.touched.message && formik.errors.message ? (<span className="text-danger">{formik.errors.message}</span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-success">Send Message</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(PushNotification))