import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { addMaqsam, AddNewShop, setFormCurrentStep } from '../../Redux/action/Shop';



const webUrl = /^(?=.{4,2048}$)((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]{1,63}(\.[a-zA-Z]{1,63}){1,5}(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

function SocialLink(props) {
    const { setLoading } = props
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams()

    const {
        userInfo,
        shopInfo,
        shopOtherInfo,
        socialurl,
        openingHours,
        shopImage,
        shopOtherImages,
        maqsam
    } = useSelector(state => state.shop)


    useEffect(() => {
        if (maqsam) {
            if (maqsam.share_location_customer_url) {
                formik.setFieldValue('share_location_customer_url', maqsam.share_location_customer_url, false)
            }
            if (maqsam.share_location_driver_url) {
                formik.setFieldValue('share_location_customer_url', maqsam.share_location_driver_url, false)
            }
            if (maqsam.accept_order_driver_url) {
                formik.setFieldValue('share_location_customer_url', maqsam.accept_order_driver_url, false)
            }
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            share_location_customer_url: '',
            share_location_driver_url: '',
            accept_order_driver_url: ''
        },

        validationSchema: Yup.object({
            share_location_customer_url: Yup.string()
                .matches(webUrl, 'Website should be a valid URL!'),

            share_location_driver_url: Yup.string()
                .matches(webUrl, 'Website should be a valid URL!'),

            accept_order_driver_url: Yup.string()
                .matches(webUrl, 'Website should be a valid URL!'),
        }),

        onSubmit: async values => {
            let fieldValues = values
            dispatch(addMaqsam(fieldValues));
            const payload = {
                ...userInfo,
                ...shopInfo,
                ...shopOtherInfo,
                ...shopImage,
                hours: openingHours,
                otherimages: [...shopOtherImages],
                sociallinks: { ...socialurl },
                ...fieldValues
            }
            if (params.id) {
                payload['parent_id'] = params.id   // TO create Shop's Branches with shop ID as parent_id
            }
            setLoading(true)
            await dispatch(AddNewShop(payload))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status === '1') {
                            toast.success(response.data.message)
                            formik.resetForm();
                            if (params.id) {
                                history.push(`/shop-branches/${params.id}`)
                            } else {
                                history.push('/shops')
                            }
                        } else {
                            toast.error(response.data?.error[0])
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                    }
                )
        }
    });

    const handlePrevious = () => {
        dispatch(setFormCurrentStep("SHOP_OTHER_IMAGE"))
    }
    return (
        <div className="card-body">
            <div className="tab-content" id="custom-tabs-one-tabContent">
                <div className="tab-pane fade active show " id="custom-maqsam" role="tabpanel" aria-labelledby="maqsam-tab">
                    <form onSubmit={formik.handleSubmit}  >
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Maqsam share location customer URL</label>
                                    <input type="text" className="form-control"
                                        name='share_location_customer_url'
                                        placeholder='Enter URL'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.share_location_customer_url}
                                    />
                                    {formik.touched.share_location_customer_url && formik.errors.share_location_customer_url ? (<span className="text-danger">{formik.errors.share_location_customer_url}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Maqsam share location driver URL</label>
                                    <input type="text" className="form-control"
                                        name='share_location_driver_url'
                                        placeholder='Enter URL'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.share_location_driver_url}
                                    />
                                    {formik.touched.share_location_driver_url && formik.errors.share_location_driver_url ? (<span className="text-danger">{formik.errors.share_location_driver_url}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Maqsam accept order driver URL</label>
                                    <input type="text" className="form-control"
                                        name='accept_order_driver_url'
                                        placeholder='Enter URL'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.accept_order_driver_url}
                                    />
                                    {formik.touched.accept_order_driver_url && formik.errors.accept_order_driver_url ? (<span className="text-danger">{formik.errors.accept_order_driver_url}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="pb-5 footer-step">
                            <button type='button' className="btn btn-primary mr-2" onClick={handlePrevious} >Back</button>
                            <button type='submit' className="btn btn-success">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(SocialLink))