import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { getMainCategories } from '../../Redux/action/Category';
import Pagination from '../../Components/Pagination';
import { Link, useLocation } from 'react-router-dom';
import { trimObjValues } from '../../Helper';
import TableFooter from '../../Components/TableFooter';
import { SidebarContext } from './../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';


function MainCategory(props) {
   const { isLoading, setLoading } = props
   const location = useLocation()
   const [categories, setcategories] = useState([])
   const [sorting, setSorting] = useState({ order_in: 1, order_by: "category" })
   const [searchParams, setSearchParams] = useState({ _id: "", category: "", num: "", active: "" })
   const {
      dispatch,
      totalCount,
      toast,
      setTotalCount,
      currentPage,
      resetForm,
      handleSubmit,
      setcurrentPage,
      itemsPerPage,
      maxPageNumberLimit,
      setmaxPageNumberLimit,
      minPageNumberLimit,
      setminPageNumberLimit,
   } = useContext(SidebarContext)

   useLayoutEffect(() => {
      if (location.state && location.state.currentPage) {
         setcurrentPage(location.state.currentPage)
      }
   }, [])

   useEffect(() => {
      getMainCategoriesData();
   }, [currentPage, sorting])

   const getMainCategoriesData = async () => {
      const trimmedSearchParams = trimObjValues(searchParams)
      const payload = {
         search: { ...trimmedSearchParams },
         shorting: { order_in: sorting.order_in, order_by: sorting.order_by },
         pagination: { start: currentPage, length: itemsPerPage }
      }
      setLoading(true)
      await dispatch(getMainCategories(payload))
         .then(
            response => {
               setLoading(false);
               if (response.status === "1") {
                  setcategories(response.data.categories)
                  setTotalCount(response.data.total_count)
               } else {
                  setcategories([])
                  setTotalCount(null)
               }
            },
            (error) => {
               setLoading(false);
               toast.error(error.response.data?.message)
            }
         )
   }

   const handleSearch = (e) => {
      const { name, value } = e.target
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value,
      }));
   }

   const handleSelect = (e) => {
      const { name, value } = e.target
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value,
      }));
   }

   const handleReset = async () => {
      const resetFormData = { _id: "", category: "", num: "", active: "" }
      setSearchParams(resetFormData)
      resetForm();
      setSorting({ order_in: 1, order_by: "category" })
   }

   const handleSorting = (orderby) => {
      setSorting((prevState) => ({
         ...prevState,
         order_by: orderby,
         order_in: prevState.order_in === -1 ? 1 : -1
      }));
   }

   return (
      <div className="content-wrapper">
         <div className="content-header main_breadcrumb_top">
            <div className="container-fluid">
               <div className="row mb-2">
                  <div className="col-sm-6">
                     <h1 className="m-0">Main Categories</h1>
                  </div>
                  <div className="col-sm-6">
                     <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active">Main Categories</li>
                     </ol>
                  </div>
               </div>
            </div>
         </div>
         <section className="content">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="all_drivers">
                        <div className="card card-dark">
                           <div className="card-header">
                              <div className="container-fluid">
                                 <div className="row">
                                    <div className="col-6 col-lg-11">
                                       <h3 className="pl-5">Search</h3>
                                    </div>
                                    <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                       <i className="fas fa-angle-up click_search"></i>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <form className="form_search" action="">
                              <div className="card-body">
                                 <div className="form-group">
                                    <label>Category name</label>
                                    <input
                                       type="text"
                                       name="category"
                                       onChange={handleSearch}
                                       value={searchParams.category}
                                       className="form-control"
                                       placeholder="Enter name" />
                                 </div>
                                 <div className="form-group">
                                    <label>Block number</label>
                                    <input
                                       type="text"
                                       name="num"
                                       onChange={handleSearch}
                                       value={searchParams.num}
                                       className="form-control"
                                       placeholder="Enter block number" />
                                 </div>
                                 <div className="form-group">
                                    <label>Active</label>
                                    <select
                                       className="form-control"
                                       value={searchParams.active}
                                       onChange={handleSelect}
                                       name="active" >
                                       <option value="" >All</option>
                                       <option value="true">Yes</option>
                                       <option value="false">No</option>
                                    </select>
                                 </div>
                              </div>
                              <div className="card-footer text-center">
                                 <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                 <button type="button" onClick={()=>handleSubmit(getMainCategoriesData)} className="btn btn-success">Submit</button>
                              </div>
                           </form>
                        </div>
                        <div className="row pagination_new">
                           <div className="col-lg-6">
                              <Link to="/create-main-category"> <button type="add" className="btn btn-primary"><i className="fa-regular fa-circle-plus"></i>Create</button></Link>
                           </div>
                           {categories?.length > 0 &&
                              <div className="col-lg-6 col-12">
                                 <Pagination
                                    totalCount={totalCount}
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    maxPageNumberLimit={maxPageNumberLimit}
                                    minPageNumberLimit={minPageNumberLimit}
                                    setcurrentPage={setcurrentPage}
                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                    setminPageNumberLimit={setminPageNumberLimit}
                                 />
                              </div>
                           }
                        </div>
                        {categories?.length > 0 &&
                           <React.Fragment>
                              <div className="card table_card completed_1">
                                 <div className="card-body all_drive_table">
                                    <table id="example2" className="table table-hover">
                                       <thead>
                                          <tr>
                                             <th>Sr. No</th>
                                             <th>Category
                                                {sorting.order_by === "category" ?
                                                   <i onClick={() => handleSorting("category")}
                                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                   </i>
                                                   : <i onClick={() => handleSorting("category")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                }
                                             </th>
                                             <th>Block
                                                {sorting.order_by === "num" ?
                                                   <i onClick={() => handleSorting("num")}
                                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                   </i>
                                                   : <i onClick={() => handleSorting("num")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                }
                                             </th>
                                             <th>Active</th>
                                             <th>Action</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          {categories.map((item, index) =>
                                          (<tr key={item._id} >
                                             <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                             <td>{item.category ? item.category : "Not Available"}</td>
                                             <td>{item.num ? item.num : "NA"}</td>
                                             <td><span className={item.active ? 'yes_1' : item.active === false ? 'no_1' : "not_avl"} >{item.active ? 'Yes' : item.active === false ? "No" : 'NA'}</span></td>
                                             <td>
                                                <div className="action_button">
                                                   <div className="btn-group">
                                                      <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                         <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                      </button>
                                                      <div className="dropdown-menu">
                                                         <Link className="dropdown-item" to={{
                                                            pathname: `/edit-main-category/${item._id}`,
                                                            state: { currentPage: currentPage }
                                                         }}>
                                                            <span className="blue_1">
                                                               <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span>
                                                         </Link>
                                                         <div className="dropdown-divider"></div>
                                                         <Link to={`/sub-categories/${item._id}`} className="dropdown-item" >
                                                            <i className="fa fa-list" aria-hidden="true"></i>Sub categories
                                                         </Link>
                                                         <div className="dropdown-divider"></div>
                                                         <span className="dropdown-item"><span className="red_1">
                                                            <i className="fa fa-trash" aria-hidden="true"></i>Delete</span></span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>)
                                          )}
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                              <div className="row pagination_new">
                                 <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={categories} totalCount={totalCount} />
                                 <div className="col-lg-4">
                                    <Pagination
                                       totalCount={totalCount}
                                       currentPage={currentPage}
                                       itemsPerPage={itemsPerPage}
                                       maxPageNumberLimit={maxPageNumberLimit}
                                       minPageNumberLimit={minPageNumberLimit}
                                       setcurrentPage={setcurrentPage}
                                       setmaxPageNumberLimit={setmaxPageNumberLimit}
                                       setminPageNumberLimit={setminPageNumberLimit}
                                    />
                                 </div>
                              </div>
                           </React.Fragment>}
                        {categories?.length === 0 && !isLoading && <NotFoundData />}
                     </div>
                  </div>
               </div>
            </div>
         </section >
      </div >
   )
}
export default IsLoadingHOC(IsLoggedinHOC(MainCategory))