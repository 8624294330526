import React, { useState, useEffect, useContext } from 'react';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { getOrderPushNotifications } from '../../Redux/action/Orders';
import { getOrderDetails } from '../../Redux/action/Orders';
import Pagination from '../../Components/Pagination';
import { Link, useParams } from 'react-router-dom';
import { getFormatedDate } from '../../Helper';
import TableFooter from '../../Components/TableFooter';
import { SidebarContext } from './../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';


function PushMessages(props) {
    const { isLoading, setLoading } = props
    const params = useParams()
    const [pushedMessages, setPushedMessages] = useState([])
    const [sorting] = useState({ order_in: -1, order_by: "_created_at" })
    const [orderDetail, setOrderDetail] = useState({})
    const { ordernumber } = orderDetail ? orderDetail : {}
    const {
        dispatch,
        totalCount,
        toast,
        setTotalCount,
        currentPage,
        setcurrentPage,
        itemsPerPage,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
    } = useContext(SidebarContext)

    useEffect(() => {
        getPushedMessagesData();
    }, [currentPage])

    useEffect(() => {
        getOrderDetailData();
    }, [])

    const getPushedMessagesData = async () => {
        const payload = {
            order_id: params.id,
            shorting: sorting,
            pagination: { start: currentPage, length: itemsPerPage }
        }
        setLoading(true)
        await dispatch(getOrderPushNotifications(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setPushedMessages(response.data?.order_notificaitons)
                        setTotalCount(response.data?.total_count)
                    } else {
                        toast.error(response.data?.message)
                        setPushedMessages([])
                        setTotalCount(null)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                    setPushedMessages([])
                    setTotalCount(null)
                }
            )
    }

    const getOrderDetailData = async () => {
        const payload = { order_id: params.id }
        setLoading(true)
        await dispatch(getOrderDetails(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        const resData = (response.data && response.data.order) || {}
                        setOrderDetail(resData)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header main_breadcrumb_top">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Pushed Messages {`(${ordernumber || ''})`}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Messages</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="all_drivers">
                                    <div className="card card-dark">
                                        <div className="card-header">
                                            <div className="container-fluid">
                                                <div className="row">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pagination_new">
                                        <div className="col-lg-6 col-12">
                                            <Link to={`/open-orders`}><button disabled={isLoading} className="btn btn-primary"><i className="fa-solid fa-arrow-left"></i>Back</button></Link>
                                            <button disabled={isLoading} type="button" className="btn btn-secondary ml-2" onClick={getPushedMessagesData}><i className={`fa-regular fa-rotate-right ${isLoading && 'fa_spinner'}`}></i>Refresh</button>
                                        </div>
                                        {pushedMessages?.length > 0 &&
                                            <div className="col-lg-6 col-12">
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    maxPageNumberLimit={maxPageNumberLimit}
                                                    minPageNumberLimit={minPageNumberLimit}
                                                    setcurrentPage={setcurrentPage}
                                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                    setminPageNumberLimit={setminPageNumberLimit}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {pushedMessages?.length > 0 &&
                                        <React.Fragment>
                                            <div className="card table_card">
                                                <div className="card-body all_drive_table">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr. No</th>
                                                                <th>Push ID</th>
                                                                <th>Driver Name</th>
                                                                <th>Driver Phone</th>
                                                                <th>Shop(is VIP)</th>
                                                                <th>Exclude VIPs</th>
                                                                <th>Geo Fence</th>
                                                                <th>Device Type</th>
                                                                <th>Push Status</th>
                                                                <th>Created At</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {pushedMessages.map((item, i) => (
                                                                <tr key={item._id} >
                                                                    <td>{(currentPage - 1) * 10 + i + 1}</td>
                                                                    <td>{item.push_id}</td>
                                                                    <td>{item.user.username || item.user.nickname || "Not Available"}</td>
                                                                    <td>{item.user.phone || "Not Available"}</td>
                                                                    <td><span className={item.is_vip ? 'yes_1' : item.is_vip === false ? 'no_1' : "not_avl"} >{item.is_vip ? 'Yes' : item.is_vip === false ? "No" : 'NA'}</span></td>
                                                                    <td><span className={item.exclude_vips ? 'yes_1' : item.exclude_vips === false ? 'no_1' : "not_avl"} >{item.exclude_vips ? 'Yes' : item.exclude_vips === false ? "No" : 'NA'}</span></td>
                                                                    <td>{item.geofence || "Not Available"}</td>
                                                                    <td>{item.user.osType || "Not Available"}</td>
                                                                    <td>{item.status || "Not Available"}</td>
                                                                    <td>{getFormatedDate(item._created_at)}</td>
                                                                </tr>)
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row pagination_new">
                                                <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={pushedMessages} totalCount={totalCount} />
                                                <div className="col-lg-4">
                                                    <Pagination
                                                        totalCount={totalCount}
                                                        currentPage={currentPage}
                                                        itemsPerPage={itemsPerPage}
                                                        maxPageNumberLimit={maxPageNumberLimit}
                                                        minPageNumberLimit={minPageNumberLimit}
                                                        setcurrentPage={setcurrentPage}
                                                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                        setminPageNumberLimit={setminPageNumberLimit}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {pushedMessages?.length === 0 && !isLoading && <NotFoundData />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default IsLoadingHOC(IsLoggedinHOC(PushMessages))