import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { phoneRegExp, countryCode } from '../../Config/constants';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addShopSubUser } from '../../Redux/action/Shop';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function AddShopAdmin(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const { isLoading, modal, closeModal, getUserList, setLoading } = props
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const formik = useFormik({
        initialValues: {
            phone: '',
            username: '',
            email: '',
            password: '',
            confirm_password: '',
            parent_id: params?.id
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required("This field is required")
                .min(3, 'User name must be at least 3 characters long'),

            phone: Yup.string()
                .required("This field is required")
                .matches(phoneRegExp, 'Phone number is not valid'),

            email: Yup.string()
                .required("This field is required"),

            password: Yup.string()
                .required("This field is required")
                .min(5, 'Password must be at least 5 characters long'),

            confirm_password: Yup.string()
                .required("This field is required")
                .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),

        }),

        onSubmit: async values => {
            let fieldValues = { ...values }
            let phoneNumber = fieldValues.phone.toString();
            if (phoneNumber[0] === "+") {
                phoneNumber = phoneNumber.substring(1)
            }
            let phoneWithCountryCode = `${countryCode}${phoneNumber}`;
            fieldValues.phone = phoneWithCountryCode;
            setLoading(true)
            await dispatch(addShopSubUser(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == '1') {
                            toast.success(response.data.message)
                            formik.resetForm();
                            closeModal()
                            getUserList()
                        } else {
                            toast.error(response.data.error[0])
                        }
                    },
                    (error) => {
                        setLoading(false);
                        toast.error(error.response.data?.message)
                    }
                ).catch(error => console.log(error))
        },
    });

    return (
        <div id="myModal" className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}
            role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit} >
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-info" id="exampleModalLongTitle">Add shop sub user</h5>
                            <button onClick={() => closeModal()} type="button"
                                className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">Name</label>
                                        <input className="form-control" placeholder='Name'
                                            type="text"
                                            name='username'
                                            required
                                            value={formik.values.username}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.touched.username && formik.errors.username ? (<span className="text-danger">{formik.errors.username}</span>) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required ">
                                        <label className="control-label">Phone</label>
                                        <div className="input-group">
                                            <div className="input-group-append">
                                                <span className="input-group-text">{countryCode}</span>
                                            </div>
                                            <input
                                                required
                                                name="phone"
                                                onChange={formik.handleChange}
                                                value={formik.values.phone}
                                                onBlur={formik.handleBlur}
                                                type="text" className="form-control" placeholder="Phone" />
                                        </div>
                                        {formik.touched.phone && formik.errors.phone ? (<span className="text-danger">{formik.errors.phone}</span>) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">Email</label>
                                        <input className="form-control" placeholder='Email'
                                            type="email"
                                            name='email'
                                            required
                                            value={formik.values.email}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.touched.email && formik.errors.email ? (<span className="text-danger">{formik.errors.email}</span>) : null}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="form-group required">
                                        <label className="control-label">Password</label>
                                        <input className="form-control" placeholder="Password"
                                            required
                                            type={showPassword ? 'text' : 'password'}
                                            name='password'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password} />
                                        <i className={showPassword ? "fas fa-eye" : " fas fa-eye-slash"} onClick={() => setShowPassword(!showPassword)} id="eye"></i>
                                        {formik.touched.password && formik.errors.password ? (<span className="text-danger">{formik.errors.password}</span>) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">Confirm Password</label>
                                        <input className="form-control" placeholder="Confirm password"
                                            required
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name='confirm_password'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.confirm_password} />
                                        <i className={showConfirmPassword ? "fas fa-eye" : " fas fa-eye-slash"} id="eye" onClick={() => setShowConfirmPassword(!showConfirmPassword)} ></i>
                                        {formik.touched.confirm_password && formik.errors.confirm_password ? (<span className="text-danger">{formik.errors.confirm_password}</span>) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" disabled={isLoading} className="btn btn-success">{isLoading ? "Loading..." : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default AddShopAdmin