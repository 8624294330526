import React from 'react'

function PrivacyPolicy() {
    return (
        <div className="container-fluid login_box_container public-bg">
            <div className="container mb-3 mt-3 pb-3 pt-3 ">
                <div className='row'>
                    <div className="col-md-12 mx-auto">
                        <div className="myform form shadow p-3 mb-3 bg-light rounded">
                            <div className="mt-2">
                                <h3 className='text-center p-2 bg-info'> Privacy Policy of Utrac Delivery Application</h3>
                                <p>This privacy policy governs your use of the software application Utrac Delivery (“Application”) provided by Sardinia Delivery Services Co.- Utrac Delivery.</p>
                                <p>This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online.
                                    PII is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context.
                                    Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our Application.</p>
                                <p> Utrac Driver collects location data to track order and link each order with the closest driver for their service user. when the app is closed or not in use and
                                    designed to provide you with live tracking of deliveries on route to you with automatic real-time notifications.</p>
                            </div>
                            <h4 className='p-2 mt-4 bg-secondary'>What personal information do we collect and how do we use it?</h4>
                            <p>When you use our Application, we collect personal information (i.e. information that could be used to contact you directly such as full name, phone number, or email address) (“Personal Information”)
                                and demographic information (i.e. information that you submit, or that we collect on registration, that is not personal information; this may include, but is not limited to, ID card, hometown, gender, username, device information, including the type of mobile device you use,
                                a unique device identifier (for example, your device's IMEI number, the MAC address of the device's wireless network interface, or the mobile phone number used by the device), mobile network information, your mobile operating system, the type of mobile browser you use,
                                time zone setting, device location, IP address, and registration information) (“Demographic Information” and, together with Personal Information, “Personal Data”).</p>
                            <p>We also use GPS technology to determine your current location. Some of our location-enabled Services require your personal data for the feature to work. If you wish to use the particular feature, you will be asked to consent to your data being used for this purpose.</p>
                            <p>Utrac Driver collects location data to track order and link each order with the closest driver for their service user. when the app is closed or not in use</p>
                            <p>For operation and maintenance purposes, and to allow us to better service you in responding to your customer service requests, this application and any third party services it uses may collect files that record application errors (Crash Reports).
                                Maintenance Information we collect may be stored or processed on computers located in any country where we do business.</p>
                            <h4 className='p-2 mt-4 bg-secondary' >Do we share captured information?</h4>
                            <p>In order to serve you, we may share collected Crash Reports with third party services to provide crash resolution remedies. Their use of information is limited to these purposes, and subject to agreements that require them to keep the information confidential.</p>
                            <p>if Sardinia Delivery Services Co. is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of this information,
                                as well as any choices you may have regarding this information.</p>
                            <h4 className='p-2 mt-4 bg-secondary'>Do we use Ad tracking?</h4>
                            <p>We do not use Ads or cookies.</p>
                            <h4 className='p-2 mt-4 bg-secondary'>Your Consent :</h4>
                            <p>By using the Application, you are consenting to our processing of your information as set forth in this Privacy Policy now and as amended by us.</p>
                            <h4 className='p-2 mt-4 bg-secondary'>Changes :</h4>
                            <p>This privacy policy was last updated on [2017-7-13]. Our privacy policy may change from time to time. If we make any material changes to our policies,
                                 we will place a prominent notice on our website or application. If the change materially affects registered users, we will send a notice to your organization.</p>
                            <div className='d-flex pt-4 flex-column'>
                                <h4 className='p-2 mt-4 bg-secondary'>Contacting Us:</h4>
                                <div className='d-flex flex-column'>
                                    <a href='https://www.facebook.com/profile.php?id=100086573296478&mibextid=ZbWKwL' target="_blank" rel="noopener noreferrer"><p className='text-primary'>https://www.facebook.com</p></a>
                                    <span><b>Tel : </b>00966-11-5119696</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
