import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useJsApiLoader, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import driverMarker from '../../assets/dist/img/active_drivermarker.png';
import { toast } from "react-toastify";
import IsLoadingHOC from "../../Components/IsLoadingHOC";
import IsLoggedinHOC from "../../Components/IsLoggedinHOC";
import { getUsersList } from "../../Redux/action/User";
import { mapCenter } from "../../Config/constants";

const libraries = ['places'];
const mapContainerStyle = { width: "100%", height: "100%" };


function DriversLocation(props) {
    const dispatch = useDispatch();
    const { setLoading } = props;
    const DSPdrivers = useSelector(state => state.auth.user.drivers)
    const { user } = useSelector(state => state.auth)
    const {own_drivers} = user
    const [center, setCenter] = useState(mapCenter);
    const [activeDrivers, setActiveDrivers] = useState([]);
    const [map, setMap] = useState(null);
    const [isMouseOver, setMouseOver] = useState(false);
    const [driverMarkerInfo, setDriverMarkerInfo] = useState(null);
    const [searchParams, setSearchParams] = useState({ _id: "", type: "Driver", online: "", nickname: "", username: "", phone: "", active: "", verified: "", available: "" })
    const [finalSearchParams, setFinalSearchParams] = useState({ _id: "", type: "Driver", online: "", nickname: "", username: "", phone: "", active: "", verified: "", available: "" })

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    }, [libraries])

    useEffect(() => {
        getDriversLocation();
    }, [finalSearchParams])


    const getDriversLocation = async () => {
        const requestPayload = finalSearchParams
        if (user.type === "Logistics") {
            if (DSPdrivers.length > 0) {
              Object.assign(requestPayload, { _id: DSPdrivers?.join(',') })
            }
            else {
              return;
            }
          }
          if (user.type === 'Shop') {
            if (own_drivers?.length > 0) {
              Object.assign(requestPayload, { _id: own_drivers?.join(',') })
            }
            else {
              return;
            }
          }
        const payLoad = {
            search: finalSearchParams,
            shorting: { order_in: 1, order_by: "username" },
        }
        setLoading(true)
        await dispatch(getUsersList(payLoad))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const resData = response.data.users
                        let activeDrivers = []
                        resData.forEach(item => {
                            if (item.type === 'Driver') {
                                const drivers = {
                                    location: item.location,
                                    phone: item.phone,
                                    nickname: item.nickname || item.username
                                }
                                activeDrivers.push(drivers)
                            }
                        })
                        if (activeDrivers && (activeDrivers.length >= 1)) {
                            const latitude = activeDrivers[0]?.location[0]
                            const longitude = activeDrivers[0]?.location[1]
                            setCenter({ lat: latitude, lng: longitude })
                        }
                        setActiveDrivers(activeDrivers)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    const handleMouseOver = (data) => {
        setMouseOver(true);
        const { location, nickname, phone } = data
        const position = { lat: location[0], lng: location[1] }
        setDriverMarkerInfo({ nickname, phone, position })
    };

    const handleInfoWindowClose = () => {
        setMouseOver(false);
        setDriverMarkerInfo(null)
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setSearchParams((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (searchParams.nickname || searchParams.phone) {
            setFinalSearchParams(searchParams);
        }
    }

    const handleReset = () => {
        if (searchParams.nickname || searchParams.phone) {
            setFinalSearchParams({
                _id: user.type === "Logistics" ? DSPdrivers.join(',') : "", type: "Driver", online: "true", nickname: "", username: "", phone: "", active: "", verified: "", available: ""
            });
            setSearchParams({ _id: user.type === "Logistics" ? DSPdrivers.join(',') : "", type: "Driver", online: "true", nickname: "", username: "", phone: "", active: "", verified: "", available: "" });
        }
    }

    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="all_drivers">
                                <div className="card card-dark">
                                    <div className="card-header">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-6 col-lg-11">
                                                    <h3 className="pl-5">Search</h3>
                                                </div>
                                                <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                    <i className="fas fa-angle-up click_search"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit} className="form_search" action="">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label>Name</label>
                                                <input className="form-control" type="text" placeholder="Enter name" name="nickname" value={searchParams.nickname} onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Phone</label>
                                                <input className="form-control" min={0} type="number" placeholder="Enter phone" name="phone" value={searchParams.phone} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="card-footer text-center">
                                            <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="all_drivers_map">
                                    {isLoaded && center &&
                                        <GoogleMap
                                            center={center}
                                            zoom={13}
                                            mapContainerStyle={mapContainerStyle}
                                            onLoad={map => setMap(map)}
                                        >
                                            {((activeDrivers && activeDrivers.length > 0)) &&
                                                activeDrivers.map((item, i) =>
                                                    item.location && item.location[0] && item.location[1] &&
                                                    <Marker
                                                        key={i + 1}
                                                        position={{ lat: item?.location[0], lng: item?.location[1] }}
                                                        icon={driverMarker}
                                                        onMouseOver={() => handleMouseOver(item)}
                                                    />
                                                )}
                                            {isMouseOver && driverMarkerInfo && (
                                                <InfoWindow
                                                    position={driverMarkerInfo.position}
                                                    onCloseClick={handleInfoWindowClose}
                                                    className="info_window"
                                                >
                                                    <div>
                                                        <h6>{driverMarkerInfo.nickname}</h6>
                                                    </div>
                                                </InfoWindow>
                                            )}
                                        </GoogleMap>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default IsLoadingHOC(IsLoggedinHOC(DriversLocation))
