import React from 'react'

function AboutApp() {
    return (
        <div className="container-fluid login_box_container public-bg">
            <div className="container mb-3 mt-3 pb-3 pt-3">
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <div className="myform form shadow p-3 mb-3 bg-light rounded">
                            <div className="mt-3">
                            <h3 className='text-center p-2 bg-info'>Utrac Delivery provides you with live tracking of deliveries on route to you with automatic real-time notifications. </h3>
                            <h4 className='text-center p-2 mt-4 bg-secondary mb-4'>Food, Balloons, Chocolate, dry-clean, gifts, … etc. Anything ! </h4>
                            </div>
                            <h4 className='text-info mt-4'>Shop</h4>
                            <p>Add Utrac pickup & delivery operations to your business. Overcome challenges of on-time deliveries and enhance your productivity by outsourcing your pickup and deliveries to Utrac Delivery. Improved delivery success and experience, live tracking feature and automated real-time customer notifications. </p>
                            
                            <h4 className='text-info mt-4'>Drivers</h4>
                            <p>Become a Utrac Driver and earn additional income. Accept pushed orders and Utrac will guide you through the rest. Start today! </p>
                            
                            <h4 className='text-info mt-4'>Customers</h4>
                            <p>Live tracking of deliveries on route to you with automatic real-time notifications.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutApp
