
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { getUtracDeliveryZoneFee, getDeliveryZoneFee } from '../../Redux/action/DeliveryZone';
import { updateScheduleOrder, getScheduleOrderDetail, reScheduleOrder } from '../../Redux/action/Orders';
import { getFavoriteLocation } from '../../Redux/action/Favorite';
import { getUserForSearchBar } from '../../Redux/action/User';
import { getShopBranches } from '../../Redux/action/Shop';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { updateBuyerDetail } from '../../Redux/action/App';
import { useDispatch, useSelector } from 'react-redux';
import { getAreas } from '../../Redux/action/Area';
import { default as ReactSelect } from "react-select";
import AddFavLocation from '../../Components/BuyerModal/AddFavLocation';
import EditFavLocation from '../../Components/BuyerModal/EditFavLocation';
import CreateBuyer from '../../Components/BuyerModal/CreateBuyer';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import { selectOptionStyle, subtractHours } from '../../Helper';
import { debounce } from 'lodash';
import { phoneRegExp, countryCode } from '../../Config/constants';
import { OrderContext } from '../../Context/OrderContext';
import moment from 'moment-timezone';
import * as Yup from 'yup';


const time_zone = process.env.REACT_APP_TIMEZONE

function EditScheduleOrder(props) {
    const { setLoading, isLoading } = props
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const pathName = location?.pathname?.split('/');
    const { user } = useSelector(state => state.auth);
    const { _id, nickname } = user ? user : {}
    const [selectedShop, setSelectedShop] = useState("");
    const [selectedArea, setSelectedArea] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [favoriteLocations, setFavoriteLocations] = useState([]);
    const [selectedFavLocation, setSelectedFavLocation] = useState('');
    const { buyer } = useSelector(stete => stete.app);
    const [ownerShops, setOwnerShops] = useState([]);
    const [areas, setAreas] = useState([]);
    const [shops, setShops] = useState([])
    const [inputType, setInputType] = useState('by_self');
    const { buyer_id } = buyer ? buyer : {};
    const [selectedBranch, setSelectedBranch] = useState("")


    const {
        addFavLocModal,
        setAddFavLocModal,
        editFavLocModal,
        setEditFavLocModal,
        createBuyerModal,
        setCreateBuyerModal,
        shopFetching,
        setShopFetching
    } = useContext(OrderContext)


    useEffect(() => {
        getAreasData();
        getScheduleOrderDetailsData();
    }, [])

    const getShopBranchesData = async (selected_branchId) => {
        const payload = {
            search: { user_id: _id ? _id : '' },
            shorting: { order_in: -1, order_by: "_created_at" }
        }
        setLoading(true)
        await dispatch(getShopBranches(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const result = (response.data && response.data.branches) || []
                        const branches = []
                        result.map(item => branches.push({ value: item.branch_shop_id, label: item.nickname }))
                        setOwnerShops(branches)
                        // If schedule Order has been created for other branch by user shop Owner
                        if (selected_branchId) {
                            branches.forEach(option => {
                                if (selected_branchId === option.value) {
                                    let shop_id = option.value
                                    formik.setFieldValue("shop_id", shop_id)
                                    setSelectedShop(option);
                                }
                            })
                        }
                    } else {
                        toast.error(response.data.error[0])
                        setOwnerShops([])
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                    console.log(error)
                }
            )
    }

    const formik = useFormik({
        initialValues: {
            shedule_order_id: params.id,
            shop_id: '',
            buyer_phone: '',
            buyer_id: '',
            buyer_name: '',
            area_id: '',
            favorite_location_id: '',
            order_price: '0',
            delivery_fee: '0',
            utrack_fee: '0',
            tax_fee: '0',
            distance: '',
            payment_method: '',
            schedule_time: '',
            status: "",
            shop_ordernumber: "",
            note: "",
            requested_vehicle_type: "all"
        },
        validationSchema: Yup.object({
            shop_id: Yup.string()
                .required("This fiels is required"),

            buyer_phone: Yup.string()
                .required("This field is required")
                .matches(phoneRegExp, 'Phone number is not valid'),

            area_id: Yup.string()
                .required("This field is required"),

            payment_method: Yup.string()
                .required("This field is required"),

            order_price: Yup.number()
                .typeError('You must specify a number')
                .min(0, "Please enter a positive number")
                .required("This field is required"),

            schedule_time: Yup.string()
                .required("This field is required"),
        }),
        onSubmit: async values => {
            const fieldValues = { ...values }
            if (buyer_id) {
                Object.assign(fieldValues, { buyer_id: buyer_id });
            }
            let phoneNumber = fieldValues.buyer_phone.toString();
            let phoneWithCountryCode = `${countryCode}${phoneNumber}`;
            fieldValues.buyer_phone = phoneWithCountryCode;
            setLoading(true)
            await dispatch(pathName[1] === 'edit-schedule-order' ? updateScheduleOrder(fieldValues) : reScheduleOrder(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status === '1') {
                            toast.success(response.data.message)
                            formik.resetForm();
                            if (pathName[1] === 'edit-schedule-order') {
                                history.push('/scheduled-orders')
                            }
                            else {
                                history.push('/open-orders')
                            }
                        } else {
                            toast.error(response.data?.error[0])
                        }
                    },
                    error => {
                        toast.error(error.response.data?.message)
                        setLoading(false)
                    }
                )
        }
    });

    // loaded options using API call with searched Shops
    const getSearchedShops = async (searchvalue) => {
        const payload = {
            search: { type: "Shop", nickname: searchvalue, username: "" },
            shorting: { order_in: "asc", order_by: "nickname" }
        }
        setShopFetching(true)
        await dispatch(getUserForSearchBar(payload))
            .then(
                response => {
                    setShopFetching(false)
                    if (response.status === "1") {
                        const resData = response.data.users
                        const allShops = []
                        resData.forEach(item => {
                            const { _id, nickname } = item
                            allShops.push({ label: nickname, value: _id })
                        })
                        setShops(allShops)
                    } else {
                        setShops([])
                    }
                },
                error => {
                    setShops([])
                    setShopFetching(false)
                    toast.error(error.response.data?.message)
                }
            )
    };

    const getShopsData = useCallback(debounce((searchvalue) => getSearchedShops(searchvalue), 500), []);
    const handleSearchShop = (inputValue) => {
        const value = inputValue.trim()
        if (value && value.length > 0) {
            getShopsData(value);
        }
    }

    const getAreasData = async () => {
        const payload = {
            search: { _id: "", name: "" },
            shorting: { order_in: 1, order_by: "name_en" },
        }
        setLoading(true)
        await dispatch(getAreas(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const result = (response.data && response.data.areas) || []
                        const areasData = []
                        result.forEach(item => {
                            areasData.push({ label: item.name_combine, value: item._id })
                        })
                        setAreas(areasData)
                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                error => {
                    toast.error(error.response.data?.message)
                    setLoading(false)
                }
            )
            .catch(err => setLoading(false))
    }

    function convertUTCToLocalDate(date) {
        let hours = time_zone === 'Asia/Riyadh' ? 3 : 5.5
        if (!date) {
            return date
        }
        date = new Date(date)
        let newdate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes())
        let milliSec_time = newdate.getTime();
        let timeZone = milliSec_time + hours * 60 * 60 * 1000;
        let finalDateTime = new Date(timeZone);
        return date
    }

    const getScheduleOrderDetailsData = async () => {
        const payload = { order_id: params.id }
        setLoading(true)
        await dispatch(getScheduleOrderDetail(payload))
            .then(
                async response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const order = (response.data && response.data.order) || {}
                        const mapping = {
                            shop_id: 'shopid',
                            buyer_phone: 'buyerphone',
                            buyer_id: 'buyerid',
                            buyer_name: 'receiver_name',
                            delivery_fee: 'deliveryfee',
                            utrack_fee: 'utrackfee',
                            area_id: 'area_id',
                            distance: 'distance',
                            payment_method: 'paymentmethod',
                            schedule_time: 'schedule_time',
                            shop_ordernumber: 'shop_ordernumber',
                            requested_vehicle_type : 'requested_vehicle_type'
                        };
                        Object.keys(mapping).forEach((field) => formik.setFieldValue(field, order[mapping[field]] || ''));
                        let phoneNumber = order.buyerphone.toString();
                        let withoutCountryPhone = phoneNumber?.replace(countryCode, '');
                        formik.setFieldValue('buyer_phone', withoutCountryPhone)
                        formik.setFieldValue('order_price', order.orderprice?.toString())
                        formik.setFieldValue('tax_fee', order.tax_fee?.toString())
                        formik.setFieldValue('tax_fee', order.taxfee?.toString())

                        if (order.shopname && order.shopid) {
                            getSearchedShops(order.shopname)
                            setSelectedShop({ label: order.shopname?.trim(), value: order.shopid })
                        }
                        if (order.receiver_address && order.area_id) {
                            setSelectedArea({ label: order.receiver_address, value: order.area_id })
                        }
                        if (order.schedule_time) {
                            setSelectedDate(convertUTCToLocalDate(order.schedule_time))
                        }
                        if (!order._p_buyerfavlocation || order._p_buyerfavlocation === 'NA') {
                            formik.setFieldValue('favorite_location_id', '')
                        } else {
                            formik.setFieldValue('favorite_location_id', order._p_buyerfavlocation)
                        }
                        if (order.buyerid) {
                            getFavoriteLocationData(order.buyerid, order._p_buyerfavlocation)
                            dispatch(updateBuyerDetail({ buyer_id: order.buyerid }))
                        }
                        if (order?.order_notes && order?.order_notes[0]?.message_description) {
                            formik.setFieldValue('note', order?.order_notes[0]?.message_description)
                        }
                        if (user.type === "Shop") {
                            if (_id === order.shopid) {
                                let options = []
                                options.push({ label: nickname, value: _id })
                                setOwnerShops(options)
                                setSelectedShop(options[0])
                                // formik.setFieldValue("shop_id", _id)
                            }
                            else {
                                let selected_branchId = order.shopid
                                await getShopBranchesData(selected_branchId)
                                setInputType("other_branches")
                                setSelectedBranch(selected_branchId)
                            }
                        }
                    } else {
                        toast.error(response.data.error[0])
                    }
                }, error => {
                    setLoading(false)
                    console.log(error)
                }
            )
    }

    const getFavoriteLocationData = async (buyer_id, buyerfavlocationId) => {
        if (buyer_id) {
            const payload = {
                search: { title: "", locationarea: "", locationtitle: "", _p_user: buyer_id },
                shorting: { order_in: -1, order_by: "_created_at" },
                pagination: { start: 1, length: 100 }
            }
            setLoading(true);
            await dispatch(getFavoriteLocation(payload))
                .then(
                    response => {
                        setLoading(false);
                        if (response.status === "1") {
                            const result = (response.data && response.data.favorite_locations) || []
                            const favoriteLocations = []
                            let fulladdress = ''
                            result.forEach(item => {
                                fulladdress = ''
                                if (item.street) {
                                    fulladdress += item.street + ' '
                                }
                                if (item.building) {
                                    fulladdress += item.building + ' '
                                }
                                if (item.floor) {
                                    fulladdress += item.floor + ' '
                                }
                                if (item.apartment) {
                                    fulladdress += item.apartment + ' '
                                }
                                if (item.locationtitle) {
                                    fulladdress += item.locationtitle + ' '
                                }
                                if (item.locationarea) {
                                    fulladdress += item.locationarea
                                }
                                favoriteLocations.push({ label: fulladdress, value: item._id })
                            })
                            setFavoriteLocations(favoriteLocations)
                            // If Favlocation already selected on Create time schedule order
                            favoriteLocations.forEach(option => {
                                if (option.value === buyerfavlocationId) {
                                    setSelectedFavLocation(option)
                                }
                            })
                        }
                        else {
                            setFavoriteLocations([])
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                        setFavoriteLocations([])
                    }
                )
        }
    }

    const getBuyerDetailByPhone = async () => {
        let phoneNumber = formik.values.buyer_phone.toString();
        if (!phoneNumber) {
            toast.error('Phone number is required');
            return;
        } else if (!phoneRegExp.test(phoneNumber)) {
            toast.error('Phone number is not valid!');
            return;
        }
        if (phoneNumber[0] === "+") {
            phoneNumber = phoneNumber.substring(1)
        }
        const payload = {
            search: { type: "Buyer", phone: phoneNumber, username: "" },
            shorting: { order_in: "asc", order_by: "nickname" }
        }
        setLoading(true)
        await dispatch(getUserForSearchBar(payload))
            .then(async response => {
                setLoading(false)
                if (response.status === "1") {
                    const user = (response.data && response.data.users && response.data.users[0]) || {}
                    const { _id, nickname, username } = user ? user : {}
                    formik.setFieldValue("buyer_name", nickname ? nickname : username)
                    await dispatch(updateBuyerDetail({ buyer_id: _id }))
                    await getFavoriteLocationData(_id);
                } else {
                    toast.error('Buyer details is not found')
                    formik.setFieldValue("buyer_name", '')
                    dispatch(updateBuyerDetail({ buyer_id: '' }))
                    setCreateBuyerModal(true)
                }
            },
                error => {
                    toast.error(error.response.data?.message)
                    setLoading(false)
                    console.log(error)
                }
            )
    };

    const getUtracFeeData = async (payload) => {
        setLoading(true);
        await dispatch(getUtracDeliveryZoneFee(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === '1') {
                        formik.setFieldValue('distance', response.data.distance?.toString())
                        const result = (response.data.delivery_zones) || {}
                        if (result && result._id) {
                            formik.setFieldValue('utrack_fee', result.utrac_fee?.toString())
                            formik.setFieldValue('delivery_fee', result.delivery_fee?.toString())
                            formik.setFieldValue('delivery_zone_id', result._id)
                            formik.setFieldValue('tax_fee', result.tax_fee?.toString())
                        } else {
                            formik.setFieldValue('utrack_fee', '')
                            formik.setFieldValue('delivery_fee', '')
                            formik.setFieldValue('delivery_zone_id', '')
                            formik.setFieldValue('tax_fee', "")
                        }
                    } else {
                        toast.error(response.data.error[0])
                    }
                }, error => {
                    setLoading(false)
                    console.log(error)
                }
            )
    }

    const getPickupDeliveryFee = async (shop_id, area_id, favorite_location_id) => {
        if (shop_id && (area_id || favorite_location_id)) {
            const payload = { shop_id, area_id, favorite_location_id }
            setLoading(true)
            await dispatch(getDeliveryZoneFee(payload))
                .then(
                    async response => {
                        setLoading(false);
                        if (response.status === '1') {
                            const result = (response.data.delivery_zones) || {}
                            formik.setFieldValue('distance', response.data?.distance?.toString())
                            if (result && result._id) {
                                formik.setFieldValue('utrack_fee', result.utrac_fee?.toString())
                                formik.setFieldValue('delivery_fee', result.delivery_fee?.toString())
                                formik.setFieldValue('delivery_zone_id', result._id)
                                formik.setFieldValue('tax_fee', result.tax_fee?.toString())
                            } else {
                                getUtracFeeData(payload)
                            }
                        } else {
                            toast.error(response.data.error[0])
                        }
                    }, error => {
                        setLoading(false)
                    }
                )
        }
    }

    const handleTypeChange = async (e) => {
        let favorite_location_id = formik.values.favorite_location_id
        let area_id = formik.values.area_id
        const { value } = e.target
        setInputType(value)
        if (value === 'by_self') {
            let options = []
            options.push({ label: nickname, value: _id })
            setOwnerShops(options)
            setSelectedShop(options[0])
            formik.setFieldValue("shop_id", _id)
            let shop_id = _id
            await getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
        } else {
            formik.setFieldValue("shop_id", "")
            setSelectedShop("")
            getShopBranchesData(selectedBranch)
        }
    }

    const handleAreaSelect = option => {
        let shop_id = formik.values.shop_id
        let favorite_location_id = formik.values.favorite_location_id
        if (option) {
            const { value } = option
            setSelectedArea(option)
            formik.setFieldValue('area_id', value);
            let area_id = value
            getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
        } else {
            setSelectedArea('')
            formik.setFieldValue('area_id', '');
            let area_id = ''
            getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
        }
    }

    const handleFavLocationSelect = async option => {
        let shop_id = formik.values.shop_id
        let area_id = formik.values.area_id
        if (option) {
            const { value } = option
            setSelectedFavLocation(option)
            formik.setFieldValue('favorite_location_id', value)
            let favorite_location_id = value
            getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
        } else {
            setSelectedFavLocation('')
            formik.setFieldValue('favorite_location_id', '')
            let favorite_location_id = ''
            getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
        }
    }

    const handleShopSelect = async (option) => {
        let favorite_location_id = formik.values.favorite_location_id
        let area_id = formik.values.area_id
        if (option) {
            let shop_id = option.value
            formik.setFieldValue("shop_id", shop_id)
            setSelectedShop(option);
            await getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
        } else {
            formik.setFieldValue("shop_id", "")
            setSelectedShop("");
            setShops([])
            let shop_id = ""
            await getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
        }
    }
    const selectShopByOwner = async (option) => {
        let favorite_location_id = formik.values.favorite_location_id
        let area_id = formik.values.area_id
        if (option) {
            let { value } = option
            formik.setFieldValue("shop_id", value)
            setSelectedShop(option);
            let shop_id = value
            await getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
        } else {
            formik.setFieldValue("shop_id", "")
            setSelectedShop("");
            let shop_id = ""
            await getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
        }
    }

    const handleChangeDateTime = (date) => {
        setSelectedDate(date);
        let result = subtractHours(date)
        let scheduledtime = moment(result).format("YYYY-MM-DD HH:mm:ss")
        formik.setFieldValue('schedule_time', scheduledtime)
    }

    const updateBuyerName = (buyerName) => {
        formik.setFieldValue('buyer_name', buyerName)
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header main_breadcrumb_top">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between mb-2">
                            <div>
                                <h1 className="m-0">Edit Schedule Order</h1>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    onClick={() => setAddFavLocModal(true)}
                                    type="button"
                                    disabled={!buyer_id}
                                    className="btn btn-primary mr-2"
                                    data-toggle="modal"
                                >
                                    <i className="fa-regular fa-circle-plus mr-2"></i>
                                    Add Location
                                </button>
                                {formik.values.favorite_location_id && (
                                    <button
                                        onClick={() => setEditFavLocModal(true)}
                                        type="button"
                                        disabled={!buyer_id}
                                        className="btn btn-primary"
                                        data-toggle="modal"
                                    >
                                        <i className="fa-regular fa-pen-to-square mr-2"></i>
                                        Edit Location
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <form onSubmit={formik.handleSubmit} >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <label className="control-label">Shop</label>
                                                        {user.type === "Shop" &&
                                                            <React.Fragment>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" style={{ marginLeft: '100px' }}
                                                                        name='Ordertype'
                                                                        type='radio'
                                                                        value='by_self'
                                                                        onChange={handleTypeChange}
                                                                        checked={inputType === 'by_self'}
                                                                    />
                                                                    <label className="form-check-label">Self</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input"
                                                                        name='Ordertype'
                                                                        type='radio'
                                                                        value='other_branches'
                                                                        checked={inputType === 'other_branches'}
                                                                        onChange={handleTypeChange}
                                                                    />
                                                                    <label className="form-check-label">Branch</label>
                                                                </div>
                                                            </React.Fragment>}
                                                        {user.type === "Admin" ?
                                                            <ReactSelect placeholder="Search"
                                                                isLoading={shopFetching}
                                                                isClearable={true}
                                                                value={selectedShop}
                                                                options={shops}
                                                                onInputChange={(value) => handleSearchShop(value)}
                                                                onChange={(option) => handleShopSelect(option)}
                                                                styles={selectOptionStyle}
                                                            /> :
                                                            <ReactSelect
                                                                options={ownerShops}
                                                                isClearable={inputType === 'other_branches'}
                                                                hideSelectedOptions={false}
                                                                value={selectedShop}
                                                                onChange={option => selectShopByOwner(option)}
                                                                styles={selectOptionStyle}
                                                                placeholder="Select" />
                                                        }
                                                        {formik.touched.shop_id && formik.errors.shop_id ? (<span className="text-danger">{formik.errors.shop_id}</span>) : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <label className="control-label">Buyer Phone</label>
                                                        <div className="input-group">
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">{countryCode}</span>
                                                            </div>
                                                            <input type="text" className="form-control"
                                                                placeholder="Phone"
                                                                name="buyer_phone"
                                                                required
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.buyer_phone}
                                                            />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-success" onClick={getBuyerDetailByPhone} type="button">Find</button>
                                                            </div>
                                                        </div>
                                                        {formik.touched.buyer_phone && formik.errors.buyer_phone ? (<span className="text-danger">{formik.errors.buyer_phone}</span>) : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">Buyer Name</label>
                                                        <input
                                                            name="buyer_name"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.buyer_name}
                                                            onBlur={formik.handleBlur}
                                                            placeholder='Name'
                                                            type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <label className="control-label">Buyer Area</label>
                                                        <ReactSelect
                                                            isClearable={true}
                                                            options={areas}
                                                            hideSelectedOptions={false}
                                                            value={selectedArea}
                                                            onChange={option => handleAreaSelect(option)}
                                                            styles={selectOptionStyle}
                                                            placeholder="Select"
                                                        />
                                                        {formik.touched.area_id && formik.errors.area_id ? (<span className="text-danger">{formik.errors.area_id}</span>) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <label className="control-label">Payment Method</label>
                                                        <select
                                                            className="form-control"
                                                            name='payment_method'
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.payment_method}
                                                        >
                                                            <option value=''>Select</option>
                                                            <option value="Cash" >Cash</option>
                                                            <option value="Credit" >Credit</option>
                                                            <option value="Visa Post" >Visa Post</option>
                                                        </select>
                                                        {formik.touched.payment_method && formik.errors.payment_method ? (<span className="text-danger ">{formik.errors.payment_method}</span>) : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <label className="control-label">Order Price</label>
                                                        <input
                                                            className="form-control"
                                                            name='order_price'
                                                            required
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.order_price}
                                                            placeholder='Price'
                                                            type="number"
                                                        />
                                                        {formik.touched.order_price && formik.errors.order_price ? (<span className="text-danger ">{formik.errors.order_price}</span>) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group required">
                                                        <label className="control-label">Schedule Time</label>
                                                        <DatePicker
                                                            className="form-control date-field ub-l cursor-pointer"
                                                            placeholderText="Select time"
                                                            dateFormat="yyyy-MM-dd HH:mm"
                                                            showIcon={selectedDate ? false : true}
                                                            isClearable={true}
                                                            highlightDates={new Date()}
                                                            selected={selectedDate}
                                                            minDate={new Date()}
                                                            onChange={date => handleChangeDateTime(date)}
                                                            dropdownMode="select"
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            showTimeSelect
                                                            timeIntervals={5}
                                                            timeFormat="HH:mm"
                                                        />
                                                        {formik.touched.schedule_time && formik.errors.schedule_time ? (<span className="text-danger">{formik.errors.schedule_time}</span>) : null}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">Buyer Favorite Location</label>
                                                        <ReactSelect
                                                            isClearable={true}
                                                            name='favorite_location_id'
                                                            options={favoriteLocations}
                                                            hideSelectedOptions={false}
                                                            value={selectedFavLocation}
                                                            onChange={(option) => handleFavLocationSelect(option)}
                                                            styles={selectOptionStyle}
                                                            placeholder="Select" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">Order Note</label>
                                                        <input
                                                            name='note'
                                                            onChange={formik.handleChange}
                                                            value={formik.values.note}
                                                            placeholder='Enter note'
                                                            type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">Order Number</label>
                                                        <input
                                                            name='shop_ordernumber'
                                                            onChange={formik.handleChange}
                                                            value={formik.values.shop_ordernumber}
                                                            placeholder='Order number'
                                                            type="text" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">Vehicle Type</label>
                                                        <select
                                                            className="form-control"
                                                            name='requested_vehicle_type'
                                                            onChange={formik.handleChange}
                                                            value={formik.values.requested_vehicle_type}
                                                        >
                                                            <option value=''>Select..</option>
                                                            <option value="all">All</option>
                                                            <option value="bike">Bike</option>
                                                            <option value="car">Car</option>
                                                            <option value="van">Van</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Distance(km)</label>
                                                        <input
                                                            disabled
                                                            value={formik.values.distance}
                                                            type="text" className="form-control"
                                                            placeholder='distance'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group required">
                                                        <label className="control-label">Utrac Fee</label>
                                                        <input
                                                            disabled
                                                            value={formik.values.utrack_fee}
                                                            placeholder='Utrac fee'
                                                            type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group required">
                                                        <label className="control-label">Delivery Fee</label>
                                                        <input
                                                            disabled
                                                            value={formik.values.delivery_fee}
                                                            placeholder='Delivery fee'
                                                            type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group required">
                                                        <label className="control-label">Tax Fee</label>
                                                        <input
                                                            disabled
                                                            value={formik.values.tax_fee}
                                                            placeholder='Tax fee'
                                                            type="text" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-foot-btn d-flex justify-content-center mt-3">
                                                <Link to='/scheduled-orders'><button type="button" className="btn btn-primary mr-2">Back</button></Link>
                                                <button type="submit" className="btn btn-success">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>

            {addFavLocModal &&
                <AddFavLocation
                    isLoading={isLoading}
                    setLoading={setLoading}
                    getFavoriteLocationData={getFavoriteLocationData}
                    modal={addFavLocModal}
                    closeModal={() => setAddFavLocModal(false)}
                />}

            {editFavLocModal &&
                <EditFavLocation
                    isLoading={isLoading}
                    setLoading={setLoading}
                    getFavoriteLocationData={getFavoriteLocationData}
                    modal={editFavLocModal}
                    closeModal={() => setEditFavLocModal(false)}
                    favLocation_id={formik.values.favorite_location_id}
                />}

            {createBuyerModal &&
                <CreateBuyer
                    isLoading={isLoading}
                    setLoading={setLoading}
                    buyer_phone={formik.values.buyer_phone}
                    buyer_name={formik.values.buyer_name}
                    modal={createBuyerModal}
                    closeModal={() => setCreateBuyerModal(false)}
                    updateBuyerName={updateBuyerName}
                />}
        </>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(EditScheduleOrder))