import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { phoneRegExp, countryCode } from '../../Config/constants';
import Select from 'react-select';
import { useSelector } from 'react-redux';


function EditBuyerModal(props) {
    const { isLoading, orderDetails, modal, closeModal, handleUpdateBuyerOrder } = props
    const { _id, buyerphone, receiver_name, ordernumber, orderprice, shop_direction, shop } = orderDetails ? orderDetails : {}
    const { userGlobalData } = useSelector(state => state.app)
    const { area_direction } = userGlobalData ? userGlobalData : []

    useEffect(() => {
        formik.setFieldValue('receiver_name', receiver_name)
        formik.setFieldValue('orderprice', orderprice)
        let phoneNumber = buyerphone.toString();
        let withoutCountryPhone = phoneNumber.replace(countryCode, '');
        formik.setFieldValue('buyerphone', withoutCountryPhone)
        if (withoutCountryPhone[0] === "+") {
            withoutCountryPhone = withoutCountryPhone.substring(1)
            formik.setFieldValue('buyerphone', withoutCountryPhone)
        }
        if (shop_direction && shop_direction !== "Not Available") {
            formik.setFieldValue('shop_direction', shop_direction)
        }
        else if (shop[0]?.market[0]?.shop_area[0]?.direction) {
            const shopDirection = shop[0]?.market[0]?.shop_area[0]?.direction
            formik.setFieldValue('shop_direction', shopDirection)
        }
    }, [_id])

    const formik = useFormik({
        initialValues: {
            buyerphone: '',
            receiver_name: '',
            orderprice: '',
            shop_direction: ''
        },
        validationSchema: Yup.object({
            receiver_name: Yup.string()
                .required("This field is required")
                .min(3, 'Name must be at least 3 characters long'),

            buyerphone: Yup.string()
                .required("This field is required")
                .matches(phoneRegExp, 'Phone number is not valid'),

            orderprice: Yup.number()
                .typeError('You must specify a number')
                .min(0, "Please enter a positive number")
                .required("This field is required"),
        }),

        onSubmit: async values => {
            let { receiver_name, buyerphone, orderprice, shop_direction } = values
            let phoneNumber = buyerphone.toString();
            let phoneWithCountryCode = `${countryCode}${phoneNumber}`;
            buyerphone = phoneWithCountryCode;
            handleUpdateBuyerOrder(_id, receiver_name, buyerphone, orderprice, shop_direction)
        }
    });

    const handleSelect = (option) => {
        if (option) {
            const { label } = option
            formik.setFieldValue('shop_direction', label)
        }
        else (
            formik.setFieldValue('shop_direction', '')
        )
    }

    return (
        <div id="myModal" className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}
            role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit} >
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Edit Buyer Order {`(${ordernumber})`}</h5>
                            <button onClick={() => closeModal()} type="button"
                                className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">Buyer Name</label>
                                        <input className="form-control" placeholder='Buyer name'
                                            type="text"
                                            name='receiver_name'
                                            required
                                            value={formik.values.receiver_name}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.touched.receiver_name && formik.errors.receiver_name ? (<span className="text-danger">{formik.errors.receiver_name}</span>) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required ">
                                        <label className="control-label">Buyer Phone</label>
                                        <div className="input-group">
                                            <div className="input-group-append">
                                                <span className="input-group-text">{countryCode}</span>
                                            </div>
                                            <input
                                                required
                                                name="buyerphone"
                                                onChange={formik.handleChange}
                                                value={formik.values.buyerphone}
                                                onBlur={formik.handleBlur}
                                                type="text" className="form-control" placeholder="Phone" />
                                        </div>
                                        {formik.touched.buyerphone && formik.errors.buyerphone ? (<span className="text-danger">{formik.errors.buyerphone}</span>) : null}
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Order Price</label>
                                        <input className="form-control" placeholder='Order Price'
                                            type="number"
                                            name='orderprice'
                                            value={formik.values.orderprice}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.touched.orderprice && formik.errors.orderprice ? (<span className="text-danger">{formik.errors.orderprice}</span>) : null}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="form-group">
                                        <label className="control-label">Shop Direction</label>
                                        <Select
                                            value={area_direction.find(option => option.label === formik.values.shop_direction)}
                                            onChange={handleSelect}
                                            options={area_direction}
                                            placeholder="Select Direction"
                                            isClearable={true}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    cursor: 'pointer'
                                                }),
                                                clearIndicator: (provided) => ({
                                                    ...provided,
                                                    cursor: 'pointer',
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="submit" disabled={isLoading} className="btn btn-success">{isLoading ? "Loading..." : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default EditBuyerModal