/* eslint-disable no-undef */
import moment from "moment";
import CryptoJS from 'crypto-js';
import { hashSecretKey, countryCode } from '../Config/constants';
import copy from 'copy-to-clipboard';
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const time_zone = process.env.REACT_APP_TIMEZONE

export function trimFileName(add, l = 14) {
    if (add.length > 12) {
        return (
            String(add).slice(0, 2) +
            String(add).slice(2, 2 + l) +
            "..." +
            String(add).slice(add.length - l, add.length)
        )
    } else {
        return add
    }
}

export function setOriginShopName(value) {
    if (value) {
        const shopfullName = value.split('(')
        if (shopfullName) {
            return shopfullName[0]
        }
    }
}

export function setAlternativeShopName(value) {
    if (value) {
        const shopfullName = value.split('(')
        if (shopfullName && shopfullName[1]) {
            return `(${shopfullName[1]}`
        }
    }
}

export function convertUTCToLocalDate(date) {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes())
    return date
}

export function convertLocalToUTCDate(date) {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()))
    return date
}

// Format Date Time with Time-Zone
export function getFormatedDate(date) {
    if (!date || date === 'Not Available') {
        return 'Not Available'
    }
    let Utc_time = moment(date)
    let formatedDate = Utc_time.tz(time_zone).format('DD MMM YYYY, hh:mm A');
    return formatedDate;
}

// Format Date Time with Time-Zone
export function getOnlyTime(date) {
    if (!date || date === 'Not Available') {
        return 'Not Available'
    }
    let Utc_time = moment(date)
    let formatedDate = Utc_time.tz(time_zone).format('hh:mm A');
    return formatedDate;
}

export function formatChatTimestamp(timestamp) {
    const currentMoment = moment();
    const messageMoment = moment(timestamp).tz(time_zone);

    if (currentMoment.isSame(messageMoment, 'day')) {
        return messageMoment.format('[Today at] h:mm A');
    }

    if (currentMoment.subtract(1, 'days').isSame(messageMoment, 'day')) {
        return messageMoment.format('[Yesterday at] h:mm A');
    }

    // Check if the message is within the last week
    // if (currentMoment.subtract(6, 'days').isBefore(messageMoment)) {
    //   return messageMoment.format('dddd h:mm A');
    // }

    return messageMoment.format('DD MMM, h:mm A');
};

export function getTimestamp(timestamp) {
    const currentMoment = moment();
    const messageMoment = moment(timestamp).tz(time_zone);

    if (currentMoment.isSame(messageMoment, 'day')) {
        return messageMoment.format('[Today]');
    }
    if (currentMoment.subtract(1, 'days').isSame(messageMoment, 'day')) {
        return messageMoment.format('[Yesterday]');
    }
    return messageMoment.format('DD MMM, h:mm A');
};

// Set Format Time without Time-zone
export function dateFormat(date) {
    if (!date || date === 'Not Available') {
        return 'Not Available'
    }
    let format_time = moment(date)?.format('DD MMM YYYY, hh:mm A')
    return format_time;
}

export function orderTrackingTime(date) {
    if (date && date !== "Not Available") {
        let format_time = moment(date).format('hh:mm A, MMM DD, YYYY')
        return format_time;
    }
}

export function orderStatusTime(order) {
    const dateFields = {
        created: '_created_at',
        'pending pickup': 'acceptdate',
        arrived: 'arrived_to_shop_date',
        picked: 'pickupdate',
        completed: 'deliverydate',
        canceled: 'canceldate',
    };

    if (order.status in dateFields) {
        const time = moment(order[dateFields[order.status]]);
        // return time.tz(time_zone).format('hh:mm A');
        return time.format('hh:mm A')
    }
}

export function getScheduledTime(scheduleDataTime) {
    if (!scheduleDataTime || scheduleDataTime === 'Not Available') {
        return 'Not Available'
    }
    // let Utc_time = moment.utc(scheduleDataTime).format('YYYY MMM DD hh:mm A');
    // let timeZone =  date.setHours(date.getHours() - hours);
    let hours = time_zone === 'Asia/Riyadh' ? 3 : 5.5
    let selected_time = new Date(convertUTCToLocalDate(scheduleDataTime));
    let milliSec_time = selected_time.getTime();
    let timeZone = milliSec_time + hours * 60 * 60 * 1000;
    let finalDateTime = new Date(timeZone);
    let formatedDateTime = moment(finalDateTime).format('YYYY MMM DD hh:mm A');
    return formatedDateTime;
}

export function getDiffTime(deliveryDateTime, CreatedDateTime) {
    // let diffTime = Math.abs(new Date().valueOf() - new Date('2021-11-22T18:30:00').valueOf());
    let diffTime = Math.abs(new Date(deliveryDateTime).getTime() - new Date(CreatedDateTime).getTime());
    let days = diffTime / (24 * 60 * 60 * 1000);
    let hours = (days % 1) * 24;
    let minutes = (hours % 1) * 60;
    let seconds = (minutes % 1) * 60;
    [days, hours, minutes, seconds] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(seconds)];
    let totalDays = days && days > 0 ? days + ' day,' : ''
    let totalHours = hours && hours > 0 ? hours + ' hour,' : '';
    let totalMinutes = minutes && minutes > 0 ? minutes + ' minute' : '';
    let final_result = `${totalDays} ${totalHours} ${totalMinutes}`;
    return final_result
}

export function subtractHours(date) {
    // let timeZone =  date.setHours(date.getHours() - hours);
    let hours = time_zone === 'Asia/Riyadh' ? 3 : 5.5
    let selected_time = new Date(date);
    let milliSec_time = selected_time.getTime();
    let timeZone = milliSec_time - hours * 60 * 60 * 1000;
    let finalDateTime = new Date(timeZone);
    return finalDateTime;
}

export function trimObjValues(obj) {
    return Object.keys(obj).reduce((acc, curr) => {
        acc[curr] = obj[curr]
        if (typeof obj[curr] === 'string') {
            acc[curr] = obj[curr].trim()
        }
        return acc;
    }, {});
}

export const trimNestedObjValues = obj => {
    if (obj && typeof obj === "object") {
        Object.keys(obj).forEach(key => {
            if (typeof obj[key] === "object") {
                trimNestedObjValues(obj[key]);
            } else if (typeof obj[key] === "string") {
                obj[key] = obj[key].trim();
            }
        });
    }
    return obj;
};

export const customStyles = {
    input: (provided, state) => ({ ...provided, margin: '-5px' }),
    menuPortal: provided => ({ ...provided, zIndex: 999 }),
    menu: provided => ({ ...provided, zIndex: 999 })
}

export const sortArrayByDate = (data) => {
    var sorted_array = data.sort((a, b) => {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    });
    return sorted_array
}

const getSortedKeys = (paramaters) => {
    let keys = Object.keys(paramaters);
    let keyssorted = keys.sort();
    let resdata = [];
    for (let k in keyssorted) {
        resdata[keyssorted[k]] = paramaters[keyssorted[k]];
    }
    return resdata;
};

export const getHashKey = (paramaters, secretKey = hashSecretKey + countryCode) => {
    if (paramaters.hash) {
        delete paramaters.hash;
    }
     let params = getSortedKeys(paramaters);
    let out = [];
    let i = 0;
    for (let k in params) {
        ++i;
        let keys = Object.keys(params);
        let nextIndex = keys.indexOf(k) + 1;
        let nextItem = keys[nextIndex];
        if (Object.keys(params).length == i) {
            out[k] = `${k}=${params[k]}${secretKey}`;
        } else if (!params[nextItem]) {
            out[k] = `${k}=${params[k]}`;
        } else {
            out[k] = `${k}=${secretKey}${params[k]}`;
        }
    }
    let outstr = Object.values(out).join("&");
    var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secretKey);
    hmac.update(outstr);
    var hash = hmac.finalize();
    let hashkey = hash.toString(CryptoJS.enc.Hex)
    return hashkey;
};

// export const getHashKey2 = (paramaters, secretKey = `${hashSecretKey}${countryCode}`) => {
//     // let crypto = require("crypto");
//     if (paramaters.hash) delete paramaters.hash;
//     let params = my_ksort(paramaters);
//     let out = [];
//     let i = 0;
//     for (let k in params) {
//         ++i;
//         let keys = Object.keys(params);
//         let nextIndex = keys.indexOf(k) + 1;
//         let nextItem = keys[nextIndex];
//         if (Object.keys(params).length == i) {
//             out[k] = `${k}=${params[k]}${secretKey}`;
//         } else if (!params[nextItem]) {
//             out[k] = `${k}=${params[k]}`;
//         } else {
//             out[k] = `${k}=${secretKey}${params[k]}`;
//         }
//     }
//     let outstr = Object.values(out).join("&");
//     let hash = crypto
//         .createHmac("sha256", secretKey)
//         .update(outstr)
//         .digest("hex");
//     return hash;
// };

export const isAllItemSelected = (resData, selectedItem) => {
    const allItemsValue = resData.map(item => item._id);
    return allItemsValue.every(item => selectedItem.includes(item));
}

export const handleSelectListItem = (event, resData, selectedItem, setSelectedItem) => {
    const is_checked = event.target.checked;
    if (is_checked) {
        const allItems = [...new Set([...selectedItem, ...resData.map(item => item._id)])];
        setSelectedItem(allItems);
    } else {
        const allItemsValue = new Set(resData.map(item => item._id));
        const newArray = selectedItem.filter(value => !allItemsValue.has(value));
        setSelectedItem(newArray);
    }
}

export const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString([], { month: 'long' });
}

export const isIntegratorNumber = (integratorNum) => {
    if (integratorNum === 'Not Available' || integratorNum === '' || integratorNum === "NA") {
        return "";
    }
    return (
        <div style={{ cursor: "pointer" }} title='Integrator-number'>
            <i className="fa-solid fa-user-large"></i>{integratorNum}
        </div>
    );
};

export const isShopOrderNumber = (shop_orderNumber) => {
    return shop_orderNumber
        ? shop_orderNumber === 'Not Available' || shop_orderNumber === '' || shop_orderNumber === 'NA'
            ? ""
            : (
                <div style={{ cursor: "pointer" }} title='Shop-order-number'>
                    <i className="fa-solid fa-user-large"></i>{shop_orderNumber}
                </div>
            )
        : null;
};

export const getOrderETA = (pickupEta, deliveryEta, orderStatus) => {
    const etaValue = (orderStatus === "pending pickup" ? pickupEta : deliveryEta);
    if (etaValue) {
        const etaMinutes = Math.round(etaValue / 60);
        return etaMinutes ? `${etaMinutes} min` : 'NA';
    } else {
        return 'NA';
    }
}

export const isOrderNotes = (order_notes) => {
    return Array.isArray(order_notes) && order_notes.length > 0;
}

export const selectOptionStyle = {
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer'
    }),
    clearIndicator: (provided) => ({
        ...provided,
        cursor: 'pointer',
    }),
    input: (provided, state) => ({ ...provided, margin: '-5px' }),
    menuPortal: provided => ({ ...provided, zIndex: 999 }),
    menu: provided => ({ ...provided, zIndex: 999 })
}

export const setCharacterLimit = (str = "") => {
    if (typeof str === 'string') {
        let result = str?.length > 30 ? `${str.substring(0, 30)}...` : str
        return result
    }
    return 'Not Available'
}

export const getBuyerLoaction = (item, length) => {
    if (item.favlocation && item.favlocation[0]?.locationarea) {
        return item.favlocation[0]?.locationarea;
    }
    else if (item.area && item.area[0]?.name) {
        return item.area[0]?.name;
    }
    else if (item.receiver_full_address) {
        if (length === 'limited') {
            let str = item.receiver_full_address || ""
            let result = str?.length > 30 ? `${str.substring(0, 30)}...` : str
            return result
        }
        else {
            return item.receiver_full_address;
        }
    }
    else {
        return "Not available"
    }
}

export const showShopDirection = (item) => {
    const shopDirection = item.shop_direction && item.shop_direction !== "Not Available"
        ? item.shop_direction.replace('_', ' ')
        : item?.shop?.[0]?.market?.[0]?.shop_area?.[0]?.direction || "Not Available";

    return shopDirection;
};

export const setDeliveryETA = (etaValue) => {
    if (etaValue === 0) {
        return '0 min'
    }
    else if (etaValue > 0) {
        const timeInMinutes = Math.round(etaValue / 60);
        const days = Math.floor(timeInMinutes / (60 * 24));
        const hours = Math.floor((timeInMinutes % (60 * 24)) / 60);
        const minutes = timeInMinutes % 60;
        const formatUnit = (value, unit) => (value > 0 ? `${value} ${unit}${value > 1 ? 's' : ''}` : '');
        const totalDays = formatUnit(days, 'day');
        const totalHours = formatUnit(hours, 'hr');
        const totalMinutes = formatUnit(minutes, 'min');
        return `${totalDays} ${totalHours} ${totalMinutes}`.trim();
    }
    return 'NA'
}

export const setStatusColor = (status) => {
    if (['created', 'CreateOrder', 'AssignDriver', 'AcceptOrder'].includes(status)) {
        return { color: "#FFBF00" }
    }
    else if (['ResumeOrder', 'hold'].includes(status)) {
        return { color: "#f79824" }
    }
    else if (status === 'pending pickup') {
        return { color: "#e8000e" }
    }
    else if (['arrived', 'ArriveToShop'].includes(status)) {
        return { color: "#FC6600" }
    }
    else if (['picked', 'PickOrder'].includes(status)) {
        return { color: "#662e9b" }
    }
    else if (['completed', 'CompleteOrder', 'online'].includes(status)) {
        return { color: "#005e0c" }
    }
    else if (['CancelOrder', 'canceled', 'offline'].includes(status)) {
        return { color: "#e8000e" }
    }
    else if (['login'].includes(status)) {
        return { color: "#17c3b2" }
    }

    else {
        return { color: "#70d6ff" }
    }

}

export const setCallout = (status) => {
    if (status === 'created') {
        return 'callout-info'
    }
    else if (status === 'hold') {
        return 'callout-warning'
    }
    else if (status === 'canceled') {
        return 'callout-danger'
    }
    else {
        return 'callout-info'
    }
}

export const addSpaceBeforeCapital = (str) => {
    if (str) {
        return str.replace(/([A-Z])/g, ' $1').trim();
    }
    return "Na"
}

export const checkWhatsAppMsg = (orderHistory) => {
    return orderHistory?.some(item => item.object_type === "whatsapp_message");
};

export const handleCopyClipboard = (textToCopy) => {
    if (textToCopy) {
        copy(textToCopy);
        toast.info('Copied to clipboard')
    }
};

export const getNestedValue = (obj, path) => {
    return path.split('.').reduce((value, key) => {
        return value && value[key] !== 'undefined' ? value[key] : null;
    }, obj);
};

// Export Json data into Excel sheet format
export const exportToExcel = (data , fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, fileName);
  };


// ------ GET CURRENT LOCATION ADDRESS AND LAT & LNG  ------- //
// export const successCallback = function (position) {
//     console.log("Position", position)
//     const lat = position.coords.latitude
//     const lng = position.coords.longitude
//     const latLng = { lat: lat, lng: lng }
//     var geocoder = new google.maps.Geocoder();
//      var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
//     geocoder.geocode({ 'latLng': latLng }, function (results, status) {
//         if (status === google.maps.GeocoderStatus.OK) {
//             if (results[1]) {
//                 map.setZoom(11);
//                 marker = new google.maps.Marker({
//                     position: latlng,
//                     map: map
//                 });
//                 infowindow.setContent(results[1].formatted_address);
//                 infowindow.open(map, marker);
//             }
//         } else {
//             alert("Geocoder failed due to: " + status);
//         }
//     });
// }
// async function playAudio() {
//     if (audioPlayer.current) {
//         await audioPlayer.current.play()
//             .then(res => {
//             })
//             .catch(error => {
//                 console.log("Audio_Play_Error", error)
//             })
//     }
// }


// function playAudio() {
//     // const audio = new Audio(NotificationSound)
//     // audio.play()
//     //     .then(res => {
//     //     })
//     //     .catch(error => {
//     //         console.log("Audio_Play_Error", error)
//     //     })

//     var audio = document.createElement("AUDIO")
//     document.body.appendChild(audio);
//     audio.src = NotificationSound
//     audio.play()
//     // document.body.addEventListener("onmessage", function () {
//     //     audio.play()
//     // })
// }