import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import Pagination from '../../Components/Pagination';
import { Link, useLocation } from 'react-router-dom';
import { getFormatedDate, trimObjValues } from '../../Helper';
import TableFooter from './../../Components/TableFooter/index';
import { SidebarContext } from '../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';
import { getWebhooklog } from '../../Redux/action/App';


function WebhookLog(props) {
    const { isLoading, setLoading } = props
    const location = useLocation()
    const [resData, setResData] = useState([])
    const [searchParams, setSearchParams] = useState({ name: "", status: '' })
    const [sorting, setSorting] = useState({ order_in: 1, order_by: "name" })

    const {
        dispatch,
        totalCount,
        toast,
        resetForm,
        setTotalCount,
        currentPage,
        handleSubmit,
        setcurrentPage,
        itemsPerPage,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
    } = useContext(SidebarContext)

    useLayoutEffect(() => {
        if (location.state && location.state.currentPage) {
            setcurrentPage(location.state.currentPage)
        }
    }, [])

    useEffect(() => {
        getWebhookLogData();
    }, [currentPage, sorting])

    const getWebhookLogData = async () => {
        const Search_params = trimObjValues(searchParams)
        const payload = {
            search: Search_params,
            shorting: sorting,
            pagination: { start: currentPage, length: itemsPerPage }
        }
        setLoading(true)
        await dispatch(getWebhooklog(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setResData(response.data.resData)
                        setTotalCount(response.data.total_count)
                    } else {
                        setResData([])
                        setTotalCount(null)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    const handleSearch = (e) => {
        const { name, value } = e.target
        setSearchParams((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleReset = async () => {
        const resetFormData = { name: "" }
        setSearchParams(resetFormData)
        resetForm()
        setSorting({ order_in: 1, order_by: "name" })
    }

    const handleSorting = (orderby) => {
        setSorting((prevState) => ({
            ...prevState,
            order_by: orderby,
            order_in: prevState.order_in === -1 ? 1 : -1
        }));
    }

    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Webhook Log</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Webhook</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="all_drivers">
                                <div className="card card-dark">
                                    <div className="card-header">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-6 col-lg-11">
                                                    <h3 className="pl-5">Search</h3>
                                                </div>
                                                <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                    <i className="fas fa-angle-up click_search"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form className="form_search" action="">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    onChange={handleSearch}
                                                    value={searchParams.name}
                                                    className="form-control"
                                                    placeholder="Enter name" />
                                            </div>
                                        </div>
                                        <div className="card-footer text-center">
                                            <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                            <button type="button" onClick={() => handleSubmit(getWebhookLogData)} className="btn btn-success">Submit</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="row pagination_new">
                                    {resData?.length > 0 &&
                                        <div className="col-lg-6 col-12">
                                            <Pagination
                                                totalCount={totalCount}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                maxPageNumberLimit={maxPageNumberLimit}
                                                minPageNumberLimit={minPageNumberLimit}
                                                setcurrentPage={setcurrentPage}
                                                setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                setminPageNumberLimit={setminPageNumberLimit}
                                            />
                                        </div>
                                    }
                                </div>
                                {resData?.length > 0 &&
                                    <React.Fragment>
                                        <div className="card table_card completed_1">
                                            <div className="card-body all_drive_table">
                                                <table id="example2" className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No</th>
                                                            <th>Name
                                                                {sorting.order_by === "name" ?
                                                                    <i onClick={() => handleSorting("name")}
                                                                        className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                    </i>
                                                                    : <i onClick={() => handleSorting("name")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                }
                                                            </th>
                                                            <th>Arabic Name</th>
                                                            <th>Status</th>
                                                            <th>Created At</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {resData.map((item, index) =>
                                                            <tr key={item._id} >
                                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                <td>{item.name ? item.name : "Not Available"}</td>
                                                                <td>{item.name_ar ? item.name_ar : "Not Available"}</td>
                                                                <td><span className={item.status ? 'yes_1' : item.active === false ? 'no_1' : "not_avl"} >{item.status ? 'Active' : item.status === false ? "Inactive" : 'Not available'}</span></td>
                                                                <td>{getFormatedDate(item._created_at)} </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row pagination_new">
                                            <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={resData} totalCount={totalCount} />
                                            <div className="col-lg-4">
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    maxPageNumberLimit={maxPageNumberLimit}
                                                    minPageNumberLimit={minPageNumberLimit}
                                                    setcurrentPage={setcurrentPage}
                                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                    setminPageNumberLimit={setminPageNumberLimit}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                {resData?.length === 0 && !isLoading && <NotFoundData />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(WebhookLog))