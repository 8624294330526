import React, { useState, useEffect } from "react";
import SelectDate from "../Form/Select/SelectDate";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getOrderAverageTime } from '../../Redux/action/Orders';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import moment from 'moment';


function RadialBarChart({ setLoading }) {
  const dispatch = useDispatch();
  var today = new Date();
  const [selectedDate, setSelectedDate] = useState(new Date().setDate(today.getDate() -1))
  const [series, setSeries] = useState([0, 0, 0, 0, 0])
  const { user } = useSelector(state => state.auth)
  const { _id, type } = user
  const { drivers } = user ? user : {}
  const logisticsDrivers = drivers ? drivers : []

  const [options, setOptions] = useState({
    chart: {
      height: 300,
      type: 'radialBar',
      background: '#fcfcfc'
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          total: {
            show: false,
            label: 'Total',
          },
          show: true,
          name: {
            show: true
          },
          value: {
            show: true,
            persentage: false
          }
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val
        // return parseInt(val)
      }
    },

    title: {
      text: `Per day orders average time`,
      align: 'left'
    },
    labels: ['Create to accept', 'Accept to arrive', 'Arrive to Pickup ', 'Accept to Pickup', 'Create to Deliver'],
  }
  )

  useEffect(() => {
    getOrderAverageTimeData()
  }, [selectedDate])

  const getOrderAverageTimeData = async () => {
    const formatedDate = moment(selectedDate).format('yyyy-MM-DD');
    let payload = {
      user_id: type === "Admin" ? "" : _id,
      date: formatedDate
    }
    if (["Logistics"].includes(user.type) && logisticsDrivers?.length > 0) {
      Object.assign(payload, { drivers: logisticsDrivers })
  }
    setLoading(true)
    await dispatch(getOrderAverageTime(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status == "1") {
            const resData = response.data?.orders
            if (resData && resData.length > 0) {
              let data = resData[0]
              let averageTime = [Math.round(data?.avg_create_accept_time), Math.round(data?.avg_accept_arive_time), Math.round(data?.avg_arive_pickup_time), Math.round(data?.avg_accept_pickup_time), Math.round(data?.avg_create_deliver_time)]
              setSeries(averageTime)
            }
            else {
              setSeries([0, 0, 0, 0, 0])
            }
          }
        }).catch(error => console.log(error))
  }

  const handleChangeDate = (date) => {
    setSelectedDate(date);
  }

  return (
    <div className="card chart-avgtime">
      <div className="card-body chart_card_body">
        <div className="row justify-content-end">
          <div className="col-lg-6 col-6">
            <form>
              <div className="form-group">
                <SelectDate selectedDate={selectedDate} handleChangeDate={handleChangeDate} />
              </div>
            </form>
          </div>
        </div>
        <ReactApexChart options={options} series={series} type="radialBar" height={300} />
      </div>
    </div>
  )
}

export default IsLoadingHOC(IsLoggedinHOC(RadialBarChart))
