import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sidebarMenu } from '../../utils/sidebarMenu';
import { updateUserAdmin } from '../../Redux/action/User';
import { toast } from 'react-toastify';


function MenuPermission(props) {
    const dispatch = useDispatch()
    const { setLoading, loader, modal, closeModal, userData, getListData } = props
    const { _id, username, allowed_menu_permission } = userData ? userData : {}
    const [permissions, setPermissions] = useState([]);


    useEffect(() => {
        if (allowed_menu_permission && allowed_menu_permission.length > 0) {
            setPermissions(allowed_menu_permission)
        }
    }, [])

    const handleCheckboxChange = (menuItem) => {
        setPermissions((prevPermissions) => {
            const isMenuChecked = prevPermissions.some(item => item.name === menuItem.name);

            if (isMenuChecked) {
                // If the menu item was previously checked, remove it from the array
                return prevPermissions.filter(item => item.name !== menuItem.name);
            } else {
                // If the menu item was not checked, add it to the array
                return [...prevPermissions, {
                    name: menuItem.name,
                    icon: menuItem.icon,
                    submenus: menuItem.submenus ? menuItem.submenus.map(submenu => ({ name: submenu.name, path: submenu.path })) : [],
                }];
            }
        });
    };

    const handleSubmenuCheckboxChange = (menuItem, submenu) => {
        setPermissions((prevPermissions) => {
            const newPermissions = [...prevPermissions];
            const menuIndex = newPermissions.findIndex(item => item.name === menuItem.name);

            if (menuIndex !== -1) {
                const submenuIndex = newPermissions[menuIndex].submenus.findIndex(item => item.name === submenu.name);

                if (submenuIndex !== -1) {
                    // If submenu was previously checked, uncheck and remove from the array
                    newPermissions[menuIndex].submenus.splice(submenuIndex, 1);
                } else {
                    // If submenu was not checked, check and add to the array
                    newPermissions[menuIndex].submenus.push({ name: submenu.name, path: submenu.path });
                }
            }

            return newPermissions;
        });
    };


    const handleSubmitData = async () => {
        const payload = userData
        Object.assign(payload, { user_id: _id, assign_roles: permissions })
        setLoading(true)
        await dispatch(updateUserAdmin(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status === '1') {
                        toast.success(response.data.message)
                        closeModal();
                        getListData();
                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            ).catch(error => console.log(error))
    }

    return (
        <div className={`modal fade cs--modal ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form>
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Allow menu permission</h5>
                            <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body overflow-auto modal--body--scroll">
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="form-group">
                                        {sidebarMenu?.map((menuItem, index) => (
                                            <div key={index + 1}>
                                                <div className="form-check select-user ml-2">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={permissions.some(item => item.name === menuItem.name)}
                                                        onChange={() => handleCheckboxChange(menuItem)}
                                                    />
                                                    <label className="control-label">{menuItem.name}</label>
                                                </div>
                                                <div className="card bg-light">
                                                    <div className="card-body">
                                                        <div className='row'>
                                                            {menuItem.submenus &&
                                                                menuItem.submenus.map((submenu, index2) => (
                                                                    <div key={index2 + 2} className="col-sm-3">
                                                                        <div className="form-check select-user">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                checked={permissions.some(item => item.name === menuItem.name && item.submenus.some(sub => sub.name === submenu.name))}
                                                                                onChange={() => handleSubmenuCheckboxChange(menuItem, submenu)}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="role">
                                                                                {submenu.name}
                                                                            </label>
                                                                        </div>

                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button disabled={loader} onClick={handleSubmitData} type="button" className="btn btn-success"> {loader ? "Processing.." : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default MenuPermission
