
import { authAxios } from "../../Config/axios"
import { trimObjValues } from "../../Helper"

export const getInvoicesList = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/invoice/list", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getBalanceSheet = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/invoice/balancesheet", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const createTxnByDriverId = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/invoice/create-txn", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}
