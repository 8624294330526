import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import ActionPermissionModal from '../../Components/PermissionModal/ActionPermissionModal';
import ExportPermissionModal from '../../Components/PermissionModal/ExportPermissionModal';
import OtherPermissionModal from '../../Components/PermissionModal/OtherPermissionModal';
import ExportModel from '../../Components/ConfirmModel/ExportData';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { getlogisticsListApi } from '../../Redux/action/User';
import Pagination from '../../Components/Pagination';
import DateInputs from '../../Components/DatePicker';
import { Link, useLocation, useHistory } from 'react-router-dom';
import TableFooter from '../../Components/TableFooter';
import { getCities } from '../../Redux/action/City';
import { saveCityData, setDspDriversId } from '../../Redux/action/App';

import { SidebarContext } from '../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';
import {
   exportToExcel,
   getFormatedDate,
   handleCopyClipboard,
   handleSelectListItem,
   isAllItemSelected,
   setCharacterLimit,
   trimObjValues
} from '../../Helper';
import moment from 'moment';
import { CSVLink } from 'react-csv';


// Export CSV File Headers
const headers = [
   { label: "Id", key: "_id" },
   { label: "User Name", key: "username" },
   { label: "Nick Name", key: "nickname" },
   { label: "Email", key: "email" },
   { label: "Phone", key: "phone" },
   { label: "City", key: "city" },
   { label: "Location Area", key: "locationarea" },
   { label: "Created At", key: "_created_at" },
];

function LogisticsUsers(props) {
   const { setLoading, isLoading } = props
   const location = useLocation()
   const history = useHistory()
   const [listData, setListData] = useState([])
   const [otherPermissionModal, setOtherPermissionModal] = useState(false)
   const [dspUserDetails, setDspUserDetails] = useState({})
   const {
      csvLink,
      dispatch,
      totalCount,
      toast,
      sorting,
      handleSorting,
      setTotalCount,
      resetForm,
      handleSubmit,
      exportModal,
      toggleExportModal,
      fileLoading,
      setFileLoading,
      selectedItem,
      exportsData,
      actionPermissionModal,
      setActionPermissionModal,
      exportPermissionModal,
      setExportPermissionModal,
      setExportsData,
      setSelectedItem,
      handleSelectItem,
      currentPage,
      setcurrentPage,
      selectedDate,
      setSelectedDate,
      itemsPerPage,
      maxPageNumberLimit,
      setmaxPageNumberLimit,
      minPageNumberLimit,
      setminPageNumberLimit,
   } = useContext(SidebarContext)

   const [searchParams, setSearchParams] = useState({
      nickname: "",
      username: "",
      email: "",
      from_date: "",
      to_date: ""
   })

   useEffect(() => {
      getCitiesData()
   }, [])

   const getCitiesData = async () => {
      const payload = {
         search: { id: "", name: "" },
         shorting: { order_in: sorting.order_in, order_by: sorting.order_by },
      }
      setLoading(true)
      await dispatch(getCities(payload))
         .then(
            response => {
               setLoading(false);
               if (response.status === "1") {
                  const cityData = []
                  const resData = response.data.cities
                  resData.map(item =>
                     cityData.push({ value: item._id, label: item.name })
                  )
                  dispatch(saveCityData(cityData))
               }
            },
            (error) => {
               setLoading(false);
               toast.error(error.response.data?.message)
            }
         )
   }

   useLayoutEffect(() => {
      if (location.state && location.state.currentPage) {
         setcurrentPage(location.state.currentPage)
      }
   }, [])

   useEffect(() => {
      getLogisticsData();
   }, [currentPage, sorting])

   const getLogisticsData = async () => {
      const trimmedSearchParams = trimObjValues(searchParams)
      const payload = {
         search: { user_search: { ...trimmedSearchParams } },
         shorting: { order_in: sorting.order_in, order_by: sorting.order_by },
         pagination: { start: currentPage, length: itemsPerPage }
      }
      setLoading(true)
      await dispatch(getlogisticsListApi(payload))
         .then(
            response => {
               setLoading(false);
               if (response.status === "1") {
                  setListData(response.data.users)
                  setTotalCount(response.data.total_count)
               } else {
                  setListData([])
                  setTotalCount(null)
               }
            },
            (error) => {
               setLoading(false);
               toast.error(error.response.data?.message)
            }
         )
   }

   const getExportingData = async () => {
      const trimmedSearchParams = trimObjValues(searchParams);
      const totalItem = [];

      const fetchLogisticsList = async (pagination) => {
         const payload = {
            search: { user_search: { ...trimmedSearchParams } },
            selected_items: pagination.start === 1 ? [...selectedItem] : [],
            shorting: { order_in: sorting.order_in, order_by: sorting.order_by },
            pagination: pagination
         };

         try {
            setFileLoading(true);
            const response = await dispatch(getlogisticsListApi(payload));
            setFileLoading(false);

            if (response.status === "1") {
               totalItem.push(...response.data.users);
            }
         } catch (error) {
            setFileLoading(false);
            toast.error(error.response?.data?.message);
         }
      };

      if (selectedItem && selectedItem.length > 0) {
         await fetchLogisticsList({ start: 1, length: 1000 });
      } else {
         const pages = Math.ceil(totalCount / 50000);
         for (let i = 1; i <= pages; i++) {
            await fetchLogisticsList({ start: i, length: 50000 });
         }
      }

      const exportData = totalItem?.map((item) => {
         const exportItem = {};
         headers.forEach(header => {
             exportItem[header.label] = item[header.key];
         });
         return exportItem;
     });
     const fileName = `DSP_Users-${moment(new Date()).format("hh-mm-ss")}.xlsx`
     exportToExcel(exportData, fileName);
      // setExportsData(totalItem);
      // csvLink.current.link.click();
      toast.success("Data exported successfully");
      toggleExportModal();
   };


   const handleSearch = (e) => {
      const { name, value } = e.target
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value,
      }));
   }

   const handleChangeDate = (date, name) => {
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: date ? moment(date).format("YYYY-MM-DD") : ''
      }))
      setSelectedDate((prevState) => ({
         ...prevState,
         [name]: date,
      }));
   }

   const handleReset = async () => {
      const resetFormData = {
         nickname: "",
         username: "",
         email: "",
         from_date: "",
         to_date: ""
      }
      setSearchParams(resetFormData)
      resetForm();
   }

   const showPermissionModal = (data, modalType) => {
      setDspUserDetails(data);
      switch (modalType) {
         case 'action':
            setActionPermissionModal(true);
            break;
         case 'export':
            setExportPermissionModal(true);
            break;
         case 'other':
            setOtherPermissionModal(true);
            break;
         default:
            break;
      }
   };

   return (
      <>
         <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
               <div className="container-fluid">
                  <div className="row mb-2">
                     <div className="col-sm-6">
                        <h1 className="m-0">Delivery Service Provider List</h1>
                     </div>
                     <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item active">Logistics</li>
                        </ol>
                     </div>
                  </div>
               </div>
            </div>
            <section className="content">
               <div className="container-fluid">
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="all_drivers">
                           <div className="card card-dark">
                              <div className="card-header">
                                 <div className="container-fluid">
                                    <div className="row">
                                       <div className="col-6 col-lg-11">
                                          <h3 className="pl-5">Search</h3>
                                       </div>
                                       <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                          <i className="fas fa-angle-up click_search"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <form className="form_search" action="">
                                 <div className="card-body">
                                    <div className="form-group">
                                       <label>User Name</label>
                                       <input
                                          type="text"
                                          name="username"
                                          onChange={handleSearch}
                                          value={searchParams.username}
                                          className="form-control"
                                          placeholder="User name" />
                                    </div>
                                    <div className="form-group">
                                       <label>Email</label>
                                       <input
                                          type="mail"
                                          name="email"
                                          onChange={handleSearch}
                                          value={searchParams.email}
                                          className="form-control"
                                          placeholder="Email ID" />
                                    </div>
                                    <DateInputs selectedDate={selectedDate} handleChangeDate={handleChangeDate} />
                                 </div>
                                 <div className="card-footer text-center">
                                    <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                    <button type="button" onClick={()=>handleSubmit(getLogisticsData)} className="btn btn-success">Submit</button>
                                 </div>
                              </form>
                           </div>
                           <div className="row pagination_new">
                              <div className="col-lg-6 col-12">
                                 <Link to="/user/create-logistic"> <button disabled={isLoading} type="add" className="btn btn-primary"><i className="fa-regular fa-circle-plus"></i>Create</button></Link>
                                 <button type='button' disabled={fileLoading || !totalCount} onClick={toggleExportModal} className="btn btn-info ml-2"><i className="fa-regular fa-circle-down"></i>{fileLoading ? "Loading.." : "Export"}</button>
                                 {/* <CSVLink
                                    className='csv-link'
                                    headers={headers}
                                    data={exportsData}
                                    filename={`DSP_Users_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss-A")}`}
                                    ref={csvLink}
                                    target='_blank'
                                 /> */}
                              </div>
                              {listData?.length > 0 &&
                                 <div className="col-lg-6 col-12">
                                    <Pagination
                                       totalCount={totalCount}
                                       currentPage={currentPage}
                                       itemsPerPage={itemsPerPage}
                                       maxPageNumberLimit={maxPageNumberLimit}
                                       minPageNumberLimit={minPageNumberLimit}
                                       setcurrentPage={setcurrentPage}
                                       setmaxPageNumberLimit={setmaxPageNumberLimit}
                                       setminPageNumberLimit={setminPageNumberLimit}
                                    />
                                 </div>
                              }
                           </div>
                           {listData?.length > 0 &&
                              <React.Fragment>
                                 <div className="card table_card completed_1">
                                    <div className="card-body all_drive_table">
                                       <table id="example2" className="table table-hover">
                                          <thead>
                                             <tr>
                                                <th><input type="checkbox" id="check" checked={isAllItemSelected(listData, selectedItem)} onChange={(event) => handleSelectListItem(event, listData, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th>
                                                <th >User Name
                                                   {sorting.order_by === "username" ?
                                                      <i onClick={() => handleSorting("username")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("username")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Email ID</th>
                                                <th>Phone</th>
                                                <th>City</th>
                                                <th>Location Area</th>
                                                <th> Created At
                                                   {sorting.order_by === "_created_at" ?
                                                      <i onClick={() => handleSorting("_created_at")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Action
                                                </th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {listData.map((item, index) => (
                                                <tr key={item._id} >
                                                   <td>
                                                      <input type="checkbox" className="user_table_checkbox"
                                                         onChange={(event) => handleSelectItem(event, item._id)}
                                                         checked={selectedItem.includes(item._id)} />
                                                   </td>
                                                   <td><span title={item?.username}>{setCharacterLimit(item?.username)}</span></td>
                                                   <td>{item.email ? item.email : "Not Available"}</td>
                                                   <td>{item.phone ? item.phone : "Not Available"}</td>
                                                   <td>{item.city ? item.city : "Not Available"}</td>
                                                   <td>{item.locationarea ? item.locationarea : "Not Available"}</td>
                                                   <td>{getFormatedDate(item._created_at)}
                                                   </td>
                                                   <td>
                                                      <div className="action_button">
                                                         <div className="btn-group">
                                                            <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                               <img src={require("../../assets/dist/img/action.png")} alt='action-png' />
                                                            </button>
                                                            <div className="dropdown-menu">
                                                               <span onClick={() => handleCopyClipboard(item._id)} className="dropdown-item"><span>
                                                                  <i className="fa-regular fa-copy"></i>Copy User Id</span>
                                                               </span>
                                                               <div className="dropdown-divider"></div>
                                                               <Link to={{ pathname: `/edit-logistic/${item._id}`, state: { currentPage: currentPage } }} className="dropdown-item"><span className="blue_1">
                                                                  <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span></Link>
                                                               <div className="dropdown-divider"></div>
                                                               <span className="dropdown-item" onClick={() => showPermissionModal(item, 'action')}>
                                                                  <i className="fa-sharp fa-regular fa-gear"></i>Action Permission</span>
                                                               <div className="dropdown-divider"></div>
                                                               <span className="dropdown-item" onClick={() => showPermissionModal(item, 'export')}>
                                                                  <i className="fa-regular fa-file-chart-column"></i>Export Permission</span>
                                                               <div className="dropdown-divider"></div>
                                                               <span className="dropdown-item" onClick={() => showPermissionModal(item, 'other')}>
                                                                  <i className="fa-regular fa-check-to-slot"></i>User Permission</span>
                                                               {item?.drivers?.length > 0 &&
                                                                  <React.Fragment>
                                                                     <div className="dropdown-divider"></div>
                                                                     <span onClick={() => {
                                                                        dispatch(setDspDriversId(item.drivers))
                                                                        history.push({ pathname: `/logistic-drivers/${item._id}` })
                                                                     }}
                                                                        className="dropdown-item"><span>
                                                                           <i className="fa-regular fa-id-card"></i>View Drivers</span></span>
                                                                  </React.Fragment>
                                                               }
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </td>
                                                </tr>)
                                             )}
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                                 <div className="row pagination_new">
                                    <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={listData} totalCount={totalCount} />
                                    <div className="col-lg-4">
                                       <Pagination
                                          totalCount={totalCount}
                                          currentPage={currentPage}
                                          itemsPerPage={itemsPerPage}
                                          maxPageNumberLimit={maxPageNumberLimit}
                                          minPageNumberLimit={minPageNumberLimit}
                                          setcurrentPage={setcurrentPage}
                                          setmaxPageNumberLimit={setmaxPageNumberLimit}
                                          setminPageNumberLimit={setminPageNumberLimit}
                                       />
                                    </div>
                                 </div>
                              </React.Fragment>
                           }
                           {listData?.length === 0 && !isLoading && <NotFoundData />}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
         {exportModal &&
            < ExportModel
               loader={fileLoading}
               exportModal={exportModal}
               toggleExportModal={toggleExportModal}
               getExportingData={getExportingData}
               totalCount={totalCount}
               selectedItem={selectedItem}
            />
         }
         {actionPermissionModal &&
            <ActionPermissionModal
               modal={actionPermissionModal}
               closeModal={() => setActionPermissionModal(false)}
               user={dspUserDetails}
               getUserList={getLogisticsData}
               setLoading={setLoading}
               isLoading={isLoading}
            />
         }
         {exportPermissionModal &&
            <ExportPermissionModal
               modal={exportPermissionModal}
               closeModal={() => setExportPermissionModal(false)}
               user={dspUserDetails}
               getUserList={getLogisticsData}
               setLoading={setLoading}
               isLoading={isLoading}
            />
         }
         {otherPermissionModal &&
            <OtherPermissionModal
               modal={otherPermissionModal}
               closeModal={() => setOtherPermissionModal(false)}
               user={dspUserDetails}
               getUserList={getLogisticsData}
               setLoading={setLoading}
               isLoading={isLoading}
            />
         }
      </>
   )
}

export default IsLoadingHOC(IsLoggedinHOC(LogisticsUsers))