import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addSocialLinks, setFormCurrentStep, updateShop } from '../../Redux/action/Shop';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';





const webUrl = /^(?=.{4,2048}$)((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]{1,63}(\.[a-zA-Z]{1,63}){1,5}(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

function SocialLink(props) {
    const { setLoading } = props
    const dispatch = useDispatch()
    let formRef = useRef(null)

    const { userInfo, shopInfo, shopOtherInfo, socialurl, openingHours, shopImage, shopOtherImages, p_shopid } = useSelector(state => state.shop)
    const params = useParams();


    useEffect(() => {
        if (socialurl) {
            if (socialurl.facebook || socialurl.twitter || socialurl.google) {
                const fields = ["facebook", "twitter", "google"];
                fields.forEach(field => formik.setFieldValue(field, socialurl[field], false));
            }
        }
    }, [socialurl])

    const formik = useFormik({
        initialValues: { facebook: '', twitter: '', google: '' },

        validationSchema: Yup.object({
            facebook: Yup.string()
                .matches(webUrl, 'Website should be a valid URL!'),

            twitter: Yup.string()
                .matches(webUrl, 'Website should be a valid URL!'),

            google: Yup.string()
                .matches(webUrl, 'Website should be a valid URL!'),
        }),

        onSubmit: async values => {
            const fieldValues = values
            if (formRef.current.name === 'next') {
                dispatch(addSocialLinks(fieldValues))
                dispatch(setFormCurrentStep("OPENING_HOURS"))
            } else {
                handleSubmitData(fieldValues)
            }
        }
    });

    const handlePrevious = () => {
        dispatch(setFormCurrentStep("SHOP_OTHER_INFO"))
    }

    const handleSubmitData = async (fieldValues) => {
        const payload = {
            user_id: params.id,
            shop_id: p_shopid,
            ...userInfo,
            ...shopInfo,
            ...shopOtherInfo,
            ...shopImage,
            hours: { ...openingHours },
            otherimages: [...shopOtherImages],
            sociallinks: { ...fieldValues },
        }
        setLoading(true)
        await dispatch(updateShop(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status === '1') {
                        toast.success(response.data.message)
                    } else {
                        toast.error(response.data?.error[0])
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                    console.log(error)
                }
            )
    }

    return (
        <div className="card-body">
            <div className="tab-content" id="custom-tabs-one-tabContent">
                <div className="tab-pane fade active show " id="custom-social-link" role="tabpanel" aria-labelledby="social-link-tab">
                    <form >
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Facebook</label>
                                    <input type="text" className="form-control" placeholder='Enter URL'
                                        name='facebook'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.facebook}
                                    />
                                    {formik.touched.facebook && formik.errors.facebook ? (<span className="text-danger">{formik.errors.facebook}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Twitter</label>
                                    <input type="text" className="form-control" placeholder='Enter URL'
                                        name='twitter'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.twitter}
                                    />
                                    {formik.touched.twitter && formik.errors.twitter ? (<span className="text-danger">{formik.errors.twitter}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Google+</label>
                                    <input type="text" className="form-control" placeholder='Enter URL'
                                        name='google'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.google}
                                    />
                                    {formik.touched.google && formik.errors.google ? (<span className="text-danger">{formik.errors.google}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="pb-5 footer-step">
                            <button type='button' className="btn btn-primary mr-2" onClick={handlePrevious} >Back</button>
                            <button type='button' ref={formRef} className="btn btn-success mr-2"
                                onClick={() => { formRef.current.name = 'update'; formik.handleSubmit() }} >Update</button>
                            <button type='button' ref={formRef} className="btn btn-info"
                                onClick={() => { formRef.current.name = 'next'; formik.handleSubmit() }} >Next</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(SocialLink))