import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLocationByGoogleShareURL } from '../../Redux/action/Favorite';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { updateDriverJobStartPoint } from '../../Redux/action/User';


function SaveDriverLocation(props) {
    const { modal, closeModal, getListData, userData, isLoading, setLoading } = props
    const { _id, username, job_start_point } = userData
    const dispatch = useDispatch()
    const [googleURL, setGoogleURL] = useState("")


    useEffect(() => {
        if (job_start_point && job_start_point.address) {
            const { address, location } = job_start_point
            formik.setFieldValue('address', address)
            formik.setFieldValue('latitude', location[0])
            formik.setFieldValue('longitude', location[1])
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            latitude: '',
            longitude: '',
            address: '',
        },

        validationSchema: Yup.object({
            latitude: Yup.number()
                .typeError('You must specify a number')
                .required("This field is required"),

            longitude: Yup.number()
                .typeError('You must specify a number')
                .required("This field is required"),

            address: Yup.string()
                .required("This field is required")
                .min(5, 'This field must be at least 5 characters'),

        }),
        onSubmit: async values => {
            let fieldValues = values
            let locationMap = [fieldValues.latitude, fieldValues.longitude]
            Object.assign(fieldValues, {
                location: locationMap,
                user_id: _id,
            });
            setLoading(true)
            await dispatch(updateDriverJobStartPoint(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == '1') {
                            toast.success(response.data.message)
                            formik.resetForm();
                            closeModal();
                            getListData();
                        } else {
                            toast.error(response.data.error[0])
                        }
                    })
                .catch(err => setLoading(false))
        },
    });


    const validateURL = (url) => {
        const pattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/.*)?$/i;
        return pattern.test(url);
    };

    const getLocationByGoogleURL = async () => {
        const url = googleURL;
        const isValid = validateURL(url);
        if (!isValid) {
            toast.error("Invalid Google URL!")
        }
        else {
            const payload = {
                url: googleURL
            }
            setLoading(true)
            await dispatch(getLocationByGoogleShareURL(payload))
                .then(
                    response => {
                        setLoading(false);
                        const { status, data } = response;
                        if (status == "1") {
                            const { geo_address, location } = data;
                            formik.setFieldValue('address', geo_address?.address);
                            if (location && location.length === 2) {
                                const [latitude, longitude] = location;
                                formik.setFieldValue('latitude', latitude);
                                formik.setFieldValue('longitude', longitude);
                            }
                        } else {
                            toast.error(data?.message);
                        }
                    },
                    error => {
                        setLoading(false);
                        const errorMessage = error.response?.data?.data?.message || error.message;
                        toast.error(errorMessage);
                    }
                )
                .catch(error => console.log(error))
        }
    }

    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Driver job start location</h5>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={formik.handleSubmit} >
                        <div className="modal-body p-3">
                            <div className='row'>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Driver name</label>
                                        <input
                                            name='username'
                                            defaultValue={username}
                                            disabled
                                            type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Google shared URL</label>
                                        <div className="input-group mb-3">
                                            <input
                                                name='location_title'
                                                placeholder='Enter Google URL'
                                                onChange={(e) => setGoogleURL(e.target.value)}
                                                value={googleURL}
                                                type="text" className="form-control" />
                                            <div className="input-group-append">
                                                <button disabled={!googleURL} onClick={getLocationByGoogleURL} className="btn btn-success" type="button">Find Location</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group required">
                                        <label className="control-label">Latitude</label>
                                        <input
                                            name='latitude'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.latitude}
                                            type="number" className="form-control" />
                                        {formik.touched.latitude && formik.errors.latitude ? (<span className="text-danger">{formik.errors.latitude}</span>) : null}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group required">
                                        <label className="control-label">Longitude</label>
                                        <input
                                            name='longitude'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.longitude}
                                            type="number" className="form-control" />
                                        {formik.touched.longitude && formik.errors.longitude ? (<span className="text-danger">{formik.errors.longitude}</span>) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">Address</label>
                                        <textarea
                                            type='text'
                                            name='address'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.address}
                                            className="form-control" rows="2"></textarea>
                                        {formik.touched.address && formik.errors.address ? (<span className="text-danger">{formik.errors.address}</span>) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-savbtn">
                            <button type="button" onClick={closeModal} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button disabled={isLoading} type="submit" className="btn btn-success">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default SaveDriverLocation