import React, { useEffect } from 'react'
import { uploadShopImgFile, removeFileImgAWS } from '../../Redux/action/Media'
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC'
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { addShopImage, setFormCurrentStep } from '../../Redux/action/Shop'
import { trimFileName } from '../../Helper';
import { toast } from 'react-toastify'
import { useFormik } from 'formik';
import * as Yup from 'yup';


function ShopInfoImage(props) {
    const { setLoading } = props
    const dispatch = useDispatch()
    const { shopImage } = useSelector(state => state.shop)

    useEffect(() => {
        if (shopImage) {
            if (shopImage.icon || shopImage.background_photo) {
                const fields = ["icon", "background_photo"];
                fields.forEach(field => formik.setFieldValue(field, shopImage[field]));
            }
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            icon: '',
            background_photo: '',
        },
        validationSchema: Yup.object({
            icon: Yup.string()
                .required("This field is required"),
            background_photo: Yup.string()
                .required("This field is required")
        }),
        onSubmit: async values => {
            const fieldValues = values
            dispatch(addShopImage(fieldValues))
            dispatch(setFormCurrentStep("SHOP_OTHER_IMAGE"))
        },
    });

    const removeFileFromAWS = async (id) => {
        const payload = { type: "shop", file_name: id }
        await dispatch(removeFileImgAWS(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status === '1') {
                        toast.success(response.data.message)
                    } else {
                        toast.error(response.data.error[0])
                    }
                }).catch(error => setLoading(false))
    }


    const handleIconImg = async (event) => {
        setLoading(true)
        if (formik.values.icon) {
            var initial_url = formik.values.icon
            const endPoint = initial_url.split('/')
            await removeFileFromAWS(endPoint[endPoint.length - 1])
        }
        const file = event.target.files[0]
        var formData = new FormData();
        formData.append(`type`, "shop");
        formData.append(`title`, "shop");
        formData.append('media', file);
        dispatch(uploadShopImgFile(formData))
            .then(
                response => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 2000)
                    if (response.status === '1') {
                        toast.success(response.data.message)
                        const file_url = response.data.file_url
                        formik.setFieldValue("icon", file_url)
                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                error => {
                    const message = (error.response && error.response.statusText) || "something went wrong"
                    toast.error(message)
                    setLoading(false)
                }
            )
            .catch(error => {
                console.log(error)
                toast.error(error.message)
                setLoading(false)
            }
            )
    }

    const handleBackgroundphoto = (event) => {
        setLoading(true)
        if (formik.values.background_photo) {
            var initial_url = formik.values.background_photo
            const endPoint = initial_url.split('/')
            removeFileFromAWS(endPoint[endPoint.length - 1])
        }
        const file = event.target.files[0]
        var formData = new FormData();
        formData.append(`type`, "shop");
        formData.append(`title`, "shop");
        formData.append('media', file);
        dispatch(uploadShopImgFile(formData))
            .then(
                response => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 2000)
                    if (response.status === '1') {
                        toast.success(response.data.message)
                        const file_url = response.data.file_url
                        formik.setFieldValue("background_photo", file_url)
                        setLoading(false)
                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                error => {
                    const message = (error.response && error.response.statusText) || "something went wrong"
                    toast.error(message)
                    setLoading(false)
                }
            )
            .catch(error => {
                console.log(error)
                toast.error(error.message)
                setLoading(false)
            })
    }

    const handlePrevious = () => {
        const { icon, background_photo } = formik.values
        const payload = { icon, background_photo }
        dispatch(addShopImage(payload))
        dispatch(setFormCurrentStep("OPENING_HOURS"))
    }

    return (
        <div className="card-body">
            <div className="tab-content" id="custom-tabs-one-tabContent">
                <div className="tab-pane fade active show " id="custom-shop-images" role="tabpanel" aria-labelledby="shop-images-tab">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required ">
                                    <label className="control-label">Upload Icon</label>
                                    <div className="custom-file">
                                        <input
                                            name='icon'
                                            accept="image/*"
                                            onBlur={formik.handleBlur}
                                            onChange={handleIconImg}
                                            type="file" className="custom-file-input" id="exampleInputFile" />
                                        <label className="custom-file-label" htmlFor="exampleInputFile">{formik.values.icon ? trimFileName(formik.values.icon) : 'Choose file'}</label>
                                    </div>
                                    {formik.touched.icon && formik.errors.icon ? (<span className="text-danger">{formik.errors.icon}</span>) : null}
                                    {/* <div className="position-relative shop-img-parent"> */}
                                    <img src={formik.values?.icon} className="file-upload-image pt-3" />
                                    {/* <span className="position-absolute">x</span> 
                                    </div>  */}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group required ">
                                    <label className="control-label">Background Image</label>
                                    <div className="custom-file">
                                        <input
                                            name='background_photo'
                                            accept="image/*"
                                            onBlur={formik.handleBlur}
                                            onChange={handleBackgroundphoto}
                                            type="file" className="custom-file-input" />
                                        <label className="custom-file-label" htmlFor="exampleInputFile">{formik.values.background_photo ? trimFileName(formik.values.background_photo) : 'Choose file'}</label>
                                    </div>
                                    {formik.touched.background_photo && formik.errors.background_photo ? (<span className="text-danger">{formik.errors.background_photo}</span>) : null}
                                    {/* <div className="position-relative"> */}
                                    <img src={formik.values?.background_photo} className="file-upload-image pt-3" id="output" />
                                    {/* <span className="position-absolute manage_cross_icon">x</span> 
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="pb-5 footer-step">
                            <button type='button' className="btn btn-primary mr-2" onClick={handlePrevious} >Back</button>
                            <button type='submit' className="btn btn-success">Next</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default IsLoadingHOC(IsLoggedinHOC(ShopInfoImage))