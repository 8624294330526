export const hashSecretKey = '12345678901234567890123456789012';
export const phoneRegExp = /^[0-9]{7,9}$/;
export const imageMaxSize = 10000000;
export const countryCode = process.env.REACT_APP_COUNTRY_CODE;
export const shopAppUrl = process.env.REACT_APP_SHOP_URL;
export const currencyCode= process.env.REACT_APP_CORRENCY_CODE || 'SAR'
export const phoneRegExpInt = /^(\+|\d)[0-9]{7,16}$/;
export const mapCenter = { lat: 29.378586, lng: 47.990341 };
export const acceptedFileTypes = "image/x-png, image/png, image/jpg, image/jpeg, image/gif";
export const acceptedOrderDocFileType = "image/x-png, image/png, image/jpg, image/jpeg, image/gif, .pdf";
export const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim();
});

export const payMethods = [
  {
    img: "/mada_pay.png",
    title: "Mada Pay",
    title_ar: "الدفع من خلال مدى",
    subTitle: "Mada Pay",
    desc: "Expires on 16/24",
    value: "CARD",
    type: "MADA",
    PaymentMethodId: 6
  },
  {
    img: "/visa_pay.png",
    title: "Visa Card",
    title_ar: "الدفع من خلال فيزا",
    subTitle: "Visa Card",
    desc: "Expires on 16/24",
    value: "CARD",
    type: "VISA",
    PaymentMethodId: 2
  },
  {
    img: "/stc_pay.png",
    title: "STC Pay",
    title_ar: "الدفع من خلال stc",
    subTitle: "STC Pay",
    desc: "Expires on 16/24",
    value: "CARD",
    type: "STC",
    PaymentMethodId: 14
  },
  {
    img: "/apple_pay.png",
    title: "Apple Pay",
    title_ar: "الدفع  من خلال apple",
    subTitle: "Apple Pay",
    desc: "Lorem Ipsum Two",
    value: "CARD",
    type: "APPLE",
    PaymentMethodId: 11
  },
];
