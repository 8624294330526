import React, { useState, useEffect, useCallback, useContext } from 'react';
import { getUtracDeliveryZoneFee, getDeliveryZoneFee } from '../../Redux/action/DeliveryZone';
import { getFavoriteLocation } from '../../Redux/action/Favorite';
import { getUserForSearchBar } from '../../Redux/action/User';
import { getShopBranches } from '../../Redux/action/Shop';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { createNewOrder, getDriversWithinShopRange } from '../../Redux/action/Orders';
import { updateBuyerDetail } from '../../Redux/action/App';
import { useDispatch, useSelector } from 'react-redux';
import { getAreas } from '../../Redux/action/Area';
import { default as ReactSelect } from "react-select";
import AddFavLocation from '../../Components/BuyerModal/AddFavLocation';
import CreateBuyer from '../../Components/BuyerModal/CreateBuyer';
import EditFavLocation from '../../Components/BuyerModal/EditFavLocation';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { phoneRegExp, countryCode } from '../../Config/constants';
import { selectOptionStyle } from '../../Helper';
import { OrderContext } from '../../Context/OrderContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';


function CreateOrder(props) {
  const { setLoading, isLoading } = props
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const { buyer } = useSelector(stete => stete.app);
  const { _id, nickname } = user ? user : {}
  const { buyer_id } = buyer ? buyer : {}
  const [selectedShop, setSelectedShop] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [favoriteLocations, setFavoriteLocations] = useState([]);
  const [selectedFavLocation, setSelectedFavLocation] = useState("");
  const [ownerShops, setOwnerShops] = useState([]);
  const [areas, setAreas] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [searchedDrivers, setSearchedDrivers] = useState([])
  const [selectedDriver, setSelectedDriver] = useState("");
  const [selectedSearchedDriver, setSelectedSearchedDriver] = useState("");
  const [inputType, setInputType] = useState('by_self');
  const [shops, setShops] = useState([])
  const [driverType, setDriverType] = useState('within_shop_range')
  const { userGlobalData } = useSelector(state => state.app)
  const { default_requested_vehicle_type } = userGlobalData ? userGlobalData : {}

  
  const {
    addFavLocModal,
    setAddFavLocModal,
    editFavLocModal,
    setEditFavLocModal,
    createBuyerModal,
    setCreateBuyerModal,
    shopFetching,
    setShopFetching,
    driverSearchable,
    setDriverSearchable,
    driverFetching,
    setDriverFetching
  } = useContext(OrderContext)


  useEffect(() => {
    getAreasData();
    dispatch(updateBuyerDetail({ buyer_id: '' }))
    if (user.type === "Shop") {
      let options = []
      options.push({ label: nickname, value: _id })
      setOwnerShops(options)
      setSelectedShop(options[0])
      formik.setFieldValue("shop_id", _id)
      let paymentmethod = formik.values.payment_method
      getDriversDataWithShopRange(_id, paymentmethod)
    }
  }, [])

  const getShopBranchesData = async () => {
    const payload = {
      search: { user_id: _id },
      shorting: { order_in: -1, order_by: "_created_at" }
    }
    setLoading(true)
    await dispatch(getShopBranches(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status == "1") {
            const resData = (response.data && response.data.branches) || []
            const branches = []
            resData.forEach(item => {
              branches.push({ value: item.branch_shop_id, label: item.nickname })
            })
            setOwnerShops(branches)
          } else {
            toast.error(`You don't have any branches !`)
            setOwnerShops([])
          }
        }, error => {
          setLoading(false)
          toast.error(error.response.data?.message)
        }
      )
  }

  const formik = useFormik({
    initialValues: {
      shop_id: '',
      buyer_phone: '',
      buyer_name: '',
      area_id: '',
      favorite_location_id: '',
      delivery_zone_id: '',
      driver_id: '',
      order_price: '0',
      delivery_fee: '0',
      utrack_fee: '0',
      tax_fee: '0',
      distance: '0',
      payment_method: 'Cash',
      status: "",
      shop_ordernumber: "",
      note: "",
      requested_vehicle_type: default_requested_vehicle_type ? default_requested_vehicle_type : "all"
    },

    validationSchema: Yup.object({
      shop_id: Yup.string()
        .required("This fiels is required"),

      buyer_phone: Yup.string()
        .required("This field is required")
        .matches(phoneRegExp, 'Phone number is not valid'),

      area_id: Yup.string()
        .required("This field is required"),

      payment_method: Yup.string()
        .required("This field is required"),

      order_price: Yup.number()
        .typeError('You must specify a number')
        .min(0, "Please enter a positive number")
        .required("This field is required"),
    }),

    onSubmit: async values => {
      if (!buyer_id) {
        toast.error("Please check buyer")
        return;
      }
      let fieldValues = { ...values }
      Object.assign(fieldValues, { buyer_id: buyer_id });
      let phoneNumber = fieldValues.buyer_phone.toString();
      fieldValues.buyer_phone = countryCode + phoneNumber;
      setLoading(true)
      await dispatch(createNewOrder(fieldValues))
        .then(
          response => {
            setLoading(false)
            if (response.status === '1') {
              if (response.data.order_id) {
                toast.success(response.data.message)
                formik.resetForm()
                setSelectedShop("")
                setSelectedArea("")
                setSelectedFavLocation("")
                setSelectedDriver("")
                history.push('/open-orders')
              }
            } else {
              toast.error(response.data.error[0])
              setLoading(false)
            }
          })
    }
  });

  const getAreasData = async () => {
    const payload = {
      search: { _id: "", name: "" },
      shorting: { order_in: 1, order_by: "name_en" },
    }
    setLoading(true)
    await dispatch(getAreas(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status === "1") {
            const result = (response.data && response.data.areas) || []
            const areasData = []
            result.forEach(item => {
              areasData.push({ label: item.name_combine, value: item._id })
            })
            setAreas(areasData)
          } else {
            toast.error(response.data.error[0])
          }
        })
      .catch(err => setLoading(false))
  }

  // loaded options using API call with searched Shops
  const getSearchedShops = async (searchvalue) => {
    const payload = {
      search: { type: "Shop", nickname: searchvalue, username: "" },
      shorting: { order_in: "asc", order_by: "nickname" }
    }
    setShopFetching(true)
    await dispatch(getUserForSearchBar(payload))
      .then(
        response => {
          setShopFetching(false)
          if (response.status === "1") {
            const resData = response.data.users
            const allShops = []
            resData.forEach(item => {
              const { _id, nickname } = item
              allShops.push({ label: nickname, value: _id })
            })
            setShops(allShops)
          } else {
            setShops([])
          }
        },
        error => {
          setShops([])
          setShopFetching(false)
          toast.error(error.response.data?.message)
        }
      )
  };

  // load options using API call with searched Drivers
  const getSearchedDrivers = async (searchDriver, vehicle_type) => {
    const digitNumbers = /^-?[\+|\d.]+(?:e-?\d+)?$/
    let payload = {
      search: { id: '', type: "Driver", nickname: '', username: "", phone: '', vehicle_type },
      shorting: { order_in: "asc", order_by: "nickname" }
    }
    if (digitNumbers.test(searchDriver)) {
      let phoneNumber = searchDriver.toString();
      if (phoneNumber[0] === "+") {
        phoneNumber = phoneNumber.substring(1)
      }
      payload.search.phone = phoneNumber
    } else {
      payload.search.nickname = searchDriver
    }
    setDriverFetching(true)
    await dispatch(getUserForSearchBar(payload))
      .then(
        response => {
          setDriverFetching(false)
          if (response.status == "1") {
            const resData = response.data.users
            const alldrivers = []
            resData.forEach(item => {
              const { _id, nickname, username, phone, available, online } = item
              let is_available = available ? 'Available' : 'Unavailable'
              let is_online = online ? 'Online' : 'Offline'
              const labelwithPhone = `${nickname ? nickname : username} (${phone}), ${is_online} , ${is_available}`
              alldrivers.push({ label: labelwithPhone, value: _id })
            })
            setSearchedDrivers(alldrivers)
          } else {
            setSearchedDrivers([])
          }
        },
        error => {
          setSearchedDrivers([])
          setDriverFetching(false)
          toast.error(error.response.data?.message)
        }
      )
  };

  const getShopsData = useCallback(debounce((searchvalue) => getSearchedShops(searchvalue), 500), []);
  const handleSearchShop = (inputValue) => {
    const value = inputValue.trim()
    if (value && value.length > 0) {
      getShopsData(value);
    }
  }

  const getDriversData = useCallback(debounce((searchvalue, vehicle_type) => getSearchedDrivers(searchvalue, vehicle_type), 500), []);
  const handleSearchDriver = (inputValue) => {
    const value = inputValue.trim()
    if (value && value.length > 0) {
      getDriversData(value, formik.values.requested_vehicle_type);
    }
  }

  const getDriversDataWithShopRange = async (shopId, paymentmethod, vehicleType = "") => {
    if (driverType === 'within_shop_range') {
      const payload = {
        shop_id: shopId,
        payment_method: paymentmethod,
        vehicle_type: vehicleType ? vehicleType : formik.values.requested_vehicle_type
      }
      setLoading(true)
      await dispatch(getDriversWithinShopRange(payload))
        .then(
          response => {
            setLoading(false);
            const result = (response.data && response.data.drivers) || []
            if (result && result.available_drivers && result.available_drivers.length > 0) {
              const availableDrivers = result.available_drivers
              const driversData = []
              availableDrivers.forEach(item => {
                const { _id, nickname, username, phone, available, online, distance } = item
                let is_available = available ? 'Available' : 'Unavailable'
                let is_online = online ? 'Online' : 'Offline'
                const labelwithPhone = `${nickname ? nickname : username} (${phone}) , ${is_online} , ${is_available}, ${distance} KM`
                driversData.push({ label: labelwithPhone, value: _id })
              })
              setDrivers(driversData)
            }
            else {
              setDrivers([])
              formik.setFieldValue('driver_id', "")
              setSelectedDriver("");
            }
          })
        .catch(err => setLoading(false))
    }
  }

  const getFavoriteLocationData = async (buyer_id, favLoc_id) => {
    if (buyer_id) {
      const payload = {
        search: { title: "", locationarea: "", locationtitle: "", _p_user: buyer_id },
        shorting: { order_in: -1, order_by: "_created_at" },
        pagination: { start: 1, length: 100 }
      }
      setLoading(true);
      await dispatch(getFavoriteLocation(payload))
        .then(
          response => {
            setLoading(false);
            if (response.status === "1") {
              const result = (response.data && response.data.favorite_locations) || []
              const favoriteLocationsData = []
              let fulladdress = ''
              result.forEach(item => {
                fulladdress = ''
                if (item.block) {
                  fulladdress += 'Block-' + item.block + ', '
                }
                if (item.street) {
                  fulladdress += 'Street-' + item.street + ', '
                }
                if (item.building) {
                  fulladdress += 'Building-' + item.building + ', '
                }
                if (item.floor) {
                  fulladdress += 'Floor-' + item.floor + ', '
                }
                if (item.apartment) {
                  fulladdress += 'Apartment-' + item.apartment + ', '
                }
                if (item.locationtitle) {
                  fulladdress += 'Title-' + item.locationtitle + ', '
                }
                if (item.locationarea) {
                  fulladdress += 'Area-' + item.locationarea
                }
                favoriteLocationsData.push({ label: fulladdress, value: item._id })
              })
              setFavoriteLocations(favoriteLocationsData)
              if (favLoc_id) {
                selectFavLocCallBack(favoriteLocationsData, favLoc_id)
              }
            } else {
              setFavoriteLocations([])
            }
          },
          error => {
            setLoading(false)
            setFavoriteLocations([])
          }
        )
    }
  }

  const getBuyerDetailByPhone = async () => {
    let phoneNumber = formik.values.buyer_phone.toString();
    if (!phoneNumber) {
      toast.error('Phone number is required');
      return;
    } else if (!phoneRegExp.test(phoneNumber)) {
      toast.error('Phone number is not valid!');
      return;
    }

    const payload = {
      search: { type: "Buyer", phone: phoneNumber, username: "" },
      shorting: { order_in: "asc", order_by: "nickname" }
    }
    setLoading(true)
    await dispatch(getUserForSearchBar(payload))
      .then(async response => {
        setLoading(false)
        if (response.status === "1") {
          const user = (response.data && response.data.users && response.data.users[0]) || {}
          const { _id, nickname, username } = user ? user : {}
          formik.setFieldValue("buyer_name", nickname ? nickname : username)
          await dispatch(updateBuyerDetail({ buyer_id: _id }))
          await getFavoriteLocationData(_id);
        } else {
          toast.error('Buyer not found by this phone!')
          formik.setFieldValue("buyer_name", '')
          dispatch(updateBuyerDetail({ buyer_id: '' }))
          setCreateBuyerModal(true)
        }
      }, error => {
        setLoading(false)
        console.log(error)
      }
      )
  };

  const getUtracFeeData = async (payload) => {
    setLoading(true);
    await dispatch(getUtracDeliveryZoneFee(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status === '1') {
            formik.setFieldValue('distance', response.data.distance?.toString())
            const result = (response.data.delivery_zones) || {}
            if (result && result._id) {
              formik.setFieldValue('utrack_fee', result.utrac_fee?.toString())
              formik.setFieldValue('delivery_fee', result.delivery_fee?.toString())
              formik.setFieldValue('delivery_zone_id', result._id)
              formik.setFieldValue('tax_fee', result.tax_fee?.toString())
            } else {
              formik.setFieldValue('utrack_fee', '')
              formik.setFieldValue('delivery_fee', '')
              formik.setFieldValue('delivery_zone_id', '')
              formik.setFieldValue('tax_fee', "")
            }
          } else {
            toast.error(response.data.error[0])
          }
        }, error => {
          setLoading(false)
          console.log(error)
        }
      )
  }

  const getPickupDeliveryFee = async (shop_id, area_id, favorite_location_id) => {
    if (shop_id && (area_id || favorite_location_id)) {
      const payload = { shop_id, area_id, favorite_location_id }
      setLoading(true)
      await dispatch(getDeliveryZoneFee(payload))
        .then(
          async response => {
            setLoading(false);
            if (response.status === '1') {
              const result = (response.data.delivery_zones) || {}
              formik.setFieldValue('distance', response.data?.distance?.toString())
              if (result && result._id) {
                formik.setFieldValue('utrack_fee', result.utrac_fee?.toString())
                formik.setFieldValue('delivery_fee', result.delivery_fee?.toString())
                formik.setFieldValue('delivery_zone_id', result._id)
                formik.setFieldValue('tax_fee', result.tax_fee?.toString())
              } else {
                getUtracFeeData(payload)
              }
            } else {
              toast.error(response.data.error[0])
            }
          }, error => {
            setLoading(false)
          }
        )
    }
  }

  // Only for Shop user Role to create order self or Branchs
  const handleChangeShopCategory = async (e) => {
    let favorite_location_id = formik.values.favorite_location_id
    let area_id = formik.values.area_id
    const { value } = e.target
    setInputType(value)
    if (value === 'by_self') {
      let options = []
      options.push({ label: nickname, value: _id })
      setOwnerShops(options)
      setSelectedShop(options[0])
      formik.setFieldValue("shop_id", _id)
      let shop_id = _id
      let paymentmethod = formik.values.payment_method
      await getDriversDataWithShopRange(shop_id, paymentmethod)
      await getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
    } else {
      formik.setFieldValue("shop_id", "")
      setSelectedShop("")
      // To Get or Update Drivers within Shop Range
      setSelectedDriver("")
      setDrivers([])
      formik.setFieldValue("driver_id", "")
      // TO Get All Shop Branches Data
      await getShopBranchesData()
    }
  }

  const handleChangeDriverType = (event) => {
    const { value } = event.target
    setDriverType(value)
    formik.setFieldValue('driver_id', "")
    if (value === 'manually_search') {
      setDriverSearchable(true)
      setSelectedDriver("")
    }
    else {
      setDriverSearchable(false)
      setSelectedSearchedDriver("")
    }
  }

  const handleAreaSelect = option => {
    let shop_id = formik.values.shop_id
    let favorite_location_id = formik.values.favorite_location_id
    if (option) {
      const { value } = option
      setSelectedArea(option)
      formik.setFieldValue('area_id', value);
      let area_id = value
      getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
    } else {
      setSelectedArea('')
      formik.setFieldValue('area_id', '');
      let area_id = ''
      getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
    }
  }

  const handleSelectDriver = (option) => {
    if (option) {
      let { value } = option
      formik.setFieldValue('driver_id', value)
      setSelectedDriver(option);
    } else {
      formik.setFieldValue('driver_id', "")
      setSelectedDriver("");
    }
  }

  const handleSelectSearchedDriver = (option) => {
    if (option) {
      let { value } = option
      formik.setFieldValue('driver_id', value)
      setSelectedSearchedDriver(option);
    } else {
      formik.setFieldValue('driver_id', "")
      setSelectedSearchedDriver("");
    }
  }

  const handleFavLocationSelect = async option => {
    let shop_id = formik.values.shop_id
    let area_id = formik.values.area_id
    if (option) {
      const { value } = option
      setSelectedFavLocation(option)
      formik.setFieldValue('favorite_location_id', value)
      let favorite_location_id = value
      getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
    } else {
      setSelectedFavLocation('')
      formik.setFieldValue('favorite_location_id', '')
      let favorite_location_id = ''
      getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
    }
  }


  const handleShopSelect = async (option) => {
    let favorite_location_id = formik.values.favorite_location_id
    let area_id = formik.values.area_id
    if (option) {
      let { value } = option
      const paymentmethod = formik.values.payment_method
      getDriversDataWithShopRange(value, paymentmethod)
      formik.setFieldValue("shop_id", value)
      setSelectedShop(option);
      let shop_id = value
      await getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
    } else {
      formik.setFieldValue("shop_id", "")
      setSelectedShop("");
      setShops([])
      let shop_id = ""
      await getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
    }
  }

  const selectShopByOwner = async (option) => {
    let favorite_location_id = formik.values.favorite_location_id
    let area_id = formik.values.area_id
    if (option) {
      let { value } = option
      const paymentmethod = formik.values.payment_method
      formik.setFieldValue("shop_id", value)
      setSelectedShop(option);
      let shop_id = value
      await getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
      getDriversDataWithShopRange(value, paymentmethod)
    } else {
      formik.setFieldValue("shop_id", "")
      setSelectedShop("");
      let shop_id = ""
      await getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
    }
  }

  const updateBuyer = (buyerName, buyerPhone) => {
    formik.setFieldValue('buyer_name', buyerName)
    formik.setFieldValue('buyer_phone', buyerPhone)
  }

  const selectFavLocCallBack = (favoriteLocationsData, favLoc_id) => {
    let shop_id = formik.values.shop_id
    let area_id = formik.values.area_id
    let favorite_location_id = favLoc_id
    favoriteLocationsData.forEach((item) => {
      if (item.value === favLoc_id) {
        setSelectedFavLocation(item)
        getPickupDeliveryFee(shop_id, area_id, favorite_location_id)
      }
    })
  }

  const handleChangeVehicleType = (e) => {
    const { name, value } = e.target
    formik.setFieldValue(name, value)
    if (formik.values.shop_id) {
      const vehicleType = value
      getDriversDataWithShopRange(formik.values.shop_id, formik.values.payment_method, vehicleType);
    }
    formik.setFieldValue('driver_id', "");
    setSelectedDriver("");
    setSelectedSearchedDriver("");
    setSearchedDrivers([])
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header main_breadcrumb_top">
          <div className="container-fluid">
            <div className="d-flex justify-content-between mb-2">
              <div>
                <h1 className="m-0">Create an Order</h1>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => setAddFavLocModal(true)}
                  type="button"
                  disabled={!buyer_id}
                  className="btn btn-primary mr-2"
                  data-toggle="modal"
                >
                  <i className="fa-regular fa-circle-plus mr-2"></i>
                  Add Location
                </button>
                {formik.values.favorite_location_id && (
                  <button
                    onClick={() => setEditFavLocModal(true)}
                    type="button"
                    disabled={!buyer_id}
                    className="btn btn-primary"
                    data-toggle="modal"
                  >
                    <i className="fa-regular fa-pen-to-square mr-2"></i>
                    Edit Location
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <form onSubmit={formik.handleSubmit} >
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group required">
                            <label className="control-label">Shop</label>
                            {user.type === "Shop" &&
                              <React.Fragment>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input radio_input" style={{ marginLeft: '100px' }}
                                    name='Ordertype'
                                    type='radio'
                                    value='by_self'
                                    onChange={handleChangeShopCategory}
                                    checked={inputType === 'by_self'}
                                  />
                                  <label className="form-check-label">Self</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input radio_input"
                                    name='Ordertype'
                                    type='radio'
                                    value='other_branches'
                                    checked={inputType === 'other_branches'}
                                    onChange={handleChangeShopCategory}
                                  />
                                  <label className="form-check-label">Branches</label>
                                </div>
                              </React.Fragment>}
                            {user.type === "Admin" ?
                              <ReactSelect placeholder="Search"
                                isLoading={shopFetching}
                                isClearable={true}
                                value={selectedShop}
                                options={shops}
                                onInputChange={(value) => handleSearchShop(value)}
                                onChange={(option) => handleShopSelect(option)}
                                styles={selectOptionStyle}
                              /> :
                              <ReactSelect
                                options={ownerShops}
                                isDisabled={ownerShops.length === 0}
                                isClearable={inputType === 'other_branches'}
                                hideSelectedOptions={false}
                                value={selectedShop}
                                onChange={option => selectShopByOwner(option)}
                                styles={selectOptionStyle}
                                placeholder="Select the Shop"
                              />
                            }
                            {formik.touched.shop_id && formik.errors.shop_id ? (<span className="text-danger">{formik.errors.shop_id}</span>) : null}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group required">
                            <label className="control-label">Buyer Phone</label>
                            <div className="input-group">
                              <div className="input-group-append">
                                <span className="input-group-text">{countryCode}</span>
                              </div>
                              <input type="text" className="form-control"
                                placeholder="Phone"
                                name="buyer_phone"
                                required
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.buyer_phone}
                              />
                              <div className="input-group-append">
                                <button className="btn btn-success" onClick={getBuyerDetailByPhone} type="button">Find</button>
                              </div>
                            </div>
                            {formik.touched.buyer_phone && formik.errors.buyer_phone ? (<span className="text-danger">{formik.errors.buyer_phone}</span>) : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="control-label">Buyer Name</label>
                            <input
                              name="buyer_name"
                              onChange={formik.handleChange}
                              value={formik.values.buyer_name}
                              onBlur={formik.handleBlur}
                              placeholder='Name'
                              type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group required">
                            <label className="control-label">Buyer Area</label>
                            <ReactSelect
                              isClearable={true}
                              options={areas}
                              hideSelectedOptions={false}
                              value={selectedArea}
                              onChange={option => handleAreaSelect(option)}
                              placeholder="Select area"
                              styles={selectOptionStyle}
                            />
                            {formik.touched.area_id && formik.errors.area_id ? (<span className="text-danger">{formik.errors.area_id}</span>) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="control-label">Vehicle Type</label>
                            <select
                              className="form-control"
                              name='requested_vehicle_type'
                              onChange={handleChangeVehicleType}
                              value={formik.values.requested_vehicle_type}
                            >
                              <option value=''>Select..</option>
                              <option value="all">All</option>
                              <option value="bike">Bike</option>
                              <option value="car">Car</option>
                              <option value="van">Van</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group required">
                            <label className="control-label">Order Price</label>
                            <input className="form-control"
                              required
                              name='order_price'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.order_price}
                              placeholder='Price'
                              type="text"
                            />
                            {formik.touched.order_price && formik.errors.order_price ? (<span className="text-danger ">{formik.errors.order_price}</span>) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {user.type === "Admin" &&
                          <div className="col-sm-6">
                            <div className="form-group">
                              <div className="row">
                                <div className='col-sm-6'>
                                  <label className="control-label">Driver</label>
                                </div>
                                <div className='col-sm-6'>
                                  <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input radio_input"
                                        name='drivertype'
                                        type='radio'
                                        value='within_shop_range'
                                        onChange={handleChangeDriverType}
                                        checked={driverType === 'within_shop_range'}
                                      />
                                      <label className="form-check-label">Shop Range</label>
                                    </div>
                                    <div className="form-check pl-2 form-check-inline">
                                      <input className="form-check-input radio_input"
                                        name='drivertype'
                                        type='radio'
                                        value='manually_search'
                                        checked={driverType === 'manually_search'}
                                        onChange={handleChangeDriverType}
                                      />
                                      <label className="form-check-label">Search</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {driverSearchable ?
                                <ReactSelect placeholder="Search driver"
                                  isClearable={true}
                                  isLoading={driverFetching}
                                  options={searchedDrivers}
                                  value={selectedSearchedDriver}
                                  onInputChange={handleSearchDriver}
                                  onChange={handleSelectSearchedDriver}
                                  styles={selectOptionStyle}
                                />
                                :
                                <ReactSelect
                                  isClearable={true}
                                  options={drivers}
                                  hideSelectedOptions={false}
                                  value={selectedDriver}
                                  onChange={option => handleSelectDriver(option)}
                                  placeholder="Select driver"
                                  styles={selectOptionStyle}
                                />
                              }
                            </div>
                          </div>
                        }
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="control-label">Buyer Favorite Location</label>
                            <ReactSelect
                              isClearable={true}
                              name='favorite_location_id'
                              options={favoriteLocations}
                              hideSelectedOptions={false}
                              value={selectedFavLocation}
                              onChange={(option) => handleFavLocationSelect(option)}
                              styles={selectOptionStyle}
                              placeholder="Select" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="control-label">Order Note</label>
                            <input
                              name='note'
                              onChange={formik.handleChange}
                              value={formik.values.note}
                              placeholder='Enter note'
                              type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="control-label">Order Number</label>
                            <input
                              name='shop_ordernumber'
                              onChange={formik.handleChange}
                              value={formik.values.shop_ordernumber}
                              placeholder='Order number'
                              type="text" className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="row">

                        <div className="col-sm-6">
                          <div className="form-group required">
                            <label className="control-label">Payment Method</label>
                            <select className="form-control"
                              required
                              name='payment_method'
                              onChange={formik.handleChange}
                              value={formik.values.payment_method}
                            >
                              <option value=''>Select...</option>
                              <option value="Cash">Cash</option>
                              <option value="Credit">Credit</option>
                              <option value="Visa Pos">Visa Pos</option>
                            </select>
                            {formik.touched.payment_method && formik.errors.payment_method ? (<span className="text-danger ">{formik.errors.payment_method}</span>) : null}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Distance(km)</label>
                            <input
                              disabled
                              value={formik.values.distance}
                              type="text" className="form-control"
                              placeholder='distance'
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group required">
                            <label className="control-label">Utrac Fee</label>
                            <input
                              disabled
                              value={formik.values.utrack_fee}
                              placeholder='Utrac fee'
                              type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group required">
                            <label className="control-label">Delivery Fee</label>
                            <input
                              disabled
                              value={formik.values.delivery_fee}
                              placeholder='Delivery fee'
                              type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group required">
                            <label className="control-label">Tax Fee</label>
                            <input
                              disabled
                              value={formik.values.tax_fee}
                              placeholder='Tax fee'
                              type="text" className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-3 mb-3">
                        <button type="submit" disabled={!buyer_id} className="btn btn-success">Create Order</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {addFavLocModal &&
        <AddFavLocation
          getFavoriteLocationData={getFavoriteLocationData}
          isLoading={isLoading}
          setLoading={setLoading}
          modal={addFavLocModal}
          closeModal={() => setAddFavLocModal(false)}
        />}

      {editFavLocModal &&
        <EditFavLocation
          getFavoriteLocationData={getFavoriteLocationData}
          closeModal={() => setEditFavLocModal(false)}
          modal={editFavLocModal}
          isLoading={isLoading}
          setLoading={setLoading}
          favLocation_id={formik.values.favorite_location_id}
        />}

      {createBuyerModal &&
        <CreateBuyer
          modal={createBuyerModal}
          buyer_phone={formik.values.buyer_phone}
          buyer_name={formik.values.buyer_name}
          closeModal={() => setCreateBuyerModal(false)}
          updateBuyer={updateBuyer}
          isLoading={isLoading}
          setLoading={setLoading}
        />}

    </>
  )
}

export default IsLoadingHOC(IsLoggedinHOC(CreateOrder))