import React, { useEffect, useState, useMemo } from "react";
import GoogleMap from './GoogleMap';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { getOrderDetails, getOrderEtaApi } from '../../Redux/action/Orders';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getHashKey, orderTrackingTime, setDeliveryETA } from "../../Helper";
import { io } from "socket.io-client";
import { setOrderETA } from "../../Redux/action/App";


function TrackNewOrder(props) {
    const { setLoading } = props
    const params = useParams()
    const dispatch = useDispatch()
    const [orderDetail, setOrderDetail] = useState(null)
    const [driverDetails, setDriverDetails] = useState(null)
    const [driverLocation, setDriverLocation] = useState(null)
    const [progressBar, setProgressBar] = useState(false)
    const [estimateDeliveryTime, setEstimateDeliveryTime] = useState('')
    const [orderStatus, setOrderStatus] = useState('')
    const { orderETA } = useSelector(state => state.app)

    useEffect(() => {
        orderDetailData();
        getOrderEtaData();
    }, [])

    useEffect(() => {
        const socket = io(process.env.REACT_APP_BASEURL, { transports: ['polling'] });
        socket.on("order_eta_update", data => {
            dispatch(setOrderETA(data))
        });
        return () => {
            socket.disconnect()
        }
    }, [])


    useEffect(() => {
        if (orderETA) {
            const { order_id, eta_value, order_status, driver_location } = orderETA ? orderETA : {}
            if (order_id == params.id) {
                setEstimateDeliveryTime(eta_value)
                setOrderStatus(order_status)
                if (driver_location?.length === 2) {
                    const [lat, lng] = driver_location;
                    setDriverLocation({ lat, lng });
                }
            }
        }
    }, [orderETA])

    const googleMap = useMemo(() => {
        return <GoogleMap orderDetail={orderDetail} driverLocation={driverLocation} />;
    }, [orderDetail, driverLocation]);

    const getOrderEtaData = async () => {
        const payload = { order_id: params.id }
        let hash_key = getHashKey(payload);
        payload['hash'] = hash_key;
        setLoading(true)
        await dispatch(getOrderEtaApi(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == 1) {
                        const resData = response.data;
                        const live_eta = resData.eta_value;
                        setEstimateDeliveryTime(live_eta);
                    }
                },
                error => {
                    toast.error(error.response.data?.message)
                    setLoading(false);
                }
            )
    }

    const orderDetailData = async () => {
        const payload = { order_id: params.id }
        let hash_key = getHashKey(payload);
        payload['hash'] = hash_key;
        setLoading(true)
        await dispatch(getOrderDetails(payload))
            .then(async response => {
                setLoading(false);
                if (response.status == "1") {
                    const resData = (response.data && response.data.order) || {}
                    setOrderDetail(resData)
                    setOrderStatus(resData.status)
                    if (resData && resData?.driver) {
                        setDriverDetails(resData?.driver[0])
                        let location = resData?.driver[0].location
                        if (location?.length === 2) {
                            const [lat, lng] = location;
                            setDriverLocation({ lat, lng });
                        }
                    }
                } else {
                    toast.error(response.data?.message)
                }
            },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
    }

    return (
        <div className="track-location">
            <header id="header" className="fixed-top haeder-inner">
                <div className="container align-items-start">
                    <div className="col_top1">
                        <h3><i className="bi bi-arrow-left-short"></i>Tracking</h3>
                    </div>
                    <div className="row w-100 mt-4">
                        <div className="col-1 image-row" style={{ textAlign: "end", marginLeft: "1rem" }}>
                            <img src={require('../../assets/img/Group_940.png')} alt="shoplogo" />
                        </div>
                        <div className="col-1 add-none"></div>
                        <div className="col-9 card-text">
                            <div className="lib-row lib-header pick-up text-left pl-5">
                                <h5 className="zero-color">Pick Up address</h5>
                                <p className="one-color">{orderDetail?.sender_full_address}</p>
                                {/* <p className="two-color">Branch 1</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row w-100 mt-2">
                        <div className="col-1 image-row track-images" style={{ textAlign: 'end', marginLeft: "1rem" }}>
                            <img src={require('../../assets/img/Group_941.png')} alt="group" />
                        </div>
                        <div className="col-1 add-none"></div>
                        <div className="col-9 card-text">
                            <div className="lib-row lib-header pick-up text-left pl-5">
                                <h5 className="zero-color">Drop off Address</h5>
                                <p className="one-color">{orderDetail?.receiver_full_address}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: progressBar ? 'block' : 'none' }} className="row w-100 mt-4">
                        <div className="col-md-10 card-text">
                            <ul className="progressbar">
                                <li className="complete">
                                    <div></div>
                                    <div className="d-block">
                                        <h5 className="zero-color">Placed Order</h5><p><i className="bi bi-clock"></i>{orderTrackingTime(orderDetail?._created_at)}</p>
                                    </div>
                                </li>
                                <li className="complete">
                                    <div></div>
                                    <div className="d-block">
                                        <h5 className="zero-color">Accepted Order</h5><p><i className="bi bi-clock"></i>{orderTrackingTime(orderDetail?.acceptdate)}</p>
                                    </div>
                                </li>
                                <li className={(orderDetail && ['arrived', 'completed', 'picked'].includes(orderDetail.status)) ? "complete" : "active"}>
                                    <div></div>
                                    <div className="d-block">
                                        <h5 className="zero-color">Arrived To Shop</h5><p><i className="bi bi-clock"></i>{orderDetail?.arrived_to_shop_date !== "Not Available" ? orderTrackingTime(orderDetail?.arrived_to_shop_date) : "Not available"}</p>
                                    </div>
                                </li>
                                <li className={(orderDetail && ['completed', 'picked'].includes(orderDetail.status)) ? "complete" : "active"}>
                                    <div></div>
                                    <div className="d-block">
                                        <h5 className="zero-color">Picked Order</h5>{orderDetail && orderDetail.pickupdate && <p><i className="bi bi-clock"></i>{orderTrackingTime(orderDetail.pickupdate)}</p>}
                                    </div>
                                </li>
                                <li className={(orderDetail && ['completed', 'picked'].includes(orderDetail.status)) ? "complete" : "active"}>
                                    <div></div>
                                    <div className="d-block">
                                        <h5 className="zero-color">Out for Delivery</h5>{orderDetail && orderDetail.pickupdate && <p><i className="bi bi-clock"></i>{orderTrackingTime(orderDetail.pickupdate)}</p>}
                                    </div>
                                </li>
                                <li className={(orderDetail && (orderDetail.status === 'completed')) ? "complete" : "active half-complete"}>
                                    <div></div>
                                    <div className="d-block">
                                        <h5 className="zero-color">Delivered Order</h5>{orderDetail && orderDetail.status === 'completed' && <p><i className="bi bi-clock"></i>{orderTrackingTime(orderDetail.deliverydate)}</p>}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {progressBar ?
                        <div onClick={() => setProgressBar(!progressBar)} className="close-bottom"></div> :
                        <div onClick={() => setProgressBar(!progressBar)} className="slide-bottom"></div>
                    }
                </div>
            </header>
            <section id="hero" className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 d-flex flex-column justify-content-center p-0 pt-lg-0 order-2 order-lg-1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <div className="map_location">
                                {orderDetail && orderDetail._id && googleMap}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about" className="about about-inner">
                <div className="container aos-init aos-animate" data-aos="fade-up">
                    <div className="rowbox-shadow w-100">
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <img src={require('../../assets/img/clock.png')} alt="clocklogo" />
                            </div>
                            <div className="col-6 card-text">
                                {(!['arrived', 'completed'].includes(orderStatus)) && <h5 style={{ fontSize: '17px' }} className="text-secondary">{orderStatus === 'pending pickup' ? 'Pickup' : 'Delivery'} Estimate Time - {setDeliveryETA(estimateDeliveryTime)}</h5>}
                            </div>
                            <div className="col-5 card-text">
                                <div className="lib-row lib-header">
                                    <div className="con-icon"><h4 style={{ color: '#16507A' }} className="text-capitalize text-bold">{orderStatus}</h4></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about" className="about about-inner mt-2">
                <div className="container aos-init aos-animate images-persion" data-aos="fade-up">
                    <div className="row box-shadow w-100">
                        <div className="col-1 image-row image-per mr-4">
                            <img src={require('../../assets/img/user-con.png')} alt="" />
                        </div>
                        <div className="col-6 card-text ml-4">
                            <div className="lib-row lib-header text-left">
                                <h3>{orderDetail?.drivername}</h3>
                            </div>
                            <h4>{driverDetails?.car_type}</h4>
                            {driverDetails?.car_plates && <div className="car-sec">
                                <div className="sec-one">{driverDetails?.car_plates}</div>
                            </div>}
                        </div>
                        <div className="col-5 card-text">
                            <div className="lib-row lib-header">
                                <div className="con-icon pr-5">
                                    <a id="whatsappLink" href={`https://wa.me/${orderDetail?.driverphone}/?text=Hi`}>
                                        <img src={require('../../assets/img/mess-icon.png')} alt="mess_icon" className="img-fluid" />
                                    </a>
                                    <a id="callDriver" href={`tel:${orderDetail?.driverphone}`}>
                                        <img src={require('../../assets/img/call-icon.png')} alt="call_icon" className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default IsLoadingHOC(TrackNewOrder);

