import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addSocialLinks ,setFormCurrentStep } from '../../Redux/action/Shop';



const webUrl = /^(?=.{4,2048}$)((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]{1,63}(\.[a-zA-Z]{1,63}){1,5}(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

function SocialLink(props) {
    const dispatch = useDispatch()
    const { socialurl } = useSelector(state => state.shop)

    useEffect(() => {
        if (socialurl) {
            if (socialurl.facebook || socialurl.twitter || socialurl.google) {
                const fields = ["facebook", "twitter", "google"];
                fields.forEach(field => formik.setFieldValue(field, socialurl[field], false));
            }
        }
    }, [])

    const formik = useFormik({
        initialValues: { facebook: '', twitter: '', google: '' },

        validationSchema: Yup.object({
            facebook: Yup.string()
                .matches(webUrl, 'Website should be a valid URL!'),

            twitter: Yup.string()
                .matches(webUrl, 'Website should be a valid URL!'),

            google: Yup.string()
                .matches(webUrl, 'Website should be a valid URL!'),
        }),
        onSubmit: async values => {
            let fieldValues = values
            dispatch(addSocialLinks(fieldValues))
            dispatch(setFormCurrentStep("OPENING_HOURS"))
        }
    });

    const handlePrevious = () => {
        dispatch(setFormCurrentStep("SHOP_OTHER_INFO"))
    }

    return (
        <div className="card-body">
            <div className="tab-content" id="custom-tabs-one-tabContent">
                <div className="tab-pane fade active show " id="custom-social-link" role="tabpanel" aria-labelledby="social-link-tab">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Facebook</label>
                                    <input type="text" className="form-control" placeholder='Enter URL'
                                        name='facebook'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.facebook}
                                    />
                                    {formik.touched.facebook && formik.errors.facebook ? (<span className="text-danger">{formik.errors.facebook}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Twitter</label>
                                    <input type="text" className="form-control" placeholder='Enter URL'
                                        name='twitter'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.twitter}
                                    />
                                    {formik.touched.twitter && formik.errors.twitter ? (<span className="text-danger">{formik.errors.twitter}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Google+</label>
                                    <input type="text" className="form-control" placeholder='Enter URL'
                                        name='google'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.google}
                                    />
                                    {formik.touched.google && formik.errors.google ? (<span className="text-danger">{formik.errors.google}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="pb-5 footer-step">
                            <button type='button' className="btn btn-primary mr-2" onClick={handlePrevious} >Back</button>
                            <button type='submit' className="btn btn-success ">Next</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default IsLoadingHOC(IsLoggedinHOC(SocialLink))