import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { Link, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify'
import UserInfomation from './UserInfomation';
import ShopInfomation from './ShopInfomation';
import ShopOtherInfomation from './ShopOtherInfomation';
import SocialLink from './SocialLink';
import OpeningHours from './OpeningHours';
import ShopImage from './ShopImage';
import Maqsam from './Maqsam';
import ShopOterImages from './ShopOterImages';
import { getShopDetails } from '../../Redux/action/Shop';
import { getCities } from '../../Redux/action/City';
import { saveCityData } from '../../Redux/action/App';
import {
    addUserInfoMation,
    addShopInfoMation,
    addShopOtherInfoMation,
    addSocialLinks,
    addOpeningHours,
    addShopImage,
    addShopOtherImage,
    addMaqsam,
    setPShopId,
} from '../../Redux/action/Shop';



function UpdateShop(props) {
    const { setLoading } = props
    const { form_step } = useSelector(state => state.shop)
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const [searchParams] = useState({ id: "", name: "" })
    const [sorting] = useState({ order_in: -1, order_by: "_created_at" })
    const { currentPage } = location.state ? location.state : {}
    const [shopName, setShopName] = useState("");


    useEffect(() => {
        getCitiesData()
    }, [])

    const getCitiesData = async () => {
        const payload = {
            search: { ...searchParams },
            shorting: { order_in: sorting.order_in, order_by: sorting.order_by },
        }
        setLoading(true)
        await dispatch(getCities(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const cityData = []
                        const resData = response.data.cities
                        resData.map(item =>
                            cityData.push({ value: item._id, label: item.name })
                        )
                        dispatch(saveCityData(cityData))
                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    useEffect(() => {
        if (params.id) {
            getShopDetailsData();
        }
    }, [])

    const getShopDetailsData = async () => {
        setLoading(true)
        const payload = { is_detail: "true", user_id: params.id }
        await dispatch(getShopDetails(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        const result = response.data.user[0]
                        UpdateShopFormData(result);
                    }
                    else {
                        toast.error("Shop details is not found")
                    }
                },
                error => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    const UpdateShopFormData = async (data) => {
        const { username, email, shop_information, share_location_service_type, _p_shopid, vip_drivers, has_own_drivers, own_drivers } = data ? data : {}
        const {
            is_vip,
            shop,
            phone,
            mainsort,
            subsort,
            abbreviation,
            city,
            locationarea,
            locationaddress,
            shop_maincategory,
            shop_subcategory,
            use_alternative_name,
            alternative_name,
            active,
            publish,
            locationmap,
            description,
            sociallinks,
            hours,
            background_photo,
            icon,
            otherimages,
            share_location_customer_url,
            share_location_driver_url,
            accept_order_driver_url,
            fix_delivery_fee,
            fix_utrac_fee,
            fix_tax_fee,
            max_geofence,
            min_geofence
        } = shop_information ? shop_information : {}
        setShopName(shop)
        dispatch(addUserInfoMation({ username, phone, email, active }))
        dispatch(addShopInfoMation({
            shop,
            mainsort,
            subsort,
            abbreviation,
            city,
            locationarea,
            category: shop_maincategory,
            subcategory: shop_subcategory,
            locationaddress,
            share_location_service_type,
            max_geofence,
            min_geofence
        }))
        dispatch(addShopOtherInfoMation({
            use_alternative_name,
            alternative_name,
            publish,
            is_vip,
            has_own_drivers,
            own_drivers: own_drivers || [],
            vip_drivers: vip_drivers || [],
            latitude: locationmap[0],
            longitude: locationmap[1],
            description,
            fix_delivery_fee: fix_delivery_fee || 0,
            fix_utrac_fee: fix_utrac_fee || 0,
            fix_tax_fee: fix_tax_fee || 0
        }))
        dispatch(setPShopId(_p_shopid))
        dispatch(addSocialLinks({ ...sociallinks }))
        dispatch(addOpeningHours({ ...hours }))
        dispatch(addShopImage({ background_photo, icon, }))
        dispatch(addShopOtherImage(otherimages))
        dispatch(addMaqsam({ share_location_customer_url, share_location_driver_url, accept_order_driver_url }));
    }

    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1 className="m-0">Edit Shop {shopName ? `- (${shopName})` : ''}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><span to="/">Home</span></li>
                                <li className="breadcrumb-item active"><Link to={{ pathname: "/shops", state: { currentPage: currentPage } }}>Shops</Link></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary card-tabs">
                                <div className="card-header p-0 pt-1">
                                    <ul className="nav nav-tabs" >
                                        <li className="nav-item">
                                            <span className={`nav-link ${form_step === "USER_INFO" && "active"}`} >Shop User Info</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link ${form_step === "SHOP_INFO" && "active"}`}>Shop Info</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link ${form_step === "SHOP_OTHER_INFO" && "active"}`} >Shop Other Info</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link ${form_step === "SOCIAL_LINK" && "active"}`}>Social Links</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link ${form_step === "OPENING_HOURS" && "active"}`} >Opening Hours</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link ${form_step === "SHOP_IMAGE" && "active"}`} >Shop Images Links</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link ${form_step === "SHOP_OTHER_IMAGE" && "active"}`} >Other Images Links</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link ${form_step === "MAQSAM" && "active"}`} >Maqsam</span>
                                        </li>
                                    </ul>
                                </div>
                                {form_step === "USER_INFO" && <UserInfomation />}
                                {form_step === "SHOP_INFO" && <ShopInfomation />}
                                {form_step === "SHOP_OTHER_INFO" && <ShopOtherInfomation />}
                                {form_step === "SOCIAL_LINK" && <SocialLink />}
                                {form_step === "OPENING_HOURS" && <OpeningHours />}
                                {form_step === "SHOP_IMAGE" && <ShopImage />}
                                {form_step === "SHOP_OTHER_IMAGE" && <ShopOterImages />}
                                {form_step === "MAQSAM" && <Maqsam />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(UpdateShop))