import React, { useEffect } from 'react';
import { updateUserAdmin, getAdminUserDetails } from '../../Redux/action/User';
import { useDispatch } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC'
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { countryCode, phoneRegExp } from '../../Config/constants';

function EditAdmin(props) {
    const { setLoading } = props
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams()
    const location = useLocation()
    const { currentPage } = location.state ? location.state : {}


    useEffect(() => {
        getAdminUserDetailsData()
    }, [])

    const formik = useFormik({
        initialValues: {
            user_id: params.id,
            username: '',
            nickname: '',
            email: '',
            phone: '',
            is_active: false,
            is_available: false,
            is_verified: false,
            assign_roles: []
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required("This fiels is required")
                .min(3, 'This field must be at least 3 characters'),

            nickname: Yup.string()
                .required("This field is required")
                .min(3, 'This field must be at least 3 characters'),

            email: Yup.string()
                .required("This field is required"),

            phone: Yup.string()
                .matches(phoneRegExp, 'Phone number is not valid')
                .required("This field is required"),
        }),

        onSubmit: async values => {
            let fieldValues = { ...values }
            let phoneNumber = fieldValues.phone.toString();
            if (phoneNumber[0] === "+") {
                phoneNumber = phoneNumber.substring(1)
            }
            let phoneWithCountryCode = `${countryCode}${phoneNumber}`;
            fieldValues.phone = phoneWithCountryCode;
            setLoading(true)
            await dispatch(updateUserAdmin(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status === '1') {
                            toast.success(response.data.message)
                            history.push({ pathname: '/admins', state: { currentPage: currentPage } })
                        } else {
                            toast.error(response.data.error[0])
                        }
                    },
                    (error) => {
                        setLoading(false);
                        toast.error(error.response.data?.message)
                    }
                ).catch(error => console.log(error))
        },
    });

    const getAdminUserDetailsData = async () => {
        setLoading(true)
        await dispatch(getAdminUserDetails({ user_id: params.id }))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const user = response.data?.user || {}
                        const menu_permission = user?.allowed_menu_permission || []
                        const fields = ['username', 'nickname', 'email'];
                        fields.forEach(field => formik.setFieldValue(field, user[field], false));
                        formik.setFieldValue('assign_roles', menu_permission)

                        let phoneNumber = user.phone.toString();
                        let withoutCountryPhone = phoneNumber.replace(countryCode, '');
                        formik.setFieldValue('phone', withoutCountryPhone)

                        if (withoutCountryPhone[0] === "+") {
                            withoutCountryPhone = withoutCountryPhone.substring(1)
                            formik.setFieldValue('phone', withoutCountryPhone)
                        }

                        if (user.hasOwnProperty("active")) {
                            formik.setFieldValue('is_active', user.active)
                        }
                        if (user.hasOwnProperty("available")) {
                            formik.setFieldValue('is_available', user.available)
                        }
                        if (user.hasOwnProperty("verified")) {
                            formik.setFieldValue('is_verified', user.verified)
                        }
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }


    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Edit Admin</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={formik.handleSubmit} >
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Name</label>
                                                    <input
                                                        required
                                                        name="username"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.username}
                                                        onBlur={formik.handleBlur}
                                                        type="text" className="form-control" placeholder="User name" />
                                                    {formik.touched.username && formik.errors.username ? (<span className="text-danger">{formik.errors.username}</span>) : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Nick name</label>
                                                    <input
                                                        required
                                                        name="nickname"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.nickname}
                                                        onBlur={formik.handleBlur}
                                                        type="text" className="form-control" placeholder="Nick name" />
                                                    {formik.touched.nickname && formik.errors.nickname ? (<span className="text-danger " >{formik.errors.nickname}</span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group required">
                                                    <label className="control-label">Email address</label>
                                                    <input
                                                        required
                                                        name="email"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.email}
                                                        onBlur={formik.handleBlur}
                                                        type="email" className="form-control" placeholder="Email ID" />
                                                    {formik.touched.email && formik.errors.email ? (<span className="text-danger">{formik.errors.email}</span>) : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group required ">
                                                    <label className="control-label">Phone</label>
                                                    <div className="input-group">
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">{countryCode}</span>
                                                        </div>
                                                        <input
                                                            required
                                                            name="phone"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.phone}
                                                            onBlur={formik.handleBlur}
                                                            type="text" className="form-control" placeholder="Phone" />
                                                    </div>
                                                    {formik.touched.phone && formik.errors.phone ? (<span className="text-danger">{formik.errors.phone}</span>) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Active</label>
                                                    <select className="form-control"
                                                        name='is_active'
                                                        type='boolean'
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.is_active} >
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Verify</label>
                                                    <select className="form-control"
                                                        name="is_verified"
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.is_verified} >
                                                        <option value={true} >Yes</option>
                                                        <option value={false} >No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Available</label>
                                                    <select className="form-control"
                                                        name='is_available'
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.is_available} >
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pb-5 form-foot-btn">
                                            <button type="button" className="btn btn-primary mr-2" onClick={() => history.push({ pathname: '/admins', state: { currentPage: currentPage } })}>Back</button>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(EditAdmin))