import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getUserOtpApi } from '../../Redux/action/User';
import { handleCopyClipboard } from '../../Helper';


function ShowUserOTPModal(props) {
    const dispatch = useDispatch()
    const { modal, closeModal, user } = props
    const { username, phone } = user
    const [loading, setLoading] = useState(true)
    const [userOTP, setUserOTP] = useState("")


    useEffect(() => {
        if (phone) {
            getUserOtp();
        }
    }, [phone])


    const getUserOtp = async () => {
        const payload = { phone: phone }
        setLoading(true)
        await dispatch(getUserOtpApi(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == 1) {
                        const resData = response.data
                        setUserOTP(resData.otp)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                })
            .catch(error => console.log(error))
    }


    return (
        <div id="myModal" className={`modal fade cs--modal ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}>
            <div className="modal-dialog modal-confirm">
                <div className="modal-content">
                    <div className="modal-header flex-column">
                        <h5 className="modal-title w-100 text-center">{username}</h5>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div className="modal-body">
                        {userOTP && <h5 className="text-info">OTP : {userOTP}</h5>}

                        {!userOTP && !loading && <h5 className="text-warning">OTP does not exist!</h5>}

                    </div>
                    <div className="modal-footer justify-content-center">
                        {userOTP &&
                            <button onClick={() => handleCopyClipboard(userOTP)} type="button" disabled={loading} className="btn btn-primary">
                                Copy OTP
                            </button>
                        }
                        <button onClick={closeModal} type="button" disabled={loading} className="btn btn-secondary">
                            {loading ? "Processing" : "Cancel"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowUserOTPModal
