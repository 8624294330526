import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { SidebarProvider } from "./Context/SidebarContext";
import { OrderProvider } from './Context/OrderContext';
import { ToastContainer } from 'react-toastify';
import RootRouters from './Routing';

console.log("Updated React latest version")

function App() {
  return (
    <>
      <Router>
        <SidebarProvider>
          <OrderProvider>
            <RootRouters />
          </OrderProvider>
        </SidebarProvider>
      </Router>
      <ToastContainer
        position="top-right"
        theme="colored"
      />
    </>
  );
}

export default App;
