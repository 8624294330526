import React, { useContext, useEffect } from 'react';
import { uploadFileApi, removeFileImgAWS } from '../../Redux/action/Media';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updateAggregator } from '../../Redux/action/User';
import { trimFileName } from '../../Helper';
import { SidebarContext } from '../../Context/SidebarContext';


function EditAggregator(props) {
  const { modal, closeModal, isLoading, setLoading, getUpdatedList, userInfo } = props

  const { dispatch, toast } = useContext(SidebarContext)

  useEffect(() => {
    if (userInfo) {
      const fields = ["name", "logo"];
      fields.forEach(field => formik.setFieldValue(field, userInfo[field], false));
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      user_id: userInfo._id,
      status: userInfo.status,
      name: '',
      logo: ''
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .min(3, 'Name must be at least 3 characters'),
      logo: Yup.string()
        .required("Logo is required!")
    }),
    onSubmit: async values => {
      let payload = values
      setLoading(true)
      await dispatch(updateAggregator(payload))
        .then(
          response => {
            setLoading(false)
            if (response.status === '1') {
              toast.success(response.data.message)
              formik.resetForm();
              getUpdatedList();
              closeModal();
            } else {
              toast.error(response.data.error[0])
            }
          },
          error => {
            toast.error(error.response.data.data.message)
            setLoading(false)
          }
        )
    },
  });

  const handleChangeLogo = async (event) => {
    setLoading(true)
    if (formik.values.logo) {
      var initial_url = formik.values.logo
      const endPoint = initial_url.split('/')
      await removeFileFromAWS(endPoint[endPoint.length - 1])
    }
    const file = event.target.files[0]
    var formData = new FormData();
    formData.append(`type`, "logo");
    formData.append(`title`, "aggregator");
    formData.append('media', file);
    dispatch(uploadFileApi(formData))
      .then(
        response => {
          setTimeout(() => {
            setLoading(false)
          }, 2000)
          if (response.status === '1') {
            toast.success(response.data.message)
            const file_url = response.data.file_url
            formik.setFieldValue("logo", file_url)
          } else {
            toast.error(response.data.error[0])
          }
        },
        error => {
          const message = (error.response && error.response.statusText) || "something went wrong"
          toast.error(message)
          setLoading(false)
        }
      )
      .catch(error => {
        console.log(error)
        toast.error(error.message)
        setLoading(false)
      }
      )
  }

  const removeFileFromAWS = async (id) => {
    const payload = { type: "logo", file_name: id }
    await dispatch(removeFileImgAWS(payload))
      .then(
        response => {
          setLoading(false)
          if (response.status === '1') {
            toast.success(response.data.message)
          } else {
            toast.error(response.data.error[0])
          }
        })
      .catch(error => setLoading(false))
  }

  return (
    <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Edit Aggregator User</h5>
            <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className=''>
            <form onSubmit={formik.handleSubmit} >
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group required">
                      <label className="control-label">Name</label>
                      <input
                        required
                        name='name'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        placeholder="Enter name"
                        type="text" className="form-control" />
                      {formik.touched.name && formik.errors.name ? (<span className="text-danger">{formik.errors.name}</span>) : null}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-sm-12">
                    <div className="form-group required ">
                      <label className="control-label">Logo</label>
                      <div className="custom-file">
                        <input
                          name='logo'
                          accept="image/*"
                          onBlur={formik.handleBlur}
                          onChange={handleChangeLogo}
                          type="file" className="custom-file-input" id="exampleInputFile" />
                        <label className="custom-file-label" htmlFor="exampleInputFile">{formik.values.logo ? trimFileName(formik.values.logo) : 'Upload logo'}</label>
                      </div>
                      {formik.touched.logo && formik.errors.logo ? (<span className="text-danger">{formik.errors.logo}</span>) : null}
                      <img src={formik.values?.logo} className="file-upload-image pt-4" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex">
                <button type="button" onClick={closeModal} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button disabled={isLoading} type="submit" className="btn btn-success">Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditAggregator;