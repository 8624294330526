import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { selectOptionStyle } from '../../Helper';


function SelectReasonModal(props) {
    const { loader, modal, closeModal, handleSubmit, orderDetails } = props
    const { ordernumber } = orderDetails ? orderDetails : {}
    const [selectedReason, setSelectedReason] = useState('')
    const { globalData, userGlobalData } = useSelector(state => state.app)

    const { cancel_reasons } = userGlobalData ? userGlobalData : globalData ? globalData : []
    const [otherReason, setOtherReason] = useState('')
    const [isOther, setOther] = useState(false)

    const options = [
        ...cancel_reasons?.map(item => ({ value: item, label: item })),
        { value: 'other', label: 'Other' }
    ];

    const handleConfirmation = async (e) => {
        e.preventDefault();

        const trimmedOtherReason = otherReason?.trim();

        if (!selectedReason) {
            return toast.error("Reason is required!");
        }

        if (isOther && !trimmedOtherReason) {
            return toast.error("Other reason is required!");
        }

        if (isOther && trimmedOtherReason.length < 6) {
            return toast.error("Other reason must be at least 6 character");
        }

        await handleSubmit(selectedReason, trimmedOtherReason);
        setSelectedReason('');
        setOtherReason('');
        setOther(false);
    };


    const handleCancelModal = () => {
        setSelectedReason('')
        setOtherReason('')
        setOther(false)
        closeModal()
    }

    const handleSelect = (option) => {
        const { value } = option;
        setSelectedReason(value);

        if (value === 'other') {
            setOther(true);
        } else {
            setOther(false);
            setOtherReason('');
        }
    }


    return (
        <div id="myModal" className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLabel">Please select reason</h5>
                        <button onClick={handleCancelModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleConfirmation}>
                        <div className="modal-body">
                            {ordernumber && <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Order Number</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            defaultValue={ordernumber}
                                            placeholder='Driver phone'
                                            disabled />
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">Reason</label>
                                        <Select
                                            value={options.find(option => option.value === selectedReason)}
                                            onChange={handleSelect}
                                            options={options}
                                            placeholder='Select Reason'
                                            styles={selectOptionStyle}
                                        />
                                    </div>
                                </div>
                            </div>
                            {isOther &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group required">
                                            <label className="control-label">Other Reason</label>
                                            <textarea placeholder='Enter the other Reason'
                                                className="form-control"
                                                required
                                                rows={2}
                                                value={otherReason}
                                                onChange={e => setOtherReason(e.target.value)}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                        <div className="modal-footer model-form-savbtn">
                            <button type="button" onClick={handleCancelModal} className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">{loader ? "Processing" : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default SelectReasonModal