// import React from 'react'
// import { useJwt } from 'react-jwt';
// import { useSelector } from 'react-redux';
// import SessionExpired from '../ConfirmModel/SessionExpired';

// const IsLoggedinHOC = (WrappedComponent) => {
//     const HocComponent = ({ ...props }) => {
//         const token = useSelector(state => state.auth.token)
//         const {isExpired } = useJwt(token);
//         if (isExpired) {
//             return (
//                 <SessionExpired/>
//             )
//         } else {
//             return (
//                 <WrappedComponent {...props} isTokenExpired={isExpired} />
//             )
//         }
//     }
//     return HocComponent
// }
// export default IsLoggedinHOC



import React, { useEffect, useState } from 'react';
import {decodeToken , isExpired } from 'react-jwt';
import { useSelector } from 'react-redux';
import SessionOutModal from '../SessionOut/PopupModal';

const IsLoggedinHOC = (WrappedComponent) => {
  const HocComponent = ({ ...props }) => {
    const token = useSelector((state) => state.auth.token);
    let isMyTokenExpired = isExpired(token);
    const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);

    useEffect(() => {
      const checkTokenValidity = () => {
       let  isMyTokenExpired = isExpired(token);
        if (isMyTokenExpired) {
          setShowSessionExpiredModal(true);
        }
      };
      checkTokenValidity();
      const tokenValidityInterval = setInterval(checkTokenValidity, 10000);
      return () => clearInterval(tokenValidityInterval);
    }, [isMyTokenExpired]);

    if (isMyTokenExpired) {
      return (
        <div>
          {showSessionExpiredModal && <SessionOutModal />}
        </div>
      );
    } else {
      return <WrappedComponent {...props} isTokenExpired={isMyTokenExpired} />;
    }
  };
  return HocComponent;
};

export default IsLoggedinHOC;
