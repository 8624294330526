import React, { useState, useEffect, useContext } from 'react';
import { addPrice, getPriceDetails, getPrices, updatePrice } from '../../Redux/action/price';
import { SidebarContext } from '../../Context/SidebarContext';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';


function ManageDeliveryPrice(props) {
    const { setLoading, isLoading, order_id, modal, closeModal, user } = props
    const [pricesdata, setPricesdata] = useState([])
    const [searchParams, setSearchParams] = useState({ user_id: user._id, title: "", delivery_fee: "", utrac_fee: "", tax_fee: "" })
    const [sorting, setSorting] = useState({ order_in: 1, order_by: 'delivery_fee' })
    const [priceId, setPriceId] = useState("")
    const [activeTab, setActiveTab] = useState("add")


    const {
        dispatch,
        totalCount,
        toast,
        setTotalCount,
        currentPage,
        resetForm,
        handleSubmit,
        setcurrentPage,
        itemsPerPage,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
    } = useContext(SidebarContext)


    useEffect(() => {
        getPricesData();
    }, [currentPage, sorting])

    const getPricesData = async () => {
        const payload = {
            search: searchParams,
            shorting: sorting,
            pagination: { start: currentPage, length: 1000 }
        }
        setLoading(true)
        await dispatch(getPrices(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setPricesdata(response.data.prices)
                        setTotalCount(response.data.total_count)
                    } else {
                        setPricesdata([])
                        setTotalCount(null)
                    }
                },
                error => {
                    setPricesdata([])
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
    }

    useEffect(() => {
        if (priceId) {
            getPriceDetailsData();
        }
    }, [priceId])


    const getPriceDetailsData = async () => {
        const payload = {
            is_detail: true,
            search: { _id: priceId },
        }
        setLoading(true)
        await dispatch(getPriceDetails(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const pricedetails = response.data.price || {}
                        const fields = ["title", "delivery_fee", "utrac_fee", "tax_fee"];
                        fields.forEach(field => formik.setFieldValue(field, pricedetails[field], false));
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
            .catch(
                error => {
                    console.log(error)
                }
            )
    }

    const formik = useFormik({
        initialValues: {
            user_id: user._id,
            title: '',
            delivery_fee: '',
            utrac_fee: 0,
            tax_fee: 0
        },

        validationSchema: Yup.object({

            title: Yup.string()
                .required("This fiels is required")
                .min(3, 'This field must be at least 3 characters'),

            delivery_fee: Yup.number()
                .typeError('You must specify a number')
                .min(0, "Please enter a positive number")
                .required("This field is required"),

            utrac_fee: Yup.number()
                .typeError('You must specify a number')
                .min(0, "Please enter a positive number")
                .required("This field is required"),

            tax_fee: Yup.number()
                .typeError('You must specify a number')
                .min(0, "Please enter a positive number")
                .required("This field is required"),

        }),

        onSubmit: async values => {
            const fieldValues = values;
            if (priceId) {
                Object.assign(fieldValues, { price_id: priceId })
            }
            setLoading(true);
            try {
                const response = await dispatch(priceId ? updatePrice(fieldValues) : addPrice(fieldValues));
                setLoading(false);
                if (response.status === '1') {
                    toast.success(response.data.message);
                    formik.resetForm();
                    if(priceId){
                        setActiveTab("list")
                        setPriceId("")
                    }
                } else {
                    toast.error(response.data.error[0]);
                }
            } catch (error) {
                setLoading(false);
                toast.error(error.response.data?.message || 'An error occurred');
                console.log(error);
            }
        }
    });

    const handleChangeTab = (type) => {
        setActiveTab(type)
        if (type === "add") {
            setPriceId("")
            formik.resetForm();
        }
        if (type === "list") {
            getPricesData();
        }
    }

    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className='d-flex gap-2 justify-content-between p-3'>
                        <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Manage Delivery Type</h5>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="row p-2">
                        <div className="col-12">
                            <div className="card card-secondary card-tabs">
                                <div className="card-header p-0">
                                    <ul className="nav nav-tabs" id="myTabs" role="tablist">
                                        <li className="nav-item" onClick={() => handleChangeTab("add")}>
                                            <a className={`${activeTab === "add" ? "active" : ""} nav-link`} data-toggle="tab" role="tab">Add Delivery</a>
                                        </li>
                                        <li className="nav-item" onClick={() => handleChangeTab("list")}>
                                            <a className={`${activeTab === "list" ? "active" : ""} nav-link`} data-toggle="tab" role="tab" >Delivery List</a>
                                        </li>
                                        {activeTab === "edit" &&
                                            <li className="nav-item" onClick={() => handleChangeTab("edit")}>
                                                <a className={`${activeTab === "edit" ? "active" : ""} nav-link`} data-toggle="tab" role="tab" >Edit Delivery</a>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body overflow-auto detail--modal--scroll">
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content">
                                    {(activeTab === 'add' || activeTab === 'edit') &&
                                        <div className="tab-pane fade show active" role="tabpanel" >
                                            <form onSubmit={formik.handleSubmit} >
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group required">
                                                            <label className="control-label">Title</label>
                                                            <input
                                                                required
                                                                name="title"
                                                                placeholder='Title name'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.title}
                                                                type="text" className="form-control" />
                                                            {formik.touched.title && formik.errors.title ? (<span className="text-danger">{formik.errors.title}</span>) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group required">
                                                            <label className="control-label">Delivery Fee</label>
                                                            <input
                                                                required
                                                                name="delivery_fee"
                                                                placeholder='Delivery fee'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.delivery_fee}
                                                                type="number" className="form-control" />
                                                            {formik.touched.delivery_fee && formik.errors.delivery_fee ? (<span className="text-danger">{formik.errors.delivery_fee}</span>) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group required">
                                                            <label className="control-label">Utrac Fee</label>
                                                            <input
                                                                required
                                                                name="utrac_fee"
                                                                placeholder='Utrac fee'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.utrac_fee}
                                                                type="number" className="form-control" />
                                                            {formik.touched.utrac_fee && formik.errors.utrac_fee ? (<span className="text-danger">{formik.errors.utrac_fee}</span>) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group required">
                                                            <label className="control-label">Tax Fee</label>
                                                            <input
                                                                required
                                                                name="tax_fee"
                                                                placeholder='Tax fee'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.tax_fee}
                                                                type="number" className="form-control" />
                                                            {formik.touched.tax_fee && formik.errors.tax_fee ? (<span className="text-danger">{formik.errors.tax_fee}</span>) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-foot-btn">
                                                    <button type="submit" className="btn btn-success">{priceId ? "Update" : "Save"}</button>
                                                </div>
                                            </form>
                                        </div>
                                    }
                                    {activeTab === 'list' &&
                                        <div className="tab-pane fade show active" role="tabpanel">
                                            {pricesdata?.length > 0 ?
                                                (
                                                    <table className="table table-hover">
                                                        <thead className="thead-light ">
                                                            <tr>
                                                                <th>Sr. No</th>
                                                                <th>Title</th>
                                                                <th>Delivery Fee</th>
                                                                <th>Utrac Fee</th>
                                                                <th>Tax Fee</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {pricesdata.map((item, index) =>
                                                            (<tr key={item._id} >
                                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                <td>{item.title ? item.title : "Not Available"}</td>
                                                                <td>{item.delivery_fee === 0 ? "0" : item.delivery_fee ? item.delivery_fee : "NA"}</td>
                                                                <td>{item.utrac_fee === 0 ? "0" : item.utrac_fee ? item.utrac_fee : "NA"}</td>
                                                                <td>{item.tax_fee === 0 ? "0" : item.tax_fee ? item.tax_fee : "NA"}</td>
                                                                <td>
                                                                    <div className="action_button">
                                                                        <div className="btn-group">
                                                                            <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                                            </button>
                                                                            <div className="dropdown-menu">
                                                                                <span className="dropdown-item"
                                                                                    onClick={() => {
                                                                                        setPriceId(item._id)
                                                                                        setActiveTab("edit")
                                                                                    }}
                                                                                >
                                                                                    <span className="blue_1">
                                                                                        <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>)
                                                            )}
                                                        </tbody>
                                                    </table>
                                                )
                                                :
                                                <div className='not-found-data p-5'>
                                                    <h2>No records available...</h2>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageDeliveryPrice

