import React from 'react';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

const Authorization = allowedRoles => WrappedComponent => {
    const WithAuthorization = (props) => {
        const location = useLocation();
        const currentPath = location.pathname;
        const { user } = useSelector(state => state.auth) || {};
        const { type, allowed_menu_permission } = user;

        const isAuthorized = (current_path) => {
            if (!allowed_menu_permission || allowed_menu_permission.length === 0) {
                return true;
            }
            return allowed_menu_permission.some(menuItem => (
                menuItem.submenus
                    ? menuItem.submenus.some(submenu => submenu.path === current_path || currentPath.split('/')?.length === 3)
                    : menuItem.path === current_path
            ));
        };

        return allowedRoles.includes(type) && isAuthorized(currentPath) ? (
            <WrappedComponent {...props} />
        ) : (
            <div className='bg-light'>
                <div className='unauth-content'>
                    <h2>You don't have permission to access.</h2>
                </div>
            </div>
        );
    };

    return WithAuthorization;
};

export default Authorization;
