import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { addlogisticsApi, getUserForSearchBar } from '../../Redux/action/User';
import { default as ReactSelect } from "react-select";
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { phoneRegExp, countryCode } from '../../Config/constants';
import { getAreas } from '../../Redux/action/Area';
import { debounce } from 'lodash';
import { selectOptionStyle } from '../../Helper';


function CreateLogistic(props) {
  const { setLoading } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedArea, setSelectedArea] = useState(null)
  const [areas, setAreas] = useState([])
  const { cityData } = useSelector(state => state.app)
  const [driverFetching, setDriverFetching] = useState(false)
  const [searchedDrivers, setSearchedDrivers] = useState([])
  const [selectedSearchedDriver, setSelectedSearchedDriver] = useState("");

  const formik = useFormik({
    initialValues: {
      username: '',
      nickname: '',
      email: '',
      phone: '',
      password: '',
      confirm_password: '',
      drivers: [],
      active: true,
      description: '',
      latitude: '',
      longitude: '',
      city: "",
      locationarea: "",
      locationaddress: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required("This fiels is required")
        .min(3, 'This field must be at least 3 characters'),

      nickname: Yup.string()
        .required("This field is required")
        .min(3, 'This field must be at least 3 characters'),

      email: Yup.string()
        .required("This field is required"),

      phone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required("This field is required"),

      latitude: Yup.number()
        .typeError('You must specify a number')
        .required("This field is required"),

      longitude: Yup.number()
        .typeError('You must specify a number')
        .required("This field is required"),

      description: Yup.string()
        .required("This field is required")
        .min(5, 'Description must be at least 5 characters'),

      password: Yup.string()
        .required("This field is required")
        .min(5, 'Password must be at least 5 characters'),

      confirm_password: Yup.string()
        .required("This field is required")
        .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),

      drivers: Yup.array()
        .required('This field is required')
        .min(1, 'This field is required'),

      city: Yup.string()
        .required("This field is required"),

      locationarea: Yup.string()
        .required("This field is required")
        .min(3, 'Locationarea must be at least 3 characters'),

      locationaddress: Yup.string()
        .required("This field is required")
        .min(5, 'Location address must be at least 5 characters'),

    }),
    onSubmit: async values => {
      let fieldValues = { ...values }
      let locationMap = [fieldValues.latitude, fieldValues.longitude]
      Object.assign(fieldValues, {
        "location": locationMap
      });
      let phoneNumber = fieldValues.phone.toString();
      if (phoneNumber[0] === "+") {
        phoneNumber = phoneNumber.substring(1)
      }
      let phoneWithCountryCode = `${countryCode}${phoneNumber}`;
      fieldValues.phone = phoneWithCountryCode;
      setLoading(true)
      await dispatch(addlogisticsApi(fieldValues))
        .then(
          response => {
            setLoading(false)
            if (response.status === '1') {
              toast.success(response.data.message)
              formik.resetForm();
              history.push('/logistics')
            } else {
              toast.error(response.data.error[0])
            }
          },
          (error) => {
            setLoading(false);
            toast.error(error.response.data?.message)
          }
        ).catch(error => console.log(error))
    },
  });

  // Get Areas Drop down list selected by City
  const getAreasData = async (cityid) => {
    const payload = {
      search: { name: "", city_id: cityid },
      shorting: { order_in: -1, order_by: "_created_at" },
    }
    setLoading(true)
    await dispatch(getAreas(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status === "1") {
            const areaDropList = []
            const resData = response.data.areas || []
            resData.forEach(item => {
              areaDropList.push({ value: item._id, label: item.name })
            })
            setAreas(areaDropList)
          } else {
            toast.error('Location area not found selected by city')
            setAreas([])
            setSelectedArea(null)
          }
        },
        (error) => {
          setLoading(false);
          toast.error(error.response.data.message)
        }
      )
  }

  const handleChangeBooleanValue = (e) => {
    let { name, value } = e.target
    value = JSON.parse(value)
    formik.setFieldValue(name, value)
  }

  const handleCitySelect = option => {
    const { value, label } = option || {}
    if (value || label) {
      setSelectedCity(option)
      formik.setFieldValue('city', label);
      getAreasData(value)
    } else {
      setSelectedCity(null)
      formik.setFieldValue('city', "");
      setAreas([])
      setSelectedArea(null)
      formik.setFieldValue('locationarea', "");
    }
  }

  const handleAreaSelect = option => {
    const { value, label } = option || {}
    if (value || label) {
      setSelectedArea(option)
      formik.setFieldValue('locationarea', label);
    } else {
      setSelectedArea(null)
      formik.setFieldValue('locationarea', "");
    }
  }

  // load options using API call with searched Drivers
  const getSearchedDrivers = async (searchDriver) => {
    const digitNumbers = /^-?[\+|\d.]+(?:e-?\d+)?$/
    let payload = {
      search: { id: '', type: "Driver", nickname: '', username: "", phone: '' },
      shorting: { order_in: "asc", order_by: "nickname" }
    }
    if (digitNumbers.test(searchDriver)) {
      let phoneNumber = searchDriver.toString();
      if (phoneNumber[0] === "+") {
        phoneNumber = phoneNumber.substring(1)
      }
      payload.search.phone = phoneNumber
    } else {
      payload.search.nickname = searchDriver
    }
    setDriverFetching(true)
    await dispatch(getUserForSearchBar(payload))
      .then(
        response => {
          setDriverFetching(false)
          if (response.status == "1") {
            const resData = response.data.users
            const alldrivers = []
            resData.forEach(item => {
              const { _id, nickname, username, phone } = item
              const labelwithPhone = `${nickname ? nickname : username} (${phone})`
              alldrivers.push({ label: labelwithPhone, value: _id })
            })
            setSearchedDrivers(alldrivers)
          } else {
            setSearchedDrivers([])
          }
        },
        error => {
          setSearchedDrivers([])
          setDriverFetching(false)
          toast.error(error.response.data?.message)
        }
      )
  };

  const getDriversData = useCallback(debounce((searchvalue) => getSearchedDrivers(searchvalue), 500), []);
  const handleSearchDriver = (inputValue) => {
    const value = inputValue.trim()
    if (value && value.length > 0) {
      getDriversData(value);
    }
  }

  const handleSelectSearchedDriver = (options) => {
    setSelectedSearchedDriver(options);
    const optionsData = []
    options.map(item => optionsData.push(item.value))
    formik.setFieldValue('drivers', optionsData);
  }

  return (
    <div className="content-wrapper">
      <div className="content-header main_breadcrumb_top">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Create delivery service provider</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <form onSubmit={formik.handleSubmit} >
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label" >User Name</label>
                          <input
                            required
                            name="username"
                            onChange={formik.handleChange}
                            value={formik.values.username}
                            onBlur={formik.handleBlur}
                            type="text" className="form-control" placeholder="User name" />
                          {formik.touched.username && formik.errors.username ? (<span className="text-danger">{formik.errors.username}</span>) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Nick Name</label>
                          <input
                            required
                            name="nickname"
                            onChange={formik.handleChange}
                            value={formik.values.nickname}
                            onBlur={formik.handleBlur}
                            type="text" className="form-control" placeholder="Nick name" />
                          {formik.touched.nickname && formik.errors.nickname ? (<span className="text-danger " >{formik.errors.nickname}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Email address</label>
                          <input
                            required
                            name="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            type="email" className="form-control" placeholder="Email" />
                          {formik.touched.email && formik.errors.email ? (<span className="text-danger">{formik.errors.email}</span>) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required ">
                          <label className="control-label">Phone</label>
                          <div className="input-group">
                            <div className="input-group-append">
                              <span className="input-group-text">{countryCode}</span>
                            </div>
                            <input
                              required
                              name="phone"
                              onChange={formik.handleChange}
                              value={formik.values.phone}
                              onBlur={formik.handleBlur}
                              type="text" className="form-control" placeholder="Phone" />
                          </div>
                          {formik.touched.phone && formik.errors.phone ? (<span className="text-danger">{formik.errors.phone}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">City</label>
                          <ReactSelect placeholder="Select City"
                            isClearable={true}
                            options={cityData}
                            hideSelectedOptions={false}
                            value={selectedCity}
                            onBlur={formik.handleBlur}
                            onChange={(option) => handleCitySelect(option)}
                            styles={selectOptionStyle}
                          />
                          {formik.touched.city && formik.errors.city ? (<span className="text-danger">{formik.errors.city}</span>) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Location Area</label>
                          <ReactSelect placeholder="Select Area"
                            isClearable={true}
                            options={areas}
                            hideSelectedOptions={false}
                            value={selectedArea}
                            onBlur={formik.handleBlur}
                            onChange={(option) => handleAreaSelect(option)}
                            styles={selectOptionStyle}
                          />
                          {formik.touched.locationarea && formik.errors.locationarea ? (<span className="text-danger">{formik.errors.locationarea}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Active</label>
                          <select name='active' className="custom-select"
                            value={formik.values.active}
                            onChange={handleChangeBooleanValue}>
                            <option value={false}>No</option>
                            <option value={true}>Yes</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Drivers</label>
                          <ReactSelect placeholder="Search driver"
                            isClearable={true}
                            isLoading={driverFetching}
                            isMulti={true}
                            options={searchedDrivers}
                            value={selectedSearchedDriver}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            onInputChange={handleSearchDriver}
                            onChange={handleSelectSearchedDriver}
                            styles={selectOptionStyle}
                          />
                          {formik.touched.drivers && formik.errors.drivers ? (<span className="text-danger">{formik.errors.drivers}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Password</label>
                          <input
                            required
                            name="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            onBlur={formik.handleBlur}
                            type={showPassword ? 'text' : 'password'}
                            className="form-control" placeholder="Password" />
                          <i className={showPassword ? "fas fa-eye" : " fas fa-eye-slash"} onClick={() => setShowPassword(!showPassword)} id="eye"></i>
                          {formik.touched.password && formik.errors.password ? (<span className="text-danger " >{formik.errors.password}</span>) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Confirm password</label>
                          <input
                            required
                            name="confirm_password"
                            onChange={formik.handleChange}
                            value={formik.values.confirm_password}
                            onBlur={formik.handleBlur}
                            type={showConfirmPassword ? 'text' : 'password'}
                            className="form-control" placeholder="Confirm password" />
                          <i className={showConfirmPassword ? "fas fa-eye" : " fas fa-eye-slash"} id="eye" onClick={() => setShowConfirmPassword(!showConfirmPassword)} ></i>
                          {formik.touched.confirm_password && formik.errors.confirm_password ? (<span className="text-danger">{formik.errors.confirm_password}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Map Location: Latitude</label>
                          <input className="form-control" placeholder='31.988485'
                            required
                            name='latitude'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.latitude}
                            type="number" />
                          {formik.touched.latitude && formik.errors.latitude ? (<span className="text-danger">{formik.errors.latitude}</span>) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Map Location : Longitude</label>
                          <input className="form-control" placeholder='36.951485'
                            required
                            name='longitude'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.longitude}
                            type="number" />
                          {formik.touched.longitude && formik.errors.longitude ? (<span className="text-danger">{formik.errors.longitude}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Full Address</label>
                          <input className="form-control" placeholder='Full address'
                            required
                            name='locationaddress'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.locationaddress}
                            type="text" />
                          {formik.touched.locationaddress && formik.errors.locationaddress ? (<span className="text-danger ">{formik.errors.locationaddress}</span>) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Description</label>
                          <textarea className="form-control" placeholder='Enter description'
                            required
                            name='description'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                            rows="1">
                          </textarea>
                          {formik.touched.description && formik.errors.description ? (<span className="text-danger">{formik.errors.description}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-foot-btn d-flex justify-content-center mt-3">
                      <button type="button" className="btn btn-primary mr-2" onClick={() => history.push('/logistics')}>Back</button>
                      <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default IsLoadingHOC(IsLoggedinHOC(CreateLogistic))