import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { addOpeningHours, setFormCurrentStep, updateShop } from '../../Redux/action/Shop';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { timeOptions } from '../../utils/selectOptions';
import Select from 'react-select';
import { selectOptionStyle } from '../../Helper';



function OpeningHours(props) {
    const { userInfo, shopInfo, shopOtherInfo, socialurl, openingHours, shopImage, shopOtherImages, p_shopid } = useSelector(state => state.shop)
    const { setLoading } = props
    const dispatch = useDispatch()
    const params = useParams();
    let formRef = useRef()

    const [monday, setMonday] = useState({ opening_time: '', closing_time: '' })
    const [tuesday, setTuesday] = useState({ opening_time: '', closing_time: '' })
    const [wednesday, setWednesday] = useState({ opening_time: '', closing_time: '' })
    const [thursday, setThursday] = useState({ opening_time: '', closing_time: '' })
    const [friday, setFriday] = useState({ opening_time: '', closing_time: '' })
    const [saturday, setSaturday] = useState({ opening_time: '', closing_time: '' })
    const [sunday, setSunday] = useState({ opening_time: '', closing_time: '' })


    useEffect(() => {
        if (openingHours) {
            const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            for (const day of days) {
                const dayData = openingHours[day];
                if (dayData && dayData.opening_time && dayData.closing_time) {
                    eval(`set${day.charAt(0).toUpperCase() + day.slice(1)}(dayData)`);
                }
            }
        }
    }, [openingHours]);

    const isFormValidated = () => {
        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        for (const day of days) {
            if (!eval(`${day.toLowerCase()}.opening_time`)) {
                toast.error(`All fields are required!!`);
                return false;
            }
            if (!eval(`${day.toLowerCase()}.closing_time`)) {
                toast.error(`All fields are required!!`);
                return false;
            }
        }
        return true;
    }

    const handleNextStep = () => {
        const payload = {
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday,
            sunday: sunday
        }
        if (isFormValidated()) {
            dispatch(addOpeningHours(payload))
            dispatch(setFormCurrentStep("SHOP_IMAGE"))
        }
    }

    const handlePrevious = () => {
        dispatch(setFormCurrentStep("SOCIAL_LINK"))
    }

    const handleSubmitData = async () => {
        const openingHoursData = {
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday,
            sunday: sunday
        }
        const payload = {
            user_id: params.id,
            shop_id: p_shopid,
            ...userInfo,
            ...shopInfo,
            ...shopOtherInfo,
            ...shopImage,
            hours: openingHoursData,
            otherimages: [...shopOtherImages],
            sociallinks: { ...socialurl },
        }
        if (isFormValidated()) {
            setLoading(true)
            await dispatch(updateShop(payload))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status === '1') {
                            toast.success(response.data.message)
                        } else {
                            toast.error(response.data?.error[0])
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                        console.log(error)
                    }
                )
        }
    }

    const changeMondayTime = (option, name) => {
        const { value } = option
        setMonday((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const changeTuesdayTime = (option, name) => {
        const { value } = option
        setTuesday((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const changeWednesdayTime = (option, name) => {
        const { value } = option
        setWednesday((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const changeThursdayTime = (option, name) => {
        const { value } = option
        setThursday((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const changeFridayTime = (option, name) => {
        const { value } = option
        setFriday((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const changeSaturdayTime = (option, name) => {
        const { value } = option
        setSaturday((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const changeSundayTime = (option, name) => {
        const { value } = option
        setSunday((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    return (
        <div className="container">
            <div className="card-body">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name of Day</th>
                            <th>Open Time</th>
                            <th>Close Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='day-name'>Monday</td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === monday.opening_time)}
                                    onChange={(option) => changeMondayTime(option, 'opening_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}

                                />
                            </td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === monday.closing_time)}
                                    onChange={(option) => changeMondayTime(option, 'closing_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='day-name'>Tuesday</td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === tuesday.opening_time)}
                                    onChange={(option) => changeTuesdayTime(option, 'opening_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === tuesday.closing_time)}
                                    onChange={(option) => changeTuesdayTime(option, 'closing_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='day-name'>Wednesday</td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === wednesday.opening_time)}
                                    onChange={(option) => changeWednesdayTime(option, 'opening_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === wednesday.closing_time)}
                                    onChange={(option) => changeWednesdayTime(option, 'closing_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='day-name'>Thursday</td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === thursday.opening_time)}
                                    onChange={(option) => changeThursdayTime(option, 'opening_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === thursday.closing_time)}
                                    onChange={(option) => changeThursdayTime(option, 'closing_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='day-name'>Friday</td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === friday.opening_time)}
                                    onChange={(option) => changeFridayTime(option, 'opening_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === friday.closing_time)}
                                    onChange={(option) => changeFridayTime(option, 'closing_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='day-name'>Saturday</td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === saturday.opening_time)}
                                    onChange={(option) => changeSaturdayTime(option, 'opening_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === saturday.closing_time)}
                                    onChange={(option) => changeSaturdayTime(option, 'closing_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='day-name'>Sunday</td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === sunday.opening_time)}
                                    onChange={(option) => changeSundayTime(option, 'opening_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                            <td>
                                <Select
                                    value={timeOptions.find(option => option.value === sunday.closing_time)}
                                    onChange={(option) => changeSundayTime(option, 'closing_time')}
                                    options={timeOptions}
                                    styles={selectOptionStyle}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="pb-5 footer-step">
                    <button type='button' className="btn btn-primary mr-2" onClick={handlePrevious} >Back</button>
                    <button type='button' ref={formRef} className="btn btn-success mr-2"
                        onClick={() => { formRef.current.name = 'update'; handleSubmitData() }} >Update</button>
                    <button type='button' ref={formRef} className="btn btn-info"
                        onClick={() => { formRef.current.name = 'next'; handleNextStep() }} >Next</button>
                </div>
            </div>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(OpeningHours))