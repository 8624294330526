import { authAxios } from "../../Config/axios"
import { toast } from 'react-toastify';
import { countryCode } from "../../Config/constants";
import { trimObjValues } from "../../Helper";


export const getUserDetails = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_user_detail", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const updateDriverDetails = (data) => async _dispatch => {
    let fieldsData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/app/driver/update_driver", fieldsData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error.response.data)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getAdminUserDetails = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_user_detail", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getUsersList = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_users", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}
export const getDriversRoster = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_shift_drivers", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getAdminUsers = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_admin_users", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getAdminUserRoles = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_roles", data)
            .then(
                response => {
                    resolve(response.data)
                    const data = response.data.data.roles
                    const roleData = []
                    data && data.length > 0 && data.map(item => {
                        roleData.push({
                            label: item.name,
                            value: item._id
                        })
                    })
                    _dispatch({ type: "SET_ROLES", payload: roleData });
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const addNewUser = (data) => async _dispatch => {
    const payload = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/add_user", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const deleteUser = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/delete_user", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const updateUserDetails = (data) => async _dispatch => {
    const payload = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/update_user", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const removeDriverJobStartPointApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/remove_driver_job_start_point", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}


export const updateDriverAvailability = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/update_driver_availability", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getUserForSearchBar = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_users_for_search_bar", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const updateUserAdmin = (data) => async _dispatch => {
    const payload = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/update_user", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const addAdminAction = (data) => async _dispatch => {
    const payload = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/add_admin_user", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const createBuyer = (data) => async _dispatch => {
    const payload = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/create_buyer", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const checkExistUser = (data) => async _dispatch => {
    const payloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/user_exist", payloadData)
            .then(
                response => {
                    if (response.data.status === "1") {
                        resolve(response.data)
                    }
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    toast.error(error)
                }
            )
    })
}

export const getUsersReports = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_users", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const removeDriverAccount = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/app/driver/remove_account", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const addnewIntegrator = (data) => async _dispatch => {
    let fieldsData = trimObjValues(data)
    let payload = { ...fieldsData }
    // To Merging Country Code 
    let phoneNumber = payload?.phone.toString();
    if (phoneNumber[0] === "+") {
        phoneNumber = phoneNumber.substring(1)
    }
    let phoneWithCountryCode = `${countryCode}${phoneNumber}`;
    payload.phone = phoneWithCountryCode;
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/integrators/add_integrator", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getIntegratorUsers = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/integrators/get_integrator_users", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getIntegratorUserDetails = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/integrators/get_integrator_users", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const updateIntegratorUser = (data) => async _dispatch => {
    let fieldsData = trimObjValues(data)
    let payload = { ...fieldsData }
    // To Merging Country Code 
    let phoneNumber = payload?.phone.toString();
    if (phoneNumber[0] === "+") {
        phoneNumber = phoneNumber.substring(1)
    }
    let phoneWithCountryCode = `${countryCode}${phoneNumber}`;
    payload.phone = phoneWithCountryCode;
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/integrators/update_integrator", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getDriverLastLocation = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_driver_last_location", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}


export const addShopVendor = (data) => async _dispatch => {
    let fieldsData = trimObjValues(data)
    let payload = { ...fieldsData }
    // To Merging Country Code 
    let phoneNumber = payload?.phone.toString();
    if (phoneNumber[0] === "+") {
        phoneNumber = phoneNumber.substring(1)
    }
    let phoneWithCountryCode = `${countryCode}${phoneNumber}`;
    payload.phone = phoneWithCountryCode;
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shop_vendor/add_shop_vendor", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getShopVendors = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shop_vendor/get_shop_vendor_users", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getShopVendorDetails = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shop_vendor/get_shop_vendor_users", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const updateShopVendor = (data) => async _dispatch => {
    let fieldsData = trimObjValues(data)
    let payload = { ...fieldsData }
    // To Merging Country Code 
    let phoneNumber = payload?.phone.toString();
    if (phoneNumber[0] === "+") {
        phoneNumber = phoneNumber.substring(1)
    }
    let phoneWithCountryCode = `${countryCode}${phoneNumber}`;
    payload.phone = phoneWithCountryCode;
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shop_vendor/update_shop_vendor", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const addlogisticsApi = (data) => async _dispatch => {
    const payload = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/logistics/add_logistics", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const editlogisticsApi = (data) => async _dispatch => {
    const payload = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/logistics/update_logistics", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getlogisticsListApi = (data) => async _dispatch => {
    const payload = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/logistics/get_logistics_users", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getLogisticDetails = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/logistics/get_logistics_users", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getdriverActivityLog = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_driver_activity_log", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}


export const getUserOtpApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_otp", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getdriverOrdersRoadmap = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/app/driver/get_driver_orders_roadmap", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const updateDriverJobStartPoint = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/update_driver_job_start_point", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const addAmountToWallet = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/wallet/recharge", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getWalletAmountHistory = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/wallet/history", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getWalletBalanceApi = () => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().get("/graph/get_total_wallet_balance")
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}


export const getUsersCount = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/dashboard/get_users_count", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getAggregatorsCount = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/dashboard/get_aggregators_count", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getAggregatorsTotalOrdersCount = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/dashboard/get_aggregators_total_orders_count", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getCodBalanceCount = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/dashboard/get_cod_balance", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getWalletBalanceCount = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/dashboard/get_wallet_balance", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getDriversActivityLog = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/get_driver_activity_log", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getAggregators = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/aggregator/get_aggregators", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const addAggregator = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/aggregator/add_aggregator", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const updateAggregator = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/aggregator/update_aggregator", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const removeAggregator = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/aggregator/remove_aggregator", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getAggregatorDetails = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/aggregator/get_aggregators", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const addDriverCompleteOrder = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/aggregator/add_driver_complete_order_with_aggregator", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getAggregatorLogs = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/aggregator/get_aggregator_log", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}






