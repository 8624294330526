import React from 'react';
import { Link } from 'react-router-dom';



const PaymentFail = () => {

  return (
    <div style={{ height: '86vh' }} className="d-flex flex-column justify-content-center align-items-center">
      <div className="text-center">
        <h4 className="text-danger">Payment Failed!</h4>
        <img src="/error.gif" alt="Error" className='m-4' style={{ maxWidth: '160px' }} />
        <h5>Sorry, your payment was unsuccessful.</h5>
        <Link to='/wallet-history'><button className="btn btn-primary mt-3">Go Back</button></Link>
      </div>
    </div>
  );
};

export default PaymentFail;


