import React from "react";
import DatePicker from 'react-datepicker';


function DateCalendar(props) {
    const { handleChangeDate, selectedDate , maxDate } = props

    return (
        <>
            <div className="form-group">
                <label>Start Date</label>
                <DatePicker className="form-control date-field ub-l cursor-pointer" placeholderText="Start Date"
                    showIcon = {selectedDate?.from_date ? false : true}
                    dateFormat='dd/MM/yyyy'
                    dropdownMode="select"
                    isClearable={true}
                    selected={selectedDate?.from_date}
                    maxDate={maxDate ? maxDate :  new Date()}
                    onChange={date => handleChangeDate(date, "from_date")}
                    showYearDropdown
                    showMonthDropdown
                    adjustDateOnChange
                    autocomplete={false}
                />
            </div>
            <div className="form-group">
                <label>End Date</label>
                <DatePicker className="form-control date-field ub-l cursor-pointer" placeholderText="End Date"
                    dateFormat={'dd/MM/yyyy'}
                    showIcon = {selectedDate?.to_date ? false : true}
                    isClearable={true}
                    selected={selectedDate.to_date}
                    maxDate={maxDate ? maxDate :  new Date()}
                    minDate={selectedDate?.from_date}
                    onChange={date => handleChangeDate(date, "to_date")}
                    dropdownMode="select"
                    showYearDropdown
                    showMonthDropdown
                    adjustDateOnChange
                    autocomplete={false}
                />
            </div>
        </>
    )
}
export default DateCalendar
