import React from 'react'
import { useDispatch } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC'
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { addMainCategory } from '../../Redux/action/Category';
import {useHistory} from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify'


function AddCategory(props) {
  const { setLoading } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const formik = useFormik({
    initialValues: { title: '', number: '' },

    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .min(3, 'This field must be at least 3 characters'),

      number: Yup.number()
        .typeError('You must specify a number')
        .required("This field is required")
        .min(0, "Please enter a positive number")
    }),

    onSubmit: async values => {
      const fieldValues = values
      setLoading(true)
      await dispatch(addMainCategory(fieldValues))
        .then(
          response => {
            setLoading(false)
            if (response.status === '1') {
              toast.success(response.data.message)
              formik.resetForm();
              history.push('/main-categories')
            } else {
              toast.error(response.data.error[0])
            }
          },
          (error) => {
            setLoading(false);
            toast.error(error.response.data?.message)
          }
          )
        .catch(
          error => console.log(error)
        )
    },
  });

  return (
    <div className="content-wrapper">
      <div className="content-header main_breadcrumb_top">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Create Main Category</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Main Category</label>
                          <input
                            required
                            name="title"
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            onBlur={formik.handleBlur}
                            type="text" className="form-control" placeholder="Category name" />
                          {formik.touched.title && formik.errors.title ? (<span className="text-danger">{formik.errors.title}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Sort Order</label>
                          <input
                            required
                            name="number"
                            onChange={formik.handleChange}
                            value={formik.values.number}
                            onBlur={formik.handleBlur}
                            type="number" className="form-control" placeholder="Sort order" />
                          {formik.touched.number && formik.errors.number ? (<span className="text-danger" >{formik.errors.number}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="pb-5 form-foot-btn">
                      <button type="button" className="btn btn-primary mr-2" onClick={() => history.push('/main-categories')}>Back</button>
                      <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default IsLoadingHOC(IsLoggedinHOC(AddCategory))