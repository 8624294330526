import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { addAmountToWallet } from '../../Redux/action/User';
import { useSelector} from 'react-redux';
import { SidebarContext } from '../../Context/SidebarContext';



const PaymentSuccess = () => {
  const { user } = useSelector(state => state.auth)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(true)
  const [error, setError] = useState(false)

  const { dispatch, toast } = useContext(SidebarContext)


  useEffect(() => {
   // saveAmountInWallet();
  }, [])


  const saveAmountInWallet = async () => {
    try {
      setLoading(true);
      const payload = {
        user_id: user._id,
        amount: ''
      };
      const response = await dispatch(addAmountToWallet(payload));
      setLoading(false);
      if (response.status == "1") {
        setSuccess(true);
        setError(false);
      } else {
        setSuccess(false);
        setError(true);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'An error occurred');
      setLoading(false);
      setSuccess(false);
      setError(true);
    }
  };


  return (
    <div style={{ height: '86vh' }} className="d-flex flex-column justify-content-center align-items-center">
      <div className="text-center">
        {loading && (
          <>
            <img
              src="/loading.gif"
              style={{ width: "100px", height: "100px", margin: "auto" }}
            />
            <p
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: "gray",
                marginTop: "2em",
                margin: "1em  auto",
              }}
            >
              Please Waite ...
            </p>
          </>
        )}
        {success &&
          <>
            <h4 className="text-success">Payment Successful!</h4>
            <img src="/success.gif" alt="Success" className='m-3' style={{ maxWidth: '300px' }} />
            <h5>Your payment has been processed successfully.</h5>
            <Link to='/wallet-history'><button className="btn btn-primary mt-3">Back To History</button> </Link>
          </>
        }
        {error &&
          <>
            <h4 className="text-danger">Recharge Failed!</h4>
            <img src="/error.gif" alt="Error" className='m-4' style={{ maxWidth: '160px' }} />
            <h5>Sorry, your wallet recharge was unsuccessful.</h5>
            <Link to='/wallet-history'><button className="btn btn-primary mt-3">Go Back</button></Link>
          </>
        }
      </div>
    </div>
  );
};

export default IsLoadingHOC(PaymentSuccess);


