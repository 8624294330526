import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { addShopInfoMation, setFormCurrentStep, updateShop } from '../../Redux/action/Shop';
import { getMainCategories, getSubCategories } from '../../Redux/action/Category';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { default as ReactSelect } from "react-select";
import { getAreas } from '../../Redux/action/Area';
import { useParams } from 'react-router-dom';
import { selectOptionStyle } from '../../Helper';


function ShopInfoMation(props) {
    const { setLoading } = props
    let formRef = useRef(null)
    const dispatch = useDispatch()
    const params = useParams();
    const [categories, setcategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [selectedCity, setSelectedCity] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null)
    const [areas, setAreas] = useState([])
    const [selectedCatg, setSelectedCatg] = useState(null)
    const [selectedSubCatg, setSelectedSubCatg] = useState(null)
    const [searchParams] = useState({ _id: "", category: "", num: "", active: "true" })
    const [subCatgParams, setsubCatgParams] = useState({ _id: "", subcategory: "", num: "", _p_maincategory: "" })
    const { cityData } = useSelector(state => state.app)
    const { userInfo, shopInfo, shopOtherInfo, socialurl, openingHours, shopImage, shopOtherImages, p_shopid } = useSelector(state => state.shop)
    

    useEffect(() => {
        getMainCategoriesData();
    }, [])

    useEffect(() => {
        getSubCategoriesData()
    }, [subCatgParams])

    useEffect(() => {
        if (shopInfo && shopInfo.shop) {
            const fields = ["shop", "mainsort", "subsort", "abbreviation", "city", "category", "subcategory", "locationarea", "locationaddress", "share_location_service_type", "min_geofence", "max_geofence"];
            fields.forEach(field => formik.setFieldValue(field, shopInfo[field], false));

            let city_id = ''
            if (shopInfo.city) {
                const selected_City = cityData.find(item => item.label === shopInfo?.city)
                if (selected_City) {
                    city_id = selected_City.value
                    setSelectedCity(selected_City)
                }
            }
            if (city_id) {
                getAreasData(city_id)
            }
        }
    }, [shopInfo])

    // Get Areas Drop down list selected by City
    const getAreasData = async (city_id) => {
        const payload = {
            search: { name: "", city_id: city_id },
            shorting: { order_in: -1, order_by: "_created_at" },
        }
        setLoading(true)
        await dispatch(getAreas(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const areaDropList = []
                        const resData = response.data.areas || []
                        resData.forEach(item => {
                            areaDropList.push({ value: item._id, label: item.name })
                        })
                        setAreas(areaDropList)
                        if (shopInfo.locationarea) {
                            const selected_Area = areaDropList.find(item => item.label === shopInfo?.locationarea)
                            if (selected_Area) {
                                setSelectedArea(selected_Area)
                            }
                        }
                    } else {
                        toast.error('Location area not found selected by city')
                        setAreas([])
                        setSelectedArea(null)

                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    const formik = useFormik({
        initialValues: {
            shop: "",
            mainsort: "",
            subsort: "",
            abbreviation: "",
            city: "",
            category: "",
            subcategory: "",
            locationarea: "",
            locationaddress: "",
            share_location_service_type: "",
            min_geofence: "",
            max_geofence: ""
        },

        validationSchema: Yup.object({
            shop: Yup.string()
                .required("This field is required")
                .min(3, 'Shop name must be at least 3 characters'),

            mainsort: Yup.number()
                .typeError('You must specify a number')
                .required("This field is required")
                .min(0, "Please enter a positive number"),

            subsort: Yup.number()
                .typeError('You must specify a number')
                .required("This field is required")
                .min(0, "Please enter a positive number"),

            abbreviation: Yup.string()
                .required("This field is required")
                .min(2, 'Abbreviation must be at least 2 characters'),

            category: Yup.string()
                .required("This field is required"),

            subcategory: Yup.string()
                .required("This field is required"),

            city: Yup.string()
                .required("This field is required"),

            locationarea: Yup.string()
                .required("This field is required")
                .min(3, 'Locationarea must be at least 3 characters'),

            locationaddress: Yup.string()
                .required("This field is required")
                .min(5, 'Location address must be at least 5 characters'),

            min_geofence: Yup.number()
                .typeError('You must specify a number')
                .required("This field is required")
                .min(0, "Please enter a positive number"),

            max_geofence: Yup.number()
                .typeError('You must specify a number')
                .required("This field is required")
                .min(0, "Please enter a positive number")
                .test(
                    'max_geofence',
                    'Max Geofence must be greater than Min Geofence',
                    function (max_geofence) {
                        const { min_geofence } = this.parent; // Access min_geofence value
                        return max_geofence > min_geofence;
                    }
                ),
        }),
        onSubmit: async (values) => {
            const fieldValues = values
            if (formRef.current.name === 'next') {
                dispatch(addShopInfoMation(fieldValues))
                dispatch(setFormCurrentStep("SHOP_OTHER_INFO"))
            } else {
                handleSubmitData(fieldValues)
            }
        },
    });

    const getMainCategoriesData = async () => {
        const payload = {
            search: { ...searchParams },
            shorting: { order_in: -1, order_by: "_created_at" },
            pagination: { start: 1, length: 100 }
        }
        setLoading(true)
        await dispatch(getMainCategories(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const result = (response.data && response.data.categories) || []
                        const mainCategories = []
                        result.forEach(item => {
                            mainCategories.push({ label: item.category, value: item._id })
                        })
                        setcategories(mainCategories)
                        if (shopInfo && shopInfo.category) {
                            const selectedMainCatg = mainCategories.find(item => item.value === shopInfo.category)
                            setSelectedCatg(selectedMainCatg)
                            setsubCatgParams((prevState) => ({
                                ...prevState,
                                _p_maincategory: shopInfo.category,
                            }));
                        }
                    }
                    else {
                        toast.error(response.data.error[0])
                        setcategories([])
                    }
                },
            )
    }

    const getSubCategoriesData = async () => {
        const payload = {
            search: { ...subCatgParams },
            shorting: { order_in: -1, order_by: "_created_at" },
            pagination: { start: 1, length: 100 }
        }
        if (subCatgParams._p_maincategory) {
            setLoading(true)
            await dispatch(getSubCategories(payload))
                .then(
                    response => {
                        setLoading(false);
                        if (response.status === "1") {
                            const result = (response.data && response.data.categories) || []
                            const subCategories = []
                            result.forEach(item => {
                                subCategories.push({ label: item.subcategory, value: item._id })
                            })
                            setSubCategories(subCategories)
                            if (shopInfo && shopInfo.subcategory) {
                                const selectedSubCatg = subCategories.find(item => item.value === shopInfo.subcategory)
                                setSelectedSubCatg(selectedSubCatg)
                            }
                        } else {
                            toast.error("Sub categories not found,by selected main category")
                            setSubCategories([])
                        }
                    },
                )
        }
    }

    const handleCategorySelect = option => {
        if (option && option.value) {
            setSelectedCatg(option)
            setsubCatgParams((prevState) => ({
                ...prevState,
                _p_maincategory: option.value,
            }))
            formik.setFieldValue('category', option.value)
        } else {
            setSelectedCatg(null)
            setsubCatgParams((prevState) => ({
                ...prevState,
                _p_maincategory: "",
            }))
            formik.setFieldValue('category', "")
        }
    }

    const handleSubCategorySelect = option => {
        if (option && option.value) {
            setSelectedSubCatg(option)
            formik.setFieldValue('subcategory', option.value)
        } else {
            setSelectedSubCatg(null)
            formik.setFieldValue('subcategory', "")
        }
    }

    const handleCitySelect = option => {
        const { value, label } = option || {}
        if (value || label) {
            setSelectedCity(option)
            formik.setFieldValue('city', label);
            getAreasData(value)
        } else {
            setSelectedCity(null)
            formik.setFieldValue('city', "");
            setAreas([])
            setSelectedArea(null)
            formik.setFieldValue('locationarea', "");
        }
    }

    const handleAreaSelect = option => {
        const { value, label } = option || {}
        if (value || label) {
            setSelectedArea(option)
            formik.setFieldValue('locationarea', label);
        } else {
            setSelectedArea(null)
            formik.setFieldValue('locationarea', "");
        }
    }

    const handlePrevious = () => {
        dispatch(setFormCurrentStep("USER_INFO"))
    }

    const handleSubmitData = async (fieldValues) => {
        const payload = {
            user_id: params.id,
            shop_id: p_shopid,
            ...userInfo,
            ...fieldValues,
            ...shopOtherInfo,
            ...shopImage,
            hours: { ...openingHours },
            otherimages: [...shopOtherImages],
            sociallinks: { ...socialurl },
        }
        setLoading(true)
        await dispatch(updateShop(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status === '1') {
                        toast.success(response.data.message)
                    } else {
                        toast.error(response.data?.error[0])
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                    console.log(error)
                }
            )
    }

    return (
        <div className="card-body">
            <div className="tab-content" id="custom-tabs-one-tabContent">
                <div className="tab-pane fade active show " id="custom-shop-info-tab" role="tabpanel" aria-labelledby="shop-info-tab">
                    <form>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Shop Name</label>
                                    <input className="form-control" placeholder='Shop'
                                        required
                                        name='shop'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.shop}
                                        type="text" />
                                    {formik.touched.shop && formik.errors.shop ? (<span className="text-danger">{formik.errors.shop}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">All List Sequence</label>
                                    <input className="form-control" placeholder='Sequence'
                                        required
                                        name='mainsort'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.mainsort}
                                        type="number" />
                                    {formik.touched.mainsort && formik.errors.mainsort ? (<span className="text-danger">{formik.errors.mainsort}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">City</label>
                                    <ReactSelect placeholder="Select City"
                                        isClearable={true}
                                        options={cityData}
                                        hideSelectedOptions={false}
                                        value={selectedCity}
                                        onBlur={formik.handleBlur}
                                        styles={selectOptionStyle}
                                        onChange={(option) => handleCitySelect(option)}
                                    />
                                    {formik.touched.city && formik.errors.city ? (<span className="text-danger">{formik.errors.city}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Location Area</label>
                                    <ReactSelect placeholder="Select Area"
                                        isClearable={true}
                                        options={areas}
                                        hideSelectedOptions={false}
                                        value={selectedArea}
                                        onBlur={formik.handleBlur}
                                        styles={selectOptionStyle}
                                        onChange={(option) => handleAreaSelect(option)}
                                    />
                                    {formik.touched.locationarea && formik.errors.locationarea ? (<span className="text-danger">{formik.errors.locationarea}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Main Category</label>
                                    <ReactSelect placeholder="Select main category"
                                        isClearable={true}
                                        options={categories}
                                        hideSelectedOptions={false}
                                        value={selectedCatg}
                                        styles={selectOptionStyle}
                                        onChange={option => handleCategorySelect(option)}
                                    />
                                    {formik.touched.category && formik.errors.category ? (<span className="text-danger">{formik.errors.category}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Category List Sequence</label>
                                    <input className="form-control" placeholder='Sequence'
                                        required
                                        name='subsort'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.subsort}
                                        type="number" />
                                    {formik.touched.subsort && formik.errors.subsort ? (<span className="text-danger">{formik.errors.subsort}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Sub Category</label>
                                    <ReactSelect placeholder="Select sub sategory"
                                        isClearable={true}
                                        options={subCategories}
                                        hideSelectedOptions={false}
                                        value={selectedSubCatg}
                                        onBlur={formik.handleBlur}
                                        styles={selectOptionStyle}
                                        onChange={(option) => handleSubCategorySelect(option)}
                                    />
                                    {formik.touched.subcategory && formik.errors.subcategory ? (<span className="text-danger">{formik.errors.subcategory}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Full Address</label>
                                    <input className="form-control" placeholder='Full address'
                                        required
                                        name='locationaddress'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.locationaddress}
                                        type="text" />
                                    {formik.touched.locationaddress && formik.errors.locationaddress ? (<span className="text-danger ">{formik.errors.locationaddress}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Abbreviation</label>
                                    <input className="form-control" placeholder='Abbreviation'
                                        required={true}
                                        maxLength={4}
                                        name='abbreviation'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.abbreviation}
                                        type="text" />
                                    {formik.touched.abbreviation && formik.errors.abbreviation ? (<span className="text-danger">{formik.errors.abbreviation}</span>) : null}
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="form-group required">
                                    <label className="control-label">Share location service type</label>
                                    <select className="form-control" name='share_location_service_type'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.share_location_service_type}
                                    >
                                        <option value='whatsapp'>WhatsApp</option>
                                        <option value='sms'>SMS</option>
                                        <option value='none'>None</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Min Geofence (Range)</label>
                                    <input className="form-control" placeholder='Sequence'
                                        required
                                        name='min_geofence'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.min_geofence}
                                        type="number" />
                                    {formik.touched.min_geofence && formik.errors.min_geofence ? (<span className="text-danger">{formik.errors.min_geofence}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Max Geofence (Range)</label>
                                    <input className="form-control" placeholder='Sequence'
                                        required
                                        name='max_geofence'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.max_geofence}
                                        type="number" />
                                    {formik.touched.max_geofence && formik.errors.max_geofence ? (<span className="text-danger">{formik.errors.max_geofence}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="pb-5 footer-step">
                            <button type='button' onClick={handlePrevious} className="btn btn-primary mr-2">Back</button>
                            <button type='button' ref={formRef} className="btn btn-success mr-2"
                                onClick={() => { formRef.current.name = 'update'; formik.handleSubmit() }} >Update</button>
                            <button type='button' ref={formRef} className="btn btn-info"
                                onClick={() => { formRef.current.name = 'next'; formik.handleSubmit() }} >Next</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default IsLoadingHOC(IsLoggedinHOC(ShopInfoMation))