import React from 'react';

function NotFoundData() {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "40vh" }}>
            <div className='align-items-center'>
                <h4>No records available..</h4>
            </div>
        </div>
    )
}

export default NotFoundData

