import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { addAmountToWallet } from '../../Redux/action/User';
import { currencyCode } from '../../Config/constants';
import { payMethods } from '../../Config/constants';



function AddMoneyByPaymentMethod(props) {
    const dispatch = useDispatch()
    const { modal, closeModal } = props
    const { user } = useSelector(state => state.auth)
    const { _id, wallet_amount } = user

    const formik = useFormik({
        initialValues: {
            user_id: _id,
            amount: '',
            PaymentMethodId: ''
        },
        validationSchema: Yup.object({
            amount: Yup.number()
                .typeError('You must specify a number')
                .min(1, "Please enter a positive number")
                .required("This field is required"),

            PaymentMethodId: Yup.string()
                .required("Please select any payment method"),
        }),
        onSubmit: async values => {
            // setLoading(true)
            await dispatch(addAmountToWallet(values))
                .then(
                    response => {
                        //  setLoading(false)
                        if (response.status == '1') {
                            toast.success(response.data.message)
                            closeModal();
                            //  getListData();
                        } else {
                            toast.error(response.data?.error[0])
                        }
                    })
        },
    });

    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? 'show' : ''}`} tabIndex="-1" style={{ display: modal ? 'block' : 'none' }}>
            <div className="modal-dialog wallet--modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center">Recharge to your Wallet</h5>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-body wallet-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <div className="input-group">
                                            <div className="input-group-append">
                                                <span className="input-group-text">{currencyCode}</span>
                                            </div>
                                            <input className="form-control"
                                                type="number"
                                                placeholder="Enter amount"
                                                name="amount"
                                                required
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.amount}
                                            />
                                        </div>
                                        {formik.touched.amount && formik.errors.amount ? (<span className="text-danger">{formik.errors.amount}</span>) : ""}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className='mt-3'>
                                        <h5>Payment Method</h5>
                                    </div>
                                        {payMethods?.map((data, index) => (
                                            <div className={formik.values.PaymentMethodId === data.PaymentMethodId ? 'active-payment-method' : ''}
                                                key={index + 1}
                                                onClick={() => formik.setFieldValue("PaymentMethodId", data.PaymentMethodId)}
                                                style={{
                                                    borderRadius: "15px",
                                                    height: "60px",
                                                    margin: "20px auto",
                                                    background: "#fff",
                                                    //  padding: "12px 10px",
                                                    alignItems: "center",
                                                    display: "grid",
                                                    cursor: "pointer",
                                                    transition: " transform ease 0.33s",
                                                    gridTemplateColumns: " 1fr 3fr",
                                                    gap: 8,
                                                }}
                                            >
                                                <img loading="lazy" src={data.img} alt="pay-method" />
                                                <span>
                                                    <h4 style={{ fontWeight: "500", fontSize: "14px" }}>
                                                        {data.title}
                                                    </h4>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                fontSize: "12px",
                                                                marginTop: "8px",
                                                            }}
                                                        >
                                                        </span>
                                                    </div>
                                                </span>
                                            </div>
                                        ))}
                                    {formik.touched.PaymentMethodId && formik.errors.PaymentMethodId ? (<span className="text-danger">{formik.errors.PaymentMethodId}</span>) : ""}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                            <button type="submit" className="btn btn-success">Pay Now</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default AddMoneyByPaymentMethod
