import React, { useState, useEffect, useLayoutEffect, useCallback, useContext } from 'react';
import { trimObjValues, getFormatedDate, customStyles } from '../../Helper';
import { isAllItemSelected, handleSelectListItem } from '../../Helper';
import DeleteConfirmation from '../../Components/ConfirmModel/Delete';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { deleteDeliveryZone, getDeliveryZones } from '../../Redux/action/DeliveryZone';
import Pagination from '../../Components/Pagination';
import { getUserForSearchBar } from '../../Redux/action/User';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import TableFooter from '../../Components/TableFooter';
import UploadFileModal from './UploadFileModal';
import { saveDeliveryZoneTitle, saveSelectedUsers } from '../../Redux/action/App';
import ReactSelect from 'react-select';
import AddUserToDeliveryZone from './AddUserToDZ';
import RemoveUserFromDeliveryZone from './RemoveUserFromDZ';
import { SidebarContext } from '../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';
import { debounce } from 'lodash';


function DeliveryZones(props) {
   const { isLoading, setLoading } = props
   const params = useParams()
   const location = useLocation()
   const history = useHistory()
   const [deliveryZones, setdeliveryZones] = useState([])
   const [sorting, setSorting] = useState({ order_in: -1, order_by: "km_max" })
   const [selectedValue, setSelectedValue] = useState("");
   const [shopFetching, setShopFetching] = useState(false)
   const [shops, setShops] = useState([])
   const [uploadFileModal, setUploadFileModal] = useState(false)
   const [searchParams, setSearchParams] = useState({ user_id: params.id ? params.id : '', title: "", removed: "", type: "" })
   const [deliveryZoneId, setDeliveryZoneId] = useState("")
   const [addUserModal, setAddUserModal] = useState(false)
   const [removeUserModal, setRemoveUserModal] = useState(false)

   const {
      dispatch,
      totalCount,
      toast,
      setTotalCount,
      deleteModal,
      setDeleteModal,
      resetForm,
      handleSubmit,
      selectedItem,
      setSelectedItem,
      handleSelectItem,
      currentPage,
      setcurrentPage,
      itemsPerPage,
      maxPageNumberLimit,
      setmaxPageNumberLimit,
      minPageNumberLimit,
      setminPageNumberLimit,
   } = useContext(SidebarContext)


   useLayoutEffect(() => {
      if (location.state && location.state.currentPage) {
         setcurrentPage(location.state.currentPage)
      }
   }, [])

   useEffect(() => {
      getDeliveryZonesData();
   }, [currentPage, sorting])

   // loaded options using API call with searched Shops
   const getSearchedShops = async (searchvalue) => {
      const payload = {
         search: { type: "Shop,Integrator", nickname: searchvalue, username: "" },
         shorting: { order_in: 1, order_by: "nickname" }
      }
      setShopFetching(true)
      await dispatch(getUserForSearchBar(payload))
         .then(
            response => {
               setShopFetching(false)
               if (response.status === "1") {
                  const resData = response.data.users
                  const allShops = []
                  resData.forEach(item => {
                     const { _id, nickname } = item
                     allShops.push({ label: nickname, value: _id })
                  })
                  setShops(allShops)
               } else {
                  setShops([])
               }
            },
            error => {
               setShops([])
               setShopFetching(false)
               toast.error(error.response.data?.message)
            }
         )
   };

   const getShopsData = useCallback(debounce((searchvalue) => getSearchedShops(searchvalue), 500), []);
   const handleSearchShop = (inputValue) => {
      const value = inputValue.trim()
      if (value && value.length > 0) {
         getShopsData(value);
      }
   }

   const handlSelectShop = (option) => {
      const { value } = option ? option : {}
      setSelectedValue(option)
      setSearchParams((prevState) => ({
         ...prevState,
         'user_id': value || '',
      }));
      if (!value) {
         setShops([])
      }
   }

   const getDeliveryZonesData = async () => {
      const search_params = trimObjValues(searchParams)
      const payload = {
         search: search_params,
         shorting: sorting,
         pagination: { start: currentPage, length: itemsPerPage }
      }
      setLoading(true)
      await dispatch(getDeliveryZones(payload))
         .then(
            response => {
               setLoading(false);
               if (response.status === "1") {
                  setdeliveryZones(response.data.delivery_zones)
                  setTotalCount(response.data.total_count)
               } else {
                  setdeliveryZones([])
                  setTotalCount(null)
               }
            },
            (error) => {
               setLoading(false);
               toast.error(error.response.data?.message)
            }
         )
   }

   const handleChange = (e) => {
      const { name, value } = e.target
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value,
      }));
   }

   const handleReset = async () => {
      const resetFormData = { user_id: params.id ? params.id : '', title: "", removed: "", type: "" }
      setSearchParams(resetFormData)
      resetForm();
      setSelectedValue("")
      setShops([])
      setSorting({ order_in: -1, order_by: "km_max" })
   }

   const handleSorting = (orderby) => {
      setSorting((prevState) => ({
         ...prevState,
         order_by: orderby,
         order_in: prevState.order_in === -1 ? 1 : -1
      }));
   }

   const handleDelete = async () => {
      let payload = { delivery_zone_id: deliveryZoneId }
      // if (selectedItem && selectedItem.length > 0) {
      //    Object.assign(payload, { orders: selectedItem })
      // }
      setLoading(true)
      await dispatch(deleteDeliveryZone(payload))
         .then(
            response => {
               setLoading(false)
               if (response.status == '1') {
                  toast.success(response.data.message)
                  getDeliveryZonesData()
                  setDeleteModal(false)
               }
               else {
                  const errMsg = response.data.message || response.data.error[0]
                  toast.error(errMsg)
               }
            },
            error => {
               setLoading(false)
               toast.error(error.response.data.message)
            }
         )
   }

   const showSelectedShopUsers = (_id, selected_users, title_name) => {
      dispatch(saveSelectedUsers(selected_users))
      dispatch(saveDeliveryZoneTitle(title_name))
      history.push({ pathname: `/delivery-zone-shops/${_id}` })
   }

   return (
      <>
         <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
               <div className="container-fluid">
                  <div className="row mb-2">
                     <div className="col-sm-6">
                        <h1 className="m-0">Delivery zones</h1>
                     </div>
                     <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item active">Delivery zones</li>
                        </ol>
                     </div>
                  </div>
               </div>
            </div>
            <section className="content">
               <div className="container-fluid">
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="all_drivers">
                           <div className="card card-dark">
                              <div className="card-header">
                                 <div className="container-fluid">
                                    <div className="row">
                                       <div className="col-6 col-lg-11">
                                          <h3 className="pl-5">Search</h3>
                                       </div>
                                       <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                          <i className="fas fa-angle-up click_search"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <form className="form_search" action="">
                                 <div className="card-body">
                                    <div className="form-group">
                                       <label>Title</label>
                                       <input
                                          type="text"
                                          name="title"
                                          onChange={handleChange}
                                          value={searchParams.title}
                                          className="form-control"
                                          placeholder="Enter Title" />
                                    </div>
                                    {!params.id &&
                                       <div className="form-group">
                                          <label>Type</label>
                                          <select
                                             className="form-control"
                                             value={searchParams.type}
                                             onChange={handleChange}
                                             name="type">
                                             <option value="">All</option>
                                             <option selected value="default">Default</option>
                                             <option value="other">Other</option>
                                          </select>
                                       </div>
                                    }
                                    {!params.id && searchParams.type === "" ?
                                       <div className="form-group">
                                          <label>User</label>
                                          <ReactSelect placeholder="Search"
                                             isLoading={shopFetching}
                                             styles={customStyles}
                                             isClearable={true}
                                             value={selectedValue}
                                             options={shops}
                                             onInputChange={handleSearchShop}
                                             onChange={(option) => handlSelectShop(option)}
                                          />
                                       </div>
                                       : null}
                                    <div className="form-group">
                                       <label>Active</label>
                                       <select
                                          className="form-control"
                                          value={searchParams.removed}
                                          onChange={handleChange}
                                          name="removed" >
                                          <option value="" >All</option>
                                          <option selected value="false">Yes</option>
                                          <option value="true">No</option>
                                       </select>
                                    </div>
                                 </div>
                                 <div className="card-footer text-center">
                                    <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                    <button type="button" onClick={() => handleSubmit(getDeliveryZonesData)} className="btn btn-success">Submit</button>
                                 </div>
                              </form>
                           </div>
                           <div className="row pagination_new">
                              <div className="col-lg-6 col-12">
                                 {params.id && <button disabled={isLoading} type="button" onClick={() => history.goBack()} className="btn btn-primary mr-2"><i className="fa-solid fa-circle-arrow-left"></i>Back</button>}
                                 <Link to="/create-delivery-zone" ><button type="create" className="btn btn-primary"><i className="fa-regular fa-circle-plus"></i>Create</button></Link>
                                 <button disabled={isLoading} type="button" onClick={() => handleSubmit(getDeliveryZonesData)} className="btn btn-secondary ml-2"><i className={`fa-regular fa-rotate-right ${isLoading && 'fa_spinner'}`}></i>Refresh</button>
                                 {selectedItem?.length > 0 &&
                                    <>
                                       <button type="button" className="btn btn-outline-info ml-2">Action</button>
                                       <button type="button" className="btn btn-outline-info dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                          <span className="caret"></span>
                                          <span className="sr-only">Toggle Dropdown</span>
                                       </button>
                                       <div className="dropdown-menu">
                                          <span style={{ cursor: 'pointer' }} className="dropdown-item text-info" onClick={() => setAddUserModal(true)}><i className="fa-solid fa-user-plus mr-2"></i>Add User</span>
                                          <div className="dropdown-divider"></div>
                                          <span style={{ cursor: 'pointer' }} className="dropdown-item text-danger" onClick={() => setRemoveUserModal(true)}><span className="red_1">
                                             <i className="fa-solid fa-user-xmark mr-2"></i>Remove User</span></span>
                                       </div>
                                    </>
                                 }
                              </div>
                              <div className="col-lg-6 col-12 text-lg-right text-center">
                                 <a href={`${process.env.REACT_APP_BASEURL}/import_delivery_zones.xlsx`} download ><button disabled={isLoading} type="button" className="btn btn-success  ml-2"><i className="fa-regular fa-circle-down"></i>Download Template</button></a>
                                 <button disabled={isLoading} type="button" onClick={() => setUploadFileModal(true)} className="btn btn-info  ml-2"><i className="fa-regular fa-circle-up"></i>Import</button>
                              </div>
                           </div>
                           {deliveryZones?.length > 0 &&
                              <React.Fragment>
                                 <div className="card table_card completed_1">
                                    <div className="card-body all_drive_table">
                                       <table id="example2" className="table table-hover">
                                          <thead>
                                             <tr>
                                                <th><input type="checkbox" id="check" checked={isAllItemSelected(deliveryZones, selectedItem)} onChange={(event) => handleSelectListItem(event, deliveryZones, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th>
                                                <th>Title</th>
                                                <th>Min Km
                                                   {sorting.order_by === "km_min" ?
                                                      <i onClick={() => handleSorting("km_min")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("km_min")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Max Km
                                                   {sorting.order_by === "km_max" ?
                                                      <i onClick={() => handleSorting("km_max")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("km_max")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Delivery Fee
                                                   {sorting.order_by === "delivery_fee" ?
                                                      <i onClick={() => handleSorting("delivery_fee")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("delivery_fee")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Utrac Fee
                                                   {sorting.order_by === "utrac_fee" ?
                                                      <i onClick={() => handleSorting("utrac_fee")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("utrac_fee")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Tax Fee
                                                   {sorting.order_by === "tax_fee" ?
                                                      <i onClick={() => handleSorting("tax_fee")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("tax_fee")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Users</th>
                                                <th>Active</th>
                                                <th>Created At
                                                   {sorting.order_by === "_created_at" ?
                                                      <i onClick={() => handleSorting("_created_at")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Action</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {deliveryZones.map((item) => (
                                                <tr key={item._id} style={{ backgroundColor: item?.selected_users?.length > 0 && '#e8eddf' }} >
                                                   <td><input type="checkbox" className="table_checkbox"
                                                      onChange={(event) => handleSelectItem(event, item._id)}
                                                      checked={selectedItem.includes(item._id)} />
                                                   </td>
                                                   <td>{item.title ? `${item.title}` : "Not Available"}</td>
                                                   <td>{item.km_min === 0 ? "0" : item.km_min ? item.km_min : "NA"}</td>
                                                   <td>{item.km_max === 0 ? "0" : item.km_max ? item.km_max : "NA"}</td>
                                                   <td>{item.delivery_fee === 0 ? "0" : item.delivery_fee ? item.delivery_fee : "NA"}</td>
                                                   <td>{item.utrac_fee === 0 ? "0" : item.utrac_fee ? item.utrac_fee : "NA"}</td>
                                                   <td>{item.tax_fee === 0 ? "0" : item.tax_fee ? item.tax_fee : "NA"}</td>
                                                   <td><i className="fa fa-user" aria-hidden="true"></i> {item?.selected_users?.length}</td>
                                                   <td><span className={item.removed ? 'no_1' : 'yes_1'}>{item.removed === true ? 'No' : item.removed === false ? "Yes" : ''}</span></td>
                                                   <td>{getFormatedDate(item._created_at)}
                                                   </td>
                                                   <td>
                                                      <div className="action_button">
                                                         <div className="btn-group">
                                                            <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                               <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                            </button>
                                                            <div className="dropdown-menu">
                                                               <Link
                                                                  to={{ pathname: `/edit-delivery-zone/${item._id}`, state: { currentPage: currentPage } }}
                                                                  className="dropdown-item"><span className="blue_1">
                                                                     <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span></Link>
                                                               <div className="dropdown-divider"></div>
                                                               <span className="dropdown-item"
                                                                  onClick={() => {
                                                                     setDeliveryZoneId(item._id)
                                                                     setDeleteModal(true)
                                                                  }}
                                                               >
                                                                  <span className="red_1">
                                                                     <i className="fa fa-trash" aria-hidden="true"></i>Delete</span>
                                                               </span>
                                                               {item.selected_users && item.selected_users.length > 0 &&
                                                                  <React.Fragment>
                                                                     <div className="dropdown-divider"></div>
                                                                     <span onClick={() => showSelectedShopUsers(item._id, item.selected_users, item.title)}
                                                                        className="dropdown-item"><span>
                                                                           <i className="fa-solid fa-shop"></i>View Users</span></span>
                                                                  </React.Fragment>
                                                               }
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </td>
                                                </tr>)
                                             )}
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                                 <div className="row pagination_new">
                                    <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={deliveryZones} totalCount={totalCount} />
                                    <div className="col-lg-4">
                                       <Pagination
                                          totalCount={totalCount}
                                          currentPage={currentPage}
                                          itemsPerPage={itemsPerPage}
                                          maxPageNumberLimit={maxPageNumberLimit}
                                          minPageNumberLimit={minPageNumberLimit}
                                          setcurrentPage={setcurrentPage}
                                          setmaxPageNumberLimit={setmaxPageNumberLimit}
                                          setminPageNumberLimit={setminPageNumberLimit}
                                       />
                                    </div>
                                 </div>
                              </React.Fragment>}
                           {deliveryZones?.length === 0 && !isLoading && <NotFoundData />}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
         {uploadFileModal && <UploadFileModal
            modal={uploadFileModal}
            closeModal={() => setUploadFileModal(false)}
         />}

         {deleteModal && <DeleteConfirmation
            loader={isLoading}
            modal={deleteModal}
            title="Do you want to remove delivery zone?"
            closeModal={() => setDeleteModal(false)}
            handleDelete={handleDelete} />}

         {addUserModal &&
            <AddUserToDeliveryZone
               modal={addUserModal}
               deliveryZones={selectedItem}
               closeModal={() => setAddUserModal(false)}
               clearSelectedItem={() => setSelectedItem([])}
            />}

         {removeUserModal &&
            <RemoveUserFromDeliveryZone
               modal={removeUserModal}
               deliveryZones={selectedItem}
               closeModal={() => setRemoveUserModal(false)}
               clearSelectedItem={() => setSelectedItem([])}
            />}
      </>
   )
}
export default IsLoadingHOC(IsLoggedinHOC(DeliveryZones))