import React, { useState, useEffect } from 'react';
import IsLoadingHOC from '../../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../../Components/IsLoggedinHOC';
import { useParams, Link } from 'react-router-dom';
import UserInfomation from './UserInfomation';
import ShopInfomation from './ShopInfomation';
import { useDispatch, useSelector } from 'react-redux';
import { getCities } from '../../../Redux/action/City';
import { saveCityData } from '../../../Redux/action/App';
import { getPrices } from '../../../Redux/action/price';
import { toast } from 'react-toastify';
import { getIntegratorUserDetails } from '../../../Redux/action/User';
import { addUserInfoMation, addShopInfoMation, } from '../../../Redux/action/Shop';



function EditIntegrator(props) {
    const { setLoading } = props
    const params = useParams()
    const dispatch = useDispatch()
    const { form_step } = useSelector(state => state.shop)
    const [searchParams] = useState({ id: "", name: "" })
    const [sorting] = useState({ order_in: -1, order_by: "_created_at" })
    const [prices, setPrices] = useState([])



    useEffect(() => {
        getCitiesData();
        if (params.id) {
            getShopDetailsData();
        }
    }, [])

    useEffect(() => {
        getPricesData();
    }, [])

    const getPricesData = async () => {
        const payload = {
            search: { ...searchParams },
            shorting: { order_in: sorting.order_in, order_by: sorting.order_by },
            // pagination: { start: 1, length: 1000 }
        }
        setLoading(true)
        await dispatch(getPrices(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const pricesData = []
                        response.data && response.data.prices &&
                            response.data.prices.forEach(item => {
                                const { _id, title, delivery_fee, utrac_fee } = item
                                const labelName = `${title} (DF : ${delivery_fee} , UF : ${utrac_fee} )`
                                pricesData.push({ label: labelName, value: _id })
                            })
                        setPrices(pricesData)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                })
            .catch(error => {
                console.log(error)
            }
            )
    }



    const getShopDetailsData = async () => {
        setLoading(true)
        const payload = {
            search: {
                user_search: { _id: params.id }
            },
            is_detail: true,
        }
        await dispatch(getIntegratorUserDetails(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const resData = response.data.users[0]
                        setIntegratorFormData(resData);
                    }
                    else {
                        toast.error("Integrator user details not found")
                    }
                },
                error => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    const setIntegratorFormData = async (data) => {
        const { username, email, phone, shop_information, prices, share_location_service_type } = data ? data : {}
        const {
            _id,
            shop,
            mainsort,
            subsort,
            abbreviation,
            city,
            locationarea,
            locationaddress,
            active,
            locationmap,
            description,
        } = shop_information ? shop_information : {}

        dispatch(addUserInfoMation({ username, phone, email, active }))
        dispatch(addShopInfoMation({
            shop_id: _id,
            shop,
            mainsort,
            subsort,
            abbreviation,
            city,
            locationarea,
            locationaddress,
            latitude: locationmap[0],
            longitude: locationmap[1],
            description,
            prices,
            share_location_service_type
        }))
    }

    const getCitiesData = async () => {
        const payload = {
            search: { ...searchParams },
            shorting: { order_in: sorting.order_in, order_by: sorting.order_by },
        }
        setLoading(true)
        await dispatch(getCities(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const cityData = []
                        const resData = response.data.cities
                        resData.map(item =>
                            cityData.push({ value: item._id, label: item.name })
                        )
                        dispatch(saveCityData(cityData))
                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data.message)
                }
            )
    }

    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Edit Integrator</h1>
                        </div>
                        {/* <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to ="/" >Home</Link></li>
                                <li className="breadcrumb-item">Integrators</li>
                                <li className="breadcrumb-item active">Edit</li>
                            </ol>
                        </div> */}
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary card-tabs">
                                <div className="card-header p-0 pt-1">
                                    <ul className="nav nav-tabs" >
                                        <li className="nav-item">
                                            <span className={`nav-link ${form_step === "USER_INFO" && "active"}`} >User information</span>
                                        </li>
                                        <li className="nav-item">
                                            <span className={`nav-link ${form_step === "SHOP_INFO" && "active"}`}>Integrator Information</span>
                                        </li>
                                    </ul>
                                </div>
                                {form_step === "USER_INFO" && <UserInfomation />}
                                {form_step === "SHOP_INFO" && <ShopInfomation prices={prices} />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(EditIntegrator))
