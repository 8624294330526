import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppRoute from './AppRoute';
import PublicRoute from './PublicRoute';
import Authorization from './Authorization';
import PrivateRoute from './PrivateRoute';
import PrivateLayout from '../Layout/Private';
import PublicLayout from '../Layout/Public';
import Login from '../Pages/Auth/Login';
import Register from '../Pages/Auth/Register';
import FoodicsSignup from '../Pages/Auth/FoodicsSignup';
import AdminDashboard from '../Pages/Dashboard/AdminDashboard';
import UserDashboard from '../Pages/Dashboard/UserDashboard';
import CompletedOrders from '../Pages/CompletedOrders';
import CanceledOrders from '../Pages/CanceledOrders';
import OpenOrders from '../Pages/OpenOrders';
import CreateAdmin from '../Pages/Admin/CreateAdmin';
import EditAdmin from '../Pages/Admin/EditAdmin';
import AdminList from '../Pages/Admin/AdminList';
import AllBuyers from '../Pages/Buyers';
import AllDrivers from '../Pages/Drivers/AllDriver';
import EditDriver from '../Pages/Drivers/EditDriver';
import MainCategory from '../Pages/Category';
import AddCategory from '../Pages/Category/AddCategory';
import EditCategory from '../Pages/Category/EditCategory';
import SubCategories from '../Pages/SubCategory';
import AddSubCategory from '../Pages/SubCategory/AddSubCategory';
import EditSubCategory from '../Pages/SubCategory/EditSubCategory';
import ShopList from '../Pages/Shops';
import ShopSubusers from '../Pages/ShopSubUser';
import ShopBranches from '../Pages/Branches';
import ShopOwnerBranches from '../Pages/ShopOwnerBranches';
import CreateShop from '../Pages/CreateShop';
import EditShop from '../Pages/EditShop';
import DeliveryZones from '../Pages/DeliveryZones';
import DeliveryZoneShopList from '../Pages/DeliveryZones/DeliveryZoneShops';
import AddDeliveryZone from '../Pages/DeliveryZones/AddDeliveryZone';
import UpdateDeliveryZone from '../Pages/DeliveryZones/UpdateDeliveryZone';
import CreateOrder from '../Pages/CreateOrder/CreateOrder';
import Areas from '../Pages/Area';
import AddArea from '../Pages/Area/AddArea';
import EditArea from '../Pages/Area/EditArea';
import Cities from '../Pages/City';
import AddCity from '../Pages/City/AddCity';
import EditCity from '../Pages/City/EditCity';
import ScheduledOrders from '../Pages/ScheduleOrder';
import CreateScheduleOrder from '../Pages/ScheduleOrder/Create';
import EditScheduleOrder from '../Pages/ScheduleOrder/Edit';
import AddNotification from '../Pages/PushMessage/AddNotification';
import PushMessages from '../Pages/PushMessage/PushMessages';
import Invoices from '../Pages/Reports/Invoices';
import AddjustTXN from '../Pages/Reports/AddjustTXN';
import BalanceSheets from '../Pages/Reports/BalanceSheet';
import OrdersCharts from '../Pages/Dashboard/OrdersCharts';
import AggregatorOrdersCharts from '../Pages/Dashboard/AggregatorOrdCharts';
import AverageApexCharts from '../Pages/Dashboard/AveragesCharts';
import AboutApp from '../Pages/AboutApp';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import TermsofService from '../Pages/TermsOfService';
import ShareLocation from '../Pages/ShareLocation';
import TrackNewOrder from '../Pages/TrackOrder';
import Prices from '../Pages/Prices/index';
import EditPrice from '../Pages/Prices/EditPrice';
import AddPrice from '../Pages/Prices/AddPrice';
import Integrators from '../Pages/Integrator/IntegratorsList';
import AddNewIntegrator from '../Pages/Integrator/AddIntegrator';
import EditIntegrator from '../Pages/Integrator/EditIntegrator';
import ShopVendors from '../Pages/ShopVendor/index';
import AddShopVendor from '../Pages/ShopVendor/AddVendor';
import EditShopVendor from '../Pages/ShopVendor/EditVendor';
import UsersLocation from '../Pages/UsersLocation';
import ShareAddress from '../Pages/ShareAddress';
import Logistics from '../Pages/Logistics';
import CreateLogistic from '../Pages/Logistics/Create';
import EditLogistic from '../Pages/Logistics/Edit';
import DriversLocation from '../Pages/DriversLocation';
import FoodicsSuccess from '../Pages/FoodicsSuccess';
import LogisticDrivers from '../Pages/Logistics/LogisticDrivers';
import WalletHistory from '../Pages/Reports/WalletHistory';
import PaymentSuccess from '../Pages/PaymentResult/PaymentSuccess';
import PaymentFail from '../Pages/PaymentResult/PaymentFail';
import ShopRegistered from '../Pages/ShopRegistered';
import WebhookLog from '../Pages/WebhookLog';
import Aggregators from '../Pages/Aggregators';
import AggregatorLogs from '../Pages/Aggregators/AggregatorLogs';

const Admin = Authorization(['Admin']);
const shop = Authorization(['Shop']);
const adminShop = Authorization(['Admin', 'Shop']);
const adminShopDsp = Authorization(['Admin', 'Logistics', 'Shop']);
const adminShopIntegrator = Authorization(['Admin', 'Shop', 'Integrator']);
const allUser = Authorization(['Admin', 'Shop', 'Integrator', 'ShopVendor', 'Logistics']);



const RootRouters = () => {
    const type = useSelector(state => state.auth.user.type)

    return (
        <Switch>
            <AppRoute exact path="/login" component={Login} layout={PublicLayout} />
            <AppRoute exact path="/register" component={Register} layout={PublicLayout} />
            <AppRoute exact path="/about-app" component={AboutApp} layout={PublicLayout} />
            <AppRoute exact path="/privacy-policy" component={PrivacyPolicy} layout={PublicLayout} />
            <AppRoute exact path="/terms-of-service" component={TermsofService} layout={PublicLayout} />
            <PublicRoute exact path="/foodics-signup" component={FoodicsSignup} layout={PublicLayout} />
            <PublicRoute exact path="/foodics-success" component={FoodicsSuccess} layout={PublicLayout} />
            <PublicRoute exact path="/share_location/:id" component={ShareLocation} layout={PublicLayout} />
            <PublicRoute exact path="/share_address/:id" component={ShareAddress} layout={PublicLayout} />
            <PublicRoute exact path="/track-order/:id" component={TrackNewOrder} layout={PublicLayout} />

            <PrivateRoute exact path="/" component={allUser(type && type === "Admin" ? AdminDashboard : UserDashboard)} layout={PrivateLayout} />
            <PrivateRoute exact path="/orders-charts" component={allUser(OrdersCharts)} layout={PrivateLayout} />
            <PrivateRoute exact path="/aggregator-orders-charts" component={Admin(AggregatorOrdersCharts)} layout={PrivateLayout} />
            <PrivateRoute exact path="/averages-charts" component={allUser(AverageApexCharts)} layout={PrivateLayout} />
            <PrivateRoute exact path="/completed-orders" component={allUser(CompletedOrders)} layout={PrivateLayout} />
            <PrivateRoute exact path="/canceled-orders" component={allUser(CanceledOrders)} layout={PrivateLayout} />
            <PrivateRoute exact path="/open-orders" component={allUser(OpenOrders)} layout={PrivateLayout} />
            <PrivateRoute exact path="/all-orders" component={allUser(OpenOrders)} layout={PrivateLayout} />
            <PrivateRoute exact path="/arrived-orders" component={allUser(OpenOrders)} layout={PrivateLayout} />
            <PrivateRoute exact path="/on-hold-orders" component={allUser(OpenOrders)} layout={PrivateLayout} />
            <PrivateRoute exact path="/users-location" component={Admin(UsersLocation)} layout={PrivateLayout} />
            <PrivateRoute exact path="/pending-pickup" component={allUser(OpenOrders)} layout={PrivateLayout} />
            <PrivateRoute exact path="/picked-orders" component={allUser(OpenOrders)} layout={PrivateLayout} />
            <PrivateRoute exact path="/completed-orders/:id" component={adminShopDsp(CompletedOrders)} layout={PrivateLayout} />
            <PrivateRoute exact path="/logistic-drivers/:id" component={Admin(LogisticDrivers)} layout={PrivateLayout} />
            <PrivateRoute exact path="/create-admin" component={Admin(CreateAdmin)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-admin/:id" component={Admin(EditAdmin)} layout={PrivateLayout} />
            <PrivateRoute exact path="/drivers" component={adminShopDsp(AllDrivers)} layout={PrivateLayout} />
            <PrivateRoute exact path="/active-drivers" component={Admin(AllDrivers)} layout={PrivateLayout} />
            <PrivateRoute exact path="/inactive-drivers" component={Admin(AllDrivers)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-driver/:id" component={adminShopDsp(EditDriver)} layout={PrivateLayout} />
            <PrivateRoute exact path="/buyers" component={Admin(AllBuyers)} layout={PrivateLayout} />
            <PrivateRoute exact path="/shop-registered" component={Admin(ShopRegistered)} layout={PrivateLayout} />
            <PrivateRoute exact path="/admins" component={Admin(AdminList)} layout={PrivateLayout} />
            <PrivateRoute exact path="/main-categories" component={Admin(MainCategory)} layout={PrivateLayout} />
            <PrivateRoute exact path="/create-main-category" component={Admin(AddCategory)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-main-category/:id" component={Admin(EditCategory)} layout={PrivateLayout} />
            <PrivateRoute exact path="/sub-categories" component={Admin(SubCategories)} layout={PrivateLayout} />
            <PrivateRoute exact path="/sub-categories/:id" component={Admin(SubCategories)} layout={PrivateLayout} />
            <PrivateRoute exact path="/create-sub-category" component={Admin(AddSubCategory)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-sub-category/:id" component={Admin(EditSubCategory)} layout={PrivateLayout} />
            <PrivateRoute exact path="/shops" component={Admin(ShopList)} layout={PrivateLayout} />
            <PrivateRoute exact path="/shop-sub-users/:id" component={Admin(ShopSubusers)} layout={PrivateLayout} />
            <PrivateRoute exact path="/integrators" component={Admin(Integrators)} layout={PrivateLayout} />
            <PrivateRoute exact path="/shop-branches" component={adminShop(ShopOwnerBranches)} layout={PrivateLayout} />
            <PrivateRoute exact path="/shop-branches/:id" component={Admin(ShopBranches)} layout={PrivateLayout} />
            <PrivateRoute exact path="/user/create-shop" component={Admin(CreateShop)} layout={PrivateLayout} />
            <PrivateRoute exact path="/user/create-integrator" component={Admin(AddNewIntegrator)} layout={PrivateLayout} />
            <PrivateRoute exact path="/create-shop-branch/:id" component={Admin(CreateShop)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-shop/:id" component={Admin(EditShop)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-shop-branch/:id" component={Admin(EditShop)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-integrator/:id" component={Admin(EditIntegrator)} layout={PrivateLayout} />
            <PrivateRoute exact path="/delivery-zones" component={Admin(DeliveryZones)} layout={PrivateLayout} />
            <PrivateRoute exact path="/delivery-zone-shops/:id" component={Admin(DeliveryZoneShopList)} layout={PrivateLayout} />
            <PrivateRoute exact path="/delivery-zones/:id" component={Admin(DeliveryZones)} layout={PrivateLayout} />
            <PrivateRoute exact path="/create-delivery-zone" component={Admin(AddDeliveryZone)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-delivery-zone/:id" component={Admin(UpdateDeliveryZone)} layout={PrivateLayout} />
            <PrivateRoute exact path="/create-order" component={adminShop(CreateOrder)} layout={PrivateLayout} />
            <PrivateRoute exact path="/areas" component={Admin(Areas)} layout={PrivateLayout} />
            <PrivateRoute exact path="/create-area" component={Admin(AddArea)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-area/:id" component={Admin(EditArea)} layout={PrivateLayout} />
            <PrivateRoute exact path="/cities" component={Admin(Cities)} layout={PrivateLayout} />
            <PrivateRoute exact path="/create-city" component={Admin(AddCity)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-city/:id" component={Admin(EditCity)} layout={PrivateLayout} />
            <PrivateRoute exact path="/scheduled-orders" component={adminShopIntegrator(ScheduledOrders)} layout={PrivateLayout} />
            <PrivateRoute exact path="/create-schedule-order" component={adminShop(CreateScheduleOrder)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-schedule-order/:id" component={adminShop(EditScheduleOrder)} layout={PrivateLayout} />
            <PrivateRoute exact path="/re-schedule-order/:id" component={adminShop(EditScheduleOrder)} layout={PrivateLayout} />
            <PrivateRoute exact path="/create-message" component={Admin(AddNotification)} layout={PrivateLayout} />
            <PrivateRoute exact path="/push-messages/:id" component={adminShop(PushMessages)} layout={PrivateLayout} />
            <PrivateRoute exact path="/invoices" component={adminShopIntegrator(Invoices)} layout={PrivateLayout} />
            <PrivateRoute exact path="/wallet-history" component={adminShop(WalletHistory)} layout={PrivateLayout} />
            <PrivateRoute exact path="/addjust-Txn/:id" component={Admin(AddjustTXN)} layout={PrivateLayout} />
            <PrivateRoute exact path="/balance-sheets/:id" component={Admin(BalanceSheets)} layout={PrivateLayout} />
            <PrivateRoute exact path="/prices" component={Admin(Prices)} layout={PrivateLayout} />
            <PrivateRoute exact path="/create-price" component={Admin(AddPrice)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-price/:id" component={Admin(EditPrice)} layout={PrivateLayout} />
            <PrivateRoute exact path="/shop-vendors" component={Admin(ShopVendors)} layout={PrivateLayout} />
            <PrivateRoute exact path="/user/create-shop-vendor" component={Admin(AddShopVendor)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-shop-vendor/:id" component={Admin(EditShopVendor)} layout={PrivateLayout} />
            <PrivateRoute exact path="/logistics" component={Admin(Logistics)} layout={PrivateLayout} />
            <PrivateRoute exact path="/user/create-logistic" component={Admin(CreateLogistic)} layout={PrivateLayout} />
            <PrivateRoute exact path="/edit-logistic/:id" component={Admin(EditLogistic)} layout={PrivateLayout} />
            <PrivateRoute exact path="/drivers-location" component={adminShopDsp(DriversLocation)} layout={PrivateLayout} />
            <PrivateRoute exact path="/pay-success" component={shop(PaymentSuccess)} layout={PrivateLayout} />
            <PrivateRoute exact path="/pay-fail" component={shop(PaymentFail)} layout={PrivateLayout} />
            <PrivateRoute exact path="/webhook-log" component={allUser(WebhookLog)} layout={PrivateLayout} />
            <PrivateRoute exact path="/aggregators" component={Admin(Aggregators)} layout={PrivateLayout} />
            <PrivateRoute exact path="/aggregator-logs/:id" component={Admin(AggregatorLogs)} layout={PrivateLayout} />

            <Route
                path="*"
                component={() => (
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <h1 style={{ color: "#000", fontSize: "2rem" }}>Page Not Found!!</h1>
                    </div>
                )}
            />
        </Switch>
    )
}
export default RootRouters;
