import React from 'react';
import { getFormatedDate } from '../../Helper';

function ShopDetailsModel(props) {

    const { modal, closeModal, shopDetails } = props
    const {
        main_category_information,
        sub_category_information,
        shop_information,
        username,
        shopmainsort,
        shopsubsort,
        buyerratings,
        shopratings,
        numofbuyerratings,
        earnigs,
        _created_at,
    } = shopDetails ? shopDetails : {}
    const { shop, abbreviation, publish, active, is_vip } = shop_information ? shop_information : {}
    const mainCategory = main_category_information?.title
    const subCategory = sub_category_information?.title


    const getNumberValue = (value) => {
        if (value === 0) {
            return value
        } else if (!value) {
            return 'NA'
        }
        return value
    }

    const getBooleanValue = (value) => {
        if (value === true) {
            return 'YES'
        }
        else if (value === false) {
            return 'NO'
        }
        return 'Not Available'
    }

    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`}
            style={{ display: modal ? "block" : "none" }} tabIndex="-1"
            role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog  modal-lg " role="document">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">User information</h5>
                        <button onClick={() => closeModal()} type="button"
                            className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form >
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Shop Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={shop ? shop : "Not Available"} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>User Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={username ? username : "Not Available"} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Main Category</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={mainCategory ? mainCategory : "Not Available"} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Sub Category</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={subCategory ? subCategory : "Not Available"} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Abbreviation</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={abbreviation ? abbreviation : "Not Available"} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Created At</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getFormatedDate(_created_at)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Published</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getBooleanValue(publish)} />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Active</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getBooleanValue(active)} />
                                    </div></div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>VIP</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getBooleanValue(is_vip)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Shop mainsort</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getNumberValue(shopmainsort)} />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Shop subsort</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getNumberValue(shopsubsort)} />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Earnigs</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getNumberValue(earnigs)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Buyer ratings</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getNumberValue(buyerratings)} />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Shop ratings</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getNumberValue(shopratings)} />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Num of buyer ratings</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getNumberValue(numofbuyerratings)} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ShopDetailsModel

