import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import IntegratorDetailsModel from '../../Components/DetailsModel/IntegratorDetails';
import ActionPermissionModal from '../../Components/PermissionModal/ActionPermissionModal';
import ExportPermissionModal from '../../Components/PermissionModal/ExportPermissionModal';
import QuickEditModal from '../../Components/UserModal/QuickEditModal';
import ExportModel from '../../Components/ConfirmModel/ExportData';
import { getIntegratorUsers } from '../../Redux/action/User';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { cleanShopFormData, setFormCurrentStep } from '../../Redux/action/Shop';
import Pagination from '../../Components/Pagination';
import { Link, useLocation, useHistory } from 'react-router-dom';
import DateInputs from '../../Components/DatePicker';
import TableFooter from '../../Components/TableFooter';
import { updateRelatedOrderId } from '../../Redux/action/App';
import UserDocuments from '../../Components/DocumentsModal/UserDocuments';
import { SidebarContext } from '../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';
import AddMoneyToWallet from '../../Components/UserModal/AddMoneyToWallet';
import ManageDeliveryPrice from '../../Components/UserModal/ManageDeliveryPrice';

import {
    trimNestedObjValues,
    isAllItemSelected,
    handleSelectListItem,
    getFormatedDate,
    setCharacterLimit,
    handleCopyClipboard,
    exportToExcel,
    getNestedValue
} from '../../Helper';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useSelector } from 'react-redux';



// Export CSV File Headers
const headers = [
    { label: "Shop Name", key: "shop_information.shop" },
    { label: "Abbreviation", key: "shop_information.abbreviation" },
    { label: "User Name", key: "username" },
    { label: "User Phone", key: "phone" },
    { label: "Email address", key: "email" },
    { label: "Published", key: "shop_information.publish" },
    { label: "Active", key: "shop_information.active" },
    { label: "City", key: "shop_information.city" },
    { label: "Created At", key: "_created_at" },
];

function Integrators(props) {
    const { isLoading, setLoading } = props
    const location = useLocation()
    const history = useHistory()
    const { user } = useSelector(state => state.auth)
    const { allowed_action_permission } = user
    const [integratorModal, setIntegratorModal] = useState(false)
    const [deliveryPriceModal, setDeliveryPriceModal] = useState(false)
    const [documentModal, setDocumentModal] = useState(false)
    const [integrator, setIntegrator] = useState({})
    const [integratorDetails, setIntegratorDetails] = useState(null)
    const [integrators, setIntegrators] = useState([])
    const {
        csvLink,
        dispatch,
        totalCount,
        toast,
        resetForm,
        sorting,
        handleSubmit,
        editModal,
        setEditModal,
        handleSorting,
        setTotalCount,
        exportModal,
        walletModal,
        setWalletModal,
        toggleExportModal,
        fileLoading,
        setFileLoading,
        selectedItem,
        exportsData,
        actionPermissionModal,
        setActionPermissionModal,
        exportPermissionModal,
        setExportPermissionModal,
        setExportsData,
        setSelectedItem,
        handleSelectItem,
        currentPage,
        setcurrentPage,
        selectedDate,
        setSelectedDate,
        itemsPerPage,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
    } = useContext(SidebarContext)

    const [searchParams, setSearchParams] = useState({
        user_search: {
            type: "Integrator",
            username: "",
            from_date: "",
            to_date: ""
        },
        shop_search: {
            abbreviation: ""
        }
    })

    useLayoutEffect(() => {
        if (location.state && location.state.currentPage) {
            setcurrentPage(location.state.currentPage)
        }
    }, [])

    useEffect(() => {
        getIntegratorsListData();
    }, [currentPage, sorting])

    useEffect(() => {
        dispatch(cleanShopFormData());
        dispatch(setFormCurrentStep("USER_INFO"))
    }, [])


    const handleSearch = (e) => {
        const { name, value } = e.target
        setSearchParams((prevState) => ({
            ...prevState,
            shop_search: {
                ...prevState.shop_search,
                [name]: value,
            }
        }));
    }

    const handleUserSearch = (e) => {
        const { name, value } = e.target
        setSearchParams((prevState) => ({
            ...prevState,
            user_search: {
                ...prevState.user_search,
                [name]: value,
            }
        }));
    }

    const handleChangeDate = (date, name) => {
        setSearchParams((prevState) => ({
            ...prevState,
            user_search: {
                ...prevState.user_search,
                [name]: date ? moment(date).format("YYYY-MM-DD") : '',
            }
        }));
        setSelectedDate((prevState) => ({
            ...prevState,
            [name]: date,
        }));
    }

    const getIntegratorsListData = async () => {
        setLoading(true)
        const search_params = trimNestedObjValues(searchParams)
        const payload = {
            search: search_params,
            shorting: sorting,
            pagination: { start: currentPage, length: itemsPerPage }
        }
        await dispatch(getIntegratorUsers(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setIntegrators(response.data.users)
                        setTotalCount(response.data.total_count)
                    } else {
                        setIntegrators([])
                        setTotalCount(null)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                    setIntegrators([])

                })
            .catch(
                error => {
                    setIntegrators([])
                    console.log("Catch", error);
                }
            )
    }

    const getExportingData = async () => {
        const search_params = trimNestedObjValues(searchParams);
        const totalItem = [];

        const fetchIntegratorUsers = async (pagination) => {
            const payload = {
                search: search_params,
                selected_items: pagination.start === 1 ? [...selectedItem] : [],
                shorting: sorting,
                pagination: pagination
            };

            try {
                setFileLoading(true);
                const response = await dispatch(getIntegratorUsers(payload));
                setFileLoading(false);

                if (response.status === "1") {
                    totalItem.push(...response.data.users);
                }
            } catch (error) {
                setFileLoading(false);
                toast.error(error.response?.data?.message);
            }
        };

        if (selectedItem && selectedItem.length > 0) {
            await fetchIntegratorUsers({ start: 1, length: 1000 });
        } else {
            const pages = Math.ceil(totalCount / 50000);
            for (let i = 1; i <= pages; i++) {
                await fetchIntegratorUsers({ start: i, length: 50000 });
            }
        }

        const exportData = totalItem?.map((item) => {
            const exportItem = {};
            headers.forEach(header => {
                exportItem[header.label] = getNestedValue(item, header.key);
            });
            return exportItem;
        });
        const fileName = `Integrator_user-${moment(new Date()).format("hh-mm-ss")}.xlsx`
        exportToExcel(exportData, fileName);
        // setExportsData(totalItem);
        // csvLink.current.link.click();
        toast.success("Data exported successfully");
        toggleExportModal();
    };

    const handleReset = async () => {
        const resetFormData = {
            user_search: {
                type: "Integrator",
                username: "",
                from_date: "",
                to_date: ""
            },
            shop_search: {
                abbreviation: ""
            }
        }
        setSearchParams(resetFormData)
        resetForm();
    }

    const handleRelatedOrders = (id, userName) => {
        const payload = { shopid: id, relatedBy: 'Integrator', user_name: userName }
        dispatch(updateRelatedOrderId(payload))
        history.push(`/completed-orders/${id}`)
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header main_breadcrumb_top">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Integrators</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Integrators</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="all_drivers">
                                    <div className="card card-dark">
                                        <div className="card-header">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-6 col-lg-11">
                                                        <h3 className="pl-5">Search</h3>
                                                    </div>
                                                    <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                        <i className="fas fa-angle-up click_search"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form className="form_search" action="">
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label>User name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        onChange={handleUserSearch}
                                                        value={searchParams.user_search.username}
                                                        name="username" placeholder="Enter name" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Abbreviation</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        onChange={handleSearch}
                                                        value={searchParams.shop_search['abbreviation']}
                                                        name="abbreviation" placeholder="Abbreviation" />
                                                </div>
                                                <DateInputs selectedDate={selectedDate} handleChangeDate={handleChangeDate} />
                                            </div>
                                            <div className="card-footer text-center">
                                                <button type="reset" className="btn btn-primary mr-2" onClick={handleReset} >Reset</button>
                                                <button type="button" onClick={() => handleSubmit(getIntegratorsListData)} className="btn btn-success">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="row pagination_new">
                                        <div className="col-lg-6 col-12">
                                            <Link to="/user/create-integrator"><button type="add" disabled={isLoading} className="btn btn-primary"><i className="fa-regular fa-circle-plus"></i>Create</button></Link>
                                            <button disabled={isLoading} type="button" onClick={() => handleSubmit(getIntegratorsListData)} className="btn btn-secondary ml-2"><i className={`fa-regular fa-rotate-right ${isLoading && 'fa_spinner'}`}></i>Refresh</button>
                                            <button type='button' disabled={fileLoading || !totalCount || isLoading} onClick={toggleExportModal} className="btn btn-info ml-2"><i className="fa-regular fa-circle-down"></i>{fileLoading ? "Loading.." : "Export"}</button>
                                            {/* <CSVLink className='csv-link' target='_blank'
                                                headers={headers}
                                                data={exportsData}
                                                filename={`Integrator_user_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss-A")}`}
                                                ref={csvLink}
                                            /> */}
                                        </div>
                                        {integrators?.length > 0 &&
                                            <div className="col-lg-6 col-12">
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    maxPageNumberLimit={maxPageNumberLimit}
                                                    minPageNumberLimit={minPageNumberLimit}
                                                    setcurrentPage={setcurrentPage}
                                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                    setminPageNumberLimit={setminPageNumberLimit}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {integrators?.length > 0 &&
                                        <React.Fragment>
                                            <div className="card table_card completed_1">
                                                <div className="card-body all_drive_table">
                                                    <table id="example2" className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th><input type="checkbox" id="check" checked={isAllItemSelected(integrators, selectedItem)} onChange={(event) => handleSelectListItem(event, integrators, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th>
                                                                <th>More info</th>
                                                                <th>Shop Name
                                                                    {sorting.order_by === "shop_information.shop" ?
                                                                        <i onClick={() => handleSorting("shop_information.shop")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("shop_information.shop")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>Abbreviation</th>
                                                                <th>User Name
                                                                    {sorting.order_by === "username" ?
                                                                        <i onClick={() => handleSorting("username")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("username")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>Email</th>
                                                                <th>Phone</th>
                                                                <th>Active</th>
                                                                <th>Created At
                                                                    {sorting.order_by === "_created_at" ?
                                                                        <i onClick={() => handleSorting("_created_at")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i> :
                                                                        <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {integrators.map((item, i) => (
                                                                <tr key={item._id} className="accordion-toggle">
                                                                    <td>
                                                                        <input type="checkbox" className="user_table_checkbox"
                                                                            onChange={(event) => handleSelectItem(event, item._id)}
                                                                            checked={selectedItem.includes(item._id)} />
                                                                    </td>
                                                                    <td>
                                                                        <div className="view-details-btn">
                                                                            <span title='Show details'
                                                                                onClick={() => {
                                                                                    setIntegrator(item)
                                                                                    setIntegratorModal(true)
                                                                                }} ><i className="fa-regular fa-circle-info fa-lg"></i>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td><i className={`${item?.is_webhook_exit ? 'fa-solid fa-link' : 'fa-duotone fa-link-slash'} `}></i> <span title={item?.shop_information?.shop}>{setCharacterLimit(item?.shop_information?.shop)}</span></td>
                                                                    <td>{item.shop_information.abbreviation ? item.shop_information.abbreviation : "Not Available"}</td>
                                                                    <td><span title={item?.username}>{setCharacterLimit(item?.username)}</span></td>
                                                                    <td>{item.email ? item.email : "Not available"}</td>
                                                                    <td>{item.phone ? item.phone : "Not available"}</td>
                                                                    <td><span className={item.shop_information.active ? 'yes_1' : item.shop_information.active === false ? 'no_1' : 'not_avl'} >
                                                                        {item.shop_information.active ? 'Yes' : item.shop_information.active === false ? "No" : 'NA'}</span>
                                                                    </td>
                                                                    <td>{getFormatedDate(item._created_at)}</td>
                                                                    <td>
                                                                        <div className="action_button">
                                                                            <div className="btn-group">
                                                                                <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                                                </button>
                                                                                <div className="dropdown-menu">
                                                                                    <span onClick={() => handleCopyClipboard(item._id)} className="dropdown-item"><span>
                                                                                        <i className="fa-regular fa-copy"></i>Copy User Id</span>
                                                                                    </span>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <Link to={{ pathname: `/edit-integrator/${item._id}`, state: { currentPage: currentPage } }} className="dropdown-item" ><span className="blue_1">
                                                                                        <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span>
                                                                                    </Link>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <span className="dropdown-item"
                                                                                        onClick={() => {
                                                                                            setIntegrator(item)
                                                                                            setEditModal(true)
                                                                                        }}>
                                                                                        <span className="blue_1">
                                                                                            <i className="fa fa-pencil" aria-hidden="true"></i>Quick Edit</span>
                                                                                    </span>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <span className="dropdown-item" onClick={() => {
                                                                                        setIntegrator(item)
                                                                                        setActionPermissionModal(true)
                                                                                    }}>
                                                                                        <i className="fa-sharp fa-regular fa-gear"></i>Action Permission
                                                                                    </span>

                                                                                    <div className="dropdown-divider"></div>
                                                                                    <span className="dropdown-item" onClick={() => {
                                                                                        setIntegrator(item)
                                                                                        setExportPermissionModal(true)
                                                                                    }}>
                                                                                        <i className="fa-regular fa-file-chart-column"></i>Export Permission
                                                                                    </span>

                                                                                    <div className="dropdown-divider"></div>
                                                                                    <span className="dropdown-item" onClick={() => {
                                                                                        setIntegrator(item)
                                                                                        setDocumentModal(true)
                                                                                    }}>
                                                                                        <i className="fa-sharp fa-solid fa-file-pdf"></i>View documents
                                                                                    </span>

                                                                                    <div className="dropdown-divider"></div>
                                                                                    <span className="dropdown-item" onClick={() => handleRelatedOrders(item._id, item.username)}>
                                                                                        <i className="fa fa-list" aria-hidden="true"></i>Related Orders
                                                                                    </span>

                                                                                    <div className="dropdown-divider"></div>
                                                                                    <span className="dropdown-item"
                                                                                        onClick={() => {
                                                                                            setIntegrator(item)
                                                                                            setDeliveryPriceModal(true)
                                                                                        }}>
                                                                                        <span className="blue_1">
                                                                                            <i className="fa-regular fa-cash-register"></i>Delivery Type</span>
                                                                                    </span>

                                                                                    {allowed_action_permission?.includes('Wallet-Recharge') &&
                                                                                        <>
                                                                                            <div className="dropdown-divider"></div>
                                                                                            <span className="dropdown-item"
                                                                                                onClick={() => {
                                                                                                    setIntegrator(item)
                                                                                                    setWalletModal(true)
                                                                                                }}
                                                                                            >
                                                                                                <span className='green_light_1'>
                                                                                                    <i className="fa-solid fa-wallet"></i>Wallet Recharge
                                                                                                </span>
                                                                                            </span>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>)
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row pagination_new">
                                                <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={integrators} totalCount={totalCount} />
                                                <div className="col-lg-4">
                                                    <Pagination
                                                        totalCount={totalCount}
                                                        currentPage={currentPage}
                                                        itemsPerPage={itemsPerPage}
                                                        maxPageNumberLimit={maxPageNumberLimit}
                                                        minPageNumberLimit={minPageNumberLimit}
                                                        setcurrentPage={setcurrentPage}
                                                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                        setminPageNumberLimit={setminPageNumberLimit}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {integrators?.length === 0 && !isLoading && <NotFoundData />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {exportModal &&
                < ExportModel
                    loader={fileLoading}
                    toggleExportModal={toggleExportModal}
                    exportModal={exportModal}
                    getExportingData={getExportingData}
                    selectedItem={selectedItem}
                    totalCount={totalCount} />
            }
            {integratorModal &&
                <IntegratorDetailsModel
                    user={integrator}
                    modal={integratorModal}
                    closeModal={() => setIntegratorModal(false)} />
            }
            {actionPermissionModal &&
                <ActionPermissionModal
                    modal={actionPermissionModal}
                    closeModal={() => setActionPermissionModal(false)}
                    user={integrator}
                    getUserList={getIntegratorsListData}
                    setLoading={setLoading}
                    isLoading={isLoading}
                />
            }
            {exportPermissionModal &&
                <ExportPermissionModal
                    modal={exportPermissionModal}
                    closeModal={() => setExportPermissionModal(false)}
                    user={integrator}
                    getUserList={getIntegratorsListData}
                    setLoading={setLoading}
                    isLoading={isLoading}
                />
            }
            {documentModal &&
                < UserDocuments
                    modal={documentModal}
                    closeModal={() => setDocumentModal(false)}
                    user={integrator}
                    getListData={getIntegratorsListData}
                    setLoading={setLoading}
                    isLoading={isLoading}
                />
            }
            {editModal &&
                < QuickEditModal
                    modal={editModal}
                    closeModal={() => setEditModal(false)}
                    user={integrator}
                    getListData={getIntegratorsListData}
                    setLoading={setLoading}
                    isLoading={isLoading}
                />
            }
            {walletModal &&
                < AddMoneyToWallet
                    modal={walletModal}
                    closeModal={() => setWalletModal(false)}
                    user={integrator}
                    getListData={getIntegratorsListData}
                    setLoading={setLoading}
                    isLoading={isLoading}
                />
            }
            {deliveryPriceModal &&
                < ManageDeliveryPrice
                    setLoading={setLoading}
                    isLoading={isLoading}
                    user={integrator}
                    modal={deliveryPriceModal}
                    closeModal={() => setDeliveryPriceModal(false)}
                />
            }
        </>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(Integrators))