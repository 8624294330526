import React, { useEffect } from 'react';
import { getMainCategoryDetails, updateMainCategory } from '../../Redux/action/Category';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import {useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';


function EditCategory(props) {
  const { setLoading } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { currentPage } = location.state ? location.state : {}


  useEffect(() => {
    getMainCategoryDetaailsData()
  }, [])

  const formik = useFormik({
    initialValues: {
      id: params.id,
      title: '',
      number: '',
      count: '',
      is_active: ''
    },

    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .min(3, 'This field must be at least 3 characters'),

      number: Yup.number()
        .typeError('You must specify a number')
        .required("This field is required")
        .min(0, "Please enter a positive number")
    }),

    onSubmit: async values => {
      const fieldValues = values
      setLoading(true)
      await dispatch(updateMainCategory(fieldValues))
        .then(
          response => {
            setLoading(false)
            if (response.status === '1') {
              toast.success(response.data.message)
              history.push({ pathname: '/main-categories', state: { currentPage: currentPage } })
            } else {
              toast.error(response.data.error[0])
            }
          },
          (error) => {
            setLoading(false);
            toast.error(error.response.data?.message)
          }
          )
        .catch(
          error => console.log(error)
        )
    },
  });
  const getMainCategoryDetaailsData = async () => {
    setLoading(true)
    const payload = {
      is_detail: "true",
      search: { _id: params.id },
      shorting: { order_in: "asc", order_by: "category" }
    }
    await dispatch(getMainCategoryDetails(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status === "1") {
            const { _id, category, count, num, active } = response.data.category
            formik.setFieldValue('id', _id)
            formik.setFieldValue('title', category)
            formik.setFieldValue('number', num)
            formik.setFieldValue('is_active', active)
            formik.setFieldValue('count', count)
          }
        },
        (error) => {
          setLoading(false);
          toast.error(error.response.data?.message)
        }
        )
      .catch(error => console.log(error))
  }

  return (
    <div className="content-wrapper">
      <div className="content-header main_breadcrumb_top">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Edit Main Category</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Main Category</label>
                          <input
                            required
                            name="title"
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            onBlur={formik.handleBlur}
                            type="text" className="form-control" placeholder="Category name" />
                          {formik.touched.title && formik.errors.title ? (<span className="text-danger ">{formik.errors.title}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group required">
                          <label className="control-label">Sort Order</label>
                          <input
                            required
                            name="number"
                            onChange={formik.handleChange}
                            value={formik.values.number}
                            onBlur={formik.handleBlur}
                            type="number" className="form-control" placeholder="Sort order" />
                          {formik.touched.number && formik.errors.number ? (<span className="text-danger" >{formik.errors.number}</span>) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Activity</label>
                          <select className="form-control"
                            name='is_active'
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              const { value } = e.target
                              formik.setFieldValue('is_active', value == 'true' ? true : false)
                            }}
                            value={formik.values.is_active?.toString()}>
                            <option value='true'>Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="pb-5 form-foot-btn">
                      <button type="button" className="btn btn-primary mr-2" onClick={() => history.push({pathname: '/main-categories', state: { currentPage: currentPage }})}>Back</button>
                      <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default IsLoadingHOC(IsLoggedinHOC(EditCategory))