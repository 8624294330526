import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { default as ReactSelect } from "react-select";
import { selectOptionStyle } from '../../Helper';
import { SidebarContext } from '../../Context/SidebarContext';


function PlaceOrderModal(props) {
    const { loader, modal, closeModal, handlePlaceOrder, orderDetails } = props
    const { ordernumber } = orderDetails ? orderDetails : {}
    const { selectedItem } = useContext(SidebarContext)
    const { userGlobalData } = useSelector(state => state.app)
    const { delivery_provider } = userGlobalData ? userGlobalData : []

    const providersOptions = delivery_provider?.map(item => ({ value: item.toLowerCase(), label: item }))

    const formik = useFormik({
        initialValues: {
            delivery_provider: ''
        },
        validationSchema: Yup.object({
            delivery_provider: Yup.string()
                .required("This field is required"),
        }),
        onSubmit: async values => {
            let fieldValues = values
            let deliveryProvider = fieldValues.delivery_provider
            await handlePlaceOrder(deliveryProvider)
           
        },
    });

    const handleSelectProvider = (option) => {
        if (option) {
            let { value } = option
            formik.setFieldValue('delivery_provider', value)
        } else {
            formik.setFieldValue('delivery_provider', "")
        }
    }


    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Place order to other</h5>
                            <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {selectedItem?.length === 0 &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="control-label">Order Number</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                defaultValue={ordernumber}
                                                placeholder='Order number'
                                                disabled />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <div className="d-flex justify-content-between">
                                            <label className="control-label">Delivery Provider</label>
                                        </div>
                                        <ReactSelect placeholder="Select delivery provider"
                                            isClearable={true}
                                            options={providersOptions}
                                            hideSelectedOptions={false}
                                            value={providersOptions?.find(item => item.value === formik.values.delivery_provider)}
                                            styles={selectOptionStyle}
                                            onChange={option => handleSelectProvider(option)}
                                        />
                                        {formik.touched.delivery_provider && formik.errors.delivery_provider ? (<span className="text-danger">{formik.errors.delivery_provider}</span>) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button disabled={loader} type="submit" className="btn btn-success"> {loader ? "Processing.." : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default PlaceOrderModal