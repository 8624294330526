import React from 'react';
import DatePicker from 'react-datepicker';


function SelectDate(props) {
    const { selectedDate, handleChangeDate } = props

    return (
        <div>
            <DatePicker className="form-control cursor-pointer" placeholderText="Select Date"
                showIcon={selectedDate ? false : true}
                dateFormat='dd-MMM-yyyy'
                dropdownMode="select"
                isClearable={true}
                selected={selectedDate}
                maxDate={new Date()}
                onChange={date => handleChangeDate(date)}
                showYearDropdown
                showMonthDropdown
                adjustDateOnChange
                autocomplete={false}
            />
        </div>
    )
}

export default SelectDate
