import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { addUserInfoMation, setFormCurrentStep, updateShop } from '../../Redux/action/Shop';
import { checkExistUser } from '../../Redux/action/User';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { phoneRegExp, countryCode } from '../../Config/constants';



function UserInformation(props) {
    let formRef = useRef();
    const { setLoading } = props
    const dispatch = useDispatch()
    const params = useParams()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const {
        userInfo,
        shopInfo,
        shopOtherInfo,
        socialurl,
        openingHours,
        shopImage,
        shopOtherImages,
        p_shopid
    } = useSelector(state => state.shop)


    useEffect(() => {
        if (userInfo && userInfo.username) {
            const fields = ["username", "phone"];
            fields.forEach(field => formik.setFieldValue(field, userInfo[field], false));

            let phoneNumber = userInfo.phone.toString();
            let withoutCountryPhone = phoneNumber.replace(countryCode, '');
            formik.setFieldValue('phone', withoutCountryPhone)

            if (withoutCountryPhone[0] === "+") {
                withoutCountryPhone = withoutCountryPhone.substring(1)
                formik.setFieldValue('phone', withoutCountryPhone)
            }

            if (userInfo.password && userInfo.confirm_password) {
                formik.setFieldValue('password', userInfo.password)
                formik.setFieldValue('confirm_password', userInfo.confirm_password)
            }
            if (userInfo.email) {
                formik.setFieldValue('email', userInfo.email)
            }
            if (userInfo.hasOwnProperty('active')) {
                formik.setFieldValue("active", userInfo.active)
            }
        }
    }, [userInfo])

    const formik = useFormik({
        initialValues: {
            username: "",
            email: "",
            phone: "",
            active: true,
            password: "",
            confirm_password: ""
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required("This field is required")
                .min(3, 'User name must be at least 3 characters'),

            email: Yup.string()
                .required("This field is required")
                .min(3, 'User name must be at least 3 characters'),

            phone: Yup.string()
                .required("This field is required")
                .matches(phoneRegExp, 'Phone number is not valid'),

            password: Yup.string()
                .min(5, "Password must be at least 5 characters"),

            confirm_password: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
        }),
        onSubmit: async values => {
            let fieldValues = values
            if (formRef.current.name === 'next') {
                checkExistUserDetail(fieldValues)
            } else {
                handleSubmitData(fieldValues)
            }
        },
    });

    // To Check User Exist details //  
    const checkUserNameExist = (username) => {
        const payload = { search: { username, user_id: params.id } }
        return dispatch(checkExistUser(payload))
    }
    const checkUserEmailExist = (email) => {
        const payload = { search: { email, user_id: params.id } }
        return dispatch(checkExistUser(payload))
    }

    const checkExistUserDetail = async (fieldValues) => {
        const { username, email } = fieldValues
        setLoading(true)
        const [nameResponse, emailResponse] = await Promise.all([checkUserNameExist(username), checkUserEmailExist(email)])
        setLoading(false)
        if (nameResponse.data.is_exist) {
            toast.error(nameResponse.data.message)
        }
        else if (emailResponse.data.is_exist) {
            toast.error(emailResponse.data.message)
        }
        else {
            dispatch(addUserInfoMation(fieldValues));
            dispatch(setFormCurrentStep("SHOP_INFO"))
        }
    }

    const handleChangeBooleanValue = (e) => {
        let { name, value } = e.target
        value = JSON.parse(value)
        formik.setFieldValue(name, value)
    }

    const handleSubmitData = async (fieldValues) => {
        const payload = {
            user_id: params.id,
            shop_id: p_shopid,
            ...fieldValues,
            ...shopInfo,
            ...shopOtherInfo,
            ...shopImage,
            hours: { ...openingHours },
            otherimages: [...shopOtherImages],
            sociallinks: { ...socialurl },
        }
        setLoading(true)
        await dispatch(updateShop(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status === '1') {
                        toast.success(response.data.message)
                    } else {
                        toast.error(response.data?.error[0])
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                    console.log(error)
                }
            )
    }

    return (
        <div className="card-body">
            <div className="tab-content" id="custom-tabs-one-tabContent">
                <div className="tab-pane fade active show" id="custom-tabs-shop-info" role="tabpanel" aria-labelledby="custom-tabs-shop-info">
                    <form>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">User name</label>
                                    <input className={`form-control`} placeholder="Name"
                                        required
                                        type="text"
                                        name='username'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.username}
                                    />
                                    {formik.touched.username && formik.errors.username ? (<span className="text-danger">{formik.errors.username}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required">
                                    <label className="control-label">Email</label>
                                    <input className={`form-control`} placeholder="Email address"
                                        required
                                        type="email"
                                        name='email'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email} />
                                    {formik.touched.email && formik.errors.email ? (<span className="text-danger">{formik.errors.email}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group required ">
                                    <label className="control-label">Phone</label>
                                    <div className="input-group">
                                        <div className="input-group-append">
                                            <span className="input-group-text">{countryCode}</span>
                                        </div>
                                        <input
                                            required
                                            name="phone"
                                            onChange={formik.handleChange}
                                            value={formik.values.phone}
                                            onBlur={formik.handleBlur}
                                            type="text" className="form-control" placeholder="Phone" />
                                    </div>
                                    {formik.touched.phone && formik.errors.phone ? (<span className="text-danger">{formik.errors.phone}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Active</label>
                                    <select name='active' className="custom-select"
                                        value={formik.values.active}
                                        onChange={handleChangeBooleanValue}>
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className="control-label">Password</label>
                                    <input className="form-control" placeholder="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                    />
                                    <i className={showPassword ? "fas fa-eye" : " fas fa-eye-slash"} onClick={() => setShowPassword(!showPassword)} id="eye"></i>
                                    {formik.touched.password && formik.errors.password ? (<span className="text-danger">{formik.errors.password}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className="control-label">Confirm Password</label>
                                    <input className="form-control" placeholder="Confirm Password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        name='confirm_password'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.confirm_password}
                                    />
                                    <i className={showConfirmPassword ? "fas fa-eye" : " fas fa-eye-slash"} id="eye" onClick={() => setShowConfirmPassword(!showConfirmPassword)} ></i>
                                    {formik.touched.confirm_password && formik.errors.confirm_password ? (<span className="text-danger">{formik.errors.confirm_password}</span>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="pb-5 footer-step">
                            <button type='button' ref={formRef} className="btn btn-success mr-2"
                                onClick={() => { formRef.current.name = 'update'; formik.handleSubmit() }} >Update</button>
                            <button type='button' ref={formRef} className="btn btn-info"
                                onClick={() => { formRef.current.name = 'next'; formik.handleSubmit() }} >Next</button>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
}
export default IsLoadingHOC(IsLoggedinHOC(UserInformation))