import React, {useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { getUserForSearchBar } from '../../Redux/action/User';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { default as ReactSelect } from "react-select";
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { addUsersToDeliveryZone } from '../../Redux/action/DeliveryZone';
import { selectOptionStyle } from '../../Helper';



function AddUserToDeliveryZone(props) {
    const dispatch = useDispatch()
    const { setLoading, loader, modal, closeModal, clearSelectedItem, deliveryZones } = props
    const [userFetching, setUserFetching] = useState(false)
    const [usersBySearch, setUsersBySearch] = useState([])
    const [selectedUsers, setSelectedUsers] = useState("");


    const formik = useFormik({
        initialValues: {
            users: [],
            delivery_zones: deliveryZones
        },
        validationSchema: Yup.object({
            users: Yup.array()
                .required("This field is required")
                .min(1, 'This field is required'),
        }),
        onSubmit: async values => {
            let fieldValues = values
            setLoading(true)
            await dispatch(addUsersToDeliveryZone(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status === '1') {
                            toast.success(response.data.message)
                            formik.resetForm();
                            clearSelectedItem()
                            closeModal();
                        } else {
                            toast.error(response.data.error[0])
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                    }
                ).catch(error => console.log(error))
        }
    });


    // load options using API call with searched Drivers
    const getSearchUsers = async (searchValue) => {
        let payload = {
            search: { id: '', type: "Shop,Integrator", nickname: searchValue, username: "", phone: '' },
            shorting: { order_in: "asc", order_by: "nickname" }
        }
        setUserFetching(true)
        await dispatch(getUserForSearchBar(payload))
            .then(
                response => {
                    setUserFetching(false)
                    if (response.status == "1") {
                        const resData = response.data.users
                        const alldrivers = []
                        resData.forEach(item => {
                            const { _id, nickname, username } = item
                            alldrivers.push({ label: nickname || username, value: _id })
                        })
                        setUsersBySearch(alldrivers)
                    } else {
                        setUsersBySearch([])
                    }
                },
                error => {
                    setUsersBySearch([])
                    setUserFetching(false)
                    toast.error(error.response.data?.message)
                }
            )
    };

    const getDriversData = useCallback(debounce((searchvalue) => getSearchUsers(searchvalue), 500), []);
    const searchUser = (inputValue) => {
        const value = inputValue.trim()
        if (value && value.length > 0) {
            getDriversData(value);
        }
    }


    const handleSelectUSer = (options) => {
        setSelectedUsers(options);
        const optionsData = []
        options.map(item => optionsData.push(item.value))
        formik.setFieldValue('users', optionsData);
    }


    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-info" id="exampleModalLongTitle">Add users to selected delivery zones</h5>
                            <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <div className="d-flex justify-content-between">
                                            <label className="control-label">User name</label>
                                        </div>
                                        <ReactSelect placeholder="Search"
                                            isClearable={true}
                                            isLoading={userFetching}
                                            options={usersBySearch}
                                            value={selectedUsers}
                                            isMulti={true}
                                            onInputChange={searchUser}
                                            onChange={handleSelectUSer}
                                            styles={selectOptionStyle}
                                        />
                                        {formik.touched.users && formik.errors.users ? (<span className="text-danger">{formik.errors.users}</span>) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button disabled={loader} type="submit" className="btn btn-success"> {loader ? "Processing.." : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(AddUserToDeliveryZone))
