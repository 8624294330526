import { authAxios } from "../../Config/axios"
import { trimObjValues } from "../../Helper"


export const getMainCategories = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/categories/get_main_categories", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getMainCategoryDetails = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/categories/get_main_categories", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const updateMainCategory = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/categories/update_main_category", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const addMainCategory = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/categories/add_main_categories", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getSubCategories = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/categories/get_sub_categories", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getSubCategoryDetails = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/categories/get_sub_categories", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const addSubCategory = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/categories/add_sub_categories", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const updateSubCategory = (data) => async _dispatch => {
    let PayloadData = trimObjValues(data)
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/categories/update_sub_category", PayloadData)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getMainRelatedSubCatgs = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/categories/get_sub_categories", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}





