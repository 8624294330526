import React from 'react';
import { getFormatedDate } from '../../Helper';

function IntegratorDetailsModel(props) {
    const { modal, closeModal, user } = props
    const { shop_information, username, phone, email, _created_at } = user ? user : {}
    const { shop, abbreviation, active, is_vip, city, locationarea, locationaddress } = shop_information ? shop_information : {}


    const getBooleanValue = (value) => {
        if (value === true) {
            return 'YES'
        }
        else if (value === false) {
            return 'NO'
        }
        return 'Not Available'
    }

    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">User information</h5>
                        <button onClick={() => closeModal()} type="button"
                            className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body overflow-auto detail--modal--scroll">
                        <form >
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Shop Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={shop ? shop : "Not Available"} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>User Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={username ? username : "Not Available"} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Location Address</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={locationaddress ? locationaddress : "Not Available"} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Location Area</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={locationarea ? locationarea : "Not Available"} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={email ? email : "Not Available"} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Phone</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={phone ? phone : "Not Available"} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Abbreviation</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={abbreviation ? abbreviation : "Not Available"} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>City</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={city ? city : "Not Available"} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Created At</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getFormatedDate(_created_at)} />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Active</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getBooleanValue(active)} />
                                    </div></div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>VIP</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            defaultValue={getBooleanValue(is_vip)} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default IntegratorDetailsModel

