import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import AdminActionPermission from './../../Components/PermissionModal/AdminActionPermission';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { getAdminUsers } from '../../Redux/action/User';
import Pagination from '../../Components/Pagination';
import DateInputs from '../../Components/DatePicker';
import { Link, useLocation } from 'react-router-dom';
import { getFormatedDate, trimObjValues } from '../../Helper';
import TableFooter from '../../Components/TableFooter';
import NotFoundData from '../../Components/NotFoundData';
import MenuPermission from './MenuPermission';
import { SidebarContext } from '../../Context/SidebarContext';
import AssignClientsModal from './AssignClientsModal';
import moment from 'moment';

function AdminList(props) {
   const { setLoading, isLoading } = props
   const location = useLocation()
   const [adminUsers, setAdminUsers] = useState([])
   const [menuPermissionModal, setMenuPermissionModal] = useState(false)
   const [actionPermissionModal, setActionPermissionModal] = useState(false)
   const [assignClientsModal, setAssignClientsModal] = useState(false)
   const [userData, setUserData] = useState("")

   const {
      dispatch,
      totalCount,
      toast,
      sorting,
      resetForm,
      handleSubmit,
      handleSorting,
      setTotalCount,
      currentPage,
      setcurrentPage,
      itemsPerPage,
      maxPageNumberLimit,
      setmaxPageNumberLimit,
      minPageNumberLimit,
      setminPageNumberLimit,
      selectedDate,
      setSelectedDate
   } = useContext(SidebarContext)

   const [searchParams, setSearchParams] = useState({ type: "Admin", nickname: "", username: "", email: "", from_date: "", to_date: "" })

   useLayoutEffect(() => {
      if (location.state && location.state.currentPage) {
         setcurrentPage(location.state.currentPage)
      }
   }, [])

   useEffect(() => {
      getAdminUsersData();
   }, [currentPage, sorting])

   const getAdminUsersData = async () => {
      try {
         setLoading(true);
         const search_params = trimObjValues(searchParams);
         const payload = {
            search: search_params,
            shorting: sorting,
            pagination: { start: currentPage, length: itemsPerPage }
         };
         const response = await dispatch(getAdminUsers(payload));
         if (response.status === "1") {
            const { total_count, users } = response.data;
            setTotalCount(total_count);
            setAdminUsers(users);
         } else {
            setAdminUsers([]);
            setTotalCount(null);
         }
      } catch (error) {
         toast.error(error.response?.data?.message || 'An error occurred');
      } finally {
         setLoading(false);
      }
   };

   const handleSearch = (e) => {
      const { name, value } = e.target
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value,
      }));
   }

   const handleChangeDate = (date, name) => {
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: date ? moment(date).format("YYYY-MM-DD") : ''
      }))
      setSelectedDate((prevState) => ({
         ...prevState,
         [name]: date,
      }));
   }

   const handleReset = () => {
      const resetFormData = {
         type: "Admin",
         nickname: "",
         username: "",
         email: "",
         from_date: "",
         to_date: ""
      }
      setSearchParams(resetFormData)
      resetForm();
   }

   return (
      <>
         <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
               <div className="container-fluid">
                  <div className="row mb-2">
                     <div className="col-sm-6">
                        <h1 className="m-0">Admin List</h1>
                     </div>
                     <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item active">Admins</li>
                        </ol>
                     </div>
                  </div>
               </div>
            </div>
            <section className="content">
               <div className="container-fluid">
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="all_drivers">
                           <div className="card card-dark">
                              <div className="card-header">
                                 <div className="container-fluid">
                                    <div className="row">
                                       <div className="col-6 col-lg-11">
                                          <h3 className="pl-5">Search</h3>
                                       </div>
                                       <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                          <i className="fas fa-angle-up click_search"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <form className="form_search" action="">
                                 <div className="card-body">
                                    <div className="form-group">
                                       <label>User name</label>
                                       <input
                                          type="text"
                                          name="username"
                                          onChange={handleSearch}
                                          value={searchParams.username}
                                          className="form-control"
                                          placeholder="User name" />
                                    </div>
                                    <div className="form-group">
                                       <label>Email</label>
                                       <input
                                          type="mail"
                                          name="email"
                                          onChange={handleSearch}
                                          value={searchParams.email}
                                          className="form-control"
                                          placeholder="Email ID" />
                                    </div>
                                    <DateInputs selectedDate={selectedDate} handleChangeDate={handleChangeDate} />
                                 </div>
                                 <div className="card-footer text-center">
                                    <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                    <button type="button" onClick={() => handleSubmit(getAdminUsersData)} className="btn btn-success">Submit</button>
                                 </div>
                              </form>
                           </div>
                           <div className="row pagination_new">
                              <div className="col-lg-6 col-12">
                                 <Link to="/create-admin"> <button disabled={isLoading} type="add" className="btn btn-primary"><i className="fa-regular fa-circle-plus"></i>Create</button></Link>
                              </div>
                              {adminUsers?.length > 0 &&
                                 <div className="col-lg-6 col-12">
                                    <Pagination
                                       totalCount={totalCount}
                                       currentPage={currentPage}
                                       itemsPerPage={itemsPerPage}
                                       maxPageNumberLimit={maxPageNumberLimit}
                                       minPageNumberLimit={minPageNumberLimit}
                                       setcurrentPage={setcurrentPage}
                                       setmaxPageNumberLimit={setmaxPageNumberLimit}
                                       setminPageNumberLimit={setminPageNumberLimit}
                                    />
                                 </div>
                              }
                           </div>
                           {adminUsers?.length > 0 &&
                              <React.Fragment>
                                 <div className="card table_card completed_1">
                                    <div className="card-body all_drive_table">
                                       {adminUsers && adminUsers.length > 0 &&
                                          <table id="example2" className="table table-hover">
                                             <thead>
                                                <tr>
                                                   <th>Sr.No</th>
                                                   <th >User Name
                                                      {sorting.order_by === "username" ?
                                                         <i onClick={() => handleSorting("username")}
                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                         </i>
                                                         : <i onClick={() => handleSorting("username")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                      }
                                                   </th>
                                                   <th>Nick Name
                                                      {sorting.order_by === "nickname" ?
                                                         <i onClick={() => handleSorting("nickname")}
                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                         </i>
                                                         : <i onClick={() => handleSorting("nickname")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                      }
                                                   </th>
                                                   <th>Email ID
                                                      {sorting.order_by === "email" ?
                                                         <i onClick={() => handleSorting("email")}
                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                         </i>
                                                         : <i onClick={() => handleSorting("email")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                      }
                                                   </th>
                                                   <th>Phone</th>
                                                   <th> Created At
                                                      {sorting.order_by === "_created_at" ?
                                                         <i onClick={() => handleSorting("_created_at")}
                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                         </i>
                                                         : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                      }
                                                   </th>
                                                   <th>Action</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {adminUsers.map((item, index) =>
                                                (<tr key={item._id} >
                                                   <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                   <td>{item.username ? item.username : "Not Available"}</td>
                                                   <td>{item.nickname ? item.nickname : "Not Available"}</td>
                                                   <td>{item.email ? item.email : "Not Available"}</td>
                                                   <td>{item.phone ? item.phone : "Not Available"}</td>
                                                   <td>{getFormatedDate(item._created_at)}
                                                   </td>
                                                   <td>
                                                      <div className="action_button">
                                                         <div className="btn-group">
                                                            <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                               <img src={require("../../assets/dist/img/action.png")} alt='action-png' />
                                                            </button>
                                                            <div className="dropdown-menu">
                                                               <span className="dropdown-item"
                                                                  onClick={() => {
                                                                     setMenuPermissionModal(true)
                                                                     setUserData(item)
                                                                  }}
                                                               >
                                                                  <span>
                                                                     <i className="fa-solid fa-bars"></i>Menu Permission</span>
                                                               </span>

                                                               <div className="dropdown-divider"></div>
                                                               <span className="dropdown-item"
                                                                  onClick={() => {
                                                                     setActionPermissionModal(true)
                                                                     setUserData(item)
                                                                  }}
                                                               >
                                                                  <span>
                                                                     <i className="fa-solid fa-square-check"></i>Action Permission</span>
                                                               </span>

                                                               <div className="dropdown-divider"></div>
                                                               <span className="dropdown-item"
                                                                  onClick={() => {
                                                                     setAssignClientsModal(true)
                                                                     setUserData(item)
                                                                  }}
                                                               >
                                                                  <span>
                                                                     <i className="fa-solid fa-shop-lock"></i>Assign Clients</span>
                                                               </span>

                                                               <div className="dropdown-divider"></div>
                                                               <Link to={{ pathname: `/edit-admin/${item._id}`, state: { currentPage: currentPage } }} className="dropdown-item"><span className="blue_1">
                                                                  <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span></Link>
                                                               <div className="dropdown-divider"></div>
                                                               <span className="dropdown-item"><span className="red_1">
                                                                  <i className="fa fa-trash" aria-hidden="true"></i>Delete</span>
                                                               </span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </td>
                                                </tr>)
                                                )}
                                             </tbody>
                                          </table>}
                                    </div>
                                 </div>
                                 <div className="row pagination_new">
                                    <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={adminUsers} totalCount={totalCount} />
                                    <div className="col-lg-4">
                                       <Pagination
                                          totalCount={totalCount}
                                          currentPage={currentPage}
                                          itemsPerPage={itemsPerPage}
                                          maxPageNumberLimit={maxPageNumberLimit}
                                          minPageNumberLimit={minPageNumberLimit}
                                          setcurrentPage={setcurrentPage}
                                          setmaxPageNumberLimit={setmaxPageNumberLimit}
                                          setminPageNumberLimit={setminPageNumberLimit}
                                       />
                                    </div>
                                 </div>
                              </React.Fragment>
                           }
                           {adminUsers?.length === 0 && !isLoading && <NotFoundData />}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
         {menuPermissionModal &&
            <MenuPermission
               modal={menuPermissionModal}
               setLoading={setLoading}
               loader={isLoading}
               getListData={getAdminUsersData}
               userData={userData}
               closeModal={() => setMenuPermissionModal(false)} />
         }
         {actionPermissionModal &&
            <AdminActionPermission
               modal={actionPermissionModal}
               closeModal={() => setActionPermissionModal(false)}
               user={userData}
               getUserList={getAdminUsersData}
               setLoading={setLoading}
               isLoading={isLoading}
            />
         }

         {assignClientsModal &&
            <AssignClientsModal
               modal={assignClientsModal}
               closeModal={() => setAssignClientsModal(false)}
               user={userData}
               getUserList={getAdminUsersData}
               setLoading={setLoading}
               isLoading={isLoading}
            />
         }

      </>
   )
}

export default IsLoadingHOC(IsLoggedinHOC(AdminList))