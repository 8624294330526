import React, { useState, useEffect, useContext, useCallback } from 'react';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import Pagination from '../../Components/Pagination';
import { Link, useParams } from 'react-router-dom';
import { getFormatedDate } from '../../Helper';
import TableFooter from '../../Components/TableFooter';
import { SidebarContext } from "../../Context/SidebarContext";
import NotFoundData from '../../Components/NotFoundData';
import { getAggregatorLogs } from '../../Redux/action/User';
import { currentDate } from '../../utils/dateFormat';
import { getUserForSearchBar } from '../../Redux/action/User';
import { debounce } from 'lodash';
import { default as ReactSelect } from "react-select";
import { customStyles } from '../../Helper';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useSelector } from 'react-redux';



function AggregatorLogs(props) {
    const { isLoading, setLoading } = props
    const params = useParams();
    const [driverFetching, setDriverFetching] = useState(false)
    const [drivers, setDrivers] = useState([])
    const [selectedDriver, setSelectedDriver] = useState("")
    const [aggregatorLog, setAggregatorLog] = useState([])
    const { aggregatorName } = useSelector(state => state.app)
    const [searchParams, setSearchParams] = useState({ driver_id: "", from_date: "", to_date: "" })
    const {
        dispatch,
        totalCount,
        toast,
        sorting,
        selectedDate,
        setSelectedDate,
        setTotalCount,
        currentPage,
        resetForm,
        handleSubmit,
        setcurrentPage,
        itemsPerPage,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
    } = useContext(SidebarContext)


    useEffect(() => {
        getAggregatorLogData();
    }, [currentPage, sorting])


    // load options using API call with searched Drivers
    const getSearchedDrivers = async (searchDriver) => {
        const digitNumbers = /^-?[\+|\d.]+(?:e-?\d+)?$/
        let payload = {
            search: { _id: '', type: "Driver", nickname: '', username: "", phone: '' },
            shorting: { order_in: -1, order_by: "nickname" }
        }
        if (digitNumbers.test(searchDriver)) {
            let phoneNumber = searchDriver.toString();
            if (phoneNumber[0] === "+") {
                phoneNumber = phoneNumber.substring(1)
            }
            payload.search.phone = phoneNumber
        } else {
            payload.search.nickname = searchDriver
        }
        setDriverFetching(true)
        await dispatch(getUserForSearchBar(payload))
            .then(
                response => {
                    setDriverFetching(false)
                    if (response.status == "1") {
                        const resData = response.data.users
                        const alldrivers = []
                        resData.forEach(item => {
                            const { _id, nickname, username, phone } = item
                            const labelwithPhone = `${nickname ? nickname : username} (${phone})`
                            alldrivers.push({ label: labelwithPhone, value: _id })
                        })
                        setDrivers(alldrivers)
                    } else {
                        setDrivers([])
                    }
                },
                error => {
                    setDrivers([])
                    setDriverFetching(false)
                    toast.error(error.response.data?.message)
                }
            )
    };


    const getAggregatorLogData = async () => {
        try {
            setLoading(true);
            const payload = {
                aggregator_id: params.id,
                search: searchParams,
            };
            const response = await dispatch(getAggregatorLogs(payload));
            setLoading(false);
            if (response.status === "1") {
                const { logs } = response.data;
                setAggregatorLog(logs);
            } else {
                setAggregatorLog([]);
            }
        } catch (error) {
            setLoading(false);
            setAggregatorLog([]);
        }
    };

    const getDriversData = useCallback(debounce((searchvalue) => getSearchedDrivers(searchvalue), 500), []);
    const handleSearchDriver = (inputValue) => {
        const value = inputValue.trim()
        if (value && value.length > 0) {
            getDriversData(value);
        }
    }

    const handleChangeDate = (date, name) => {
        setSearchParams((prevState) => ({
          ...prevState,
          [name]: date ? moment(date).format("YYYY-MM-DD") : '',
        }));
        setSelectedDate((prevState) => ({
          ...prevState,
          [name]: date,
        }));
      }

    const handleReset = async () => {
        const resetFormData = { driver_id: "", from_date: "", to_date: "" }
        setSearchParams(resetFormData)
        setSelectedDriver("")
        resetForm();
    }

    const handleChangeDriver = (option) => {
        setSelectedDriver(option)
        setSearchParams(prevState => ({
            ...prevState,
            driver_id: option?.value || ""
        }))
    }

    return (
        <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <Link to="/aggregators">
                                <button className='btn btn-primary px-4'><i class="fa-regular fa-circle-arrow-left mr-1"></i>Back</button>
                            </Link>
                            <h1 className="mt-2">Aggregator Logs of {aggregatorName && `(${aggregatorName})`}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/aggregators">Aggregators</Link></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="all_drivers">
                                <div className="card card-dark">
                                    <div className="card-header">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-6 col-lg-11">
                                                    <h3 className="pl-5">Search</h3>
                                                </div>
                                                <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                    <i className="fas fa-angle-up click_search"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form className="form_search" action="">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label>Driver</label>
                                                <ReactSelect placeholder="Search"
                                                    isLoading={driverFetching}
                                                    styles={customStyles}
                                                    isClearable={true}
                                                    value={selectedDriver}
                                                    options={drivers}
                                                    onInputChange={handleSearchDriver}
                                                    onChange={handleChangeDriver}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Start Date</label>
                                                <DatePicker className="form-control date-field ub-l cursor-pointer" placeholderText="Start Date"
                                                    showIcon={selectedDate?.from_date ? false : true}
                                                    dateFormat='dd/MM/yyyy'
                                                    dropdownMode="select"
                                                    isClearable={true}
                                                    selected={selectedDate.from_date}
                                                    // maxDate={new Date()}
                                                    onChange={date => handleChangeDate(date, "from_date")}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    adjustDateOnChange
                                                    autocomplete={false}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>End Date</label>
                                                <DatePicker className="form-control date-field ub-l cursor-pointer" placeholderText="End Date"
                                                    showIcon={selectedDate?.to_date ? false : true}
                                                    dateFormat='dd/MM/yyyy'
                                                    dropdownMode="select"
                                                    isClearable={true}
                                                    selected={selectedDate.to_date}
                                                    // maxDate={new Date()}
                                                    minDate={selectedDate?.from_date}
                                                    onChange={date => handleChangeDate(date, "to_date")}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    adjustDateOnChange
                                                    autocomplete={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-footer text-center">
                                            <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                            <button onClick={() => handleSubmit(getAggregatorLogData)} type="button" className="btn btn-success">Submit</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="row pagination_new">
                                    <div className="col-lg-6">
                                    </div>
                                    {/* {aggregatorLog?.length > 0 &&
                                        <div className="col-lg-6 col-12">
                                            <Pagination
                                                totalCount={totalCount}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                maxPageNumberLimit={maxPageNumberLimit}
                                                minPageNumberLimit={minPageNumberLimit}
                                                setcurrentPage={setcurrentPage}
                                                setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                setminPageNumberLimit={setminPageNumberLimit}
                                            />
                                        </div>
                                    } */}
                                </div>
                                {aggregatorLog?.length > 0 &&
                                    <React.Fragment>
                                        <div className="card table_card completed_1">
                                            <div className="card-body all_drive_table">
                                                <table id="example2" className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No</th>
                                                            <th>Driver Name</th>
                                                            <th>Driver Phone</th>
                                                            <th>Complete Orders</th>
                                                            <th>Log Date </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {aggregatorLog.map((item, index) =>
                                                        (<tr key={item._id} >
                                                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                            <td>{item.driver[0]?.username}</td>
                                                            <td>{item.driver[0]?.phone}</td>
                                                            <td>{item.complete_orders}</td>
                                                            <td>{getFormatedDate(item.log_date)} </td>
                                                        </tr>)
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/* <div className="row pagination_new">
                                            <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={aggregatorLog} totalCount={totalCount} />
                                            <div className="col-lg-4">
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    maxPageNumberLimit={maxPageNumberLimit}
                                                    minPageNumberLimit={minPageNumberLimit}
                                                    setcurrentPage={setcurrentPage}
                                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                    setminPageNumberLimit={setminPageNumberLimit}
                                                />
                                            </div>
                                        </div> */}
                                    </React.Fragment>
                                }
                                {aggregatorLog?.length === 0 && !isLoading && <NotFoundData />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(AggregatorLogs))