import React, { useState, useEffect, useContext } from 'react';
import OrderDetailsModel from '../../Components/DetailsModel/OrderDetails';
import OrderNotesModel from '../../Components/DetailsModel/OrderNotes';
import ExportModel from '../../Components/ConfirmModel/ExportData';
import { getOrdersList } from '../../Redux/action/Orders';
import { useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import Pagination from '../../Components/Pagination';
import { Link, useParams, useHistory } from 'react-router-dom';
import SearchForm from '../../Components/SearchOrder';
import { completedHeader } from '../../utils/OrdersCSV';
import { CSVLink } from 'react-csv';
import { dateFormat, exportToExcel, getBuyerLoaction, handleCopyClipboard, trimObjValues } from '../../Helper';
import TableFooter from '../../Components/TableFooter';
import { setNotifyOrder, updateRelatedOrderId } from '../../Redux/action/App';
import ViewComments from "../../Components/DetailsModel/ViewComments";
import WhatsAppMsges from '../../Components/DetailsModel/WhatsAppMsges';
import { SidebarContext } from '../../Context/SidebarContext';
import { OrderContext } from '../../Context/OrderContext';
import OrderDocuments from '../../Components/DocumentsModal/OrderDocuments';

import {
   handleSelectListItem,
   isAllItemSelected,
   isIntegratorNumber,
   getDiffTime,
   showShopDirection,
   isShopOrderNumber,
   isOrderNotes,
   checkWhatsAppMsg
} from '../../Helper';
import NotFoundData from '../../Components/NotFoundData';
import moment from 'moment';



function CompletedOrders(props) {
   const { isLoading, setLoading } = props
   const history = useHistory()
   const params = useParams()
   const { user } = useSelector(state => state.auth)
   const { _id, type, allowed_order_export_column, drivers, allowed_action_permission } = user ? user : {}
   const { relatedOrder } = useSelector(state => state.app)
   const [completedOrders, setCompletedOrders] = useState([])
   const [selectedValue, setSelectedValue] = useState({ shopid: null, driverid: null });
   const [sorting, setSorting] = useState({ order_in: -1, order_by: "deliverydate" })
   const { branches_id } = useSelector(state => state.shop)
   const [selectedBranches, setSelectedBranches] = useState([])
   const { notifyOrder } = useSelector(state => state.app)
   const [uploadDocModal, setUploadDocModal] = useState(false)


   let exportsColumns = []
   if (type === "Admin") {
      exportsColumns = completedHeader
   }
   else {
      const filteredArray = completedHeader.filter(item => allowed_order_export_column?.includes(item.label))
      exportsColumns = filteredArray || []
   }

   const {
      orderDetailModal,
      setOrderDetailModal,
      orderNotesModal,
      setOrderNotesModal,
      viewCommentModal,
      setViewCommentModal,
      whatsAppMsgesModal,
      setWhatsAppMsgesModal
   } = useContext(OrderContext)

   const {
      csvLink,
      dispatch,
      totalCount,
      toast,
      setTotalCount,
      currentPage,
      setcurrentPage,
      handleSubmit,
      itemsPerPage,
      exportsData,
      setExportsData,
      selectedDate,
      setSelectedDate,
      selectedItem,
      resetForm,
      setSelectedItem,
      handleSelectItem,
      fileLoading,
      setFileLoading,
      orderDetails,
      setOrderDetail,
      orderId,
      setOrderId,
      exportModal,
      toggleExportModal,
      maxPageNumberLimit,
      setmaxPageNumberLimit,
      minPageNumberLimit,
      setminPageNumberLimit,
   } = useContext(SidebarContext)

   const [searchParams, setSearchParams] = useState({
      status: "completed",
      shopid: ["Admin", "Logistics"].includes(user.type) ? "" : branches_id,
      driverid: ["Logistics"].includes(user.type) ? drivers.join(',') : "",
      branch_ids: "",
      shopname: "",
      ordernumber: "",
      drivername: "",
      receiver_name: "",
      buyerphone: "",
      receiver_address: "",
      from_date: "",
      to_date: "",
   })

   useEffect(() => {
      if (notifyOrder?._id) {
         if (notifyOrder.status === 'completed') {
            setCompletedOrders((prevOrders) => [notifyOrder, ...prevOrders]);
         }
         dispatch(setNotifyOrder(""))
      }
   }, [notifyOrder]);

   useEffect(() => {
      getCompleteOrdersData();
      if (!params.id) {
         const payload = { buyerid: "", relatedBy: '', user_name: '' }
         dispatch(updateRelatedOrderId(payload))
      }
   }, [currentPage, sorting])

   const handleSelectShop = (option, name) => {
      let { value } = option ? option : {}
      if (value) {
         setSearchParams((prevState) => ({
            ...prevState,
            [name]: value,
         }));
      }
      else {
         if (user.type === "Admin") {
            setSearchParams((prevState) => ({
               ...prevState,
               [name]: "",
            }));
         }
         else {
            setSearchParams((prevState) => ({
               ...prevState,
               [name]: _id,
            }));
         }
      }
      setSelectedValue((prevState) => ({
         ...prevState,
         [name]: option,
      }));
   }

   const handleSelectDriver = (option, name) => {
      let { value } = option ? option : {}
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value || '',
      }));
      setSelectedValue((prevState) => ({
         ...prevState,
         [name]: option,
      }));
   }

   const handleSelectBranch = (options) => {
      const selectedValues = []
      options.map(item => selectedValues.push(item.value))
      setSearchParams((prevState) => ({
         ...prevState,
         branch_ids: selectedValues
      }));
      setSelectedBranches(options)
   }

   const handleSearch = (e) => {
      const { name, value } = e.target
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value,
      }));
   }

   const handleChangeDate = (date, name) => {
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: date ? moment(date).format("YYYY-MM-DD") : '',
      }));
      setSelectedDate((prevState) => ({
         ...prevState,
         [name]: date,
      }));
   }

   const getCompleteOrdersData = async () => {
      if (user.type === "Logistics" && (drivers?.length === 0)) {
         return;
      }
      const search_params = trimObjValues(searchParams)
      if (params.id) {
         let dummyobj = { userid: '', driverid: '', buyerid: '' }

         if (relatedOrder && relatedOrder.relatedBy === 'Driver') {
            dummyobj['driverid'] = relatedOrder.driverid
         }
         else if (relatedOrder && relatedOrder.relatedBy === 'Buyer') {
            dummyobj['buyerid'] = relatedOrder.buyerid
         }
         else if (relatedOrder && ['Integrator', 'Shop'].includes(relatedOrder.relatedBy)) {
            dummyobj['shopid'] = relatedOrder.shopid
         }
         else {
            dummyobj['userid'] = relatedOrder.userid
         }
         Object.assign(search_params, dummyobj);
      }
      const payload = {
         search: search_params,
         shorting: sorting,
         pagination: { start: currentPage, length: itemsPerPage }
      }
      setLoading(true)
      await dispatch(getOrdersList(payload))
         .then(
            response => {
               setLoading(false);
               if (response.status === "1") {
                  setCompletedOrders(response.data.orders)
                  setTotalCount(response.data.total_count)
               } else {
                  setCompletedOrders([])
                  setTotalCount(null)
               }
            },
            (error) => {
               setLoading(false);
               toast.error(error.response.data?.message)
            }
         )
         .catch(
            error => {
               console.log("Catch", error);
            }
         )
   }

   const getExportingData = async () => {
      if (user.type === "Logistics" && (!drivers || drivers.length === 0)) {
         return;
      }
      const search_params = trimObjValues(searchParams);
      const totalItem = [];
      const fetchOrders = async (pagination) => {
         const payload = {
            search: search_params,
            selected_items: selectedItem,
            shorting: sorting,
            pagination: pagination
         };

         try {
            setFileLoading(true);
            const response = await dispatch(getOrdersList(payload));
            setFileLoading(false);

            if (response.status === "1") {
               totalItem.push(...response.data.orders);
            }
         } catch (error) {
            setFileLoading(false);
            toast.error(error.response?.data?.message);
         }
      };

      if (selectedItem && selectedItem.length > 0) {
         await fetchOrders({ start: 1, length: 1000 });
      } else {
         const pages = Math.ceil(totalCount / 50000);
         for (let i = 1; i <= pages; i++) {
            await fetchOrders({ start: i, length: 50000 });
         }
      }

      // setExportsData(totalItem);

      const exportData = totalItem?.map((item) => {
         const exportItem = {};
         exportsColumns.forEach(header => {
            exportItem[header.label] = item[header.key];
         });
         return exportItem;
      });

      const fileName = `Completed-Orders-${moment(new Date()).format("hh-mm-ss")}.xlsx`
      exportToExcel(exportData, fileName)

      // csvLink.current.link.click();
      toast.success("Data exported successfully");
      toggleExportModal();
   };

   const handleReset = async () => {
      const resetFormData = {
         status: "completed",
         shopid: ["Admin", "Logistics"].includes(user.type) ? "" : branches_id,
         driverid: ["Logistics"].includes(user.type) ? drivers.join(',') : "",
         branch_ids: "",
         shopname: "",
         ordernumber: "",
         drivername: "",
         receiver_name: "",
         buyerphone: "",
         receiver_address: "",
         from_date: "",
         to_date: "",
      }
      setSearchParams(resetFormData)
      resetForm()
      setSelectedValue({ shopid: null, driverid: null })
      setSorting({ order_in: -1, order_by: "deliverydate" })
      setSelectedBranches([])
   }

   const handleSorting = (orderby) => {
      setSorting((prevState) => ({
         ...prevState,
         order_by: orderby,
         order_in: prevState.order_in === -1 ? 1 : -1
      }));
   }

   const handleUploadDocModal = (item) => {
      setUploadDocModal(true)
      setOrderDetail(item)
   }

   const checkAllowedAction = (ActionType) => {
      if (allowed_action_permission?.length > 0) {
         const isAllowed = allowed_action_permission?.includes(ActionType)
         return isAllowed
      }
      else {
         return false
      }
   }

   return (
      <>
         <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
               <div className="container-fluid">
                  <div className="row mb-2">
                     <div className="col-sm-6">
                        <h1 className="m-0">Completed Orders {params.id && relatedOrder?.user_name ? `with ${relatedOrder?.user_name}` : ''}</h1>
                     </div>
                     <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item active">Orders</li>
                        </ol>
                     </div>
                  </div>
               </div>
            </div>
            <section className="content">
               <div className="container-fluid">
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="all_drivers">
                           <div className="card card-dark">
                              <div className="card-header">
                                 <div className="container-fluid">
                                    <div className="row">
                                       <div className="col-6 col-lg-11">
                                          <h3 className="pl-5">Search</h3>
                                       </div>
                                       <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                          <i className="fas fa-angle-up click_search"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <form className="form_search" action="">
                                 <SearchForm
                                    searchParams={searchParams}
                                    selectedValue={selectedValue}
                                    selectedDate={selectedDate}
                                    handleSearch={handleSearch}
                                    handleSelectShop={handleSelectShop}
                                    handleSelectDriver={handleSelectDriver}
                                    handleChangeDate={handleChangeDate}
                                    handleSelectBranch={handleSelectBranch}
                                    selectedBranches={selectedBranches}
                                 />
                                 <div className="card-footer text-center">
                                    <button type="reset" className="btn btn-primary mr-2" onClick={handleReset} >Reset</button>
                                    <button type="button" onClick={() => handleSubmit(getCompleteOrdersData)} className="btn btn-success">Submit</button>
                                 </div>
                              </form>
                           </div>
                           <div className="row pagination_new" >
                              <div className="col-lg-6 col-12">
                                 {params.id && <button disabled={isLoading} type="button" onClick={() => history.goBack()} className="btn btn-primary mr-2"><i className="fa-solid fa-circle-arrow-left"></i>Back</button>}
                                 <button disabled={isLoading} type="button" onClick={() => handleSubmit(getCompleteOrdersData)} className="btn btn-secondary"><i className={`fa-regular fa-rotate-right ${isLoading && 'fa_spinner'}`}></i>Refresh</button>
                                 <button type='button' disabled={fileLoading || !totalCount || isLoading} onClick={toggleExportModal} className="btn btn-info ml-2"><i className="fa-regular fa-circle-down"></i>{fileLoading ? "Loading.." : "Export"}</button>
                                 {/* {type === 'Admin' ?
                                    <CSVLink className='csv-link' target='_blank' ref={csvLink}
                                       headers={completedHeader}
                                       data={exportsData}
                                       filename={`Completed_Orders_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss")}`}
                                    /> :
                                    <CSVLink className='csv-link' target='_blank' ref={csvLink}
                                       headers={allowedExportColumn && allowedExportColumn.length > 0 ? allowedExportColumn : completedHeader}
                                       data={exportsData}
                                       filename={`Completed_Orders_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss")}`}
                                    />
                                 } */}
                              </div>
                              {completedOrders?.length > 0 &&
                                 <div className="col-lg-6 col-12">
                                    <Pagination
                                       totalCount={totalCount}
                                       currentPage={currentPage}
                                       itemsPerPage={itemsPerPage}
                                       maxPageNumberLimit={maxPageNumberLimit}
                                       minPageNumberLimit={minPageNumberLimit}
                                       setcurrentPage={setcurrentPage}
                                       setmaxPageNumberLimit={setmaxPageNumberLimit}
                                       setminPageNumberLimit={setminPageNumberLimit}
                                    />
                                 </div>
                              }
                           </div>
                           {completedOrders?.length > 0 &&
                              <React.Fragment>
                                 <div className="card table_card completed_1">
                                    <div className="card-body all_drive_table">
                                       <table id="example2" className="table table-hover">
                                          <thead>
                                             <tr>
                                                <th><input type="checkbox" id="check" checked={isAllItemSelected(completedOrders, selectedItem)} onChange={(event) => handleSelectListItem(event, completedOrders, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th>
                                                <th>More info</th>
                                                <th>Order</th>
                                                <th>Shop</th>
                                                <th>Buyer</th>
                                                <th>Driver</th>
                                                <th>Delivered At
                                                   {sorting.order_by === "deliverydate" ?
                                                      <i onClick={() => handleSorting("deliverydate")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("deliverydate")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Created At
                                                   {sorting.order_by === "_created_at" ?
                                                      <i onClick={() => handleSorting("_created_at")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Action</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {completedOrders.map((item, i) => (
                                                <tr key={item._id} className="accordion-toggle" style={{ backgroundColor: item?.location_shared === true && '#e8eddf' }}>
                                                   <td>
                                                      <input type="checkbox" className="table_checkbox"
                                                         onChange={(event) => handleSelectItem(event, item._id)}
                                                         checked={selectedItem.includes(item._id)} />
                                                   </td>
                                                   <td>
                                                      <div title='Order-Info'
                                                         onClick={() => {
                                                            setOrderId(item._id)
                                                            setOrderDetailModal(true)
                                                         }} >
                                                         <i className="fa-solid fa-circle-info fa-lg"></i></div>
                                                      {
                                                         item.have_operation_comments &&
                                                         <div title='View-comments'
                                                            onClick={() => {
                                                               setOrderId(item._id)
                                                               setOrderDetail(item)
                                                               setViewCommentModal(true)
                                                            }}
                                                         ><i className="fa-solid fa-comment fa-lg"></i>
                                                         </div>
                                                      }
                                                      {
                                                         isOrderNotes(item.order_notes) &&
                                                         <div title='show notes order'
                                                            onClick={() => {
                                                               setOrderDetail(item)
                                                               setOrderNotesModal(true)
                                                            }}>
                                                            <i className="fa-solid fa-note fa-lg"></i>
                                                         </div>
                                                      }
                                                      {
                                                         checkWhatsAppMsg(item.order_history) &&
                                                         <div title='View-message'
                                                            onClick={() => {
                                                               setOrderId(item._id)
                                                               setOrderDetail(item)
                                                               setWhatsAppMsgesModal(true)
                                                            }}>
                                                            <i className="fa-brands fa-whatsapp text-success fa-lg"></i>
                                                         </div>
                                                      }
                                                   </td>
                                                   <td>
                                                      <div onClick={() => handleCopyClipboard(item._id)} className='cursor-pointer' title='Order-number'>
                                                         {item.requested_vehicle_type === "bike" && <i className="fa-solid fa-motorcycle"></i>}
                                                         {item.requested_vehicle_type === "car" && <i className="fa-solid fa-car"></i>}
                                                         {item.requested_vehicle_type === "truck" && <i className="fa-sharp fa-solid fa-truck"></i>}
                                                         {item.requested_vehicle_type === "van" && <i className="fa-solid fa-van-shuttle"></i>}
                                                         {item.requested_vehicle_type === "all" && <i className="fa-sharp fa-solid fa-truck"></i>}
                                                         {item.ordernumber}
                                                      </div>
                                                      {isIntegratorNumber(item?.integrator_number)}
                                                      {isShopOrderNumber(item?.shop_ordernumber)}
                                                      <div title="Delivered-Time"><i className="fa-solid fa-clock"></i>{getDiffTime(item.deliverydate, item._created_at)}</div>
                                                      <div className='text-success' role="button" title='Order-Price'><i className="fa-solid fa-coins"></i>{item?.orderprice}</div>
                                                      <div className='text-capitalize text-secondary' role="button" title='Payment-Method'><i className="fa-duotone fa-credit-card"></i>{item?.paymentmethod}</div>
                                                   </td>
                                                   <td>
                                                      <div title={item.shopname}><i className="fa-solid fa-store"></i>{item.shopname}</div>
                                                      {item?.senderphone && <div title={item.senderphone}><i className="fa fa-phone" aria-hidden="true"></i>{item?.senderphone}</div>}
                                                      <div className='text-capitalize'><i className="fa-solid fa-diamond-turn-right"></i>{showShopDirection(item)}</div>
                                                   </td>
                                                   <td>
                                                      <div title={item.receiver_name}><i className="fa fa-user" aria-hidden="true"></i>{item.receiver_name}</div>
                                                      <div> <i className="fa fa-phone" aria-hidden="true"></i>{item.buyerphone ? item.buyerphone : "Not Available"} </div>
                                                      <div title={getBuyerLoaction(item, 'unlimited')}>
                                                         <i className="fa-solid fa-location-dot"></i>
                                                         {getBuyerLoaction(item, 'limited')}
                                                      </div>
                                                   </td>
                                                   <td>
                                                      <div title={item.drivername}><i className="fa fa-user" aria-hidden="true"></i>{item.drivername}</div>
                                                      <div> <i className="fa fa-phone" aria-hidden="true"></i>{item.driverphone ? item.driverphone : "Not Available"}</div>
                                                      {item.driver?.length > 0 && item.driver[0]?.job_directions && item.driver[0]?.job_directions.length > 0 && <div>
                                                         <span className='text-capitalize'>
                                                            <i className="fa-solid fa-diamond-turn-right"></i>
                                                            {item.driver[0]?.job_directions?.join(' , ')}
                                                         </span>
                                                      </div>
                                                      }
                                                   </td>
                                                   <td>
                                                      <div>
                                                         {dateFormat(item.deliverydate)}
                                                      </div>
                                                      {(item.hasOwnProperty('delivery_vendor') && item?.delivery_vendor?.order_id) &&
                                                         <div>
                                                            <span title='Delivery Provider' className='font-weight-bold'> {item?.delivery_vendor?.provider} </span>
                                                         </div>
                                                      }
                                                   </td>

                                                   <td>
                                                      <div>
                                                         {dateFormat(item._created_at)}
                                                      </div>
                                                      {item?.delivery_type && item?.delivery_type?.title &&
                                                         <div>
                                                            <span className='text-bold'>{item?.delivery_type?.title}</span>
                                                         </div>
                                                      }
                                                      {item?.order_documents && item?.order_documents?.length > 0 &&
                                                         <div>
                                                            <span title='Download Order Documents'>
                                                               <a href={item?.order_documents[0]?.media}>
                                                                  <i class="fa-solid fa-folder-arrow-down fa-lg"></i>Attachment
                                                               </a>
                                                            </span>
                                                         </div>
                                                      }
                                                   </td>
                                                   <td>
                                                      <div className="action_button">
                                                         <div className="btn-group">
                                                            <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                               <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                            </button>
                                                            <div className="dropdown-menu">
                                                               {item.senderlocation && item.senderlocation.length > 0 &&
                                                                  <>
                                                                     < a href={`https://www.google.com/maps/search/?api=1&query=${item.senderlocation[0]},${item.senderlocation[1]}`} rel="noreferrer" target="_blank" className="dropdown-item">
                                                                        <span>
                                                                           <i className="fa-solid fa-location-dot"></i>Track shop
                                                                        </span>
                                                                     </a>
                                                                     <div className="dropdown-divider"></div>
                                                                  </>
                                                               }
                                                               {item.buyercurrlocation && item.buyercurrlocation.length > 0 &&
                                                                  <>
                                                                     < a href={`https://www.google.com/maps/search/?api=1&query=${item.buyercurrlocation[0]},${item.buyercurrlocation[1]}`} rel="noreferrer" target="_blank" className="dropdown-item">
                                                                        <span>
                                                                           <i className="fa-solid fa-location-dot"></i>Track buyer
                                                                        </span>
                                                                     </a>
                                                                  </>
                                                               }
                                                               {!item?.delivery_vendor && (type === "Admin" || checkAllowedAction("Upload-Document")) && (
                                                                  <>
                                                                     <div className="dropdown-divider"></div>
                                                                     <span onClick={() => handleUploadDocModal(item)} className="dropdown-item">
                                                                        <span>
                                                                           <i className="fa-solid fa-file"></i>
                                                                           View Documents
                                                                        </span>
                                                                     </span>
                                                                  </>
                                                               )}
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </td>
                                                </tr>)
                                             )}
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                                 <div className="row pagination_new">
                                    <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={completedOrders} totalCount={totalCount} />
                                    <div className="col-lg-4">
                                       <Pagination
                                          totalCount={totalCount}
                                          currentPage={currentPage}
                                          itemsPerPage={itemsPerPage}
                                          maxPageNumberLimit={maxPageNumberLimit}
                                          minPageNumberLimit={minPageNumberLimit}
                                          setcurrentPage={setcurrentPage}
                                          setmaxPageNumberLimit={setmaxPageNumberLimit}
                                          setminPageNumberLimit={setminPageNumberLimit}
                                       />
                                    </div>
                                 </div>
                              </React.Fragment>
                           }
                           {completedOrders?.length === 0 && !isLoading && <NotFoundData />}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
         {exportModal && <ExportModel
            loader={fileLoading}
            exportModal={exportModal}
            toggleExportModal={toggleExportModal}
            getExportingData={getExportingData}
            selectedItem={selectedItem}
            totalCount={totalCount}
         />}
         {orderDetailModal && <OrderDetailsModel
            order_id={orderId}
            setLoading={setLoading}
            isLoading={isLoading}
            modal={orderDetailModal}
            closeModal={() => setOrderDetailModal(false)}
         />}
         {orderNotesModal && <OrderNotesModel
            orderDetails={orderDetails}
            setLoading={setLoading}
            isLoading={isLoading}
            modal={orderNotesModal}
            closeModal={() => setOrderNotesModal(false)}
         />}
         {viewCommentModal && <ViewComments
            orderDetails={orderDetails}
            modal={viewCommentModal}
            closeModal={() => setViewCommentModal(false)}
            isLoading={isLoading}
            setLoading={setLoading}
            orderId={orderId}
         />}
         {whatsAppMsgesModal &&
            < WhatsAppMsges
               orderDetails={orderDetails}
               modal={whatsAppMsgesModal}
               closeModal={() => setWhatsAppMsgesModal(false)}
            />
         }

         {uploadDocModal &&
            < OrderDocuments
               isLoading={isLoading}
               orderDetails={orderDetails}
               modal={uploadDocModal}
               setLoading={setLoading}
               closeModal={() => setUploadDocModal(false)}
            />
         }

      </>
   )
}
export default IsLoadingHOC(IsLoggedinHOC(CompletedOrders))