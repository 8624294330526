import React from 'react';

function DeleteConfirmation(props) {
    const { loader, modal, closeModal, handleDelete, title } = props

    return (
        <div id="myModal" className={`modal fade cs--modal ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}>
            <div className="modal-dialog modal-confirm">
                <div className="modal-content">
                    <div className="modal-header flex-column">
                        <h4 className="modal-title w-100 text-center">Delete confirmation</h4>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div className="modal-body">
                        <h5 className='text-secondary' style={{ fontSize: '21px' }} >{title || "Do you want to make the changes ?"}</h5>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" onClick={closeModal} className="btn btn-secondary" data-dismiss="modal">No, Keep it</button>
                        <button type="button" onClick={handleDelete} className="btn btn-danger">{loader ? "Processing" : "YES, Delete it"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DeleteConfirmation