import AdminSidebar from "./Sidebar/AdminSidebar";
import UserSidebar from "./Sidebar/UserSidebar";
import TopNavBar from './Navbar/index';
import Footer from "./Footer";
import { useSelector } from "react-redux";


const PrivateLayout = ({ children }) => {
    const { user } = useSelector(state => state.auth)

    return (
        <div className="wrapper">
            <TopNavBar />
            {user?.type === "Admin" ? <AdminSidebar /> : <UserSidebar />}
            {children}
            <Footer />
        </div>
    )
}

export default PrivateLayout;
