import React, { useState, useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { default as ReactSelect } from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { getUsersList, getUserForSearchBar } from '../../Redux/action/User';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { customStyles } from '../../Helper';
import { getShopBranches } from '../../Redux/action/Shop';


function SearchForm(props) {
    const dispatch = useDispatch();
    const loaction = useLocation();
    const pathname = loaction.pathname
    const [driverFetching, setDriverFetching] = useState(false)
    const [shopFetching, setShopFetching] = useState(false)
    const { globalData } = useSelector(state => state.app)
    const { cancel_reasons } = globalData ? globalData : []
    const [cancelReasons, setCancelReasons] = useState([])
    const { user } = useSelector(state => state.auth)
    const { _id } = user ? user : {}
    const [drivers, setDrivers] = useState([])
    const [shops, setShops] = useState([])
    const [shopBranches, setShopBranches] = useState([])
    const { branches_id } = useSelector(state => state.shop)
    const { relatedOrder } = useSelector(state => state.app)
    const { searchParams, handleSearch, handleSelectShop, handleSelectDriver, handleChangeDate, selectedValue, selectedCancelReason, handleSelectCancelReason, selectedDate, handleSelectBranch, selectedBranches } = props

    useEffect(() => {
        if (cancel_reasons && cancel_reasons.length > 0) {
            let canceledReasons = cancel_reasons.map(item => ({ label: item, value: item }))
            canceledReasons.push({ label: 'Other', value: 'Other' })
            setCancelReasons(canceledReasons)
        }
    }, [])

    useEffect(() => {
        if (user.type === "Shop") {
            getShopBranchesData(_id)
        }
    }, [])

    const getShopBranchesData = async (headBranchId) => {
        const payload = {
            search: { user_id: headBranchId ? headBranchId : '' },
            shorting: { order_in: -1, order_by: "_created_at" }
        }
        await dispatch(getShopBranches(payload))
            .then(
                response => {
                    if (response.status === "1") {
                        const resData = response.data.branches
                        if (resData && resData.length > 0) {
                            const branches = []
                            resData.forEach(item => {
                                branches.push({ value: item.branch_shop_id, label: item.nickname })
                            })
                            setShopBranches(branches)
                        }
                    } else {
                        setShopBranches([])
                    }
                }, error => {
                    toast.error(error.response.data?.message)
                }
            )
    }

    // load options using API call with searched Drivers
    const getSearchedDrivers = async (searchDriver) => {
        const digitNumbers = /^-?[\+|\d.]+(?:e-?\d+)?$/
        let payload = {
            search: { _id: user.type === "Logistics" ? user.drivers.join(",") : '', type: "Driver", nickname: '', username: "", phone: '' },
            shorting: { order_in: -1, order_by: "nickname" }
        }
        if (digitNumbers.test(searchDriver)) {
            let phoneNumber = searchDriver.toString();
            if (phoneNumber[0] === "+") {
                phoneNumber = phoneNumber.substring(1)
            }
            payload.search.phone = phoneNumber
        } else {
            payload.search.nickname = searchDriver
        }
        setDriverFetching(true)
        await dispatch(getUsersList(payload))
            .then(
                response => {
                    setDriverFetching(false)
                    if (response.status == "1") {
                        const resData = response.data.users
                        const alldrivers = []
                        resData.forEach(item => {
                            const { _id, nickname, username, phone } = item
                            const labelwithPhone = `${nickname ? nickname : username} (${phone})`
                            alldrivers.push({ label: labelwithPhone, value: _id })
                        })
                        setDrivers(alldrivers)
                    } else {
                        setDrivers([])
                    }
                },
                error => {
                    setDrivers([])
                    setDriverFetching(false)
                    toast.error(error.response.data?.message)
                }
            )
    };

    // loaded options using API call with searched Shops
    const getSearchedShops = async (searchvalue) => {
        const payload = {
            search: { type: "Shop,Integrator,ShopVendor", nickname: searchvalue, username: "" },
            shorting: { order_in: 1, order_by: "nickname" }
        }
        setShopFetching(true)
        await dispatch(getUsersList(payload))
            .then(
                response => {
                    setShopFetching(false)
                    if (response.status === "1") {
                        const resData = response.data.users
                        const allShops = []
                        resData.forEach(item => {
                            const { _id, nickname, is_branch } = item
                            allShops.push({ label: nickname, value: _id, is_branch })
                        })
                        setShops(allShops)
                    } else {
                        setShops([])
                    }
                },
                error => {
                    setShops([])
                    setShopFetching(false)
                    toast.error(error.response.data?.message)
                }
            )
    };

    const getDriversData = useCallback(debounce((searchvalue) => getSearchedDrivers(searchvalue), 500), []);
    const handleSearchDriver = (inputValue) => {
        const value = inputValue.trim()
        if (value && value.length > 0) {
            getDriversData(value);
        }
    }

    const getShopsData = useCallback(debounce((searchvalue) => getSearchedShops(searchvalue), 500), []);
    const handleSearchShop = (inputValue) => {
        const value = inputValue.trim()
        if (value && value.length > 0) {
            getShopsData(value);
        }
    }

    const handleChangeShop = (option) => {
        if (option?.is_branch === false) {
            getShopBranchesData(option.value)
        }
        else {
            setShopBranches([])
        }
        handleSelectShop(option, "shopid")
        if (!option) {
            setShops([])
        }
    }

    const handleChangeDriver = (option) => {
        handleSelectDriver(option, "driverid")
        if (!option) {
            setDrivers([])
        }
    }

    const handleChangeBranch = (options) => {
        handleSelectBranch(options)
    }

    return (
        <>
            <div className="card-body">
                <div className="form-group">
                    <label>Reference Number</label>
                    <input className="form-control" type="text" placeholder="order/shop/integrator"
                        name="ordernumber"
                        onChange={handleSearch}
                        value={searchParams.ordernumber}
                    />
                </div>
                {pathname === '/canceled-orders' && <div className="form-group">
                    <label>Cancellation’s Reason</label>
                    <ReactSelect placeholder="Select cancel reason"
                        styles={customStyles}
                        isClearable={true}
                        value={selectedCancelReason}
                        options={cancelReasons}
                        onChange={(option) => handleSelectCancelReason(option, "order_notes.message")}
                    />
                </div>}
                {pathname === '/open-orders' && <div className="form-group">
                    <label>Order Status</label>
                    <select className="form-control" name="status" value={searchParams.status} onChange={handleSearch}>
                        <option value="created,arrived,pending pickup,picked">All</option>
                        <option value="created">Created</option>
                        <option value="arrived">Arrived to Shop</option>
                        <option value="pending pickup">Pending Pickup</option>
                        <option value="picked">Picked</option>
                    </select>
                </div>}
                {pathname === '/all-orders' && <div className="form-group">
                    <label>Order Status</label>
                    <select className="form-control" name="status" value={searchParams.status} onChange={handleSearch}>
                        <option value="created,arrived,pending pickup,picked,hold,completed,canceled">All</option>
                        <option value="created">Created</option>
                        <option value="arrived">Arrived to Shop</option>
                        <option value="pending pickup">Pending Pickup</option>
                        <option value="picked">Picked</option>
                        <option value="hold">On Hold</option>
                        <option value="completed">Completed</option>
                        <option value="canceled">Canceled</option>
                    </select>
                </div>}
                {user.type === "Admin" &&
                    <div className="form-group">
                        <label>Shop</label>
                        <ReactSelect placeholder="Search shop"
                            isLoading={shopFetching}
                            styles={customStyles}
                            isClearable={true}
                            isDisabled={relatedOrder?.relatedBy === 'Shop'}
                            value={selectedValue.shopid}
                            options={shops}
                            onInputChange={handleSearchShop}
                            onChange={handleChangeShop}
                        />
                    </div>}
                {shopBranches && shopBranches.length > 0 &&
                    <div className="form-group">
                        <label>Branches</label>
                        <ReactSelect placeholder="Select branch"
                            styles={customStyles}
                            isClearable={true}
                            isMulti={true}
                            value={selectedBranches}
                            options={shopBranches}
                            onChange={handleChangeBranch}
                        />
                    </div>
                }
                <div className="form-group">
                    <label>Driver</label>
                    <ReactSelect placeholder="Search"
                        isLoading={driverFetching}
                        styles={customStyles}
                        isClearable={true}
                        isDisabled={relatedOrder?.relatedBy === 'Driver'}
                        value={selectedValue.driverid}
                        options={drivers}
                        onInputChange={handleSearchDriver}
                        onChange={handleChangeDriver}
                    />
                </div>
                <div className="form-group">
                    <label>Buyer name</label>
                    <input type="text" className="form-control" placeholder="Name"
                        name="receiver_name"
                        onChange={handleSearch}
                        disabled={relatedOrder?.relatedBy === 'Buyer'}
                        value={searchParams.receiver_name}
                    />
                </div>
                <div className="form-group">
                    <label>Buyer Phone</label>
                    <input type="number" className="form-control" placeholder="Phone"
                        name="buyerphone"
                        onChange={handleSearch}
                        disabled={relatedOrder?.relatedBy === 'Buyer'}
                        value={searchParams.buyerphone
                        }
                    />
                </div>
                {/* <div className="form-group">
                    <label>Destination</label>
                    <input type="text" className="form-control" placeholder="Destination"
                        name="receiver_address"
                        onChange={handleSearch}
                        value={searchParams.receiver_address}
                    />
                </div> */}
                <div className="form-group">
                    <label>Start Date</label>
                    <DatePicker className="form-control date-field ub-l cursor-pointer" placeholderText="Start Date"
                        showIcon={selectedDate?.from_date ? false : true}
                        dateFormat='dd/MM/yyyy'
                        dropdownMode="select"
                        isClearable={true}
                        selected={selectedDate.from_date}
                        maxDate={new Date()}
                        onChange={date => handleChangeDate(date, "from_date")}
                        showYearDropdown
                        showMonthDropdown
                        adjustDateOnChange
                        autocomplete={false}
                    />
                </div>
                <div className="form-group">
                    <label>End Date</label>
                    <DatePicker className="form-control date-field ub-l cursor-pointer" placeholderText="End Date"
                        showIcon={selectedDate?.to_date ? false : true}
                        dateFormat='dd/MM/yyyy'
                        dropdownMode="select"
                        isClearable={true}
                        selected={selectedDate.to_date}
                        maxDate={new Date()}
                        minDate={selectedDate?.from_date}
                        onChange={date => handleChangeDate(date, "to_date")}
                        showYearDropdown
                        showMonthDropdown
                        adjustDateOnChange
                        autocomplete={false}
                    />
                </div>
            </div>
        </>
    )
}
export default SearchForm