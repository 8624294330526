import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoriteLocationDetail, updateFavoriteLocation } from '../../Redux/action/Favorite';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { countryCode } from '../../Config/constants';

function EditFavLocation(props) {
    const { modal, closeModal, getFavoriteLocationData, favLocation_id, isLoading, setLoading } = props
    const dispatch = useDispatch()
    const { buyer } = useSelector(stete => stete.app)
    const { buyer_id } = buyer ? buyer : {}

    useEffect(() => {
        getfavLocDetailsData()
    }, [])

    const getfavLocDetailsData = async () => {
        const payload = {
            is_detail: "true",
            search: { _id: favLocation_id },
            shorting: { order_in: -1, order_by: "_created_at" }
        }
        setLoading(true)
        await dispatch(getFavoriteLocationDetail(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const result = response.data.favorite_location
                        formik.setFieldValue('location_title', result.locationtitle)
                        formik.setFieldValue('location_area', result.locationarea)
                        formik.setFieldValue('location_description', result.locationdescription)
                        formik.setFieldValue('location_title', result.locationtitle)
                        if (result.block) {
                            formik.setFieldValue('block', result.block)
                        }
                        if (result.street) {
                            formik.setFieldValue('street', result.street)
                        }
                        if (result.building) {
                            formik.setFieldValue('building', result.building)
                        }
                        if (result.floor) {
                            formik.setFieldValue('floor', result.floor)
                        }
                        if (result.apartment) {
                            formik.setFieldValue('apartment', result.apartment)
                        }
                        if (result.geolocation && result.geolocation.length > 0) {
                            formik.setFieldValue('latitude', result.geolocation[0])
                            formik.setFieldValue('longitude', result.geolocation[1])
                        }
                    }
                }).catch(error => console.log(error))
    }

    const formik = useFormik({
        initialValues: {
            location_title: '',
            location_area: '',
            latitude: '',
            longitude: '',
            location_description: '',
            street: '',
            building: '',
            floor: '',
            apartment: '',
            block: ''
        },

        validationSchema: Yup.object({
            location_title: Yup.string()
                .required("This fiels is required")
                .min(3, 'This field must be at least 3 characters'),

            location_area: Yup.string()
                .required("This field is required")
                .min(3, 'This field must be at least 3 characters'),

            latitude: Yup.number()
                .typeError('You must specify a number')
                .required("This field is required"),

            longitude: Yup.number()
                .typeError('You must specify a number')
                .required("This field is required"),

            location_description: Yup.string()
                .required("This field is required")
                .min(5, 'This field must be at least 5 characters'),

        }),
        onSubmit: async values => {
            let fieldValues = values
            let locationMap = [fieldValues.latitude, fieldValues.longitude]
            Object.assign(fieldValues, {
                geolocation: locationMap,
                favorite_location_id: favLocation_id,
            });
            setLoading(true)
            await dispatch(updateFavoriteLocation(fieldValues))
                .then(
                    async response => {
                        setLoading(false)
                        if (response.status === '1') {
                            toast.success(response.data.message)

                            formik.resetForm();
                            closeModal();
                            await getFavoriteLocationData(buyer_id, favLocation_id);
                        } else {
                            toast.error(response.data.error[0])
                        }
                    })
                .catch(err => setLoading(false))
        },
    });

    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Edit Favorite Location</h5>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={formik.handleSubmit} >
                        <div className="modal-body p-3">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group required">
                                        <label className="control-label">Title</label>
                                        <input
                                            name='location_title'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.location_title}
                                            type="text" className="form-control" />
                                        {formik.touched.location_title && formik.errors.location_title ? (<span className="text-danger">{formik.errors.location_title}</span>) : null}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group required">
                                        <label className="control-label">Area</label>
                                        <input
                                            name='location_area'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.location_area}
                                            type="text" className="form-control" />
                                        {formik.touched.location_area && formik.errors.location_area ? (<span className="text-danger">{formik.errors.location_area}</span>) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group required">
                                        <label className="control-label">Latitude</label>
                                        <input
                                            name='latitude'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.latitude}
                                            type="number" className="form-control" />
                                        {formik.touched.latitude && formik.errors.latitude ? (<span className="text-danger">{formik.errors.latitude}</span>) : null}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group required">
                                        <label className="control-label">Longitude</label>
                                        <input
                                            name='longitude'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.longitude}
                                            type="number" className="form-control" />
                                        {formik.touched.longitude && formik.errors.longitude ? (<span className="text-danger">{formik.errors.longitude}</span>) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">Location Description</label>
                                        <textarea
                                            type='text'
                                            name='location_description'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.location_description}
                                            className="form-control" rows="2"></textarea>
                                        {formik.touched.location_description && formik.errors.location_description ? (<span className="text-danger">{formik.errors.location_description}</span>) : null}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                {countryCode === '+965' &&
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Block</label>
                                            <input
                                                name='block'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.block}
                                                type="text" className="form-control" />
                                        </div>
                                    </div>
                                }
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Street</label>
                                        <input
                                            name='street'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.street}
                                            type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Building</label>
                                        <input
                                            name='building'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.building}
                                            type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Apartment</label>
                                        <input
                                            name='apartment'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.apartment}
                                            type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Floor</label>
                                        <input
                                            name='floor'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.floor}
                                            type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-savbtn">
                            <button type="button" onClick={closeModal} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button disabled={isLoading} type="submit" className="btn btn-success">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default EditFavLocation