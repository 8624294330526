import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { default as ReactSelect } from "react-select";
import { getShopsToAddNewBranch, addNewShopInHeadBranch } from '../../Redux/action/Shop';
import { toast } from 'react-toastify';
import { selectOptionStyle } from '../../Helper';



function AddNewBranchModal(props) {
    const dispatch = useDispatch()
    const { modal, closeModal, parent_shop_id, headBranchName, getShopBranchesData } = props
    const [selecteShop, setSelectedShop] = useState("");
    const [shops, setShops] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (parent_shop_id) {
            getShopsToAddNewBranchData()
        }
    }, [parent_shop_id])

    const formik = useFormik({
        initialValues: { parent_shop_id, branches: [] },
        validationSchema: Yup.object({
            branches: Yup.array()
                .required('This field is required')
                .min(1, 'This field is required')
        }),
        onSubmit: async values => {
            const fieldValues = values
            setLoading(true)
            await dispatch(addNewShopInHeadBranch(fieldValues))
                .then(
                    async response => {
                        setLoading(false)
                        if (response.status == '1') {
                            toast.success(response.data.message)
                            formik.resetForm();
                            closeModal()
                            await getShopBranchesData();
                        } else {
                            toast.error(response.data.error[0])
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                    }
                )
                .catch(error => console.log(error)
                )
        }
    });

    const handleSelectShop = options => {
        setSelectedShop(options)
        const optionsData = []
        options.map(item => optionsData.push(item.value))
        formik.setFieldValue('branches', optionsData);
    }

    const getShopsToAddNewBranchData = async () => {
        const payload = { parent_shop_id }
        setLoading(true)
        await dispatch(getShopsToAddNewBranch(payload))
            .then(
                response => {
                    setLoading(false);
                    let shopsList = []
                    const result = response.data && response.data.shops
                    result.forEach(item => {
                        shopsList.push({ value: item._id, label: item.nickname })
                    })
                    setShops(shopsList)
                },
                error => {
                    setLoading(false)
                }
            )
    };


    return (
        <div className={`modal fade scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Add exist shop into head branch</h5>
                            <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Head Branch Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            defaultValue={headBranchName}
                                            placeholder='Head branch name'
                                            disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">Exist Shop</label>
                                        <ReactSelect placeholder="Select shop"
                                            isClearable={true}
                                            options={shops}
                                            isMulti={true}
                                            hideSelectedOptions={false}
                                            value={selecteShop}
                                            styles={selectOptionStyle}
                                            onChange={option => handleSelectShop(option)}
                                        />
                                        {formik.touched.branches && formik.errors.branches ? (<span className="text-danger">{formik.errors.branches}</span>) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={closeModal} className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button disabled={loading} type="submit" className="btn btn-success"> {loading ? "Loading..." : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(AddNewBranchModal))