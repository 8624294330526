import React, { useEffect, useState } from "react";
import { getFormatedDate, setCallout, setStatusColor } from '../../Helper';


function OrderNotes(props) {
    const { modal, closeModal, orderDetails } = props
    const { order_notes } = orderDetails
    const [notesData, setNotesData] = useState([])

    useEffect(() => {
        if (order_notes) {
            if (Array.isArray(order_notes)) {
                setNotesData(order_notes)
            }
        }
    }, [orderDetails])

    const setOrderNotes = (item) => {
        if (item.message.toLowerCase() === 'other') {
            return item.message_description
        }
        else {
            return item.message
        }
    }

    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} id="myModal"
            style={{ display: modal ? "block" : "none" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary">Notes List {`(${orderDetails?.ordernumber})`}</h5>
                        <button type="button" onClick={() => closeModal()} className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div className="modal-body overflow-auto detail--modal--scroll order_notes_txt">
                        {notesData && notesData.length > 0 ?
                            notesData.map((item, index) =>
                                <div key={index + 1} className={`callout ${setCallout(item?.status)}`}>
                                    <div className="d-flex justify-content-between">
                                        <div title="Action Time" className="d-flex justify-content-start align-items-center">
                                            <i className="fa-solid fa-clock pb-2"></i>
                                            <h6 className="pl-2 fs-18">{getFormatedDate(item.date)}</h6>
                                        </div>
                                        <h5 title="Order status" style={setStatusColor(item?.status)} className='cursor-pointer text-capitalize fs-18'>{item?.status}</h5>
                                    </div>
                                    <div title="Message type" className="d-flex justify-content-start align-items-center">
                                        <i className="fa-solid fa-message-dots pt-1"></i>
                                        <p className="pl-2 fs-17">{setOrderNotes(item)}</p>
                                    </div>
                                </div>
                            ) :
                            <div className='not-found-data'>
                                <h2>There are no data to display</h2>
                            </div>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OrderNotes