import React from 'react';

function ReorderModal(props) {
    const {
        loader,
        actionType,
        reorderModel,
        closeModal,
        handleReorderCreated,
        handleReorderPendingPick,
        handleRenotifyOrder,
        createToScheduleOrder
    } = props

    const handleConfirmed = () => {
        if (actionType === 'Created_Reorder') {
            handleReorderCreated();
        } else if (actionType === 'Pending_pickup_Reorder') {
            handleReorderPendingPick();
        } else if (actionType === 'Created_Renotify') {
            handleRenotifyOrder();
        }
        else if (actionType === 'Create_To_Schedule') {
            createToScheduleOrder();
        }
    }

    return (
        <div id="myModal" className={`modal fade cs--modal scroler-fixed ${reorderModel ? "show" : "hide"}`} style={{ display: reorderModel ? "block" : "none" }}>
            <div className="modal-dialog modal-confirm">
                <div className="modal-content">
                    <div className="modal-header flex-column">
                        <h4 className="modal-title w-100 text-center">Please Confirm</h4>
                        <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div className="modal-body">
                        {actionType === 'Create_To_Schedule' ?
                            <h5 style={{ fontSize: '21px' }} className='text-secondary'>Are you sure you want to schedule ?</h5>
                            :
                            <h5 style={{ fontSize: '21px' }} className='text-secondary'>Are you sure, you want to {actionType === 'Created_Renotify' ? 'renotify' : 'reorder'} ?</h5>
                        }
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">No</button>
                        <button type="button" onClick={() => handleConfirmed()} className="btn btn-primary">{loader ? 'Loading...' : 'YES'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ReorderModal