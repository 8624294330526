import React, { useState } from "react";

function Pagination(props) {
    const [pageNumberLimit] = useState(5);
    let {
        totalCount,
        setcurrentPage,
        currentPage,
        itemsPerPage,
        maxPageNumberLimit,
        minPageNumberLimit,
        setmaxPageNumberLimit,
        setminPageNumberLimit
    } = props

    itemsPerPage = itemsPerPage || 50

    const pages = [];
    for (let i = 1; i <= Math.ceil(totalCount / itemsPerPage); i++) {
        pages.push(i);
    }

    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li
                    key={number}
                    onClick={() => paginate(number)}
                    className={currentPage === number ? 'active page-item ' : 'none page-item'}
                >
                    <a className='page-link' > {number} </a>
                </li>
            );
        } else {
            return null;
        }
    });

    const paginate = (pageNumber) => {
        setcurrentPage(pageNumber);
    };
    
    const handleNextbtn = () => {
        if (currentPage === pages[pages.length - 1]) {
            return;
        }
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const handlePrevbtn = () => {
        if (currentPage === pages[0]) {
            return;
        }
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };

    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
    }

    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
    }
    return (
        <>
            <nav aria-label="navigation">
                <ul className="pagination">
                    <li className="page-item">
                        <span className={`page-link ${currentPage !== pages[0] && 'btn_active'}`} onClick={handlePrevbtn}>Previous</span>
                    </li>
                    {pageDecrementBtn}
                    {renderPageNumbers}
                    {pageIncrementBtn}
                    <li className="page-item">
                        <span className={`page-link ${currentPage !== pages[pages.length - 1] && 'btn_active'}`} onClick={handleNextbtn}>Next</span>
                    </li>
                </ul>
            </nav>
        </>
    )
}
export default Pagination
