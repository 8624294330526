import React, { useState, useEffect, useContext } from 'react';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { getBalanceSheet } from '../../Redux/action/Report';
import ExportModel from '../../Components/ConfirmModel/ExportData';
import { setOriginShopName, setAlternativeShopName, getFormatedDate, exportToExcel } from '../../Helper';
import { Link, useParams } from 'react-router-dom';
import DateInputs from '../../Components/DatePicker';
import Pagination from '../../Components/Pagination';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TableFooter from '../../Components/TableFooter';
import { SidebarContext } from './../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';
import moment from 'moment';
import { CSVLink } from 'react-csv';

// Export CSV File Headers
const headers = [
    { label: "TXN ID", key: "order[0].ordernumber" },
    { label: "Shop Name", key: "order[0].shopname" },
    { label: "Driver Name", key: "order[0].drivername" },
    { label: "Driver Phone", key: "order[0].driverphone" },
    { label: "Type", key: "type" },
    { label: "Notes", key: "notes" },
    { label: "Ammount", key: "amount" },
    { label: "Created At", key: "_created_at" },
    { label: "Updated At", key: "_updated_at" },
];

function BalanceSheets(props) {
    const { setLoading, isLoading } = props
    const params = useParams()
    const { invoiceDriver } = useSelector(state => state.app)
    const { username, phone } = invoiceDriver ? invoiceDriver : {}
    const [users, setUsers] = useState([])
    const [searchParams, setSearchParams] = useState({ from_date: "", to_date: "" })
    const location = useLocation()
    const prevCurrentPage = location.state && location.state.currentPage ? location.state.currentPage : {}

    const {
        csvLink,
        dispatch,
        fileLoading,
        setFileLoading,
        totalCount,
        toast,
        handleSubmit,
        selectedDate,
        setSelectedDate,
        setTotalCount,
        sorting,
        resetForm,
        handleSorting,
        currentPage,
        setcurrentPage,
        itemsPerPage,
        exportsData,
        setExportsData,
        selectedItem,
        exportModal,
        toggleExportModal,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
    } = useContext(SidebarContext)


    useEffect(() => {
        getUsersData();
    }, [currentPage, sorting])

    const getUsersData = async () => {
        const payload = {
            driverId: params.id,
            search: searchParams,
            shorting: sorting,
            pagination: { start: currentPage, length: itemsPerPage }
        }
        setLoading(true)
        await dispatch(getBalanceSheet(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setUsers(response.data.orders)
                        setTotalCount(response.data.total_count)
                    } else {
                        setUsers([])
                        setTotalCount(null)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
    }

    const getExportingData = async () => {
        try {
            const totalItem = [];
            let payload;
            let itemCount;

            if (selectedItem && selectedItem.length > 0) {
                payload = {
                    driverId: params.id,
                    search: searchParams,
                    selected_items: selectedItem,
                    shorting: sorting,
                    pagination: { start: 1, length: 1000 }
                };
                itemCount = 1;
            } else {
                payload = {
                    driverId: params.id,
                    search: { ...searchParams },
                    selected_items: [],
                    shorting: sorting,
                    pagination: { start: 1, length: 50000 }
                };
                itemCount = Math.ceil(totalCount / 50000);
            }

            setFileLoading(true);

            for (let i = 1; i <= itemCount; i++) {
                payload.pagination.start = i;
                const response = await dispatch(getBalanceSheet(payload));
                setFileLoading(false);

                if (response.status === "1") {
                    totalItem.push(...response.data.orders);
                } else {
                    toast.error(response?.data?.message || "An error occurred");
                }
            }
            const exportData = totalItem?.map((item) => {
                const exportItem = {};
                headers.forEach(header => {
                    exportItem[header.label] = item[header.key];
                });
                return exportItem;
            });
            const fileName = `Balance_Sheets-${moment(new Date()).format("hh-mm-ss")}.xlsx`
            exportToExcel(exportData, fileName);
            // setExportsData(totalItem);
            // csvLink.current.link.click();
            toast.success("Data exported successfully");
            toggleExportModal();
        } catch (error) {
            setFileLoading(false);
            toast.error(error.message || "An error occurred");
        }
    };

    const handleChangeDate = (date, name) => {
        setSearchParams((prevState) => ({
            ...prevState,
            [name]: date ? moment(date).format("YYYY-MM-DD") : '',
        }));
        setSelectedDate((prevState) => ({
            ...prevState,
            [name]: date,
        }));
    }

    const handleReset = async () => {
        const resetFormData = { from_date: "", to_date: "" }
        setSearchParams(resetFormData)
        resetForm();
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header main_breadcrumb_top">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">{username}({phone})'s Balance Sheet</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Balance Sheet</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="all_drivers">
                                    <div className="card card-dark">
                                        <div className="card-header">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-6 col-lg-11">
                                                        <h3 className="pl-5">Search</h3>
                                                    </div>
                                                    <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                        <i className="fas fa-angle-up click_search"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form className="form_search" action="">
                                            <div className="card-body">
                                                <DateInputs selectedDate={selectedDate} handleChangeDate={handleChangeDate} />
                                            </div>
                                            <div className="card-footer text-center">
                                                <button type="button" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                                                <button type="button" onClick={() => handleSubmit(getUsersData)} className="btn btn-success">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="row pagination_new">
                                        <div className="col-lg-6 col-12">
                                            <Link to={{ pathname: "/invoices", state: { currentPage: prevCurrentPage } }}><button disabled={isLoading} type="button" className="btn btn-primary mr-2"><i className="fa-regular fa-arrow-left"></i>Back</button></Link>
                                            <button disabled={isLoading} type="button" onClick={() => handleSubmit(getUsersData)} className="btn btn-secondary"><i className={`fa-regular fa-rotate-right ${isLoading && 'fa_spinner'}`}></i>Refresh</button>
                                            <button type='button' disabled={fileLoading || !totalCount || isLoading} onClick={toggleExportModal} className="btn btn-info ml-2"><i className="fa-regular fa-circle-down"></i>{fileLoading ? "Loading.." : "Export"}</button>
                                            {/* <CSVLink className='csv-link' target='_blank' ref={csvLink}
                                                headers={headers}
                                                data={exportsData}
                                                filename={`Balance_Sheets_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss-A")}`}
                                            /> */}
                                        </div>
                                        {users?.length > 0 &&
                                            <div className="col-lg-6 col-12">
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    maxPageNumberLimit={maxPageNumberLimit}
                                                    minPageNumberLimit={minPageNumberLimit}
                                                    setcurrentPage={setcurrentPage}
                                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                    setminPageNumberLimit={setminPageNumberLimit}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {users?.length > 0 &&
                                        <React.Fragment>
                                            <div className="card table_card completed_1">
                                                <div className="card-body all_drive_table">
                                                    <table id="example2" className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Txn Id</th>
                                                                <th>Shop Name</th>
                                                                <th>Type</th>
                                                                <th>Note</th>
                                                                <th>Description</th>
                                                                <th>Action</th>
                                                                <th>Amount</th>
                                                                <th>Txn Date
                                                                    {sorting.order_by === "_created_at" ?
                                                                        <i onClick={() => handleSorting("_created_at")}
                                                                            className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                                        </i>
                                                                        : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                                    }
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {users && users.length > 0 && users.map((item) =>
                                                            (<tr key={item._id} >
                                                                <td>{item.order && item.order[0] && item.order[0].ordernumber ? item.order[0].ordernumber : "Not Available"}</td>
                                                                <td>{item.order && item.order[0] && item.order[0].shopname ? setOriginShopName(item.order[0].shopname) : "Not Available"} <br />
                                                                    {item.order && item.order[0] && item.order[0].shopname ? setAlternativeShopName(item.order && item.order[0] && item.order[0].shopname) : ''}
                                                                </td>
                                                                <td>{item.type ? item.type?.replace(/_/g, " ") : "Not Available"}</td>
                                                                <td className='text-capitalize'>{item.notes ? item.notes : "Not Available"}</td>
                                                                <td>{item.description ? item.description : "Not Available"}</td>
                                                                <td className='text-capitalize'>{item?.action?.replace(/_/g, " ")}</td>
                                                                <td className={`${item?.amount < 0 ? 'text-danger' : "text-success"}`} >{Math.abs(item?.amount)?.toFixed(2)}</td>
                                                                <td>{getFormatedDate(item._updated_at)}</td>
                                                            </tr>)
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row pagination_new">
                                                <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={users} totalCount={totalCount} />
                                                <div className="col-lg-4">
                                                    <Pagination
                                                        totalCount={totalCount}
                                                        currentPage={currentPage}
                                                        itemsPerPage={itemsPerPage}
                                                        maxPageNumberLimit={maxPageNumberLimit}
                                                        minPageNumberLimit={minPageNumberLimit}
                                                        setcurrentPage={setcurrentPage}
                                                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                        setminPageNumberLimit={setminPageNumberLimit}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {users?.length === 0 && !isLoading && <NotFoundData />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {exportModal && <ExportModel
                loader={fileLoading}
                exportModal={exportModal}
                toggleExportModal={toggleExportModal}
                getExportingData={getExportingData}
                selectedItem={selectedItem}
                totalCount={totalCount} />}
        </>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(BalanceSheets))