import React from 'react';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';


function SearchUsersForm(props) {
    const location = useLocation()
    const pathName = location.pathname
    const { searchParams, handleSearch, handleChangeDate, selectedDate } = props

    return (
        <div className="card-body">
            <div className="form-group">
                <label >Name</label>
                <input className="form-control" type="text" placeholder="Name"
                    name="username"
                    value={searchParams.username}
                    onChange={handleSearch}
                />
            </div>
            <div className="form-group">
                <label>Phone</label>
                <input className="form-control" type="tel" placeholder="Phone"
                    name="phone"
                    value={searchParams.phone}
                    onChange={handleSearch}
                />
            </div>
            <div className="form-group">
                <label>Start Date</label>
                <DatePicker className="form-control date-field ub-l cursor-pointer" placeholderText="Start Date"
                    dateFormat='dd/MM/yyyy'
                    showIcon={selectedDate?.from_date ? false : true}
                    dropdownMode="select"
                    isClearable={true}
                    selected={selectedDate?.from_date}
                    maxDate={new Date()}
                    onChange={date => handleChangeDate(date, "from_date")}
                    showYearDropdown
                    showMonthDropdown
                    adjustDateOnChange
                    autocomplete={false}
                />
            </div>
            <div className="form-group">
                <label>End Date</label>
                <DatePicker className="form-control date-field ub-l cursor-pointer" placeholderText="End Date"
                    dateFormat={'dd/MM/yyyy'}
                    showIcon={selectedDate?.to_date ? false : true}
                    isClearable={true}
                    selected={selectedDate.to_date}
                    maxDate={new Date()}
                    minDate={selectedDate?.from_date}
                    onChange={date => handleChangeDate(date, "to_date")}
                    dropdownMode="select"
                    showYearDropdown
                    showMonthDropdown
                    adjustDateOnChange
                    autocomplete={false}
                />
            </div>
            {pathName === '/drivers' &&
                <div className="form-group">
                    <label>Active</label>
                    <select className="form-control" name="active" value={searchParams.active} onChange={handleSearch}>
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
            }
            {pathName !== '/drivers' &&
                <div className="form-group">
                    <label>Verified</label>
                    <select className="form-control" name="verified" value={searchParams.verified} onChange={handleSearch}>
                        <option value="" >All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>}
            {pathName === '/drivers' &&
                <div className="form-group">
                    <label>VIP</label>
                    <select className="form-control" name="is_vip" value={searchParams.is_vip} onChange={handleSearch}>
                        <option value="" >All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>}
            <div className="form-group">
                <label>Available</label>
                <select className="form-control" name="available" value={searchParams.available} onChange={handleSearch}>
                    <option value="" >All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            </div>
            <div className="form-group">
                <label>Online</label>
                <select className="form-control" name="online" value={searchParams.online} onChange={handleSearch}>
                    <option value="">All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            </div>
        </div>
    )
}
export default SearchUsersForm