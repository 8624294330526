import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useJsApiLoader, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import shopMarker from '../../assets/img/shop.png';
import availableDriver from "../../assets/img/available_driver.png";
import unAvailableDriver from "../../assets/img/unavailable_driver.png";
import { toast } from "react-toastify";
import { getUsersList } from "../../Redux/action/User";
import { mapCenter } from '../../Config/constants'


const libraries = ['places'];
const mapContainerStyle = { width: "100%", height: "100%" };

function AssignWithMapModal(props) {
    const dispatch = useDispatch()
    const { modal, closeModal, order, handleAssignOrder, setLoading } = props
    const [shop, setShop] = useState({ ...mapCenter, nickname: '' });
    const [activeDrivers, setActiveDrivers] = useState([]);
    const [map, setMap] = useState(null);
    const [isMouseOver, setMouseOver] = useState(false);
    const [shopMarkerInfo, setShopMarkerInfo] = useState(null);
    const [driverMarkerInfo, setDriverMarkerInfo] = useState(null);
    const [searchParams] = useState({ _id: "", type: "Driver", online: "true", nickname: "", username: "", phone: "", active: "true", verified: "", available: "" })

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    }, [libraries])

    useEffect(() => {
        getActiveDriversLocation();
    }, [])

    useEffect(() => {
        if (order && order.senderlocation) {
            setShop((prevState) => ({
                ...prevState,
                nickname: order.shopname || order.sendername,
                lat: parseFloat(order.senderlocation[0]),
                lng: parseFloat(order.senderlocation[1])
            }));
        }
    }, [order])

    const getActiveDriversLocation = async () => {
        const payLoad = {
            search: searchParams,
            shorting: { order_in: 1, order_by: "username" },
        }
        setLoading(true)
        await dispatch(getUsersList(payLoad))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const resData = response.data.users
                        // Filter User Data for Active Drivers //
                        let activeDrivers = []
                        resData.forEach(item => {
                            if (item.online) {
                                const drivers = {
                                    location: item.location,
                                    phone: item.phone,
                                    nickname: item.nickname || item.username,
                                    _id: item._id,
                                    available: item.available,
                                }
                                activeDrivers.push(drivers)
                            }
                        })
                        setActiveDrivers(activeDrivers)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    const handleShopMouseOver = (shop_info) => {
        setMouseOver(true);
        setDriverMarkerInfo(null)
        const { nickname, lat, lng } = shop_info
        const position = { lat, lng }
        setShopMarkerInfo({ nickname, position })
    };

    const handleDriverMouseOver = (data) => {
        setMouseOver(true);
        setShopMarkerInfo(null)
        const { location, nickname, phone, _id, available } = data
        const position = { lat: location[0], lng: location[1] }
        setDriverMarkerInfo({ nickname, phone, position, _id, available })
    };

    const handleInfoWindowClose = () => {
        setMouseOver(false);
        setShopMarkerInfo(null)
        setDriverMarkerInfo(null)
    };

    const assignOrderToDriver = async (driver_id) => {
        await handleAssignOrder(driver_id)
    }

    const getDriverInfo = (user, type) => {
        const { available } = user;
        let status, icon, textColor;
        if (available) {
            status = "AVAILABLE"
            icon = availableDriver
            textColor = "text-success"
        }
        else {
            status = "UNAVAILABLE"
            icon = unAvailableDriver
            textColor = "text-warning"
        }
        return type === "marker" ? icon : type === "text-color" ? textColor : status;
    };

    return (
        <div id="myModal" className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLabel">Assign order to driver by map</h5>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form >
                        <div className="modal-body">
                            <div className="all_drivers_map">
                                {isLoaded && shop && shop.lat && shop.lng &&
                                    <GoogleMap
                                        center={{ lat: shop.lat, lng: shop.lng }}
                                        zoom={13}
                                        mapContainerStyle={mapContainerStyle}
                                        onLoad={map => setMap(map)}
                                    >
                                        {
                                            shop.lat && shop.lng &&
                                            <Marker
                                                position={{ lat: shop.lat, lng: shop.lng }}
                                                icon={shopMarker}
                                                onMouseOver={() => handleShopMouseOver(shop)}
                                            />
                                        }
                                        {(activeDrivers && activeDrivers.length > 0) &&
                                            activeDrivers.map((item, i) =>
                                                item.location && item.location[0] && item.location[1] &&
                                                <Marker
                                                    key={i + 1}
                                                    position={{ lat: item?.location[0], lng: item?.location[1] }}
                                                    icon={getDriverInfo(item, "marker")}
                                                    onMouseOver={() => handleDriverMouseOver(item)}
                                                />
                                            )}
                                        {isMouseOver && shopMarkerInfo && (
                                            <InfoWindow
                                                position={shopMarkerInfo.position}
                                                onCloseClick={handleInfoWindowClose}
                                            >
                                                <div>
                                                    <h6>{shopMarkerInfo.nickname}</h6>
                                                </div>
                                            </InfoWindow>
                                        )}
                                        {isMouseOver && driverMarkerInfo && (
                                            <InfoWindow
                                                position={driverMarkerInfo.position}
                                                onCloseClick={handleInfoWindowClose}
                                                className="info_window"
                                            >
                                                <div className="w-100">
                                                    <h5 style={{ fontSize: "16px" }} className={`${getDriverInfo(driverMarkerInfo, "text-color")}`}>{getDriverInfo(driverMarkerInfo, "status")}</h5>
                                                    <h6 style={{ fontSize: "16px" }} className="text-secondary">{driverMarkerInfo.nickname}</h6>
                                                    {/* <h5>{driverMarkerInfo.phone}</h5> */}
                                                    <button type="button" onClick={() => assignOrderToDriver(driverMarkerInfo._id)} className="btn btn-primary btn-sm">Assign Order</button>
                                                </div>
                                            </InfoWindow>
                                        )}
                                    </GoogleMap>
                                }
                            </div>
                        </div>
                        <div className="modal-footer model-form-savbtn">
                            <button type="button" onClick={closeModal} className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AssignWithMapModal
