import { authAxios } from "../../Config/axios";
import { countryCode } from '../../Config/constants';
import { trimNestedObjValues } from "../../Helper";


export const setFormCurrentStep = data => _dispatch => {
    return (
        _dispatch({ type: "SET_FORM_CURRENT_STEP", payload: data })
    )
}

export const addUserInfoMation = data => _dispatch => {
    return (
        _dispatch({ type: "SET_USER_INFO", payload: data })
    )
}

export const addShopInfoMation = data => _dispatch => {
    return (
        _dispatch({ type: "SET_SHOP_INFO", payload: data })
    )
}

export const addShopOtherInfoMation = data => _dispatch => {
    return (
        _dispatch({ type: "SET_SHOP_OTHER_INFO", payload: data })
    )
}

export const addSocialLinks = data => _dispatch => {
    return (
        _dispatch({ type: "SET_SOCIAL_LINKS", payload: data })
    )
}

export const addOpeningHours = data => _dispatch => {
    return (
        _dispatch({ type: "SET_OPENING_HOURS", payload: data })
    )
}

export const addMaqsam = data => _dispatch => {
    return (
        _dispatch({ type: "SET_MAQSAM", payload: data })
    )
}

export const addShopImage = data => _dispatch => {
    return (
        _dispatch({ type: "SET_SHOP_IMAGE", payload: data })
    )
}

export const addShopOtherImage = data => _dispatch => {
    return (
        _dispatch({ type: "SET_SHOP_OTHER_IMAGE", payload: data })
    )
}

export const cleanShopFormData = () => _dispatch => {
    return (
        _dispatch({ type: "CLEAN_SHOP_FORM_DATA" })
    )
}

export const setPShopId = data => _dispatch => {
    return (
        _dispatch({ type: "SET_P_SHOP_ID", payload: data })
    )
}

export const saveHeadBranchName = data => _dispatch => {
    return (
        _dispatch({ type: "SAVE_HEAD_BRANCH_NAME", payload: data })
    )
}

export const saveBranchesID = data => _dispatch => {
    return (
        _dispatch({ type: "SAVE_BRANCHES_ID", payload: data })
    )
}

export const saveBranchesArray = data => _dispatch => {
    return (
        _dispatch({ type: "SAVE_BRANCHES_ARRAY", payload: data })
    )
}


export const getShopList = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/get_shop_users", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getRegisteredShops = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/get_registered_shop_users", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const approveRegisteredShop = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/approve_registered_shop", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}
export const removeRegisteredShop = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/remove_registered_shop", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getShopBranches = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/get_shop_branches", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getShopsToAddNewBranch = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/get_shops_list_add_branch", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const addNewShopInHeadBranch = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/add_shop_branches", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const AddNewShop = (data) => async _dispatch => {
    let fieldsData = trimNestedObjValues(data)
    let payload = { ...fieldsData }
    let phoneNumber = payload.phone.toString();
    payload.phone = countryCode + phoneNumber;
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/add_shop", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const updateShop = (data) => async _dispatch => {
    let fieldsData = trimNestedObjValues(data)
    let payload = { ...fieldsData }
    let phoneNumber = payload.phone.toString();
    payload.phone = countryCode + phoneNumber;
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/update_shop", payload)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getShopDetails = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/get_shop_detail", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const getAllShopLocations = () => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/get_markets_location_list")
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const shopOrderExportSetting = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/update_shop_order_export_setting", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const shopActionPermissionSetting = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/update_shop_action_permission_setting", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const assignClientApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/assign_clients", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const updateUserPermission = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/update_user_permission", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const addShopSubUser = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/create_shop_sub_user", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const editShopSubUser = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/update_shop_sub_user", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const removeShopSubUser = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/remove_shop_sub_user", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const excludeShopApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/exclude_shop", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const quickShopUpdate = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/shops/quick_shop_update", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}


