import React, { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts";
import SelectDate from "../Form/Select/SelectDate";
import { useDispatch, useSelector } from "react-redux";
import { getAreasOrderCount } from '../../Redux/action/Orders';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import moment from "moment";



function PolarAreaChart({ setLoading }) {
  const dispatch = useDispatch();
  const [series, setSeries] = useState([])
  var today = new Date();
  const [selectedDate, setSelectedDate] = useState(new Date().setDate(today.getDate() - 1))
  const { user } = useSelector(state => state.auth)
  const { _id, type } = user
  const { drivers } = user ? user : {}
  const logisticsDrivers = drivers ? drivers : []

  const [options] = useState({
    chart: {
      type: 'polarArea',
      background: '#fcfcfc'
    },
    stroke: {
      colors: ['#fff']
    },
    fill: {
      opacity: 0.8
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    title: {
      text: `Per day orders within areas`,
      align: 'left'
    },
    labels: ['East', 'West', 'North', 'South', 'Center'],
  }
  )

  useEffect(() => {
    getAreasAverageData()
  }, [selectedDate])

  const getAreasAverageData = async () => {
    const formatedDate = moment(selectedDate).format('yyyy-MM-DD');
    let payload = {
      user_id: type === "Admin" ? "" : _id,
      date: formatedDate
    }
    if (["Logistics"].includes(user.type) && logisticsDrivers?.length > 0) {
      Object.assign(payload, { drivers: logisticsDrivers })
    }
    setLoading(true)
    await dispatch(getAreasOrderCount(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status == "1") {
            const resData = response.data?.orders
            const lebels = []
            const counts = []

            if (resData && resData.length > 0) {
              let ordersCount = resData.map(item => Math.round(item.count))
              setSeries(ordersCount.sort((a, b) => b - a))
              //    setOptions((prevState) => ({
              //     ...prevState,
              //     labels: orderLabels,
              // }));
            }
          }
        }).catch(error => console.log(error))
  }
  const handleChangeDate = (date) => {
    setSelectedDate(date);
  }

  return (
    <>
      <div className="card chart-avgtime">
        <div className="card-body chart_card_body">
          <div className="row justify-content-end">
            <div className="col-lg-6 col-6">
              <form>
                <div className="form-group">
                  <SelectDate selectedDate={selectedDate} handleChangeDate={handleChangeDate} />
                </div>
              </form>
            </div>
          </div>
          <ReactApexChart options={options} series={series} type="polarArea" height={300} />
        </div>
      </div>
    </>
  )
}

export default IsLoadingHOC(IsLoggedinHOC(PolarAreaChart))
