import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { trimFileName } from '../../Helper';
import { importDeliveryZones } from '../../Redux/action/DeliveryZone';


function UploadFileModal(props) {
    const dispatch = useDispatch()
    const { setLoading, loader, modal, closeModal } = props
    const [selectedFile, setSelectedFile] = useState(null)

    const formik = useFormik({
        initialValues: {
            file_name: ''
        },
        validationSchema: Yup.object({
            file_name: Yup.string()
                .required("This field is required"),
        }),
        onSubmit: async values => {
            let fieldValues = values
            var formData = new FormData();
            formData.append('file', fieldValues.file_name);
            setLoading(true)
            await dispatch(importDeliveryZones(formData))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == '1') {
                            toast.success(response.data.message)
                            formik.resetForm();
                            closeModal();
                        } else {
                            const errMsg = response.data.message || response.data.error[0]
                            toast.error(errMsg)
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                    }
                ).catch(error => console.log(error))
        },
    });

    const handleChangeFile = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            formik.setFieldValue("file_name", file)
            setSelectedFile(URL.createObjectURL(event.target.files[0]));
        }
    }

    return (
        <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Import Delivery Zone</h5>
                            <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required">
                                        <label className="control-label">CSV file</label>
                                        <div className="custom-file">
                                            <input
                                                className="custom-file-input"
                                                type="file"
                                                placeholder='Choose file'
                                                onChange={handleChangeFile}
                                                accept=".xls, .xlsx, .csv"
                                            />
                                            <label className="custom-file-label" htmlFor="exampleInputFile">{selectedFile ? trimFileName(selectedFile) : 'Choose file'}</label>
                                        </div>
                                        {formik.touched.file_name && formik.errors.file_name ? (<span className="text-danger">{formik.errors.file_name}</span>) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-btn">
                            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button disabled={loader} type="submit" className="btn btn-primary"> {loader ? "Processing.." : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(UploadFileModal))