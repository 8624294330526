import React, { useEffect, useState } from 'react';
import { formatChatTimestamp } from '../../Helper';


function WhatsAppMsges(props) {
  const { modal, closeModal, isLoading, orderDetails } = props
  const { order_history } = orderDetails ? orderDetails : []
  const [whatsAppMessages, setWhatsAppMessages] = useState([])

  useEffect(() => {
    const whatsApp_messages = order_history?.filter(item => item.object_type === "whatsapp_message")
    setWhatsAppMessages(whatsApp_messages)
  }, [order_history])


  return (
    <div className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} id="myModal"
      style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title w-100 text-center">WhatsApp Message {`(${orderDetails?.ordernumber})`}</h5>
            <button type="button" onClick={() => closeModal()} className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
          </div>
          <div style={{ maxHeight: '400px', overflowY: 'auto' }} className="modal-body">
            <div className="comment-widgets">
              {whatsAppMessages?.length > 0 &&
                whatsAppMessages.map((item, index) =>
                  <div key={index + 1} className="card p-2">
                      <div className='d-flex justify-content-between'>
                        <div>
                          <h6 title='Sender' className='text-primary text-capitalize'>{item.user}</h6>
                          <small className='text-secondary'> ({item.role})</small>
                        </div>
                        <div>
                        <i className="fa-sharp fa-solid fa-arrow-right"></i>
                        </div>
                        <div>
                          <h6 title='Receiver' className='text-success text-capitalize'>{item.receiver.name}</h6>
                          <small className='text-secondary'>({item.receiver.type})</small>
                        </div>
                        <div>
                          <small title='Time' className="text-muted float-right">{formatChatTimestamp(item.time)}</small>
                        </div>
                      </div>
                      <div title='Whatsapp-message'>
                        <p className='pt-2'>{item.message}</p>
                      </div>
                  </div>
                )}
            </div>
            {whatsAppMessages && whatsAppMessages.length === 0 && !isLoading &&
              <div className='text-center'>
                <h5>Whatsapp message data not found</h5>
              </div>
            }
          </div>
          <div className="modal-footer">
            <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default WhatsAppMsges;



