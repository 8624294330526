import React from 'react'

function SelectMonth(props) {
    const { selectedMonth, handleChangeMonth } = props

    return (
        <React.Fragment>
            <select name='active' className="custom-select" value={selectedMonth} onChange={(e) => handleChangeMonth(e.target.value)}>
                <option value=''>Select Month</option>
                <option value='1'>January</option>
                <option value='2'>February</option>
                <option value='3'>March</option>
                <option value='4'>April</option>
                <option value='5'>May</option>
                <option value='6'>June</option>
                <option value='7'>July</option>
                <option value='8'>August</option>
                <option value='9'>September</option>
                <option value='10'>October</option>
                <option value='11'>November</option>
                <option value='12'>December</option>
            </select>
        </React.Fragment>
    )
}
export default SelectMonth
