import { authAxios} from "../../Config/axios";


export const uploadShopImgFile = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/media/upload_file" ,data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const uploadFileApi = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/media/upload_file" ,data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const removeFileImgAWS = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/media/remove_file" ,data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const uploadDriverDocument = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/app/driver/upload_document" ,data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}

export const uploadUserDocument = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/users/upload_document" ,data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Catch", error);
                }
            )
    })
}