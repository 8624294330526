import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { default as ReactSelect } from "react-select";
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { phoneRegExp, countryCode } from '../../Config/constants';
import { getAreas } from '../../Redux/action/Area';
import { getHashKey, selectOptionStyle } from '../../Helper';
import { getCities } from '../../Redux/action/City';
import { registerShopUser, sendOtpApi, verifyOtpApi } from '../../Redux/action/Auth';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import {
    useJsApiLoader,
    GoogleMap,
    MarkerF,
    Autocomplete,
} from "@react-google-maps/api";
import { mapCenter } from '../../Config/constants'


const userAccountSchema = Yup.object({
    username: Yup.string()
        .required("User name is required")
        .min(3, 'User name must be at least 3 characters'),

    email: Yup.string()
        .required("Email is required")
        .email("Please enter a valid email address"),

    password: Yup.string()
        .required("Password is required")
        .min(5, 'Password must be at least 5 characters'),

    confirm_password: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),

})

const userPersonalSchema = Yup.object({

    shop: Yup.string()
        .required("Shop name is required")
        .min(3, 'Shop must be at least 3 characters'),

    city: Yup.string()
        .required("Please select any city"),

    locationarea: Yup.string()
        .required("Please select area location"),

    locationaddress: Yup.string()
        .required("Location Address is required")
        .min(5, 'Address must be at least 5 characters'),

})

function Register(props) {
    const { setLoading } = props
    const dispatch = useDispatch()
    const history = useHistory()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [selectedCity, setSelectedCity] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null)
    const [areas, setAreas] = useState([])
    const [cities, setCities] = useState([])
    const [activeSteps, setActiveSteps] = useState(['otpVerification'])
    const [showOtp, setShowOtp] = useState(false)
    const [otp, setOtp] = useState("")


    const [latLng, setLatLng] = useState(mapCenter)
    const [map, setMap] = useState(null);
    const autocompleteRef = useRef();


    var options = {
        enableHighAccuracy: true,
        timeout: 1000,
        maximumAge: 0,
    };

    const successCallback = function (position) {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                const location = { lat: position.coords.latitude, lng: position.coords.longitude };
                setLatLng(location)
            });
        }
    };


    function errorCallback(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                alert("User denied the request for Geolocation.");
                break;
            case error.POSITION_UNAVAILABLE:
                alert("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                alert("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                alert("An unknown error occurred.");
                break;
            default:
                alert("Unknown error");
        }
    }

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                successCallback,
                errorCallback,
                options
            );
        } else {
            alert("Geolocation is not supported by your browser");
        }
    }, []);

    const { isLoaded } = useJsApiLoader(
        {
            googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            libraries: ["places"],
        },
        []
    );

    function handleLoad(maps) {
        setMap(maps);
    }


    async function onPlaceChanged(place) {
        if (autocompleteRef.current.value === "") {
            return;
        }
        // eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
            { address: autocompleteRef.current.value },
            function (results, status) {
                // eslint-disable-next-line no-undef
                if (status === google.maps.GeocoderStatus.OK) {
                    let latitude = results[0].geometry.location.lat();
                    let longitude = results[0].geometry.location.lng();
                    const location = { lat: latitude, lng: longitude };
                    setLatLng(location)
                } else {
                    alert(
                        "Geocode was not successful for the following reason: " + status
                    );
                }
            }
        );
    }

    function handleDragEnd(e) {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    autocompleteRef.current.value = results[0].formatted_address
                    geocoder.geocode({ address: autocompleteRef.current.value })
                    setLatLng({ lat, lng })
                } else {
                    console.log('No results found');
                }
            } else {
                console.log('Geocoder failed due to: ' + status);
            }
        });
    }

    const mapContainerStyle = { width: "100%", height: "400px" };

    const accountFormik = useFormik({
        initialValues: {
            username: '',
            email: '',
            phone: '',
            password: '',
            confirm_password: '',
        },
        validationSchema: userAccountSchema,
        onSubmit: async (values) => {
            console.log("userAccountSchema", values)
            handleNextToPersonal();
        },
    });

    const personalFormik = useFormik({
        initialValues: {
            shop: '',
            city: "",
            locationarea: "",
            locationaddress: "",
        },
        validationSchema: userPersonalSchema,
        onSubmit: async (values) => {
            console.log("userPersonalSchema", values)
            handleNextToMapLocation();
        },
    });

    const handleSubmit = async () => {
        let fieldValues = { ...accountFormik.values, ...personalFormik.values }
        let locationMap = [latLng.lat, latLng.lng]
        Object.assign(fieldValues, {
            "location": locationMap
        });
        let phoneNumber = fieldValues.phone.toString();
        fieldValues.phone = `${countryCode}${phoneNumber}`;
        let hash_key = getHashKey(fieldValues);
        setLoading(true)
        await dispatch(registerShopUser(fieldValues, hash_key))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const toastMsg = response.message || response.data.message
                        toast.success(toastMsg)
                        history.push('/login')
                    } else {
                        const error = response.message || response?.data?.error[0] || 'Somthing went wrong'
                        toast.error(error)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data.message)
                }
            )
    }

    useEffect(() => {
        getCitiesData()
    }, [])

    const getCitiesData = async () => {
        const payload = {
            search: { id: "", name: "" },
            shorting: { order_in: -1, order_by: "_created_at" },
        }
        let hash_key = getHashKey(payload);
        payload['hash'] = hash_key;
        setLoading(true)
        await dispatch(getCities(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const cityData = []
                        const resData = response.data.cities
                        resData.map(item =>
                            cityData.push({ value: item._id, label: item.name })
                        )
                        setCities(cityData)
                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
    }

    const getAreasData = async (cityid) => {
        const payload = {
            search: { name: "", city_id: cityid },
            shorting: { order_in: -1, order_by: "_created_at" },
        }
        let hash_key = getHashKey(payload);
        payload['hash'] = hash_key;
        setLoading(true)
        await dispatch(getAreas(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const areaDropList = []
                        const resData = response.data.areas || []
                        resData.forEach(item => {
                            areaDropList.push({ value: item._id, label: item.name })
                        })
                        setAreas(areaDropList)
                    } else {
                        toast.error('Location area not found selected by city')
                        setAreas([])
                        setSelectedArea(null)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data.message)
                }
            )
    }

    const handleCitySelect = option => {
        const { value, label } = option || {}
        if (value || label) {
            setSelectedCity(option)
            personalFormik.setFieldValue('city', label);
            getAreasData(value)
        } else {
            personalFormik.setFieldValue('city', "");
            setSelectedArea(null)
            setSelectedCity(null)
            setAreas([])
            personalFormik.setFieldValue('locationarea', "");
        }
    }

    const handleAreaSelect = option => {
        const { value, label } = option || {}
        if (value || label) {
            setSelectedArea(option)
            personalFormik.setFieldValue('locationarea', label);
        } else {
            setSelectedArea(null)
            personalFormik.setFieldValue('locationarea', "");
        }
    }


    const handleNextToAccount = () => {
        let stepsArr = [...activeSteps]
        stepsArr.push('account')
        setActiveSteps(stepsArr)
    }

    const handleNextToPersonal = () => {
        let stepsArr = [...activeSteps]
        stepsArr.push('personal')
        setActiveSteps(stepsArr)
    }

    const handleNextToMapLocation = () => {
        let stepsArr = [...activeSteps]
        stepsArr.push('mapLocation')
        setActiveSteps(stepsArr)
    }

    const handlePrevious = (currentStep) => {
        let stepsArr = [...activeSteps]
        stepsArr = stepsArr.filter(item => item !== currentStep);
        setActiveSteps(stepsArr)
    }

    const handleSendOtp = async () => {
        if (!accountFormik.values.phone) {
            toast.error("Please enter phone number")
        }
        else if (!phoneRegExp.test(accountFormik.values.phone)) {
            toast.error("Please enter valid phone number")
        }
        else {
            let payload = {}
            let phoneNumber = accountFormik.values.phone.toString();
            payload.phone = `${countryCode}${phoneNumber}`;
            let hash_key = getHashKey(payload);
            payload['hash'] = hash_key;
            setLoading(true)
            await dispatch(sendOtpApi(payload))
                .then(
                    response => {
                        setLoading(false);
                        if (response.status == "1") {
                            const toastMsg=  response.data.message || response.message
                            toast.success(toastMsg)
                            setShowOtp(true)
                        } else {
                            setShowOtp(false)
                            const error = response.message || response?.data?.error[0] || 'Somthing went wrong'
                            toast.error(error)
                        }
                    },
                    (error) => {
                        setLoading(false);
                        setShowOtp(false)
                        const errMsg = error.response?.data?.data?.error[0] || 'Somthing went wrong'
                        toast.error(errMsg)
                    }
                )
                .catch(error => {
                    setLoading(false);
                    setShowOtp(false)
                    const errMsg = error.response?.data?.data?.error[0] || 'Somthing went wrong'
                    toast.error(errMsg)
                })
        }
    }

    const verifyOtp = async () => {
        let payload = { otp: otp }
        let phoneNumber = accountFormik.values.phone.toString();
        payload.phone = `${countryCode}${phoneNumber}`;
        let hash_key = getHashKey(payload);
        payload['hash'] = hash_key;
        setLoading(true)
        await dispatch(verifyOtpApi(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        toast.success(response.message)
                        handleNextToAccount()
                    } else {
                        const error = response.message || response?.data?.error[0] || 'Somthing went wrong'
                        toast.error(error)
                    }
                },
                (error) => {
                    setLoading(false);
                    const errMsg = error.response?.data?.data?.error[0] || 'Somthing went wrong'
                    toast.error(errMsg)
                }
            )
            .catch(error => {
                setLoading(false);
                const errMsg = error.response?.data?.data?.error[0] || 'Somthing went wrong'
                toast.error(errMsg)
            })
    }

    return (
        <div className="container-fluid signup--content" id="grad1">
            <div className="row justify-content-center mt-0">
                <div className="col-11 col-sm-9 col-md-7 col-lg-4 text-center p-0 mt-5 mb-2">
                    <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
                        <h4>Shop Registration</h4>
                        <p>Fill all mandatory form field to go to next step</p>
                        <div className="row">
                            <div className="col-md-12 mx-0">
                                <ul id="progressbar">
                                    <li className={activeSteps.includes('otpVerification') ? "active" : ""} id="otpVerification"><strong>OTP Verification</strong></li>
                                    <li className={activeSteps.includes('account') ? "active" : ""} id="account"><strong>Account</strong></li>
                                    <li className={activeSteps.includes('personal') ? "active" : ""} id="personal"><strong>Personal</strong></li>
                                    <li className={activeSteps.includes('mapLocation') ? "active" : ""} id="mapLocation"><strong>Map Location</strong></li>
                                </ul>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div style={{ display: activeSteps.length === 1 ? 'block' : 'none' }}>
                                                    {!showOtp &&
                                                        <div className='row'>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <h5>Phone Verification</h5>
                                                                    <div className="input-group">
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text">{countryCode}</span>
                                                                        </div>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            placeholder="Phone No."
                                                                            required
                                                                            name="phone"
                                                                            onChange={accountFormik.handleChange}
                                                                            value={accountFormik.values.phone}
                                                                            onBlur={accountFormik.handleBlur}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {showOtp &&
                                                        <div className='row'>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <h5>OTP Verification</h5>
                                                                    <OtpInput
                                                                        inputType="number"
                                                                        value={otp}
                                                                        onChange={(e) => setOtp(e)}
                                                                        numInputs={5}
                                                                        renderSeparator={<span style={{ width: "5px" }}></span>}
                                                                        renderInput={(props) => <input {...props} />}
                                                                        shouldAutoFocus={true}
                                                                        containerStyle={{
                                                                            justifyContent: "center",
                                                                            marginTop: "0em",
                                                                        }}
                                                                        inputStyle={{
                                                                            border: "1px solid #F6A94A",
                                                                            borderRadius: "8px",
                                                                            width: "10%",
                                                                            height: "35px",
                                                                            fontSize: "16px",
                                                                            color: "black",
                                                                            fontWeight: "400",
                                                                            caretColor: "blue",
                                                                            margin: "20px 8px 0 8px",
                                                                        }}
                                                                        focusStyle={{
                                                                            border: "1px solid #F6A94A ",
                                                                            outline: "none",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        !showOtp ?
                                                            <button type="button" onClick={handleSendOtp} className="btn btn-primary mt-2">Send OTP </button>
                                                            : <>
                                                                <button disabled={otp.length < 5} type="button" onClick={verifyOtp} className="btn btn-primary mt-2">Verify OTP </button>
                                                                <div style={{ margin: "3em auto " }} className="d-flex justify-content-center text-center">
                                                                    <p style={{ textAlign: "center", margin: "auto 10px  auto auto" }}>
                                                                        Didn't recieve code ?
                                                                    </p>
                                                                    <button
                                                                        style={{
                                                                            backgroundColor: "transparent",
                                                                            border: "none",
                                                                            display: "block",
                                                                            margin: " auto auto  auto 0px",
                                                                            color: "#F6A94A",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={handleSendOtp}
                                                                    >
                                                                        Resend OTP
                                                                    </button>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div style={{ display: activeSteps.length === 2 ? 'block' : 'none' }} >
                                                    <div className='row'>
                                                        <div className="col-sm-12">
                                                            <div className="form-group text-left">
                                                                <h5 className="mb-4">Account Information</h5>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="User Name*"
                                                                    required
                                                                    id='username'
                                                                    name="username"
                                                                    onChange={accountFormik.handleChange}
                                                                    value={accountFormik.values.username}
                                                                    onBlur={accountFormik.handleBlur}
                                                                />
                                                                {accountFormik.touched.username && accountFormik.errors.username ? (<span className="text-danger">{accountFormik.errors.username}</span>) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-sm-12">
                                                            <div className="form-group text-left">
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="Email Id*"
                                                                    required
                                                                    name="email"
                                                                    onChange={accountFormik.handleChange}
                                                                    value={accountFormik.values.email}
                                                                    onBlur={accountFormik.handleBlur}
                                                                />
                                                                {accountFormik.touched.email && accountFormik.errors.email ? (<span className="text-danger">{accountFormik.errors.email}</span>) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-sm-12">
                                                            <div className="form-group text-left">
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Password*"
                                                                    required
                                                                    name="password"
                                                                    onChange={accountFormik.handleChange}
                                                                    value={accountFormik.values.password}
                                                                    onBlur={accountFormik.handleBlur}
                                                                    type={showPassword ? 'text' : 'password'}
                                                                />
                                                                <i style={{ top: "13px" }} className={showPassword ? "fas fa-eye" : " fas fa-eye-slash"} onClick={() => setShowPassword(!showPassword)} id="eye"></i>
                                                                {accountFormik.touched.password && accountFormik.errors.password ? (<span className="text-danger">{accountFormik.errors.password}</span>) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-sm-12">
                                                            <div className="form-group text-left">
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Confirm password*"
                                                                    required
                                                                    name="confirm_password"
                                                                    onChange={accountFormik.handleChange}
                                                                    value={accountFormik.values.confirm_password}
                                                                    onBlur={accountFormik.handleBlur}
                                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                                />
                                                                <i style={{ top: "13px" }} className={showConfirmPassword ? "fas fa-eye" : " fas fa-eye-slash"} id="eye" onClick={() => setShowConfirmPassword(!showConfirmPassword)} ></i>
                                                                {accountFormik.touched.confirm_password && accountFormik.errors.confirm_password ? (<span className="text-danger">{accountFormik.errors.confirm_password}</span>) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" onClick={accountFormik.handleSubmit} name="next" className="btn btn-primary" value="Next Step">Next Step</button>
                                                </div>
                                                <div style={{ display: activeSteps.length === 3 ? 'block' : 'none' }}>
                                                    <div className='row'>
                                                        <div className="col-sm-12">
                                                            <div className="form-group text-left">
                                                                <h5 className="mb-4">Personal Information</h5>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Shop name*"
                                                                    required
                                                                    name="shop"
                                                                    onChange={personalFormik.handleChange}
                                                                    value={personalFormik.values.shop}
                                                                    onBlur={personalFormik.handleBlur}
                                                                />
                                                                {personalFormik.touched.shop && personalFormik.errors.shop ? (<span className="text-danger">{personalFormik.errors.shop}</span>) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-sm-12">
                                                            <div className="form-group text-left">
                                                                <ReactSelect
                                                                    placeholder="Select City*"
                                                                    isClearable={true}
                                                                    options={cities}
                                                                    hideSelectedOptions={false}
                                                                    value={selectedCity}
                                                                    onBlur={personalFormik.handleBlur}
                                                                    onChange={(option) => handleCitySelect(option)}
                                                                    styles={selectOptionStyle}
                                                                />
                                                                {personalFormik.touched.city && personalFormik.errors.city ? (<span className="text-danger">{personalFormik.errors.city}</span>) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-sm-12">
                                                            <div className="form-group text-left">
                                                                <ReactSelect
                                                                    placeholder="Select Area*"
                                                                    isClearable={true}
                                                                    options={areas}
                                                                    hideSelectedOptions={false}
                                                                    value={selectedArea}
                                                                    onBlur={personalFormik.handleBlur}
                                                                    onChange={(option) => handleAreaSelect(option)}
                                                                    styles={selectOptionStyle}
                                                                />
                                                                {personalFormik.touched.locationarea && personalFormik.errors.locationarea ? (<span className="text-danger">{personalFormik.errors.locationarea}</span>) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-sm-12">
                                                            <div className="form-group text-left">
                                                                <input
                                                                    className="form-control"
                                                                    placeholder='Full address*'
                                                                    required
                                                                    name='locationaddress'
                                                                    onChange={personalFormik.handleChange}
                                                                    onBlur={personalFormik.handleBlur}
                                                                    value={personalFormik.values.locationaddress}
                                                                    type="text"
                                                                />
                                                                {personalFormik.touched.locationaddress && personalFormik.errors.locationaddress ? (<span className="text-danger">{personalFormik.errors.locationaddress}</span>) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" name="previous" onClick={() => handlePrevious('personal')} className="btn btn-secondary mr-2">Previous</button>
                                                    <button type="button" name="next" onClick={personalFormik.handleSubmit} className="btn btn-primary">Next Step</button>
                                                </div>
                                                <div style={{ display: activeSteps.length === 4 ? 'block' : 'none' }} >
                                                    {isLoaded && latLng && latLng.lat && latLng.lng && (
                                                        <div className='row'>
                                                            <div className="col-sm-12">
                                                                <GoogleMap
                                                                    center={latLng}
                                                                    zoom={14}
                                                                    mapContainerStyle={mapContainerStyle}
                                                                    onLoad={handleLoad}
                                                                    options={{
                                                                        zoomControl: true,
                                                                        streetViewControl: false,
                                                                        mapTypeControl: false,
                                                                        fullscreenControl: false,
                                                                    }}
                                                                >
                                                                    <MarkerF
                                                                        draggable
                                                                        onDragEnd={handleDragEnd}
                                                                        position={latLng} />
                                                                    <Autocomplete onPlaceChanged={(place) => onPlaceChanged(place)}>
                                                                        <section className="d-flex justify-content-center mt-4">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <input
                                                                                        style={{ borderRadius: "12px", height: "45px", width: "clamp(180px, 26vw, 500px)" }}
                                                                                        className="form-control map--input"
                                                                                        ref={autocompleteRef}
                                                                                        type="search"
                                                                                        placeholder="Search"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </section>
                                                                    </Autocomplete>
                                                                </GoogleMap>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className='mt-4'>
                                                        <button type="button" name="previous" onClick={() => handlePrevious('mapLocation')} className="btn btn-secondary mr-2">Previous</button>
                                                        <button type="button" onClick={handleSubmit} name="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ fontSize: "16px" }} id="emailHelp" className="form-text text-center mb-4 text-dark">Have an account?
                                    <Link to="/login" className="fw-bold"> Log in</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default IsLoadingHOC(Register)




