import React, { useState, useEffect, useContext } from 'react';
import { trimObjValues, isAllItemSelected, handleSelectListItem, getFormatedDate, setCharacterLimit, handleCopyClipboard } from '../../Helper';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import { getUsersList } from '../../Redux/action/User';
import { updateRelatedOrderId } from '../../Redux/action/App';
import ExportModel from '../../Components/ConfirmModel/ExportData';
import { Link, useHistory } from 'react-router-dom';
import Pagination from '../../Components/Pagination';
import SearchUsersForm from '../../Components/SearchUsers';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import TableFooter from '../../Components/TableFooter';
import { SidebarContext } from '../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';
import { exportToExcel } from '../../Helper';



// Export CSV File Headers
const headers = [
  { label: "User Name", key: "username" },
  { label: "Phone Number", key: "phone" },
  { label: "Email Address", key: "email" },
  { label: "Activated", key: "active" },
  { label: "Verified", key: "verified" },
  { label: "Available", key: "available" },
  { label: "Online", key: "online" },
  { label: "Balance", key: "balance" },
  { label: "Created At", key: "_created_at" },
  { label: "Updated At", key: "_updated_at" },
];


function AllBuyers(props) {
  const { isLoading, setLoading } = props
  const history = useHistory()
  const [buyers, setBuyers] = useState([])
  const {
    csvLink,
    dispatch,
    totalCount,
    toast,
    setTotalCount,
    currentPage,
    setcurrentPage,
    itemsPerPage,
    sorting,
    resetForm,
    handleSubmit,
    exportsData,
    setExportsData,
    selectedDate,
    setSelectedDate,
    selectedItem,
    setSelectedItem,
    handleSelectItem,
    fileLoading,
    setFileLoading,
    exportModal,
    toggleExportModal,
    handleSorting,
    maxPageNumberLimit,
    setmaxPageNumberLimit,
    minPageNumberLimit,
    setminPageNumberLimit,
  } = useContext(SidebarContext)


  const [searchParams, setSearchParams] = useState({
    type: "Buyer",
    nickname: "",
    username: "",
    phone: "",
    from_date: "",
    to_date: "",
    active: "",
    verified: "",
    available: "",
    online: "",
    states_driver: ""
  })

  useEffect(() => {
    getBuyersData();
  }, [currentPage, sorting])


  const getBuyersData = async () => {
    const search_params = trimObjValues(searchParams)
    const payload = {
      search: search_params,
      shorting: sorting,
      pagination: { start: currentPage, length: itemsPerPage }
    }
    setLoading(true)
    await dispatch(getUsersList(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status === "1") {
            setBuyers(response.data.users)
            setTotalCount(response.data.total_count)
          } else {
            setBuyers([])
            setTotalCount(null)
          }
        },
        (error) => {
          setLoading(false);
          toast.error(error.response.data?.message)
        }
      )
      .catch(
        error => {
          console.log(error)
        }
      )
  }

  const getExportingData = async () => {
    const search_params = trimObjValues(searchParams);
    let totalItem = [];

    const fetchUsersList = async (start, length, selectedItems) => {
      const payload = {
        search: search_params,
        selected_items: selectedItems,
        shorting: sorting,
        pagination: { start, length }
      };
      setFileLoading(true);
      try {
        const response = await dispatch(getUsersList(payload));
        setFileLoading(false);
        if (response.status === "1") {
          return response.data.users;
        }
      } catch (error) {
        setLoading(false);
        setFileLoading(false);
        toast.error(error.response.data?.message);
      }
      return [];
    };

    if (selectedItem && selectedItem.length > 0) {
      totalItem = await fetchUsersList(1, 1000, selectedItem);
    } else {
      const batchSize = 50000;
      const totalBatches = Math.ceil(totalCount / batchSize);
      for (let i = 1; i <= totalBatches; i++) {
        const users = await fetchUsersList(i, batchSize, []);
        totalItem = totalItem.concat(users);
      }
    }

    const exportData = totalItem?.map((item) => {
      const exportItem = {};
      headers.forEach(header => {
        exportItem[header.label] = item[header.key];
      });
      return exportItem;
    });
    const fileName = `Buyers-${moment(new Date()).format("hh-mm-ss")}.xlsx`
    exportToExcel(exportData, fileName)
    // setExportsData(totalItem);
    //  csvLink.current.link.click();
    toast.success("Data exported successfully");
    toggleExportModal()
  };


  const handleSearch = (e) => {
    let { name, value } = e.target
    value = value.toString();
    if (value[0] === "+") {
      value = value.substring(1)
    }
    setSearchParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleChangeDate = (date, name) => {
    setSearchParams((prevState) => ({
      ...prevState,
      [name]: date ? moment(date).format("YYYY-MM-DD") : '',
    }));
    setSelectedDate((prevState) => ({
      ...prevState,
      [name]: date,
    }));
  }

  const handleReset = async () => {
    const resetFormData = {
      type: "Buyer",
      nickname: "",
      username: "",
      phone: "",
      from_date: "",
      to_date: "",
      active: "",
      verified: "",
      available: "",
      online: "",
      states_driver: ""
    }
    setSearchParams(resetFormData);
    resetForm();
  }

  const handleRelatedOrders = (id, userName) => {
    const payload = { buyerid: id, relatedBy: 'Buyer', user_name: userName }
    dispatch(updateRelatedOrderId(payload))
    history.push(`/completed-orders/${id}`)
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header main_breadcrumb_top">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Buyers</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item active">Buyers</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="all_drivers">
                  <div className="card card-dark">
                    <div className="card-header">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-6 col-lg-11">
                            <h3 className="pl-5">Search</h3>
                          </div>
                          <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                            <i className="fas fa-angle-up click_search"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <form className="form_search" action="">
                      <SearchUsersForm
                        searchParams={searchParams}
                        handleSearch={handleSearch}
                        handleChangeDate={handleChangeDate}
                        selectedDate={selectedDate}
                      />
                      <div className="card-footer text-center">
                        <button type="reset" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                        <button type="button" onClick={() => handleSubmit(getBuyersData)} className="btn btn-success">Submit</button>
                      </div>
                    </form>
                  </div>
                  <div className="row pagination_new">
                    <div className="col-lg-6 col-12">
                      <button disabled={isLoading} type="button" onClick={() => handleSubmit(getBuyersData)} className="btn btn-secondary"><i className={`fa-regular fa-rotate-right ${isLoading && 'fa_spinner'}`}></i>Refresh</button>
                      <button type='button' disabled={fileLoading || !totalCount} onClick={toggleExportModal} className="btn btn-info ml-2"><i className="fa-regular fa-circle-down"></i>{fileLoading ? "Loading.." : "Export"}</button>
                      {/* <CSVLink className='csv-link' target='_blank' ref={csvLink}
                        headers={headers}
                        data={exportsData}
                        filename={`Buyers_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss")}`}
                      /> */}
                    </div>
                    {buyers?.length > 0 &&
                      <div className="col-lg-6 col-12">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          maxPageNumberLimit={maxPageNumberLimit}
                          minPageNumberLimit={minPageNumberLimit}
                          setcurrentPage={setcurrentPage}
                          setmaxPageNumberLimit={setmaxPageNumberLimit}
                          setminPageNumberLimit={setminPageNumberLimit}
                        />
                      </div>
                    }
                  </div>
                  {buyers?.length > 0 &&
                    <React.Fragment>
                      <div className="card table_card">
                        <div className="card-body all_drive_table">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th><input type="checkbox" id="check" checked={isAllItemSelected(buyers, selectedItem)} onChange={(event) => handleSelectListItem(event, buyers, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th>
                                {/* <th>Sr. No</th> */}
                                <th>Name
                                  {sorting.order_by === "username" ?
                                    <i onClick={() => handleSorting("username")}
                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                    </i>
                                    : <i onClick={() => handleSorting("username")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                  }
                                </th>
                                <th>Phone</th>
                                <th>Email ID
                                  {sorting.order_by === "email" ?
                                    <i onClick={() => handleSorting("email")}
                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                    </i>
                                    : <i onClick={() => handleSorting("email")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                  }
                                </th>
                                <th>Active</th>
                                <th>Online</th>
                                <th>Balance
                                  {sorting.order_by === "balance" ?
                                    <i onClick={() => handleSorting("balance")}
                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                    </i>
                                    : <i onClick={() => handleSorting("balance")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                  }
                                </th>
                                <th>Created At
                                  {sorting.order_by === "_created_at" ?
                                    <i onClick={() => handleSorting("_created_at")}
                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                    </i>
                                    : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                  }
                                </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {buyers.map((item, i) => (
                                <tr key={item._id} >
                                  <td>
                                    <input type="checkbox" className="user_table_checkbox"
                                      onChange={(event) => handleSelectItem(event, item._id)}
                                      checked={selectedItem.includes(item._id)} />
                                  </td>
                                  {/* <td>{(currentPage - 1) * itemsPerPage + i + 1}</td> */}
                                  <td className='cursor-pointer' title={item.username}>{setCharacterLimit(item.username)}</td>
                                  <td>{item.phone ? item.phone : "Not Available"}</td>
                                  <td>{item.email ? item.email : "Not Available"}</td>
                                  <td><span className={item.active ? 'yes_1' : item.active === false ? 'no_1' : "not_avl"} >{item.active ? 'Yes' : item.active === false ? "No" : 'NA'}</span></td>
                                  <td><span className={item.online ? 'yes_1' : item.online === false ? 'no_1' : "not_avl"} >{item.online ? 'Yes' : item.online === false ? "No" : 'NA'}</span></td>
                                  <td>{item.balance === 0 ? item.balance : item.balance ? item?.balance?.toFixed(2) : "NA"}</td>
                                  <td>{getFormatedDate(item._created_at)}</td>
                                  <td>
                                    <div className="action_button">
                                      <div className="btn-group">
                                        <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                        </button>
                                        <div className="dropdown-menu">
                                          <span onClick={() => handleCopyClipboard(item._id)} className="dropdown-item"><span>
                                            <i className="fa-regular fa-copy"></i>Copy User Id</span>
                                          </span>
                                          <div className="dropdown-divider"></div>
                                          <span className="dropdown-item" onClick={() => handleRelatedOrders(item._id, item.username)}>
                                            <i className="fa fa-list" aria-hidden="true"></i>Related Orders</span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>)
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row pagination_new">
                        <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={buyers} totalCount={totalCount} />
                        <div className="col-lg-4">
                          <Pagination
                            totalCount={totalCount}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            maxPageNumberLimit={maxPageNumberLimit}
                            minPageNumberLimit={minPageNumberLimit}
                            setcurrentPage={setcurrentPage}
                            setmaxPageNumberLimit={setmaxPageNumberLimit}
                            setminPageNumberLimit={setminPageNumberLimit}
                          />
                        </div>
                      </div>
                    </React.Fragment>}
                  {buyers?.length === 0 && !isLoading && <NotFoundData />}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {exportModal &&
        < ExportModel
          loader={fileLoading}
          toggleExportModal={toggleExportModal}
          exportModal={exportModal}
          getExportingData={getExportingData}
          totalCount={totalCount}
          selectedItem={selectedItem} />}
    </>
  )
}
export default IsLoadingHOC(IsLoggedinHOC(AllBuyers))