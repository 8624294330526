import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateUserPermission } from '../../Redux/action/Shop';
import MultiSelect from "./MultiSelect";
import { userPermission } from '../../utils/selectOptions';
import { getUserDetails } from '../../Redux/action/User';



function OtherPermissionModal(props) {
    const { setLoading, isLoading, modal, closeModal, user, getUserList } = props
    const { _id, nickname, username } = user
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState([]);
    const [selected, setSelected] = useState();


    useEffect(() => {
        getUserDetailsData();
    }, [])

    const saveSelectedOptions = (selected_Options) => {
        if (selected_Options && selected_Options.length > 0) {
            const results = userPermission.filter(({ label: id1 }) => selected_Options.some((id2) => id2 === id1))
            setSelected(results)
            setSelectedValue(selected_Options)
        }
    }

    const getUserDetailsData = async () => {
        const payload = {
            user_id: _id
        }
        setLoading(true)
        await dispatch(getUserDetails(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const resData = response.data.user
                        const { allowed_permission } = resData
                        saveSelectedOptions(allowed_permission)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                })
            .catch(error => console.log(error))
    }


    const handleChange = (options) => {
        setSelected(options);
        setSelectedValue(options.map((item) => item.label));
    };


    const handleSubmit = async () => {
        const payLoad = { allowed_permission: selectedValue, user_id: _id }
        setLoading(true)
        await dispatch(updateUserPermission(payLoad))
            .then(
                response => {
                    setLoading(false)
                    if (response.status == '1') {
                        toast.success(response.data.message)
                        setSelected([])
                        setSelectedValue([])
                        closeModal()
                        getUserList()
                    } else {
                        toast.error(response.data.error[0])
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                }
            )
            .catch(
                error => console.log(error)
            )
    }

    return (
        <div id="myModal" className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Allow other permission</h5>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form>
                        <div className="modal-body p-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">User Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={nickname || username}
                                            placeholder='Shop Name'
                                            disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group required" style={{ fontFamily: "sans-serif" }}>
                                        <label className="control-label">User Permission</label>
                                        <MultiSelect
                                            key="example_id"
                                            options={userPermission}
                                            onChange={handleChange}
                                            value={selected}
                                            isSelectAll={true}
                                            menuPlacement={"bottom"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer model-form-savbtn">
                            <button type="button" onClick={handleSubmit} className="btn btn-success">{isLoading ? "Processing" : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default OtherPermissionModal