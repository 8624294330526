import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getAccessTokenApi } from '../../Redux/action/Auth';
import { useSelector } from 'react-redux';

function FoodicsSuccess({ setLoading }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { foodics_user, foodics_user_token } = useSelector(state => state.auth)
    const [isAccessToken, setAccToken] = useState(false)
    

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const codeParam = queryParams.get('code');
        if (codeParam) {
            getAccessToken({ code: codeParam })
        }
    }, [location]);

    const getAccessToken = async (data) => {
        setLoading(true)
        await dispatch(getAccessTokenApi(data, foodics_user_token))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        const resData = response.data
                        if (resData.user) {
                            setAccToken(true)
                        }
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                })
            .catch(error => console.log(error))
    }

    const redirectToDashboard = () => {
        dispatch({ type: "USER_DATA_UPDATE", payload: foodics_user });
        dispatch({ type: "SAVE_TOKEN", payload: foodics_user_token });
        history.push('/')
        window.location.reload()
    }


    return (
        <div className="vh-100 d-flex justify-content-center align-items-center">
            <div className="col-md-4">
                <div className="border border-4 border-success"></div>
                <div className="card  bg-white shadow p-5">
                    <div className="mb-4 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-success bi bi-check-circle" width="75" height="75"
                            fill="currentColor" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path
                                d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>
                    </div>
                    <div className="text-center">
                        <h1>Thank You !</h1>
                        <p>Your account has been authorized successfully</p>
                        <button onClick={redirectToDashboard} disabled={!isAccessToken} type='button' className="btn btn-outline-success">Go to Dashboard</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IsLoadingHOC(FoodicsSuccess)
