import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import "react-datepicker/dist/react-datepicker.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import { store, persistor } from './Redux/store';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import "./assets/css/newStyle.css";
import "./assets/css/trackOrderStyle.css";
import App from './App';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
