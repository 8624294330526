
import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { logOut } from '../../../Redux/action/Auth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { countryCode } from '../../../Config/constants';


const time_zone = process.env.REACT_APP_TIMEZONE || 'Asia/Kolkata';

const UserSidebar = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const history = useHistory()
    const { user } = useSelector(state => state.auth)
    const { own_drivers } = user
    const [time, setTime] = useState('AdminLTE 3');
    const { branchesArr } = useSelector(state => state.shop)


    useEffect(() => {
        const timer = setInterval(() => {
            var date = moment(new Date());
            let current_time = date.tz(time_zone).format('hh:mm:ss A');
            setTime(current_time);
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);


    const handleLogOut = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Utrac</h1>
                        <p>Do you want to exit from this App?</p>
                        <button onClick={onClose}>No</button>
                        <button
                            onClick={() => {
                                dispatch(logOut())
                                history.push('/login')
                                onClose()
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            }
        });
    }

    return (
        <>
            <aside className="main-sidebar new_main_sidebar sidebar-dark-primary elevation-4">
                <a href="/#" className="nav_button" data-widget="pushmenu" role="button"><i className="fas fa-angle-left"></i></a>
                <div className="logo">
                    <span className="brand-link" href="#">
                        {countryCode === '+965' ?
                            <img style={{ borderRadius: '50%', width: "45px", height: "50px", margin: '10px', padding: '1px' }} id="logo" className="brand-image  elevation-3" alt="Logo" src={require("../../../assets/logo/kuwait_logo.png")} />
                            :
                            <img id="logo" className="brand-image  elevation-3" alt="Logo" src={require("../../../assets/dist/img/logo.png")} />
                        }
                        <span className="brand-text font-weight-light">{time}</span>
                        {countryCode === '+965' &&
                            <div>
                                <span style={{ fontSize: "1rem" }}>Powered by Utrac</span>
                            </div>
                        }
                    </span>
                </div>
                <div className="sidebar">
                    <nav className="mt-2 main_nav">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <a href="/#" className="nav-link">
                                    <i className="fa-regular fa-house fa-lg mr-3"></i>
                                    <p>
                                        Dashboard<span></span>
                                        <i className="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="/" className={`nav-link ${location.pathname === "/" ? "active" : ""}`} >
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Summary</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/orders-charts" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Orders Chart</p>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/averages-charts" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Average Chart</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            {["Shop", "Logistics"].includes(user?.type) &&
                                <li className="nav-item">
                                    <a className="nav-link">
                                        <i className="fa-regular fa-user fa-lg mr-3"></i>
                                        <p>
                                            User<span></span>
                                            <i className="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        {["Shop", "Logistics"].includes(user?.type) &&
                                            <li className="nav-item">
                                                <NavLink to="/drivers" className="nav-link">
                                                    <i className="far fa-circle nav-icon"></i>
                                                    <p>
                                                        Drivers<span></span>
                                                    </p>
                                                </NavLink>
                                            </li>
                                        }
                                    </ul>
                                </li>
                            }

                            <li className="nav-item">
                                <a className="nav-link">
                                    <i className="fa-regular fa-bags-shopping fa-lg mr-3"></i>
                                    <p>
                                        Order<span></span>
                                        <i className="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    {user && user.type === "Shop" &&
                                        <li className="nav-item">
                                            <NavLink to="/create-order" className="nav-link">
                                                <i className="fa-regular fa-circle-plus nav-icon"></i>
                                                <p>Create Order</p>
                                            </NavLink>
                                        </li>}
                                    <li className="nav-item">
                                        <NavLink to="/all-orders" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>All</p>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/open-orders" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Open</p>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/pending-pickup" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Pending Pickup</p>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/arrived-orders" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Arrived</p>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/picked-orders" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Picked</p>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/completed-orders" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Completed</p>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/canceled-orders" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Canceled</p>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/on-hold-orders" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>On Hold</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            {['Shop', 'Integrator'].includes(user?.type) &&
                                <li className="nav-item">
                                    <a className="nav-link">
                                        <i className="fa-regular fa-calendar-week fa-lg mr-3"></i>
                                        <p>
                                            Schedule<span></span>
                                            <i className="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        {user?.type === 'Shop' &&
                                            <li className="nav-item">
                                                <NavLink to="/create-schedule-order" className="nav-link">
                                                    <i className="fa-regular fa-circle-plus nav-icon"></i>
                                                    <p>Create</p>
                                                </NavLink>
                                            </li>
                                        }
                                        <li className="nav-item">
                                            <NavLink to="/scheduled-orders" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Manage</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>}

                            {["Shop", "Logistics"].includes(user?.type) &&
                                <li className="nav-item">
                                    <a className="nav-link">
                                        <i className="fa-regular fa-layer-group fa-lg mr-3"></i>
                                        <p>
                                            Other<span></span>
                                            <i className="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        {["Shop", "Logistics"].includes(user?.type) &&
                                            <li className="nav-item">
                                                <NavLink to="/drivers-location" className="nav-link">
                                                    <i className="fa-regular fa-circle nav-icon"></i>
                                                    <p>
                                                        Driver Location<span></span>
                                                    </p>
                                                </NavLink>
                                            </li>
                                        }
                                        {["Shop"].includes(user?.type) &&
                                            <li className="nav-item">
                                                <NavLink to="/shop-branches" className="nav-link">
                                                    <i className="fa-regular fa-circle nav-icon"></i>
                                                    <p>
                                                        Branches<span></span>
                                                    </p>
                                                </NavLink>
                                            </li>
                                        }
                                    </ul>
                                </li>
                            }

                            <li className="nav-item">
                                <a className="nav-link">
                                    <i className="fa-regular fa-file-lines fa-lg mr-3"></i>
                                    <p>
                                        Report<span></span>
                                        <i className="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    {["Shop", "Integrator"].includes(user?.type) &&
                                        <li className="nav-item">
                                            <NavLink to="/invoices" className="nav-link">
                                                <i className="fa-regular fa-circle nav-icon"></i>
                                                <p>
                                                    Invoices<span></span>
                                                </p>
                                            </NavLink>
                                        </li>
                                    }
                                    {["Shop"].includes(user?.type) &&
                                        <li className="nav-item">
                                            <NavLink to="/wallet-history" className="nav-link">
                                                <i className="fa-regular fa-circle nav-icon"></i>
                                                <p>
                                                    Wallet History<span></span>
                                                </p>
                                            </NavLink>
                                        </li>
                                    }
                                </ul>
                            </li>

                            <li className="nav-item">
                                <a onClick={handleLogOut} className="nav-link">
                                    <i className="fas fa-sign-out-alt fa-lg mr-3"></i>
                                    Log out
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    )
}
export default UserSidebar