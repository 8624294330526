import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from 'react';
import { getDriversActivityLog, getUsersList, removeDriverAccount, removeDriverJobStartPointApi, updateDriverAvailability, updateUserDetails } from '../../Redux/action/User';
import { getFormatedDate, handleCopyClipboard, handleSelectListItem, isAllItemSelected, setCharacterLimit, trimObjValues } from '../../Helper';
import SearchUsersForm from '../../Components/SearchUsers';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import DriverDocuments from '../../Components/DocumentsModal/DriverDocuments';
import DeleteConfirmation from '../../Components/ConfirmModel/Delete';
import { updateRelatedOrderId } from '../../Redux/action/App';
import Pagination from '../../Components/Pagination';
import { Link, useHistory, useLocation } from 'react-router-dom';
import TableFooter from '../../Components/TableFooter';
import ManageShiftTimeModal from './ManageShiftTime';
import DriverActivityModal from './ActivityModal';
import DriverOrdersModal from './DriverOrders';
import ShowUserOTPModal from '../../Components/UserModal/ShowUserOtpModal';
import SaveDriverLocation from './SaveDriverLocation';
import SwitchToggle from '../../Components/Form/Switch/SwitchToggle';
import { SidebarContext } from '../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { exportToExcel } from '../../Helper';
import moment from 'moment';

// Export CSV File Headers
const headers = [
  { label: "Name", key: "username" },
  { label: "Phone Number", key: "phone" },
  { label: "Email Address", key: "email" },
  { label: "Activated", key: "active" },
  { label: "Verified", key: "verified" },
  { label: "Available", key: "available" },
  { label: "Online", key: "online" },
  { label: "Balance", key: "balance" },
  { label: "Created At", key: "_created_at" },
  { label: "Updated At", key: "_updated_at" },
];

const activityLogHeaders = [
  { label: "UserId", key: "user_id" },
  { label: "Name", key: "driver.username" },
  { label: "Action", key: "action" },
  { label: "Summary", key: "summary" },
  { label: "Created At", key: "_created_at" },
  { label: "Updated At", key: "_updated_at" },
];

function AllDrivers(props) {
  const { isLoading, setLoading } = props
  const history = useHistory()
  const location = useLocation()
  const pathName = location.pathname
  const [drivers, setDrivers] = useState([])
  const { user } = useSelector(state => state.auth)
  const { own_drivers } = user
  const DSPdrivers = useSelector(state => state.auth.user.drivers)
  const [driverId, setDriverId] = useState('')
  const [driverDetails, setDriverDetails] = useState(null)
  const [documentsModal, setDocumentsModal] = useState(false)
  const [activityModal, setActivityModal] = useState(false)
  const [roadMapModal, setRoadMapModal] = useState(false)
  const [locationModal, setLocationModal] = useState(false)
  const [shiftTimeModal, setShiftTimeModal] = useState(false)
  const csvLink2 = useRef();

  const {
    csvLink,
    dispatch,
    totalCount,
    toast,
    sorting,
    handleSorting,
    setTotalCount,
    deleteModal,
    handleSubmit,
    setDeleteModal,
    fileLoading,
    resetForm,
    setFileLoading,
    selectedItem,
    handleSelectItem,
    exportsData,
    setExportsData,
    setSelectedItem,
    currentPage,
    setcurrentPage,
    selectedDate,
    setSelectedDate,
    itemsPerPage,
    maxPageNumberLimit,
    setmaxPageNumberLimit,
    minPageNumberLimit,
    setminPageNumberLimit,
    showOTPModal,
    setShowOTPModal
  } = useContext(SidebarContext)

  useLayoutEffect(() => {
    if (location.state && location.state.currentPage) {
      setcurrentPage(location.state.currentPage)
    }
  }, [])

  let driversType = 'All Drivers'
  let is_active = ""
  if (pathName === '/active-drivers') {
    is_active = 'true'
    driversType = 'Active Drivers'
  } else if (pathName === '/inactive-drivers') {
    is_active = 'false'
    driversType = 'Inactive Drivers'
  }

  const [searchParams, setSearchParams] = useState({
    _id: "",
    type: "Driver",
    nickname: "",
    username: "",
    phone: "",
    from_date: "",
    to_date: "",
    active: is_active,
    verified: "",
    is_vip: "",
    available: "",
    online: "",
    states_driver: ""
  })

  useEffect(() => {
    allDriversData();
  }, [currentPage, sorting])

  const allDriversData = async () => {
    const trimmedSearchParams = trimObjValues(searchParams)
    if (user.type === "Logistics") {
      if (DSPdrivers.length > 0) {
        Object.assign(trimmedSearchParams, { _id: DSPdrivers?.join(',') })
      }
      else {
        return;
      }
    }
    if (user.type === 'Shop') {
      if (own_drivers?.length > 0) {
        Object.assign(trimmedSearchParams, { _id: own_drivers?.join(',') })
      }
      else {
        return;
      }
    }
    const payload = {
      search: { ...trimmedSearchParams },
      shorting: sorting,
      pagination: { start: currentPage, length: itemsPerPage }
    }
    setLoading(true)
    await dispatch(getUsersList(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status === "1") {
            setDrivers(response.data.users)
            setTotalCount(response.data.total_count)
          } else {
            setDrivers([])
            setTotalCount(null)
          }
        },
        (error) => {
          setLoading(false);
          toast.error(error.response.data?.message)
        }
      )
  }

  const exportDriverList = async () => {
    const totalItem = [];
    const trimmedSearchParams = trimObjValues(searchParams);

    if (user.type === "Logistics" && DSPdrivers?.length > 0) {
      Object.assign(trimmedSearchParams, { _id: DSPdrivers.join(',') });
    } else if (user.type === 'Shop' && own_drivers?.length > 0) {
      Object.assign(trimmedSearchParams, { _id: own_drivers.join(',') });
    }

    const fetchUsersList = async (pagination) => {
      const payload = {
        search: { ...trimmedSearchParams },
        selected_items: pagination.start === 1 ? [...selectedItem] : [],
        shorting: sorting,
        pagination: pagination
      };

      try {
        setFileLoading(true);
        const response = await dispatch(getUsersList(payload));
        setFileLoading(false);

        if (response.status === "1") {
          totalItem.push(...response.data.users);
        }
      } catch (error) {
        setFileLoading(false);
        toast.error(error.response?.data?.message);
      }
    };

    if (selectedItem && selectedItem.length > 0) {
      await fetchUsersList({ start: 1, length: 1000 });
    } else {
      const pages = Math.ceil(totalCount / 50000);
      for (let i = 1; i <= pages; i++) {
        await fetchUsersList({ start: i, length: 50000 });
      }
    }
    const exportData = totalItem?.map((item) => {
      const exportItem = {};
      headers.forEach(header => {
        exportItem[header.label] = item[header.key];
      });
      return exportItem;
    });
    const fileName = `Drivers-${moment(new Date()).format("hh-mm-ss")}.xlsx`
    exportToExcel(exportData, fileName)
    //setExportsData(totalItem);
    //csvLink.current.link.click();
    toast.success("Data exported successfully");
    setSelectedItem([]);
  };


  const exportDriversActivity = async () => {
    const trimmedSearchParams = trimObjValues(searchParams)
    if (user.type === "Logistics") {
      if (DSPdrivers.length > 0) {
        Object.assign(trimmedSearchParams, { _id: DSPdrivers?.join(',') })
      }
      else {
        return;
      }
    }
    if (user.type === 'Shop') {
      if (own_drivers?.length > 0) {
        Object.assign(trimmedSearchParams, { _id: own_drivers?.join(',') })
      }
      else {
        return;
      }
    }
    const payload = {
      search: {
        user_id: "",
        from_date: "",
        to_date: ""
      },
      selected_items: selectedItem
    }
    setLoading(true)
    await dispatch(getDriversActivityLog(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status == "1") {
            const resData = response.data?.logs
            const exportData = resData?.map((item) => {
              const exportItem = {};
              activityLogHeaders.forEach(header => {
                exportItem[header.label] = item[header.key];
              });
              return exportItem;
            });
            const fileName = `Drivers_logactivity-${moment(new Date()).format("hh-mm-ss")}.xlsx`
            exportToExcel(exportData, fileName)
            // setExportsData(resData);
            // csvLink2.current.link.click();
            toast.success("Data exported successfully");
            setSelectedItem([])
          }
        },
        (error) => {
          setLoading(false);
          const toastMsg = error.response.data?.message || error.response.data.data?.message
          toast.error(toastMsg)
        }
      )
  }

  const handleRemoveAccount = async () => {
    const payload = { driver_id: driverId }
    setLoading(true)
    await dispatch(removeDriverAccount(payload))
      .then(
        response => {
          setLoading(false);
          if (response.status == "1") {
            toast.success(response.data?.message)
            setDeleteModal(false)
            allDriversData();
          } else {
            toast.error(response.data?.message)
          }
        },
        (error) => {
          toast.error(error.response.data?.data?.message)
          setLoading(false);
        }
      )
  }

  const handleSearch = (e) => {
    let { name, value } = e.target
    value = value.toString();
    if (value[0] === "+") {
      value = value.substring(1)
    }
    setSearchParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleChangeDate = (date, name) => {
    setSearchParams((prevState) => ({
      ...prevState,
      [name]: date ? moment(date).format("YYYY-MM-DD") : '',
    }));
    setSelectedDate((prevState) => ({
      ...prevState,
      [name]: date,
    }));
  }

  const handleReset = async () => {
    const resetFormData = {
      _id: "",
      type: "Driver",
      nickname: "",
      username: "",
      phone: "",
      from_date: "",
      to_date: "",
      active: is_active,
      verified: "",
      available: "",
      online: "",
      states_driver: ""
    }
    setSearchParams(resetFormData);
    resetForm();
  }

  const handleRelatedOrders = async (item) => {
    const payload = { driverid: item._id, relatedBy: 'Driver', user_name: item.username }
    dispatch(updateRelatedOrderId(payload))
    history.push(`/completed-orders/${item._id}`)
  }


  const removeDriverJobStartPoint = async (data) => {
    const { _id } = data
    const payload = { user_id: _id }
    setLoading(true)
    await dispatch(removeDriverJobStartPointApi(payload))
      .then(
        response => {
          setLoading(false)
          if (response.status == 1) {
            toast.success(response.data.message)
            allDriversData();
          }
        },
        error => {
          setLoading(false)
          toast.error(error.response.data?.message)
        }
      )
      .catch((error) => console.log("Catch Error", error))
  }

  const handleChangeAvailability = async (event, data) => {
    const { checked } = event.target
    const { _id } = data
    const payload = { user_id: _id, availability: checked }
    setLoading(true)
    await dispatch(updateDriverAvailability(payload))
      .then(
        response => {
          setLoading(false)
          if (response.status == 1) {
            toast.success(response.data.message)
            const result = drivers.map(driver => {
              if (driver._id === _id) {
                return { ...driver, online: checked };
              }
              return driver
            })
            setDrivers(result)
          }
        },
        error => {
          setLoading(false)
          toast.error(error.response.data?.message)
        }
      )
      .catch((error) => console.log("Catch Error", error))
  }

  const handleChangeActivity = async (event, data) => {
    const { checked } = event.target
    const { _id, username, nickname, email, phone } = data
    const payload = { user_id: _id, username, nickname, email, phone, is_active: checked }
    setLoading(true)
    await dispatch(updateUserDetails(payload))
      .then(
        response => {
          setLoading(false)
          if (response.status == 1) {
            toast.success(response.data.message)
            const result = drivers.map(driver => {
              if (driver._id === _id) {
                return { ...driver, active: checked };
              }
              return driver
            })
            setDrivers(result)
          }
        },
        error => {
          setLoading(false)
          toast.error(error.response.data?.message)
        }
      )
      .catch((error) => console.log("Catch Error", error))
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header main_breadcrumb_top">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{driversType}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item active">Drivers</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="all_drivers">
                  <div className="card card-dark">
                    <div className="card-header">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-6 col-lg-11">
                            <h3 className="pl-5">Search</h3>
                          </div>
                          <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                            <i className="fas fa-angle-up click_search"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <form className="form_search" action="">
                      <SearchUsersForm
                        searchParams={searchParams}
                        handleSearch={handleSearch}
                        handleChangeDate={handleChangeDate}
                        selectedDate={selectedDate}
                      />
                      <div className="card-footer text-center">
                        <button type="reset" className="btn btn-primary mr-2" onClick={handleReset}>Reset</button>
                        <button type="button" onClick={() => handleSubmit(allDriversData)} className="btn btn-success">Submit</button>
                      </div>
                    </form>
                  </div>
                  <div className="row pagination_new flex-wrap">
                    <div className="col-lg-6 col-12  d-flex">
                      <button disabled={isLoading} type="button" onClick={() => handleSubmit(allDriversData)} className="btn btn-secondary"><i className={`fa-regular fa-rotate-right ${isLoading && 'fa_spinner'}`}></i>Refresh</button>
                      {drivers?.length >= 1 &&
                        <div className="dropdown ml-2">
                          <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            <i className="fa-regular fa-circle-up mr-2"></i>
                            {fileLoading ? 'Processing' : 'Export'}
                          </button>
                          <div className="dropdown-menu">
                            <ul className='origin-top-left absolute w-56 mb-0 rounded-md bg-white dark:bg-gray-800 focus:outline-none z-40'>
                              <li style={{ cursor: 'pointer' }} className="dropdown-item py-2 font-medium " type='button' disabled={fileLoading || !totalCount || isLoading} onClick={exportDriverList} >
                                <span className='flex items-center'>
                                  <i className="fa-sharp fa-regular fa-file-spreadsheet fa-lg mr-3"></i>
                                  {fileLoading ? "Loading.." : "Driver List"}
                                </span>
                              </li>
                              {/* <CSVLink className='csv-link' target='_blank' ref={csvLink}
                                headers={headers}
                                data={exportsData}
                                filename={`Drivers_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss-A")}`}
                              /> */}
                              <li style={{ cursor: 'pointer' }} className="dropdown-item py-2 font-medium " type='button' disabled={fileLoading || !totalCount || isLoading} onClick={exportDriversActivity} >
                                <span className='flex items-center'>
                                  <i className="fa-sharp fa-regular fa-file-spreadsheet fa-lg mr-3"></i>
                                  {fileLoading ? "Loading.." : "Activity Log"}
                                </span>
                              </li>
                              {/* <CSVLink className='csv-link' target='_blank' ref={csvLink2}
                                headers={activityLogHeaders}
                                data={exportsData}
                                filename={`Drivers_logactivity_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss-A")}`}
                              /> */}
                            </ul>
                          </div>
                        </div>
                      }
                    </div>
                    {drivers?.length > 0 &&
                      <div className="col-lg-6 col-12">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          maxPageNumberLimit={maxPageNumberLimit}
                          minPageNumberLimit={minPageNumberLimit}
                          setcurrentPage={setcurrentPage}
                          setmaxPageNumberLimit={setmaxPageNumberLimit}
                          setminPageNumberLimit={setminPageNumberLimit}
                        />
                      </div>
                    }
                  </div>
                  {drivers?.length > 0 &&
                    <React.Fragment>
                      <div className="card table_card">
                        <div className="card-body all_drive_table">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th className='input--checkbox'><input type="checkbox" id="check" checked={isAllItemSelected(drivers, selectedItem)} onChange={(event) => handleSelectListItem(event, drivers, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th>
                                <th className='user--name'>Name
                                  {sorting.order_by === "username" ?
                                    <i onClick={() => handleSorting("username")}
                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                    </i>
                                    : <i onClick={() => handleSorting("username")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                  }
                                </th>
                                <th className='phone--number'>Phone</th>
                                <th className='availability' >VIP</th>
                                <th className='availability'>Available</th>
                                <th className='driver--balance'>Balance
                                  {sorting.order_by === "balance" ?
                                    <i onClick={() => handleSorting("balance")}
                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                    </i>
                                    : <i onClick={() => handleSorting("balance")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                  }
                                </th>
                                <th className='created--date'>Created At
                                  {sorting.order_by === "_created_at" ?
                                    <i onClick={() => handleSorting("_created_at")}
                                      className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                    </i>
                                    : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                  }
                                </th>
                                <th className='online-driver'>Online</th>
                                <th className='active-driver' >Active</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {drivers.map((item, i) => (
                                <tr key={item._id} >
                                  <td>
                                    <input type="checkbox" className="user_table_checkbox"
                                      onChange={(event) => handleSelectItem(event, item._id)}
                                      checked={selectedItem.includes(item._id)} />
                                  </td>
                                  <td className='cursor-pointer'>
                                    <div>
                                      <i title={item.is_permanent ? 'Permanent Driver' : 'Temporary Driver'} className={`${item.is_permanent ? 'fa-solid' : 'fa-regular'} fa-user`}></i>
                                      <span title={item.username}>
                                        {setCharacterLimit(item.username)}
                                      </span>
                                    </div>
                                    {item?.shift_starts_at && item?.shift_ends_at &&
                                      <div>
                                        <span className='text-success'>
                                          <i className="fa-regular fa-clock"></i>
                                          <span title='Shift-Time'>{item.shift_starts_at}-{item.shift_ends_at}</span>
                                        </span>
                                      </div>
                                    }
                                    {item?.job_start_point?.location &&
                                      <div>
                                        <span className="text-success">
                                          <i className="fa-regular fa-location-dot"></i><span>Start Point Enable</span>
                                        </span>
                                      </div>
                                    }
                                  </td>
                                  <td>
                                    <div><i className="fa-solid fa-phone"></i> {item.phone ? item.phone : "Not Available"}</div>
                                    {item?.vehicle_type &&
                                      <div>
                                        {item.vehicle_type === "bike" && <i className="fa-solid fa-motorcycle"></i>}
                                        {item.vehicle_type === "car" && <i className="fa-solid fa-car"></i>}
                                        {item.vehicle_type === "truck" && <i className="fa-sharp fa-solid fa-truck"></i>}
                                        {item.vehicle_type === "van" && <i className="fa-solid fa-van-shuttle"></i>}
                                        {item.vehicle_type === "Not Available" && <i className="fa-sharp fa-solid fa-truck"></i>}
                                        {item?.vehicle_type}
                                      </div>
                                    }
                                  </td>
                                  <td><span className={item.is_vip ? 'yes_1' : item.is_vip === false ? 'no_1' : "not_avl"} >{item.is_vip ? 'Yes' : item.is_vip === false ? "No" : 'NA'}</span></td>
                                  <td><span className={item.available ? 'yes_1' : item.available === false ? 'no_1' : "not_avl"}>{item.available ? 'Yes' : item.available === false ? "No" : 'NA'}</span></td>
                                  <td>{item.balance === 0 ? "0" : item.balance ? item.balance?.toFixed(2) : "NA"}</td>
                                  <td>{getFormatedDate(item._created_at)}</td>
                                  <td>
                                    <SwitchToggle item={item} isChecked={item.online} handleSwitch={(event) => handleChangeAvailability(event, item)} />
                                  </td>
                                  <td>
                                    <SwitchToggle item={item} isChecked={item.active} handleSwitch={(event) => handleChangeActivity(event, item)} />
                                  </td>
                                  <td>
                                    <div className="action_button">
                                      <div className="btn-group">
                                        <button style={{ width: "50px" }} type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                        </button>
                                        <div className="dropdown-menu">
                                          <span onClick={() => handleCopyClipboard(item._id)} className="dropdown-item"><span>
                                            <i className="fa-regular fa-copy"></i>Copy User Id</span>
                                          </span>
                                          <div className="dropdown-divider"></div>
                                          <span onClick={() => {
                                            setShowOTPModal(true)
                                            setDriverDetails(item)
                                          }}
                                            className="dropdown-item"><span>
                                              <i className="fa-solid fa-eye"></i>Show OTP</span>
                                          </span>
                                          <div className="dropdown-divider"></div>
                                          <span className="dropdown-item" onClick={() => handleRelatedOrders(item)}>
                                            <i className="fa fa-list" aria-hidden="true"></i>Completed Orders
                                          </span>
                                          <div className="dropdown-divider"></div>
                                          <span className="dropdown-item"
                                            onClick={() => {
                                              setDriverDetails(item)
                                              setDocumentsModal(true)
                                            }}
                                          >
                                            <span>
                                              <i className="fa-regular fa-file"></i>View documents</span>
                                          </span>

                                          <div className="dropdown-divider"></div>
                                          <span className="dropdown-item"
                                            onClick={() => {
                                              setDriverDetails(item)
                                              setActivityModal(true)
                                            }}
                                          >
                                            <span>
                                              <i className="fa-regular fa-person-circle-check"></i>Log activity</span>
                                          </span>

                                          <div className="dropdown-divider"></div>
                                          <span className="dropdown-item"
                                            onClick={() => {
                                              setDriverDetails(item)
                                              setRoadMapModal(true)
                                            }}
                                          >
                                            <span>
                                              <i className="fa-solid fa-road"></i>Road Map</span>
                                          </span>

                                          <div className="dropdown-divider"></div>
                                          <Link className="dropdown-item" to={{
                                            pathname: `/edit-driver/${item._id}`,
                                            state: { currentPage: currentPage }
                                          }} ><span className="blue_1">
                                              <i className="fa fa-pencil" aria-hidden="true"></i>Edit account</span>
                                          </Link>
                                          <div className="dropdown-divider"></div>
                                          <span className="dropdown-item"
                                            onClick={() => {
                                              setDriverDetails(item)
                                              setShiftTimeModal(true)
                                            }}
                                          >
                                            <span>
                                              <i className="fa-sharp fa-regular fa-clock"></i>Schedule shift</span>
                                          </span>
                                          <div className="dropdown-divider"></div>
                                          <span className="dropdown-item"
                                            onClick={() => {
                                              setDriverDetails(item)
                                              setLocationModal(true)
                                            }}
                                          >
                                            <span className='text-success'>
                                              <i className="fa-regular fa-location-plus"></i>Add start point</span>
                                          </span>
                                          {item?.job_start_point?.location &&
                                            <>
                                              <div className="dropdown-divider"></div>
                                              <span className="dropdown-item" onClick={() => removeDriverJobStartPoint(item)} ><span className='text-warning'>
                                                <i className="fa-regular fa-location-xmark"></i>Remove start point</span>
                                              </span>
                                            </>
                                          }
                                          {user.type === 'Admin' &&
                                            <>
                                              <div className="dropdown-divider"></div>
                                              <span className="dropdown-item"
                                                onClick={() => {
                                                  setDriverId(item._id)
                                                  setDeleteModal(true)
                                                }}
                                              >
                                                <span className="red_1">
                                                  <i className="fa fa-trash" aria-hidden="true"></i>Remove account</span>
                                              </span>
                                            </>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>)
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row pagination_new">
                        <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={drivers} totalCount={totalCount} />
                        <div className="col-lg-4">
                          <Pagination
                            totalCount={totalCount}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            maxPageNumberLimit={maxPageNumberLimit}
                            minPageNumberLimit={minPageNumberLimit}
                            setcurrentPage={setcurrentPage}
                            setmaxPageNumberLimit={setmaxPageNumberLimit}
                            setminPageNumberLimit={setminPageNumberLimit}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  }
                  {drivers?.length === 0 && !isLoading && <NotFoundData />}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {
        deleteModal && <DeleteConfirmation
          modal={deleteModal}
          loader={isLoading}
          title="Do you want to remove user account?"
          closeModal={() => setDeleteModal(false)}
          handleDelete={handleRemoveAccount} />
      }

      {
        documentsModal && <DriverDocuments
          modal={documentsModal}
          driverDetails={driverDetails}
          getListData={allDriversData}
          setLoading={setLoading}
          closeModal={() => setDocumentsModal(false)} />
      }

      {
        shiftTimeModal && <ManageShiftTimeModal
          modal={shiftTimeModal}
          userData={driverDetails}
          setLoading={setLoading}
          getListData={allDriversData}
          closeModal={() => setShiftTimeModal(false)} />
      }

      {
        activityModal && <DriverActivityModal
          modal={activityModal}
          setLoading={setLoading}
          isLoading={isLoading}
          driverDetails={driverDetails}
          closeModal={() => setActivityModal(false)} />
      }

      {roadMapModal &&
        <DriverOrdersModal
          modal={roadMapModal}
          setLoading={setLoading}
          isLoading={isLoading}
          driverDetails={driverDetails}
          closeModal={() => setRoadMapModal(false)} />
      }

      {
        locationModal && <SaveDriverLocation
          modal={locationModal}
          userData={driverDetails}
          getListData={allDriversData}
          isLoading={isLoading}
          setLoading={setLoading}
          closeModal={() => setLocationModal(false)} />
      }

      {
        showOTPModal && <ShowUserOTPModal
          modal={showOTPModal}
          user={driverDetails}
          closeModal={() => setShowOTPModal(false)} />
      }

    </>
  )
}
export default IsLoadingHOC(IsLoggedinHOC(AllDrivers))