/* eslint-disable no-undef */
import React, {useState} from "react";
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import simpleMarker from '../../assets/dist/img/marker.png';

const libraries = ['places'];

function SimpleMap({currentLatLng}) {
    const [map, setMap] = useState(null)

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    }, [libraries])

    function handleLoad(maps) {
        setMap(maps)
    }

    const mapContainerStyle = { width: "100%", height: "100%", };

    return (
        <div className="track-maps-content">
            {isLoaded && currentLatLng && currentLatLng.lat > 0 && currentLatLng.lng > 0 &&
                <GoogleMap
                    center={currentLatLng}
                    zoom={15}
                    mapContainerStyle={mapContainerStyle}
                    onLoad={handleLoad}
                    options={{
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                    }}
                >
                    <Marker
                        position={currentLatLng}
                        icon={simpleMarker}
                    />
                </GoogleMap>
            }
        </div>
    );
}
export default SimpleMap ;