import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { setParentShopID } from '../../Redux/action/App';
import { handleCopyClipboard, setCharacterLimit } from '../../Helper';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { getShopBranches, cleanShopFormData, setFormCurrentStep } from '../../Redux/action/Shop';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import Pagination from '../../Components/Pagination';
import { useSelector } from 'react-redux';
import TableFooter from '../../Components/TableFooter';
import AddBranchModal from './AddBranchModal';
import { SidebarContext } from '../../Context/SidebarContext';
import NotFoundData from '../../Components/NotFoundData';


function ShopBranches(props) {
    const { isLoading, setLoading } = props
    const history = useHistory()
    const params = useParams()
    const location = useLocation()
    const [branches, setBranches] = useState([])
    const [modal, setModal] = useState(false)
    const { headBranchName } = useSelector(state => state.shop)

    const {
        dispatch,
        totalCount,
        toast,
        setTotalCount,
        currentPage,
        setcurrentPage,
        itemsPerPage,
        sorting,
        maxPageNumberLimit,
        setmaxPageNumberLimit,
        minPageNumberLimit,
        setminPageNumberLimit,
    } = useContext(SidebarContext)

    useLayoutEffect(() => {
        if (location.state && location.state.currentPage) {
            setcurrentPage(location.state.currentPage)
        }
    }, [])

    useEffect(() => {
        getShopBranchesData();
    }, [currentPage])

    useEffect(() => {
        dispatch(cleanShopFormData());
        dispatch(setFormCurrentStep("USER_INFO"))
    }, [])

    const getShopBranchesData = async () => {
        const payload = {
            search: { user_id: params.id ? params.id : '' },
            shorting: sorting,
            pagination: { start: currentPage, length: itemsPerPage }
        }
        setLoading(true)
        await dispatch(getShopBranches(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const result = (response.data && response.data.branches) || []
                        setBranches(result)
                        setTotalCount(response.data.total_count)
                    } else {
                        setBranches([])
                        setTotalCount(null)
                    }
                },
                error => {
                    toast.error(error.response.data?.message)
                    setLoading(false)
                }
            )
    }

    const updateParentShopID = (data) => {
        if (data.parent_shop_id && data.branch_shop_id) {
            dispatch(setParentShopID(data.parent_shop_id))
            history.push(`/edit-shop-branch/${data.branch_shop_id}`)
        }
        else {
            toast.error('Branch shop id not found')
        }
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header main_breadcrumb_top">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Shop Branches of {`(${headBranchName})`}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Shop Branches</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="all_drivers">
                                    <div className="card card-dark">
                                        <div className="card-header">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-6 col-lg-11">
                                                    </div>
                                                    <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pagination_new">
                                        <div className="col-lg-6 col-12">
                                            <Link to='/shops'><button disabled={isLoading} type="button" className="btn btn-primary mr-2"><i className="fa-solid fa-circle-arrow-left"></i>Back</button></Link>
                                            <Link to={`/create-shop-branch/${params.id}`}><button className="btn btn-info mr-2" disabled={isLoading}><i className="fa-regular fa-circle-plus"></i>Create Branch</button></Link>
                                            <button onClick={() => setModal(true)} className="btn btn-success mr-2" disabled={isLoading}><i className="fa-regular fa-circle-plus"></i>Add Exist Shop</button>
                                        </div>
                                        {branches?.length > 0 &&
                                            <div className="col-lg-6 col-12">
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    maxPageNumberLimit={maxPageNumberLimit}
                                                    minPageNumberLimit={minPageNumberLimit}
                                                    setcurrentPage={setcurrentPage}
                                                    setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                    setminPageNumberLimit={setminPageNumberLimit}
                                                />
                                            </div>}
                                    </div>
                                    {branches?.length > 0 &&
                                        <React.Fragment>
                                            <div className="card table_card completed_1">
                                                <div className="card-body all_drive_table">
                                                    <table id="example2" className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr. No</th>
                                                                <th>User Name</th>
                                                                <th>Shop Name</th>
                                                                <th>Active</th>
                                                                <th>Available</th>
                                                                <th>Verify</th>
                                                                <th>VIP</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {branches.map((item, index) =>
                                                                <tr key={item._id} >
                                                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                    <td><span title={item.username}>{setCharacterLimit(item.username)}</span></td>
                                                                    <td><span title={item.nickname}>{setCharacterLimit(item.nickname)}</span></td>
                                                                    <td><span className={item.active ? 'yes_1' : item.active === false ? 'no_1' : 'not_avl'} >
                                                                        {item.active ? 'Yes' : item.active === false ? "No" : 'NA'}</span>
                                                                    </td>
                                                                    <td><span className={item.available ? 'yes_1' : item.available === false ? 'no_1' : 'not_avl'} >
                                                                        {item.available ? 'Yes' : item.available === false ? "No" : 'NA'}</span>
                                                                    </td>
                                                                    <td><span className={item.verified ? 'yes_1' : item.verified === false ? 'no_1' : 'not_avl'} >
                                                                        {item.verified ? 'Yes' : item.verified === false ? "No" : 'NA'}</span>
                                                                    </td>
                                                                    <td><span className={item.is_vip ? 'yes_1' : item.is_vip === false ? 'no_1' : 'not_avl'} >
                                                                        {item.is_vip ? 'Yes' : item.is_vip === false ? "No" : 'NA'}</span>
                                                                    </td>
                                                                    <td>
                                                                        <div className="action_button">
                                                                            <div className="btn-group">
                                                                                <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                                                </button>
                                                                                <div className="dropdown-menu">
                                                                                    <span onClick={() => handleCopyClipboard(item._id)} className="dropdown-item"><span>
                                                                                        <i className="fa-regular fa-copy"></i>Copy User Id</span>
                                                                                    </span>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <span onClick={() => updateParentShopID(item)} className="dropdown-item"><span className="blue_1">
                                                                                        <i className="fa fa-pencil" aria-hidden="true"></i>Edit</span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row pagination_new">
                                                <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={branches} totalCount={totalCount} />
                                                <div className="col-lg-4">
                                                    <Pagination
                                                        totalCount={totalCount}
                                                        currentPage={currentPage}
                                                        itemsPerPage={itemsPerPage}
                                                        maxPageNumberLimit={maxPageNumberLimit}
                                                        minPageNumberLimit={minPageNumberLimit}
                                                        setcurrentPage={setcurrentPage}
                                                        setmaxPageNumberLimit={setmaxPageNumberLimit}
                                                        setminPageNumberLimit={setminPageNumberLimit}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {branches?.length === 0 && !isLoading && <NotFoundData />}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {modal &&
                < AddBranchModal
                    modal={modal}
                    closeModal={() => setModal(false)}
                    getShopBranchesData={getShopBranchesData}
                    parent_shop_id={params.id}
                    headBranchName={headBranchName}
                />
            }
        </>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(ShopBranches))